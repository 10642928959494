import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { prop, path, flatten } from 'ramda';
import { getLanguageText } from '../language.js';
import { isMultiLanguageStringObject } from '../validation/validation.js';
import { isFineService, isCarService } from './car-payments.helpers.js';
import { composeEntityName } from './products.helpers.js';
import { MOVEMENT_TYPES } from '../constants/movements.js';
import { firstTwoInitials } from '../string/string.js';
import { TEMPLATE_PAYMENT_TYPES, BILL_PAYMENT_TEMPLATE_TYPE } from '../constants/payments-constants.js';
import { composeUrlByReference, composeUrlByTemplateId } from '../assets/assets.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var isMovementCredit = function isMovementCredit(id) {
  return id && id[0] === 'C';
};
var isMovementDebit = function isMovementDebit(id) {
  return id && id[0] === 'D';
};
var ACCOUNT_TYPES = {
  ACCOUNT: '100',
  CARD: '200',
  DEPOSIT: '32'
};

var getAccountName = function getAccountName(iban, accounts, cards, deposits, accountTypes, productTypes) {
  var account = accounts && accounts.find(function (_ref) {
    var ibanAccount = _ref.ibanAccount;
    return ibanAccount === iban;
  });

  if (account) {
    var _ref2 = account.accountType || {},
        accTypeId = _ref2.accTypeId,
        text = _ref2.text;

    switch (accTypeId) {
      case ACCOUNT_TYPES.ACCOUNT:
        {
          // If account type within account entity contains localized text, return text without iterating account types.
          if (isMultiLanguageStringObject(text)) {
            return text;
          }

          var accountType = accountTypes && accountTypes.find(function (_ref3) {
            var accTypeId = _ref3.accTypeId;
            return accTypeId === ACCOUNT_TYPES.ACCOUNT;
          });
          return accountType === null || accountType === void 0 ? void 0 : accountType.text;
        }

      case ACCOUNT_TYPES.CARD:
        {
          var _accounts$cards;

          var friendlyName = composeEntityName(account);

          if (friendlyName) {
            return friendlyName;
          }

          var card = (_accounts$cards = accounts.cards) === null || _accounts$cards === void 0 ? void 0 : _accounts$cards.find(function (_ref4) {
            var ibanAccount = _ref4.ibanAccount;
            return ibanAccount && ibanAccount.entityUniqueId === iban;
          });
          var productTypeId = path(['productType', 'entityUniqueId'], card); // If product type within card entity contains localized text, return text without iterating product types.

          var productTypeText = path(['productType', 'text'], card);

          if (isMultiLanguageStringObject(productTypeText)) {
            return productTypeText;
          }

          var productType = productTypeId && productTypes && productTypes.find(function (_ref5) {
            var prodTypeID = _ref5.prodTypeID;
            return prodTypeID === productTypeId;
          });
          return productType === null || productType === void 0 ? void 0 : productType.text;
        }

      case ACCOUNT_TYPES.DEPOSIT:
        {
          var deposit = iban && deposits && deposits.find(function (_ref6) {
            var depositAccount = _ref6.depositAccount;
            return depositAccount && depositAccount.ibanAccount === iban;
          });

          var _productTypeId = path(['productType', 'entityUniqueId'], deposit); // If product type within deposit entity contains localized text, return text without iterating product types.


          var _productTypeText = path(['productType', 'text'], deposit);

          if (isMultiLanguageStringObject(_productTypeText)) {
            return _productTypeText;
          }

          var _productType = _productTypeId && productTypes && productTypes.find(function (_ref7) {
            var prodTypeID = _ref7.prodTypeID;
            return prodTypeID === _productTypeId;
          });

          return _productType === null || _productType === void 0 ? void 0 : _productType.text;
        }

      default:
        return '';
    }
  }

  return '';
};

var getCreditAccountName = function getCreditAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes) {
  if (!movementDetail) {
    return null;
  }

  var altaCreditAccountIBAN = movementDetail.altaCreditAccountIBAN;
  return getAccountName(altaCreditAccountIBAN, accounts, cards, deposits, accountTypes, productTypes);
};

var getDebitAccountName = function getDebitAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes) {
  if (!movementDetail) {
    return null;
  }

  var altaDebitAccountIBAN = movementDetail.altaDebitAccountIBAN,
      clientAccount = movementDetail.clientAccount,
      partnerClientAccount = movementDetail.partnerClientAccount; // TODO: Prepare explicit logic to get debit account based on movement type,
  // instead of iterating account attributes.

  var debitAccount = [clientAccount, partnerClientAccount].find(function (account) {
    return account && account.ibanAccount === altaDebitAccountIBAN;
  });

  if (debitAccount && debitAccount.friendlyName) {
    return debitAccount.friendlyName;
  }

  return getAccountName(altaDebitAccountIBAN, accounts, cards, deposits, accountTypes, productTypes);
};

var getNameFromTemplate = function getNameFromTemplate(templates, iban) {
  var template = templates && templates.find(function (_ref8) {
    var beneficiaryAccount = _ref8.beneficiaryAccount;
    return beneficiaryAccount === iban;
  });
  return template && template.beneficiaryName;
};
var getMovementExportTemplate = function getMovementExportTemplate(_ref9) {
  var movementType = _ref9.movementType,
      id = _ref9.id,
      rateAmount = _ref9.rateAmount,
      altaCreditAccountIBAN = _ref9.altaCreditAccountIBAN,
      currency = _ref9.currency;

  switch (movementType && movementType.entityUniqueId) {
    case MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING:
    case MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING:
      {
        var isSwift = altaCreditAccountIBAN && (!altaCreditAccountIBAN.includes('GE') || prop('currencyID', currency) !== 'GEL');

        if (isSwift) {
          return 'classpath:/cz/bsc/g8/vtb/gps/template/movement/pdf/payment-order-international.tdxml';
        }

        return 'classpath:/cz/bsc/g8/vtb/gps/template/movement/pdf/payment-order.tdxml';
      }

    case MOVEMENT_TYPES.OWN_ACCOUNT:
    case MOVEMENT_TYPES.TREASURY:
    case MOVEMENT_TYPES.BILL_PAYMENT:
    case MOVEMENT_TYPES.FEE:
    case MOVEMENT_TYPES.FAST_MONEY_TRANSFER:
    case MOVEMENT_TYPES.SMS_CASHOUT:
      return 'classpath:/cz/bsc/g8/vtb/gps/template/movement/pdf/payment-order.tdxml';

    case MOVEMENT_TYPES.OTHER:
      if (rateAmount) {
        return 'classpath:/cz/bsc/g8/vtb/gps/template/movement/pdf/currency-exchange.tdxml';
      }

      return 'classpath:/cz/bsc/g8/vtb/gps/template/movement/pdf/payment-order.tdxml';

    case MOVEMENT_TYPES.CURRENCY_EXCHANGE:
      return 'classpath:/cz/bsc/g8/vtb/gps/template/movement/pdf/currency-exchange.tdxml';

    case MOVEMENT_TYPES.PAYMENT_BY_CARD:
    case MOVEMENT_TYPES.ATM_CASHOUT:
    case MOVEMENT_TYPES.LOAN_OPERATION:
    case MOVEMENT_TYPES.DEPOSIT_OPERATION:
      return 'classpath:/cz/bsc/g8/vtb/gps/template/movement/pdf/memorial-order.tdxml';

    case MOVEMENT_TYPES.CASH_OPERATION:
      if (isMovementDebit(id)) {
        return 'classpath:/cz/bsc/g8/vtb/gps/template/movement/pdf/cash-withdrawal.tdxml';
      }

      return 'classpath:/cz/bsc/g8/vtb/gps/template/movement/pdf/cash-deposit.tdxml';

    default:
      return 'classpath:/cz/bsc/g8/vtb/gps/template/movement/pdf/payment-order.tdxml';
  }
};
var getMovementExchangeRate = function getMovementExchangeRate(_ref10, exchangeRateDisplays) {
  var rateItems = _ref10.rateItems,
      currency = _ref10.currency,
      partnerCurrency = _ref10.partnerCurrency,
      rateAmount = _ref10.rateAmount;
  var currencyLeft = currency && currency.currencyID;
  var currencyRight = partnerCurrency && partnerCurrency.currencyID;
  var exchangeRateIndex = currencyLeft < currencyRight ? "".concat(currencyLeft, "2").concat(currencyRight) : "".concat(currencyRight, "2").concat(currencyLeft);
  var exchangeRate = exchangeRateDisplays && exchangeRateDisplays.find(function (_ref11) {
    var code = _ref11.code;
    return code === exchangeRateIndex;
  });
  var ccyLeft = path(['ccyLeft', 'currencyID'], exchangeRate);
  var ccyRight = path(['ccyRight', 'currencyID'], exchangeRate);

  if (exchangeRate) {
    return "".concat(rateItems).concat(ccyLeft, " = ").concat(rateAmount).concat(ccyRight);
  }

  return '';
};
var SHOW_REPEAT_PAYMENT_LEFT = [MOVEMENT_TYPES.OWN_ACCOUNT, MOVEMENT_TYPES.DEPOSIT_OPERATION];
var SHOW_REPEAT_PAYMENT_RIGHT = [MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING, MOVEMENT_TYPES.TREASURY];
var SHOW_ADDITIONAL_INFORMATION = [MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING, MOVEMENT_TYPES.TREASURY, MOVEMENT_TYPES.BILL_PAYMENT, MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING, MOVEMENT_TYPES.FEE];
var SHOW_CARD_HOLDER = [MOVEMENT_TYPES.ATM_CASHOUT, MOVEMENT_TYPES.SMS_CASHOUT, MOVEMENT_TYPES.PAYMENT_BY_CARD];
var SHOW_BANK_NAME_SENDER = [MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING, MOVEMENT_TYPES.TREASURY, MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING];
var SHOW_BANK_NAME_RECEIVER = [MOVEMENT_TYPES.TREASURY, MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING, MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING];
var SHOW_BANK_CODE_RECEIVER = [MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING, MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING];
var SHOW_BANK_CODE_SENDER = [MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING, MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING];
var SHOW_REPLY_PAYMENT = [MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING];
var SHOW_SAVE_TEMPLATE_LEFT = [MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING, MOVEMENT_TYPES.TREASURY, MOVEMENT_TYPES.BILL_PAYMENT];
var LOAD_INSTRUCTION_TYPES = [MOVEMENT_TYPES.TREASURY, MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING];
var showRepeatPaymentRight = function showRepeatPaymentRight(transactionTypeID, serviceCode) {
  return MOVEMENT_TYPES.BILL_PAYMENT === transactionTypeID && !isFineService(serviceCode) || SHOW_REPEAT_PAYMENT_RIGHT.includes(transactionTypeID);
};
var showSaveTemplateLeft = function showSaveTemplateLeft(movementDetail, instruction) {
  var _bpTransaction$servic;

  var _ref12 = movementDetail || {},
      transactionTypeID = _ref12.transactionTypeID,
      bpTransaction = _ref12.bpTransaction,
      treasuryCode = _ref12.treasuryCode;

  var service = {
    billType: bpTransaction === null || bpTransaction === void 0 ? void 0 : (_bpTransaction$servic = bpTransaction.serviceCode) === null || _bpTransaction$servic === void 0 ? void 0 : _bpTransaction$servic.billType
  };

  if (SHOW_SAVE_TEMPLATE_LEFT.includes(transactionTypeID) && !isCarService(service)) {
    if (MOVEMENT_TYPES.TREASURY === transactionTypeID) {
      var _instruction$instruct, _instruction$instruct2, _instruction$instruct3, _instruction$instruct4;

      return treasuryCode || !!(instruction !== null && instruction !== void 0 && (_instruction$instruct = instruction.instructionItems) !== null && _instruction$instruct !== void 0 && (_instruction$instruct2 = _instruction$instruct.data) !== null && _instruction$instruct2 !== void 0 && (_instruction$instruct3 = _instruction$instruct2[0]) !== null && _instruction$instruct3 !== void 0 && (_instruction$instruct4 = _instruction$instruct3.jsonData) !== null && _instruction$instruct4 !== void 0 && _instruction$instruct4.treasuryCode);
    }

    return true;
  }

  return false;
};
var getReceiverDetailBankCode = function getReceiverDetailBankCode(movementDetail) {
  if (!movementDetail) {
    return '';
  }

  switch (movementDetail.transactionTypeID) {
    case MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING:
      return movementDetail.receiverBankCode || '';

    case MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING:
      return !movementDetail.senderBankCode.includes('GE') ? movementDetail.receiverBankCode : '';

    default:
      return '';
  }
};
var getSenderDetailBankCode = function getSenderDetailBankCode(movementDetail) {
  if (!movementDetail) {
    return '';
  }

  switch (movementDetail.transactionTypeID) {
    case MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING:
    case MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING:
      return !movementDetail.senderBankCode.includes('GE') ? movementDetail.senderBankCode : '';

    default:
      return '';
  }
};
var getSenderIban = function getSenderIban(movementDetail) {
  var isDetail = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (!movementDetail) {
    return '';
  }

  var partnerCurrency = path(['partnerCurrency', 'currencyID'], movementDetail);
  var currency = path(['currency', 'currencyID'], movementDetail);

  switch (movementDetail.transactionTypeID) {
    case MOVEMENT_TYPES.OTHER:
    case MOVEMENT_TYPES.LOAN_OPERATION:
      return isMovementDebit(movementDetail.id) ? "".concat(movementDetail.altaDebitAccountIBAN, " ").concat(isDetail && currency ? currency : '') : '';

    case MOVEMENT_TYPES.PAYMENT_BY_CARD:
    case MOVEMENT_TYPES.CASH_OPERATION:
    case MOVEMENT_TYPES.FAST_MONEY_TRANSFER:
    case MOVEMENT_TYPES.DEPOSIT_OPERATION:
      return isMovementDebit(movementDetail.id) ? "".concat(movementDetail.altaDebitAccountIBAN, " ").concat(isDetail && currency ? currency : '') : '';

    case MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING:
      {
        return isDetail ? "".concat(movementDetail.senderAccount, " ").concat(partnerCurrency || currency) : movementDetail.senderBankName;
      }

    case MOVEMENT_TYPES.TREASURY:
      return "".concat(movementDetail.senderAccount, " ").concat(isDetail ? partnerCurrency || currency : '');

    default:
      return "".concat(movementDetail.altaDebitAccountIBAN, " ").concat(isDetail && currency ? currency : '');
  }
};
var getReceiverIban = function getReceiverIban(movementDetail) {
  var isDetail = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (!movementDetail) {
    return '';
  }

  var partnerCurrency = path(['partnerCurrency', 'currencyID'], movementDetail);
  var currency = path(['currency', 'currencyID'], movementDetail);

  switch (movementDetail.transactionTypeID) {
    case MOVEMENT_TYPES.OTHER:
    case MOVEMENT_TYPES.LOAN_OPERATION:
      return isMovementCredit(movementDetail.id) ? "".concat(movementDetail.altaCreditAccountIBAN, " ").concat(isDetail && currency ? currency : '') : '';

    case MOVEMENT_TYPES.PAYMENT_BY_CARD:
    case MOVEMENT_TYPES.CASH_OPERATION:
    case MOVEMENT_TYPES.FAST_MONEY_TRANSFER:
      return isMovementCredit(movementDetail.id) ? "".concat(movementDetail.altaCreditAccountIBAN, " ").concat(isDetail && currency ? currency : '') : '';

    case MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING:
      return "".concat(movementDetail.receiverAccount, " ").concat(isDetail && currency ? currency || partnerCurrency : '');

    case MOVEMENT_TYPES.OWN_ACCOUNT:
    case MOVEMENT_TYPES.DEPOSIT_OPERATION:
      return "".concat(movementDetail.altaCreditAccountIBAN, " ").concat(isDetail && currency ? currency : '');

    case MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING:
    case MOVEMENT_TYPES.TREASURY:
      return isDetail ? "".concat(movementDetail.receiverAccount, " ").concat(partnerCurrency || currency) : movementDetail.receiverBankName;

    case MOVEMENT_TYPES.CURRENCY_EXCHANGE:
      return "".concat(movementDetail.altaCreditAccountIBAN, " ").concat(isDetail && partnerCurrency ? partnerCurrency || currency : '');

    default:
      return '';
  }
};
var getSenderCardHolder = function getSenderCardHolder(movementDetail, cards) {
  if (!movementDetail || isMovementCredit(movementDetail.id)) {
    return '';
  }

  var cardAccount = movementDetail.altaDebitAccountIBAN && cards && cards.find(function (_ref13) {
    var ibanAccount = _ref13.ibanAccount;
    return ibanAccount && ibanAccount.entityUniqueId === movementDetail.altaDebitAccountIBAN;
  });
  return cardAccount ? cardAccount.cardHolder : '';
};
var getReceiverCardHolder = function getReceiverCardHolder(movementDetail, cards) {
  if (!movementDetail || isMovementDebit(movementDetail.id)) {
    return '';
  }

  var cardAccount = movementDetail.altaCreditAccountIBAN && cards && cards.find(function (_ref14) {
    var ibanAccount = _ref14.ibanAccount;
    return ibanAccount && ibanAccount.entityUniqueId === movementDetail.altaCreditAccountIBAN;
  });
  return cardAccount ? cardAccount.cardHolder : '';
};
var showPositiveAmount = function showPositiveAmount(_ref15) {
  var id = _ref15.id;
  return isMovementCredit(id);
};

var getExchangeDetails = function getExchangeDetails(movementDetail, rates) {
  var amount = movementDetail.amount,
      _movementDetail$curre = movementDetail.currency;
  _movementDetail$curre = _movementDetail$curre === void 0 ? {} : _movementDetail$curre;
  var _movementDetail$curre2 = _movementDetail$curre.currencyID,
      ccy = _movementDetail$curre2 === void 0 ? '' : _movementDetail$curre2,
      partnerAmount = movementDetail.partnerAmount,
      _movementDetail$partn = movementDetail.partnerCurrency;
  _movementDetail$partn = _movementDetail$partn === void 0 ? {} : _movementDetail$partn;
  var _movementDetail$partn2 = _movementDetail$partn.currencyID,
      partnerCcy = _movementDetail$partn2 === void 0 ? '' : _movementDetail$partn2;
  return "".concat(amount).concat(ccy, " => ").concat(partnerAmount).concat(partnerCcy, ", ").concat(getMovementExchangeRate(movementDetail, rates));
};

var getDetails = function getDetails(movementDetail, rates) {
  if (!movementDetail) {
    return '';
  }

  switch (movementDetail.transactionTypeID) {
    case MOVEMENT_TYPES.CURRENCY_EXCHANGE:
      {
        return getExchangeDetails(movementDetail, rates);
      }

    case MOVEMENT_TYPES.LOAN_OPERATION:
    case MOVEMENT_TYPES.OTHER:
      {
        var exchangeDetails = movementDetail.rateItems ? "".concat(getExchangeDetails(movementDetail, rates), ", ") : '';
        return "".concat(exchangeDetails).concat(movementDetail.description);
      }

    default:
      return movementDetail.description;
  }
};
var getSenderName = function getSenderName(movementDetail, accounts, cards, deposits, accountTypes, productTypes, templates) {
  if (!movementDetail) {
    return '';
  }

  switch (movementDetail.transactionTypeID) {
    case MOVEMENT_TYPES.PAYMENT_BY_CARD:
      return isMovementCredit(movementDetail.id) ? 'accounts.movement.purchaseByCard.label' : getDebitAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes);

    case MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING:
      return getNameFromTemplate(templates, movementDetail.altaDebitAccountIBAN) || movementDetail.senderName || getDebitAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes);

    case MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING:
      return getDebitAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes);

    case MOVEMENT_TYPES.CASH_OPERATION:
    case MOVEMENT_TYPES.FAST_MONEY_TRANSFER:
      return isMovementDebit(movementDetail.id) ? getDebitAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes) : 'accounts.movement.cashIn.label';

    case MOVEMENT_TYPES.DEPOSIT_OPERATION:
      return isMovementDebit(movementDetail.id) ? getDebitAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes) : 'accounts.movement.depositOperation.label';

    case MOVEMENT_TYPES.OTHER:
      return isMovementDebit(movementDetail.id) ? getDebitAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes) : 'accounts.movement.other.label';

    case MOVEMENT_TYPES.LOAN_OPERATION:
      return isMovementDebit(movementDetail.id) ? getDebitAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes) : 'accounts.movement.loan.label';

    default:
      return getDebitAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes);
  }
};
var getReceiverName = function getReceiverName(movementDetail, accounts, cards, deposits, accountTypes, productTypes, templates) {
  if (!movementDetail) {
    return '';
  }

  switch (movementDetail.transactionTypeID) {
    case MOVEMENT_TYPES.TREASURY:
      return movementDetail.receiverName;

    case MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING:
      return getNameFromTemplate(templates, movementDetail.altaCreditAccountIBAN) || movementDetail.receiverName || getCreditAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes);

    case MOVEMENT_TYPES.CASH_OPERATION:
    case MOVEMENT_TYPES.FAST_MONEY_TRANSFER:
      return isMovementDebit(movementDetail.id) ? 'accounts.movement.cashOut.label' : getCreditAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes);

    case MOVEMENT_TYPES.OTHER:
      return isMovementDebit(movementDetail.id) ? 'accounts.movement.other.label' : getCreditAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes);

    case MOVEMENT_TYPES.LOAN_OPERATION:
      return isMovementDebit(movementDetail.id) ? 'accounts.movement.loan.label' : getCreditAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes);

    case MOVEMENT_TYPES.PAYMENT_BY_CARD:
      return isMovementDebit(movementDetail.id) ? 'accounts.movement.purchaseByCard.label' : getCreditAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes);

    case MOVEMENT_TYPES.ATM_CASHOUT:
    case MOVEMENT_TYPES.SMS_CASHOUT:
      return 'accounts.movement.withdrawalByCard.label';

    case MOVEMENT_TYPES.FEE:
      return 'accounts.movement.commission.label';

    case MOVEMENT_TYPES.BILL_PAYMENT:
      {
        var providerName = path(['bpTransaction', 'providerCode', 'text'], movementDetail) || '';
        var serviceName = path(['bpTransaction', 'serviceCode', 'serviceNameLocalizations'], movementDetail) || '';

        if (!providerName && !serviceName) {
          return 'accounts.movement.generalBillPayment.label';
        }

        return serviceName ? [serviceName, providerName] : providerName;
      }

    default:
      return getCreditAccountName(movementDetail, accounts, cards, deposits, accountTypes, productTypes);
  }
};

var getAccountIconFromCardsAndDeposits = function getAccountIconFromCardsAndDeposits(iban, cards, deposits, SvgIcon) {
  var cardAccount = iban && cards && cards.find(function (_ref16) {
    var ibanAccount = _ref16.ibanAccount;
    return ibanAccount && ibanAccount.entityUniqueId === iban;
  });

  if (cardAccount) {
    var cardDesignPath = path(['cardDesign', 'picSmall'], cardAccount);

    if (cardDesignPath) {
      return SvgIcon(cardDesignPath);
    }
  }

  var depositAccount = iban && deposits && deposits.find(function (_ref17) {
    var depositAccount = _ref17.depositAccount;
    return depositAccount && depositAccount.ibanAccount === iban;
  });

  if (depositAccount) {
    var depositIconPath = path(['customProductIcon', 'icon'], depositAccount) || path(['productType', 'productIcon', 'icon'], depositAccount);

    if (depositIconPath) {
      return SvgIcon(depositIconPath);
    }
  }
};

var getAccountIcon = function getAccountIcon(accounts, cards, deposits, SvgIcon, accountIban, getAccountIconComponent) {
  if (!getAccountIconComponent) {
    return getAccountIconFromCardsAndDeposits(accountIban, cards, deposits, SvgIcon);
  }

  if (!Array.isArray(accounts)) {
    return null;
  }

  var account = accounts === null || accounts === void 0 ? void 0 : accounts.find(function (_ref18) {
    var iban = _ref18.iban;
    return iban === accountIban;
  });
  return account ? getAccountIconComponent(account) : null;
};

var getIcon = function getIcon(movementDetail, SvgIcon, getAccountIconComponent, accounts, cards, deposits, _ref19, iban, otherSideIcon) {
  var mineIconPath = _ref19.mineIconPath,
      isOtherSideIconForCredit = _ref19.isOtherSideIconForCredit;
  var showOtherSideIcon = isMovementCredit(movementDetail.id);

  if (!isOtherSideIconForCredit) {
    showOtherSideIcon = !showOtherSideIcon;
  }

  if (otherSideIcon && showOtherSideIcon) {
    return otherSideIcon;
  }

  var mineIcon = path(mineIconPath, movementDetail);
  var mineIconSvg = mineIcon && SvgIcon(mineIcon);
  var account = showOtherSideIcon ? 'partnerClientAccount' : 'clientAccount';
  var defaultIcon = path([account, 'accountType', 'defaultAccountIcon', 'icon'], movementDetail);
  var defaultIconSvg = defaultIcon && SvgIcon(defaultIcon);
  return mineIconSvg || getAccountIcon(accounts, cards, deposits, SvgIcon, iban, getAccountIconComponent) || defaultIconSvg || null;
};

var getSenderIcon = function getSenderIcon(icons, movementDetail, accounts, cards, deposits, accountTypes, productTypes, lang, templates, getAccountIconComponent) {
  if (!movementDetail) {
    return '';
  }

  var getStandardSenderIcon = function getStandardSenderIcon(iconForCredit, iban) {
    return getIcon(movementDetail, icons.SvgIcon, getAccountIconComponent, accounts, cards, deposits, {
      mineIconPath: ['sender', 'icon'],
      isOtherSideIconForCredit: true
    }, iban || movementDetail.altaDebitAccountIBAN, iconForCredit);
  };

  switch (movementDetail.transactionTypeID) {
    case 'TCPaymentByCard':
      return getStandardSenderIcon(icons.IcPurchaseByCard);

    case 'TCOtherAccountIncoming':
      {
        var reference = path(['additionalAccountInfo', 'pictureReference'], movementDetail);
        var nameMultiLang = getSenderName(movementDetail, accounts, cards, deposits, accountTypes, productTypes, templates);
        var abbr = firstTwoInitials(getLanguageText(nameMultiLang, null, lang), false);

        if (reference) {
          var icon = composeUrlByReference(reference);
          return icons.HolderImage(icon, abbr);
        }

        return icons.UserIcon(abbr);
      }

    case 'TCLoanOperation':
      return getStandardSenderIcon(icons.IcLoanOperations);

    case 'TCCashOperation':
    case 'TCFastMoneyTransfer':
      return getStandardSenderIcon(icons.IcCashOperations);

    case 'TCDepositOperation':
      return getStandardSenderIcon(icons.IcDepositOperations);

    case 'TCOther':
      return getStandardSenderIcon(icons.IcPaymentOther, movementDetail.altaDebitAccountIBAN);

    case 'TCOwnAccount':
    case 'TCBillPayment':
    default:
      return getStandardSenderIcon();
  }
};
var getReceiverIcon = function getReceiverIcon(icons, movementDetail, accounts, cards, deposits, accountTypes, productTypes, lang, templates, getAccountIconComponent) {
  if (!movementDetail) {
    return null;
  }

  var getStandardReceiverIcon = function getStandardReceiverIcon(iconForDebit) {
    return getIcon(movementDetail, icons.SvgIcon, getAccountIconComponent, accounts, cards, deposits, {
      mineIconPath: ['receiver', 'icon'],
      isOtherSideIconForCredit: false
    }, movementDetail.altaCreditAccountIBAN, iconForDebit);
  };

  switch (movementDetail.transactionTypeID) {
    case 'TCOtherAccountOutgoing':
      {
        var reference = path(['additionalAccountInfo', 'pictureReference'], movementDetail);
        var sourceTemplate = movementDetail.sourceTemplate;
        var name = getReceiverName(movementDetail, accounts, cards, deposits, accountTypes, productTypes, templates);
        var abbr = firstTwoInitials((name === null || name === void 0 ? void 0 : name[lang]) || name, false);

        if (reference) {
          var icon = composeUrlByReference(reference);
          return icons.HolderImage(icon, abbr);
        }

        if (sourceTemplate) {
          var _icon = composeUrlByTemplateId(sourceTemplate);

          return icons.HolderImage(_icon, abbr);
        }

        return icons.UserIcon(abbr);
      }

    case 'TCTreasury':
      return icons.IcTreasury;

    case 'TCATMCashout':
    case 'TCSMSCashout':
      return icons.IcAtm;

    case 'TCPaymentByCard':
      return getStandardReceiverIcon(icons.IcPurchaseByCard);

    case 'TCFee':
      return icons.IcFee;

    case 'TCCashOperation':
    case 'TCFastMoneyTransfer':
      return getStandardReceiverIcon(icons.IcCashOperations);

    case 'TCLoanOperation':
      return getStandardReceiverIcon(icons.IcLoanOperations);

    case 'TCBillPayment':
      {
        var providerName = path(['bpTransaction', 'providerName'], movementDetail);
        var serviceName = path(['bpTransaction', 'serviceName'], movementDetail);
        var providerIconUri = path(['bpTransaction', 'providerCode', 'providerIconUri'], movementDetail);
        var serviceIconUri = path(['bpTransaction', 'serviceCode', 'serviceIconUri'], movementDetail);

        if (serviceName) {
          return icons.SvgIcon(serviceIconUri || providerIconUri);
        }

        if (providerName) {
          return icons.SvgIcon(providerIconUri);
        }

        return icons.IcBillPayment;
      }

    case 'TCOther':
      return getStandardReceiverIcon(icons.IcPaymentOther);

    case 'TCDepositOperation':
    case 'TCOwnAccount':
    default:
      return getStandardReceiverIcon();
  }
};
var mapTemplateInitialValues = function mapTemplateInitialValues(movementDetail, accounts, cards, deposits, accountTypes, productTypes, currentLang, templates, instruction) {
  var _ref20 = movementDetail || {},
      altaCreditAccountIBAN = _ref20.altaCreditAccountIBAN,
      receiverBankCode = _ref20.receiverBankCode,
      amount = _ref20.amount,
      currency = _ref20.currency,
      description = _ref20.description,
      additionalInformation = _ref20.additionalInformation,
      client = _ref20.client;

  var receiverMultiLangName = getReceiverName(movementDetail, accounts, cards, deposits, accountTypes, productTypes, templates);
  var receiverName = getLanguageText(receiverMultiLangName, null, currentLang);
  var defaultTemplateValues = {
    templateName: receiverName,
    beneficiaryName: receiverName === null || receiverName === void 0 ? void 0 : receiverName.substring(0, 70),
    beneficiaryAccount: altaCreditAccountIBAN,
    beneficiaryBankCode: receiverBankCode,
    amount: amount,
    ccy: currency && currency.currencyID,
    purpose: description,
    extrInfo: additionalInformation,
    customerId: client
  };

  switch (movementDetail.transactionTypeID) {
    case MOVEMENT_TYPES.BILL_PAYMENT:
      {
        var _ref21 = movementDetail || {},
            bpTransaction = _ref21.bpTransaction;

        var _ref22 = bpTransaction || {},
            providerName = _ref22.providerName,
            serviceName = _ref22.serviceName,
            customerIdentificationCode = _ref22.customerIdentificationCode;

        return {
          templateName: "".concat(serviceName ? "".concat(serviceName, " ") : '').concat(providerName || '').substring(0, 30),
          templateIcon: '',
          serviceType: movementDetail.serviceType || '',
          service: path(['bpTransaction', 'serviceCode', 'entityUniqueId'], movementDetail) || '',
          subscriberId: customerIdentificationCode || ''
        };
      }

    case MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING:
      {
        var receiverAccount = movementDetail.receiverAccount,
            additionalAccountInfo = movementDetail.additionalAccountInfo;
        return _objectSpread(_objectSpread({}, defaultTemplateValues), {}, {
          beneficiaryAccount: receiverAccount,
          pictureReference: additionalAccountInfo === null || additionalAccountInfo === void 0 ? void 0 : additionalAccountInfo.pictureReference
        });
      }

    case MOVEMENT_TYPES.TREASURY:
      {
        var _instruction$instruct5, _instruction$instruct6, _instruction$instruct7, _instruction$instruct8;

        var payerName = movementDetail.payerName,
            payerTaxId = movementDetail.payerId,
            _receiverName = movementDetail.receiverName,
            treasuryCode = movementDetail.treasuryCode,
            _receiverAccount = movementDetail.receiverAccount;
        return _objectSpread(_objectSpread({}, defaultTemplateValues), {}, {
          payerName: payerName,
          payerTaxId: payerTaxId,
          treasuryCode: treasuryCode || (instruction === null || instruction === void 0 ? void 0 : (_instruction$instruct5 = instruction.instructionItems) === null || _instruction$instruct5 === void 0 ? void 0 : (_instruction$instruct6 = _instruction$instruct5.data) === null || _instruction$instruct6 === void 0 ? void 0 : (_instruction$instruct7 = _instruction$instruct6[0]) === null || _instruction$instruct7 === void 0 ? void 0 : (_instruction$instruct8 = _instruction$instruct7.jsonData) === null || _instruction$instruct8 === void 0 ? void 0 : _instruction$instruct8.treasuryCode),
          templateName: _receiverAccount,
          beneficiaryName: _receiverName === null || _receiverName === void 0 ? void 0 : _receiverName.substring(0, 50)
        });
      }

    default:
      {
        return defaultTemplateValues;
      }
  }
};
var getTemplateType = function getTemplateType(transactionTypeID) {
  switch (transactionTypeID) {
    case MOVEMENT_TYPES.TREASURY:
      return TEMPLATE_PAYMENT_TYPES.TREASURY;

    case MOVEMENT_TYPES.BILL_PAYMENT:
      return BILL_PAYMENT_TEMPLATE_TYPE;

    default:
      return TEMPLATE_PAYMENT_TYPES.TRANSFERTOSOMEONEELSE;
  }
};
var filterMoreValues = function filterMoreValues(values) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  var valueKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
  var filteredValues = values;

  if (values.some(function (_ref23) {
    var checked = _ref23.checked;
    return checked;
  })) {
    // WEB has different filter structure than GMB
    filteredValues = values.filter(function (_ref24) {
      var checked = _ref24.checked;
      return checked;
    });
  }

  if (valueKey) {
    return flatten(filteredValues.map(function (value) {
      return value[valueKey];
    }));
  }

  return flatten(filteredValues.map(function (_ref25) {
    var value = _ref25.value;
    return key ? value ? value[key] : undefined : value;
  }));
};
var shouldLoadInstruction = function shouldLoadInstruction(transactionTypeID, treasuryCode) {
  return LOAD_INSTRUCTION_TYPES.includes(transactionTypeID) && !(transactionTypeID === MOVEMENT_TYPES.TREASURY && treasuryCode);
};
var getReplyNavigationProps = function getReplyNavigationProps(movement, cards, deposits, currentLang, templates) {
  var beneficiaryNameMultiLang = getSenderName(movement, null, cards, deposits, null, null, templates);
  return {
    filter: _objectSpread(_objectSpread({}, movement), {}, {
      beneficiaryName: getLanguageText(beneficiaryNameMultiLang, null, currentLang),
      debitAccount: parseInt(path(['clientAccount', 'entityUniqueId'], movement)),
      beneficiaryAccount: movement.altaDebitAccountIBAN,
      ccy: movement.currency,
      purpose: movement.description
    })
  };
};

export { SHOW_ADDITIONAL_INFORMATION, SHOW_BANK_CODE_RECEIVER, SHOW_BANK_CODE_SENDER, SHOW_BANK_NAME_RECEIVER, SHOW_BANK_NAME_SENDER, SHOW_CARD_HOLDER, SHOW_REPEAT_PAYMENT_LEFT, SHOW_REPLY_PAYMENT, filterMoreValues, getDetails, getMovementExchangeRate, getMovementExportTemplate, getNameFromTemplate, getReceiverCardHolder, getReceiverDetailBankCode, getReceiverIban, getReceiverIcon, getReceiverName, getReplyNavigationProps, getSenderCardHolder, getSenderDetailBankCode, getSenderIban, getSenderIcon, getSenderName, getTemplateType, isMovementCredit, isMovementDebit, mapTemplateInitialValues, shouldLoadInstruction, showPositiveAmount, showRepeatPaymentRight, showSaveTemplateLeft };
