import { composeFragments, validationRule, maxLength, autocorrectingValidationRule } from 'core-ingredients';
import { fieldsFragment, fieldFragment } from 'core-recipes';
import { regexCharset } from 'vtb-utils';
import { requiredRule, charset } from '../../../utils/validators.js';

var batchNewConceptFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'conceptName',
  rules: [validationRule(maxLength(30)), validationRule(requiredRule), autocorrectingValidationRule(charset(regexCharset)), autocorrectingValidationRule(charset(/^\S/))]
}), fieldFragment({
  name: 'conceptDescription',
  rules: [validationRule(maxLength(140)), autocorrectingValidationRule(charset(regexCharset)), autocorrectingValidationRule(charset(/^\S/)), validationRule(requiredRule)]
}), fieldFragment({
  name: 'debitAccount',
  rules: [validationRule(requiredRule)]
}), fieldFragment({
  name: 'importFile'
})));

export { batchNewConceptFieldsFragment };
