var getProviderQuery = function getProviderQuery(providerCode) {
  var q = {
    BPProviders: ['nameOriginal', 'providerCode', 'providerIconUri', 'text', 'isActive', 'sortOrder', 'isActiveGemini', 'isLocalGlobal'],
    where: {
      isActiveGemini: 'true',
      isActive: 'true',
      isDeleted: 'false'
    },
    order: {
      sortOrder: 'asc'
    },
    limit: 1000
  };

  if (providerCode) {
    q.where['providerCode'] = [providerCode];
    q.limit = 1;
  }

  return q;
};

export { getProviderQuery };
