var getBPTemplatesQuery = function getBPTemplatesQuery() {
  var q = {
    BPTemplates: ['templateId', 'templateName', 'hasImage', {
      templateGroup: ['groupId', 'groupName', 'groupCategory', 'sortOrder', 'originatorUser']
    }, {
      templateType: ['billTypeKey', 'description']
    }, {
      service: ['serviceCode', 'serviceParameters', 'serviceIconUri', 'serviceNameLocalizations', 'certify', 'category', {
        provider: ['providerIconUri', 'text', 'providerCode']
      }]
    }, 'subscriberId', 'sortOrder', 'client', 'bornDate', 'lastUpdateDate'],
    where: {},
    order: {
      bornDate: 'desc'
    },
    limit: 1000
  };
  return q;
};

export { getBPTemplatesQuery };
