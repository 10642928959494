import _defineProperty from '@babel/runtime/helpers/defineProperty';
import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { DateTime, MOVEMENT_TYPES } from 'vtb-utils';
import { addMonths } from 'date-fns';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var movementsGraph = ['sourceTemplate', 'partnerName', 'payerName', 'payerId', 'id', 'transactionId', 'valueDate', 'description', 'additionalInformation', 'amount', 'chargeAmount', {
  movementType: ['transTypeID', 'text']
}, 'status', {
  currency: ['currencyID']
}, 'partnerAmount', {
  partnerCurrency: ['currencyID']
}, 'rateItems', 'rateAmount', 'senderName', 'senderBankCode', 'senderBankName', 'receiverName', 'receiverAccount', 'senderAccount', 'receiverBankName', 'receiverBankCode', 'partnerAccountIBAN', 'altaDebitAccountIBAN', 'altaCreditAccountIBAN', 'altaDebitCustomerId', 'transactionReference', 'altaCreditAccountName', {
  clientAccount: ['friendlyName', 'accountName', 'ibanAccount', {
    customProductIcon: ['icon']
  }, {
    accountType: ['accTypeId', 'text', {
      defaultAccountIcon: ['icon']
    }]
  }]
}, {
  partnerClientAccount: ['friendlyName', 'accountName', 'ibanAccount', {
    customProductIcon: ['icon']
  }, {
    accountType: ['accTypeId', 'text', {
      defaultAccountIcon: ['icon']
    }]
  }]
}, {
  bpTransaction: ['serviceName', 'providerName', 'customerIdentificationCode', {
    providerCode: ['providerIconUri', 'providerCode', 'text']
  }, {
    serviceCode: ['serviceIconUri', 'serviceCode', 'serviceNameLocalizations', {
      billType: ['billTypeKey']
    }]
  }]
}];
var getFixedMovementsFilter = function getFixedMovementsFilter(clientID) {
  return {
    'clientAccount.accountType.entityUniqueId': {
      inlist: ['32', '100', '101', '200']
    },
    allowedMovementTypes: [// Debit movements (directionDRCR === 1)
    {
      directionDRCR1: [{
        delimiter: 'AND'
      }, {
        'movementType.entityUniqueId': {
          inlist: [MOVEMENT_TYPES.DEPOSIT_OPERATION, MOVEMENT_TYPES.PAYMENT_BY_CARD, MOVEMENT_TYPES.LOAN_OPERATION, MOVEMENT_TYPES.SMS_CASHOUT, MOVEMENT_TYPES.OTHER, MOVEMENT_TYPES.BILL_PAYMENT, MOVEMENT_TYPES.ATM_CASHOUT, MOVEMENT_TYPES.FEE, MOVEMENT_TYPES.TREASURY, MOVEMENT_TYPES.CURRENCY_EXCHANGE, MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING]
        },
        directionDRCR: 1
      }]
    }, {
      TCOwnAccount: [{
        delimiter: 'AND'
      }, {
        'movementType.entityUniqueId': MOVEMENT_TYPES.OWN_ACCOUNT,
        directionDRCR: clientID ? 0 : 1
      }]
    }].concat(_toConsumableArray(clientID ? [{
      TCCashOperation_D: [{
        delimiter: 'AND'
      }, {
        'movementType.entityUniqueId': MOVEMENT_TYPES.CASH_OPERATION,
        altaCreditCustomer: {
          ne: clientID
        },
        directionDRCR: 1
      }]
    }] : []), _toConsumableArray(clientID ? [{
      TCCashOperation_DNull: [{
        delimiter: 'AND'
      }, {
        'movementType.entityUniqueId': MOVEMENT_TYPES.CASH_OPERATION,
        altaCreditCustomer: {
          isNull: true
        },
        directionDRCR: 1
      }]
    }] : []), _toConsumableArray(clientID ? [{
      TCFastMoneyTransfer_D: [{
        delimiter: 'AND'
      }, {
        'movementType.entityUniqueId': MOVEMENT_TYPES.FAST_MONEY_TRANSFER,
        altaCreditCustomer: clientID,
        directionDRCR: 1
      }]
    }] : []), _toConsumableArray(clientID ? [{
      directionDRCR0: [{
        delimiter: 'AND'
      }, {
        'movementType.entityUniqueId': {
          inlist: [MOVEMENT_TYPES.DEPOSIT_OPERATION, MOVEMENT_TYPES.PAYMENT_BY_CARD, MOVEMENT_TYPES.CASH_OPERATION, MOVEMENT_TYPES.FAST_MONEY_TRANSFER, MOVEMENT_TYPES.LOAN_OPERATION, MOVEMENT_TYPES.SMS_CASHOUT, MOVEMENT_TYPES.OTHER]
        },
        'partnerClientAccount.accountType.accTypeId': {
          inlist: ['1', '2', '4', '8', '16', '64', '128']
        },
        directionDRCR: 0
      }]
    }] : []), _toConsumableArray(clientID ? [{
      directionDRCR0Null: [{
        delimiter: 'AND'
      }, {
        'movementType.entityUniqueId': {
          inlist: [MOVEMENT_TYPES.DEPOSIT_OPERATION, MOVEMENT_TYPES.PAYMENT_BY_CARD, MOVEMENT_TYPES.CASH_OPERATION, MOVEMENT_TYPES.FAST_MONEY_TRANSFER, MOVEMENT_TYPES.LOAN_OPERATION, MOVEMENT_TYPES.SMS_CASHOUT, MOVEMENT_TYPES.OTHER]
        },
        'partnerClientAccount.accountType.accTypeId': {
          isNull: true
        },
        directionDRCR: 0
      }]
    }] : []), [{
      TCOtherAccountIncoming: [{
        delimiter: 'AND'
      }, {
        'movementType.entityUniqueId': MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING,
        directionDRCR: 0
      }]
    }])
  };
};
var getMovementsQuery = function getMovementsQuery(filter, paging, limit, currentClient) {
  var callAggregates = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var beforeMonth = DateTime.toISOString(addMonths(new Date(), -1));
  var defaultFilter = {
    valueDate: {
      gte: beforeMonth
    }
  };
  var aggregate = {
    amountDebit: {
      sum: []
    },
    amountDebitGEL: {
      sum: []
    },
    amountCredit: {
      sum: []
    },
    amountCreditGEL: {
      sum: []
    }
  };
  var query = {
    Movements: callAggregates ? {} : movementsGraph,
    aggregate: callAggregates ? aggregate : {},
    order: {
      valueDate: 'desc',
      transactionId: 'desc'
    },
    // limit: 100,
    limit: paging ? paging.limit : 30
  };

  if (paging && paging.after) {
    query.after = paging.after;
  }

  var customFilter = filter || defaultFilter;

  if (limit) {
    query.limit = limit;
  }

  query.where = _objectSpread(_objectSpread({}, getFixedMovementsFilter(currentClient === null || currentClient === void 0 ? void 0 : currentClient.clientID)), customFilter);
  return query;
};

export { getFixedMovementsFilter, getMovementsQuery, movementsGraph };
