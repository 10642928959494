var ACCOUNT_GROUP_ID = {
  DEBIT: 1,
  CREDIT: 2,
  CURRENT: 3
};
var ACCOUNT_VISIBILITY = {
  OWN_ACC_INDEBIT: 'visibilityOwnAccInDebit',
  OWN_ACC_INCREDIT: 'visibilityOwnAccInCredit',
  TREASURY_INDEBIT: 'visibilityTreasuryInDebit',
  BATCH_INDEBIT: 'visibilityBatchInDebit',
  BILL_PAYMENT_INDEBIT: 'visibilityBillPaymentInDebit',
  TO_OTHER_INDEBIT: 'visibilityToOtherInDebit',
  CUREXCH_INDEBIT: 'visibilityCurExchInDebit',
  CUREXCH_INCREDIT: 'visibilityCurExchInCredit'
};

export { ACCOUNT_GROUP_ID, ACCOUNT_VISIBILITY };
