import { composeFragments, pathFragment, action, stateFlag, state, resolveLogic, sagaifyLogic2 } from 'core-ingredients';
import { appInitFragment } from './initialization.fragment.js';
import { localeFragment } from './locale.fragment.js';

var appLogicSchema = composeFragments(pathFragment('app'), appInitFragment(), localeFragment(), action('setUserAgentInfo', function (userAgentInfo) {
  return {
    userAgentInfo: userAgentInfo
  };
}), action('setDesktopMode', function (isDesktopMode) {
  return {
    isDesktopMode: isDesktopMode
  };
}), stateFlag('wasFocused', ['focus'], [], false), state(['desktopMode', 'setDesktopMode'], function (_, _ref) {
  var isDesktopMode = _ref.isDesktopMode;
  return isDesktopMode;
}, false), state(['traceId', 'setTraceId'], function (_, _ref2) {
  var traceId = _ref2.traceId;
  return traceId;
}, null), state(['userAgentInfo', 'setUserAgentInfo'], function (_, _ref3) {
  var userAgentInfo = _ref3.userAgentInfo;
  return userAgentInfo;
}, null));
var logicAPI = resolveLogic(appLogicSchema);
var appLogic = logicAPI.logic;
var clientSystemSelector = appLogic.selectors.clientSystem;
var systemParamsSelector = appLogic.selectors.systemParams;
var traceIdSelector = appLogic.selectors.traceId;
var appSaga = sagaifyLogic2(appLogic);
var appReducer = appLogic.reducer;

export { appLogic, appReducer, appSaga, clientSystemSelector, systemParamsSelector, traceIdSelector };
