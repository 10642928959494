import { composeFragments, pathFragment, action, state, resolveLogic } from 'core-ingredients';
import { listFragment, withFieldsSelectors, withInitializeForm, withResetForm } from 'core-recipes';
import { signingRulesStepLimitsFieldsFragment } from './signing-rules-step-limits.fields.js';

var signingRulesStepLimitsLogicSchema = composeFragments(pathFragment('signingRules', 'stepLimits'), signingRulesStepLimitsFieldsFragment, listFragment({
  listName: 'currencies',
  entityName: 'Currencies',
  listQuery: {
    Currencies: ['currencyID'],
    where: {
      status: 'true',
      isDeleted: 'false',
      forSigningRules: 'true'
    },
    limit: 1000
  }
}), action('updateLimits', function (values) {
  return {
    values: values
  };
}), state(['limits', 'updateLimits'], function (_, _ref) {
  var values = _ref.values;
  return values;
}), withFieldsSelectors, withInitializeForm({}), withResetForm({}));
var signingRulesStepLimitsLogicAPI = resolveLogic(signingRulesStepLimitsLogicSchema);
var signingRulesStepLimitsLogic = signingRulesStepLimitsLogicAPI.logic;
var signingRulesStepLimitsReducer = signingRulesStepLimitsLogicAPI.reducer;
var signingRulesStepLimitsSaga = signingRulesStepLimitsLogicAPI.saga;

export { signingRulesStepLimitsLogic, signingRulesStepLimitsLogicAPI, signingRulesStepLimitsReducer, signingRulesStepLimitsSaga };
