import { takeLatest } from 'redux-saga/effects';
import { composeFragments, pathFragment, action, flow, resolveAction, state, resolveLogic } from 'core-ingredients';
import { fetchFragment } from 'core-recipes';
import { rolesBeforeQueryHandler, rolesAfterQueryHandler, setSignersFlow } from './signing-rules-add-role.flow.js';

var signingRulesAddRoleLogicSchema = composeFragments(pathFragment('signingRules', 'addRole'), fetchFragment({
  valueKey: 'roles',
  valueQuery: {
    roles: []
  },
  beforeQueryHandler: rolesBeforeQueryHandler,
  afterQueryHandler: rolesAfterQueryHandler,
  supplier: 'ibs'
}), action('setSigners'), flow('setSignersFlow', [resolveAction('setSigners'), takeLatest, setSignersFlow]), action('setRolesToAdd', function (rolesToAdd) {
  return {
    rolesToAdd: rolesToAdd
  };
}), state(['rolesToAdd', 'setRolesToAdd'], function (_, _ref) {
  var rolesToAdd = _ref.rolesToAdd;
  return rolesToAdd;
}));
var signingRulesAddRoleLogicAPI = resolveLogic(signingRulesAddRoleLogicSchema);
var signingRulesAddRoleLogic = signingRulesAddRoleLogicAPI.logic;
var signingRulesAddRoleReducer = signingRulesAddRoleLogicAPI.reducer;
var signingRulesAddRoleSaga = signingRulesAddRoleLogicAPI.saga;

export { signingRulesAddRoleLogic, signingRulesAddRoleLogicAPI, signingRulesAddRoleReducer, signingRulesAddRoleSaga };
