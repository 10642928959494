import _defineProperty from '@babel/runtime/helpers/defineProperty';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var templateQuery = function templateQuery(entity) {
  var _ref;

  return _ref = {}, _defineProperty(_ref, entity, ['templateId', 'templateName', 'amount', 'ccy', 'paymentType', 'hasImage', 'beneficiaryName', 'beneficiaryPhoneNumber', 'beneficiaryAccount', 'beneficiaryBankCode', 'extrInfo', 'purpose', 'treasuryCode', 'payerTaxId', 'payerName', 'bornDate']), _defineProperty(_ref, "limit", 1000), _ref;
};
var prepareTemplateFilter = function prepareTemplateFilter(_, _ref2) {
  var paymentType = _ref2.paymentType,
      currency = _ref2.currency,
      country = _ref2.country;
  var basicCondition = {
    beneficiaryAccount: country ? {
      like: "".concat(country, "%")
    } : undefined,
    'ccy.currencyID': currency
  };

  if (Array.isArray(paymentType)) {
    return {
      where: paymentType.length > 0 ? _objectSpread(_objectSpread({}, basicCondition), {}, {
        paymentType: {
          inlist: paymentType
        }
      }) : basicCondition
    };
  }

  return {
    where: _objectSpread(_objectSpread({}, basicCondition), {}, {
      paymentType: paymentType
    })
  };
};

export { prepareTemplateFilter, templateQuery };
