import { composeFragments, validationRule, required, minLengthValidation, maxLengthValidation, autocorrectingValidationRule } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withDependentFieldValidation } from 'core-recipes';
import { lowerCaseValidation, upperCaseValidation, digitValidation, validationPasswordFactory } from '../validation-utils.js';
import { PASSWORD_MIN_LENGTH, specialCharValidation, PASSWORD_MAX_LENGTH, charsetValidation } from '../../auth/password-validation.js';

/* eslint-disable no-useless-escape */
var changePasswordFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'currentPassword',
  rules: [validationRule(required), validationRule(minLengthValidation('minLength', 'security.error.passwordMinLength')(PASSWORD_MIN_LENGTH))]
}), fieldFragment({
  name: 'password',
  rules: [validationRule(required), validationRule(specialCharValidation), validationRule(lowerCaseValidation), validationRule(upperCaseValidation), validationRule(digitValidation), validationRule(minLengthValidation('minLength', 'security.error.passwordMinLength')(PASSWORD_MIN_LENGTH)), validationRule(maxLengthValidation('maxLength', 'security.error.passwordMaxLength')(PASSWORD_MAX_LENGTH)), validationRule(charsetValidation('security.error.passwordInput.notAllowedCharacters')), autocorrectingValidationRule(maxLengthValidation('maxLength', 'security.error.passwordMaxLength')(PASSWORD_MAX_LENGTH))]
}), fieldFragment({
  name: 'repeatPassword',
  rules: [validationRule(required)]
}, withDependentFieldValidation({
  fieldName: 'password',
  validation: validationPasswordFactory,
  code: 'passwordEquality',
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      password: selectors.password,
      repeatPassword: selectors.repeatPassword
    };
  }
}))));

export { changePasswordFieldsFragment };
