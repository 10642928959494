import { eventChannel } from 'redux-saga'

export const app = {
  stateChannel: () =>
    eventChannel((emitter) => {
      const appStateHandler = () => {
        emitter(document.visibilityState === 'visible' ? 'active' : 'background')
      }

      const appHiddenHandler = () => {
        emitter('background')
      }

      document.addEventListener('visibilitychange', appStateHandler)
      window.addEventListener('pagehide', appHiddenHandler)

      return () => {
        document.removeEventListener('visibilitychange', appStateHandler)
        window.removeEventListener('pagehide', appHiddenHandler)
      }
    }),
}
