import { takeLatest } from 'redux-saga/effects';
import { resolveLogic, composeFragments, pathFragment, stateFlag, action, state, flow, resolveAction } from 'core-ingredients';
import { withVTBCertificationFragment } from '../../../../common/withVTBCertificationFragment.js';
import { verifyAvailableBalanceFragment } from '../../../../common/helpers.js';
import { instructionIdsFragment } from '../../../../common/instruction-logic.js';
import { soFragment } from '../common/so-formbasic-fragment.js';
import { moveInstructionToEditFragment } from '../../../../../tpm/moveInstructionToEditFragment.js';
import { createSoFlow } from './so-to-own-account.flow.js';
import { BOASoAmountFieldFragment } from './fields.fragment.js';
import { validateAmountFlow } from './validateAmountFlow.js';
import { combinedBOAFragment } from '../../../../between-own-accounts/boa.logic.js';

var soToOwnAccountLogicAPI = resolveLogic(composeFragments(pathFragment('so', 'ownaccount'), combinedBOAFragment(BOASoAmountFieldFragment, true), stateFlag('fullAmount', ['fullAmountOn'], ['fullAmountOff'], null), // default state is null, not false
withVTBCertificationFragment, verifyAvailableBalanceFragment, instructionIdsFragment, moveInstructionToEditFragment, soFragment, action('saveTemplateUse', function (values) {
  return {
    values: values
  };
}), action('validateAmountValue'), state(['certifiable', 'certificationSubmited'], function (_, _ref) {
  var certifiable = _ref.certifiable;
  return certifiable;
}, false), flow('createSo', [resolveAction('initSoForm'), takeLatest, createSoFlow]), flow('validateAmountFlow', [resolveAction('initializeForm'), takeLatest, validateAmountFlow])));
var soToOwnAccountLogic = soToOwnAccountLogicAPI.logic;
var soToOwnAccountReducer = soToOwnAccountLogicAPI.reducer;
var soToOwnAccountSaga = soToOwnAccountLogicAPI.saga;

export { soToOwnAccountLogic, soToOwnAccountReducer, soToOwnAccountSaga };
