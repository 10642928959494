var MOVEMENT_TYPES = {
  OWN_ACCOUNT: 'TCOwnAccount',
  OTHER_ACCOUNT_OUTGOING: 'TCOtherAccountOutgoing',
  TREASURY: 'TCTreasury',
  CURRENCY_EXCHANGE: 'TCCurrencyExchange',
  BILL_PAYMENT: 'TCBillPayment',
  PAYMENT_BY_CARD: 'TCPaymentByCard',
  OTHER_ACCOUNT_INCOMING: 'TCOtherAccountIncoming',
  FEE: 'TCFee',
  CASH_OPERATION: 'TCCashOperation',
  ATM_CASHOUT: 'TCATMCashout',
  FAST_MONEY_TRANSFER: 'TCFastMoneyTransfer',
  SMS_CASHOUT: 'TCSMSCashout',
  LOAN_OPERATION: 'TCLoanOperation',
  DEPOSIT_OPERATION: 'TCDepositOperation',
  OTHER: 'TCOther'
};

export { MOVEMENT_TYPES };
