import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeLatest } from 'redux-saga/effects';
import { composeFragments, flow, resolveAction, action, state, stateFlag, extendInitialState, resolveLogic, pathFragment } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { wizardFragment } from '../../generic/wizard.fragment.js';
import { transferFragment } from '../common/transfer/transfer.fragment.js';
import { TRANSFERS_STEPS } from '../constants.js';
import { decidePaymentWithoutBalanceCheckFragment } from '../common/decidePaymentWithoutBalanceCheckFragment.js';
import { initializeFlow, transferBOACombosFragment } from './boa.combos.fragment.js';
import { transferBOAFieldsFragment, BOAAmountFieldFragment } from './fields.fragment.js';
import { finishTransferFlow } from './flows/between-own-accounts-finish-transfer.flow.js';
import { postTransferFlow } from './flows/between-own-accounts-post-transfer.flow.js';
import { resetTransferFlow } from './flows/between-own-accounts-reset-transfer.flow.js';
import { updateTransferFlow } from './flows/between-own-accounts-update-transfer.flow.js';
import { recalculateBOAFee } from './flows/between-own-accounts-recalculate-fee.flow.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var initialState = {
  instruction: null,
  transfer: {
    customerId: null,
    debitAccount: null,
    creditAccount: null,
    amount: null,
    ccy: null,
    purpose: 'payOA.defpurpose',
    extrInfo: '',
    date: '',
    transactionReference: '-',
    channelId: null,
    paymentsFee: ''
  },
  isFormSubmited: false,
  serverErrors: []
};
var submitBOATransferFragment = composeFragments(flow('postTransfer', [resolveAction('postTransfer'), takeLatest, postTransferFlow]), flow('confirm', [resolveAction('confirm'), takeLatest, finishTransferFlow]));
var basicBOAFragment = composeFragments(action('postTransfer', function (transfer) {
  return {
    transfer: transfer
  };
}), action('reset', function () {
  return {
    initialState: initialState.transfer
  };
}), action('recalculateFee'), state(['transfer', 'postTransfer'], function (_, _ref) {
  var transfer = _ref.transfer;
  return transfer;
}), state(['transfer', 'transferUpdated'], function (_, _ref2) {
  var transfer = _ref2.transfer;
  return transfer;
}), state(['transfer', 'reset'], function () {
  return initialState.transfer;
}), state(['paymentsFee', 'resetForm'], function () {
  return '';
}), stateFlag('paymentsFeeFailed', ['feeLoadFailed'], ['feeLoaded', 'initializeForm', 'resetForm']), stateFlag('isTransferHeaderOpen', ['openTransferHeader'], ['closeTransferHeader', 'reset', 'resetForm']), flow('recalculateFee', [resolveAction('recalculateFee'), takeLatest, recalculateBOAFee]), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, initializeFlow]), flow('updateTransfer', [resolveAction('updateTransfer'), takeLatest, updateTransferFlow]), flow('reset', [resolveAction('reset'), takeLatest, resetTransferFlow]), extendInitialState(null, _objectSpread({}, initialState)));
var combinedBOAFragment = function combinedBOAFragment(amountFieldFragment) {
  var isSO = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return composeFragments(decidePaymentWithoutBalanceCheckFragment, transferBOAFieldsFragment({}, amountFieldFragment, isSO), withResetForm({}), withInitializeForm({}), withFieldsSelectors, wizardFragment(TRANSFERS_STEPS), transferFragment({}), basicBOAFragment, submitBOATransferFragment, transferBOACombosFragment(isSO));
};
var transferBOALogicAPI = resolveLogic(composeFragments(pathFragment('transfers', 'boa'), combinedBOAFragment(BOAAmountFieldFragment)));
var transferBOALogic = transferBOALogicAPI.logic;
var transferBOAReducer = transferBOALogicAPI.reducer;
var transferBOASaga = transferBOALogicAPI.saga;

export { combinedBOAFragment, initialState, submitBOATransferFragment, transferBOALogic, transferBOAReducer, transferBOASaga };
