import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { PAGE_SIZE } from 'vtb-utils';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var batchConceptsQuery = {
  BatchConcepts: ['id', 'conceptName', 'conceptDescription', 'conceptType', 'numberOfPayments', 'totalAmount', 'sortingOrder', 'unsavedItemExist', 'debitAccount', 'isSaved'],
  order: {
    bornDate: 'asc',
    sortingOrder: 'desc'
  },
  limit: 10000
};
var batchConceptItemQuery = {
  BatchConceptItems: ['paymentId', 'paymentType', {
    batchConcept: ['conceptName', 'conceptType']
  }, 'beneficiaryAccount', {
    beneficiaryBank: ['id', 'text']
  }, 'beneficiaryName', 'amount', 'ccy', 'purpose', 'extrInfo', 'payerName', 'payerTaxId', 'treasuryCode', 'status', 'isSaved', 'isSimilar', 'errors']
};
var batchConceptItemsQuery = _objectSpread(_objectSpread({}, batchConceptItemQuery), {}, {
  order: {
    status: 'asc',
    isSaved: 'asc',
    bornDate: 'desc'
  },
  aggregate: {
    entityUniqueId: 'count'
  },
  limit: PAGE_SIZE
});

export { batchConceptItemQuery, batchConceptItemsQuery, batchConceptsQuery };
