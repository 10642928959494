import { DateTime, parseDateFromVtbFormat } from 'vtb-utils';

var isVtbDateAfterOrEqual = function isVtbDateAfterOrEqual(vtbFormattedDate) {
  var date = parseDateFromVtbFormat(vtbFormattedDate);
  return DateTime.isAfterOrEqual(new Date(), date);
};
var getCarGetDebtIdentifiers = function getCarGetDebtIdentifiers(template, service) {
  var customerData = "".concat(template.carNumber, "/").concat(template.carOwnerIdNumber);
  var serviceCode = service.serviceCode;
  return {
    customerData: customerData,
    serviceCode: serviceCode
  };
};

export { getCarGetDebtIdentifiers, isVtbDateAfterOrEqual };
