import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeLatest } from 'redux-saga/effects';
import { composeFragments, action, state, stateFlag, flow, resolveAction } from 'core-ingredients';
import { catalogFragment, listFragment, configResetListFragment } from 'core-recipes';
import { getAccountQuery, getStandingOrderQuery } from '../common/so-query.js';
import { afterStandingOrderLoadedFlow } from './after-standing-order-loaded.flow.js';
import { initFlow } from './init.flow.js';
import { deleteStandingOrderFlow } from './delete-standing-order.flow.js';
import { mapAccountsFlow } from './so-accounts.flow.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var configAccountListFragment = function configAccountListFragment(listName) {
  return {
    listName: listName,
    entityName: 'Accounts',
    listQuery: _objectSpread({}, getAccountQuery()),
    afterQueryHandler: mapAccountsFlow(listName)
  };
};

var soDetailFragment = composeFragments(catalogFragment({
  name: 'standingOrderTypes',
  querySchema: {
    StandingOrderTypes: [],
    limit: 1000
  }
}), listFragment({
  listName: 'standingOrder',
  entityName: 'StandingOrders',
  listQuery: _objectSpread({}, getStandingOrderQuery()),
  afterQueryHandler: afterStandingOrderLoadedFlow
}, configResetListFragment({})), listFragment(configAccountListFragment('debitAccounts'), configResetListFragment({})), listFragment(configAccountListFragment('creditAccounts'), configResetListFragment({})), listFragment(configAccountListFragment('collectionAccount'), configResetListFragment({})), action('init'), action('deleteStandingOrder'), action('deleteFailed'), action('accountsMapped'), state(['bankInfo', 'bankLoaded'], function (_, _ref) {
  var bank = _ref.bank;
  return bank;
}), state(['bankInfo', 'reset'], function () {
  return null;
}), stateFlag('isLoading', ['init'], ['loadingDone', 'loadingFailed'], true), stateFlag('deleteSoSuccessful', ['deleteSOSuccess'], ['deleteSOFailed'], true), stateFlag('isConfirmDeleteModalOpen', ['openConfirmDeleteModal'], ['failed', 'reset', 'resetForm', 'closeDeleteModal'], false), flow('deleteStandingOrder', [resolveAction('deleteStandingOrder'), takeLatest, deleteStandingOrderFlow]), flow('initFlow', [resolveAction('init'), takeLatest, initFlow]));

export { soDetailFragment };
