import { takeLatest } from 'redux-saga/effects';
import { composeFragments, action, stateFlag, state, selector, flow, resolveAction } from 'core-ingredients';
import { commonTemplateFragment } from '../common-template-forms.fragment.js';
import { bpSaveAsTemplateProcessFlow } from './bill-payment-save-as-template.flow.js';

var bpSaveAsTemplateFragment = composeFragments(commonTemplateFragment, action('saveOnlyTemplate', function (mobile, onSuccess) {
  return {
    mobile: mobile,
    onSuccess: onSuccess
  };
}), stateFlag('savingTemplate', ['saveOnlyTemplate'], ['setTemplateSaved', 'stopSaving', 'failed', 'tmpFailed', 'reset', 'resetForm', 'initializeForm'], false), stateFlag('templateSaved', ['setTemplateSaved'], ['saveOnlyTemplate', 'failed', 'reset', 'resetForm'], false), state(['savedTemplate', 'setTemplateSaved'], function (_, _ref) {
  var template = _ref.template;
  return template || null;
}, null), selector('loading', ['groupsLoading', 'submitting'], function (groupsLoading, submitting) {
  return groupsLoading || submitting;
}), flow('initializeSaveAsTemplateForm', [resolveAction('initializeForm'), takeLatest, bpSaveAsTemplateProcessFlow]));

export { bpSaveAsTemplateFragment };
