import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { MOVEMENT_TYPES } from 'vtb-utils';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var allowedMovementTypes = [MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING, MOVEMENT_TYPES.TREASURY, MOVEMENT_TYPES.OWN_ACCOUNT, MOVEMENT_TYPES.CURRENCY_EXCHANGE];
var ccPaymentsFilterAndLimit = {
  where: {
    'ccPaymentDecision.userDecisionTime': {
      isNull: true
    },
    'movementType.transTypeID': {
      inList: allowedMovementTypes
    }
  },
  limit: 1000
};
var ccPaymentsQuery = _objectSpread({
  CCPayments: ['transactionId', 'valueDate', {
    clientAccount: ['cbsID', 'ibanAccount', 'friendlyName', {
      balance: ['balance']
    }, {
      accountType: ['text']
    }, {
      currency: ['currencyID']
    }]
  }, {
    partnerClientAccount: ['cbsID', 'ibanAccount', 'friendlyName', {
      balance: ['balance']
    }, {
      accountType: ['text']
    }, {
      currency: ['currencyID']
    }]
  }, {
    movementType: ['transTypeID']
  }, 'receiverAccount', 'receiverName', {
    partnerClientAccount: ['friendlyName', {
      accountType: ['text']
    }]
  }, 'description', 'amount', 'partnerAmount', 'chargeAmount', 'altaDebitAccountId', 'altaDebitCustomerId', 'altaCreditAccountIBAN', 'intermediateBankCode', 'intermediateBankName', 'debitExtraInformation', 'rateItems', 'rateAmount', 'payerId', 'payerName', {
    partnerCurrency: ['currencyID']
  }, {
    currency: ['currencyID']
  }],
  order: {
    transactionId: 'desc'
  }
}, ccPaymentsFilterAndLimit);

export { ccPaymentsFilterAndLimit, ccPaymentsQuery };
