import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { validation } from 'core-ingredients';
export { autocorrectingValidationRule, maxLength, required, validationRule } from 'core-ingredients';
import { format, isAfter, isSameDay, parse, isBefore } from 'date-fns';
import moment from 'moment';
import { is, isEmpty } from 'ramda';

var isNumber = validation('isNumber', function (value) {
  return value && is(Number, value);
});
var minAmount = function minAmount(amount) {
  var defaultCorrection = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return validation('minAmount', function (value) {
    if (amount && value) {
      var parsedValue = is(Number, value) ? value : Number(value === null || value === void 0 ? void 0 : value.replace(',', '.'));
      return parsedValue >= amount;
    }

    return true;
  }, defaultCorrection || function () {
    return amount;
  }, 'common.validation.toSmall');
};
var minDate = function minDate(_minDate) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error.minDate';
  return validation('minDate', function (value) {
    if (_minDate && value) {
      var _value$toDate, _minDate$toDate;

      var formatedValue = format(moment.isMoment(value) ? value === null || value === void 0 ? void 0 : (_value$toDate = value.toDate) === null || _value$toDate === void 0 ? void 0 : _value$toDate.call(value) : value, 'YYYY-MM-DD');
      var formatedMinDate = format(moment.isMoment(_minDate) ? _minDate === null || _minDate === void 0 ? void 0 : (_minDate$toDate = _minDate.toDate) === null || _minDate$toDate === void 0 ? void 0 : _minDate$toDate.call(_minDate) : _minDate, 'YYYY-MM-DD');
      return isAfter(formatedValue, formatedMinDate) || isSameDay(formatedValue, formatedMinDate);
    }

    return true;
  }, function () {
    return _minDate;
  }, message);
};
var maxLengthAmount = function maxLengthAmount() {
  var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 5;
  return validation('maxLengthAmount', function (value) {
    if (!value) {
      return true;
    }

    var v = value === null || value === void 0 ? void 0 : value.toString();

    if (value && (v !== null && v !== void 0 && v.includes('.') || v !== null && v !== void 0 && v.includes(','))) {
      var _splitValue$;

      var separator = v.includes('.') ? '.' : ',';
      var splitValue = v.split(separator);
      return ((_splitValue$ = splitValue[0]) === null || _splitValue$ === void 0 ? void 0 : _splitValue$.length) <= length;
    }

    return (v === null || v === void 0 ? void 0 : v.length) <= length;
  }, // eslint-disable-next-line @typescript-eslint/no-empty-function
  function () {}, 'attribute.validator.length.exceeded');
};
var requiredRule = validation('required', function (value) {
  return value && !isEmpty(value);
}, null, 'validation.error.Empty');
var fakeValidation = validation('fakeValidation', function () {
  return true;
}, '');
/**
 * Charset validation, validator accepts regex, optional error message and value
 * @type {validationFn}
 */

var charset = function charset(regex) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'validation.error.charset';
  var correctingRule = arguments.length > 2 ? arguments[2] : undefined;
  return validation('charset', function (value) {
    return !value || regex.test(value);
  }, function (value) {
    return value.replace(correctingRule || /[^- a-zA-Z0-9/?\-:().,'+\u10d0\u10d1\u10d2\u10d3\u10d4\u10d5\u10d6\u10d7\u10d8\u10d9\u10da\u10db\u10dc\u10dd\u10de\u10df\u10e0\u10e1\u10e2\u10e3\u10e4\u10e5\u10e6\u10e7\u10e8\u10e9\u10ea\u10eb\u10ec\u10ed\u10ee\u10ef\u10f0]/g, '');
  }, message);
};
var getIsValidRegExpFromCharset = function getIsValidRegExpFromCharset(charset) {
  return new RegExp("^[".concat(charset, "]+$"));
};
var charsetWithAutoCorrect = function charsetWithAutoCorrect(allowedChars) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'validation.error.charset';
  var isValidRegexp = getIsValidRegExpFromCharset(allowedChars);
  var charsToReplaceRegexp = new RegExp("[^".concat(allowedChars, "]"), 'g');
  return charset(isValidRegexp, message, charsToReplaceRegexp);
};
var charsetWithoutAutoCorrect = function charsetWithoutAutoCorrect(regex) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'validation.error.charset';
  return validation('charset', function (value) {
    return !value || regex.test(value);
  }, function (value) {
    return value;
  }, message);
};
/**
 * Mask validation, validator accepts regex and value
 * @type {validationFn}
 */

var mask = function mask(_mask) {
  return validation('mask', function (value) {
    return !value || _mask.test(value);
  }, null, 'validation.error.mask');
};
var specificLengthValidation = function specificLengthValidation(lengthsArray, fieldName) {
  var message = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var errorCode = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'specificLength';
  return function (params) {
    var _ref = params || {},
        localizations = _ref.localizations;

    if (!message && !localizations) return fakeValidation;
    var composedMessage = message;

    if (!message) {
      var field = localizations[fieldName];
      var errorMessage = localizations['validation.error.specificLength'];
      var or = localizations['validation.error.specificLength.or'];
      var range = lengthsArray.reduce(function (acc, value, index) {
        return index === lengthsArray.length - 1 ? "".concat(acc).concat(value) : "".concat(acc).concat(value, " ").concat(or, " ");
      }, '');
      composedMessage = errorMessage && errorMessage.replace('{0}', field || 'Field').replace('{1}', range);
    }

    return validation(errorCode, function (value) {
      return lengthsArray && value ? _toConsumableArray(lengthsArray).includes(value.length) : true;
    }, function (value) {
      return value;
    }, composedMessage);
  };
};
var dateRangeValidation = function dateRangeValidation(params) {
  var _startDateValue$toDat, _endDateValue$toDate;

  var _ref2 = params || {},
      startDate = _ref2.startDate,
      endDate = _ref2.endDate;

  var startDateValue = startDate === null || startDate === void 0 ? void 0 : startDate.value;
  var endDateValue = endDate === null || endDate === void 0 ? void 0 : endDate.value;
  var formatedStartDate = format(parse(moment.isMoment(startDateValue) ? startDateValue === null || startDateValue === void 0 ? void 0 : (_startDateValue$toDat = startDateValue.toDate) === null || _startDateValue$toDat === void 0 ? void 0 : _startDateValue$toDat.call(startDateValue) : startDateValue), 'YYYY-MM-DD');
  var formatedEndDate = format(parse(moment.isMoment(endDateValue) ? endDateValue === null || endDateValue === void 0 ? void 0 : (_endDateValue$toDate = endDateValue.toDate) === null || _endDateValue$toDate === void 0 ? void 0 : _endDateValue$toDate.call(endDateValue) : endDateValue), 'YYYY-MM-DD');
  return validation('startDateEndDate', function () {
    return !startDateValue || !endDateValue ? true : isBefore(formatedStartDate, formatedEndDate);
  }, function (value) {
    return value;
  }, 'error.startDateEndDate');
};
var EMAIL_MAX_LENGTH = 50;

export { EMAIL_MAX_LENGTH, charset, charsetWithAutoCorrect, charsetWithoutAutoCorrect, dateRangeValidation, fakeValidation, getIsValidRegExpFromCharset, isNumber, mask, maxLengthAmount, minAmount, minDate, requiredRule, specificLengthValidation };
