import { composeFragments, pathFragment, resolveLogic } from 'core-ingredients';
import { lnPwdOtpFragment } from './ln-pwd-otp.fragment.js';

var lnPwdOtpSchema = composeFragments(pathFragment('ln_pwd_otp'), lnPwdOtpFragment());

var _resolveLogic = resolveLogic(lnPwdOtpSchema),
    logic = _resolveLogic.logic,
    reducer = _resolveLogic.reducer,
    saga = _resolveLogic.saga;

export { logic as lnPwdOtpLogic, reducer as lnPwdOtpReducer, saga as lnPwdOtpSaga };
