var billPaymentGroupsQuery = {
  BPTemplateGroups: ['groupName', 'groupId', 'sortOrder', {
    groupCategory: ['isActiveGemini', 'name', 'groupCategoryId', 'groupCategoryIconUri', 'sortOrder']
  }],
  where: {},
  order: {
    sortOrder: 'asc'
  },
  limit: 1000
};
var billPaymentGroupCategoriesQuery = {
  BPGroupCategories: ['groupCategoryIconUri', 'name', 'sortOrder', 'groupCategoryId'],
  where: {
    isActiveGemini: 'true',
    isDeleted: 'false'
  },
  order: {
    sortOrder: 'asc'
  },
  limit: 1000
};
var billPaymentTypesQuery = {
  BPBillTypes: ['billTypeKey', 'description'],
  limit: 1000
};

export { billPaymentGroupCategoriesQuery, billPaymentGroupsQuery, billPaymentTypesQuery };
