import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { buttons } from './buttons.js';
import { colors } from './colors.js';
import image from './image.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var breakpoints = [768].map(function (n) {
  return "".concat(n, "px");
});
var common = {
  fontSize: '15px'
};
var space = {
  // todo elementHeight should also have "px" but right now there are cases where
  // todo we're using e.g. elementHeight * 1.5
  // todo this should be later refactored by using more space constatns
  elementHeight: 40,
  elementBorderRadius: '5px',
  defaultSpace: '10px',
  smallElementHeight: 28,
  appWidth: 1360,
  mobileAppWidth: 320,
  contentIndent: 230,
  pageWidth: 1180,
  pageContentWidth: 1120,
  rowWidth: 1090,
  responsiveWidth: 290,
  filterWidth: [290, 545]
};
var theme = _objectSpread({
  colors: colors,
  image: image,
  space: space,
  buttons: buttons,
  breakpoints: breakpoints
}, common);

export { theme };
