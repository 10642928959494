var IOS_OS = 'ios';
var MAIN_CURRENCY = 'GEL';
var MINIMUM_SEARCH_LENGTH = 3;
var DECIMAL_SEPARATOR = '.';
var PASSCODE_MAX_LOGIN_ATTEMPS = 3;
var DEBOUNCE = 500;
var MODAL_TIMEOUT = 6000;
var PAGE_SIZE = 10;
var MAX_PAGE_SIZE = 2000;
var SORT = {
  ASC: 'asc',
  DESC: 'desc'
};
var DEVICE_REG_BLOCKED = 'register_BLOCKED';
var CLIENT_TYPES = {
  ORGANIZATION: 'Organization',
  INDIVIDUAL: 'Individual'
};
var OTP_EXPIRATION_SECONDS = 120000;
var METHODS = {
  card: 'CARD',
  email: 'EMAIL',
  sms: 'SMS'
};
var ACCEPT_EXCEL_FILES = '.xlsx, .xls, .csv';
var EXPORT_STATUSES = {
  FAILED: 'FAILED',
  FINISHED: 'FINISHED',
  PARTIAL: 'PARTIAL',
  PROCESSING: 'PROCESSING',
  IN_QUEUE: 'IN_QUEUE',
  SUSPENDED: 'SUSPENDED'
};
var MESSAGES_TIME_FORMAT = 'DD MMM YYYY HH:mm';
var PHONE_NUMBER_DOMESTIC_PREFIX = '+995';

export { ACCEPT_EXCEL_FILES, CLIENT_TYPES, DEBOUNCE, DECIMAL_SEPARATOR, DEVICE_REG_BLOCKED, EXPORT_STATUSES, IOS_OS, MAIN_CURRENCY, MAX_PAGE_SIZE, MESSAGES_TIME_FORMAT, METHODS, MINIMUM_SEARCH_LENGTH, MODAL_TIMEOUT, OTP_EXPIRATION_SECONDS, PAGE_SIZE, PASSCODE_MAX_LOGIN_ATTEMPS, PHONE_NUMBER_DOMESTIC_PREFIX, SORT };
