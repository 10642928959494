import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { takeLatest } from 'redux-saga/effects';
import { findIndex, propEq } from 'ramda';
import { composeFragments, action, state, stateFlag, addFlow, resolveAction, selector } from 'core-ingredients';
import { getCustDebt, getDebtParam, getPayServiceCode, getPayCustomerCode } from 'vtb-utils';
import { getDebtFlow, getCommissionFlow } from './debt-and-commission-flows.js';

var debtAndCommissionFragment = composeFragments(action('getDebt', function (customerData, serviceCode, channelId) {
  return {
    customerData: customerData,
    serviceCode: serviceCode,
    channelId: channelId
  };
}), action('getCommission', function (payload) {
  return {
    payload: payload
  };
}), action('commissionLoadError'), action('debtLoadError'), state(['debt', 'setDebt'], function (_, _ref) {
  var data = _ref.data;
  return data;
}, null), state(['commission', 'setCommission'], function (_, _ref2) {
  var commission = _ref2.commission;
  return commission;
}, null), state(['debt', 'reset'], function () {
  return null;
}), state(['debt', 'resetDebt'], function () {
  return null;
}), state(['commission', 'reset'], function () {
  return null;
}), state(['debtErrors', 'setDebtError'], function (errors, _ref3) {
  var errorCode = _ref3.errorCode,
      serviceCode = _ref3.serviceCode,
      customerData = _ref3.customerData,
      expirationDate = _ref3.expirationDate,
      renewalDate = _ref3.renewalDate;
  var newStateItem = {
    errorCode: errorCode,
    serviceCode: serviceCode,
    customerData: customerData,
    expirationDate: expirationDate,
    renewalDate: renewalDate
  };

  if (!errors) {
    return [newStateItem];
  }

  var index = findIndex(propEq('errorCode', errorCode))(errors);

  if (index > -1) {
    return _toConsumableArray(errors).splice(index, 1, newStateItem);
  }

  return [].concat(_toConsumableArray(errors), [newStateItem]);
}, null), state(['debtErrors', 'reset'], function () {
  return null;
}), state(['debtErrors', 'resetDebt'], function () {
  return null;
}), stateFlag('commissionLoading', ['getCommission'], ['setCommission', 'commissionLoadError', 'reset'], false), stateFlag('commissionLoadFailed', ['commissionLoadError'], ['setCommission', 'getCommission', 'reset'], false), stateFlag('debtLoading', ['getDebt'], ['setDebt', 'debtLoadError', 'reset', 'resetDebt'], false), stateFlag('debtLoadFailed', ['debtLoadError'], ['setDebt', 'getDebt', 'reset', 'resetDebt'], false), addFlow('getDebt', [resolveAction('getDebt'), takeLatest, getDebtFlow]), addFlow('getCommission', [resolveAction('getCommission'), takeLatest, getCommissionFlow]), selector('canOverride', 'debt', function (debtData) {
  var foundCustDebt = getCustDebt(debtData, 0);
  return foundCustDebt && foundCustDebt.canOverrideValue || false;
}), selector('debtAmount', 'debt', function (debtData) {
  var foundCustDebt = getDebtParam(debtData, 'CUST_DEBT');
  return foundCustDebt && Number(foundCustDebt) || 0;
}), selector('payServiceCode', 'debt', function (debtData) {
  return getPayServiceCode(debtData, 0);
}), selector('payCustomerCode', 'debt', function (debtData) {
  return getPayCustomerCode(debtData, 0);
}), selector('totalCommission', 'commission', function (commi) {
  return Number(commi && commi[0] && commi[0].totalCommissionAmount);
}), selector('debtDescription', 'debt', function (debtData) {
  if (debtData && debtData.debtInfo && debtData.debtInfo.length > 0) {
    return debtData.debtInfo[0].debtDescription;
  }

  return null;
}), selector('debtDataDescription', 'debt', function (debtData) {
  return getDebtParam(debtData, 'DEBT_DESCRIPTION');
}));

export { debtAndCommissionFragment };
