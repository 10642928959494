import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import _defineProperty from '@babel/runtime/helpers/defineProperty';
import _extends from '@babel/runtime/helpers/extends';
import { takeLatest } from 'redux-saga/effects';
import { resolveLogic, composeFragments, pathFragment, action, state, stateFlag, selector, flow, resolveAction } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors, listFragment } from 'core-recipes';
import { isCardOrCurrent, ACCOUNT_VISIBILITY } from 'vtb-utils';
import { wizardFragment } from '../../generic/wizard.fragment.js';
import { transferFragment } from '../common/transfer/transfer.fragment.js';
import { TRANSFERS_STEPS } from '../constants.js';
import { userHasNoAccountsSelector } from '../common/selectors.js';
import { useTemplateFragment } from '../../generic/statistics/use-template.fragment.js';
import { instructionIdsFragment } from '../common/instruction-logic.js';
import { withVTBCertificationFragment } from '../common/withVTBCertificationFragment.js';
import { verifyAvailableBalanceFragment } from '../common/helpers.js';
import { moveInstructionToEditFragment } from '../../tpm/moveInstructionToEditFragment.js';
import { decidePaymentWithoutBalanceCheckFragment } from '../common/decidePaymentWithoutBalanceCheckFragment.js';
import { sharedAccountsFragment } from '../common/shared-accounts/shared-accounts.fragment.js';
import { mapSharedAccountsFlow } from '../common/shared-accounts/map-shared-accounts.js';
import { transferToSomeoneElseFieldsFragment } from './someone-else.fields.js';
import { processToSomeoneElseFlow, initializeBeneficiaryFlow, validateBeneficiaryAddressFlow, resetDebitAccountFlow, recalculateFee, verifyInstructionTypeFlow } from './someone-else.flows.js';
import { errorsMerger } from '../../common/helpers.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var transferToSomeoneElseLogicAPI = resolveLogic(composeFragments(pathFragment('transfers', 'someoneElse'), decidePaymentWithoutBalanceCheckFragment, transferToSomeoneElseFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, useTemplateFragment, listFragment({
  entityName: 'countries',
  listQuery: {
    Countries: ['countryID', 'text'],
    limit: 1000
  }
}), listFragment({
  entityName: 'swiftCharges',
  listQuery: {
    SwiftCharges: ['code', 'cbsCode', 'description']
  }
}), sharedAccountsFragment({
  listName: 'accounts',
  afterQueryHandler: mapSharedAccountsFlow,
  filterCondition: isCardOrCurrent,
  visibilityKey: ACCOUNT_VISIBILITY.TO_OTHER_INDEBIT,
  instructionTypes: ['T:paymentInterBankFcy', 'T:paymentIntraBank', 'T:paymentInterBankLcy']
}), wizardFragment(TRANSFERS_STEPS), withVTBCertificationFragment, verifyAvailableBalanceFragment, instructionIdsFragment, moveInstructionToEditFragment, transferFragment({}), action('submit'), action('initialized'), action('saveTemplate'), action('initializeForm'), action('initializeBeneficiary'), action('recalculateFee'), action('setInstructionType'), action('validateBeneficiaryAddress'), action(['additionalAccountInfoLoaded'], function (data) {
  return {
    data: data
  };
}), state(['paymentsFee', 'feeLoaded'], function (_, _ref) {
  var value = _ref.value;
  return value;
}), state(['paymentsFee', 'resetForm'], function () {
  return null;
}), state(['tmpError', 'setTmpError'], function (_, _ref2) {
  var error = _ref2.error;
  return error || null;
}, null), state(['tmpError', 'resetForm'], function () {
  return null;
}), state(['tmpError', 'initializeForm'], function () {
  return null;
}), state(['tmpError', 'calling'], function () {
  return null;
}), stateFlag('paymentsFeeFailed', ['feeLoadFailed'], ['feeLoaded', 'initializeForm', 'resetForm']), state(['instructionType', 'setVerifiedInstructionType'], function (_, _ref3) {
  var value = _ref3.value;
  return value;
}, 'paymentInterBankLcy'), stateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm'], false), stateFlag('isTemplateInvalid', ['setTemplateInvalid'], ['toggleTemplateModal', 'reset', 'resetForm'], false), stateFlag('submitting', ['calling'], ['tmpSuccess', 'tmpFailed', 'failed', 'certificationSubmited', 'certified', 'stopCalling']), stateFlag('initializing', ['initializeForm'], ['initialized']), stateFlag('forceFilter', ['setForceFilter'], ['initializeForm', 'initialized', 'backToFirstStep', 'reset', 'resetForm'], false), stateFlag('beneficiaryAccountValidating', ['beneficiaryAccountValidationStarted'], ['patchBeneficiaryAccount', 'completeBeneficiaryAccount', 'tmpSuccess', 'tmpFailed', 'failed'], false), state(['isForeignPayment', 'setForeignFlag'], function (_, _ref4) {
  var isForeign = _ref4.isForeign;
  return isForeign;
}, false), state(['isForeignPayment', 'resetForm'], function () {
  return false;
}), state(['isTemplatesModalOpen', 'toggleTemplateModal'], function (_, _ref5) {
  var isOpen = _ref5.isOpen;
  return isOpen;
}, false), state(['isBanksCodesOpen', 'toggleBanksCodesModal'], function (_, _ref6) {
  var isOpen = _ref6.isOpen;
  return isOpen;
}, false), state(['isIntermediaryBanksOpen', 'toggleIntermediaryBanksModal'], function (_, _ref7) {
  var isOpen = _ref7.isOpen;
  return isOpen;
}, false), state(['oldBeneficiaryAccount', 'setOldBeneficiaryAccount'], function (_, _ref8) {
  var beneficiaryAccount = _ref8.beneficiaryAccount;
  return beneficiaryAccount;
}, null), state(['oldBeneficiaryAccount', 'reset'], function () {
  return null;
}, null), state(['oldBeneficiaryAccount', 'resetForm'], function () {
  return null;
}, null), stateFlag('isTemplatesModalOpen', [], ['initializeForm', 'reset', 'resetForm']), stateFlag('isBanksCodesOpen', [], ['initializeForm', 'reset', 'resetForm']), stateFlag('isIntermediaryBanksOpen', [], ['initializeForm', 'reset', 'resetForm']), stateFlag('isTransferHeaderOpen', ['openTransferHeader'], ['closeTransferHeader', 'reset', 'resetForm']), stateFlag('isFromDashboard', ['setFromDashboard'], ['reset', 'resetForm'], false), // TODO: HACK until foreign batch transaction will be available
stateFlag('isBatchPayment', ['setBatchPayment'], ['reset', 'resetForm']), state(['certifiable', 'certificationSubmited'], function (_, _ref9) {
  var certifiable = _ref9.certifiable;
  return certifiable;
}, false), state(['bankInfo', 'bankLoaded'], function (_, _ref10) {
  var bank = _ref10.bank;
  return bank;
}), state(['bankInfo', 'resetForm'], function () {
  return null;
}), state(['bankCodeDisabled', 'disableBankCode'], function (_, _ref11) {
  var disabled = _ref11.disabled;
  return disabled === true;
}, true), state(['additionalAccountInfo', 'additionalAccountInfoLoaded'], function (_, _ref12) {
  var data = _ref12.data;
  return data;
}), state(['additionalAccountInfo', 'resetForm'], function () {
  return null;
}), state(['beneficiaryAccount', 'patchBeneficiaryAccount'], function (state, _ref13) {
  var patchedState = _extends({}, _ref13);

  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, state), patchedState), errorsMerger([].concat(_toConsumableArray(state.errors && state.errors), _toConsumableArray(patchedState.errors && patchedState.errors)))), {}, {
    dirty: true,
    updating: false,
    focused: false
  });
}), selector('loading', ['accountsLoading', 'countriesLoading', 'swiftChargesLoading', 'initializing'], function (accountsLoading, countriesLoading, swiftChargesLoading, initializing) {
  return accountsLoading || countriesLoading || swiftChargesLoading || initializing;
}), userHasNoAccountsSelector, flow('initializeForm', [resolveAction('initializeForm'), takeLatest, processToSomeoneElseFlow]), flow('initializeBeneficiaryFlow', [resolveAction('initializeBeneficiary'), takeLatest, initializeBeneficiaryFlow]), flow('validationFlow', [resolveAction('initializeForm'), takeLatest, validateBeneficiaryAddressFlow]), flow('resetDebitAccountFlow', [resolveAction('initializeForm'), takeLatest, resetDebitAccountFlow]), flow('recalculateFee', [resolveAction('recalculateFee'), takeLatest, recalculateFee]), flow('verifyInstructionType', [resolveAction('setInstructionType'), takeLatest, verifyInstructionTypeFlow])));
var transferToSomeoneElseLogic = transferToSomeoneElseLogicAPI.logic;
var transferToSomeoneElseReducer = transferToSomeoneElseLogicAPI.reducer;
var transferToSomeoneElseSaga = transferToSomeoneElseLogicAPI.saga;

export { transferToSomeoneElseLogic, transferToSomeoneElseReducer, transferToSomeoneElseSaga };
