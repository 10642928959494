import { memoize } from '../../memoize.js';
import { STANDING_ORDER_FREQUENCY_TYPES, STANDING_ORDER_FREQUENCY_DAYS } from './constants.js';

var weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

var getPaymentWeekDays = function getPaymentWeekDays() {
  var paymentDays = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  if (!Array.isArray(paymentDays)) {
    return [];
  }

  return paymentDays.map(function (day) {
    var intValue = parseInt(day);

    if (!isNaN(intValue)) {
      return intValue;
    } else {
      return weekDays.indexOf(day) + 1;
    }
  });
};

var getPaymentMonthDays = function getPaymentMonthDays() {
  var paymentDays = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  if (!Array.isArray(paymentDays)) {
    return [];
  }

  return paymentDays.map(function (day) {
    return day === STANDING_ORDER_FREQUENCY_DAYS.EOD ? STANDING_ORDER_FREQUENCY_DAYS.EOD_KEY : parseInt(day);
  });
};
/**
 * How it works
 *
 * payment day
 * if interval is Weekly then should be from 1 to 7 (on lost focus)
 * if Interval is Monthly then 1 to 31 (on lost focus)
 * if interval is Quartarly then from 1 to 31 (on lost focus)
 * if interval is Yearly then from 1 to 31 (on lost focus)

 * payment month
 * if interval is Quartarly then from 1 to 3 (on lost focus)
 * if interval is Yearly then from 1 to 12 (on lost focus)
 * payment day/month should be positive integer (on lost focus)

 * If selected daily, no additional field will be displayed below.
 * If selected weekly, drop down with week day selections should be displayed.
 * If selected monthly, drop down with dates from 1 to 31 should be should be displayed.
 * If selected yearly, calendar should be displayed.
 */


var getFormatedFrequencyFn = function getFormatedFrequencyFn(standingOrder) {
  var _ref = standingOrder || {},
      frequency = _ref.frequency,
      paymentDays = _ref.paymentDays,
      paymentMonth = _ref.paymentMonth;

  var key = '';
  var month = null;
  var days = [];

  switch (frequency.id) {
    case STANDING_ORDER_FREQUENCY_TYPES.Quarterly:
      key = 'pay.standingOrderPeriodicityQuarterly.label';
      month = paymentMonth;
      days = getPaymentMonthDays(paymentDays);
      break;

    case STANDING_ORDER_FREQUENCY_TYPES.Yearly:
      key = 'pay.standingOrderPeriodicityYearly.label';
      month = paymentMonth;
      days = getPaymentMonthDays(paymentDays);
      break;

    case STANDING_ORDER_FREQUENCY_TYPES.Monthly:
      key = 'pay.standingOrderPeriodicityMonthly.label';
      days = getPaymentMonthDays(paymentDays);
      break;

    case STANDING_ORDER_FREQUENCY_TYPES.Weekly:
      key = 'pay.standingOrderPeriodicityWeekly.label';
      days = getPaymentWeekDays(paymentDays);
      break;

    case STANDING_ORDER_FREQUENCY_TYPES.Daily:
      key = 'pay.standingOrderPeriodicityDaily.label';
      break;

    case STANDING_ORDER_FREQUENCY_TYPES.Individual:
      key = 'pay.standingOrderPeriodicityCustom.label';
      break;

    case STANDING_ORDER_FREQUENCY_TYPES.Semestrial:
      key = 'pay.standingOrderPeriodicitySemestrial.label';
      month = paymentMonth;
      days = getPaymentMonthDays(paymentDays);
      break;

    default:
      key = 'pay.standingOrderPeriodicityCustom.label';
      break;
  }

  return {
    id: frequency.id,
    key: key,
    month: month,
    days: days,
    hasMore: days.length > 1
  };
};

var getFormatedFrequency = memoize(getFormatedFrequencyFn, function (standingOrder) {
  if (!standingOrder) {
    return 'empty';
  }

  var frequency = standingOrder.frequency,
      paymentDays = standingOrder.paymentDays,
      paymentMonth = standingOrder.paymentMonth;
  return "".concat(frequency.id, "-").concat(paymentDays ? paymentDays.toString() : 'empty', "-").concat(paymentMonth);
});

export { getFormatedFrequency };
