import { composeFragments, selector } from 'core-ingredients';
import { hasSpecificRightSelector } from '../../permissions/index.js';
import { BALANCE_CHECK } from '../../permissions/rights.js';

/**
 * Have to be placed before ...FieldsFragment in logic
 */
var decidePaymentWithoutBalanceCheckFragment = composeFragments( // In case User is allowed VIEW Balanace Check, then we should check it (is generally retail user)
// @see https://support.bsc-ideas.com/jira/browse/P20009432-566
// @see https://support.bsc-ideas.com/confl/pages/viewpage.action?spaceKey=VTBREAL&title=Rights+setting
selector('isBalanceCheckDisabled', [hasSpecificRightSelector], function (hasRights) {
  return !hasRights(BALANCE_CHECK());
}));

export { decidePaymentWithoutBalanceCheckFragment };
