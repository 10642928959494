import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { format } from 'date-fns';
import { is } from 'ramda';
import { INSTRUCTION_TYPES } from 'vtb-utils';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var mapInstruction = function mapInstruction(values) {
  var debitAccount = values.debitAccount;
  return {
    accountNumber: debitAccount.id
  };
};
var mapToSomeoneElseInstructionItem = function mapToSomeoneElseInstructionItem(values, paymentType) {
  var debitAccount = values.debitAccount,
      beneficiaryAccount = values.beneficiaryAccount,
      beneficiaryName = values.beneficiaryName,
      amount = values.amount,
      purpose = values.purpose,
      customerId = values.customerId,
      extrInfo = values.extrInfo,
      beneficiaryCountry = values.beneficiaryCountry,
      beneficiaryAddress = values.beneficiaryAddress,
      beneficiaryBankCode = values.beneficiaryBankCode,
      beneficiaryBankName = values.beneficiaryBankName,
      intermediaryBankCode = values.intermediaryBankCode,
      intermediaryBankName = values.intermediaryBankName,
      commissionType = values.commissionType,
      attachment = values.attachment,
      channelId = values.channelId,
      sourceTemplateId = values.sourceTemplateId;
  var payLoad = {
    customerId: customerId,
    debitAccountId: debitAccount.id,
    beneficiaryAccount: beneficiaryAccount,
    beneficiaryName: beneficiaryName,
    ccy: debitAccount === null || debitAccount === void 0 ? void 0 : debitAccount.currency,
    amount: amount,
    date: format(new Date(), 'YYYY-MM-DD'),
    purpose: purpose,
    transactionReference: '-',
    // on trunas request
    channelId: channelId,
    extrInfo: extrInfo,
    attachment: attachment === null || attachment === void 0 ? void 0 : attachment.base64,
    sourceTemplate: sourceTemplateId
  };

  if (paymentType === INSTRUCTION_TYPES.toSomeoneElseDomestic) {
    payLoad = _objectSpread(_objectSpread({}, payLoad), {}, {
      isFastPayment: false
    });
  } else if (paymentType === INSTRUCTION_TYPES.toSomeoneElseSWIFT) {
    payLoad = _objectSpread(_objectSpread({}, payLoad), {}, {
      beneficiaryCountry: is(Object, beneficiaryCountry) ? beneficiaryCountry === null || beneficiaryCountry === void 0 ? void 0 : beneficiaryCountry.value : beneficiaryCountry,
      beneficiaryAddress: beneficiaryAddress,
      beneficiaryBankCode: beneficiaryBankCode,
      beneficiaryBankName: beneficiaryBankName,
      intermediaryBankCode: intermediaryBankCode,
      intermediaryBankName: intermediaryBankName,
      commissionType: is(Object, commissionType) ? commissionType === null || commissionType === void 0 ? void 0 : commissionType.value : commissionType
    });
  }

  return payLoad;
};

export { mapInstruction, mapToSomeoneElseInstructionItem };
