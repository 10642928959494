import { identity } from 'ramda';
import { composeFragments, action, state, stateFlag } from 'core-ingredients';
import { listFragment, fieldsFragment, fieldFragment, fetchFragment } from 'core-recipes';
import { movementsBeforeQueryHandler, movementsAfterQueryHandler, aggregatesAfterQueryHandler } from './movements.flows.js';
import { exchangeRatesFragment } from '../../exchange-rates/header/exchange-rates-header.fragment.js';
import { movemenetsFilterLogic } from './filter/movements-filter.logic.js';

var initialState = {
  sums: null,
  filterData: [],
  previousMovements: [],
  fullLoading: true,
  additionalAccountInfo: []
};
var movementsFragment = composeFragments(listFragment({
  listName: 'movements',
  entityName: 'movements',
  beforeQueryHandler: movementsBeforeQueryHandler(false),
  afterQueryHandler: movementsAfterQueryHandler,
  ignoreDefaultHandlers: true
}), exchangeRatesFragment(), listFragment({
  listName: 'aggregates',
  entityName: 'movements',
  beforeQueryHandler: movementsBeforeQueryHandler(true),
  afterQueryHandler: aggregatesAfterQueryHandler,
  ignoreDefaultHandlers: true
}), fieldsFragment({})(fieldFragment({
  name: 'fullSearch'
})), fetchFragment({
  valueKey: 'instruction',
  valueQuery: function valueQuery(_ref) {
    var id = _ref.id;
    return {
      instructionItems: [],
      id: id,
      instructionTypeCodeName: 'instructionTypeCodes'
    };
  },
  supplier: 'tpm'
}), action('setCanLoadMore', identity), action('loaded', function (_ref2) {
  var list = _ref2.list,
      sums = _ref2.sums;
  return {
    list: list,
    sums: sums
  };
}), action('setAdditionalAccountInfo', function (info) {
  return {
    info: info
  };
}), state(['allMovements', 'movementAllDataLoaded'], function (_, _ref3) {
  var movements = _ref3.movements;
  return movements;
}), state(['additionalAccountInfo', 'setAdditionalAccountInfo'], function (_, _ref4) {
  var info = _ref4.info;
  return info;
}), state(['filterData', 'loadMovements'], function (_, _ref5) {
  var filter = _ref5.filter;
  return filter;
}), state(['canLoadMore', 'setCanLoadMore'], function (_, _ref6) {
  var canLoadMore = _ref6.canLoadMore;
  return canLoadMore;
}, false), state(['instruction', 'resetInstruction'], function () {
  return null;
}), stateFlag(['fullLoading'], ['fullReloadStarted'], ['fullReloadDone']), stateFlag(['movementAllDataLoading'], ['loadMovements'], ['movementAllDataLoaded']), {
  initialState: initialState,
  selectors: {
    datePickerValue: [function () {
      return [movemenetsFilterLogic.selectors.values];
    }, function (_ref7) {
      var dates = _ref7.dates;
      return dates;
    }]
  }
});

export { movementsFragment };
