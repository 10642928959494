import { composeFragments, validationRule } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withDependentFieldFlow } from 'core-recipes';
import { validationConfig } from './currency-exchange.validations.js';
import { requiredRule, minAmount } from '../../utils/validators.js';

var currencyExchangeFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'debitAccount',
  rules: [validationRule(requiredRule)]
}, withDependentFieldFlow({
  source: 'debitAccount',
  sourceActionName: 'update',
  updateHandler: validationConfig.debitAccount.dependentFlow
})), fieldFragment({
  name: 'creditAccount',
  rules: [validationRule(requiredRule)]
}, withDependentFieldFlow({
  source: 'creditAccount',
  sourceActionName: 'update',
  updateHandler: validationConfig.creditAccount.dependentFlow
})), fieldFragment({
  name: 'sourceAmount',
  rules: [validationRule(requiredRule), validationRule(minAmount(0.01))]
}, withDependentFieldFlow({
  source: 'sourceAmount',
  sourceActionName: 'complete',
  updateHandler: validationConfig.sourceAmount.dependentFlow
})), fieldFragment({
  name: 'targetAmount',
  rules: [validationRule(requiredRule), validationRule(minAmount(0.01))]
}, withDependentFieldFlow({
  source: 'targetAmount',
  sourceActionName: 'complete',
  updateHandler: validationConfig.targetAmount.dependentFlow
}))));

export { currencyExchangeFieldsFragment };
