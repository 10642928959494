// uniq keys form StandingOrderTypes catalog
var STANDING_ORDER_TYPES = {
  LCY: 'LcySO',
  FCY: 'FcySO',
  INTRA: 'IntraBankSO',
  OWN: 'OwnAccountsSO',
  TRESAURY: 'TreasurySO'
};
/** Global system parameters keys */

var STANDING_ORDER_GPS_TYPES = {
  FCY: 'SO_Fcy',
  // 41
  INTRA: 'SO_IntraBank',
  // 58
  LCY: 'SO_Lcy',
  // 41
  OWN: 'SO_OwnAccounts',
  // 1
  TRESAURY: 'SO_Treasury' // 3

};
var DISCRIMINATORS = {
  OWN_ACCOUNT: 'OWN_ACCOUNT',
  INTRA_BANK: 'INTRA_BANK',
  LCY_INTER_BANK: 'LCY_INTER_BANK',
  FCY_INTER_BANK: 'FCY_INTER_BANK',
  TREASURY: 'TREASURY'
};
var STANDING_ORDER_FREQUENCY_TYPES = {
  Quarterly: 'Quarterly',
  Yearly: 'Yearly',
  Monthly: 'Monthly',
  Weekly: 'Weekly',
  Daily: 'Daily',
  Individual: 'Individual',
  Semestrial: 'Semestrial'
};
var STANDING_ORDER_FREQUENCY_DAYS = {
  EOD: 'EndOfMonth',
  EOD_KEY: 1000
};
var MONTH_TRANSLATIONS = ['pay.months.january.label', 'pay.months.february.label', 'pay.months.march.label', 'pay.months.april.label', 'pay.months.may.label', 'pay.months.june.label', 'pay.months.july.label', 'pay.months.august.label', 'pay.months.september.label', 'pay.months.october.label', 'pay.months.november.label', 'pay.months.december.label'];
var DAY_TRANSLATIONS = ['pay.days.Monday.label', 'pay.days.Tuesday.label', 'pay.days.Wednesday.label', 'pay.days.Thursday.label', 'pay.days.Friday.label', 'pay.days.Saturday.label', 'pay.days.Sunday.label'];
var STANDING_ORDER_WIZARD_BACK = 'homepage';
var STANDING_ORDER_PAYMENTS_TO_SHOW = [0, 1, 5];
var DIRECT_DEBIT_PAYMENTS_TO_SHOW = [3];
var STANDING_ORDER_PERCENT_TYPE = 'Percent';
var STANDING_ORDER_ENDLESS_DATE = '2079-06-06';

export { DAY_TRANSLATIONS, DIRECT_DEBIT_PAYMENTS_TO_SHOW, DISCRIMINATORS, MONTH_TRANSLATIONS, STANDING_ORDER_ENDLESS_DATE, STANDING_ORDER_FREQUENCY_DAYS, STANDING_ORDER_FREQUENCY_TYPES, STANDING_ORDER_GPS_TYPES, STANDING_ORDER_PAYMENTS_TO_SHOW, STANDING_ORDER_PERCENT_TYPE, STANDING_ORDER_TYPES, STANDING_ORDER_WIZARD_BACK };
