import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { Logger, ROUTES } from 'vtb-utils'
import { createStore } from 'vtb-app/store'

import rootSaga from './sagas'
import createReducerFactory from './reducers'
import prepareSagaOptions from './configure-saga'
import { loggingSaga } from './logging'

export const history = createBrowserHistory({ basename: ROUTES.BASE_URL })

export default (supply) => {
  const onSagaError = (error, { sagaStack }) => {
    Logger.error(`Uncaught Saga exception: ${error} ${sagaStack}`)
  }
  const sagaOptions = prepareSagaOptions(supply.entries, onSagaError)
  const sagaMiddleware = createSagaMiddleware(sagaOptions)
  const { run: runSaga } = sagaMiddleware
  const createReducer = createReducerFactory(history)

  const middleware =
    process.env.NODE_ENV === 'development'
      ? [reduxImmutableStateInvariant(), sagaMiddleware, routerMiddleware(history)]
      : [sagaMiddleware, routerMiddleware(history)]

  const store = createStore(createReducer(), middleware, {
    runSaga,
    createReducer,
  })

  const { getState, dispatch } = store
  const supplySaga = supply.prepareRun({ getState, dispatch })

  runSaga(rootSaga)
  runSaga(supplySaga)
  runSaga(loggingSaga)

  return store
}
