import { logic2, composeFragments, pathFragment, action, stateFlag, selector, sagaifyLogic2 } from 'core-ingredients';
import { productNotificationsFragment } from './mm-notifications.fragment.js';

var productNotificationsLogic = logic2(composeFragments(pathFragment('products', 'notifications'), productNotificationsFragment(), action('accountsLoadingFailed'), action('cardsLoadingFailed'), action('loansLoadingFailed'), stateFlag('accountsNotifiLoaded', ['accountsLoaded', 'accountsLoadingFailed'], ['loadProductNotifications'], false), stateFlag('cardsNotifiLoaded', ['cardsLoaded', 'cardsLoadingFailed'], ['loadProductNotifications'], false), stateFlag('loansNotifiLoaded', ['loansLoaded', 'loansLoadingFailed'], ['loadProductNotifications'], false), selector('notificationsLoaded', ['accountsNotifiLoaded', 'loansNotifiLoaded', 'cardsNotifiLoaded'], function (accountsNotifiLoaded, loansNotifiLoaded, cardsNotifiLoaded) {
  return accountsNotifiLoaded && loansNotifiLoaded && cardsNotifiLoaded;
})));
var productNotificationsReducer = productNotificationsLogic.reducer;
var productNotificationsSaga = sagaifyLogic2(productNotificationsLogic);

export { productNotificationsLogic, productNotificationsReducer, productNotificationsSaga };
