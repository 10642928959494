var loadingFragment = function loadingFragment() {
  return {
    actions: {
      init: function init() {
        return {};
      },
      initializing: function initializing() {
        return {};
      },
      initialized: function initialized() {
        return {};
      },
      loadError: function loadError() {
        return {};
      },
      load: function load() {
        return {};
      }
    },
    state: {
      loading: {
        'actions.initializing': function actionsInitializing() {
          return true;
        },
        'actions.initialized': function actionsInitialized() {
          return false;
        },
        'actions.loadError': function actionsLoadError() {
          return false;
        }
      },
      failed: {
        'actions.init': function actionsInit() {
          return false;
        },
        'actions.load': function actionsLoad() {
          return false;
        },
        'actions.initializing': function actionsInitializing() {
          return false;
        },
        'actions.loadError': function actionsLoadError() {
          return true;
        }
      }
    },
    selectors: {
      loading: [['loading']],
      failed: [['failed']]
    },
    flows: {},
    initialState: {
      loading: false,
      failed: false
    }
  };
};

export { loadingFragment };
