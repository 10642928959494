import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { sortWith, descend, prop, path } from 'ramda';

var sortCards = sortWith([descend(prop('isMainCard')), descend(path(['productType', 'linkedCardPriority'])), descend(prop('validTo'))]);
var composeEntityName = function composeEntityName(detail) {
  var _ref = detail || {},
      cards = _ref.cards,
      accountType = _ref.accountType,
      entityType = _ref.entityType,
      friendlyName = _ref.friendlyName,
      productType = _ref.productType,
      accountName = _ref.accountName;

  if (friendlyName) {
    return friendlyName;
  }

  if (((accountType === null || accountType === void 0 ? void 0 : accountType.accTypeId) || (accountType === null || accountType === void 0 ? void 0 : accountType.entityUniqueId)) === '200' && (cards === null || cards === void 0 ? void 0 : cards.length) > 0) {
    var _highestPriorityCard$, _highestPriorityCard$2;

    var highestPriorityCard = sortCards(_toConsumableArray(cards));
    return ((_highestPriorityCard$ = highestPriorityCard[0]) === null || _highestPriorityCard$ === void 0 ? void 0 : (_highestPriorityCard$2 = _highestPriorityCard$.productType) === null || _highestPriorityCard$2 === void 0 ? void 0 : _highestPriorityCard$2.text) || '';
  }

  if (entityType === 'Account') {
    return (accountType === null || accountType === void 0 ? void 0 : accountType.text) || accountName || '';
  }

  return (productType === null || productType === void 0 ? void 0 : productType.text) || '';
};
var composeIcon = function composeIcon(detail) {
  var _detail$customProduct, _detail$customProduct2, _detail$productType, _detail$productType$p, _detail$accountType, _detail$accountType$d;

  return (// Because we have incorrectly stored the path to the icon in the entityUniqueId key in the past, we must leave customProductIcon.entityUniqueId here.
    // Newly and correctly it works so that the path is stored in customProductIcon.icon
    (detail === null || detail === void 0 ? void 0 : (_detail$customProduct = detail.customProductIcon) === null || _detail$customProduct === void 0 ? void 0 : _detail$customProduct.icon) || (detail === null || detail === void 0 ? void 0 : (_detail$customProduct2 = detail.customProductIcon) === null || _detail$customProduct2 === void 0 ? void 0 : _detail$customProduct2.entityUniqueId) || (detail === null || detail === void 0 ? void 0 : (_detail$productType = detail.productType) === null || _detail$productType === void 0 ? void 0 : (_detail$productType$p = _detail$productType.productIcon) === null || _detail$productType$p === void 0 ? void 0 : _detail$productType$p.icon) || (detail === null || detail === void 0 ? void 0 : (_detail$accountType = detail.accountType) === null || _detail$accountType === void 0 ? void 0 : (_detail$accountType$d = _detail$accountType.defaultAccountIcon) === null || _detail$accountType$d === void 0 ? void 0 : _detail$accountType$d.icon) || null
  );
};

export { composeEntityName, composeIcon, sortCards };
