import { takeLatest } from 'redux-saga/effects';
import { loadRequisitesFlow } from './accounts-requisites.flow.js';

var accountRequisitesFragment = function accountRequisitesFragment() {
  return {
    actions: {
      loadReq: function loadReq(id) {
        return {
          id: id
        };
      },
      loadedReq: function loadedReq(data) {
        return {
          data: data
        };
      },
      loadReqError: function loadReqError() {
        return {};
      }
    },
    state: {
      requisites: {
        'actions.loadedReq': function actionsLoadedReq(_, _ref) {
          var data = _ref.data;
          return data;
        }
      }
    },
    selectors: {
      requisites: [['requisites']]
    },
    flows: {
      loadRequisites: ['actions.loadReq', takeLatest, loadRequisitesFlow]
    },
    initialState: {
      requisites: null
    }
  };
};

export { accountRequisitesFragment };
