import { composeFragments } from 'core-ingredients';
import { fieldsFragment, fieldFragment } from 'core-recipes';

var signingRulesStepSignersFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'orderImportance',
  initialValue: false
}), fieldFragment({
  name: 'minimalSignatures'
})));

export { signingRulesStepSignersFieldsFragment };
