import { takeLatest } from 'redux-saga/effects';
import { composeFragments, action, state, stateFlag, selector, flow, resolveAction, extendInitialState } from 'core-ingredients';
import { groupFromProcessFlow } from './template-group-form-process.flow.js';

var groupFormFragment = composeFragments(action('submit'), action('calling'), action('initializeForm'), action('setCreatedGroup', function (group) {
  return {
    group: group
  };
}), state(['createdGroup', 'setCreatedGroup'], function (_, _ref) {
  var group = _ref.group;
  return group;
}), state(['createdGroup', 'reset'], function () {
  return null;
}), state(['createdGroup', 'failed'], function () {
  return null;
}), state(['createdGroup', 'initializeForm'], function () {
  return null;
}), stateFlag('isSuccessResult', ['tmpSuccess'], ['tmpFailed', 'failed', 'reset', 'resetForm'], false), stateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm'], false), stateFlag('submitting', ['calling'], ['tmpSuccess', 'tmpFailed', 'failed', 'backToFirstStep'], false), selector('loading', ['submitting'], function (submitting) {
  return submitting;
}), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, groupFromProcessFlow]), extendInitialState(null, {
  createdGroup: null
}));

export { groupFormFragment };
