import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { Flex } from '../../../../components/generic/flex.js';

var _templateObject;
var RoudedBox = styled(Flex).attrs(function () {
  return {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: [0, 10],
    boxShadow: ['none', '2px 8px 25px 0 rgba(0, 46, 69, 0.1), 2px 1px 4px 0 rgba(0, 37, 90, 0.04);']
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));

export { RoudedBox };
