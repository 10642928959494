import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { alignSelf, fontFamily, fontSize, fontWeight, justifyContent, justifySelf, letterSpacing, lineHeight, overflow, textAlign, verticalAlign } from '../../node_modules/styled-system/dist/index.esm.js';
import { Box } from './box.js';

var _templateObject, _templateObject2;
var Text = styled(Box).attrs(function (_ref) {
  var _ref$display = _ref.display,
      display = _ref$display === void 0 ? 'inline-block' : _ref$display,
      _ref$fontWeight = _ref.fontWeight,
      fontWeight = _ref$fontWeight === void 0 ? 300 : _ref$fontWeight;
  return {
    display: display,
    fontWeight: fontWeight
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), alignSelf, fontFamily, fontSize, fontWeight, justifyContent, justifySelf, letterSpacing, lineHeight, overflow, textAlign, verticalAlign);
var WordBreak = styled(Text)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  word-wrap: break-word;\n  word-break: break-word;\n  overflow-wrap: anywhere;\n"])));

export { Text, WordBreak };
