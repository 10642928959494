import { composeFragments, state, extendInitialState } from 'core-ingredients';
import { TRANSFER_TYPES, ROUTES } from 'vtb-utils';

var makeNewItems = [{
  id: 0,
  type: TRANSFER_TYPES.TRANSFER_TO_OWN_ACCOUNT,
  url: '/payments/transfer-to-own-account',
  Icon: null,
  text: 'payOA.name.label'
}, {
  id: 1,
  type: TRANSFER_TYPES.TRANSFER_TO_SOMEONE_ELSE,
  url: '/payments/transfer-to-someone-else',
  Icon: null,
  text: 'pay.someoneElseTitle.label'
}, {
  id: 2,
  type: TRANSFER_TYPES.MOBILE_TOPUP,
  url: '/payments/mobile-top-up',
  Icon: null,
  text: 'paymentCenter.mobileTopUp.title'
}, {
  id: 3,
  type: TRANSFER_TYPES.BILL_PAYMENTS,
  url: '/payments/bill-payments',
  Icon: null,
  text: 'paymentCenter.billPayment.title'
}, {
  id: 4,
  type: TRANSFER_TYPES.CURRENCY_EXCHANGE,
  url: '/payments/currency-exchange',
  Icon: null,
  text: 'pay.convTitle.label'
}, {
  id: 5,
  type: TRANSFER_TYPES.TREASURY_TRANSFER,
  url: '/payments/treasury-transfer',
  Icon: null,
  text: 'pay.treasuryTitle.label'
}, {
  id: 6,
  type: TRANSFER_TYPES.FINES,
  url: ROUTES.PAYMENT_CENTER.CARS,
  Icon: null,
  text: 'paymentCenter.parkingFines.title'
}, {
  id: 7,
  type: TRANSFER_TYPES.BATCH_PAYMENTS,
  url: '/payments/batch-payments',
  Icon: null,
  text: 'paymentCenter.batchPayments.title'
}];
var initialState = {
  transfers: makeNewItems
};
var transferHomepageFragment = composeFragments(state(['transfers', 'transfersDataLoaded'], function (_, _ref) {
  var transfers = _ref.transfers;
  return transfers;
}), state(['upcomings', 'upcomingsDataLoaded'], function (_, _ref2) {
  var list = _ref2.list;
  return list;
}), extendInitialState(null, initialState));

export { transferHomepageFragment };
