import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeLatest } from 'redux-saga/effects';
import { identity } from 'ramda';
import { composeFragments, action, stateFlag, flow, resolveAction, selector } from 'core-ingredients';
import { catalogFragment, listFragment, configResetListFragment } from 'core-recipes';
import { getStandingOrderQuery } from '../common/so-query.js';
import { soLoadImagesFragment } from '../common/images/so-load-images.fragment.js';
import { soUpdateSortingFlow } from './so-update-sorting.flow.js';
import { initFlow } from './init.flow.js';
import { afterStandingOrdersLoadedFlow, addSortIndexAndSort } from './after-standing-orders-loaded.flow.js';
import { userLogic } from '../../../../generic/user/user.logic.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var soListFragment = composeFragments(catalogFragment({
  name: 'standingOrderTypes',
  querySchema: {
    StandingOrderTypes: [],
    limit: 1000
  }
}), listFragment({
  listName: 'standingOrders',
  entityName: 'StandingOrders',
  listQuery: _objectSpread({}, getStandingOrderQuery()),
  afterQueryHandler: afterStandingOrdersLoadedFlow
}, configResetListFragment({})), action('init'), action('onSortEnd', identity), stateFlag('isLoading', ['init'], ['loadingDone', 'loadingFailed'], true), flow('initFlow', [resolveAction('init'), takeLatest, initFlow]), selector('sortedStandingOrders', ['standingOrders', 'standingOrderTypesLoaded', userLogic.selectors.clientUserSettings], function (standingOrders, standingOrderTypesLoaded, clientUserSettings) {
  if (!standingOrderTypesLoaded) {
    return standingOrders;
  }

  return addSortIndexAndSort(standingOrders, clientUserSettings);
}), flow('updateSorting', [resolveAction('onSortEnd'), takeLatest, soUpdateSortingFlow]), soLoadImagesFragment);

export { soListFragment };
