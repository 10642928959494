var resolveExchangeRate = function resolveExchangeRate(_ref) {
  var rateSetUsed = _ref.rateSetUsed,
      customerRate = _ref.customerRate,
      standardRate = _ref.standardRate,
      channelRate = _ref.channelRate;

  switch (rateSetUsed) {
    case 'Customer':
      return customerRate;

    case 'Channel':
      return channelRate;

    default:
      return standardRate;
  }
};

export { resolveExchangeRate };
