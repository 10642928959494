import { takeLatest } from 'redux-saga/effects';
import { listFragment } from 'core-recipes';
import { composeFragments, action, state, stateFlag, selector, flow, resolveAction, extendInitialState } from 'core-ingredients';
import { getCarsQuery } from '../common/cars-query.js';
import { addCarProcessFlow } from './add-car-process.flow.js';
import { validateAdditionalFieldsFlow } from './validate-additional-fields.flow.js';

var addCarFormFragment = composeFragments(listFragment({
  entityName: 'CarTemplates',
  listName: 'cars',
  listQuery: getCarsQuery()
}), action('submit'), action('calling'), action('initializeForm'), action('validateAdditionalFields'), action('setCreatedCar', function (car) {
  return {
    car: car
  };
}), state(['listErrors', 'setServerErrors'], function (_, _ref) {
  var errors = _ref.errors;
  return errors;
}, null), state(['createdCar', 'setCreatedCar'], function (_, _ref2) {
  var car = _ref2.car;
  return car;
}), state(['createdCar', 'reset'], function () {
  return null;
}), state(['createdCar', 'failed'], function () {
  return null;
}), state(['createdCar', 'initializeForm'], function () {
  return null;
}), stateFlag('wrongCarCredentials', ['credentialsAreWrong'], ['reset', 'resetForm', 'credentialsAreCorrect'], false), stateFlag('isSuccessResult', ['tmpSuccess'], ['tmpFailed', 'failed', 'reset', 'resetForm'], false), stateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm'], false), stateFlag('validatingCar', ['startValidation'], ['stopValidation', 'reset', 'resetForm'], false), stateFlag('submitting', ['calling'], ['tmpSuccess', 'tmpFailed', 'failed', 'backToFirstStep'], false), selector('loading', ['submitting'], function (submitting) {
  return submitting;
}), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, addCarProcessFlow]), flow('validateAdditionalFields', [resolveAction('initializeForm'), takeLatest, validateAdditionalFieldsFlow]), extendInitialState(null, {
  createdCar: null
}));

export { addCarFormFragment };
