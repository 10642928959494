import { validationRule, maxLength, autocorrectingValidationRule } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withDependentFieldFlow, withDependentField, withDynamicFieldValidation } from 'core-recipes';
import { charset, requiredRule, fakeValidation } from '../../../../utils/validators.js';
import { validationConfig as validationConfig$1 } from '../bill-payment-template-fields-validations.js';
import { validationConfig } from '../../form/bill-payment-fields-validations.js';
import { templateNameChars, groupNameOnlyAllowedChars } from '../../../../utils/validation-patterns.js';

var billPaymentTemplateFieldsFragment = fieldsFragment({})(fieldFragment({
  name: 'templateName',
  rules: [validationRule(maxLength(30)), autocorrectingValidationRule(charset(templateNameChars)), validationRule(requiredRule)]
}), fieldFragment({
  name: 'serviceCode',
  rules: [validationRule(requiredRule)]
}, withDependentFieldFlow({
  source: 'serviceCode',
  updateHandler: validationConfig.serviceCode.dependentFlow
})), fieldFragment({
  name: 'customerCode',
  rules: [validationRule(maxLength(140)), validationRule(requiredRule)]
}, withDependentField({
  source: 'serviceCode',
  computation: validationConfig.customerCode.serviceComputation
})), fieldFragment({
  name: 'templateIcon',
  rules: []
}), fieldFragment({
  name: 'mode',
  initialValue: 'dontAssign'
}, withDependentFieldFlow({
  source: 'mode',
  updateHandler: validationConfig$1.mode.dependentFlow
})), fieldFragment({
  name: 'groupId'
}, withDynamicFieldValidation({
  query: validationConfig$1.groupId.dependentFlow('groupId'),
  isCallable: true,
  code: 'required',
  triggerActionName: 'validateTemplateGroups',
  validation: function validation(validationResult) {
    return validationResult && validationResult.required ? requiredRule : fakeValidation;
  }
})), fieldFragment({
  name: 'groupCategory'
}, withDynamicFieldValidation({
  query: validationConfig$1.groupCategory.dependentFlow,
  isCallable: true,
  code: 'required',
  triggerActionName: 'validateTemplateGroups',
  validation: function validation(validationResult) {
    return validationResult && validationResult.required ? requiredRule : fakeValidation;
  }
})), fieldFragment({
  name: 'groupName',
  rules: [validationRule(maxLength(30)), autocorrectingValidationRule(charset(groupNameOnlyAllowedChars))]
}, withDynamicFieldValidation({
  query: validationConfig$1.groupName.dependentFlow,
  isCallable: true,
  code: 'required',
  triggerActionName: 'validateTemplateGroups',
  validation: function validation(validationResult) {
    return validationResult && validationResult.required ? requiredRule : fakeValidation;
  }
})));

export { billPaymentTemplateFieldsFragment };
