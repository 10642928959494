import { takeLatest } from 'redux-saga/effects';
import { composeFragments, pathFragment, action, flow, resolveAction, state, stateFlag, resolveLogic } from 'core-ingredients';
import { enableSigningRuleFlow } from './signing-rules-enable.flow.js';

var signingRulesEnableLogicSchema = composeFragments(pathFragment('signingRules', 'enable'), action('enableSigningRule'), flow('enableSigningRuleFlow', [resolveAction('enableSigningRule'), takeLatest, enableSigningRuleFlow]), action('updateCertificationStatus'), action('setCertificationState', function (certificationState) {
  return {
    certificationState: certificationState
  };
}), state(['certificationState', 'setCertificationState'], function (_, _ref) {
  var certificationState = _ref.certificationState;
  return certificationState;
}), stateFlag('isProcessing', ['enableSigningRule'], ['setCertificationState'], false));
var signingRulesEnableLogicAPI = resolveLogic(signingRulesEnableLogicSchema);
var signingRulesEnableLogic = signingRulesEnableLogicAPI.logic;
var signingRulesEnableReducer = signingRulesEnableLogicAPI.reducer;
var signingRulesEnableSaga = signingRulesEnableLogicAPI.saga;

export { signingRulesEnableLogic, signingRulesEnableLogicAPI, signingRulesEnableReducer, signingRulesEnableSaga };
