import _defineProperty from '@babel/runtime/helpers/defineProperty';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var processServiceParamsValue = function processServiceParamsValue(serviceParameters) {
  var result = []; // eg: billNumber/personalNumber||carNumber||techIdentificationNumber

  var parts = serviceParameters.split('/');

  if (parts.length === 1) {
    result.push({
      paramKey: parts[0],
      sortOrder: 1,
      mandatory: true
    });
  }

  if (parts.length > 1) {
    result.push({
      paramKey: parts[0],
      sortOrder: 1,
      mandatory: true
    });
    var secondParts = parts[1].split('||');
    secondParts.forEach(function (field, index) {
      result.push({
        paramKey: field,
        sortOrder: index + 2,
        mandatory: false
      });
    });
  }

  return result;
};

var processServiceParameters = function processServiceParameters(services) {
  return services.map(function (service) {
    var serviceParameters = [];

    if (service.serviceParameters) {
      serviceParameters = processServiceParamsValue(service.serviceParameters);
    }

    return _objectSpread(_objectSpread({}, service), {}, {
      serviceParameters: serviceParameters
    });
  });
};

export { processServiceParameters };
