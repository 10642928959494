import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { loadingFragment } from '../../loading.fragment.js';
import { userHasNoAccountsSelector } from '../../../payment-centre/common/selectors.js';
import { accountsBalancesFragment } from './accounts-balances.fragment.js';

var accountBalancesLogic = logic2(composeFragments(pathFragment('accounts', 'balances'), loadingFragment(), accountsBalancesFragment(), userHasNoAccountsSelector));
var accountBalancesReducer = accountBalancesLogic.reducer;
var accountBalancesSaga = sagaifyLogic2(accountBalancesLogic);

export { accountBalancesLogic, accountBalancesReducer, accountBalancesSaga };
