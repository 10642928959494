import { Location } from 'history'
import React, { memo } from 'react'
import { I18n } from 'vtb-ui'

export interface PageNotFoundProps {
  location: Location
}

export const PageNotFound = memo<PageNotFoundProps>(({ location }) => (
  <div>
    <h3>
      Error 404 - <code>{location.pathname}</code>
    </h3>
    <I18n>common.problem.text</I18n>
  </div>
))
