var billPaymentTemplatePayload = function billPaymentTemplatePayload() {
  var _template$serviceCode, _template$serviceCode2, _template$serviceCode3, _template$templateIco;

  for (var _len = arguments.length, props = new Array(_len), _key = 0; _key < _len; _key++) {
    props[_key] = arguments[_key];
  }

  var template = props[0],
      templateType = props[1];
  var payload = {
    templateName: template.templateName,
    service: ((_template$serviceCode = template.serviceCode) === null || _template$serviceCode === void 0 ? void 0 : (_template$serviceCode2 = _template$serviceCode.value) === null || _template$serviceCode2 === void 0 ? void 0 : _template$serviceCode2.serviceCode) || (template === null || template === void 0 ? void 0 : (_template$serviceCode3 = template.serviceCode) === null || _template$serviceCode3 === void 0 ? void 0 : _template$serviceCode3.value),
    subscriberId: template.customerCode,
    templateType: templateType
  };

  if ((template === null || template === void 0 ? void 0 : template.templateIcon) === null || template !== null && template !== void 0 && (_template$templateIco = template.templateIcon) !== null && _template$templateIco !== void 0 && _template$templateIco.base64) {
    var _template$templateIco2;

    payload.templateIcon = (template === null || template === void 0 ? void 0 : (_template$templateIco2 = template.templateIcon) === null || _template$templateIco2 === void 0 ? void 0 : _template$templateIco2.base64) || ''; // delete image by empty string
  }

  if (template.groupId && template.groupId.value && template.groupId.value.groupId) {
    payload['templateGroup'] = template.groupId.value.groupId;
  }

  if (template.templateId) {
    payload['templateId'] = template.templateId;
  }

  return payload;
};

export { billPaymentTemplatePayload };
