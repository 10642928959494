import { composeFragments, pathFragment, resolveLogic } from 'core-ingredients';
import { generationFragment } from './generation.fragment.js';

var digipassGenerationSchema = composeFragments(pathFragment('digipass', 'generation'), generationFragment);

var _resolveLogic = resolveLogic(digipassGenerationSchema),
    logic = _resolveLogic.logic,
    reducer = _resolveLogic.reducer,
    saga = _resolveLogic.saga;

export { logic as digipassGenerationLogic, reducer as digipassGenerationReducer, saga as digipassGenerationSaga, digipassGenerationSchema };
