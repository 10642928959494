import { withDefaultProps } from 'core-ui';
import { PopperBox } from './propper-box.js';
import { Tooltip } from './Tooltip.js';

var TooltipBox = withDefaultProps({
  components: {
    tooltip: Tooltip
  }
}, PopperBox);

export { TooltipBox };
