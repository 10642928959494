var TREASURY_STEP = {
  PART_A: 0,
  PART_B: 1,
  PART_C: 2
};
var CURRENCY_EXCHAGE_RATES = {
  BR: 'BR',
  CHR: 'CHR',
  CUS: 'CUS'
};
var CURRENCY_EXCHAGE_RATES_TYPES = {
  STANDART: 'Standard',
  OFFICIAL: 'Official',
  CHANNEL: 'Channel',
  CUSTOMER_RATE: 'Customer rate'
};
var TRANSFER_TYPES = {
  TRANSFER_TO_OWN_ACCOUNT: 'TransferToOwnAccount',
  TRANSFER_TO_SOMEONE_ELSE: 'TransferToSomeoneElse',
  MOBILE_TOPUP: 'MobileTopUp',
  BILL_PAYMENTS: 'BillPayments',
  CURRENCY_EXCHANGE: 'CurrencyExchange',
  TREASURY_TRANSFER: 'TreasuryTransfer',
  FINES: 'Fines',
  BATCH_PAYMENTS: 'BatchPayments',
  SODD: 'SODD'
};
var NEW_GROUP_OPTION_KEY = '#new-group';
var TREASURY_TRANSFER_PAYMENT_TYPE = {
  ME: 'me',
  SOMEONE_ELSE: 'someoneelse'
};
var treasuryModeOptions = [{
  label: 'pay.treasuryMe.label',
  selected: true,
  value: TREASURY_TRANSFER_PAYMENT_TYPE.ME,
  mr: 50,
  dataCy: TREASURY_TRANSFER_PAYMENT_TYPE.ME
}, {
  label: 'pay.treasurySomeoneElse.label',
  selected: false,
  value: TREASURY_TRANSFER_PAYMENT_TYPE.SOMEONE_ELSE,
  dataCy: TREASURY_TRANSFER_PAYMENT_TYPE.SOMEONE_ELSE
}];
var BP_SERVICE_TYPES = {
  SERVICE: 'SERVICE',
  PACKAGE: 'PACKAGE'
};
var BILLPAYMENT_WIZARDS = {
  PAYMENTS: 'paymentsWizard',
  TEMPLATES: 'templateWizard'
};
var CAR_WIZARDS = {
  PARKING: 'parking',
  FINES: 'fines'
};
var CAR_WIZARD_STEPS = {
  PROVIDERS: 'providers',
  SERVICES: 'services'
};
var CAR_FLOW_TYPES = {
  PARKINGFINES_BASKET: 'PARKINGFINES_BASKET',
  PARKINGPASS_BASKET: 'PARKINGPASS_BASKET',
  PARKINGPASS_ONEPAYMENT_BASKET: 'PARKINGPASS_ONEPAYMENT_BASKET',
  POLICEFINES_BASKET: 'POLICEFINES_BASKET'
};
var CARS_LIST_ERROR_TYPES = {
  ADD: 'add',
  EDIT: 'edit'
};
var CAR_EMPTY_CARID_URL = 'no-id';
var BILL_PAYMENT_SAVE_AS_TEMPLATE_MODES = [{
  label: 'paymentCenter.saveAsTemplate.assignToGroup.dontAssign.label',
  dataCy: 'modeDontAssignBtn',
  selected: true,
  value: 'dontAssign',
  mr: 40,
  ml: 8,
  textColor: '#6b7683'
}, {
  label: 'paymentCenter.saveAsTemplate.assignToGroup.assign.label',
  dataCy: 'modeAssignBtn',
  selected: false,
  value: 'assign',
  ml: 8,
  textColor: '#6b7683'
}];
var TPM_ERRORS_CODE = {
  NO_CERTIFICATION_METHOD_AVAILABLE: 'tpm.error.certification.no.suitable.method',
  NO_SIGNATURE_AVAILABLE: 'tpm.error.certification.no.suitable.signature.rule',
  ACCOUNT_NOT_FOUND: 'tpm.error.common.not.found'
};
var TEMPLATE_PAYMENT_TYPES = {
  TRANSFERTOSOMEONEELSE: 'TRANSFERTOSOMEONEELSE',
  TREASURY: 'TREASURY'
};
var FINES_FILTER_KEYS = {
  ALL: 'all',
  POLICEFINES: 'POLICEFINES',
  PARKINGFINES: 'PARKINGFINES'
};
var ADD_NEW_GROUP_ID = 'ADD_NEW_GROUP';
var PAYMENT_DUE_GROUP_ID = 'paymentsDue';
var PAYMENT_ALL_GROUP_ID = 'all';
var TEMPLATE_WITHOUT_GROUP_ID = '_';
var FINES_AND_PARKING_STATUSES = ['active', 'inactive', 'expired'];
var OPERATION_TYPE = {
  SELL: 'Sell',
  BUY: 'Buy'
};
var TPM_OPERATION_TYPE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE'
};
var BATCH_CONCEPT_TYPE = {
  TREASURY: 'TREASURY',
  SOMEONE_ELSE: 'SOMEONEELSE'
};
var DIRECT_DEBIT_PAY_TYPE = {
  BILL_AMOUNT: 'BILL_AMOUNT',
  FIX_AMOUNT: 'FIX_AMOUNT'
};
var BILL_TYPES = {
  UTILITY: 'UTILITY',
  MOBILE: 'MOBILE',
  PARKINGPASS: 'PARKINGPASS',
  POLICEFINES: 'POLICEFINES',
  PARKINGFINES: 'PARKINGFINES'
};
var STATUS = {
  VALID: 'VALID',
  INVALID: 'INVALID'
};
var directDebitPayTypeOptions = [{
  dataCy: 'billAmount',
  label: 'paymentCenter.directDebit.billAmount.label',
  selected: false,
  value: DIRECT_DEBIT_PAY_TYPE.BILL_AMOUNT
}, {
  dataCy: 'fixedAmount',
  label: 'paymentCenter.directDebit.fixedAmount.label',
  selected: true,
  value: DIRECT_DEBIT_PAY_TYPE.FIX_AMOUNT
}];
var BATCH_CONCEPT_ITEM_SELECT_ITEMS = {
  UNSELECTED: 'UNSELECTED',
  NONE: null,
  ALL: 'ALL',
  UNSAVED: 'UNSAVED'
};
var BATCH_IMPORT_TYPE_TABLE = [{
  id: 'CSV',
  contentType: 'text/csv'
}, {
  id: 'XLSX',
  contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}, {
  id: 'XLS',
  contentType: ''
}];
var BATCH_CONCEPT_ITEM_IMPORT_TYPES = {
  TRANSFERTOSOMEONEELSE: 'BatchSomeoneElse',
  TREASURY: 'BatchTreasury'
};
var BATCH_CONCEPT_ITEM_TABLE_SELECT_ITEMS = [{
  label: 'batchPayment.createBatch.table.select.none',
  value: BATCH_CONCEPT_ITEM_SELECT_ITEMS.NONE
}, {
  label: 'batchPayment.createBatch.table.select.all',
  value: BATCH_CONCEPT_ITEM_SELECT_ITEMS.ALL
}, {
  label: 'batchPayment.createBatch.table.select.unsaved',
  value: BATCH_CONCEPT_ITEM_SELECT_ITEMS.UNSAVED
}];
var BATCH_CONCEPT_PROCEED_TYPE = {
  BATCH: 'batch',
  SINGLE: 'single'
};
var batchProceedAsOptions = [{
  label: 'batchPayment.batch.label',
  selected: true,
  value: BATCH_CONCEPT_PROCEED_TYPE.BATCH,
  mr: 50
}, {
  label: 'batchPayment.singlePayment.label',
  selected: false,
  value: BATCH_CONCEPT_PROCEED_TYPE.SINGLE
}];
var BATCH_PAYMENT_TYPE = {
  INTRABANKPAYMENTS: 'intraBankPayments',
  INTERBANKLCCYPAYMENTS: 'interBankLCCYPayments',
  TREASURYPAYMENTS: 'treasuryPayments'
};
var TREASURY_CODE_TYPES = {
  A: 'A',
  B: 'B',
  C: 'C'
};
var BILL_PAYMENT_TEMPLATE_TYPE = 'BILLPAYMENT';

export { ADD_NEW_GROUP_ID, BATCH_CONCEPT_ITEM_IMPORT_TYPES, BATCH_CONCEPT_ITEM_SELECT_ITEMS, BATCH_CONCEPT_ITEM_TABLE_SELECT_ITEMS, BATCH_CONCEPT_PROCEED_TYPE, BATCH_CONCEPT_TYPE, BATCH_IMPORT_TYPE_TABLE, BATCH_PAYMENT_TYPE, BILLPAYMENT_WIZARDS, BILL_PAYMENT_SAVE_AS_TEMPLATE_MODES, BILL_PAYMENT_TEMPLATE_TYPE, BILL_TYPES, BP_SERVICE_TYPES, CARS_LIST_ERROR_TYPES, CAR_EMPTY_CARID_URL, CAR_FLOW_TYPES, CAR_WIZARDS, CAR_WIZARD_STEPS, CURRENCY_EXCHAGE_RATES, CURRENCY_EXCHAGE_RATES_TYPES, DIRECT_DEBIT_PAY_TYPE, FINES_AND_PARKING_STATUSES, FINES_FILTER_KEYS, NEW_GROUP_OPTION_KEY, OPERATION_TYPE, PAYMENT_ALL_GROUP_ID, PAYMENT_DUE_GROUP_ID, STATUS, TEMPLATE_PAYMENT_TYPES, TEMPLATE_WITHOUT_GROUP_ID, TPM_ERRORS_CODE, TPM_OPERATION_TYPE, TRANSFER_TYPES, TREASURY_CODE_TYPES, TREASURY_STEP, TREASURY_TRANSFER_PAYMENT_TYPE, batchProceedAsOptions, directDebitPayTypeOptions, treasuryModeOptions };
