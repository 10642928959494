import { composeFragments, pathFragment, action, state, stateFlag, flow, resolveAction, resolveLogic } from 'core-ingredients';
import { listFragment } from 'core-recipes';
import { takeLatest } from 'redux-saga/effects';
import { saveLangFlow, loadLangFlow } from './personalize.flow.js';
import { processDataFlow, processNotifInteraction } from './notifications-flow.js';

var personalizeLogicSchema = composeFragments(pathFragment('profile', 'personalize'), listFragment({
  listName: 'notificationSetingsRetail',
  entityName: 'NotificationSettingRetail',
  listQuery: {
    NotificationSettingRetails: [],
    limit: 10
  },
  afterQueryHandler: processDataFlow
}), action('notificationInteraction'), action('saveLang'), action('loadLang'), state(['smsChecked', 'smsCheckToggle'], function (_, _ref) {
  var smsChecked = _ref.smsChecked;
  return smsChecked;
}, false), state(['pushChecked', 'pushCheckToggle'], function (_, _ref2) {
  var pushChecked = _ref2.pushChecked;
  return pushChecked;
}, false), state(['mailChecked', 'mailCheckToggle'], function (_, _ref3) {
  var mailChecked = _ref3.mailChecked;
  return mailChecked;
}, false), state(['isDefault', 'changeDefault'], function (_, _ref4) {
  var isDefault = _ref4.isDefault;
  return isDefault;
}), state(['fetchedOptions', 'saveOptions'], function (_, _ref5) {
  var saveOptions = _ref5.saveOptions;
  return saveOptions;
}, []), state(['initialLoadFinished', 'finishInitialLoad'], function (_, _ref6) {
  var bool = _ref6.bool;
  return bool;
}, false), stateFlag('instructionFailed', [], ['failed'], false), flow('saveLang', [resolveAction('saveLang'), takeLatest, saveLangFlow]), flow('loadLang', [resolveAction('loadLang'), takeLatest, loadLangFlow]), flow('notificationInteraction', [resolveAction('notificationInteraction'), takeLatest, processNotifInteraction]));
var personalizeLogicAPI = resolveLogic(personalizeLogicSchema);
var personalizeLogic = personalizeLogicAPI.logic;
var personalizeReducer = personalizeLogicAPI.reducer;
var personalizeSaga = personalizeLogicAPI.saga;

export { personalizeLogic, personalizeLogicAPI, personalizeReducer, personalizeSaga };
