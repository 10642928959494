import _defineProperty from '@babel/runtime/helpers/defineProperty';
import _toArray from '@babel/runtime/helpers/toArray';
import _slicedToArray from '@babel/runtime/helpers/slicedToArray';
import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { is, assocPath, mergeDeepRight } from 'ramda';
import { compose, createStore as createStore$1, applyMiddleware } from 'redux';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var createStore = function createStore(reducer, middleware, options) {
  // @ts-ignore
  var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // @ts-ignore
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    shouldHotReload: true
  }) : compose;
  var store = createStore$1(reducer, composeEnhancers(applyMiddleware.apply(void 0, _toConsumableArray(middleware))));
  var asyncSagas = {};
  var asyncReducers = {};

  var runSaga = function runSaga(name, saga) {
    var derivedName = name || saga.name;

    if (!asyncSagas[derivedName]) {
      asyncSagas[derivedName] = saga;
      options.runSaga(saga);
    }
  };

  var runSagas = function runSagas(sagas) {
    Object.entries(sagas).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          name = _ref2[0],
          saga = _ref2[1];

      if (!asyncSagas[name]) {
        asyncSagas[name] = saga;
        options.runSaga(saga);
      }
    });
  };

  var injectReducer = function injectReducer(name, reducer) {
    if (is(Array, name)) {
      var _name = _toArray(name),
          root = _name[0],
          namePath = _name.slice(1);

      if (namePath.length) {
        var currentReducer = asyncReducers[root];
        asyncReducers[root] = assocPath(namePath, reducer, currentReducer || {});
      } else {
        asyncReducers[root] = reducer;
      }
    } else {
      asyncReducers[name] = reducer;
    }

    store.replaceReducer(options.createReducer(asyncReducers));
  };

  var injectReducers = function injectReducers(reducers) {
    Object.entries(reducers).forEach(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
          name = _ref4[0],
          reducer = _ref4[1];

      var currentReducer = asyncReducers[name];

      if (!currentReducer || is(Function, currentReducer) || is(Function, reducer)) {
        asyncReducers[name] = reducer;
      } else {
        asyncReducers[name] = mergeDeepRight(currentReducer, reducer);
      }
    });
    store.replaceReducer(options.createReducer(asyncReducers));
  };

  return _objectSpread(_objectSpread({}, store), {}, {
    runSaga: runSaga,
    runSagas: runSagas,
    injectReducer: injectReducer,
    injectReducers: injectReducers
  });
};

export { createStore };
