import { withDefaultProps, ListItem } from 'core-ui';
import { Flex } from '../../../../components/generic/flex.js';
import { TransferPopupItemView } from './transfer-popup-item-view.js';

var TransferPopupItem = withDefaultProps({
  RenderComponent: TransferPopupItemView,
  components: {
    Label: Flex
  }
}, ListItem);

export { TransferPopupItem };
