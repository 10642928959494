import { subDays, addDays, parse, isBefore, differenceInMonths, differenceInCalendarDays, addMonths, startOfToday, endOfToday, subMonths, addYears, subYears, startOfYesterday, endOfYesterday } from 'date-fns';
import moment from 'moment';
import { memoize } from './memoize.js';
import DateTime from './validation/dates.js';
import { LANGUAGES } from './constants/languages.js';

var nextWorkingDay = function nextWorkingDay() {
  var dayNum = new Date().getDay();

  switch (dayNum) {
    case 5:
      return moment().add(3, 'day');

    case 6:
      return moment().add(2, 'day');

    default:
      return moment().add(1, 'day');
  }
};
var getDateBefore = memoize(function (days) {
  return DateTime.toISOString(subDays(new Date(), days));
});
var getDateAfter = memoize(function (days) {
  return DateTime.toISOString(addDays(new Date(), days));
}); // https://support.bsc-ideas.com/jira/browse/P20009327-5704

var MAGIC_BREAKPOINT_CONSTANT = 15;
var getDifferenceInMonthsOrDays = function getDifferenceInMonthsOrDays(endDate, startDate) {
  var normalizedStart = parse(startDate);
  var normalizedEnd = parse(endDate);

  if (isBefore(normalizedEnd, normalizedStart)) {
    return {
      days: 0,
      months: 0
    };
  } // equivalent to repeatedly adding months


  var months = differenceInMonths(normalizedEnd, normalizedStart);

  if (months < 1) {
    return {
      // DATEDIFF equivalent
      days: differenceInCalendarDays(normalizedEnd, normalizedStart),
      months: 0
    };
  }

  var daysDiff = differenceInCalendarDays(normalizedEnd, addMonths(normalizedStart, months));

  if (daysDiff > MAGIC_BREAKPOINT_CONSTANT) {
    ++months;
  }

  return {
    days: 0,
    months: months
  };
};
var isInExpirationPeriod = function isInExpirationPeriod(date, limitInDays) {
  var today = new Date();
  var parsedDate = parse(date);
  var startOfNotificationPeriod = subDays(parsedDate, Number(limitInDays));
  return isBefore(startOfNotificationPeriod, today);
};
var DATE_RANGE_OPTIONS = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  LAST_6_MONTHS: 'LAST_6_MONTHS',
  LAST_YEAR: 'LAST_YEAR',
  CUSTOM_PERIOD: 'CUSTOM_PERIOD',
  NEXT_MONTH: 'NEXT_MONTH',
  NEXT_6_MONTHS: 'NEXT_6_MONTHS',
  NEXT_YEAR: 'NEXT_YEAR'
};
var lastWeek = {
  startDate: subDays(startOfToday(), 6),
  endDate: endOfToday()
};
var lastMonth = {
  startDate: addDays(subMonths(startOfToday(), 1), 1),
  endDate: endOfToday()
};
var nextYear = {
  startDate: startOfToday(),
  endDate: addYears(endOfToday(), 1)
};
var getDateRange = function getDateRange(dateRangeOption) {
  switch (dateRangeOption) {
    case DATE_RANGE_OPTIONS.TODAY:
      return {
        startDate: startOfToday(),
        endDate: endOfToday()
      };

    case DATE_RANGE_OPTIONS.YESTERDAY:
      return {
        startDate: startOfYesterday(),
        endDate: endOfYesterday()
      };

    case DATE_RANGE_OPTIONS.LAST_WEEK:
      return lastWeek;

    case DATE_RANGE_OPTIONS.LAST_MONTH:
      return lastMonth;

    case DATE_RANGE_OPTIONS.LAST_6_MONTHS:
      return {
        startDate: addDays(subMonths(startOfToday(), 6), 1),
        endDate: endOfToday()
      };

    case DATE_RANGE_OPTIONS.LAST_YEAR:
      return {
        startDate: addDays(subYears(startOfToday(), 1), 1),
        endDate: endOfToday()
      };

    case DATE_RANGE_OPTIONS.NEXT_MONTH:
      return {
        startDate: startOfToday(),
        endDate: addDays(addMonths(endOfToday(), 1), 1)
      };

    case DATE_RANGE_OPTIONS.NEXT_6_MONTHS:
      return {
        startDate: startOfToday(),
        endDate: addDays(addMonths(endOfToday(), 6), 1)
      };

    case DATE_RANGE_OPTIONS.NEXT_YEAR:
      return nextYear;

    default:
      return {};
  }
};
var initialDatePickerLastMonth = {
  dateRange: lastMonth,
  dateRangeType: DATE_RANGE_OPTIONS.LAST_MONTH
};
var initialDatePickerLastWeek = {
  dateRange: lastWeek,
  dateRangeType: DATE_RANGE_OPTIONS.LAST_WEEK
};
var initialDatePickerNextYear = {
  dateRange: nextYear,
  dateRangeType: DATE_RANGE_OPTIONS.NEXT_YEAR
};
var NUMBER_OF_YEARS = {
  GENERAL: 2,
  STATEMENTS: 2
};
var padTime = function padTime(number) {
  return String(number || 0).padStart(2, '0');
}; // Days/month calculating algorithm from VTB database
// (extracted into `getDifferenceInMonthsOrDays` function)
//
// SET ANSI_NULLS ON
// GO
// SET QUOTED_IDENTIFIER ON
// GO
// -- =============================================
// -- Author:          <Author,,Name>
// -- Create date: <Create Date, ,>
// -- Description:     <Description, ,>
// -- =============================================
// alter FUNCTION vtb.loan_left_remaining_term
// (
//       @END_DATE DATETIME
// )
// RETURNS NVARCHAR(max)
// AS
// BEGIN
//       IF(@END_DATE<GETDATE())
//              RETURN '0 days'
//       DECLARE @TEMP_DATE datetime=GETDATE();
//       DECLARE @RESULT NVARCHAR(max)
//       DECLARE @MONTHS INT =0;
//       DECLARE @DAYS_DIF INT =0;
//       WHILE @TEMP_DATE<=@END_DATE
//       BEGIN
//              SET @TEMP_DATE=DATEADD(MONTH,1, @TEMP_DATE);
//              SET @MONTHS=@MONTHS+1
//       END
//       SET @TEMP_DATE=DATEADD(MONTH,-1, @TEMP_DATE);
//       SET @MONTHS=@MONTHS-1;
//       SET @DAYS_DIF=DATEDIFF(DAY,@TEMP_DATE, @END_DATE);
//       IF(@MONTHS=0)
//       BEGIN
//              SET @RESULT =      CAST(@DAYS_DIF AS NVARCHAR(255)) +' days';
//       END
//       ELSE IF(@DAYS_DIF<=15)
//       BEGIN
//              SET @RESULT = CAST(@MONTHS AS VARCHAR(255))+' monthes';
//       END
//       ELSE IF(@DAYS_DIF>15)
//       BEGIN
//              SET @RESULT = CAST(@MONTHS+1 AS VARCHAR(255))+' monthes';
//       END
//       ELSE
//       BEGIN
//              SET @RESULT = 'oops';
//       END
//       --RETURN CAST(@DAYS_DIF AS NVARCHAR(255));
//       RETURN @RESULT;
// END
// GO

var getParsedDate = function getParsedDate(date, currentLanguage, formatPattern) {
  return moment(date).locale(currentLanguage).format(formatPattern);
};
var updateShortMonthFormat = function updateShortMonthFormat(moment) {
  var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : LANGUAGES.RU;
  // shrinks short months (MMM) to 3 letters and also upper-case first letter for 'ru' language
  moment.updateLocale(locale, {
    monthsShort: moment.localeData(locale).monthsShort().map(function (month) {
      if (locale === LANGUAGES.RU) {
        return (month === null || month === void 0 ? void 0 : month.charAt(0).toUpperCase()) + (month === null || month === void 0 ? void 0 : month.slice(1, 3));
      }

      return month === null || month === void 0 ? void 0 : month.slice(0, 3);
    })
  });
};
var defaultCalendarOptions = function defaultCalendarOptions(translations) {
  return [{
    value: DATE_RANGE_OPTIONS.TODAY,
    label: translations['dateRange.today']
  }, {
    value: DATE_RANGE_OPTIONS.YESTERDAY,
    label: translations['dateRange.yesterday']
  }, {
    value: DATE_RANGE_OPTIONS.LAST_WEEK,
    label: translations['dateRange.lastWeek']
  }, {
    value: DATE_RANGE_OPTIONS.LAST_MONTH,
    label: translations['dateRange.lastMonth']
  }, {
    value: DATE_RANGE_OPTIONS.LAST_6_MONTHS,
    label: translations['dateRange.lastSixMonths']
  }, {
    value: DATE_RANGE_OPTIONS.LAST_YEAR,
    label: translations['dateRange.lastYear']
  }, {
    value: DATE_RANGE_OPTIONS.CUSTOM_PERIOD,
    label: translations['dateRange.customPeriod']
  }];
};
var futureCalendarOptions = function futureCalendarOptions(translations) {
  return [{
    value: DATE_RANGE_OPTIONS.NEXT_MONTH,
    label: translations['dateRange.nextMonth']
  }, {
    value: DATE_RANGE_OPTIONS.NEXT_6_MONTHS,
    label: translations['dateRange.nextSixMonths']
  }, {
    value: DATE_RANGE_OPTIONS.NEXT_YEAR,
    label: translations['dateRange.nextYear']
  }, {
    value: DATE_RANGE_OPTIONS.CUSTOM_PERIOD,
    label: translations['dateRange.customPeriod']
  }];
};
var signingRulesCalendarOptions = function signingRulesCalendarOptions(translations) {
  return [{
    value: DATE_RANGE_OPTIONS.TODAY,
    label: translations['dateRange.today']
  }, {
    value: DATE_RANGE_OPTIONS.LAST_WEEK,
    label: translations['dateRange.currentWeek']
  }, {
    value: DATE_RANGE_OPTIONS.LAST_MONTH,
    label: translations['dateRange.currentMonth']
  }, {
    value: DATE_RANGE_OPTIONS.NEXT_6_MONTHS,
    label: translations['dateRange.nextSixMonths']
  }, {
    value: DATE_RANGE_OPTIONS.CUSTOM_PERIOD,
    label: translations['dateRange.customPeriod']
  }];
};
var DATE_FORMATS = {
  DDMMM: 'DD MMM',
  DDMMMYY: 'DD MMM YY',
  DDMMMYYYY: 'DD MMM YYYY',
  DDMMYYYY: 'DD/MM/YYYY'
};

export { DATE_FORMATS, DATE_RANGE_OPTIONS, NUMBER_OF_YEARS, defaultCalendarOptions, futureCalendarOptions, getDateAfter, getDateBefore, getDateRange, getDifferenceInMonthsOrDays, getParsedDate, initialDatePickerLastMonth, initialDatePickerLastWeek, initialDatePickerNextYear, isInExpirationPeriod, nextWorkingDay, padTime, signingRulesCalendarOptions, updateShortMonthFormat };
