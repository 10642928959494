import { composeFragments, pathFragment } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withResetForm, withFieldsSelectors } from 'core-recipes';

var usersFilterFieldsFragment = composeFragments(pathFragment('corporateUsers', 'usersFilter'), fieldsFragment({})(fieldFragment({
  name: 'names',
  rules: []
}), fieldFragment({
  name: 'types',
  rules: []
}), fieldFragment({
  name: 'roles',
  rules: []
}), fieldFragment({
  name: 'statuses',
  rules: []
})), withResetForm({}), withFieldsSelectors);

export { usersFilterFieldsFragment };
