import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { listFragment } from 'core-recipes';
import { composeFragments, action, state, extendInitialState } from 'core-ingredients';
import { AssetsUtils } from 'vtb-utils';
import { hasNotListData } from '../../../utils/util-fns.js';
import { getCategoriesQuery } from './bill-payment-categories.query.js';
import { currentCategorySelector } from './selectors.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var loadedActionHandlerFactory = function loadedActionHandlerFactory(entityName, listName, mapFn) {
  return function (_, newState) {
    var data = newState[listName];

    if (hasNotListData({
      data: data
    }, entityName)) {
      return [];
    }

    var list = data[entityName].map(mapFn);
    return list;
  };
};
var mapCategoryFn = function mapCategoryFn(category) {
  return _objectSpread(_objectSpread({}, category), {}, {
    Icon: AssetsUtils.composeAssetsUrl(category.categoryIconUri),
    text: category.text,
    id: category.categoryId
  });
};
var billPaymentFragment = composeFragments(listFragment({
  listName: 'categories',
  entityName: 'categories',
  listQuery: _objectSpread({}, getCategoriesQuery()),
  loadedHandler: loadedActionHandlerFactory('BPCategories', 'categories', mapCategoryFn)
}), action('setCategoryId', function (id) {
  return {
    id: id
  };
}), state(['currentCategoryId', 'setCategoryId'], function (_, _ref) {
  var id = _ref.id;
  return id;
}), currentCategorySelector, extendInitialState(null, {
  currentCategoryId: null
}));

export { billPaymentFragment, loadedActionHandlerFactory, mapCategoryFn };
