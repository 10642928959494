import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { groupFormFragment } from './template-group-form.fragment.js';
import { paymentGroupFieldsFragment } from './template-group.fields.js';

var templateGroupFormLogic = logic2(composeFragments(pathFragment('templates', 'groupForm'), paymentGroupFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, groupFormFragment));
var templateGroupFormReducer = templateGroupFormLogic.reducer;
var templateGroupFormSaga = sagaifyLogic2(templateGroupFormLogic);

export { templateGroupFormLogic, templateGroupFormReducer, templateGroupFormSaga };
