import _defineProperty from '@babel/runtime/helpers/defineProperty';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var defaultParams = {
  useBaseUrl: true,
  assetsProvider: 'assets'
};
var assetsPaths = {
  assets: '/web-assets',
  gds: '/vtb-gds/public/image',
  ibs: '/vtb-mch/ibs'
};
var composeAssetsUrl = function composeAssetsUrl(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var mergedParams = _objectSpread(_objectSpread({}, defaultParams), params);

  if (mergedParams.useBaseUrl) {
    var assetsPath = assetsPaths[mergedParams.assetsProvider] || assetsPaths[defaultParams.assetsProvider];
    return assetsPath + url;
  }

  return url;
};
var composeUrlByReference = function composeUrlByReference(reference) {
  return composeAssetsUrl("/account/v1/accountHolders/profile/picture/".concat(reference), {
    assetsProvider: 'ibs'
  });
};
var composeUrlByTemplateId = function composeUrlByTemplateId(entityUniqueId) {
  return composeAssetsUrl("/PaymentTemplates/".concat(entityUniqueId, "/beneficiaryAvatar"), {
    assetsProvider: 'gds'
  });
};
var composeUrlByBillPaymentTemplateId = function composeUrlByBillPaymentTemplateId(templateId) {
  return composeAssetsUrl("/BPTemplates/".concat(templateId, "/templateIcon"), {
    assetsProvider: 'gds'
  });
};
var composeUrlByCarTemplateId = function composeUrlByCarTemplateId(carTemplateId) {
  return composeAssetsUrl("/CarTemplates/".concat(carTemplateId, "/carTemplateIcon"), {
    assetsProvider: 'gds'
  });
};

export { composeAssetsUrl, composeUrlByBillPaymentTemplateId, composeUrlByCarTemplateId, composeUrlByReference, composeUrlByTemplateId };
