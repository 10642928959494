import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { map, has, find, propEq, groupBy, path, pathOr, pathEq, merge } from 'ramda';
import { parse } from 'date-fns';
import { isCardAccount, composeIcon, composeEntityName, isCreditCardAccount, isCurrentAccount, getDifferenceInMonthsOrDays } from 'vtb-utils';
import { LOAN_CATEGORIES, LOAN_PAYMENT_TYPES } from '../loans.constants.js';
import { calculateLoanRepaid, calculateDuePayment, calculateFullRepayment, processDebtLoan } from './debt-loan.helpers.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var isLoanConsumer = function isLoanConsumer(loan) {
  return loan.category === LOAN_CATEGORIES.CONSUMER;
};
var isLoanMortgage = function isLoanMortgage(loan) {
  return loan.category === LOAN_CATEGORIES.MORTGAGE_LOAN;
};
var isLoanCreditCard = function isLoanCreditCard(loan) {
  return isCreditCardAccount(path(['accountType', 'accTypeId'], loan.account), path(['accountSubType', 'id'], loan.account));
};
var isFullRepayment = function isFullRepayment(loan) {
  var paymentType = path(['productType', 'repaymentType', 'typeID'], loan);

  if (!paymentType) {
    // fallback -> decide upon account type
    var accountType = path(['account', 'accountType', 'accTypeId'], loan);
    return isCurrentAccount(accountType) || isCardAccount(accountType);
  }

  return paymentType.trim() === LOAN_PAYMENT_TYPES.FULL_REPAYMENT.trim();
};
var calculateRemainingTerm = function calculateRemainingTerm(loan) {
  var CurrentDate = new Date();
  var parsedEndDate = parse(loan.endDate);

  var _getDifferenceInMonth = getDifferenceInMonthsOrDays(parsedEndDate, CurrentDate),
      numberOfMonths = _getDifferenceInMonth.months,
      numberOfDays = _getDifferenceInMonth.days; // https://support.bsc-ideas.com/jira/browse/P20009327-5616


  var _getDifferenceInMonth2 = getDifferenceInMonthsOrDays(parsedEndDate, loan.startDate),
      outOfMonths = _getDifferenceInMonth2.months,
      outOfDays = _getDifferenceInMonth2.days;

  return {
    valueInMonths: numberOfMonths > 0,
    value: numberOfMonths > 0 ? numberOfMonths : numberOfDays,
    outOfInMonths: outOfMonths > 0,
    outOf: outOfMonths > 0 ? outOfMonths : outOfDays
  };
};
var calculateSpentLimit = function calculateSpentLimit(_ref) {
  var loan = _ref.loan,
      debtLoan = _ref.debtLoan;

  if (!debtLoan) {
    return null;
  }

  return {
    value: debtLoan && debtLoan.withdrawnLoanAmount || 0,
    outOf: loan && loan.loanAmount || 0
  };
};

var processConsumerLoan = function processConsumerLoan(loan, debtLoan) {
  return _objectSpread(_objectSpread({}, loan), {}, {
    id: loan.cbsLoanId,
    icon: composeIcon(loan),
    friendlyName: composeEntityName(loan),
    remainingTerm: calculateRemainingTerm(loan),
    isLoanConsumer: isLoanConsumer(loan),
    isLoanMortgage: isLoanMortgage(loan),
    isCreditCard: isLoanCreditCard(loan),
    isFullRepayment: isFullRepayment(loan),
    loanRepaid: calculateLoanRepaid({
      debtLoan: debtLoan
    })
  });
};

var processFullRepaymentLoan = function processFullRepaymentLoan(_ref2) {
  var loan = _ref2.loan,
      debtLoan = _ref2.debtLoan,
      MCH_LOAN_DUE_DAYS_NOTIFICATION = _ref2.MCH_LOAN_DUE_DAYS_NOTIFICATION;
  return {
    duePayment: calculateDuePayment({
      debtLoan: debtLoan,
      isFullRepayment: true,
      MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
    }),
    fullRepayment: calculateFullRepayment({
      debtLoan: debtLoan,
      isFullRepayment: true
    }),
    spentLimit: calculateSpentLimit({
      loan: loan,
      debtLoan: debtLoan
    })
  };
};

var getCardAccounts = function getCardAccounts(loans) {
  return loans.filter(function (account) {
    return isCardAccount(path(['account', 'accountType', 'accTypeId'], account));
  }).map(path(['account']));
};
var processLoanList = function processLoanList(loans, debtLoans, MCH_LOAN_DUE_DAYS_NOTIFICATION, accountCards) {
  var loansData = loans.map(function (loan) {
    var cards = [];
    accountCards.forEach(function (card) {
      var _card$ibanAccount, _loan$account;

      if (((_card$ibanAccount = card.ibanAccount) === null || _card$ibanAccount === void 0 ? void 0 : _card$ibanAccount.iban) === (loan === null || loan === void 0 ? void 0 : (_loan$account = loan.account) === null || _loan$account === void 0 ? void 0 : _loan$account.ibanAccount)) {
        cards.push(card);
      }
    });
    var debtLoan = path(['data'], find(pathEq(['data', 'loanId'], loan.cbsLoanId))(debtLoans));
    var processedLoan = processConsumerLoan(_objectSpread(_objectSpread({}, loan), {}, {
      cards: cards
    }), debtLoan);

    if (processedLoan.isFullRepayment) {
      return merge(processedLoan, processFullRepaymentLoan({
        loan: processedLoan,
        debtLoan: debtLoan,
        MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
      }));
    }

    return merge(processedLoan, processDebtLoan({
      debtLoan: debtLoan,
      MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
    }));
  });
  return loansData;
};
var processLoansByCategory = function processLoansByCategory(loans) {
  return groupBy(function (loan) {
    return loan.category;
  })(loans);
};
var processLoanWithAccount = function processLoanWithAccount(loans, cardAccountsWithCards) {
  return map(function (loan) {
    var cardAccount = null;

    if (!loan || !has('account')(loan)) {
      return _objectSpread(_objectSpread({}, loan), {}, {
        cardAccount: cardAccount
      });
    }

    cardAccount = find(propEq('iban', loan.account.ibanAccount))(cardAccountsWithCards);
    return _objectSpread(_objectSpread({}, loan), {}, {
      cardAccount: cardAccount
    });
  })(loans);
};
var processLoanCategories = function processLoanCategories(loans) {
  return map(function (loan) {
    return _objectSpread(_objectSpread({}, loan), {}, {
      category: pathOr(null, ['productType', 'prodTypeCategory', 'entityUniqueId'], loan)
    });
  })(loans);
};

export { calculateRemainingTerm, calculateSpentLimit, getCardAccounts, isFullRepayment, isLoanConsumer, isLoanCreditCard, isLoanMortgage, processLoanCategories, processLoanList, processLoanWithAccount, processLoansByCategory };
