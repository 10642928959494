import { resolveLogic, composeFragments, pathFragment } from 'core-ingredients';
import { userHasNoAccountsSelector } from '../../payment-centre/common/selectors.js';
import { signingProceduresFragment } from './fragments/signing-procedures-fragment.js';
import { exchangeRatesFragment } from './fragments/exchange-rates-fragment.js';

var signingProceduresLogicAPI = resolveLogic(composeFragments(pathFragment('signingProcedures', 'list'), signingProceduresFragment, exchangeRatesFragment, userHasNoAccountsSelector));
var signingProceduresLogic = signingProceduresLogicAPI.logic;
var SigningProceduresListReducer = signingProceduresLogicAPI.reducer;
var SigningProceduresListSaga = signingProceduresLogicAPI.saga;

export { SigningProceduresListReducer, SigningProceduresListSaga, signingProceduresLogic, signingProceduresLogicAPI };
