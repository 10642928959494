import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { accountsSettingsFragment } from './fragments/accounts-settings.fragment.js';
import { productIconsFragment } from './fragments/product-icons.fragment.js';
import { loansSettingsFragment } from './fragments/loans-settings-fragment.js';
import { savingsSettingsFragment } from './fragments/savings-settings-fragment.js';
import { exchangeRatesFragment } from '../../../exchange-rates/header/exchange-rates-header.fragment.js';

var accountSettingsLogic = logic2(composeFragments(pathFragment('accounts', 'settings'), accountsSettingsFragment(), exchangeRatesFragment(), productIconsFragment(), loansSettingsFragment(), savingsSettingsFragment()));
var accountSettingsReducer = accountSettingsLogic.reducer;
var accountSettingsSaga = sagaifyLogic2(accountSettingsLogic);

export { accountSettingsLogic, accountSettingsReducer, accountSettingsSaga };
