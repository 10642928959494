import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { pipe, map, filter } from 'ramda';
import { areThereAnyChanges } from './validate-cars.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var prepareCarsForRequest = function prepareCarsForRequest(cars) {
  var index = 0;
  return pipe(map(function (item) {
    var car = _objectSpread(_objectSpread({}, item), {}, {
      sortOrder: index + 1
    });

    if (car.newCarTemplateIcon) {
      car.carTemplateIcon = car.newCarTemplateIcon;
    }

    index++;
    return car;
  }), filter(areThereAnyChanges(cars, 'sortOrder')));
};

export { prepareCarsForRequest };
