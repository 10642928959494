import { channel } from 'redux-saga'
import { Logger, LoggingProvider, remoteLogHandler, devLogHandler, LOG_LEVEL } from 'vtb-utils'
import { prepareLoggingSaga } from 'vtb-logic'

const loggingChannel = channel()

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') {
  const appLabel = 'GIB'
  LoggingProvider.bootstrap(devLogHandler(appLabel))
  Logger.level(LOG_LEVEL.DEBUG)
} else {
  // eslint-disable-next-line no-undef
  const version = process.env.GIB_VERSION
  const appLabel = `GIB (${version})`
  LoggingProvider.bootstrap(remoteLogHandler(appLabel, loggingChannel.put))
}

export const loggingSaga = prepareLoggingSaga(loggingChannel)
