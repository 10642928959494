import { fetchFragment } from 'core-recipes';
import { resolveLogic, composeFragments, pathFragment, action, state } from 'core-ingredients';

var loyaltyLogicAPI = resolveLogic(composeFragments(pathFragment('loyalty'), fetchFragment({
  valueKey: 'points',
  valueQuery: {
    loyalty: []
  },
  supplier: 'ibs'
}), action('updatePoints', function (points) {
  return {
    points: points
  };
}), state(['points', 'updatePoints'], function (_, _ref) {
  var points = _ref.points;
  return points;
})));
var loyaltyLogic = loyaltyLogicAPI.logic,
    loyaltyReducer = loyaltyLogicAPI.reducer,
    loyaltySaga = loyaltyLogicAPI.saga;

export { loyaltyLogic, loyaltyReducer, loyaltySaga };
