import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import { createGlobalStyle } from 'styled-components';
import englishFontCss from '../../assets/fonts-english.css';
import georgianFontCss from '../../assets/fonts-georgian.css';
import russianFontCss from '../../assets/fonts-russian.css';

var _templateObject, _templateObject2, _templateObject3;
var globalStyles = "\n  body {\n    margin: 0;\n  }\n\n  .ReactModal__Html--open {\n    overflow: hidden;\n    margin-right: 10px;\n  }\n"; // Chrome on windows has a problem with antialiasing bold Helvetica font,

var isChrome = window.navigator.userAgent.includes('Chrome');
var isWindows = window.navigator.userAgent.includes('Windows');
var suffix = isChrome && isWindows ? '-win-chrome' : '';
var EnglishFont = createGlobalStyle(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  html {\n    font-family: 'Helvetica-neue", "', sans-serif;\n  }\n  ", "\n"])), englishFontCss, suffix, globalStyles);
var GeorgianFont = createGlobalStyle(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  html {\n    font-family: 'HelveticaNeueLTGEO", "', sans-serif;\n  }\n  ", "\n"])), georgianFontCss, suffix, globalStyles);
var RussianFont = createGlobalStyle(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  html {\n    font-family: 'HelveticaNeueCyr", "', sans-serif;\n  }\n  ", "\n"])), russianFontCss, suffix, globalStyles);

export { EnglishFont, GeorgianFont, RussianFont };
