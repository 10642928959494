import { connectRouter } from 'connected-react-router'
import { authReducer } from 'core-auth-client'
import { catalogsReducer, localizationReducer, systemParamsReducer } from 'core-configuration'
import { dataReducer } from 'core-supply'
import { History } from 'history'
import { combineReducers, ReducerMap } from 'vtb-app/store'
import {
  accountReducers,
  allAuthReducers,
  appReducer,
  cardsReducers,
  certificationReducer,
  userReducer,
  clientReducer,
  connectionReducer,
  contextReducer,
  corporateUsersReducers,
  digipassReducer,
  loansReducers,
  loyaltyReducer,
  movementExportReducer,
  movementsReducers,
  otpCertificationReducer,
  productsReducers,
  savingsReducers,
  sharedReducer,
  signingProceduresReducers,
  signingRulesReducers,
  smsCertificationReducer,
  statementsReducer,
  transferReducers,
  vtbOtpCertificationReducer,
  vtbSmsCertificationReducer,
  // lazy
  carsReducers,
  currencyExchangeRequestReducer,
  currentOrdersBeneficiarySearchReducer,
  currentOrdersReducer,
  ddReducers,
  exchangeRatesListReducer,
  exchangeRatesReducer,
  messageCenterReducers,
  movementsBeneficiarySearchReducer,
  notificationSettingsReducer,
  popularPaymentsReducer,
  securityReducers,
  soPaymentCentreReducer,
  soReducers,
  templatesReducers,
  videochatReducer,
  whatsNewReducer,
  cardBlocksReducers,
  layoutReducers,
  profileSettingsReducers,
  openBankingLogicReducer,
  openBankingDetailReducer,
  offersReducer,
} from 'vtb-logic'

export default (history: History) => (asyncReducers: ReducerMap = {}) =>
  combineReducers({
    app: appReducer,
    router: connectRouter(history),
    context: contextReducer,
    shared: sharedReducer,
    data: dataReducer,
    localization: localizationReducer,
    catalogs: catalogsReducer,
    certification: certificationReducer,
    certificationSms: smsCertificationReducer,
    certificationVtbSms: vtbSmsCertificationReducer,
    certificationOtp: otpCertificationReducer,
    certificationVtbOtp: vtbOtpCertificationReducer,
    systemParams: systemParamsReducer,
    auth: authReducer,
    authControl: allAuthReducers,
    connection: connectionReducer,
    accounts: accountReducers,
    cards: cardsReducers,
    products: productsReducers,
    savings: savingsReducers,
    loans: loansReducers,
    openBanking: openBankingLogicReducer,
    openBankingDetail: openBankingDetailReducer,
    offers: offersReducer,
    statements: statementsReducer,
    movements: movementsReducers,
    user: userReducer,
    client: clientReducer,
    transfers: transferReducers,
    loyalty: loyaltyReducer,
    movementExport: movementExportReducer,
    corporateUsers: corporateUsersReducers,
    signingRules: signingRulesReducers,
    signingProcedures: signingProceduresReducers,
    digipass: digipassReducer,
    // lazy
    transactions: cardBlocksReducers,
    layout: layoutReducers,
    profile: profileSettingsReducers,
    exchangeRates: exchangeRatesReducer,
    exchangeRatesList: exchangeRatesListReducer,
    currentOrders: currentOrdersReducer,
    videochat: videochatReducer,
    templates: templatesReducers,
    popularPayments: popularPaymentsReducer,
    security: securityReducers,
    so: soReducers,
    dd: ddReducers,
    paymentCentre: soPaymentCentreReducer,
    cars: carsReducers,
    messageCenter: messageCenterReducers,
    movementsBeneficiarySearch: movementsBeneficiarySearchReducer,
    currentOrdersBeneficiarySearch: currentOrdersBeneficiarySearchReducer,
    whatsNew: whatsNewReducer,
    currencyExchangeRequest: currencyExchangeRequestReducer,
    corporateNotifs: notificationSettingsReducer,
    ...asyncReducers,
  })
