import { fieldsFragment, fieldFragment, withDependentField, withDependentFieldFlow, withDynamicFieldValidation } from 'core-recipes';
import { validationRule, autocorrectingValidationRule } from 'core-ingredients';
import { validationConfig } from './mobile-topup.fields.validations.js';
import { requiredRule, charset } from '../../utils/validators.js';

var transferMTUFieldsFragment = function transferMTUFieldsFragment() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return fieldsFragment(options)(fieldFragment({
    name: 'debitAccount',
    initialValue: '',
    rules: [validationRule(requiredRule)]
  }), fieldFragment({
    name: 'amount',
    initialValue: '',
    rules: [validationRule(requiredRule), autocorrectingValidationRule(charset(/[0-9]*/))]
  }, // HACK: serviceCode field is missing form mobileTopUp so we set source as phoneNumber, which
  //       triggers load of services (serviceCode)
  withDependentField({
    source: 'phoneNumber',
    computation: validationConfig.amount.dependentField,
    computationSelector: function computationSelector(selectors) {
      return selectors.services;
    }
  }), withDependentFieldFlow({
    source: 'amount',
    sourceActionName: 'update',
    updateHandler: validationConfig.amount.dependentFlow
  })), fieldFragment({
    name: 'phoneNumber',
    initialValue: '',
    rules: [validationRule(requiredRule)]
  }, withDependentFieldFlow({
    source: 'phoneNumber',
    sourceActionName: 'update',
    updateHandler: validationConfig.phoneNumber.dependentFlow
  }), withDynamicFieldValidation({
    query: validationConfig.phoneNumber.dynamicFieldValidationFlow,
    isCallable: true,
    code: 'required',
    triggerActionName: 'phoneNumberValidation',
    validation: validationConfig.phoneNumber.dynamicFieldValidation
  }), withDynamicFieldValidation({
    validationKey: 'phoneNumberLength',
    query: validationConfig.phoneNumber.dynamicFieldValidationFlow,
    isCallable: true,
    code: 'required',
    triggerActionName: 'phoneNumberValidation',
    validation: validationConfig.phoneNumber.dynamicFieldLengthValidation
  }), withDynamicFieldValidation({
    validationKey: 'providerNotFoundValidation',
    query: validationConfig.phoneNumber.providerNotFound,
    isCallable: true,
    code: 'required',
    triggerActionName: 'providerNotFoundValidation',
    validation: validationConfig.phoneNumber.providerNotFoundValidation
  })), fieldFragment({
    name: 'mobilePrefix',
    initialValue: '',
    rules: [validationRule(requiredRule)]
  }));
};

export { transferMTUFieldsFragment };
