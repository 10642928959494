import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeLatest } from 'redux-saga/effects';

var infiniteFragment = function infiniteFragment(_ref) {
  var _actions;

  var name = _ref.name,
      flow = _ref.flow;
  var actionName = 'loadMore' + name.charAt(0).toUpperCase() + name.slice(1);
  return {
    actions: (_actions = {}, _defineProperty(_actions, actionName, function (params) {
      return params;
    }), _defineProperty(_actions, "setPageSize", function setPageSize(size) {
      return {
        size: size
      };
    }), _defineProperty(_actions, "setPaging", function setPaging(data) {
      return {
        data: data
      };
    }), _actions),
    state: {
      pageSize: {
        'actions.setPageSize': function actionsSetPageSize(_, _ref2) {
          var size = _ref2.size;
          return size;
        }
      },
      paging: {
        'actions.setPaging': function actionsSetPaging(_, _ref3) {
          var data = _ref3.data;
          return data;
        }
      }
    },
    selectors: {
      pageSize: [['pageSize']],
      paging: [['paging']],
      after: [function (selectors) {
        return [selectors.paging];
      }, function (value) {
        return value && value.cursors && value.cursors.after;
      }]
    },
    flows: _defineProperty({}, actionName, ["actions.".concat(actionName), takeLatest, flow]),
    initialState: {
      pageSize: 30,
      paging: null
    }
  };
};

export { infiniteFragment };
