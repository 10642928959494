import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { templateIconFragment } from '../../../templates/templates-icon-fragment.js';
import { billPaymentFragment } from '../../wizard/bill-payment.fragment.js';
import { mainFormFragment } from './bill-payment-template-main-form.fragment.js';
import { billPaymentTemplateFieldsFragment } from './bill-payment-form.fields.js';
import { bpTemplatesFragment } from '../../../common/bp-templates/bp-templates.fragment.js';
import { providerFragment } from '../../../common/provider/provider.fragment.js';
import { debtAndCommissionFragment } from '../../../common/debt-and-commision/debt-and-commission-fragment.js';

var billPaymentTemplateMainFormLogic = logic2(composeFragments(pathFragment('transfers', 'billTemplateForm'), bpTemplatesFragment, // config form and core fragments:
billPaymentTemplateFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, // providers and services
providerFragment, // BP categories & movements:
billPaymentFragment, // edit provider/template icons
templateIconFragment, // debt info
debtAndCommissionFragment, // template form handling
mainFormFragment));
var bpTemplateMainFormReducer = billPaymentTemplateMainFormLogic.reducer;
var bpTemplateMainFormSaga = sagaifyLogic2(billPaymentTemplateMainFormLogic);

export { billPaymentTemplateMainFormLogic, bpTemplateMainFormReducer, bpTemplateMainFormSaga };
