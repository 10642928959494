import { pipe, split, last, head, replace, toUpper } from 'ramda';
import { isPropNotEmpty } from '../validation/validation.js';

var georgianCharset = 'აბგდევზთიკლმნოპჟრსტუფქღყშჩცძწჭხჯჰ';
var georgianUnicodeCharset = "\u10D0\u10D1\u10D2\u10D3\u10D4\u10D5\u10D6\u10D7\u10D8\u10D9\u10DA\u10DB\u10DC\u10DD\u10DE\u10DF\u10E0\u10E1\u10E2\u10E3\u10E4\u10E5\u10E6\u10E7\u10E8\u10E9\u10EA\u10EB\u10EC\u10ED\u10EE\u10EF\u10F0";
var russianCharset = 'АаБбВвГгДдЕеЁёЖжЗзИиЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЪъЫыЬьЭэЮюЯя';
var nonBreakableText = function nonBreakableText(template) {
  return isPropNotEmpty(template) ? template.replace(/\s/g, "\xA0") : template;
};
/**
 * Returns last part of string splited by slash
 */

var getLocationId = pipe(split('/'), last);
/**
 * Splits given string by empty space and returns first letter of first two of them
 * Do not uppercase georgian chars
 */

var firstTwoInitials = function firstTwoInitials(string) {
  var upperCase = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

  if (!string || string.length === 0) {
    return '';
  }

  var firstTwoInitials = string.split(' ').map(function (word) {
    return word[0];
  }).join('').slice(0, 2);
  var isGeorgianChars = new RegExp("[".concat(georgianCharset).concat(georgianUnicodeCharset, "]+"));
  return upperCase && !isGeorgianChars.test(firstTwoInitials) ? firstTwoInitials.toUpperCase() : firstTwoInitials;
};
var isSubString = function isSubString(string, substring) {
  return string && substring && string.toLowerCase().includes(substring.toLowerCase());
};
var truncateText = function truncateText(text, length) {
  if ((text === null || text === void 0 ? void 0 : text.length) > length) {
    return "".concat(text.substring(0, length), "...");
  }

  return text;
};
var capitalizeNonGeorgian = function capitalizeNonGeorgian(input) {
  var firstLetter = head(input);
  var isGeorgianChars = new RegExp("[".concat(georgianCharset).concat(georgianUnicodeCharset, "]+"));
  return isGeorgianChars.test(firstLetter) ? input : replace(firstLetter, toUpper(firstLetter), input);
};

export { capitalizeNonGeorgian, firstTwoInitials, georgianCharset, georgianUnicodeCharset, getLocationId, isSubString, nonBreakableText, russianCharset, truncateText };
