var USER_SETTINGS_REALMS = {
  sorting: 'sorting',
  common: 'common',
  hiddenAccounts: 'hiddenAccounts',
  ACCOUNT_SORTING: 'accountSorting',
  BASKET_TEMPLATE_SORTING: 'basketTemplateSorting',
  SO_LIST: 'soList',
  DD_LIST: 'ddList',
  BATCH_CONTEXTS_LIST: 'batchContextList',
  BATCH_AVAILABLE_IMPORT: 'batchAvailableImport',
  BATCH_AVAILABLE_EXPORT: 'batchAvailableExport',
  SELECTED_CONTEXT: 'selectedContext',
  CONTEXT_SORTING: 'contextSorting'
}; // TODO here to devide - client-user-sorting

export { USER_SETTINGS_REALMS };
