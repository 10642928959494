import { createStructuredSelector } from 'reselect';
import { getTime, addSeconds } from 'date-fns';
import { createFetch2, post, resolveUrl, get } from 'core-supply';
import { issuerSelector } from 'core-auth-client';

var END_SESSION = 'end-session';
var SESSION_INFO = 'autologoutinfo';
var EXTEND_SESSION = 'resetsessioninactivity';
var parseSessionInfo = function parseSessionInfo(response) {
  var inactivityTimeout = response.stl_inactivity,
      terminationTimeout = response.stl_maxduration;

  if (inactivityTimeout && terminationTimeout) {
    var sessionTimeout = Math.min(inactivityTimeout, terminationTimeout);
    var isActive = sessionTimeout > 0;
    var isExtendable = sessionTimeout === inactivityTimeout;

    if (isActive) {
      var expirationTime = getTime(addSeconds(new Date(), sessionTimeout));
      return {
        isActive: isActive,
        isExtendable: isExtendable,
        expirationTime: expirationTime
      };
    }
  }

  return {
    isActive: false
  };
};
var logoutRequest = function logoutRequest(_ref) {
  var issuer = _ref.issuer;
  var url = "".concat(issuer, "/").concat(END_SESSION);
  return post(url, null, {
    logout: true
  });
};
var sessionInfoRequest = function sessionInfoRequest(_ref2) {
  var issuer = _ref2.issuer;
  var url = resolveUrl("".concat(issuer, "/"), SESSION_INFO);
  return get(url);
};
var extendSessionRequest = function extendSessionRequest(_ref3) {
  var issuer = _ref3.issuer;
  var url = resolveUrl("".concat(issuer, "/"), EXTEND_SESSION);
  return post(url);
};
var logOut = createFetch2(logoutRequest, createStructuredSelector({
  issuer: issuerSelector
}));
var sessionInfo = createFetch2(sessionInfoRequest, createStructuredSelector({
  issuer: issuerSelector
}));
var extendSession = createFetch2(extendSessionRequest, createStructuredSelector({
  issuer: issuerSelector
}));

export { END_SESSION, SESSION_INFO, extendSession, extendSessionRequest, logOut, logoutRequest, parseSessionInfo, sessionInfo, sessionInfoRequest };
