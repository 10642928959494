import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { userInvitationFragment } from './user-invitation.fragment.js';
import { userPhoneNumberFragment } from './user-phone-number.fragment.js';
import { userSettingsFragment } from './user-settings.fragment.js';

var userLogic = logic2(composeFragments(pathFragment('user'), userSettingsFragment, userInvitationFragment, userPhoneNumberFragment));
var userReducer = userLogic.reducer;
var userSaga = sagaifyLogic2(userLogic);

export { userLogic, userReducer, userSaga };
