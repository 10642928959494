import { STANDING_ORDER_TYPES } from './constants.js';

var isSoOwn = function isSoOwn(type) {
  return Boolean(type && type.id === STANDING_ORDER_TYPES.OWN);
};
var isSoTTSE = function isSoTTSE(type) {
  if (!type) {
    return false;
  }

  return Boolean(type.id === STANDING_ORDER_TYPES.FCY || type.id === STANDING_ORDER_TYPES.LCY || type.id === STANDING_ORDER_TYPES.INTRA);
};
var isSoTreasury = function isSoTreasury(type) {
  return Boolean(type && type.id === STANDING_ORDER_TYPES.TRESAURY);
};
var StandingOrderType = {
  BetweenOwnAccounts: 'BetweenOwnAccounts',
  ToSomeOneElse: 'ToSomeOneElse',
  Treasury: 'Treasury'
};
var getStandingOrderType = function getStandingOrderType(_ref) {
  var instruction = _ref.instruction;

  if (instruction.order__creditAccountId) {
    return StandingOrderType.BetweenOwnAccounts;
  }

  if (instruction.order__beneficiaryAccount) {
    return StandingOrderType.ToSomeOneElse;
  }

  if (instruction.order__treasuryCode) {
    return StandingOrderType.Treasury;
  }
};

export { StandingOrderType, getStandingOrderType, isSoOwn, isSoTTSE, isSoTreasury };
