import { resolveLogic, composeFragments, pathFragment, action, state, stateFlag, flow, resolveAction } from 'core-ingredients';
import { takeLatest } from 'redux-saga/effects';
import { signingProceduresSignFragment } from '../common/signingProceduresSignFragment.js';
import { wizardFragment } from '../../generic/wizard.fragment.js';
import { checkInstructionsBalanceErrorsFragment } from '../../payment-centre/common/checkBalanceErrorsFragment.js';
import { spMultipleConfirmFlow } from './signing-procedures-multiple-confirm.flow.js';
import { TRANSFERS_STEPS } from '../../payment-centre/constants.js';

var signingProceduresConfirmMultipleAPI = resolveLogic(composeFragments(pathFragment('signingProcedures', 'confirmMultiple'), wizardFragment(TRANSFERS_STEPS), signingProceduresSignFragment, checkInstructionsBalanceErrorsFragment, action('init'), action('failed'), state(['rateChanged', 'setChangedRates'], function (_, _ref) {
  var rateChanged = _ref.rateChanged;
  return rateChanged;
}, false), state(['changedRates', 'setChangedRates'], function (_, _ref2) {
  var data = _ref2.data;
  return data;
}, null), stateFlag('loading', ['calling'], ['failed', 'reset', 'resetForm', 'stop', 'certificationSubmited'], false), flow('process', [resolveAction('init'), takeLatest, spMultipleConfirmFlow])));
var signingConfirmMultipleLogic = signingProceduresConfirmMultipleAPI.logic;
var SigningConfirmMultipleReducer = signingProceduresConfirmMultipleAPI.reducer;
var SigningConfirmMultipleSaga = signingProceduresConfirmMultipleAPI.saga;

export { SigningConfirmMultipleReducer, SigningConfirmMultipleSaga, signingConfirmMultipleLogic, signingProceduresConfirmMultipleAPI };
