import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { pathOr, merge, or } from 'ramda';
import { composeEntityName, composeIcon, isCurrentAccount, isDebitCardAccount, isCreditCardAccount, isPropEmpty } from 'vtb-utils';
import { isAfter, differenceInCalendarDays } from 'date-fns';
import { LOAN_PRODUCT_TYPES } from '../loans.constants.js';
import { processCollateral } from '../helpers/collateral.helpers.js';
import { isLoanCreditCard, calculateRemainingTerm, isFullRepayment, calculateSpentLimit } from '../helpers/loan.helpers.js';
import { calculateLoanRepaid, calculateDuePayment, calculateFullRepayment, calculateNextPayment, prepareRemainingPrincipal } from '../helpers/debt-loan.helpers.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var isLoanCreditLineOrBusinessOverdraft = function isLoanCreditLineOrBusinessOverdraft(productTypeGroup) {
  return [LOAN_PRODUCT_TYPES.BUSINESS_OVERDRAFT, LOAN_PRODUCT_TYPES.CREDIT_LINE].includes(productTypeGroup);
};

var getInterestRate = function getInterestRate(_ref) {
  var debtLoan = _ref.debtLoan,
      productTypeGroup = _ref.productTypeGroup;

  if (!debtLoan) {
    return null;
  }

  if (isPropEmpty(productTypeGroup)) {
    return undefined;
  }

  var creditCardDebt = debtLoan.creditCardDebt;
  var interestRate = {
    isSubsidedLoan: false,
    isOverdraftCreditCard: false,
    isCreditCard: false,
    isOverdraftBusinessCreditCardLine: false,
    isCreditLine: false
  };

  if (productTypeGroup === LOAN_PRODUCT_TYPES.SUBSIDED_LOAN) {
    interestRate = merge(interestRate, {
      isSubsidedLoan: true,
      subsidedInterestRate: debtLoan.subsidedInterestRate,
      customerInterestRate: debtLoan.customerInterestRate
    });
  }

  if ([LOAN_PRODUCT_TYPES.OVERDRAFT, LOAN_PRODUCT_TYPES.CREDIT_CARD].includes(productTypeGroup)) {
    interestRate = merge(interestRate, {
      isOverdraftCreditCard: true,
      lastStatementDate: creditCardDebt === null || creditCardDebt === void 0 ? void 0 : creditCardDebt.lastStatementDate,
      nextRepaymentDate: creditCardDebt === null || creditCardDebt === void 0 ? void 0 : creditCardDebt.nextRepaymentDate
    });
  }

  if (productTypeGroup === LOAN_PRODUCT_TYPES.CREDIT_CARD) {
    var today = new Date();
    var gracePeriodDate = isAfter(creditCardDebt.interestFreePeriodExpireDate, today) ? creditCardDebt.interestFreePeriodExpireDate : today;
    interestRate = merge(interestRate, {
      isCreditCard: true,
      gracePeriodDate: gracePeriodDate
    });
  }

  if ([LOAN_PRODUCT_TYPES.OVERDRAFT, LOAN_PRODUCT_TYPES.BUSINESS_OVERDRAFT, LOAN_PRODUCT_TYPES.CREDIT_CARD, LOAN_PRODUCT_TYPES.CREDIT_LINE].includes(productTypeGroup)) {
    interestRate = merge(interestRate, {
      isOverdraftBusinessCreditCardLine: true,
      unusedBalance: Number(debtLoan.unusedBalance || 0),
      balance: Number(debtLoan.balance || 0)
    });
  }

  if (productTypeGroup === LOAN_PRODUCT_TYPES.CREDIT_LINE) {
    interestRate = merge(interestRate, {
      isCreditLine: true,
      unusedBalanceInterestRate: Number(debtLoan.unusedBalanceInterestRate || 0)
    });
  }

  return interestRate;
}; // eslint-disable-next-line no-confusing-arrow


var getAccountIban = function getAccountIban(_ref2) {
  var _loan$account, _loan$account2, _loan$account2$accoun, _loan$account3, _loan$account3$accoun, _loan$account4, _loan$account4$accoun, _loan$account5, _loan$account5$accoun, _loan$account6;

  var loan = _ref2.loan;
  return or(isCurrentAccount(loan === null || loan === void 0 ? void 0 : (_loan$account = loan.account) === null || _loan$account === void 0 ? void 0 : _loan$account.accountType.accTypeId), isDebitCardAccount(loan === null || loan === void 0 ? void 0 : (_loan$account2 = loan.account) === null || _loan$account2 === void 0 ? void 0 : (_loan$account2$accoun = _loan$account2.accountType) === null || _loan$account2$accoun === void 0 ? void 0 : _loan$account2$accoun.accTypeId, loan === null || loan === void 0 ? void 0 : (_loan$account3 = loan.account) === null || _loan$account3 === void 0 ? void 0 : (_loan$account3$accoun = _loan$account3.accountSubType) === null || _loan$account3$accoun === void 0 ? void 0 : _loan$account3$accoun.id), isCreditCardAccount(loan === null || loan === void 0 ? void 0 : (_loan$account4 = loan.account) === null || _loan$account4 === void 0 ? void 0 : (_loan$account4$accoun = _loan$account4.accountType) === null || _loan$account4$accoun === void 0 ? void 0 : _loan$account4$accoun.accTypeId, loan === null || loan === void 0 ? void 0 : (_loan$account5 = loan.account) === null || _loan$account5 === void 0 ? void 0 : (_loan$account5$accoun = _loan$account5.accountSubType) === null || _loan$account5$accoun === void 0 ? void 0 : _loan$account5$accoun.id)) ? loan === null || loan === void 0 ? void 0 : (_loan$account6 = loan.account) === null || _loan$account6 === void 0 ? void 0 : _loan$account6.ibanAccount : null;
};

var processCommonLoan = function processCommonLoan(_ref3) {
  var debtLoan = _ref3.debtLoan,
      repaymentSchedule = _ref3.repaymentSchedule,
      MCH_LOAN_DUE_DAYS_NOTIFICATION = _ref3.MCH_LOAN_DUE_DAYS_NOTIFICATION;
  return {
    duePayment: calculateDuePayment({
      debtLoan: debtLoan,
      repaymentSchedule: repaymentSchedule,
      MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
    }),
    nextPayment: calculateNextPayment({
      debtLoan: debtLoan,
      repaymentSchedule: repaymentSchedule,
      MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
    }),
    remainingPrincipal: prepareRemainingPrincipal({
      debtLoan: debtLoan
    })
  };
};

var processCreditCardLoan = function processCreditCardLoan(_ref4) {
  var loan = _ref4.loan,
      debtLoan = _ref4.debtLoan,
      repaymentSchedule = _ref4.repaymentSchedule,
      MCH_LOAN_DUE_DAYS_NOTIFICATION = _ref4.MCH_LOAN_DUE_DAYS_NOTIFICATION;
  return {
    duePayment: calculateDuePayment({
      debtLoan: debtLoan,
      repaymentSchedule: repaymentSchedule,
      isFullRepayment: true,
      MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
    }),
    fullRepayment: calculateFullRepayment({
      debtLoan: debtLoan,
      isFullRepayment: true
    }),
    spentLimit: calculateSpentLimit({
      loan: loan,
      debtLoan: debtLoan
    }),
    iban: loan.account && loan.account.accountType ? getAccountIban({
      loan: loan
    }) : null
  };
};

var processRepaymentSchedule = function processRepaymentSchedule(_ref5) {
  var repaymentSchedule = _ref5.repaymentSchedule;

  if (!repaymentSchedule) {
    return [];
  }

  var currentDate = new Date();
  var openedGroups = [];

  var _repaymentSchedule$re = repaymentSchedule.reduce(function (acc, repaymentScheduleItem, i) {
    acc.items.push(_objectSpread({
      id: repaymentScheduleItem.date,
      installment: repaymentScheduleItem.interestAmount + repaymentScheduleItem.principalAmount + (repaymentScheduleItem.insurance || 0),
      year: repaymentScheduleItem.date.substr(0, 4),
      higlighted: false
    }, repaymentScheduleItem));
    var daysDiff = differenceInCalendarDays(repaymentScheduleItem.date, currentDate);

    if (daysDiff >= 0 && (!acc.leastDays || daysDiff < acc.leastDays)) {
      acc.closestIndex = i;
      acc.leastDays = daysDiff;
    }

    return acc;
  }, {
    items: [],
    leastDays: null,
    closestIndex: null
  }),
      items = _repaymentSchedule$re.items,
      closestIndex = _repaymentSchedule$re.closestIndex;

  if (closestIndex) {
    items[closestIndex].higlighted = true;
    openedGroups.push(items[closestIndex].year);
  }

  return {
    items: items,
    openedGroups: openedGroups
  };
};

var processLoanDetail = function processLoanDetail(_ref6) {
  var loan = _ref6.loan,
      debtLoan = _ref6.debtLoan,
      insuranceLoan = _ref6.insuranceLoan,
      repaymentSchedule = _ref6.repaymentSchedule,
      MCH_LOAN_DUE_DAYS_NOTIFICATION = _ref6.MCH_LOAN_DUE_DAYS_NOTIFICATION,
      collateralLoan = _ref6.collateralLoan,
      accounts = _ref6.accounts;
  var account = loan.account;
  var productTypeGroup = pathOr(null, ['productType', 'detailParamsGroup', 'detailParamsGroupID'], loan);
  var collateral = processCollateral({
    loanAccount: account,
    accounts: accounts,
    collaterals: collateralLoan
  });
  var isCreditLineOrBusinessOverdraft = isLoanCreditLineOrBusinessOverdraft(productTypeGroup);

  var processedLoan = _objectSpread(_objectSpread({}, loan), {}, {
    isCreditCard: isLoanCreditCard(loan),
    friendlyName: composeEntityName(loan),
    icon: composeIcon(loan),
    paidAmount: debtLoan ? debtLoan.withdrawnLoanAmount - debtLoan.outstandingPrincipalAmount : null,
    issuedAmount: debtLoan ? debtLoan.withdrawnLoanAmount : null,
    byProductTypes: getInterestRate({
      debtLoan: debtLoan,
      productTypeGroup: productTypeGroup
    }),
    collateral: collateral,
    insurances: insuranceLoan || [],
    repaymentSchedule: processRepaymentSchedule({
      repaymentSchedule: repaymentSchedule
    }),
    remainingTerm: calculateRemainingTerm(loan),
    loanRepaid: calculateLoanRepaid({
      debtLoan: debtLoan
    }),
    isFullRepayment: isFullRepayment(loan),
    isCreditLineOrBusinessOverdraft: isCreditLineOrBusinessOverdraft,
    allowedLimit: isCreditLineOrBusinessOverdraft ? Number(debtLoan.balance || 0) + Number(debtLoan.unusedBalance || 0) : null
  });

  if (processedLoan.isFullRepayment) {
    return merge(processedLoan, processCreditCardLoan({
      loan: loan,
      debtLoan: debtLoan,
      repaymentSchedule: repaymentSchedule,
      MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
    }));
  }

  return merge(processedLoan, processCommonLoan({
    debtLoan: debtLoan,
    repaymentSchedule: repaymentSchedule,
    MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
  }));
};

export { processLoanDetail };
