import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { DateTime, filterMoreValues, MOVEMENT_TYPES, getLanguageText, getReceiverName, getSenderName } from 'vtb-utils';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var hasAdditionalAccount = function hasAdditionalAccount(movement, transactionTypeID) {
  switch (transactionTypeID) {
    case MOVEMENT_TYPES.OTHER_ACCOUNT_INCOMING:
      return movement.altaDebitAccountIBAN;

    case MOVEMENT_TYPES.OTHER_ACCOUNT_OUTGOING:
      return movement.altaCreditAccountIBAN;
  }
}; // YES, they really send treasuryCode in the receiverAccount (but only occasionally)


var getTreasuryCode = function getTreasuryCode(transTypeID, receiverAccount, altaCreditAccountIBAN, altaDebitAccountIBAN) {
  if (transTypeID === MOVEMENT_TYPES.TREASURY && receiverAccount && receiverAccount !== altaCreditAccountIBAN && receiverAccount !== altaDebitAccountIBAN) {
    return receiverAccount;
  }
};

var prepareMovement = function prepareMovement(movement) {
  var prevAdditionalAccounts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var cards = arguments.length > 2 ? arguments[2] : undefined;
  var templates = arguments.length > 3 ? arguments[3] : undefined;
  var deposits = arguments.length > 4 ? arguments[4] : undefined;
  var lang = arguments.length > 5 ? arguments[5] : undefined;
  var accountsData = arguments.length > 6 ? arguments[6] : undefined;

  var _ref = movement || {},
      receiverAccount = _ref.receiverAccount,
      altaCreditAccountIBAN = _ref.altaCreditAccountIBAN,
      altaDebitAccountIBAN = _ref.altaDebitAccountIBAN,
      movementType = _ref.movementType;

  var _ref2 = movementType || {},
      transTypeID = _ref2.transTypeID;

  var movementWithTransTypeID = _objectSpread(_objectSpread({}, movement), {}, {
    transactionTypeID: transTypeID
  });

  var additionalAccountIban = hasAdditionalAccount(movement, transTypeID);
  var additionalAccountInfo = additionalAccountIban && (prevAdditionalAccounts === null || prevAdditionalAccounts === void 0 ? void 0 : prevAdditionalAccounts.find(function (_ref3) {
    var accountIban = _ref3.accountIban;
    return accountIban === additionalAccountIban;
  }));
  var toNameMultiLang = getReceiverName(movementWithTransTypeID, accountsData, cards, deposits, null, null, templates);
  var fromNameMultiLang = getSenderName(movementWithTransTypeID, accountsData, cards, deposits, null, null, templates);
  return _objectSpread(_objectSpread(_objectSpread({
    treasuryCode: getTreasuryCode(transTypeID, receiverAccount, altaCreditAccountIBAN, altaDebitAccountIBAN),
    toName: getLanguageText(toNameMultiLang, null, lang),
    fromName: getLanguageText(fromNameMultiLang, null, lang)
  }, additionalAccountInfo ? {} : {
    additionalAccountIban: additionalAccountIban
  }), additionalAccountInfo ? {
    additionalAccountInfo: additionalAccountInfo
  } : {}), movementWithTransTypeID);
};
var isFeeAndPrevMovementIsCharged = function isFeeAndPrevMovementIsCharged(movement, nextMovement) {
  var _movement$movementTyp;

  return (movement === null || movement === void 0 ? void 0 : (_movement$movementTyp = movement.movementType) === null || _movement$movementTyp === void 0 ? void 0 : _movement$movementTyp.transTypeID) === MOVEMENT_TYPES.FEE && (nextMovement === null || nextMovement === void 0 ? void 0 : nextMovement.chargeAmount) === (movement === null || movement === void 0 ? void 0 : movement.amount);
};
var prepareMovements = function prepareMovements(_ref4) {
  var movements = _ref4.movements,
      prevAdditionalAccounts = _ref4.prevAdditionalAccounts,
      cards = _ref4.cards,
      templates = _ref4.templates,
      deposits = _ref4.deposits,
      lang = _ref4.lang,
      accountsData = _ref4.accountsData;
  return movements.reduce(function (acc, movement, i) {
    if (isFeeAndPrevMovementIsCharged(movement, movements[i + 1])) {
      return acc;
    }

    var preparedMovement = prepareMovement(movement, prevAdditionalAccounts, cards, templates, deposits, lang, accountsData);
    var additionalAccountIban = preparedMovement.additionalAccountIban;
    return {
      preparedMovements: [].concat(_toConsumableArray(acc.preparedMovements), [preparedMovement]),
      additionalAccountIbans: [].concat(_toConsumableArray(acc.additionalAccountIbans), _toConsumableArray(additionalAccountIban ? [additionalAccountIban] : []))
    };
  }, {
    preparedMovements: [],
    additionalAccountIbans: []
  });
};
function prepareDataForFilter(fixedFilter, filter, rightsBundle) {
  var _filter$types, _filter$currencies, _filter$filterAccount, _filter$statuses;

  var _filter$dates$dateRan = filter.dates.dateRange,
      startDate = _filter$dates$dateRan.startDate,
      endDate = _filter$dates$dateRan.endDate; // eslint-disable-next-line @typescript-eslint/no-use-before-define

  var newFilter = getValueDateForFilter(startDate, endDate);

  if (filter.minAmount) {
    newFilter.amount = {
      gte: filter.minAmount
    };
  }

  if (filter.maxAmount) {
    if (!newFilter.amount) {
      newFilter.amount = {};
    }

    newFilter.amount.lte = filter.maxAmount;
  }

  if ((_filter$types = filter.types) !== null && _filter$types !== void 0 && _filter$types.length) {
    newFilter.directionDRCR = {
      inlist: filterMoreValues(filter.types, 'directionDRCR')
    };
  }

  if ((_filter$currencies = filter.currencies) !== null && _filter$currencies !== void 0 && _filter$currencies.length) {
    newFilter['currency.currencyID'] = {
      inlist: filterMoreValues(filter.currencies)
    };
  }

  if ((_filter$filterAccount = filter.filterAccounts) !== null && _filter$filterAccount !== void 0 && _filter$filterAccount.length) {
    newFilter['clientAccount.ibanAccount'] = {
      inlist: filterMoreValues(filter.filterAccounts).map(function (_ref5) {
        var ibanAccount = _ref5.ibanAccount;
        return ibanAccount;
      })
    };
  }

  if (filter.beneficiaryName && filter.beneficiaryName.length > 0) {
    var beneficiaryNames = filterMoreValues(filter.beneficiaryName);
    var namesQuery = {
      inlist: _toConsumableArray(new Set(beneficiaryNames))
    };
    newFilter.whereNestedBeneficiary = {
      receiverName: namesQuery,
      senderName: namesQuery
    };
    var ibans = filterMoreValues(filter.beneficiaryName, undefined, 'iban').filter(Boolean);
    var treasuryCodes = filterMoreValues(filter.beneficiaryName, undefined, 'treasuryCode').filter(Boolean);
    var receiverAccounts = [].concat(_toConsumableArray(ibans), _toConsumableArray(treasuryCodes));

    if (receiverAccounts.length > 0) {
      var accountsQuery = {
        inlist: _toConsumableArray(new Set(receiverAccounts))
      };
      newFilter.whereNestedBeneficiary.receiverAccount = accountsQuery;
      newFilter.whereNestedBeneficiary.senderAccount = accountsQuery;
    }
  }

  if (filter.bank) {
    var escapedQuery = filter.bank.replace(/"/g, '\\"').replace(/\s/g, '%');
    var searchQuery = {
      inlike: "%".concat(escapedQuery, "%")
    };
    newFilter.whereNestedBank = {
      senderBankName: searchQuery,
      senderBankCode: searchQuery
    };
  }

  if ((_filter$statuses = filter.statuses) !== null && _filter$statuses !== void 0 && _filter$statuses.length) {
    newFilter.status = {
      inlist: filterMoreValues(filter.statuses)
    };
  }

  if (!(rightsBundle !== null && rightsBundle !== void 0 && rightsBundle.canViewPending)) {
    var _newFilter$status, _newFilter$status$inl, _statusInlist;

    var statusInlist = newFilter === null || newFilter === void 0 ? void 0 : (_newFilter$status = newFilter.status) === null || _newFilter$status === void 0 ? void 0 : (_newFilter$status$inl = _newFilter$status.inlist) === null || _newFilter$status$inl === void 0 ? void 0 : _newFilter$status$inl.filter(function (status) {
      return status !== 'YELLOW';
    });

    if (!((_statusInlist = statusInlist) !== null && _statusInlist !== void 0 && _statusInlist.length)) {
      statusInlist = ['GREEN', 'RED'];
    }

    newFilter.status = {
      inlist: statusInlist
    };
  }

  return _objectSpread(_objectSpread({}, newFilter), fixedFilter);
}

var getValueDateForFilter = function getValueDateForFilter(startDate, endDate) {
  return {
    valueDate: {
      gte: DateTime.toISOString(startDate),
      lte: DateTime.toISOString(endDate)
    }
  };
};

export { isFeeAndPrevMovementIsCharged, prepareDataForFilter, prepareMovement, prepareMovements };
