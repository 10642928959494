import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import _defineProperty from '@babel/runtime/helpers/defineProperty';
import _extends from '@babel/runtime/helpers/extends';
import { takeLatest } from 'redux-saga/effects';
import { composeFragments, action, state, stateFlag, selector, flow, resolveAction, logic2, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { listFragment, configResetListFragment, withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { TEMPLATE_PAYMENT_TYPES } from 'vtb-utils';
import { getTemplateGroupsQuery } from '../templates-query.js';
import { templateIconFragment } from '../templates-icon-fragment.js';
import { templatesEditFieldsFragment } from './templates-edit.fields.js';
import { initFlow, toggleFormTypeFlow, editTemplateProcessFlow, setTreasuryTemplatePurpose } from './template-process.flow.js';
import { templateBeforeQueryHandler, prefillForm } from './prefill-form.js';
import { saveAsTemplateSubmitFlow } from './saveAsTemplateSubmit.flow.js';
import { errorsMerger } from '../../../common/helpers.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var fragments = composeFragments(listFragment({
  listName: 'template',
  entityName: 'PaymentTemplate',
  listQuery: {
    PaymentTemplates: ['templateId', 'templateName', 'hasImage', 'beneficiaryName', 'beneficiaryPhoneNumber', 'beneficiaryEmail', 'beneficiaryAccount', 'beneficiaryBankCode', 'amount', 'ccy', 'purpose', 'extrInfo', 'payerName', 'payerTaxId', 'treasuryCode', 'customer', 'paymentType', 'templateGroup', 'templateStatus', 'user', 'originatorUser', 'lastUpdateUser', 'bornDate', 'lastUpdateDate'],
    where: {}
  },
  beforeQueryHandler: templateBeforeQueryHandler,
  afterQueryHandler: prefillForm
}, configResetListFragment({})), listFragment({
  listName: 'groups',
  entityName: 'PaymentTemplateGroups',
  listQuery: getTemplateGroupsQuery()
}), listFragment({
  entityName: 'currencies',
  listQuery: {
    Currencies: ['flag', 'currencyID'],
    limit: 1000
  }
}), listFragment({
  entityName: 'countries',
  listQuery: {
    Countries: ['countryID', 'text'],
    limit: 1000
  }
}), action('submit'), action('failed'), action('saveTemplate'), action('initialize'), action('resetAndInit'), action('refreshFormValidations'), action('templateFailedSaveCompleted'), action(['additionalAccountInfoLoaded'], function (data) {
  return {
    data: data
  };
}), state(['accounts', 'accountsDataLoaded'], function () {
  return [];
}, []), // fake state because of ttse validation implementation
stateFlag('templateSaved', ['templateSaveCompleted'], ['initializeForm', 'reset', 'resetForm'], false), state(['bank', 'setBank'], function (_, _ref) {
  var bank = _ref.bank;
  return bank;
}, null), state(['bank', 'reset'], function () {
  return null;
}), state(['bankInfo', 'bankLoaded'], function (_, _ref2) {
  var bank = _ref2.bank;
  return bank;
}), state(['bankInfo', 'resetForm'], function () {
  return null;
}), state(['additionalAccountInfo', 'additionalAccountInfoLoaded'], function (_, _ref3) {
  var data = _ref3.data;
  return data;
}), state(['additionalAccountInfo', 'resetForm'], function () {
  return null;
}), state(['isForeignPayment', 'setForeignFlag'], function (_, _ref4) {
  var isForeign = _ref4.isForeign;
  return isForeign;
}, false), state(['isForeignPayment', 'resetForm'], function () {
  return false;
}), state(['isBanksCodesOpen', 'toggleBanksCodesModal'], function (_, _ref5) {
  var isOpen = _ref5.isOpen;
  return isOpen;
}, false), state(['savedTemplate', 'setSavedTemplate'], function (_, _ref6) {
  var template = _ref6.template;
  return template;
}, null), state(['savedTemplate', 'initializeForm'], function () {
  return null;
}), state(['savedTemplate', 'resetForm'], function () {
  return null;
}), stateFlag('isBanksCodesOpen', [], ['initializeForm', 'reset', 'resetForm']), state(['bankCodeDisabled', 'disableBankCode'], function (_, _ref7) {
  var disabled = _ref7.disabled;
  return disabled === true;
}, true), state(['instructionType', 'setInstructionType'], function (_, _ref8) {
  var value = _ref8.value;
  return value;
}, 'paymentInterBankLcy'), state(['serverErrors', 'setServerErrors'], function (_, _ref9) {
  var errors = _ref9.errors;
  return errors;
}, []), state(['serverErrors', 'resetForm'], function () {
  return [];
}), state(['serverErrors', 'reset'], function () {
  return [];
}), stateFlag('isFromDashboard', ['setFromDashboard'], ['reset', 'resetForm'], false), // TODO: HACK until foreign batch transaction will be available
stateFlag('isBatchPayment', ['setBatchPayment'], ['reset', 'resetForm']), stateFlag('forceFilter', ['setForceFilter'], ['initializeForm', 'initialized', 'backToFirstStep', 'reset', 'resetForm'], false), stateFlag('instantSaveError', ['instantSaveFailed'], ['tmpSuccess', 'initializeForm', 'reset', 'resetForm', 'closeErrorModal', 'templateSaveCompleted', 'templateFailedSaveCompleted'], false), stateFlag('isEditFailedModalOpen', ['templateSavingFailed'], ['templateSaveCompleted', 'templateFailedSaveCompleted', 'tmpSuccess', 'tmpFailed', 'reset', 'closeErrorModal'], false), stateFlag('beneficiaryAccountValidating', ['beneficiaryAccountValidationStarted'], ['patchBeneficiaryAccount', 'tmpSuccess', 'tmpFailed', 'failed'], false), state(['formType', 'toggleFormType'], function (state) {
  return state === TEMPLATE_PAYMENT_TYPES.TRANSFERTOSOMEONEELSE ? TEMPLATE_PAYMENT_TYPES.TREASURY : TEMPLATE_PAYMENT_TYPES.TRANSFERTOSOMEONEELSE;
}, TEMPLATE_PAYMENT_TYPES.TRANSFERTOSOMEONEELSE), // eslint-disable-next-line arrow-body-style
state(['beneficiaryAccount', 'patchBeneficiaryAccount'], function (state, _ref10) {
  var _patchedState$value;

  var patchedState = _extends({}, _ref10);

  // there is different approach than in SomeElse - Template edit does not use requireRule so we have to deserialize
  // only errors form patchedState, when value is not empty
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, state), patchedState), errorsMerger(_toConsumableArray((patchedState === null || patchedState === void 0 ? void 0 : (_patchedState$value = patchedState.value) === null || _patchedState$value === void 0 ? void 0 : _patchedState$value.length) !== 0 && patchedState.errors && patchedState.errors || []))), {}, {
    dirty: true,
    updating: false,
    focused: false
  });
}), state(['formType', 'reset'], function () {
  return TEMPLATE_PAYMENT_TYPES.TRANSFERTOSOMEONEELSE;
}), state(['allServices', 'setAllServices'], function (_, _ref11) {
  var services = _ref11.services;
  return services;
}, []), stateFlag('savingAsTemplate', ['startSavingAsTemplate'], ['instantSaveFailed', 'setSavedTemplate', 'reset', 'submit', 'initializeForm'], false), stateFlag('isFormEdit', ['isEdit'], ['isAdd', 'reset'], false), stateFlag('isSubmited', ['submit', 'saveTemplate'], ['failed', 'reset', 'toggleFormType'], false), stateFlag('banksLoaded', ['setBanksLoaded'], ['failed', 'reset', 'resetForm', 'toggleFormType'], false), stateFlag('submitting', ['calling'], ['backToFirstStep', 'stopCalling'], false), selector('loading', ['groupsLoading', 'currenciesLoading', 'templateLoading'], function (groupsLoading, currenciesLoading, templateLoading) {
  return groupsLoading || currenciesLoading || templateLoading;
}), flow('saveAsTemplate', [resolveAction('submit'), takeLatest, saveAsTemplateSubmitFlow]), flow('resetAndInitFlow', [resolveAction('resetAndInit'), takeLatest, initFlow]), flow('toggleFormFlow', [resolveAction('toggleFormType'), takeLatest, toggleFormTypeFlow]), flow('processFlow', [resolveAction('initialize'), takeLatest, editTemplateProcessFlow]), flow('setTreasuryTemplatePurpose', [resolveAction('updateBeneficiaryDetails'), takeLatest, setTreasuryTemplatePurpose]));
var templatesEditLogic = logic2(composeFragments(pathFragment('templates', 'edit'), templatesEditFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, templateIconFragment, fragments));
var templatesEditReducer = templatesEditLogic.reducer;
var templatesEditSaga = sagaifyLogic2(templatesEditLogic);

export { templatesEditLogic, templatesEditReducer, templatesEditSaga };
