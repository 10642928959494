import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { takeLatest } from 'redux-saga/effects';
import { identity } from 'ramda';
import { logic2, composeFragments, pathFragment, action, state, stateFlag, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { listFragment, configResetListFragment, configMorePageFragment } from 'core-recipes';
import { batchConceptsQuery } from '../batch-payment.queries.js';
import { batchConceptsBeforeQueryHandler, mapBatchConceptsFlow, updateSortingFlow, deleteBatchConceptFlow } from './batch-payment-list.flow.js';

var batchPaymentListLogic = logic2(composeFragments(pathFragment('transfers', 'batchList'), action('onSortEnd', identity), action('deleteBatchConcept'), state(['canLoadMore', 'setCanLoadMore'], function (_, _ref) {
  var canLoadMore = _ref.canLoadMore;
  return canLoadMore;
}, false), state(['batchConceptToDelete', 'setBatchConceptToDelete'], function (_, _ref2) {
  var batchConcept = _ref2.batchConcept;
  return batchConcept;
}, null), state(['batchConceptToDelete', 'closeDeleteModal'], function () {
  return null;
}), state(['mappedBatchConcepts', 'batchConceptsMapped'], function (_, _ref3) {
  var batchConcepts = _ref3.batchConcepts;
  return [].concat(_toConsumableArray(_), _toConsumableArray(batchConcepts));
}, []), state(['mappedBatchConcepts', 'batchConceptsSorted'], function (_, _ref4) {
  var batchConcepts = _ref4.batchConcepts;
  return batchConcepts;
}, []), state(['mappedBatchConcepts', 'resetBatchConcepts'], function () {
  return [];
}), stateFlag('batchConceptDeleting', ['deleteBatchConcept'], ['deleteSuccess', 'tmpFailed'], false), stateFlag('isDeleteSuccess', ['deleteSuccess'], ['closeDeleteModal', 'tmpFailed'], false), stateFlag('isDeleteModalOpen', ['openDeleteModal'], ['closeDeleteModal', 'tmpFailed'], false), stateFlag('batchConceptMapping', ['loadBatchConcepts'], ['batchConceptsMapped', 'batchConceptsSorted', 'tmpFailed'], false), stateFlag('batchConceptsSorting', ['onSortEnd'], ['sortingDone', 'tmpFailed'], false), listFragment({
  listName: 'batchConcepts',
  entityName: 'BatchConcepts',
  listQuery: batchConceptsQuery,
  beforeQueryHandler: batchConceptsBeforeQueryHandler
}, configResetListFragment({}), configMorePageFragment({
  pageSize: 10000,
  useCursors: true
})), // HACK: there is somekind of problem with afterQueryHandler when configMorePageFragment is used
flow('batchConceptsDataLoaded', [resolveAction('batchConceptsDataLoaded'), takeLatest, mapBatchConceptsFlow]), flow('updateSorting', [resolveAction('onSortEnd'), takeLatest, updateSortingFlow]), flow('deleteBatchConcept', [resolveAction('deleteBatchConcept'), takeLatest, deleteBatchConceptFlow])));
var batchPaymentListReducer = batchPaymentListLogic.reducer;
var batchPaymentListSaga = sagaifyLogic2(batchPaymentListLogic);

export { batchPaymentListLogic, batchPaymentListReducer, batchPaymentListSaga };
