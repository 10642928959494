import { ConnectedRouter } from 'connected-react-router'
import React, { Suspense, useMemo } from 'react'
import { connect } from 'react-redux'
import { Router as ReactRouter } from 'react-router-dom'
import { logoutLogic } from 'vtb-logic'
import {
  EnglishFont,
  ErrorPage,
  Flex,
  GeorgianFont,
  LocalesConsumer,
  LocalesProvider,
  MainLayout,
  ModalProvider,
  RussianFont,
} from 'vtb-ui'
import { Connection } from 'vtb-ui/src'
import { LANGUAGES, LanguageType, ROUTES } from 'vtb-utils'

import { history } from '../app/store'
import { AutologoutPrompt, Header } from '../common'
import { Menu } from '../navigation'

import { Content } from './components/content'
import { NoAccountsModal } from './payment-centre/common/no-accounts-modal'

interface IModal {
  value: string
}

interface IAppState {
  appStarting: boolean
  appStarted: boolean
  appFailed: boolean
}

interface IMainScreenState {
  connection: Connection
  localization: any
  modal: IModal
  app?: IAppState
}

const withRedux = connect(
  (state: IMainScreenState) => ({
    connection: state.connection,
    localization: state.localization,
    modal: state.modal,
    app: state.app,
  }),
  (dispatch) => ({
    logout: () => dispatch(logoutLogic.actions.logout()),
  }),
)

type LayoutProps = { connection: Connection }

const Layout = ({ connection }: LayoutProps) => {
  const { header, menu, content } = useMemo(
    () => ({
      header: (
        <Suspense fallback={null}>
          <Header />
        </Suspense>
      ),
      menu: (
        <Suspense fallback={null}>
          <Menu />
        </Suspense>
      ),
      content: <Content />,
    }),
    [],
  )

  return <MainLayout connection={connection} header={header} menu={menu} content={content} />
}

export const Router = withRedux(
  ({
    connection,
    localization: { localizations = {}, locale } = {},
    modal,
    app,
    logout,
  }: IMainScreenState & { logout: Function }) => {
    if (!app || app.appStarting) {
      return <Flex />
    }

    if (app.appFailed) {
      return (
        <LocalesProvider value={localizations} locale={locale}>
          <Flex width={[290, 1080]} mt={100}>
            <ErrorPage buttonHandler={logout} buttonText='common.textLink.logout.text' />
          </Flex>
        </LocalesProvider>
      )
    }

    return (
      <ConnectedRouter history={history}>
        <ReactRouter basename={ROUTES.BASE_URL} history={history}>
          <LocalesProvider value={localizations} locale={locale}>
            <ModalProvider value={modal?.value}>
              <LocalesConsumer>
                {({ current }: { current: LanguageType }) => {
                  if (current === LANGUAGES.KA) {
                    return <GeorgianFont />
                  }
                  if (current === LANGUAGES.RU) {
                    return <RussianFont />
                  }
                  return <EnglishFont />
                }}
              </LocalesConsumer>
              <Layout connection={connection} />
              <AutologoutPrompt />
              <NoAccountsModal />
            </ModalProvider>
          </LocalesProvider>
        </ReactRouter>
      </ConnectedRouter>
    )
  },
)
