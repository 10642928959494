import React, { PureComponent } from 'react'
import { is } from 'ramda'
import { Flex, scroller, IcSimpleArrowUp, PrimaryNormalButton, Table, I18n } from 'vtb-ui'

import { InfiniteScroll } from './infinite-scroll'

interface ITableInfiniteScrollContainer {
  loading
  scrollToTopEnabled
}

class TableInfiniteScroll extends PureComponent<any, ITableInfiniteScrollContainer> {
  static defaultProps = {
    showMorePageButtons: false,
  }

  state = {
    loading: false,
    scrollToTopEnabled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate(prevProps) {
    const { tableDataSource: tableData } = prevProps
    if (this.props.tableDataSource.data.length !== tableData.data.length) {
      this.updateTableDataSource()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  updateTableDataSource = () =>
    // eslint-disable-next-line arrow-body-style
    this.setState(() => {
      // we can scroll to bootom of list
      // this.scrollToMorePagination()
      return { loading: false }
    })

  handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const needScrollToTop = scrollTop > 0
    this.setState((prevState) => ({ ...prevState, scrollToTopEnabled: needScrollToTop }))
  }

  morePagesHandler = () => {
    const { fixedFilter } = this.props
    this.setState(() => ({ loading: true }))

    this.props.tableDataSource.paginationProps.onChangePage({ fixedFilter })
  }

  scrollToTop = () => {
    scroller.scrollTo('app', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    })
  }

  scrollToMorePagination = () => {
    scroller.scrollTo('morePagination', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    })
  }

  canLoadMore = () => {
    const { tableDataSource } = this.props
    const { data, paginationProps } = tableDataSource || {}
    const { canLoadMore, pageSize } = paginationProps || {}
    return is(Boolean, canLoadMore) ? canLoadMore : data.length % pageSize === 0
  }

  onBecomeVisible = () => {
    const enabled = this.canLoadMore()
    enabled && this.morePagesHandler()
  }

  renderInfiniteScrollButtons = () => {
    const { loading, scrollToTopEnabled } = this.state
    const { tableDataSource } = this.props
    const enabled = this.canLoadMore()
    return (
      tableDataSource.data.length > 0 && (
        <Flex id='list-button-more' class='morePagination' justifyContent='center'>
          <Flex style={{ height: '60px', position: 'relative' }}>
            <Flex>
              <PrimaryNormalButton disabled={!scrollToTopEnabled} icon onClick={this.scrollToTop} mr={20}>
                <IcSimpleArrowUp stroke={scrollToTopEnabled ? '#00AAFF' : undefined} />
              </PrimaryNormalButton>
              <PrimaryNormalButton loading={loading} disabled={!enabled} onClick={this.morePagesHandler}>
                <I18n>common.button.more</I18n>
              </PrimaryNormalButton>
            </Flex>
          </Flex>
        </Flex>
      )
    )
  }

  renderInfiniteScroll = () => {
    const { tableDataSource } = this.props
    return (
      tableDataSource.data.length > 0 && this.canLoadMore() && <InfiniteScroll onBecomeVisible={this.onBecomeVisible} />
    )
  }

  render() {
    const { showMorePageButtons, components, tableDataSource: tableData, dataCy } = this.props

    return (
      <Flex flex={1} flexDirection='column' id='table'>
        <Table
          dataCy={dataCy || tableData?.options?.dataCy}
          id={tableData.options.tableId}
          tableData={tableData}
          components={components}
        />
        <hr />
        {showMorePageButtons ? this.renderInfiniteScrollButtons() : this.renderInfiniteScroll()}
      </Flex>
    )
  }
}

export const TableInfiniteScrollContainer = TableInfiniteScroll
