import { takeLatest } from 'redux-saga/effects';
import { logic2, composeFragments, pathFragment, action, state, stateFlag, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { loadMessageFlow } from '../actions/actions.js';
import { loadAttachmentFlow } from './detail-message.flow.js';

var detailMessageLogic = logic2(composeFragments(pathFragment('messageCenter', 'detail'), action('loadMessage', function (id, messageType) {
  return {
    id: id,
    messageType: messageType
  };
}), action('resetAttachments', function () {
  return {};
}), action('loadAttachment', function (mId, att, messageType, saveAs) {
  return {
    mId: mId,
    att: att,
    messageType: messageType,
    saveAs: saveAs
  };
}), action('loadedAttachmentGMB', function (data) {
  return {
    data: data
  };
}), action('setEnv', function (env) {
  return {
    env: env
  };
}), state(['attachments', 'handleAttachment'], function (_, _ref) {
  var files = _ref.files;
  return {
    files: files
  };
}, {}), state(['attachments', 'resetAttachments'], function () {
  return [];
}), state(['attachment', 'loadedAttachmentGMB'], function (_, _ref2) {
  var data = _ref2.data;
  return data;
}, null), state(['message', 'messageLoaded'], function (_, _ref3) {
  var message = _ref3.message;
  return message;
}, {}), state(['message', 'resetMessage'], function () {}), state(['env', 'setEnv'], function (_, _ref4) {
  var env = _ref4.env;
  return env;
}, null), stateFlag('loading', ['loadMessage', 'loadAttachment', 'startLoading', 'showDeleteModal'], ['loaded'], true), flow('loadMessageFlow', [resolveAction('loadMessage'), takeLatest, loadMessageFlow]), flow('loadAttachmentFlow', [resolveAction('loadAttachment'), takeLatest, loadAttachmentFlow])));
var detailMessageReducer = detailMessageLogic.reducer;
var detailMessageSaga = sagaifyLogic2(detailMessageLogic);

export { detailMessageLogic, detailMessageReducer, detailMessageSaga };
