var addBaseRoute = function addBaseRoute(config, baseRoute) {
  var withBaseRoutes = {
    base: baseRoute
  };
  Object.keys(config).forEach(function (key) {
    withBaseRoutes[key] = "".concat(baseRoute, "/").concat(config[key]);
  });
  return withBaseRoutes;
};

var LOGIN_APP = {
  PASSWORD_RECOVERY: 'password-recovery',
  ENROLLMENT: 'enrollment'
};
var PASSWORD_RECOVERY = addBaseRoute({
  RECOVERY: 'recovery',
  OTP: 'otp',
  CHOOSE_USERNAME: 'chooser',
  AUTHORIZATION: 'authorization',
  FORM: 'form',
  EXCEPTION: 'exception',
  RESULT: 'result'
}, LOGIN_APP.PASSWORD_RECOVERY);
var ENROLLMENT = addBaseRoute({
  STEP_1: 'step-1',
  STEP_2: 'step-2',
  STEP_3: 'step-3',
  STEP_4: 'step-4',
  EXCEPTION: 'exception',
  WELCOME: 'welcome'
}, LOGIN_APP.ENROLLMENT);
var MAIN = {
  OVERVIEW: '/',
  MY_MONEY: '/my-money',
  PAYMENTS: '/payments',
  TRANSACTIONS: '/transactions',
  PLANER: '/financial-planer',
  PRODUCTS: '/products',
  PROFILE_SETTING: '/profile-setting',
  SECURITY_SETTING: '/security-setting',
  CORPORATE_SETTING: '/corporate-setting',
  MESSAGE_CENTER: '/message-center',
  BRANCHES_ATMS: '/branches-atms',
  CREATE_SIGNING_RULE: '/create-signing-rule',
  DELETE_SIGNING_RULE: '/delete-signing-rule',
  ENABLE_SIGNING_RULE: '/enable-signing-rule',
  GENERAL_ERROR: '/general-error',
  SIGNING_PROCEDURES: '/signing-procedures'
};
var MESSAGE_CENTER = addBaseRoute({
  RECEIVED_MESSAGES: 'received-messages',
  SENT_MESSAGES: 'sent-messages',
  CREATE_MESSAGE: 'create-message',
  DETAIL_MESSAGE: 'detail-message/:id',
  DETAIL_NOTIFICATION: 'detail-notification/:id',
  NOTIFICATIONS: 'notifications'
}, MAIN.MESSAGE_CENTER);
var MY_MONEY = addBaseRoute({
  ACCOUNTS: 'accounts',
  ACCOUNTS_SETTINGS: 'accounts/settings',
  ACCOUNTS_DETAIL: 'accounts/detail',
  CARDS: 'cards',
  LOANS: 'loans',
  LOANS_DETAIL: 'loans/detail',
  SAVINGS: 'savings',
  OPEN_BANKING: 'open-banking',
  OPEN_BANKING_CONSENT: 'open-banking/add-or-extend-consent',
  OPEN_BANKING_DETAIL: 'open-banking/detail',
  OPEN_BANKING_CONSENT_RESULT: 'open-banking/consent-result'
}, MAIN.MY_MONEY);
var TRANSACTIONS = addBaseRoute({
  MOVEMENTS: 'movements',
  STATEMENTS: 'statements',
  ORDERS: 'current-orders',
  CARD_BLOCKS: 'card-blocks'
}, MAIN.TRANSACTIONS);
var PAYMENT_CENTER = addBaseRoute({
  CENTRE: 'centre',
  BOA: 'transfer-to-own-account',
  TREASURY: 'treasury-transfer',
  SE: 'transfer-to-someone-else',
  CURRENCY_EXCHANGE: 'currency-exchange',
  MOBILE: 'mobile-top-up',
  BILL: 'bill-payments',
  BILL_PAYMENTS_WIZARD: 'bill-payments/paymentsWizard',
  BASKET_SUMMARY: 'bill-payments/basket-summary',
  BILL_NEW_TEMPLATE: 'bill-payments/new-template-form',
  BILL_NEW_TEMPLATE_WIZARD: 'bill-payments/new-template',
  BILL_EDIT_GROUPS: 'bill-payments/edit-groups',
  BILL_CATEGORY: 'bill-payments/category',
  BILL_NEW_PAYMENT: 'bill-payments/new-payment',
  FINES: 'fines',
  BATCH_PAYMENT: 'batch-payments',
  BATCH_NEW_CONCEPT: 'bill-payments/new-concept',
  TEMPLATES: 'templates',
  TEMPLATES_EDIT: 'templates/edit',
  TEMPLATES_EDIT_GROUPS: 'template-groups',
  EXCHANGE_RATES: 'exchange-rates',
  SODD: 'sodd',
  SO_WIZARD: 'sodd/so-wizard',
  SO_DETAIL: 'sodd/so-detail',
  SO_OWN: 'sodd/to-own-account',
  SO_TTSE: 'sodd/to-someone-else',
  SO_TREASURY: 'sodd/treasury',
  SO_DELETE_RESULT: 'sodd/so-delete-result',
  DIRECT_DEBIT: 'sodd/direct-debit',
  CARS: 'cars',
  CARS_BASKET: 'cars/basket',
  CARS_LIST: 'cars/list',
  CARS_LIST_ERROR: 'cars/error',
  CARS_NEW_PAYMENT: 'cars/new-payment-type',
  CARS_PAY_FINES: 'cars/pay-fines',
  CARS_PAY_PARIKING: 'cars/pay-parking-pass',
  CARS_FORM: 'cars/form',
  CALL_CENTRE: 'call-centre',
  INDIVIDUAL_RATE: 'individual-rate'
}, MAIN.PAYMENTS);
var PROFILE_SETTING = addBaseRoute({
  PERSONALIZE: 'personalize',
  PERSONAL_INFO: 'personal-info',
  MY_SHORTCUTS: 'my-shortucts'
}, MAIN.PROFILE_SETTING);
var SECURITY_SETTING = addBaseRoute({
  CHANGE_USER_NAME: 'change-user-name',
  CHANGE_PASSWORD: 'change-password',
  SECURITY_LEVEL: 'security-level',
  MY_DEVICES: 'my-devices'
}, MAIN.SECURITY_SETTING);
var CORPORATE_SETTING = addBaseRoute({
  USERS: 'users',
  CREATE_USER: 'create-user',
  AUTHORIZE_USER_ACTION: 'authorize',
  NOTIFICATIONS: 'notifications',
  SIGNING_RULES: 'signing-rules'
}, MAIN.CORPORATE_SETTING);
var SIGNING_PROCEDURES = addBaseRoute({
  WAITING_FOR_ME: 'waiting-for-me',
  NOT_MY_TURN: 'not-my-turn',
  PENDING: 'pending',
  ARCHIVE: 'archive',
  DETAIL: 'detail',
  CONFIRM_MULTIPLE: 'confirm-multiple',
  CONFIRM_BATCH: 'confirm-batch',
  CONFIRM_OTHER: 'confirm-other'
}, MAIN.SIGNING_PROCEDURES);
var BRANCHES_ATMS = {
  base: MAIN.BRANCHES_ATMS
};
var BASE_URL = '/web';
var ROUTES = {
  BASE_URL: BASE_URL,
  MAIN: MAIN,
  MY_MONEY: MY_MONEY,
  TRANSACTIONS: TRANSACTIONS,
  MESSAGE_CENTER: MESSAGE_CENTER,
  PAYMENT_CENTER: PAYMENT_CENTER,
  PROFILE_SETTING: PROFILE_SETTING,
  SECURITY_SETTING: SECURITY_SETTING,
  CORPORATE_SETTING: CORPORATE_SETTING,
  BRANCHES_ATMS: BRANCHES_ATMS,
  LOGIN_APP: LOGIN_APP,
  PASSWORD_RECOVERY: PASSWORD_RECOVERY,
  SIGNING_PROCEDURES: SIGNING_PROCEDURES,
  ENROLLMENT: ENROLLMENT
};
var SCROLL_ANCHORS = {
  CENTRE: {
    ORDERS: 'current-orders-title'
  }
};

export { ROUTES, SCROLL_ANCHORS };
