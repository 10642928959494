import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { Flex } from './flex.js';

var _templateObject;
var Screen = styled(Flex).attrs(function (_ref) {
  var theme = _ref.theme;
  return {
    width: [theme.space.mobileAppWidth, theme.space.appWidth]
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n  height: 100%;\n  flex-direction: column;\n  align-items: center;\n"])));

export { Screen };
