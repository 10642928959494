import _objectWithoutProperties from '@babel/runtime/helpers/objectWithoutProperties';

var colors = {
  themePrimary: '#1287ff',
  themeSecondary: '#54c4ff',
  linkPrimary: '#00aaff',
  option: '#00aaff',
  darkBlue: '#2d55d2',
  neutralAccent: '#000',
  neutralPrimary: '#3e4546',
  neutralSecondary: '#dce0eb',
  neutralGrey: '#f7f7f7',
  light: '#dce0eb',
  clear: '#fff',
  white: '#fff',
  warning: '#ffbd49',
  warningDark: '#f1a038',
  error: '#ea6b50',
  errorDark: '#ff0000',
  errorAmount: '#d0021b',
  primaryGreen: '#39e75b',
  secondaryGreen: '#2db747',
  success: '#2db747',
  highlightGreen: '#4cc864',
  inputGreen: '#7ed321',
  back: 'black',
  amount: '#2f3441',
  tooltipText: '#6b7683',
  subtitleText: '#6b7683',
  subMenuItem: '#6b7683',
  subMenuItemActionBorder: '#a7b8ea',
  propertyLabel: '#acb6c3',
  formLabel: '#6b7683',
  transferCurrency: '#c5ccd5',
  transferTitle: '#2f3441',
  propertyValue: '#2f3441',
  transparent: 'transparent',
  disabled: '#acb6c3',
  tableRowEven: '#f5f6fa',
  currencyWrapper: '#f5f6fa',
  tableRowOdd: 'white',
  selectboxBorder: '#6b7683',
  infoIcon: '#cceeff',
  selectEven: '#cceeff',
  headerGray: '#FAFAFC',
  inputBorder: '#acb6c3',
  loginGray: '#acb6c3',
  moneyHeaders: '#6b7683',
  userMenuActive: '#ececec',
  datepickerText: '#6b7683',
  datepickerSelected: '#00aaff',
  selectItemFocus: '#cceeff',
  selectItemActive: '#00aaff',
  menuActiveFrom: '#49ADF8',
  menuActiveTo: '#5DCAFA',
  acountsBalances: '#00aaff',
  buttonGradStart: '#22ceff',
  buttonFocusBorder: '#00aaff',
  buttonGradEnd: '#8792a0',
  buttonLabelColor: '#9176e4',
  buttonLabelBackground: '#f1edfe',
  restrictionUnderline: '#eaedf5',
  exchangeBorder: '#cceeff',
  searchResultColor: '#a8a8a8',
  inactiveStatusColor: 'orange'
};

var color = function color(type) {
  return function (props) {
    return props.theme.colors[type];
  };
};

var primaryColor = color('themePrimary');
var linkPrimaryColor = color('linkPrimary');
var tooltipBackground = color('themeSecondary');
var whiteColor = color('clear');
var secondaryColor = color('themeSecondary');
var successColor = color('primaryGreen');
var neutralSecondaryColor = color('neutralSecondary');
var transparentColor = color('transparent');
var datepickerTextColor = color('datepickerText');
var datepickerSelectedColor = color('datepickerSelected');
var accentTextColor = color('neutralAccent');
var secondaryTextColor = color('neutralAccent');
var errorColor = color('error');
var errorAltColor = color('error');
var errorDarkColor = color('errorDark');
var smallTextColor = color('neutralPrimary');
var neutralPrimaryColor = color('neutralPrimary');
var subtitleTextColor = color('subtitleText');
var propertyLabelColor = color('propertyLabel');
var propertyValueColor = color('propertyValue');
var sliderPointerActiveColor = color('propertyValue');
var sliderPointerColor = color('propertyLabel');
var evenTableRowColor = color('tableRowEven');
var oddTableRowColor = color('tableRowOdd');
var subMenuItemColor = color('subMenuItem');
var subMenuItemActionBorderColor = color('subMenuItemActionBorder');
var disabledColor = color('disabled');
var formLabelColor = color('formLabel');
var backgroundColor = color('white');
var basicIconColor = color('darkBlue');
var selectItemFocused = color('selectItemFocus');
var selectItemActive = color('selectItemActive');
var tooltipTextColor = color('tooltipText');
var inactiveStatusColor = color('inactiveStatusColor');
var activeStatusColor = color('highlightGreen');
var errorAmountColor = color('errorAmount');
var iconBackgroundColor = color('restrictionUnderline');
var colorByTypes = {
  success: successColor,
  error: errorColor,
  info: neutralSecondaryColor,
  warning: errorColor
};
var typeToColor = function typeToColor(_ref) {
  var type = _ref.type,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? undefined : _ref$disabled,
      props = _objectWithoutProperties(_ref, ["type", "disabled"]);

  return disabled ? disabledColor(props) : colorByTypes[type](props) || successColor(props);
};

export { accentTextColor, activeStatusColor, backgroundColor, basicIconColor, colors, datepickerSelectedColor, datepickerTextColor, disabledColor, errorAltColor, errorAmountColor, errorColor, errorDarkColor, evenTableRowColor, formLabelColor, iconBackgroundColor, inactiveStatusColor, linkPrimaryColor, neutralPrimaryColor, neutralSecondaryColor, oddTableRowColor, primaryColor, propertyLabelColor, propertyValueColor, secondaryColor, secondaryTextColor, selectItemActive, selectItemFocused, sliderPointerActiveColor, sliderPointerColor, smallTextColor, subMenuItemActionBorderColor, subMenuItemColor, subtitleTextColor, successColor, tooltipBackground, tooltipTextColor, transparentColor, typeToColor, whiteColor };
