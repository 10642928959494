import { STANDING_ORDER_FREQUENCY_TYPES } from './constants.js';

var isSoDaily = function isSoDaily(periodicity) {
  return periodicity === STANDING_ORDER_FREQUENCY_TYPES.Daily;
};
var isSoWeekly = function isSoWeekly(periodicity) {
  return periodicity === STANDING_ORDER_FREQUENCY_TYPES.Weekly;
};
var isSoMonthly = function isSoMonthly(periodicity) {
  return periodicity === STANDING_ORDER_FREQUENCY_TYPES.Monthly;
};
var isSoQuarterly = function isSoQuarterly(periodicity) {
  return periodicity === STANDING_ORDER_FREQUENCY_TYPES.Quarterly;
};
var isSoYearly = function isSoYearly(periodicity) {
  return periodicity === STANDING_ORDER_FREQUENCY_TYPES.Yearly;
};

export { isSoDaily, isSoMonthly, isSoQuarterly, isSoWeekly, isSoYearly };
