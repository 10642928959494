var getTemplatesQuery = function getTemplatesQuery(id) {
  var q = {
    PaymentTemplates: ['templateId', 'templateName', 'hasImage', 'beneficiaryName', 'beneficiaryPhoneNumber', 'beneficiaryEmail', 'beneficiaryAccount', 'beneficiaryBankCode', 'amount', 'ccy', 'purpose', 'extrInfo', 'payerName', 'payerTaxId', 'treasuryCode', 'customer', 'paymentType', 'templateGroup', 'templateStatus', 'user', 'originatorUser', 'lastUpdateUser', 'bornDate', 'lastUpdateDate'],
    where: {},
    order: {
      templateName: 'asc'
    },
    limit: 1000
  };

  if (id) {
    q['id'] = id;
  }

  return q;
};
var getTemplateGroupsQuery = function getTemplateGroupsQuery(id) {
  var q = {
    PaymentTemplateGroups: [],
    where: {},
    order: {
      sortingNumber: 'asc'
    },
    limit: 1000
  };

  if (id) {
    q['id'] = id;
  }

  return q;
};

export { getTemplateGroupsQuery, getTemplatesQuery };
