import { composeFragments } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withDependentFieldFlow } from 'core-recipes';
import { validationConfig } from './exchange-rates-validations.js';

var ratesCalculatorFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'sellRate',
  rules: []
}, withDependentFieldFlow({
  source: 'sellRate',
  sourceActionName: 'update',
  updateHandler: validationConfig.ccySell.dependentFlow
})), fieldFragment({
  name: 'buyRate',
  rules: []
}, withDependentFieldFlow({
  source: 'buyRate',
  sourceActionName: 'update',
  updateHandler: validationConfig.ccyBuy.dependentFlow
})), fieldFragment({
  name: 'rateSourceAmount',
  initialValue: 100,
  rules: []
}, withDependentFieldFlow({
  source: 'rateSourceAmount',
  sourceActionName: 'complete',
  updateHandler: validationConfig.rateSourceAmount.dependentFlow
})), fieldFragment({
  name: 'rateTargetAmount',
  rules: []
}, withDependentFieldFlow({
  source: 'rateTargetAmount',
  sourceActionName: 'complete',
  updateHandler: validationConfig.rateTargetAmount.dependentFlow
}))));

export { ratesCalculatorFieldsFragment };
