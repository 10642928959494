import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { pipe, filter, sortBy, prop } from 'ramda';
import { mapFullOptions } from '../collections.js';
import { TEMPLATE_PAYMENT_TYPES } from '../constants/payments-constants.js';
import { composeUrlByReference } from '../assets/assets.js';

var getTemplateFormPageTitle = function getTemplateFormPageTitle(isFormEdit, templateId) {
  if (isFormEdit || templateId) {
    return 'paymentCenter.editTemplate.title';
  }

  return 'paymentCenter.paymentTemplates.addNewTemplate.title';
};
var pickedCurrencyKeys = ['GEL', 'USD', 'EUR', 'GBP', 'CHF', 'RUB'];
var getTemplatesCurrencyOptions = function getTemplatesCurrencyOptions() {
  var currencies = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  // prepare specified currencies
  var picked = currencies && currencies.length > 0 && pickedCurrencyKeys.map(function (key) {
    return currencies.find(function (c) {
      return c.entityUniqueId === key;
    });
  }) || []; // sort rest of currencies by alphabet

  var rest = pipe(filter(function (type) {
    return !pickedCurrencyKeys.includes(type.entityUniqueId);
  }), sortBy(prop('entityUniqueId')))(currencies) || [];
  var sorted = [].concat(_toConsumableArray(picked), _toConsumableArray(rest));
  return mapFullOptions(sorted, {
    labelKey: 'entityUniqueId'
  });
};
var prepareTemplateName = function prepareTemplateName() {
  var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return text.replace('.', '').replace('-', '').substr(0, 29);
};
var getIconOfJustSavedTemplate = function getIconOfJustSavedTemplate(formType, additionalAccountInfo, templateIcon) {
  var _templateIcon$value, _templateIcon$value2;

  var isTreasury = formType === TEMPLATE_PAYMENT_TYPES.TREASURY; // base64 is present when adding new template;
  // uri is present when editing template with avatar but without avatar change

  var icon = templateIcon !== null && templateIcon !== void 0 && (_templateIcon$value = templateIcon.value) !== null && _templateIcon$value !== void 0 && _templateIcon$value.base64 || templateIcon !== null && templateIcon !== void 0 && (_templateIcon$value2 = templateIcon.value) !== null && _templateIcon$value2 !== void 0 && _templateIcon$value2.uri || isTreasury ? templateIcon : additionalAccountInfo !== null && additionalAccountInfo !== void 0 && additionalAccountInfo.pictureReference ? {
    uri: composeUrlByReference(additionalAccountInfo === null || additionalAccountInfo === void 0 ? void 0 : additionalAccountInfo.pictureReference)
  } : templateIcon;
  return icon;
};

export { getIconOfJustSavedTemplate, getTemplateFormPageTitle, getTemplatesCurrencyOptions, prepareTemplateName };
