import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { wizardFragment } from '../../../generic/wizard.fragment.js';
import { TRANSFERS_STEPS } from '../../constants.js';
import { withVTBCertificationFragment } from '../../common/withVTBCertificationFragment.js';
import { checkRightsFragment } from '../../../permissions/index.js';
import { instructionIdsFragment } from '../../common/instruction-logic.js';
import { servicesForCarsFragment } from '../common/services-for-cars.fragment.js';
import { carsFragment } from '../common/load-cars.fragment.js';
import { decidePaymentWithoutBalanceCheckFragment } from '../../common/decidePaymentWithoutBalanceCheckFragment.js';
import { paymentWithGetDebtIsSuccessfulFragment } from '../../common/paymentWithGetDebtIsSuccessfulFragment.js';
import { basketFragment } from './basket.fragment.js';
import { basketFieldsFragment } from './basket.fields.js';
import { BILL_PAYMENT } from '../../../permissions/rights.js';

var carsBasketLogic = logic2(composeFragments(pathFragment('cars', 'basket'), wizardFragment(TRANSFERS_STEPS), carsFragment, servicesForCarsFragment, decidePaymentWithoutBalanceCheckFragment, basketFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, withVTBCertificationFragment, instructionIdsFragment, paymentWithGetDebtIsSuccessfulFragment, basketFragment, checkRightsFragment({
  rights: BILL_PAYMENT()
})));
var CarsBasketReducer = carsBasketLogic.reducer;
var CarsBasketSaga = sagaifyLogic2(carsBasketLogic);

export { CarsBasketReducer, CarsBasketSaga, carsBasketLogic };
