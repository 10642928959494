import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { MAIN_CURRENCY } from '../constants/index.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var CREDIT_CARD_SUBTYPES = ['31', '32', '33'];
var ACCOUNT_TYPE_NAMES;
/**
 * ACC_TYPE=200
 */

(function (ACCOUNT_TYPE_NAMES) {
  ACCOUNT_TYPE_NAMES["LOAN"] = "LOAN";
  ACCOUNT_TYPE_NAMES["DEPOSIT"] = "DEPOSIT";
  ACCOUNT_TYPE_NAMES["CURRENT"] = "CURRENT";
  ACCOUNT_TYPE_NAMES["CARD"] = "CARD";
})(ACCOUNT_TYPE_NAMES || (ACCOUNT_TYPE_NAMES = {}));

var isCardAccount = function isCardAccount(accountType) {
  return accountType === '200';
};
/**
 * ACC_TYPE=200 and ACC_SUBTYPE not in (31, 32, 33)
 */

var isDebitCardAccount = function isDebitCardAccount(accountType, accountSubType) {
  return accountType === '200' && !CREDIT_CARD_SUBTYPES.includes(accountSubType);
};
/**
 * ACC_TYPE=200 and ACC_SUBTYPE IN (31, 32, 33)
 */

var isCreditCardAccount = function isCreditCardAccount(accountType, accountSubType) {
  return accountType === '200' && CREDIT_CARD_SUBTYPES.includes(accountSubType);
};
/**
 * ACC_TYPE=100
 */

var isCurrentAccount = function isCurrentAccount(accountType) {
  return accountType === '100';
};
var isCardOrCurrent = function isCardOrCurrent(account) {
  var accountType = account.accountType && account.accountType.entityUniqueId;
  return isCurrentAccount(accountType) || isCardAccount(accountType);
};
var isCardOrCurrentGELAccount = function isCardOrCurrentGELAccount(account) {
  var _account$currency;

  return isCardOrCurrent(account) && (account === null || account === void 0 ? void 0 : (_account$currency = account.currency) === null || _account$currency === void 0 ? void 0 : _account$currency.entityUniqueId) === MAIN_CURRENCY;
};
var getSubBalances = function getSubBalances(detail) {
  var _detail$multicurrency;

  return detail !== null && detail !== void 0 && detail.multicurrencyAccounts && Array.isArray(detail.multicurrencyAccounts) && ((_detail$multicurrency = detail.multicurrencyAccounts) === null || _detail$multicurrency === void 0 ? void 0 : _detail$multicurrency.length) > 0 ? detail.multicurrencyAccounts.map(function (_ref) {
    var balance = _ref.balance,
        cbsID = _ref.cbsID;
    return _objectSpread(_objectSpread({}, balance), {}, {
      cbsID: cbsID
    });
  }) : [detail === null || detail === void 0 ? void 0 : detail.balance].filter(Boolean);
};
var getAccountTypeName = function getAccountTypeName(data) {
  if (!data.accountType) return null;

  switch (data.accountType.accTypeId) {
    case '32':
      return ACCOUNT_TYPE_NAMES.DEPOSIT;

    case '64':
      return ACCOUNT_TYPE_NAMES.LOAN;

    case '100':
      return ACCOUNT_TYPE_NAMES.CURRENT;

    case '200':
      return ACCOUNT_TYPE_NAMES.CARD;

    default:
      return null;
  }
};

export { ACCOUNT_TYPE_NAMES, getAccountTypeName, getSubBalances, isCardAccount, isCardOrCurrent, isCardOrCurrentGELAccount, isCreditCardAccount, isCurrentAccount, isDebitCardAccount };
