import { fieldsFragment, fieldFragment } from 'core-recipes';
import { validationRule, autocorrectingValidationRule, maxLength } from 'core-ingredients';
import { requiredRule, charset } from '../../utils/validators.js';

var createMesssageFieldFragment = function createMesssageFieldFragment() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return fieldsFragment(options)(fieldFragment({
    name: 'subject',
    initialValue: '',
    rules: [validationRule(requiredRule), autocorrectingValidationRule(maxLength(70)), autocorrectingValidationRule(charset(/.*[^ ].*/))]
  }), fieldFragment({
    name: 'messageBody',
    initialValue: '',
    rules: [validationRule(requiredRule), autocorrectingValidationRule(maxLength(2000))]
  }));
};

export { createMesssageFieldFragment };
