var getErrorParam = function getErrorParam(errors, type) {
  var i = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  if (Array.isArray(errors) && errors[i] && errors[i].parameters && errors[i].parameters.length > 0) {
    var result = errors[i].parameters.find(function (_ref) {
      var name = _ref.name;
      return name === type;
    });
    return result ? result.value : '';
  }

  return null;
};
var getDebtParam = function getDebtParam(debtData, type) {
  var i = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  if (debtData && debtData.debtInfo && debtData.debtInfo.length > 0) {
    var result = debtData.debtInfo[i].debtData.find(function (_ref2) {
      var key = _ref2.key;
      return key === type;
    });
    return result ? result.value : '';
  }

  return null;
};
var getPayServiceCode = function getPayServiceCode(debtData, i) {
  if (debtData && debtData.debtInfo && debtData.debtInfo.length > 0) {
    return debtData.debtInfo[i].payServiceCode;
  }

  return null;
};
var getPayCustomerCode = function getPayCustomerCode(debtData, i) {
  if (debtData && debtData.debtInfo && debtData.debtInfo.length > 0) {
    return debtData.debtInfo[i].payCustomerCode;
  }

  return null;
};
var getDebtErrors = function getDebtErrors(debtData) {
  var i = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

  if (debtData && debtData.errors && debtData.errors.length > 0) {
    return debtData.errors[i];
  }

  return null;
};
var hasDebtSpecificError = function hasDebtSpecificError(debtData, type) {
  var i = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var error = getDebtErrors(debtData, i);

  if (error) {
    return error.errorCode === type;
  }

  return false;
};
/**
 * Returns CUST DEBT debt data object by given index i
 * */

var getCustDebt = function getCustDebt(debtData, i) {
  if (debtData && debtData.debtInfo && debtData.debtInfo.length > 0) {
    return debtData.debtInfo[i].debtData.find(function (_ref3) {
      var key = _ref3.key;
      return key === 'CUST_DEBT';
    });
  }

  return null;
};

export { getCustDebt, getDebtErrors, getDebtParam, getErrorParam, getPayCustomerCode, getPayServiceCode, hasDebtSpecificError };
