import gibCommon from './gib-common.json'
import gibCommonDates from './gib-common-dates.json'
import gibDashboard from './gib-dashboard.json'
import gibLocationMap from './gib-location-map.json'
import gibMessageCenter from './gib-message-center.json'
import gibMyMoney from './gib-mymoney.json'
import gibPayments from './gib-payments.json'
import gibProfile from './gib-profile.json'
import gibSecurity from './gib-security.json'
import gibSettingsAccounts from './gib-settings-accounts.json'
import gibSodd from './gib-sodd.json'
import gibTransactions from './gib-transactions.json'
import gibInstructions from './gib-instructions.json'

export const localizationCollection = ({
  en: {
    ...gibCommon.en,
    ...gibCommonDates.en,
    ...gibDashboard.en,
    ...gibLocationMap.en,
    ...gibMessageCenter.en,
    ...gibMyMoney.en,
    ...gibPayments.en,
    ...gibProfile.en,
    ...gibSecurity.en,
    ...gibSettingsAccounts.en,
    ...gibSodd.en,
    ...gibTransactions.en,
    ...gibInstructions.en,
  },
  ka: {
    ...gibCommon.ka,
    ...gibCommonDates.ka,
    ...gibDashboard.ka,
    ...gibLocationMap.ka,
    ...gibMessageCenter.ka,
    ...gibMyMoney.ka,
    ...gibPayments.ka,
    ...gibProfile.ka,
    ...gibSecurity.ka,
    ...gibSettingsAccounts.ka,
    ...gibSodd.ka,
    ...gibTransactions.ka,
    ...gibInstructions.ka,
  },
  ru: {
    ...gibCommon.ru,
    ...gibCommonDates.ru,
    ...gibDashboard.ru,
    ...gibLocationMap.ru,
    ...gibMessageCenter.ru,
    ...gibMyMoney.ru,
    ...gibPayments.ru,
    ...gibProfile.ru,
    ...gibSecurity.ru,
    ...gibSettingsAccounts.ru,
    ...gibSodd.ru,
    ...gibTransactions.ru,
    ...gibInstructions.ru,
  },
})
