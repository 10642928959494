import { takeLatest } from 'redux-saga/effects';
import { logic2, composeFragments, pathFragment, validationRule, maxLength, action, state, stateFlag, selector, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withResetForm, withInitializeForm, withFieldsSelectors, fetchFragment } from 'core-recipes';
import { directDebitSingleQuery } from '../dd.queries.js';
import { TRANSFERS_STEPS } from '../../../constants.js';
import { wizardFragment } from '../../../../generic/wizard.fragment.js';
import { templateIconFragment } from '../../../templates/templates-icon-fragment.js';
import { afterDirectDebitQueryHandler, submitDirectDebitFlow, deleteDirectDebitFlow } from './dd-edit.flows.js';
import { requiredRule } from '../../../../utils/validators.js';

var ddEditLogic = logic2(composeFragments(pathFragment('dd', 'edit'), fieldsFragment({})(fieldFragment({
  name: 'friendlyName',
  rules: [validationRule(requiredRule), validationRule(maxLength(30))]
}), fieldFragment({
  name: 'ddIcon'
})), withResetForm({}), withInitializeForm({}), withFieldsSelectors, wizardFragment(TRANSFERS_STEPS), templateIconFragment, fetchFragment({
  valueKey: 'directDebit',
  valueQuery: directDebitSingleQuery,
  afterQueryHandler: afterDirectDebitQueryHandler
}), action('initializeForm'), action('debitAccountsMapped'), action('deleteDirectDebit'), state(['initialFormValues', 'directDebitDataLoaded'], function (_, _ref) {
  var directDebit = _ref.directDebit;
  return directDebit;
}, null), state(['initialFormValues', 'resetForm'], function () {
  return null;
}), stateFlag('isDeleteModalOpen', ['openDeleteModal'], ['closeDeleteModal', 'deleleSuccess', 'tmpFailed'], false), stateFlag('loading', ['loadDirectDebit'], ['initializeForm', 'tmpFailed'], false), stateFlag('submitting', ['submit'], ['initializeForm', 'failed', 'reset', 'resetForm'], false), stateFlag('isSubmited', ['submit'], ['failed', 'resetForm'], false), stateFlag('deleting', ['deleteDirectDebit'], ['deleleSuccess', 'tmpFailed'], false), stateFlag('deleted', ['deleleSuccess'], ['initializeForm', 'resetForm', 'deleteDirectDebit', 'tmpFailed'], false), stateFlag('iconEdited', ['startIconToggle'], ['loadDirectDebit', 'resetForm'], false), stateFlag('commissionLoadFailed', ['commissionLoadError'], ['reset', 'resetForm'], false), selector('formChanged', ['fields', 'initialFormValues'], function (fields, initialFormValues) {
  if (!initialFormValues) {
    return false;
  }

  var _ref2 = initialFormValues || {},
      ddIcon = _ref2.ddIcon,
      friendlyName = _ref2.friendlyName;

  return fields.friendlyName.value !== friendlyName || fields.ddIcon.value !== ddIcon;
}), flow('submitDirectDebit', [resolveAction('submit'), takeLatest, submitDirectDebitFlow]), flow('deleteDirectDebit', [resolveAction('deleteDirectDebit'), takeLatest, deleteDirectDebitFlow])));
var ddEditReducer = ddEditLogic.reducer;
var ddEditSaga = sagaifyLogic2(ddEditLogic);

export { ddEditLogic, ddEditReducer, ddEditSaga };
