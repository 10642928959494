import { composeFragments, action, state, flow, resolveAction } from 'core-ingredients';
import { takeLatest } from 'redux-saga/effects';
import { useTemplateFlow } from './use-template-flow.js';

var useTemplateFragment = composeFragments(action('saveTemplateUse', function (values) {
  return {
    values: values
  };
}), state(['sourceTemplateId', 'setSourceTemplateId'], function (_, _ref) {
  var id = _ref.id;
  return id;
}, null), flow('saveUse', [resolveAction('saveTemplateUse'), takeLatest, useTemplateFlow]));

export { useTemplateFragment };
