import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { call } from 'redux-saga/effects';
import { path, pipe, map, filter, uniq } from 'ramda';
import { fetchQuery, query } from 'core-supply';
import { sortCards } from 'vtb-utils';
import { getAllowedCardsFilter } from '../my-money/cards/list/cards.query.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var getCardIcons = function getCardIcons(cards) {
  var account = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var imageProp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'picMedium';
  var accountIcon = path(['customProductIcon', 'icon'], account) || path(['accountType', 'defaultAccountIcon', 'icon'], account);
  return pipe(sortCards, map(function (card) {
    var cardImage = card && card.cardDesign && card.cardDesign[imageProp];
    return {
      img: cardImage || accountIcon || path(['productType', 'productIcon', 'icon'], card),
      cardNumber: card.cardNumber,
      cbsId: card.cbsId
    };
  }))(cards);
};
var getProductIcon = function getProductIcon(account, cards) {
  var imageProp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'picMedium';

  // Card account
  if (account.accountType && account.accountType.accTypeId === '200') {
    var accountCards = cards.filter(function (_ref) {
      var iban = _ref.ibanAccount.iban;
      return iban === account.ibanAccount;
    });
    var cardsWithIcons = getCardIcons(accountCards, account, imageProp);

    if (cardsWithIcons.length > 0) {
      return _objectSpread(_objectSpread({}, cardsWithIcons[0]), {}, {
        cardsWithIcons: cardsWithIcons
      });
    }
  }

  return {
    img: path(['customProductIcon', 'icon'], account) || path(['accountType', 'defaultAccountIcon', 'icon'], account) || null,
    cardNumber: null,
    cardsWithIcons: []
  };
};

var getCardsQuery = function getCardsQuery(accounts, maxExpirationDays) {
  var cardIbans = pipe(filter(function (account) {
    return account && account.accountType && account.accountType.accTypeId === '200';
  }), map(function (_ref2) {
    var ibanAccount = _ref2.ibanAccount;
    return ibanAccount;
  }), uniq)(accounts);
  var where = {};

  if (cardIbans.length > 0) {
    where.where = _objectSpread(_objectSpread({}, getAllowedCardsFilter(maxExpirationDays)), {}, {
      'ibanAccount.entityUniqueId': {
        inlist: cardIbans
      }
    });
  }

  return _objectSpread(_objectSpread({
    Cards: ['cardNumber', 'isMainCard', 'validTo', {
      productType: ['linkedCardPriority', 'text']
    }, {
      cardDesign: ['picSmall']
    }, {
      ibanAccount: ['iban']
    }]
  }, where), {}, {
    limit: 100
  });
};

var getCardsForAccountIcons = function getCardsForAccountIcons(accounts, maxExpirationDays, errorAction) {
  return call(fetchQuery, query(getCardsQuery(accounts, maxExpirationDays)), {
    error: errorAction
  }, {
    supplier: 'gds'
  });
};

export { getCardIcons, getCardsForAccountIcons, getProductIcon };
