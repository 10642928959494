import { validationRule, maxLength, autocorrectingValidationRule } from 'core-ingredients';
import { fieldsFragment, fieldFragment } from 'core-recipes';
import { groupNameOnlyAllowedChars } from '../../../utils/validation-patterns.js';
import { charset, requiredRule } from '../../../utils/validators.js';

var editGroupsFieldsFragment = fieldsFragment({})(fieldFragment({
  name: 'groupName',
  rules: [validationRule(maxLength(30)), autocorrectingValidationRule(charset(groupNameOnlyAllowedChars)), validationRule(requiredRule)]
}));

export { editGroupsFieldsFragment };
