import { differenceInDays, startOfDay } from 'date-fns';
import { memoizeOne } from '../../memoize.js';

var isRuleAlreadyRunningComponent = function isRuleAlreadyRunningComponent(validFrom) {
  return !validFrom || differenceInDays(validFrom, startOfDay(new Date())) < 1;
};

var isRuleAlreadyRunning = memoizeOne(isRuleAlreadyRunningComponent);

export { isRuleAlreadyRunning };
