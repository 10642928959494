import React, { PureComponent, ReactNode } from 'react'
import { RouteComponentProps } from 'react-router'
import { ErrorPage } from 'vtb-ui'
import { Logger } from 'vtb-utils'

export class ErrorScreen extends PureComponent<RouteComponentProps> {
  componentDidMount(): void {
    const error = this.props.location?.state?.error
    if (error) {
      Logger.warn(`General error displayed: ${error}`)
    }
  }

  render(): ReactNode {
    return <ErrorPage />
  }
}
