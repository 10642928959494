import { takeLatest } from 'redux-saga/effects';
import { resolveLogic, composeFragments, pathFragment, validationRule, action, stateFlag, flow, resolveAction } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { PHONE_NUMBER_DOMESTIC_PREFIX } from 'vtb-utils';
import { mask } from '../../utils/validators.js';
import { instructionIdsFragment } from '../common/instruction-logic.js';
import { processCurrencyExchangeFlow } from './currency-exchange-request.flows.js';

var currencyExchangeRequestLogicAPI = resolveLogic(composeFragments(pathFragment('currencyExchangeRequest'), fieldsFragment({})(fieldFragment({
  name: 'comment',
  initialValue: ''
}), fieldFragment({
  name: 'mobilePrefix',
  initialValue: PHONE_NUMBER_DOMESTIC_PREFIX
}), fieldFragment({
  name: 'mobileNumber',
  initialValue: '',
  rules: [validationRule(mask(/^[0-9]{9}$/))]
})), withResetForm({}), withInitializeForm({}), withFieldsSelectors, action('submit'), action('initializeForm'), action('calling'), action('tmpFailed'), action('tpmTransactionDone'), stateFlag('isSubmited', ['calling'], ['failed', 'reset', 'resetForm'], false), stateFlag('isDone', ['tpmTransactionDone'], ['failed', 'reset', 'resetForm'], false), stateFlag('isFailed', ['tmpFailed'], ['failed', 'reset', 'resetForm'], false), instructionIdsFragment, flow('initializeForm', [resolveAction('initializeForm'), takeLatest, processCurrencyExchangeFlow])));
var currencyExchangeRequestLogic = currencyExchangeRequestLogicAPI.logic;
var currencyExchangeRequestReducer = currencyExchangeRequestLogicAPI.reducer;
var currencyExchangeRequestSaga = currencyExchangeRequestLogicAPI.saga;

export { currencyExchangeRequestLogic, currencyExchangeRequestReducer, currencyExchangeRequestSaga };
