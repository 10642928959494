import _defineProperty from '@babel/runtime/helpers/defineProperty';
import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { takeLatest } from 'redux-saga/effects';
import { loadSavingsFlow } from './savings-list.flow.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var initialStateSavings = [];
var savingsListFragment = function savingsListFragment() {
  return {
    actions: {
      resetDeposits: function resetDeposits() {
        return {};
      },
      loadDeposits: function loadDeposits() {
        return {};
      },
      depositsDataLoaded: function depositsDataLoaded(savings) {
        return {
          savings: savings
        };
      },
      changeLocalDepositName: function changeLocalDepositName(itemId, friendlyName) {
        return {
          itemId: itemId,
          friendlyName: friendlyName
        };
      }
    },
    state: {
      savings: {
        'actions.loadDeposits': function actionsLoadDeposits() {
          return initialStateSavings;
        },
        'actions.depositsDataLoaded': function actionsDepositsDataLoaded(_, _ref) {
          var savings = _ref.savings;
          return savings;
        },
        'actions.loadError': function actionsLoadError() {
          return null;
        },
        'actions.resetDeposits': function actionsResetDeposits() {
          return [];
        },
        'actions.changeLocalDepositName': function actionsChangeLocalDepositName(prevState, _ref2) {
          var itemId = _ref2.itemId,
              friendlyName = _ref2.friendlyName;
          var index = prevState.findIndex(function (deposit) {
            return deposit.cbsId === itemId;
          });

          if (index !== -1) {
            var newState = _toConsumableArray(prevState);

            newState[index] = _objectSpread(_objectSpread({}, newState[index]), {}, {
              friendlyName: friendlyName
            });
            return _toConsumableArray(newState);
          }

          return _toConsumableArray(prevState);
        }
      },
      loading: {
        'actions.initializing': function actionsInitializing() {
          return true;
        },
        'actions.initialized': function actionsInitialized() {
          return false;
        },
        'actions.loadError': function actionsLoadError() {
          return false;
        }
      }
    },
    selectors: {
      savings: [['savings']]
    },
    flows: {
      loadCards: ['actions.loadDeposits', takeLatest, loadSavingsFlow]
    },
    initialState: {
      savings: initialStateSavings,
      depositsLoaded: false,
      depositsLoadFailed: false
    }
  };
};

export { savingsListFragment };
