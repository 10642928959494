var loansSettingsQuery = function loansSettingsQuery() {
  return {
    Loans: ['cbsLoanId', 'loanType', 'customProductIcon', 'friendlyName', 'currency', 'interestRate', 'startDate', 'endDate', 'loanAmount', 'account', {
      productType: ['prodTypeID', 'prodTypeBisCode', 'prodTypeCategory', 'text', {
        productIcon: ['icon']
      }, {
        detailParamsGroup: ['detailParamsGroupID']
      }]
    }, {
      customProductIcon: ['icon']
    }, {
      currency: ['currencyID']
    }, {
      account: ['cbsID', 'ibanAccount', 'friendlyName', {
        accountType: ['accTypeId', 'text']
      }, {
        accountSubType: ['id']
      }, {
        balance: ['availableBalance']
      }]
    }],
    limit: 50,
    order: {
      startDate: 'desc'
    }
  };
};

export { loansSettingsQuery };
