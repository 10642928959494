import React, { memo } from 'react'
import { BasicLayout, Layout, Flex, ErrorPage, IcPadlock } from 'vtb-ui'

const Icon = <IcPadlock stroke='#EA6B50' width={130} height={111} />
const NoRightsRouteComponent = () => {
  return (
    <BasicLayout id='noRightsRoute'>
      <Layout>
        <Flex pt={50} pb={70}>
          <ErrorPage Icon={Icon} SubMessage='common.notAllowed.errorMessage' />
        </Flex>
      </Layout>
    </BasicLayout>
  )
}

export const NoRightsRoute = memo(NoRightsRouteComponent)
