var SO_STATUSES = {
  ACTIVE: 'Active'
};
var getStandingOrderQuery = function getStandingOrderQuery() {
  var q = {
    StandingOrders: ['type', 'template', 'sortOrder', 'rateType', 'friendlyName', 'status', 'statusId', 'creditAccounts', 'frequency', 'collectionAccount', {
      debitAccount: ['ibanAccount', 'currency', 'priority', 'account']
    }, 'debitAccounts', 'startDate', 'endDate', 'nextPaymentDay', 'paymentDays', 'paymentMonth', 'productId', 'productName', 'schedule', 'scheduleId', 'lastPaymentAmount', 'lastPaymentCurrency', 'lastPaymentDate'],
    where: {
      status: SO_STATUSES.ACTIVE,
      // === statusId = 20
      isDeleted: 'false'
    },
    order: {
      sortOrder: 'asc'
    },
    limit: 1000
  };
  return q;
};
var getAllStandingOrderQuery = function getAllStandingOrderQuery() {
  var q = {
    StandingOrders: ['scheduleId'],
    where: {
      status: SO_STATUSES.ACTIVE,
      isDeleted: 'false'
    },
    limit: 10000
  };
  return q;
};
var getAccountQuery = function getAccountQuery() {
  return {
    Accounts: ['friendlyName', 'currency', 'ibanAccount', 'customProductIcon', 'accountName', 'status', 'govRestrictionSeqType', 'govRestrictionIncType', {
      balance: ['balance', 'currency', 'availableBalance']
    }, {
      accountType: ['accTypeId', 'status', 'text', {
        'defaultAccountIcon': ['icon']
      }]
    }],
    where: {
      isDeleted: 'false'
    }
  };
};

export { SO_STATUSES, getAccountQuery, getAllStandingOrderQuery, getStandingOrderQuery };
