import { path } from 'ramda';

var billPaymentTemplateGroupPayload = function billPaymentTemplateGroupPayload() {
  for (var _len = arguments.length, props = new Array(_len), _key = 0; _key < _len; _key++) {
    props[_key] = arguments[_key];
  }

  var group = props[0];
  return {
    // TODO EDIT
    // groupId: group.groupId.value,
    groupCategory: path(['groupCategory', 'value', 'groupCategoryId'], group),
    groupName: group.groupName
  };
};

export { billPaymentTemplateGroupPayload };
