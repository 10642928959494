import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { addDays, parse, min, addYears } from 'date-fns';
import { STANDING_ORDER_ENDLESS_DATE } from './constants.js';
import { DIRECT_DEBIT_MAX_DATE } from '../signing-procedures/constants.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getDateBoundaries = function getDateBoundaries(startDate, endDate) {
  var startMinDate = addDays(new Date(), 1);
  var startDateMaxBoundary = addYears(new Date(), 1);
  return {
    startMinDate: startMinDate,
    startMaxDate: endDate ? min(addDays(endDate, -1), startDateMaxBoundary) : startDateMaxBoundary,
    endMinDate: startDate ? addDays(startDate, 1) : addDays(startMinDate, 1)
  };
};

var getSoDateBoundaries = function getSoDateBoundaries(startDate, endDate) {
  return _objectSpread(_objectSpread({}, getDateBoundaries(startDate, endDate)), {}, {
    // boundary value marks endless/`when I notify` period and could not be picked
    endMaxDate: addDays(parse(STANDING_ORDER_ENDLESS_DATE), -1)
  });
};
var getDdDateBoundaries = function getDdDateBoundaries(startDate, endDate) {
  return _objectSpread(_objectSpread({}, getDateBoundaries(startDate, endDate)), {}, {
    // boundary value marks endless/`when I notify` period and could not be picked
    endMaxDate: addDays(parse(DIRECT_DEBIT_MAX_DATE), -1)
  });
};

export { getDdDateBoundaries, getSoDateBoundaries };
