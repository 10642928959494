import { createSelector } from 'reselect';

var localizationLocaleSelector = function localizationLocaleSelector(state) {
  return state.localization.locale;
};
var allLocalizationSelector = function allLocalizationSelector(state) {
  return state.localization.localizations;
};
var localizationSelector = createSelector([function (state) {
  return state.localization.localizations;
}, localizationLocaleSelector], function (localizations, locale) {
  return localizations && localizations[locale];
});

export { allLocalizationSelector, localizationLocaleSelector, localizationSelector };
