import _defineProperty from '@babel/runtime/helpers/defineProperty';
import _objectWithoutProperties from '@babel/runtime/helpers/objectWithoutProperties';
import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { find, propEq, equals, props, pipe, filter, flatten, uniq } from 'ramda';
import { validationRule, maxLength, autocorrectingValidationRule } from 'core-ingredients';
import { carNameOnlyAllowedChars } from '../../../utils/validation-patterns.js';
import { charsetWithoutAutoCorrect, requiredRule } from '../../../utils/validators.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var CAR_NAME_LENGTH = 30;
var carNameFormatValidationRules = [validationRule(maxLength(CAR_NAME_LENGTH)), validationRule(charsetWithoutAutoCorrect(carNameOnlyAllowedChars)), autocorrectingValidationRule(maxLength(CAR_NAME_LENGTH))];
var carNameValidationRules = [validationRule(requiredRule)].concat(carNameFormatValidationRules);
var getCarValidationErrors = function getCarValidationErrors(field, rules) {
  var errors = [];
  rules.forEach(function (rule) {
    var validated = rule(field);

    if (validated.errors && validated.errors.length > 0) {
      errors = uniq([].concat(_toConsumableArray(errors), _toConsumableArray(validated.errors)));
    }
  });
  return errors;
};
var validateCars = function validateCars(groupsToEdit, rules) {
  var validatedFields = groupsToEdit.map(function (item) {
    var field = {
      id: item.carTemplateId,
      value: item.carTemplateName,
      errors: getCarValidationErrors({
        value: item.carTemplateName
      }, rules)
    };
    return field;
  });
  var errors = pipe(filter(function (field) {
    return field.errors.length;
  }), flatten)(validatedFields);
  return {
    valid: errors.length === 0,
    errors: errors
  };
};
var areThereAnyChanges = function areThereAnyChanges(carsToEdit, key) {
  return function (_ref) {
    var carTemplateId = _ref.carTemplateId,
        carTemplateName = _ref.carTemplateName,
        carTemplateIcon = _ref.carTemplateIcon,
        newCarTemplateIcon = _ref.newCarTemplateIcon,
        rest = _objectWithoutProperties(_ref, ["carTemplateId", "carTemplateName", "carTemplateIcon", "newCarTemplateIcon"]);

    var potentiallyEditedGroup = find(propEq('carTemplateId', carTemplateId))(carsToEdit);
    return !equals(props(['carTemplateName', 'carTemplateIcon', 'newCarTemplateIcon', key], potentiallyEditedGroup), [carTemplateName, carTemplateIcon, newCarTemplateIcon, rest[key]]);
  };
};
var processServerErrorMessages = function processServerErrorMessages() {
  var errors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return errors === null || errors === void 0 ? void 0 : errors.map(function (err) {
    if (err.code === 'attribute.validator.illegal.chars') {
      return _objectSpread(_objectSpread({}, err), {}, {
        message: 'validation.error.charset'
      });
    }

    return _objectSpread({}, err);
  });
};
var processServersErrors = function processServersErrors(result, carsToEdit) {
  return result.map(function (error, index) {
    if (!carsToEdit[index]) {
      return null;
    }

    return {
      id: carsToEdit[index].carTemplateId,
      value: carsToEdit[index].carTemplateName,
      errors: processServerErrorMessages(error.error.info.errors)
    };
  }).filter(function (i) {
    return i;
  });
};

export { CAR_NAME_LENGTH, areThereAnyChanges, carNameFormatValidationRules, carNameValidationRules, getCarValidationErrors, processServerErrorMessages, processServersErrors, validateCars };
