var getOpenBankName = function getOpenBankName(bank, getText) {
  return bank !== null && bank !== void 0 && bank.text ? getText(bank === null || bank === void 0 ? void 0 : bank.text) : bank === null || bank === void 0 ? void 0 : bank.id;
};
var OPEN_BANKING_STEPS = {
  SELECT_BANK: 'selectBank',
  DURATION: 'duration',
  TERMS: 'terms',
  WEB_VIEW: 'webView',
  SUCCESS: 'success'
};

export { OPEN_BANKING_STEPS, getOpenBankName };
