var BILL_PAYMENT = 352;
var CONVERSION_TRANSFER = 1319;
var MOBILE_TOP_UP = 353;
var TRANSFER_TO_SOMEONE_ELSE = 321;
var TREASURY_TRANSFER = 317;
var STANDING_ORDER = 250;
var DIRECT_DEBIT_ORDER = 305;
var BATCH_PAYMENT_TRANSFER = 1381;
var MESSAGE_TO_BANK = 630;
var SINGLE_PAYMENTS = 301;
var MESSAGE_CENTER = 650;
var STANDING_ORDERS = 320;
var DIRECT_DEBITS = 330;
var BATCH_PAYMENTS = 1340;
var SIGNING_RULES_SENSITIVE_OPERATIONS = [BILL_PAYMENT, CONVERSION_TRANSFER, MOBILE_TOP_UP, TRANSFER_TO_SOMEONE_ELSE, TREASURY_TRANSFER, STANDING_ORDER, DIRECT_DEBIT_ORDER, BATCH_PAYMENT_TRANSFER, MESSAGE_TO_BANK, SINGLE_PAYMENTS, MESSAGE_CENTER, STANDING_ORDERS, DIRECT_DEBITS, BATCH_PAYMENTS];

export { SIGNING_RULES_SENSITIVE_OPERATIONS };
