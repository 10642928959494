import { composeFragments, autocorrectingValidationRule, action, state, selector } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { smartSearchAllowedCharset } from '../../../utils/validation-patterns.js';
import { charsetWithAutoCorrect } from '../../../utils/validators.js';

var commonFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'beneficiaryName',
  rules: [autocorrectingValidationRule(charsetWithAutoCorrect(smartSearchAllowedCharset))]
})), withResetForm({}), withInitializeForm({}), withFieldsSelectors, action('initializeFlow'), state(['isOpen', 'toggleModal'], function (_, _ref) {
  var isOpen = _ref.isOpen;
  return isOpen;
}, false), selector('loading', ['templatesLoading'], function (templatesLoading) {
  return templatesLoading;
}));

export { commonFragment };
