import { composeFragments } from 'core-ingredients';
import { fieldsFragment, fieldFragment } from 'core-recipes';
import { initialDatePickerLastMonth } from 'vtb-utils';

var signingRulesFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'signRole',
  initialValue: ''
}), fieldFragment({
  name: 'operation',
  initialValue: ''
}), fieldFragment({
  name: 'currencies',
  initialValue: ''
}), fieldFragment({
  name: 'account',
  initialValue: ''
}), fieldFragment({
  name: 'dates',
  initialValue: initialDatePickerLastMonth
})));

export { signingRulesFieldsFragment };
