import _extends from '@babel/runtime/helpers/extends';
import { takeLatest } from 'redux-saga/effects';
import { composeFragments, action, state, stateFlag, selector, flow, resolveAction } from 'core-ingredients';
import { listFragment } from 'core-recipes';
import { getTemplateGroupsQuery } from '../templates-query.js';
import { editGroupsProcessFlow } from './groups-process-flow.js';
import { deleteGroupFlow } from './delete-group-flow.js';
import { renameOneGroupFlow } from './groups-prefill-form-flow.js';
import { areThereAnyChanges } from './validate-template-groups.js';

var entityName = 'PaymentTemplateGroups';
var listName = 'groups';

var mapLoadedGroupsForEdit = function mapLoadedGroupsForEdit(_, _ref) {
  var response = _extends({}, _ref);

  return response[listName][entityName].map(function (_ref2) {
    var groupId = _ref2.groupId,
        groupName = _ref2.groupName,
        sortingNumber = _ref2.sortingNumber;
    return {
      groupId: groupId,
      groupName: groupName,
      sortingNumber: sortingNumber,
      sortOrder: sortingNumber
    };
  });
};

var groupsListFragment = composeFragments(listFragment({
  entityName: entityName,
  listName: listName,
  listQuery: getTemplateGroupsQuery()
}), // save group name change
action('submit'), action('calling'), action('initializeForm'), action('addNewGroup'), action('deleteGroup'), action('updateOneGroupName', function (originalGroupId, groupName) {
  return {
    originalGroupId: originalGroupId,
    groupName: groupName
  };
}), action('openDeleteConfirm', function (group) {
  return {
    group: group
  };
}), action('closeDeleteConfirm'), state(['listErrors', 'setListErrors'], function (_, _ref3) {
  var errors = _ref3.errors;
  return errors;
}, null), state(['listErrors', 'resetForm'], function () {
  return null;
}), state(['groupToDelete', 'openDeleteConfirm'], function (_, _ref4) {
  var group = _ref4.group;
  return group;
}, null), state(['groupToDelete', 'closeDeleteConfirm'], function () {
  return null;
}), state(['groupsToEdit', 'setGroupsToEdit'], function (_, _ref5) {
  var groups = _ref5.groups;
  return groups;
}, []), state(['groupsToEdit', 'groupsDataLoaded'], mapLoadedGroupsForEdit), state(['isSuccessModalOpen', 'toggleSuccessModal'], function (_, _ref6) {
  var isOpen = _ref6.isOpen;
  return isOpen;
}, false), state(['deletedGroup', 'setJustDeletedGroup'], function (_, _ref7) {
  var group = _ref7.group;
  return group;
}, null), stateFlag('deleting', ['deleteGroup'], ['groupDeleteSuccess', 'groupDeleteFailed', 'reset'], false), stateFlag('isDeleteSuccessModalOpen', ['groupDeleteSuccess'], ['groupDeleteFailed', 'closeGroupDeleteSuccessModal'], false), stateFlag('isDeleteErrorModalOpen', ['groupDeleteFailed'], ['groupDeleteSuccess', 'closeGroupDeleteSuccessModal'], false), stateFlag('isSuccessModalOpen', ['tmpSuccess'], ['initializeForm', 'reset', 'resetForm']), stateFlag('deleteConfirmOpened', ['openDeleteConfirm'], ['groupDeleteSuccess', 'groupDeleteFailed', 'closeDeleteConfirm', 'reset'], false), stateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm', 'tmpSuccess'], false), stateFlag('submitting', ['calling'], ['tmpSuccess', 'tmpFailed', 'failed', 'backToFirstStep', 'groupDeleteFailed', 'setListErrors'], false), selector('loading', ['groupsLoading', 'submitting'], function (groupsLoading, submitting) {
  return groupsLoading || submitting;
}), selector('isListChanged', ['groupsToEdit', 'groups'], function (groupsToEdit, groups) {
  return groups.some(areThereAnyChanges(groupsToEdit, 'sortingNumber'));
}), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, editGroupsProcessFlow]), flow('deleteGroup', [resolveAction('deleteGroup'), takeLatest, deleteGroupFlow]), flow('updateOneGroupName', [resolveAction('updateOneGroupName'), takeLatest, renameOneGroupFlow]));

export { groupsListFragment };
