import { VTB_ERRORS } from 'vtb-utils'

const unauthorizedError = (error) => ({
  type: 'AUTH_CONTROL_SESSION_LOGOUT',
  error,
})

export const errorInterceptor = {
  name: 'errorInterceptor',
  error: async (operation, error, { put }) => {
    if ((error && error.status === 400 && error.body.code === VTB_ERRORS.UNAUTHORIZED) || error.status === 504) {
      await put(unauthorizedError(error))
    }

    throw error
  },
}
