import { takeLatest } from 'redux-saga/effects';
import { logic2, composeFragments, pathFragment, action, selector, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { listFragment, configResetListFragment } from 'core-recipes';
import { directDebitQuery } from '../dd.queries.js';
import { beforeDirectDebitsQueryHandler, afterDirectDebitsLoadedFlow, addSortIndexAndSort, ddUpdateSortingFlow } from './dd-list.flows.js';
import { userLogic } from '../../../../generic/user/user.logic.js';

var ddListLogic = logic2(composeFragments(pathFragment('dd', 'list'), listFragment({
  listName: 'directDebits',
  entityName: 'DirectDebits',
  listQuery: directDebitQuery,
  beforeQueryHandler: beforeDirectDebitsQueryHandler,
  afterQueryHandler: afterDirectDebitsLoadedFlow
}, configResetListFragment({})), action('onSortEnd'), selector('sortedDirectDebitsOrders', ['directDebits', userLogic.selectors.clientUserSettings], function (directDebits, clientUserSettings) {
  return addSortIndexAndSort(directDebits, clientUserSettings);
}), flow('updateSorting', [resolveAction('onSortEnd'), takeLatest, ddUpdateSortingFlow])));
var ddListReducer = ddListLogic.reducer;
var ddListSaga = sagaifyLogic2(ddListLogic);

export { ddListLogic, ddListReducer, ddListSaga };
