import { format } from 'date-fns';
import { OPERATION_TYPE, CURRENCY_EXCHAGE_RATES } from 'vtb-utils';

var mapInstruction = function mapInstruction(values) {
  var debitAccount = values.debitAccount;
  return {
    accountNumber: debitAccount && debitAccount.id
  };
};
var mapCurrencyExchangeInstructionItem = function mapCurrencyExchangeInstructionItem(values) {
  var debitAccount = values.debitAccount,
      creditAccount = values.creditAccount,
      sourceAmount = values.sourceAmount,
      targetAmount = values.targetAmount,
      convertMoney = values.convertMoney,
      operationType = values.operationType,
      customerId = values.customerId,
      channelId = values.channelId,
      fxRequestValues = values.fxRequestValues;

  var _ref = convertMoney || {},
      bestRateType = _ref.bestRateType,
      standardRate = _ref.standardRate,
      channelRate = _ref.channelRate,
      customerRate = _ref.customerRate;

  var _ref2 = fxRequestValues || {},
      comment = _ref2.comment,
      mobileNumber = _ref2.mobileNumber;

  var usedRate = bestRateType === CURRENCY_EXCHAGE_RATES.CHR ? channelRate : bestRateType === CURRENCY_EXCHAGE_RATES.CUS ? customerRate : standardRate;
  return {
    customerId: customerId,
    debitAccountId: debitAccount.id,
    creditAccountId: creditAccount.id,
    fromCcy: debitAccount.currency,
    toCcy: creditAccount.currency,
    amountToExchange: operationType === OPERATION_TYPE.SELL ? sourceAmount : targetAmount,
    date: format(new Date(), 'YYYY-MM-DD'),
    purpose: 'Conversion Request',
    transactionReference: '-',
    channelId: channelId,
    rate__multiplier: usedRate.multiplier,
    rate__value: usedRate.value,
    systemOfferedRateValue: usedRate.value,
    rate__isReversed: usedRate.isReversed,
    operationType: operationType,
    rateType: 'Individual',
    userNote: comment,
    phone: mobileNumber
  };
};

export { mapCurrencyExchangeInstructionItem, mapInstruction };
