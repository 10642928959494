import { logic2, composeFragments, pathFragment, addStateFlag, resolveAction, action, state } from 'core-ingredients';
import { loadingFragment } from '../../loading.fragment.js';
import { LOAN_CATEGORIES } from '../loans.constants.js';
import { checkRightsFragment } from '../../../permissions/index.js';
import { loansListFragment } from './loans-list.fragment.js';
import { VIEW_LOANS } from '../../../permissions/rights.js';

var logic = logic2(composeFragments(pathFragment('loans', 'list'), loadingFragment(), loansListFragment(), checkRightsFragment({
  rights: VIEW_LOANS
}), addStateFlag(['loansLoaded'], [resolveAction('loansDataLoaded')], [resolveAction('loadError')]), addStateFlag(['loansLoadFailed'], [resolveAction('loadError')], [resolveAction('loansDataLoaded')]), action('updateLoansByCategory', function (loansByCategory) {
  return {
    loansByCategory: loansByCategory
  };
}), state(['loansByCategory', 'updateLoansByCategory'], function (_, _ref) {
  var loansByCategory = _ref.loansByCategory;
  return loansByCategory;
})));
logic.CONSTANTS = {
  LOAN_CATEGORIES: LOAN_CATEGORIES
};
var loansLogic = logic;

export { loansLogic };
