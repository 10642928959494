import _objectWithoutProperties from '@babel/runtime/helpers/objectWithoutProperties';
import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { Flex } from '../components/generic/flex.js';

var _templateObject;

var ModalWrapper = styled(Flex).attrs(function (_ref) {
  var theme = _ref.theme,
      props = _objectWithoutProperties(_ref, ["theme"]);

  return {
    flex: 1,
    width: props.width || '100%',
    height: '100%',
    background: theme.colors.clear,
    flexDirection: 'column',
    alignItems: 'center',
    borderTopRightRadius: ['50px', '100px'],
    borderBottomLeftRadius: ['50px', '100px'],
    borderTopLeftRadius: '15px',
    borderBottomRightRadius: '15px',
    shadow: 7,
    shadowColor: 'neutralPrimary',
    padding: '56px 32px 32px 32px'
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-flex;\n"])));

export { ModalWrapper };
