import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { systemParamSelectorFactory } from '../app/systemParamSelectorFactory.js';
import { logoutFragment } from './logout.fragment.js';
import { autologoutFragment } from './autologout.fragment.js';
import { unauthenticatedFragment } from './unauthenticated.fragment.js';
import { webAuthenticationFragment } from './web-authentication.fragment.js';

var isNative = navigator && navigator.product === 'ReactNative';
var logoutLogic = logic2(composeFragments(isNative ? pathFragment('logout') : pathFragment('authControl', 'session'), logoutFragment(), autologoutFragment({
  promptInterval: systemParamSelectorFactory('IBS_SESSION_IDLE_TIMEOUT_WARNING')
}), unauthenticatedFragment(), webAuthenticationFragment({})));
var logoutReducer = logoutLogic.reducer;
var logoutSaga = sagaifyLogic2(logoutLogic);

export { logoutLogic, logoutReducer, logoutSaga };
