import { composeFragments, pathFragment, resolveLogic } from 'core-ingredients';
import { digipassOtpFragment } from '../digipass-otp.fragment.js';
import { digipassPinFragment } from '../digipass-pin.fragment.js';
import { changePinProcessingFragment } from './change-pin.fragment.js';
import { changePinLoginFragment } from './change-pin-login.fragment.js';

var digipassChangePinSchema = composeFragments(pathFragment('digipass', 'changePin'), changePinProcessingFragment(), changePinLoginFragment(), digipassOtpFragment(), digipassPinFragment());

var _resolveLogic = resolveLogic(digipassChangePinSchema),
    logic = _resolveLogic.logic,
    reducer = _resolveLogic.reducer,
    saga = _resolveLogic.saga;

export { logic as digipassChangePinLogic, reducer as digipassChangePinReducer, saga as digipassChangePinSaga, digipassChangePinSchema };
