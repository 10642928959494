import { resolveLogic, composeFragments, pathFragment } from 'core-ingredients';
import { withFieldsSelectors } from 'core-recipes';
import lnPwdSmsFragment from './ln-pwd-sms.fragment.js';

var logicApi = resolveLogic(composeFragments(pathFragment('ln_pwd_sms'), lnPwdSmsFragment(), withFieldsSelectors));
var lnPwdSmsLogic = logicApi.logic;
var lnPwdSmsReducer = logicApi.reducer;
var lnPwdSmsSaga = logicApi.saga;

export { lnPwdSmsLogic, lnPwdSmsReducer, lnPwdSmsSaga };
