import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { uniq, path, groupBy, find, propEq, sortWith, ascend, prop, descend } from 'ramda';
import { LANGUAGES, isDebitCardAccount, composeEntityName, USER_SETTINGS_REALMS, ACCOUNT_GROUP_ID } from 'vtb-utils';
import { setCardAccountBalance } from '../../accounts/list/accounts-list.helpers.js';
import { isExpired, isInBeforeExpirationPeriod, isCardNew } from '../../../products/notifications/mm-notifications.helpers.js';
import { createBalance } from '../../balance/create-balance.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var composeAccountName = function composeAccountName(mainAccount) {
  var name = mainAccount.friendlyName;
  var suffix;

  if (!name) {
    // account has no friendlyName so accountType name is used:
    name = composeEntityName(mainAccount);
    var shortIban = mainAccount.ibanAccount.substr(mainAccount.ibanAccount.length - 8); // with trailing +spaces:

    suffix = " *".concat(shortIban, " ");
  } else {
    name += ' ';
  }

  return {
    name: name,
    suffix: suffix
  };
};

var getFetchedAccounts = function getFetchedAccounts(fetchedAccounts, businessCardsData, accountsWithBalancesAndCurrencies) {
  var accounts = fetchedAccounts && fetchedAccounts.data && fetchedAccounts.data.Accounts ? fetchedAccounts.data.Accounts : [];
  businessCardsData.forEach(function (cardHolder) {
    var isCardIbanInLoadedAccounts = accounts.some(function (acc) {
      return acc.ibanAccount === cardHolder.originalCardIban;
    });
    var availableBalanceSum = accountsWithBalancesAndCurrencies[cardHolder.originalCardIban];

    if (!isCardIbanInLoadedAccounts) {
      accounts.push({
        cbsID: null,
        entityUniqueId: null,
        accountName: cardHolder.accountName,
        accountSubType: null,
        friendlyName: cardHolder.friendlyName,
        ibanAccount: cardHolder.originalCardIban,
        entityType: 'Account',
        availableBalanceSum: {
          amount: availableBalanceSum && availableBalanceSum.amount || null,
          currency: availableBalanceSum && availableBalanceSum.currency || null
        },
        isOthersAccount: true
      });
    }
  });
  return accounts;
};
var getAccountsWithBalances = function getAccountsWithBalances(fetchedBalanaces) {
  var result = {};
  fetchedBalanaces.forEach(function (_ref2) {
    var multicurrencyAccount = _ref2.multicurrencyAccount,
        balance = _ref2.balance,
        currency = _ref2.currency,
        priorityCcy = _ref2.priorityCcy;

    if (multicurrencyAccount && multicurrencyAccount.iban) {
      result[multicurrencyAccount.iban] = _objectSpread(_objectSpread({}, createBalance(balance, currency.currencyID)), {}, {
        priorityCurrency: priorityCcy.currencyID
      });
    }
  });
  return result;
};

var pickCardForAccount = function pickCardForAccount(cards) {
  if (cards.length === 1) {
    return cards[0].cbsId;
  }

  var activeCard = cards.find(function (card) {
    var status = path(['cardStatus', 'cardStatusId'])(card);
    return ['30', 'Active'].includes(status);
  });
  return (activeCard === null || activeCard === void 0 ? void 0 : activeCard.cbsId) || cards[0].cbsId;
};
/**
 * Returns one card cbsID for every card account
 *
 * @param cards Array<ICard>
 */


var getSingleCardIdForEveryCardAccount = function getSingleCardIdForEveryCardAccount(cards) {
  var cardsByIbans = groupBy(path(['ibanAccount', 'iban']), cards);
  return {
    cards: uniq(Object.values(cardsByIbans).map(pickCardForAccount)),
    ibans: Object.keys(cardsByIbans)
  };
};
/**
 * Returns array which is iterated in cards list component
 *
 * @param data Array<ICard> Collection of fetched cards
 * @param fetchedAccounts Array<IAccount> Accounts in priority currency
 */

var composeCardsList = function composeCardsList() {
  var _clientUserSettings$U, _clientUserSettings$U2;

  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var fetchedAccounts = arguments.length > 1 ? arguments[1] : undefined;
  var businessCardsData = arguments.length > 2 ? arguments[2] : undefined;
  var accountsWithBalancesAndCurrencies = arguments.length > 3 ? arguments[3] : undefined;
  var SYSTEM_PARAMS = arguments.length > 4 ? arguments[4] : undefined;
  var clientUserSettings = arguments.length > 5 ? arguments[5] : undefined;
  var applePayFpanIds = arguments.length > 6 ? arguments[6] : undefined;
  var originalAccountsWithCards = arguments.length > 7 ? arguments[7] : undefined;
  var filteredAccountsList = getFetchedAccounts(fetchedAccounts, businessCardsData, accountsWithBalancesAndCurrencies);
  var accounts = [];
  var debitCardAccountsSorting = (clientUserSettings === null || clientUserSettings === void 0 ? void 0 : (_clientUserSettings$U = clientUserSettings[USER_SETTINGS_REALMS.ACCOUNT_SORTING]) === null || _clientUserSettings$U === void 0 ? void 0 : _clientUserSettings$U[ACCOUNT_GROUP_ID.DEBIT]) || [];
  var creditCardAccountsSorting = (clientUserSettings === null || clientUserSettings === void 0 ? void 0 : (_clientUserSettings$U2 = clientUserSettings[USER_SETTINGS_REALMS.ACCOUNT_SORTING]) === null || _clientUserSettings$U2 === void 0 ? void 0 : _clientUserSettings$U2[ACCOUNT_GROUP_ID.CREDIT]) || [];
  data.forEach(function (card, index) {
    card.id = index;
    card.isInExpiredPeriod = isExpired(card);

    var _isInBeforeExpiration = isInBeforeExpirationPeriod(card, SYSTEM_PARAMS.MCH_CARD_BEFOREEXPIRE_WARNING),
        isInExpirationPeriod = _isInBeforeExpiration.isInExpirationPeriod,
        daysToExpiration = _isInBeforeExpiration.daysToExpiration;

    card.isInBeforeExpirationPeriod = isInExpirationPeriod;
    card.daysToExpiration = daysToExpiration;
    card.sysParams = {
      MCH_CARD_BEFOREEXPIRE_WARNING: SYSTEM_PARAMS.MCH_CARD_BEFOREEXPIRE_WARNING,
      MCH_CARD_EXPIRED_VISIBILITY: SYSTEM_PARAMS.MCH_CARD_EXPIRED_VISIBILITY
    };
    card.isNew = isCardNew(card);
    var iban = card.ibanAccount ? card.ibanAccount.iban : null;

    if (!iban) {
      return;
    }

    if (applePayFpanIds) {
      var cbsIdWithFpanId = find(propEq('cardId', card.cbsId))(applePayFpanIds);

      if (cbsIdWithFpanId && cbsIdWithFpanId.fpanId) {
        card.applePayFpanId = cbsIdWithFpanId.fpanId;
      }
    }

    var acc = find(propEq('id', iban))(accounts);

    if (!acc) {
      var mainAccount = filteredAccountsList.find(propEq('ibanAccount', iban));

      if (!mainAccount) {
        return;
      }

      if (!mainAccount.isOthersAccount || mainAccount.isOthersAccount && mainAccount.availableBalanceSum.currency) {
        setCardAccountBalance(mainAccount, accountsWithBalancesAndCurrencies);
      }

      var isDebit = isDebitCardAccount(path(['accountType', 'accTypeId'], mainAccount), path(['accountSubType', 'id'], mainAccount));

      var _sortingRealm = isDebit ? debitCardAccountsSorting : creditCardAccountsSorting;

      if (!Array.isArray(_sortingRealm)) {
        _sortingRealm = [];
      }

      var userSortingIndex = _sortingRealm.indexOf(mainAccount.ibanAccount);

      acc = {
        id: iban,
        isDebit: isDebit,
        balance: mainAccount.availableBalanceSum && mainAccount.availableBalanceSum.amount,
        currency: mainAccount.availableBalanceSum && mainAccount.availableBalanceSum.currency,
        accountTitle: composeAccountName(mainAccount),
        cards: [],
        cbsID: mainAccount.cbsID,
        // new accounts (without assigned index yet) are pushed to the bottom
        // (but still within DEBIT or CREDIT accounts - these two kinds of accounts are not mixed)
        userSortingIndex: userSortingIndex < 0 ? _sortingRealm.length : userSortingIndex,
        isOthersAccount: Boolean(mainAccount.isOthersAccount)
      };
      accounts.push(acc);
    }

    card.accountId = acc.cbsID;
    var cardUserSorting = path(['sorting', 'cards', iban], clientUserSettings) || [];
    card.userSortingIndex = cardUserSorting.indexOf(card.cbsId);
    acc.cards.push(card);
  });
  var accountsProcessed = accounts.map(function (account) {
    originalAccountsWithCards.find(function (originalAccount) {
      var _account;

      if ((originalAccount === null || originalAccount === void 0 ? void 0 : originalAccount.ibanAccount) === ((_account = account) === null || _account === void 0 ? void 0 : _account.id)) {
        account = _objectSpread(_objectSpread({}, account), {}, {
          accountTitle: composeAccountName(originalAccount)
        });
      }
    });
    return account;
  });
  return accountsProcessed;
};

var sortAccounts = function sortAccounts(groups) {
  return (// sort accounts according to card type - DEBIT on the top
    // AND according to user-defined sorting (new accounts without defined sorting are on the bottom)
    sortWith([descend(prop('isDebit')), ascend(prop('userSortingIndex'))])(groups)
  );
};

var sortAllCards = function sortAllCards(groups) {
  groups.forEach(function (account) {
    // sort cards according to openDate, newest on the top
    account.cards = sortWith([ascend(prop('userSortingIndex')), descend(prop('openDate'))])(account.cards);
  });
  return groups;
};
/**
 * Method sorts accounts with cards according to loaded user settings (if exists) or by AN definition
 * @see https://support.bsc-ideas.com/confl/display/VTBREAL/Products+sorting
 *
 * @param groups Array<ICardsListGroup>
 * @param clientUserSettings
 */


var sortCardsList = function sortCardsList(groups) {
  var sortedAccounts = sortAccounts(groups);
  return sortAllCards(sortedAccounts);
};

var getBusinessOwnerForClient = function getBusinessOwnerForClient(currentLang, businessClient, currentClient) {
  if (!businessClient) {
    return null;
  }

  var currentClientId = currentClient && Number(currentClient.externalClientID);
  var isAccountOwner = currentClientId === Number(businessClient.accountCustomer);
  return _objectSpread({
    idsDiffers: Number(businessClient.cardHolderCustomer) !== Number(businessClient.accountCustomer),
    isCardHolder: currentClientId === Number(businessClient.cardHolderCustomer),
    isAccountOwner: isAccountOwner,
    canEditCard: isAccountOwner
  }, businessClient);
};

var getBusinessOwner = function getBusinessOwner(account, currentLang, businessClients, currentClient) {
  return (businessClients || []).filter(function (holder) {
    return holder.originalCardIban === account.id;
  }) // use ka version for georgian, use EN version for en and russian
  .map(function (client) {
    var name = client.clientOwnerName ? currentLang === LANGUAGES.KA ? client.clientOwnerName[currentLang] : client.clientOwnerName[LANGUAGES.EN] : '';
    return _objectSpread({
      name: name
    }, getBusinessOwnerForClient(currentLang, client, currentClient));
  }).shift();
};
var getCardsOwners = function getCardsOwners(cards, currentLang, businessClients, currentClient) {
  return cards.map(function (card) {
    return _objectSpread(_objectSpread({}, card), {}, {
      businessOwner: getBusinessOwnerForClient(currentLang, businessClients.find(function (_ref3) {
        var cardId = _ref3.cardId;
        return cardId === card.cbsId;
      }), currentClient)
    });
  });
};

export { composeCardsList, getAccountsWithBalances, getBusinessOwner, getCardsOwners, getSingleCardIdForEveryCardAccount, sortCardsList };
