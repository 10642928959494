import { composeFragments, action, state, stateFlag, selector, flow, resolveAction } from 'core-ingredients';
import { takeLatest } from 'redux-saga/effects';
import { loadInstructionsFlow, waitingBalanceCountFlow } from '../flows/tpm-flows.js';
import { selectItemFlow, selectAllFlow, deselectAllFlow } from '../flows/selecting-flows.js';
import { authorizeBasketFlow } from '../flows/authorize-basket.flow.js';
import { cancelFlow } from '../flows/cancel.flow.js';
import { repeatItemFlow } from '../flows/repeat.flow.js';
import { showInstructionCanceledByFlow } from '../flows/show-instruction-canceled-by.flow.js';
import { signingProceduresDetailLogic } from '../../detail/signing-procedures-detail.logic.js';

var loadInstructionsFragment = composeFragments(action('loadInstructions'), action('deleteUnreadAuthorization'), state(['waitingForMeCount', 'waitingForMeCountLoaded'], function (_, _ref) {
  var data = _ref.data;
  return data;
}, 0), state(['waitingBalanceCount', 'setWaitingBalanceCount'], function (_, _ref2) {
  var count = _ref2.count;
  return count;
}, 0), state(['archivedUnread', 'setArchiveUnread'], function (_, _ref3) {
  var data = _ref3.data;
  return data;
}, []), state(['exchangesUnread', 'setExchangesUnread'], function (_, _ref4) {
  var data = _ref4.data;
  return data;
}, []), state(['archivedNonFinInstructions', 'unreadNonFinInstrLoaded'], function (_, _ref5) {
  var data = _ref5.data;
  return data;
}, []), state(['financialInstructions', 'instructionDataLoaded'], function (_, _ref6) {
  var data = _ref6.data;
  return data;
}, []), state(['nonFinancialInstructions', 'nonFinInstrDataLoaded'], function (_, _ref7) {
  var data = _ref7.data;
  return data;
}, []), state(['individualRateInstructions', 'individualRateInstrDataLoaded'], function (_, _ref8) {
  var data = _ref8.data;
  return data;
}, []), state(['userInvitationInstructions', 'userInvInstrDataLoaded'], function (_, _ref9) {
  var data = _ref9.data;
  return data;
}, []), state(['archivedInstructions', 'archivedInstructionsDataLoaded'], function (_, _ref10) {
  var data = _ref10.data;
  return data;
}, null), stateFlag('repeatingInProgress', ['startRepeat'], ['stopRepeat'], false), state(['financialInstructions', 'reset'], function () {
  return null;
}), // LOADING
stateFlag('hasSufficientBalance', ['sufficientBalanceIndicated'], ['noBalanceIndicated', 'expectedResultFailed'], false), stateFlag('archivedInstructionsLoading', ['loadInstructions'], ['archivedInstructionsDataLoaded', 'archivedInstructionsLoadError', 'reset'], false), stateFlag('financialInstructionsLoading', ['loadInstructions'], ['instructionDataLoaded', 'instructionsLoadError', 'reset'], false), stateFlag('nonFinancialInstructionsLoading', ['loadInstructions'], ['nonFinInstrDataLoaded', 'nonFinancialInstructionsLoadError', 'reset'], false), stateFlag('individualRateInstructionsLoading', ['loadInstructions'], ['individualRateInstrDataLoaded', 'individualRateInstructionsLoadError', 'reset'], false), stateFlag('userInvitationInstructionsLoading', ['loadInstructions'], ['userInvInstrDataLoaded', 'userInvitationInstructionsLoadError', 'reset'], false), stateFlag('waitingForMeCountLoading', ['loadInstructions'], ['waitingForMeCountLoaded', 'userInvitationInstructionsLoadError', 'reset'], false), // both particular and all instruction loaders are kept in usage
// - in case only one instr. type call fails other will still have a loading indication
// + multiple loaders still in use at GMB
selector('allInstructionsBeingLoaded', ['financialInstructionsLoading', 'nonFinancialInstructionsLoading', 'individualRateInstructionsLoading', 'userInvitationInstructionsLoading'], function (financialInstructionsLoading, nonFinancialInstructionsLoading, individualRateInstructionsLoading, userInvitationInstructionsLoading) {
  return financialInstructionsLoading || nonFinancialInstructionsLoading || individualRateInstructionsLoading || userInvitationInstructionsLoading;
}), // FAILED
stateFlag('instructionsLoadFailed', ['instructionsLoadError'], ['instructionDataLoaded', 'reset'], false), stateFlag('nonFinancialInstructionsLoadFailed', ['nonFinancialInstructionsLoadError'], ['nonFinInstrDataLoaded', 'reset'], false), stateFlag('individualRateInstructionsLoadFailed', ['individualRateInstructionsLoadError'], ['individualRateInstrDataLoaded', 'reset'], false), stateFlag('userInvitationInstructionsLoadFailed', ['userInvitationInstructionsLoadError'], ['userInvInstrDataLoaded', 'reset'], false), // LOADED
stateFlag('instructionsLoaded', ['instructionDataLoaded'], ['instructionsLoadError', 'reset'], false), stateFlag('nonFinancialInstructionsLoaded', ['nonFinInstrDataLoaded'], ['nonFinancialInstructionsLoadError', 'reset'], false), stateFlag('individualRateInstructionsLoaded', ['individualRateInstrDataLoaded'], ['individualRateInstructionsLoadError', 'reset'], false), stateFlag('userInvitationInstructionsLoaded', ['userInvInstrDataLoaded'], ['userInvitationInstructionsLoadError', 'reset'], false), flow('fetchIntructionsFlow', [resolveAction('loadInstructions'), takeLatest, loadInstructionsFlow]), flow('waitingBalanceCountFlow', [resolveAction('loadInstructions'), takeLatest, waitingBalanceCountFlow]));
var signingProceduresFragment = composeFragments(loadInstructionsFragment, action('checkAll'), action('deselectAll'), action('authorizeBasket'), action('storeCheckedItems', function (newItems) {
  return {
    newItems: newItems
  };
}), action('checkItem', function (item) {
  return {
    item: item
  };
}), action('repeatItem'), action('setChosenSubtype', function (chosenSubType) {
  return {
    chosenSubType: chosenSubType
  };
}), action('resetChosenSubtype', function () {
  return {};
}), action('setPreviousGroup', function (previousGroup) {
  return {
    previousGroup: previousGroup
  };
}), action('setCurrentGroup', function (currentGroup) {
  return {
    currentGroup: currentGroup
  };
}), action('cancelInstruction', function (item, tab) {
  return {
    item: item,
    tab: tab
  };
}), action('cancelInstructionError', function () {
  return null;
}), action('showInstructionCanceledBy', function (instruction) {
  return {
    instruction: instruction
  };
}), action('setItemToCancel', function (item) {
  return {
    item: item
  };
}), action('setTab', function (tab) {
  return {
    tab: tab
  };
}), action('setInstructionCanceledBy'), state(['instructionCanceledBy', 'setInstructionCanceledBy'], function (_, user) {
  return user;
}, null), state(['instructionCanceledBy', 'resetInstructionCanceledBy'], function () {
  return null;
}), state(['itemToCancel', 'setItemToCancel'], function (_, item) {
  return item;
}, null), state(['sectionTab', 'setTab'], function (_, _ref11) {
  var tab = _ref11.tab;
  return tab;
}, null), state(['previousGroup', 'setPreviousGroup'], function (_, _ref12) {
  var previousGroup = _ref12.previousGroup;
  return previousGroup;
}, null), state(['currentGroup', 'setCurrentGroup'], function (_, _ref13) {
  var currentGroup = _ref13.currentGroup;
  return currentGroup;
}, null), state(['chosenSubType', 'setChosenSubtype'], function (_, _ref14) {
  var chosenSubType = _ref14.chosenSubType;
  return chosenSubType;
}, null), state(['chosenSubType', 'resetChosenSubtype'], function () {
  return null;
}), state(['chosenSubType', 'deselectAll'], function () {
  return null;
}), state(['checkedItems', 'storeCheckedItems'], function (_, _ref15) {
  var newItems = _ref15.newItems;
  return newItems;
}, []), state(['checkedItems', 'deselectAll'], function () {
  return [];
}), stateFlag('cancelModalOpened', ['openCancelModal'], ['closeCancelModal', 'cancelSuccess'], false), stateFlag('cancelFailed', ['cancelInstructionError'], ['closeCancelModal'], false), stateFlag('canceling', ['initCancel'], ['cancelSuccess', 'cancelInstructionError'], false), stateFlag('partialySelected', ['partiallySelectedOn'], ['partiallySelectedOff'], false), stateFlag('allSelected', ['toggleAll'], ['toggleAllOff', 'selectAllNonFinToggle'], false), stateFlag('financialGroupSelected', ['setAllOpen', 'selectFinGroup'], ['selectExceptionalGroup', 'selectNonFinGroup', 'selectIndividualRateGroup', 'selectUserInvitationGroup'], true), stateFlag('nonFinancialGroupSelected', ['setAllOpen', 'selectNonFinGroup'], ['selectExceptionalGroup', 'selectFinGroup', 'selectIndividualRateGroup', 'selectUserInvitationGroup'], true), stateFlag('individualRateGroupSelected', ['setAllOpen', 'selectIndividualRateGroup'], ['selectExceptionalGroup', 'selectFinGroup', 'selectNonFinGroup', 'selectUserInvitationGroup'], true), stateFlag('userInvitationGroupSelected', ['setAllOpen', 'selectUserInvitationGroup'], ['selectExceptionalGroup', 'selectFinGroup', 'selectNonFinGroup', 'selectIndividualRateGroup'], true), flow('selectItemFlow', [resolveAction('checkItem'), takeLatest, selectItemFlow]), flow('repeatItemFlow', [resolveAction('repeatItem'), takeLatest, repeatItemFlow]), flow('selectAllFlow', [resolveAction('checkAll'), takeLatest, selectAllFlow]), flow('authorizeBasket', [resolveAction('authorizeBasket'), takeLatest, authorizeBasketFlow]), flow('cancelInstructionFlow', [resolveAction('cancelInstruction'), takeLatest, cancelFlow]), flow('showInstructionCanceledByFlow', [resolveAction('showInstructionCanceledBy'), takeLatest, showInstructionCanceledByFlow]), flow('deselectAllFlow', [signingProceduresDetailLogic.actions.deselectAll, takeLatest, deselectAllFlow]));

export { signingProceduresFragment };
