import { composeFragments, validationRule, minLength, maxLength } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withResetForm, withFieldsSelectors } from 'core-recipes';
import { requiredRule, charset } from '../../utils/validators.js';

var addDigiPassFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'serialNumber',
  rules: [validationRule(requiredRule), validationRule(charset(/^[^ ]+$/)), validationRule(minLength(8)), validationRule(maxLength(15))]
})), withResetForm({}), withFieldsSelectors);

export { addDigiPassFieldsFragment };
