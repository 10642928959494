import { logic2, composeFragments, pathFragment, stateFlag, state } from 'core-ingredients';
import { certify } from 'core-recipes';
import changePasscodeFields from './change-passcode-fields.js';
import actionsFragment from './change-passcode-actions.js';
import flowsFragment from './change-passcode-flows.js';

var withCertificationFragment = certify.withCertificationFragment;
var logicApi = logic2(composeFragments(pathFragment('security', 'changePasscode'), changePasscodeFields(), actionsFragment(), flowsFragment(), withCertificationFragment({}), stateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm'], false), stateFlag('isModalGMB', ['openModalGMB', 'failed', 'denied'], ['closeModalGMB']), stateFlag('gmbSMS', ['openVerify'], ['closeVerify']), stateFlag('changePasscodeFailed', ['failed', 'denied'], ['initialize'], false), stateFlag('process', ['startProcess'], ['error', 'failed', 'endProcess'], false), state(['certifiable', 'certificationSubmited'], function (_, _ref) {
  var certifiable = _ref.certifiable;
  return certifiable;
}, false), state(['newPasscodeInputType', 'newTypeToggle'], function (state) {
  return state === 'password' ? 'text' : 'password';
}, 'password'), state(['repeatPasscodeInputType', 'repeatTypeToggle'], function (state) {
  return state === 'password' ? 'text' : 'password';
}, 'password')));

export default logicApi;
