import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { catalogFragment } from 'core-recipes';
import { loadingFragment } from '../../loading.fragment.js';
import { pendingTransactionsTotalsFragment } from '../../pending-transactions-totals.fragment.js';
import { accountCardLimitOverdraftFragment } from '../card-limit-overdraft/card-limit-overdraft.fragmanet.js';
import { accountRequisitesFragment } from './requisites/accounts-requisites.fragment.js';
import { accountsDetailFragment } from './accounts-detail.fragment.js';
import { exportFragment } from '../../../generic/export/export.fragment.js';
import { exportRequisitesConfigFactory } from '../../../generic/export/export-config-factories.js';

var accountDetailLogic = logic2(composeFragments(pathFragment('accounts', 'detail'), loadingFragment(), pendingTransactionsTotalsFragment, accountsDetailFragment(), accountRequisitesFragment(), accountCardLimitOverdraftFragment(), catalogFragment({
  name: 'currencies',
  querySchema: {
    Currencies: ['flag', 'currencyID'],
    limit: 1000
  }
}), exportFragment({
  configFactory: exportRequisitesConfigFactory
})));
var accountDetailReducer = accountDetailLogic.reducer;
var accountDetailSaga = sagaifyLogic2(accountDetailLogic);

export { accountDetailLogic, accountDetailReducer, accountDetailSaga };
