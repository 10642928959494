import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';

var _templateObject;
/**
 * Adds an animation wrapper around any component
 * @param Component
 * @param animation definition
 * @param time
 * @returns Animated StyledComponent
 */

var withAnimation = function withAnimation(Component, animation, time) {
  return styled(Component)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  animation: ", " ", "s ease;\n"])), animation, time);
};

export { withAnimation };
