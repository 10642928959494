/* eslint-disable global-require */

import '@babel/polyfill'
import 'whatwg-fetch'
import './filter-console-errors'
import './index.css'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { appLogic } from 'vtb-logic'

import * as serviceWorker from './serviceWorker'
import AppContainer from './app/container'
import createSupply from './app/supply'
import createStore from './app/store'
import { localizationCollection } from './localizations/localization-handler'

const supply = createSupply()
const store = createStore(supply)
const NODE = document.getElementById('app')

const onErrorHandler = () => {
  store.dispatch(appLogic.actions.loadLocalLocalizations({ localizations: localizationCollection }))
}

store.dispatch(appLogic.actions.starting({ clientSystem: 'GIB', onError: onErrorHandler }))

// eslint-disable-next-line no-console, no-undef
console.log('%c Digital bank of VTB Bank Georgia: GIB-' + process.env.GIB_VERSION, 'color:darkblue; background:white; font-size:16px')

const render = () => {
  ReactDOM.render(<AppContainer store={store} />, NODE)
}

render()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
