import { createReduxSagaSupply } from 'core-supply'

import legacy from './legacy'
import graph from './graph'

const createSupply = () =>
  createReduxSagaSupply({
    entries: {
      legacy,
      graph,
    },
  })

export default createSupply
