import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { listFragment } from 'core-recipes';
import { composeFragments, action, state, stateFlag, flow, resolveAction, selector, extendInitialState } from 'core-ingredients';
import { takeLatest } from 'redux-saga/effects';
import { identity, mergeAll, path } from 'ramda';
import { PAYMENT_ALL_GROUP_ID, isCardOrCurrentGELAccount, ACCOUNT_VISIBILITY, INSTRUCTION_TYPES, PAYMENT_DUE_GROUP_ID, enrichDataWithService, TEMPLATE_WITHOUT_GROUP_ID } from 'vtb-utils';
import BigNumber from 'bignumber.js';
import { billPaymentGroupsQuery } from '../template/bill-payment.queries.js';
import { billPaymentLogic } from '../wizard/bill-payment.logic.js';
import { instructionIdsFragment } from '../../common/instruction-logic.js';
import { decideInstructionTypeFragment } from '../../common/decideInstructionTypeFragment.js';
import { userHasNoAccountsSelector } from '../../common/selectors.js';
import { sharedAccountsFragment } from '../../common/shared-accounts/shared-accounts.fragment.js';
import { mapSharedAccountsFlow } from '../../common/shared-accounts/map-shared-accounts.js';
import { TRANSFERS_STEPS } from '../../constants.js';
import { getAllDebts } from './get-all-debts.flow.js';
import { calculateComission } from './calculate-comission.flow.js';
import { processBasketFlow, GMBPrepareCertificationFlow } from './form.flows.js';
import { updateSortingFlow } from './update-sorting.flow.js';
import { updateAmountReducer, removeIdReducer, selectMainAccountFlow, toggleTemplateReducer, addSortIndexAndSort } from './basket.helpers.js';
import { resetDebtsAndTemplatesFlow } from './reset-debts-and-templates.flow.js';
import { BILL_NON_CERT, BILL_CERT, CREATE } from '../../../permissions/rights.js';
import { userLogic } from '../../../generic/user/user.logic.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var basketFragment = composeFragments(listFragment({
  listName: 'BPTemplateGroups',
  entityName: 'BPTemplateGroups',
  listQuery: _objectSpread({}, billPaymentGroupsQuery)
}), action('getAllDebts'), action('success'), action('debtLoadError'), action('loadAccounts'), action('setDebitOptions'), action('selectAccount', function (account) {
  return {
    account: account
  };
}), action('switchGroup', function (id) {
  return {
    id: id
  };
}), action('onSortEnd', identity), action('setTotalFee', function (fee) {
  return {
    fee: fee
  };
}), action('toggleTemplate', function (id) {
  return {
    id: id
  };
}), action('updateAmount', function (id, amount) {
  return {
    id: id,
    amount: amount
  };
}), action('setDebts', function (debts) {
  return {
    debts: debts
  };
}), action('payBasket'), action('GMBPrepareCertification'), action('tmpSuccessWithoutCertification'), action('setPaymentTime', function (paymentTime) {
  return {
    paymentTime: paymentTime
  };
}), action('selectMainAccount'), action('resetDebtsAndTemplates'), state(['comission', 'setCommission'], function (_, _ref) {
  var commission = _ref.commission;
  return commission;
}), state(['comission', 'switchGroup'], function () {
  return [];
}), state(['comission', 'deselectAllTemplates'], function () {
  return [];
}), state(['comission', 'reset'], function () {
  return [];
}), state(['debts', 'setDebts'], function (_, _ref2) {
  var debts = _ref2.debts;
  return debts;
}), stateFlag('commissionLoadFailed', ['commissionLoadError'], ['setCommission', 'reset'], false), state(['activeGroupId', 'switchGroup'], function (_, _ref3) {
  var id = _ref3.id;
  return id;
}), state(['activeGroupId', 'reset'], function () {
  return PAYMENT_ALL_GROUP_ID;
}), state(['updatedAmounts', 'switchGroup'], function () {
  return {};
}), state(['updatedAmounts', 'reset'], function () {
  return {};
}), state(['updatedAmounts', 'updateAmount'], updateAmountReducer), state(['updatedAmounts', 'toggleTemplate'], removeIdReducer), state(['updatedAmounts', 'deselectAllTemplates'], function () {
  return {};
}), state(['totalFee', 'setTotalFee'], function (_, _ref4) {
  var fee = _ref4.fee;
  return fee;
}), state(['totalFee', 'switchGroup'], function () {
  return 0;
}), state(['totalFee', 'deselectAllTemplates'], function () {
  return 0;
}), state(['totalFee', 'reset'], function () {
  return 0;
}), state(['templateResult', 'showTemplateResult'], function (_, _ref5) {
  var savedTemplate = _ref5.savedTemplate,
      errorMessage = _ref5.errorMessage;
  return {
    savedTemplate: savedTemplate,
    errorMessage: errorMessage
  };
}), state(['templateResult', 'hideTemplateResult'], function () {
  return null;
}), stateFlag('certifiableWithoutCertification', ['tmpSuccessWithoutCertification'], ['reset', 'backToFirstStep'], false), state(['certifiable', 'certificationSubmited'], function (_, _ref6) {
  var certifiable = _ref6.certifiable;
  return certifiable;
}, false), state(['shouldCertify', 'shouldCertifyInstruction'], function (_, _ref7) {
  var shouldCertify = _ref7.shouldCertify;
  return shouldCertify;
}, false), state(['instructionType', 'setInstructionType'], function (_, _ref8) {
  var value = _ref8.value;
  return value;
}, null), state(['instructionType', 'reset'], function () {
  return null;
}), instructionIdsFragment, state(['paymentTime', 'setPaymentTime'], function (_, _ref9) {
  var paymentTime = _ref9.paymentTime;
  return paymentTime;
}, null), state(['paymentTime', 'reset'], function () {
  return null;
}), state(['tmpError', 'tmpFailed'], function (_, _ref10) {
  var error = _ref10.error;
  return error;
}, null), state(['tmpError', 'reset'], function () {
  return null;
}), state(['tmpError', 'setStep'], function (previous, _ref11) {
  var step = _ref11.step;
  return step === TRANSFERS_STEPS.FORM ? null : previous;
}), state(['tmpError', 'backToFirstStep'], function () {
  return null;
}), stateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm'], false), stateFlag('formInitializing', ['initializeForm'], ['reset', 'setFormInitialized'], false), stateFlag('formInitialized', ['setFormInitialized'], ['initializeForm'], false), stateFlag('accountsBeingMapped', ['loadAccounts'], ['accountsMapped'], true), stateFlag('certifying', ['GMBPrepareCertification', 'certifyWithBalanceValidation', 'verifyingAvailableBalance'], ['failed', 'reset', 'resetForm', 'stop', 'finalizeHasFailed', 'backToFirstStep'], false), stateFlag('submitting', ['calling'], ['tmpSuccess', 'tmpFailed', 'failed', 'checked', 'backToFirstStep', 'debtLoadError', 'tmpSuccessWithoutCertification', 'updateCertificationStatus'], false), stateFlag('processingDebts', ['startProcessingDebts'], ['setDebts', 'reset', 'resetForm'], false), flow('getDebts', [resolveAction('getAllDebts'), takeLatest, getAllDebts]), flow('calculateFee', [resolveAction('toggleTemplate'), takeLatest, calculateComission]), flow('calculateFee2', [resolveAction('updateDebitAccount'), takeLatest, calculateComission]), flow('calculateFee3', [resolveAction('selectAllTemplates'), takeLatest, calculateComission]), flow('calculateFee4', [resolveAction('updateAmount'), takeLatest, calculateComission]), sharedAccountsFragment({
  listName: 'accounts',
  afterQueryHandler: mapSharedAccountsFlow,
  filterCondition: isCardOrCurrentGELAccount,
  visibilityKey: ACCOUNT_VISIBILITY.BILL_PAYMENT_INDEBIT,
  instructionTypes: ['T:billPayment', 'T:billPaymentCert']
}), userHasNoAccountsSelector, flow('updateSorting', [resolveAction('onSortEnd'), takeLatest, updateSortingFlow]), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, processBasketFlow]), flow('GMBPrepareCertification', [resolveAction('GMBPrepareCertification'), takeLatest, GMBPrepareCertificationFlow]), flow('selectMainAccountFlow', [resolveAction('selectMainAccount'), takeLatest, selectMainAccountFlow]), flow('resetABitWhenSwitchContext', [resolveAction('resetDebtsAndTemplates'), takeLatest, resetDebtsAndTemplatesFlow]), decideInstructionTypeFragment({
  instruction: INSTRUCTION_TYPES.billPayment,
  instructionCert: INSTRUCTION_TYPES.billPaymentCert,
  right: BILL_NON_CERT(CREATE),
  rightCert: BILL_CERT(CREATE),
  isBillPayment: true
}), selector('mainAccount', [userLogic.selectors.mainAccount], function (mainAccount) {
  return mainAccount;
}), selector('debtAmounts', ['debts'], function (debts) {
  return mergeAll(Object.keys(debts).map(function (templateId) {
    var debtData = path(['data', 'debtInfo', '0', 'debtData'])(debts[templateId]);

    if (!debtData) {
      return {};
    }

    var amount = debtData.find(function (_ref12) {
      var key = _ref12.key;
      return key === 'CUST_DEBT';
    });
    return _defineProperty({}, templateId, amount ? Number(amount.value) : 0);
  }));
}), selector('defaultAmounts', ['debtAmounts'], function (debts) {
  return mergeAll(Object.keys(debts).map(function (templateId) {
    return _defineProperty({}, templateId, debts[templateId] > 0 ? debts[templateId] : 0);
  }));
}), selector('noOfSelectedTemplates', ['selectedTemplates'], function (selectedTemplates) {
  return selectedTemplates.length;
}), {
  state: {
    selectedTemplates: {
      'actions.toggleTemplate': toggleTemplateReducer,
      'actions.deselectAllTemplates': function actionsDeselectAllTemplates() {
        return [];
      },
      'actions.switchGroup': function actionsSwitchGroup() {
        return [];
      },
      'actions.reset': function actionsReset() {
        return [];
      },
      'actions.selectAllTemplates': [function (_ref15) {
        var activeGroupId = _ref15.activeGroupId,
            groupedTemplates = _ref15.groupedTemplates,
            debtAmounts = _ref15.debtAmounts;
        return {
          groupedTemplates: groupedTemplates,
          activeGroupId: activeGroupId,
          debtAmounts: debtAmounts,
          allTemplates: billPaymentLogic.selectors.bpTemplates
        };
      }, function (_, actionData, _ref16) {
        var groupedTemplates = _ref16.groupedTemplates,
            activeGroupId = _ref16.activeGroupId,
            allTemplates = _ref16.allTemplates,
            debtAmounts = _ref16.debtAmounts;

        if (activeGroupId !== PAYMENT_DUE_GROUP_ID) {
          var templatesList = activeGroupId === PAYMENT_ALL_GROUP_ID ? allTemplates : groupedTemplates && groupedTemplates[activeGroupId];

          if (!templatesList) {
            return [];
          }

          return templatesList.map(function (_ref17) {
            var templateId = _ref17.templateId;
            return templateId;
          });
        }

        if (!allTemplates) {
          return [];
        }

        return allTemplates.filter(function (_ref18) {
          var templateId = _ref18.templateId;
          return debtAmounts[templateId] > 0;
        }).map(function (_ref19) {
          var templateId = _ref19.templateId;
          return templateId;
        });
      }]
    }
  },
  selectors: {
    totalAmount: [function (selectors) {
      return [['updatedAmounts'], selectors.defaultAmounts, selectors.selectedTemplates];
    }, function (updatedAmounts, defaultAmounts, selectedTemplates) {
      return selectedTemplates.reduce(function (sum, templateId) {
        var amount = (updatedAmounts[templateId] >= 0 ? updatedAmounts[templateId] : defaultAmounts[templateId]) || 0;
        return new BigNumber(sum).plus(amount).toNumber();
      }, 0);
    }],
    groupedTemplates: [function (selectors) {
      return [selectors.BPTemplateGroups, billPaymentLogic.selectors.bpTemplates, userLogic.selectors.clientUserSettings];
    }, function (groups, allTemplates, clientUserSettings) {
      if (!allTemplates) {
        allTemplates = [];
      }

      allTemplates = allTemplates.map(function (template) {
        return _objectSpread(_objectSpread({}, template), {}, {
          id: template.templateId
        });
      });
      var groupedTemplates = {};
      var knownGroupIds = (groups || []).map(function (_ref20, groupIndex) {
        var groupId = _ref20.groupId;
        groupedTemplates[groupId] = addSortIndexAndSort(allTemplates, clientUserSettings, groupId, groupIndex);
        return groupId;
      });
      groupedTemplates[TEMPLATE_WITHOUT_GROUP_ID] = addSortIndexAndSort(allTemplates.filter(function (_ref21) {
        var templateGroup = _ref21.templateGroup;
        return !knownGroupIds.includes(templateGroup === null || templateGroup === void 0 ? void 0 : templateGroup.groupId);
      }), clientUserSettings, TEMPLATE_WITHOUT_GROUP_ID, knownGroupIds.length, true);
      return groupedTemplates;
    }],
    areAllTemplatesSelected: [function (selectors) {
      return [['selectedTemplates'], selectors.groupedTemplates, selectors.activeGroupId, selectors.debtAmounts, billPaymentLogic.selectors.bpTemplates];
    }, function (selectedTemplates, groupedTemplates, activeGroupId, debts, allTemplates) {
      if (activeGroupId !== PAYMENT_DUE_GROUP_ID) {
        var templates = activeGroupId === PAYMENT_ALL_GROUP_ID ? allTemplates : groupedTemplates && groupedTemplates[activeGroupId];

        if (!templates) {
          return false;
        }

        return templates.length === selectedTemplates.length;
      }

      var debtTemplatesLength = allTemplates.filter(function (_ref22) {
        var templateId = _ref22.templateId;
        return debts[templateId] > 0;
      }).length;
      return selectedTemplates.length === debtTemplatesLength;
    }]
  }
}, selector('totalAmountToPay', ['updatedAmounts', 'defaultAmounts', billPaymentLogic.selectors.bpTemplates], function (updatedAmounts, defaultAmounts, bpTemplates) {
  return bpTemplates.reduce(function (sum, _ref23) {
    var templateId = _ref23.templateId;
    var amount = (updatedAmounts[templateId] >= 0 ? updatedAmounts[templateId] : defaultAmounts[templateId]) || 0;
    return sum + amount;
  }, 0);
}), selector('enoughFunds', ['debitAccount', 'totalAmount', 'totalFee'], function (debitAccount, totalAmount, totalFee) {
  if (!debitAccount || !debitAccount.value) {
    return true;
  }

  return debitAccount.value.balance >= totalAmount + totalFee;
}), selector('hasBalanceProblem', ['submitting', 'debitAccount', 'totalFee', ['totalAmount']], function (submitting, debitAccount, totalFee, totalAmount) {
  if (debitAccount && debitAccount.value) {
    return submitting && debitAccount.value.balance < Number(totalAmount) + (Number(totalFee) || 0);
  }

  return false;
}), selector('selectedTemplatesCollection', ['selectedTemplates', billPaymentLogic.selectors.bpTemplates, 'comission', 'debts', 'updatedAmounts', 'defaultAmounts', billPaymentLogic.selectors.services], function (selectedTemplates, bpTemplates, commission, debts, updatedAmounts, defaultAmounts, services) {
  return selectedTemplates.filter(function (id) {
    var amount = updatedAmounts[id] >= 0 ? updatedAmounts[id] : defaultAmounts[id];
    return amount > 0;
  }).map(function (id) {
    var template = bpTemplates.find(function (_ref24) {
      var templateId = _ref24.templateId;
      return id === templateId;
    });
    var templateComission = commission.find(function (_ref25) {
      var templateId = _ref25.templateId;
      return id === templateId;
    });
    var debtsData = debts && debts[id] && debts[id].data;
    var serviceCode = [];
    var serviceOriginal = services.find(function (_ref26) {
      var serviceCode = _ref26.serviceCode;
      return serviceCode === template.service.serviceCode;
    });
    enrichDataWithService(serviceCode, null, undefined)(serviceOriginal);
    return _objectSpread(_objectSpread({}, template), {}, {
      debts: debtsData,
      commission: templateComission || {},
      serviceCode: {
        value: serviceCode[0]
      }
    });
  });
}), selector('canPay', ['totalAmount', 'debitAccount', 'enoughFunds', 'selectedTemplatesCollection', 'commissionLoadFailed', 'isBalanceCheckDisabled'], function (totalAmount, debitAccount, enoughFunds, selectedTemplatesCollection, commissionLoadFailed) {
  var isCommisionLoaded = selectedTemplatesCollection.every(function (_ref27) {
    var commission = _ref27.commission;
    return commission && commission.payAmount;
  });
  return totalAmount > 0 && (isCommisionLoaded || commissionLoadFailed) && debitAccount && debitAccount.value;
}), selector('noOfDebtsByGroup', ['defaultAmounts', 'groupedTemplates'], function (defaultAmounts, groupedTemplates) {
  if (!groupedTemplates || !defaultAmounts) {
    return {};
  }

  var debtByGroup = {};
  Object.keys(groupedTemplates).forEach(function (groupId) {
    debtByGroup[groupId] = groupedTemplates[groupId].reduce(function (sum, template) {
      return defaultAmounts[template.id] > 0 ? sum + 1 : sum;
    }, 0);
  });
  return debtByGroup;
}), selector('allDebtsCount', ['defaultAmounts'], function (defaultAmounts) {
  return Object.values(defaultAmounts).reduce(function (sum, debt) {
    return debt > 0 ? sum + 1 : sum;
  }, 0);
}), selector('multiServicesProviders', [billPaymentLogic.selectors.providers, billPaymentLogic.selectors.services], function (providers, services) {
  var multiServicesProviders = {};

  if (!Array.isArray(providers) || !Array.isArray(services)) {
    return multiServicesProviders;
  }

  providers.forEach(function (provider) {
    if (!provider || !provider.providerCode) {
      return;
    }

    var count = 0;
    var isMultiple = services.some(function (service) {
      var serviceProviderCode = path(['provider', 'providerCode'], service);

      if (serviceProviderCode === provider.providerCode) {
        ++count;
      }

      return count > 1;
    });
    multiServicesProviders[provider.providerCode] = isMultiple;
  });
  return multiServicesProviders;
}), extendInitialState(null, {
  activeGroupId: PAYMENT_ALL_GROUP_ID,
  selectedTemplates: [],
  updatedAmounts: {},
  areAllTemplatesSelected: false,
  totalAmount: 0,
  totalFee: null,
  debts: {},
  comission: [],
  account: null,
  showCertification: false
}));

export { basketFragment };
