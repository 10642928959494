import { resolveLogic, composeFragments, pathFragment } from 'core-ingredients';
import { signingProceduresSignFragment } from '../common/signingProceduresSignFragment.js';
import { wizardFragment } from '../../generic/wizard.fragment.js';
import { TRANSFERS_STEPS } from '../../payment-centre/constants.js';
import { checkInstructionsBalanceErrorsFragment } from '../../payment-centre/common/checkBalanceErrorsFragment.js';
import { conditionFields } from '../../payment-centre/common/condition-fields.fragment.js';
import { signingProceduresDetailFragment } from './signing-procedures-detail.fragment.js';
import { debtAndCommissionFragment } from '../../payment-centre/common/debt-and-commision/debt-and-commission-fragment.js';

var signingProceduresDetailLogicAPI = resolveLogic(composeFragments(pathFragment('signingProcedures', 'detail'), wizardFragment(TRANSFERS_STEPS), conditionFields, debtAndCommissionFragment, signingProceduresSignFragment, signingProceduresDetailFragment, checkInstructionsBalanceErrorsFragment));
var signingProceduresDetailLogic = signingProceduresDetailLogicAPI.logic;
var SigningProceduresDetailReducer = signingProceduresDetailLogicAPI.reducer;
var SigningProceduresDetailSaga = signingProceduresDetailLogicAPI.saga;

export { SigningProceduresDetailReducer, SigningProceduresDetailSaga, signingProceduresDetailLogic, signingProceduresDetailLogicAPI };
