import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { hasRightSelector, allRightsSelector } from 'vtb-logic'
import { translationsSelector } from 'core-configuration'
import { PageWithLoader } from 'vtb-ui'

import { NoRightsRoute } from './no-rights-route'
import AuthenticatedRoute from './AuthenticatedRoute'

export const WithAuthorization = (right, noRightsRoute = NoRightsRoute) => (Content) => {
  class WithAuthorizationContent extends PureComponent {
    render() {
      const { authorized, allRights, ...props } = this.props
      if (!allRights || (allRights && allRights.length === 0)) {
        return <PageWithLoader loading />
      }
      const altContent = props.altContent ? props.altContent : <AuthenticatedRoute component={noRightsRoute} />
      return authorized ? <Content {...props} /> : altContent
    }
  }

  return connect(createStructuredSelector({
    authorized: hasRightSelector(right),
    allRights: allRightsSelector,
    translations: translationsSelector('app'),
  }))(WithAuthorizationContent)
}

export const AuthorizedRoute = ({ right, noRightsRoute, ...props }) => {
  if (right) {
    const NewRoute = WithAuthorization(right, noRightsRoute)(AuthenticatedRoute)
    return <NewRoute {...props} />
  }
  return <AuthenticatedRoute {...props} />
}
