import { OPERATION_TYPE } from '../../constants/payments-constants.js';
import { resolveExchangeRate } from './exchange-rate.js';

var calculateExchangeRateError = function calculateExchangeRateError(_ref) {
  var row = _ref.row,
      instructionRates = _ref.instructionRates;
  var id = row.id,
      _row$item = row.item,
      operationType = _row$item.operationType,
      toAmount = _row$item.toAmount,
      fromAmount = _row$item.fromAmount,
      rateOriginal = _row$item.rateOriginal;
  var exchangeRate = instructionRates[id];

  if (!exchangeRate) {
    return false;
  }

  var rate = resolveExchangeRate(exchangeRate);
  var instructionAmount = operationType === OPERATION_TYPE.SELL ? toAmount : fromAmount;
  var originalAmount = String(instructionAmount);
  var originalPrecision = originalAmount.includes('.') ? originalAmount.length - 1 : originalAmount.length;
  var newAmount = Number.parseFloat(exchangeRate.convertedAmount).toPrecision(originalPrecision).toString();
  return rateOriginal && rateOriginal.toFixed(5) < parseFloat(rate.value).toFixed(5) || originalAmount < newAmount;
};
var hasExchangeRateChanged = function hasExchangeRateChanged(_ref2) {
  var _ref2$row = _ref2.row,
      id = _ref2$row.id,
      _ref2$row$item = _ref2$row.item,
      operationType = _ref2$row$item.operationType,
      toAmount = _ref2$row$item.toAmount,
      fromAmount = _ref2$row$item.fromAmount,
      rateOriginal = _ref2$row$item.rateOriginal,
      instructionRates = _ref2.instructionRates;
  var exchangeRate = instructionRates[id];

  if (!exchangeRate) {
    return false;
  }

  var rate = resolveExchangeRate(exchangeRate);
  var instructionAmount = operationType === OPERATION_TYPE.SELL ? toAmount : fromAmount;
  var originalAmount = String(instructionAmount);
  var originalPrecision = originalAmount.includes('.') ? originalAmount.length - 1 : originalAmount.length;
  var newAmount = Number.parseFloat(exchangeRate.convertedAmount).toPrecision(originalPrecision).toString();
  return String(rateOriginal) !== rate.value || originalAmount !== newAmount;
};

export { calculateExchangeRateError, hasExchangeRateChanged };
