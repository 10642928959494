import { takeLatest } from 'redux-saga/effects';
import { logic2, composeFragments, pathFragment, action, state, stateFlag, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { TRANSFERS_STEPS } from '../../constants.js';
import { wizardFragment } from '../../../generic/wizard.fragment.js';
import { processBatchConceptItem } from './batch-new-concept-item.flow.js';

var batchNewConceptItemLogic = logic2(composeFragments(pathFragment('transfers', 'batchNewConceptItem'), wizardFragment(TRANSFERS_STEPS), action('initializeForm'), state(['conceptType', 'openModal'], function (_, _ref) {
  var conceptType = _ref.conceptType;
  return conceptType;
}), state(['conceptId', 'openModal'], function (_, _ref2) {
  var conceptId = _ref2.conceptId;
  return conceptId;
}), state(['batchConceptItem', 'setBatchConceptItem'], function (_, _ref3) {
  var batchConceptItem = _ref3.batchConceptItem;
  return batchConceptItem;
}, null), state(['batchConceptItem', 'reset'], function () {
  return null;
}), state(['batchConceptItem', 'closeModal'], function () {
  return null;
}), state(['submitedBatchConceptItem', 'submitted'], function (_, batchConceptItem) {
  return batchConceptItem;
}, null), state(['submitedBatchConceptItem', 'submit'], function () {
  return null;
}), state(['submitedBatchConceptItem', 'openModal'], function () {
  return null;
}), stateFlag('isModelOpen', ['openModal'], ['closeModal'], false), stateFlag('submitting', ['calling'], ['tmpFailed', 'reset', 'submitted'], false), stateFlag('submitSuccess', ['submitted'], ['tmpFailed', 'reset', 'submit'], false), stateFlag('isSubmited', ['submit'], ['tmpFailed', 'reset', 'submitted', 'closeModal'], false), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, processBatchConceptItem])));
var batchNewConceptItemReducer = batchNewConceptItemLogic.reducer;
var batchNewConceptItemSaga = sagaifyLogic2(batchNewConceptItemLogic);

export { batchNewConceptItemLogic, batchNewConceptItemReducer, batchNewConceptItemSaga };
