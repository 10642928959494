var productIconsQuery = function productIconsQuery() {
  var query = {
    ProductIcons: ['isDeleted', 'icon', 'id', 'status', {
      product: ['prodTypeGroupID']
    }],
    where: {
      'product.entityUniqueId': {
        inlist: ['1', '2', '4']
      },
      status: 'true',
      isDeleted: 'false'
    },
    limit: 50
  };
  return query;
};

export { productIconsQuery };
