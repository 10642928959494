import { Menu as MenuUI } from 'core-ui'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { withToggle } from 'vtb-ui'
import { ROUTES } from 'vtb-utils'

import { MenuActions } from './menu-actions'
import { menuConfig } from './menu-config'
import { MenuItemView } from './menu-item-view'
import { MenuView } from './menu-view'

class Component extends PureComponent<any> {
  linkHandler = (path) => {
    const { onHamburgerClick, history } = this.props
    onHamburgerClick()
    history.push(path)
  }

  render() {
    return (
      <MenuUI
        baseUrl={ROUTES.BASE_URL}
        linkHandler={this.linkHandler}
        items={menuConfig}
        RenderItemComponent={MenuItemView}
        RenderActionsComponent={MenuActions}
        RenderComponent={MenuView}
        {...this.props}
      />
    )
  }
}

const MenuWithHamburgerClick = withToggle('drawerOn', 'onHamburgerClick')(Component)

export const Menu = withRouter(MenuWithHamburgerClick)
