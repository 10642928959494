import { getDateBefore } from 'vtb-utils';

var getAccountsQuery = function getAccountsQuery(ids) {
  var q = {
    Accounts: ['cbsID', 'friendlyName', 'accountName', 'ibanAccount', {
      balance: ['availableBalance', {
        currency: ['currencyID']
      }]
    }, {
      currency: ['currencyID']
    }, {
      accountType: ['accTypeId', 'status', 'defaultAccountIcon', 'text']
    }, {
      accountSubType: ['text', 'externalId', 'id']
    }],
    where: {
      'accountType.entityUniqueId': '200',
      // card accounts
      'status.entityUniqueId': {
        inlist: ['Open', 'CreditOnly', 'BudgetOnly', 'Frozen']
      }
    },
    limit: 1000
  };

  if (Array.isArray(ids) && ids.length > 0) {
    q.where['ibanAccount'] = {
      inlist: ids
    };
  }

  return q;
};

var getActiveAndExpired = function getActiveAndExpired(maxExpirationDays) {
  return {
    search: [{
      'cardStatus.entityUniqueId': {
        inlist: ['30', 'Active']
      }
    }, {
      expired: [{
        delimiter: 'AND'
      }, {
        'cardStatus.entityUniqueId': {
          inlist: ['90', 'Expired']
        },
        'validTo': {
          gte: getDateBefore(maxExpirationDays)
        }
      }]
    }]
  };
};

var getAllowedCardsFilter = function getAllowedCardsFilter(maxExpirationDays) {
  var filter = getActiveAndExpired(maxExpirationDays);
  filter.search.push({
    "new": [{
      delimiter: 'AND'
    }, {
      'cardStatus.entityUniqueId': {
        inlist: ['0', 'New']
      },
      'cardState.entityUniqueId': {
        inlist: ['21', '26', '29', 'DeliveredToBranch', 'InDeliveryBranch', 'TransferredToCourier']
      }
    }]
  });
  return filter;
};
var cardsQuery = function cardsQuery(maxExpirationDays) {
  return {
    Cards: ['cbsId', 'cmsCardId', 'friendlyName', 'cardHolder', 'blocks', 'validTo', 'openDate', 'cardNumber', 'description', 'customCoverFileName', {
      cardHolderCustomer: ['externalClientID']
    }, {
      accountCustomer: ['externalClientID']
    }, {
      riskLevel: ['allowedContactless', 'riskLevel', 'limitInternet', 'limitCurrency', 'limitContactlessPayments', 'limitOverall', 'text']
    }, 'isBlocked', 'isMainCard', {
      productType: ['text', 'linkedCardPriority', 'applePayAllowed', {
        productIcon: ['icon']
      }]
    }, {
      cardDesign: ['picLarge']
    }, {
      cardState: ['cardStateId', 'text']
    }, {
      cardStatus: ['cardStatusId', 'cardStatusEnum', 'text']
    }, {
      ibanAccount: ['iban']
    }],
    where: getAllowedCardsFilter(maxExpirationDays),
    limit: 100
  };
};

export { cardsQuery, getAccountsQuery, getAllowedCardsFilter };
