import { composeFragments, validationRule, required, minLengthValidation, maxLengthValidation } from 'core-ingredients';
import { fieldsFragment, fieldFragment } from 'core-recipes';
import { USERNAME_MIN_LENGTH, USERNAME_MAX_LENGTH } from '../../auth/username-validation.js';
import { usernameAllowedChars } from '../../utils/validation-patterns.js';
import { charset } from '../../utils/validators.js';

/* eslint-disable no-useless-escape */
var changeUsernameFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'username',
  rules: [validationRule(required), validationRule(minLengthValidation('minLength', 'security.error.usernameMinLength')(USERNAME_MIN_LENGTH)), validationRule(maxLengthValidation('maxLength', 'security.error.usernameMaxLength')(USERNAME_MAX_LENGTH)), validationRule(charset(usernameAllowedChars))]
}), fieldFragment({
  name: 'password',
  rules: [validationRule(required)]
})));

export { changeUsernameFieldsFragment };
