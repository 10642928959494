import { format } from 'date-fns';

var mapCarPaymentInstructionItem = function mapCarPaymentInstructionItem(values) {
  var debitAccount = values.debitAccount,
      serviceCode = values.serviceCode,
      customerCode = values.customerCode,
      amount = values.amount,
      feeAmount = values.feeAmount,
      clientPaymentId = values.clientPaymentId,
      additionalInfo = values.additionalInfo,
      channelId = values.channelId,
      customerData = values.customerData,
      carTemplate = values.carTemplate;
  var payLoad = {
    debitAccountIBAN: debitAccount.iban,
    debitAccountId: debitAccount.id,
    serviceCode: serviceCode,
    customerCode: customerCode,
    ccy: debitAccount.currency,
    amount: amount,
    feeAmount: feeAmount,
    date: format(new Date(), 'YYYY-MM-DD'),
    paymentTime: new Date(),
    clientPaymentId: clientPaymentId,
    channel: channelId,
    additionalInfo: additionalInfo,
    customerData: customerData,
    carTemplate: carTemplate
  };
  return payLoad;
};

export { mapCarPaymentInstructionItem };
