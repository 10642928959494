import { tokenSelector } from 'core-auth-client'
import { legacyBodyInterceptor } from 'vtb-logic'
import {
  createEntry,
  languageInterceptor,
  httpInterceptor,
  apiInterceptor,
  tokenInterceptor,
} from 'core-supply'

const interceptors = [
  tokenInterceptor(() => 'MCH', tokenSelector),
  legacyBodyInterceptor,
  apiInterceptor,
  languageInterceptor(() => 'en'),
  httpInterceptor({ fetch, base: '/vtb-mch/' }),
]

export default createEntry(...interceptors)
