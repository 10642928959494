import { isCountryIBAN } from 'core-domain';
import { or, isNil, isEmpty, all, propEq, anyPass, has, is } from 'ramda';
import { LANGUAGES } from '../constants/languages.js';

var regexCharset = /^[- a-zA-Z0-9\/-/?:().,'+)\u10d0\u10d1\u10d2\u10d3\u10d4\u10d5\u10d6\u10d7\u10d8\u10d9\u10da\u10db\u10dc\u10dd\u10de\u10df\u10e0\u10e1\u10e2\u10e3\u10e4\u10e5\u10e6\u10e7\u10e8\u10e9\u10ea\u10eb\u10ec\u10ed\u10ee\u10ef\u10f0]+$/; // eslint-disable-next-line no-useless-escape, max-len

var regexCharsetProductName = /^[- a-zA-Z0-9\/-/?:().,'+)\u10d0\u10d1\u10d2\u10d3\u10d4\u10d5\u10d6\u10d7\u10d8\u10d9\u10da\u10db\u10dc\u10dd\u10de\u10df\u10e0\u10e1\u10e2\u10e3\u10e4\u10e5\u10e6\u10e7\u10e8\u10e9\u10ea\u10eb\u10ec\u10ed\u10ee\u10ef\u10f0]*$/;
/**
 * Check if the passed property is undefined|null or not filled
 * @param property
 * @return {boolean} True if property is undefined|null or []|''|{}
 */

var isPropEmpty = function isPropEmpty(property) {
  return or(isNil(property), isEmpty(property));
};
/**
 * Check if the passed property is defined or filled
 * @param property
 * @return {boolean} True if property is not undefined|null or []|''|{}
 */

var isPropNotEmpty = function isPropNotEmpty(property) {
  return !isPropEmpty(property);
};
/**
 * Check if the passed property is defined or filled and not zero
 * @param property
 * @return {boolean} True if property is not undefined|null or []|''|{}
 */

var isPropNotEmptyNumber = function isPropNotEmptyNumber(property) {
  return !isPropEmpty(property) && property !== 0;
};
/**
 * Check if the all passed properties of fields `valid` is true
 * @param fields
 * @return {boolean} True if `valid` is true
 */

var areFieldsValid = function areFieldsValid(fields) {
  return all(propEq('valid', true))(fields);
};
/**
 * Check if the some passed properties of fields `valid` is false
 * @param fields
 * @return {boolean} True if one of `valid` is false
 */

var areFieldsInvalid = function areFieldsInvalid(fields) {
  return !areFieldsValid(fields);
};
var matchInsideBank = /^.{4}VT/;
var isIntraBankInGE = function isIntraBankInGE(iban) {
  return isCountryIBAN(iban, 'GE') && matchInsideBank.test(iban);
};
var isAccountNumber = function isAccountNumber(value) {
  return value && !isNaN(value.slice(0, 2));
};
var checkIFForeignPayment = function checkIFForeignPayment(_ref) {
  var beneficiaryAccount = _ref.beneficiaryAccount,
      beneficiaryCountry = _ref.beneficiaryCountry;

  if (isAccountNumber(beneficiaryAccount)) {
    return beneficiaryCountry && beneficiaryCountry.value !== 'GE';
  }

  return beneficiaryAccount && !isCountryIBAN(beneficiaryAccount, 'GE');
};
var isLanguageText = anyPass([has(LANGUAGES.EN), has(LANGUAGES.KA), has(LANGUAGES.RU)]);
var isMultiLanguageStringObject = function isMultiLanguageStringObject(key) {
  if (!is(Object, key)) {
    return false;
  }

  return isLanguageText(key);
};

export { areFieldsInvalid, areFieldsValid, checkIFForeignPayment, isAccountNumber, isIntraBankInGE, isMultiLanguageStringObject, isPropEmpty, isPropNotEmpty, isPropNotEmptyNumber, regexCharset, regexCharsetProductName };
