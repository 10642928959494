import { composeFragments, pathFragment, resolveLogic } from 'core-ingredients';
import { createRoleFieldsFragment } from './create-role.fields.js';

var createRoleFormLogicSchema = composeFragments(pathFragment('corporateUsers', 'createRoleForm'), createRoleFieldsFragment);
var createRoleFormLogicAPI = resolveLogic(createRoleFormLogicSchema);
var createRoleFormLogic = createRoleFormLogicAPI.logic;
var createRoleFormReducer = createRoleFormLogicAPI.reducer;
var createRoleFormSaga = createRoleFormLogicAPI.saga;

export { createRoleFormLogic, createRoleFormReducer, createRoleFormSaga };
