import { withDefaultProps, ItemsRow, ItemsList } from 'core-ui';
import { ItemsListView } from './views/items-list-view.js';
import { ItemsRowView } from './views/items-row-view.js';
import { ItemListBasic } from './item-basic.js';

var BasicItemsList = withDefaultProps({
  RenderComponent: ItemsListView,
  components: {
    ItemsRowComponent: ItemsRow,
    itemsRow: {
      RenderComponent: ItemsRowView
    },
    Item: ItemListBasic
  }
}, ItemsList);

export { BasicItemsList };
