import { logic2, composeFragments, pathFragment, action, stateFlag, state, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { takeLeading, takeLatest } from 'redux-saga/effects';
import { remove } from 'ramda';
import { certify } from 'core-recipes';
import { toggleDevice, getDevices, removeDevice, checkIfDeviceIsBlockedFlow } from './my-devices-flows.js';

var withCertificationFragment = certify.withCertificationFragment;
var myDevicesLogic = logic2(composeFragments(pathFragment('security', 'myDevices'), withCertificationFragment({}), action('loadList'), action('setDevices', function (devices) {
  return {
    devices: devices
  };
}), action('loadListDevices', function (listDevices) {
  return {
    listDevices: listDevices
  };
}), action('failed'), action('reset'), action('startProcess', function (resultType) {
  return {
    resultType: resultType
  };
}), action('processing', function () {
  var willLogout = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    willLogout: willLogout
  };
}), action('onStatusToggle', function (name) {
  return {
    name: name
  };
}), action('onRemove', function (name) {
  return {
    name: name
  };
}), action('onRemoveSuccess', function (name) {
  return {
    name: name
  };
}), action('checkIfDeviceIsBlocked'), stateFlag('process', ['startProcess', 'processing'], ['failed', 'openVerify', 'reset', 'endProcess'], false), stateFlag('loading', ['refresh'], ['loaded', 'failed', 'reset', 'endProcess', 'setDevices'], true), stateFlag('gmbSMS', ['openVerify'], ['closeVerify', 'processing', 'endProcess', 'reset']), stateFlag('isRemoveConfirmOpen', ['onStartRemoval'], ['onRemove', 'closeRemoveConfirmModal']), state(['certifiable', 'certificationSubmited'], function (_, _ref) {
  var certifiable = _ref.certifiable;
  return certifiable;
}, false), state(['devices', 'resetList'], function () {
  return [];
}, []), state(['devices', 'setDevices'], function (_, _ref2) {
  var devices = _ref2.devices;
  return devices;
}), state(['currentDevice', 'setCurrentDevice'], function (_, _ref3) {
  var currentDevice = _ref3.currentDevice;
  return currentDevice;
}), state(['deviceToRemove', 'onStartRemoval'], function (_, _ref4) {
  var device = _ref4.device;
  return device;
}, null), state(['deviceToRemove', 'closeRemoveConfirmModal'], function () {
  return null;
}), state(['deviceToRemove', 'closeRemoveConfirmModal'], function () {
  return null;
}), state(['resultType', 'startProcess'], function (_, _ref5) {
  var resultType = _ref5.resultType;
  return resultType;
}), stateFlag('showResult', ['onRemoveSuccess', 'processing', 'failed'], ['closeResult', 'reset'], false), state(['listDevices', 'loadListDevices'], function (_, _ref6) {
  var listDevices = _ref6.listDevices;
  return listDevices;
}, []), stateFlag('success', ['processing', 'endProcess'], ['failed'], false), state(['willLogout', 'processing'], function (_, _ref7) {
  var willLogout = _ref7.willLogout;
  return willLogout;
}, false), state(['willLogout', 'startProcess'], function () {
  return false;
}), state(['listDevices', 'onRemoveSuccess'], function (listDevices, _ref8) {
  var name = _ref8.name;
  var deviceIndex = listDevices.findIndex(function (device) {
    return device.name === name;
  });

  if (deviceIndex < 0) {
    return listDevices;
  }

  return remove(deviceIndex, 1, listDevices);
}), flow('toggleDevice', [resolveAction('onStatusToggle'), takeLeading, toggleDevice]), flow('loadList', [resolveAction('loadList'), takeLatest, getDevices]), flow('removeDevice', [resolveAction('onRemove'), takeLatest, removeDevice]), flow('checkIfDeviceIsBlocked', [resolveAction('checkIfDeviceIsBlocked'), takeLatest, checkIfDeviceIsBlockedFlow])));
var myDevicesReducer = myDevicesLogic.reducer;
var myDevicesSaga = sagaifyLogic2(myDevicesLogic);

export { myDevicesLogic, myDevicesReducer, myDevicesSaga };
