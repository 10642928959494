import { composeFragments, pathFragment, state, resolveLogic } from 'core-ingredients';
import { usersFilterFieldsFragment } from './users-filter.fields.js';

var usersFilterLogicSchema = composeFragments(pathFragment('corporateUsers', 'usersFilter'), usersFilterFieldsFragment, state(['chosenNames', 'setChosenNames'], function (_, _ref) {
  var selectedUsers = _ref.selectedUsers;
  return selectedUsers;
}, []), state(['chosenRoles', 'setChosenRoles'], function (_, _ref2) {
  var selectedRoles = _ref2.selectedRoles;
  return selectedRoles;
}, []), state(['chosenNames', 'removeNames'], function () {
  return [];
}), state(['chosenRoles', 'removeRoles'], function () {
  return [];
}));
var usersFilterLogicAPI = resolveLogic(usersFilterLogicSchema);
var usersFilterLogic = usersFilterLogicAPI.logic;
var usersFilterReducer = usersFilterLogicAPI.reducer;
var usersFilterSaga = usersFilterLogicAPI.saga;

export { usersFilterLogic, usersFilterReducer, usersFilterSaga };
