import { takeLatest } from 'redux-saga/effects';
import { updateProductNameFlow, updateFieldFlow } from './product-name.flows.js';

var productNameFragment = function productNameFragment() {
  return {
    actions: {
      initializing: function initializing() {
        return {};
      },
      initialized: function initialized() {
        return {};
      },
      updateNameError: function updateNameError() {
        return {};
      },
      updateSaved: function updateSaved(saved) {
        return {
          saved: saved
        };
      },
      fieldUpdated: function fieldUpdated(name) {
        return {
          name: name
        };
      },
      setValidationErrors: function setValidationErrors(errors) {
        return {
          errors: errors
        };
      },
      stop: function stop() {},
      update: function update(typeItem, cbsID, friendlyName) {
        return {
          typeItem: typeItem,
          cbsID: cbsID,
          friendlyName: friendlyName
        };
      },
      submit: function submit(typeItem, cbsID, friendlyName, instructionType, onChange, accountIban) {
        return {
          typeItem: typeItem,
          cbsID: cbsID,
          friendlyName: friendlyName,
          instructionType: instructionType,
          onChange: onChange,
          accountIban: accountIban
        };
      }
    },
    state: {
      initializing: {
        'actions.initializing': function actionsInitializing() {
          return true;
        },
        'actions.initialized': function actionsInitialized() {
          return false;
        }
      },
      names: {
        'actions.fieldUpdated': function actionsFieldUpdated(_, _ref) {
          var name = _ref.name;
          return name;
        }
      },
      validationErrors: {
        'actions.setValidationErrors': function actionsSetValidationErrors(_, _ref2) {
          var errors = _ref2.errors;
          return errors;
        },
        'actions.stop': function actionsStop() {
          return null;
        }
      }
    },
    selectors: {
      initializing: [['initializing']],
      names: [['names']]
    },
    flows: {
      submit: ['actions.submit', takeLatest, updateProductNameFlow],
      update: ['actions.update', takeLatest, updateFieldFlow]
    },
    initialState: {
      initializing: false,
      names: {}
    }
  };
};

export { productNameFragment };
