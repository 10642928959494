var hasAfterSlash = /\/(.*)/;
var hasWhiteSpace = /\s/g;
var hasDash = /-/g;
var hasSlash = /\//g;
var cleanInput = function cleanInput(value) {
  return value.replace(hasWhiteSpace, '');
};
var cleanInputDash = function cleanInputDash(value) {
  return value.replace(hasDash, '');
};
var cleanInputSlash = function cleanInputSlash(value) {
  return value.replace(hasSlash, '');
};
var parseBankCode = function parseBankCode(value) {
  var match = String(value || '').match(hasAfterSlash);
  return match ? match[1] : null;
};
var hasWhiteSpaces = function hasWhiteSpaces(value) {
  return hasWhiteSpace.test(String(value || ''));
};
var hasDashes = function hasDashes(value) {
  return hasDash.test(String(value || ''));
};
var hasSlashes = function hasSlashes(value) {
  return hasSlash.test(String(value || ''));
};

export { cleanInput, cleanInputDash, cleanInputSlash, hasDashes, hasSlashes, hasWhiteSpaces, parseBankCode };
