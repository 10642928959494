import { takeLatest } from 'redux-saga/effects';
import { identity } from 'ramda';
import { initialDatePickerLastWeek } from 'vtb-utils';
import { loadAccountsAndDefaultStatement } from './flows/statements-filter.flows.js';
import { loadStatementsFlow } from './flows/statements.flows.js';

var statementsFragment = function statementsFragment() {
  return {
    actions: {
      loadStatements: function loadStatements() {
        return {};
      },
      statementsDataLoaded: function statementsDataLoaded(list) {
        return {
          list: list
        };
      },
      loadedStatement: function loadedStatement(statement) {
        return {
          statement: statement
        };
      },
      loadDataForFilter: function loadDataForFilter(account) {
        return {
          account: account
        };
      },
      loadedDataForFilter: function loadedDataForFilter(items) {
        return {
          items: items
        };
      },
      updateFilter: function updateFilter(account) {
        return {
          account: account
        };
      },
      additionalInfoToggle: function additionalInfoToggle() {
        return {};
      },
      equivalentToggle: function equivalentToggle() {
        return {};
      },
      updateDateInput: function updateDateInput(datePickerValue) {
        return {
          datePickerValue: datePickerValue
        };
      },
      accountsLoadingDone: function accountsLoadingDone() {
        return {};
      },
      setCanLoadMore: identity,
      reset: identity
    },
    state: {
      statement: {
        'actions.loadedStatement': function actionsLoadedStatement(_, _ref) {
          var statement = _ref.statement;
          return statement;
        },
        'actions.reset': function actionsReset() {
          return null;
        }
      },
      statements: {
        'actions.statementsDataLoaded': function actionsStatementsDataLoaded(_, _ref2) {
          var list = _ref2.list;
          return list.movements;
        }
      },
      startPointer: {
        'actions.loadedStatement': function actionsLoadedStatement(_, _ref3) {
          var statement = _ref3.statement;
          return statement && statement.startPointer || null;
        },
        'actions.statementsDataLoaded': function actionsStatementsDataLoaded(_, _ref4) {
          var list = _ref4.list;
          return list && list.startPointer || null;
        }
      },
      endPointer: {
        'actions.loadedStatement': function actionsLoadedStatement(_, _ref5) {
          var statement = _ref5.statement;
          return statement && statement.endPointer || null;
        },
        'actions.statementsDataLoaded': function actionsStatementsDataLoaded(_, _ref6) {
          var list = _ref6.list;
          return list && list.endPointer || null;
        }
      },
      filterData: {
        'actions.loadedDataForFilter': function actionsLoadedDataForFilter(_, _ref7) {
          var items = _ref7.items;
          return items;
        }
      },
      additionalInfoChecked: {
        'actions.additionalInfoToggle': function actionsAdditionalInfoToggle(additionalInfoChecked) {
          return !additionalInfoChecked;
        },
        'actions.reset': function actionsReset() {
          return true;
        }
      },
      equivalentChecked: {
        'actions.equivalentToggle': function actionsEquivalentToggle(equivalentChecked) {
          return !equivalentChecked;
        },
        'actions.reset': function actionsReset() {
          return false;
        }
      },
      datePickerValue: {
        'actions.updateDateInput': function actionsUpdateDateInput(_, _ref8) {
          var datePickerValue = _ref8.datePickerValue;
          return datePickerValue;
        },
        'actions.reset': function actionsReset() {
          return initialDatePickerLastWeek;
        }
      },
      selectedAccount: {
        'actions.updateFilter': function actionsUpdateFilter(_, _ref9) {
          var account = _ref9.account;
          return account;
        },
        'actions.reset': function actionsReset() {
          return null;
        }
      },
      accountsLoaded: {
        'actions.accountsLoadingDone': function actionsAccountsLoadingDone() {
          return true;
        },
        'actions.loadDataForFilter': function actionsLoadDataForFilter() {
          return false;
        }
      },
      canLoadMore: {
        'actions.setCanLoadMore': function actionsSetCanLoadMore(_, _ref10) {
          var canLoadMore = _ref10.canLoadMore;
          return canLoadMore;
        }
      }
    },
    selectors: {
      startPointer: [['startPointer']],
      endPointer: [['endPointer']],
      accountsLoaded: [['accountsLoaded']],
      statement: [['statement']],
      statements: [['statements']],
      filterData: [['filterData']],
      datePickerValue: [['datePickerValue']],
      additionalInfoChecked: [['additionalInfoChecked']],
      equivalentChecked: [['equivalentChecked']],
      canLoadMore: [['canLoadMore']]
    },
    flows: {
      equivalentToggle: ['actions.equivalentToggle', takeLatest, loadStatementsFlow],
      loadStatements: ['actions.loadStatements', takeLatest, loadStatementsFlow],
      loadDataForFilter: ['actions.loadDataForFilter', takeLatest, loadAccountsAndDefaultStatement],
      updateFilter: ['actions.updateFilter', takeLatest, loadStatementsFlow]
    },
    initialState: {
      accountsLoaded: false,
      startPointer: null,
      endPointer: null,
      selectedAccount: null,
      statement: null,
      statements: [],
      filterData: [],
      additionalInfoChecked: true,
      equivalentChecked: false,
      datePickerValue: initialDatePickerLastWeek
    }
  };
};

export { statementsFragment };
