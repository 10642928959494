import { takeLatest } from 'redux-saga/effects';
import { loadSavingDetailFlow } from './savings.flows.js';

var savingsDetailFragment = function savingsDetailFragment() {
  return {
    actions: {
      loadDetail: function loadDetail(id) {
        return {
          id: id
        };
      },
      detailLoaded: function detailLoaded(detail) {
        return {
          detail: detail
        };
      }
    },
    state: {
      detail: {
        'actions.loadDetail': function actionsLoadDetail() {
          return null;
        },
        'actions.detailLoaded': function actionsDetailLoaded(_, _ref) {
          var detail = _ref.detail;
          return detail;
        }
      }
    },
    selectors: {
      detail: [['detail']]
    },
    flows: {
      loadDetail: ['actions.loadDetail', takeLatest, loadSavingDetailFlow]
    },
    initialState: {
      detail: null
    }
  };
};

export { savingsDetailFragment };
