import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { isSoTreasury, isSoOwn, isInExpirationPeriod, STANDING_ORDER_ENDLESS_DATE, getFormatedFrequency, STANDING_ORDER_TYPES, memoize, STANDING_ORDER_PERCENT_TYPE, isSoTTSE } from 'vtb-utils';
import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en';
import { getFirstCreditAccount, getFirstDebitAccount, getSoType } from './getSoType.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getDetails = function getDetails(type, firstCreditAccount) {
  if (!firstCreditAccount) {
    return '';
  }

  if (type && type.id === STANDING_ORDER_TYPES.TRESAURY) {
    return firstCreditAccount.beneficiaryAccountNumber;
  }

  return firstCreditAccount.purpose;
};

var getName = function getName(standingOrder, creditAccount) {
  if (!standingOrder) {
    return '';
  }

  if (standingOrder.friendlyName) {
    return standingOrder.friendlyName;
  }

  if (!creditAccount) {
    return '';
  }

  if (standingOrder.type && standingOrder.type.id === STANDING_ORDER_TYPES.OWN) {
    return creditAccount && creditAccount.accountName || '';
  }

  if (standingOrder.type && (standingOrder.type.id === STANDING_ORDER_TYPES.FCY || standingOrder.type.id === STANDING_ORDER_TYPES.INTRA || standingOrder.type.id === STANDING_ORDER_TYPES.LCY)) {
    return creditAccount && creditAccount.beneficiaryName || '';
  }

  if (standingOrder.type && standingOrder.type.id === STANDING_ORDER_TYPES.TRESAURY) {
    return creditAccount && creditAccount.beneficiaryAccountNumber || '';
  }

  return '';
};

var isLcy = function isLcy(type) {
  return type && type.id === STANDING_ORDER_TYPES.LCY;
};

var isFcy = function isFcy(type) {
  return type && type.id === STANDING_ORDER_TYPES.FCY;
};

var isIntra = function isIntra(type) {
  return type && type.id === STANDING_ORDER_TYPES.INTRA;
};

var getTTSeDetails = function getTTSeDetails(isTTSE, type) {
  if (!isTTSE) {
    return {
      isLcy: false,
      isFcy: false,
      isIntra: false
    };
  }

  return {
    isLcy: isLcy(type),
    isFcy: isFcy(type),
    isIntra: isIntra(type)
  };
};

var formatDateFn = function formatDateFn(date, locale) {
  return format(date, 'DD.MM.YYYY', {
    locale: locale
  });
};

var formatDate = memoize(formatDateFn, function (date) {
  return "".concat(date);
});

var isSOSingleDebit = function isSOSingleDebit(item) {
  var _item$debitAccounts;

  return Array.isArray(item.debitAccounts) ? ((_item$debitAccounts = item.debitAccounts) === null || _item$debitAccounts === void 0 ? void 0 : _item$debitAccounts.length) <= 1 : false;
};

var getAmountSumWhenThereIsMoreDebitAccounts = function getAmountSumWhenThereIsMoreDebitAccounts(isSingleDebit, item) {
  var amountSum = 0;

  if (!isSingleDebit) {
    var _item$debitAccounts2;

    amountSum = (_item$debitAccounts2 = item.debitAccounts) === null || _item$debitAccounts2 === void 0 ? void 0 : _item$debitAccounts2.reduce(function (prev, curr) {
      return prev + curr.amount_Type !== STANDING_ORDER_PERCENT_TYPE ? Number(curr.amount_Value) : 0;
    }, 0);
  }

  return amountSum;
};

var processStandingOrder = function processStandingOrder(item, soTypesCatalog, expireWarningLimit) {
  var firstCreditAccount = getFirstCreditAccount(item);
  var firstDebitAccount = getFirstDebitAccount(item);
  var type = item.type || getSoType(item, soTypesCatalog);
  var isTTSE = isSoTTSE(type);
  var isSingleDebit = isSOSingleDebit(item);
  return _objectSpread(_objectSpread({}, item), {}, {
    firstCreditAccount: firstCreditAccount,
    firstDebitAccount: firstDebitAccount,
    type: type,
    currency: item.lastPaymentCurrency && item.lastPaymentCurrency.currencyID,
    name: getName(item, firstCreditAccount),
    description: getDetails(type, firstCreditAccount),
    formatedLastPaymentDate: item.lastPaymentDate && formatDate(item.lastPaymentDate, enLocale) || '',
    isTreasury: isSoTreasury(type),
    isOwn: isSoOwn(type),
    isTTSE: isTTSE,
    isSingleDebit: isSingleDebit,
    amountSum: getAmountSumWhenThereIsMoreDebitAccounts(isSingleDebit, item),
    isInExpirationPeriod: isInExpirationPeriod(item.endDate, expireWarningLimit || '14'),
    endDateFormated: item.endDate && formatDate(item.endDate, enLocale) || '',
    whenINotify: item.endDate === STANDING_ORDER_ENDLESS_DATE || item.endDate === null || item.endDate === '01-01-2100' || item.endDate === '06-06-2079',
    formatedFrequency: getFormatedFrequency(item)
  }, getTTSeDetails(isTTSE, type));
};

export { processStandingOrder };
