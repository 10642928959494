import { composeFragments, pathFragment, action, state, resolveLogic } from 'core-ingredients';
import { withFieldsSelectors, withInitializeForm, withResetForm } from 'core-recipes';
import { signingRulesStepHomeFieldsFragment } from './signing-rules-step-home.fields.js';

var signingRulesStepHomeLogicSchema = composeFragments(pathFragment('signingRules', 'stepHome'), action('setRules', function (rules) {
  return {
    rules: rules
  };
}), state(['existingRules', 'setRules'], function (_, _ref) {
  var rules = _ref.rules;
  return rules;
}), signingRulesStepHomeFieldsFragment, withFieldsSelectors, withInitializeForm({}), withResetForm({}));
var signingRulesStepHomeLogicAPI = resolveLogic(signingRulesStepHomeLogicSchema);
var signingRulesStepHomeLogic = signingRulesStepHomeLogicAPI.logic;
var signingRulesStepHomeReducer = signingRulesStepHomeLogicAPI.reducer;
var signingRulesStepHomeSaga = signingRulesStepHomeLogicAPI.saga;

export { signingRulesStepHomeLogic, signingRulesStepHomeLogicAPI, signingRulesStepHomeReducer, signingRulesStepHomeSaga };
