import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { providerFragment } from '../../common/provider/provider.fragment.js';
import { wizardFragment } from '../../../generic/wizard.fragment.js';
import { TRANSFERS_STEPS } from '../../constants.js';
import { billPaymentFragment } from '../wizard/bill-payment.fragment.js';
import { instructionIdsFragment } from '../../common/instruction-logic.js';
import { withVTBCertificationFragment } from '../../common/withVTBCertificationFragment.js';
import { useTemplateFragment } from '../../../generic/statistics/use-template.fragment.js';
import { decidePaymentWithoutBalanceCheckFragment } from '../../common/decidePaymentWithoutBalanceCheckFragment.js';
import { paymentWithGetDebtIsSuccessfulFragment } from '../../common/paymentWithGetDebtIsSuccessfulFragment.js';
import { bpFormFragment } from './bill-payment-form.fragment.js';
import { transferBillPaymentFieldsFragment } from './bill-payment.fields.js';
import { bpTemplatesFragment } from '../../common/bp-templates/bp-templates.fragment.js';
import { debtAndCommissionFragment } from '../../common/debt-and-commision/debt-and-commission-fragment.js';

var billPaymentFormLogic = logic2(composeFragments(pathFragment('transfers', 'billForm'), wizardFragment(TRANSFERS_STEPS), providerFragment, billPaymentFragment, bpTemplatesFragment, decidePaymentWithoutBalanceCheckFragment, transferBillPaymentFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, useTemplateFragment, withVTBCertificationFragment, instructionIdsFragment, debtAndCommissionFragment, paymentWithGetDebtIsSuccessfulFragment, bpFormFragment));
var billPaymentFormReducer = billPaymentFormLogic.reducer;
var billPaymentFormSaga = sagaifyLogic2(billPaymentFormLogic);

export { billPaymentFormLogic, billPaymentFormReducer, billPaymentFormSaga };
