import { resolveLogic, composeFragments, pathFragment, action, stateFlag, state, flow, resolveAction } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors, certify } from 'core-recipes';
import { takeLatest } from 'redux-saga/effects';
import { changePasswordFieldsFragment } from './change-password-fields-fragment.js';
import { processPasswordFlow } from './change-password-flow.js';

var withCertificationFragment = certify.withCertificationFragment;
var changePasswordLogicAPI = resolveLogic(composeFragments(pathFragment('security', 'changePassword'), changePasswordFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, withCertificationFragment({}), action('submit'), action('calling'), action('initializeForm'), action('closeModal'), action('selfAdminFailed', function (error) {
  return {
    error: error
  };
}), action('notCorrectPassword'), action('setSubmitCount', function (submitCount) {
  return {
    submitCount: submitCount
  };
}), action('setBlocked', function (blocked) {
  return {
    blocked: blocked
  };
}), stateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm'], false), stateFlag('submitting', ['calling'], ['denied', 'tmpSuccess', 'tmpFailed', 'failed', 'resetForm', 'notCorrectPassword', 'selfAdminFailed']), stateFlag('modalIsOpen', ['openModal'], ['closeModal']), stateFlag('gmbSMS', ['openVerify'], ['closeVerify']), stateFlag('isModalGMB', ['openModalGMB', 'failed', 'denied'], ['closeModalGMB']), stateFlag('buttonIsHidden', ['hideButton'], ['closeModal', 'showButton', 'resetForm']), stateFlag('changePasswordFailed', ['failed'], ['closeModal', 'showButton', 'initializeForm']), stateFlag('passwordAccessDenied', ['denied'], ['closeModal', 'showButton']), stateFlag('selfAdminFailedState', ['selfAdminFailed'], ['updatePassword', 'resetForm'], false), state(['notCorrectPasswordState', 'selfAdminFailed'], function (_, _ref) {
  var _error$body, _error$body$errors;

  var error = _ref.error;
  return error === null || error === void 0 ? void 0 : (_error$body = error.body) === null || _error$body === void 0 ? void 0 : (_error$body$errors = _error$body.errors) === null || _error$body$errors === void 0 ? void 0 : _error$body$errors.some(function (violation) {
    return violation.code === 'CHANGE_PASSWD_CHECK_ACTUAL_POLICY_VIOLATION';
  });
}), state(['notCorrectPasswordState', 'notCorrectPassword'], function () {
  return true;
}), state(['notCorrectPasswordState', 'updateCurrentPassword'], function () {
  return false;
}), state(['notCorrectPasswordState', 'resetForm'], function () {
  return false;
}), state(['sameAsLoginnameState', 'selfAdminFailed'], function (_, _ref2) {
  var _error$body2, _error$body2$errors;

  var error = _ref2.error;
  return error === null || error === void 0 ? void 0 : (_error$body2 = error.body) === null || _error$body2 === void 0 ? void 0 : (_error$body2$errors = _error$body2.errors) === null || _error$body2$errors === void 0 ? void 0 : _error$body2$errors.some(function (violation) {
    return violation.code === 'SAME_AS_LOGIN_NAME_POLICY_VIOLATION';
  });
}), state(['sameAsLoginnameState', 'updatePassword'], function () {
  return false;
}), state(['sameAsLoginnameState', 'resetForm'], function () {
  return false;
}), state(['certifiable', 'certificationSubmited'], function (_, _ref3) {
  var certifiable = _ref3.certifiable;
  return certifiable;
}, false), state(['currentPasswordTypeInput', 'currentTypeToggle'], function (state) {
  return state === 'password' ? 'text' : 'password';
}, 'password'), state(['passwordInputType', 'passwordTypeToggle'], function (state) {
  return state === 'password' ? 'text' : 'password';
}, 'password'), state(['repeatPasswordInputType', 'repeatTypeToggle'], function (state) {
  return state === 'password' ? 'text' : 'password';
}, 'password'), state(['currentPasswordTypeInput', 'hidePassword'], function () {
  return 'password';
}, 'password'), state(['passwordInputType', 'hidePassword'], function () {
  return 'password';
}, 'password'), state(['repeatPasswordInputType', 'hidePassword'], function () {
  return 'password';
}, 'password'), state(['submitCount', 'setSubmitCount'], function (_, _ref4) {
  var submitCount = _ref4.submitCount;
  return submitCount;
}, 1), state(['blocked', 'setBlocked'], function (_, _ref5) {
  var blocked = _ref5.blocked;
  return blocked;
}, false), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, processPasswordFlow])));
var changePasswordLogic = changePasswordLogicAPI.logic;
var changePasswordReducer = changePasswordLogicAPI.reducer;
var changePasswordSaga = changePasswordLogicAPI.saga;

export { changePasswordLogic, changePasswordReducer, changePasswordSaga };
