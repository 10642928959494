import React from 'react'
import { MenuItem } from 'vtb-ui'
import { memoize, ROUTES } from 'vtb-utils'

const isPartOfSectionHandler = ({ base }, currentPage, baseUrl) => {
  const url = `${baseUrl}${base}`
  return currentPage.substr(0, url.length) === url
}

const isPartOfSection: typeof isPartOfSectionHandler = memoize(
  isPartOfSectionHandler,
  ({ base }, currentPage) => `${base}${currentPage}`,
)

// todo @safi? add bells and whistles if any
export const MenuItemView = (props) => {
  const active =
    props.href === window.location.pathname ||
    (props.base && isPartOfSection(props.base, window.location.pathname, ROUTES.BASE_URL)) ||
    false
  return <MenuItem {...props} active={active} color={props.textColor} />
}
