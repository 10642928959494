import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { providerFragment } from '../../common/provider/provider.fragment.js';
import { wizardFragment } from '../../../generic/wizard.fragment.js';
import { TRANSFERS_STEPS } from '../../constants.js';
import { withVTBCertificationFragment } from '../../common/withVTBCertificationFragment.js';
import { instructionIdsFragment } from '../../common/instruction-logic.js';
import { decidePaymentWithoutBalanceCheckFragment } from '../../common/decidePaymentWithoutBalanceCheckFragment.js';
import { paymentWithGetDebtIsSuccessfulFragment } from '../../common/paymentWithGetDebtIsSuccessfulFragment.js';
import { carPaymentFieldsFragment } from './car-payment-form.fields.js';
import { carPaymentFormFragment } from './car-payment-form.fragment.js';
import { debtAndCommissionFragment } from '../../common/debt-and-commision/debt-and-commission-fragment.js';

var carsFormLogic = logic2(composeFragments(pathFragment('cars', 'paymentForm'), wizardFragment(TRANSFERS_STEPS), providerFragment, decidePaymentWithoutBalanceCheckFragment, carPaymentFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, withVTBCertificationFragment, instructionIdsFragment, debtAndCommissionFragment, paymentWithGetDebtIsSuccessfulFragment, carPaymentFormFragment));
var CarsFormReducer = carsFormLogic.reducer;
var CarsFormSaga = sagaifyLogic2(carsFormLogic);

export { CarsFormReducer, CarsFormSaga, carsFormLogic };
