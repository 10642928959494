/**
 * Returns simple IBalance object
 */
var createBalance = function createBalance(amount, currency) {
  return {
    amount: amount,
    currency: currency
  };
};

export { createBalance };
