import { composeFragments, action, state, selector, extendInitialState } from 'core-ingredients';
import { identity } from 'ramda';
import { CONFIRM_FORM } from '../payment-centre/constants.js';

var wizardFragment = function wizardFragment(STEPS) {
  return composeFragments(action('backToFirstStep', identity), action('setStep', function (step) {
    return {
      step: step
    };
  }), action('start', identity), action('stop', identity), state(['step', 'backToFirstStep'], function () {
    return STEPS.FORM;
  }), state(['step', 'backToConfirmStep'], function () {
    return STEPS.CONFIRM;
  }), state(['step', 'setStep'], function (_, _ref) {
    var step = _ref.step;
    return step;
  }), state(['step', 'tmpFailed'], function () {
    return STEPS.ERROR;
  }), state(['step', 'tmpSuccess'], function () {
    return STEPS.SUCCESS;
  }), state(['step', 'reset'], function () {
    return STEPS.FORM;
  }), state(['sending', 'start'], function () {
    return true;
  }), state(['sending', 'reset'], function () {
    return false;
  }), state(['sending', 'stop'], function () {
    return false;
  }), selector('isSuccessResult', ['step'], function (step) {
    return step === STEPS.SUCCESS;
  }), selector('isResultStep', ['step'], function (step) {
    return step === STEPS.SUCCESS || step === STEPS.ERROR;
  }), selector('isConfirmStep', ['step'], function (step) {
    return step === STEPS.CONFIRM;
  }), selector('isGbmConfirmStep', ['step'], function (step) {
    return [STEPS.CONFIRM, CONFIRM_FORM].includes(step);
  }), selector('isFormStep', ['step'], function (step) {
    return step === STEPS.FORM;
  }), extendInitialState(null, {
    step: STEPS.FORM,
    sending: false
  }));
};

export { wizardFragment };
