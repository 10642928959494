import qs from 'qs'
import { pipe, is, filter as filterList, isNil } from 'ramda'

const PREFIX = '/'

/*

IBS_CONFIG

keys: query.name
values: url as string, transform function or array of transform functions (will be piped together), request object

- Transfrom functions receive ({query, request}) and return same.
- request object's url is also transformed if it is function of array of functions

For known names contextId query param is always added.
*/

interface URLTransformInput {
  request: any
  query: any
}

type IURLTransformFunction = (input: URLTransformInput) => URLTransformInput

type IURLOrTransformFunction = string | IURLTransformFunction | IURLTransformFunction[]

interface IAdvancedNameConfig {
  method: string
  url: IURLOrTransformFunction
  [requestParamName: string]: any
}

type INameConfig = IURLOrTransformFunction | IAdvancedNameConfig

interface IIBSC_CONFIG {
  [queryName: string]: INameConfig
}

function setBasicUrl(input) {
  const { request, query } = input
  const { name } = query
  return { ...input, request: { ...request, url: `${PREFIX}${name.slice(0, -1)}/v1/${name}/` } }
}

function addId(input) {
  const { request, query } = input
  const { url } = request
  const { id } = query
  return { ...input, request: { ...request, url: `${url}${id}` } }
}

function addFirstChild(input) {
  const { request, query } = input
  const { url } = request
  const scope = query.children[0].name
  return { ...input, request: { ...request, url: `${url}/${scope}` } }
}

function addAllChildren(input) {
  const { request, query } = input
  const { url } = request
  const scopes = query.children.map(({ name }) => name).join('/')
  return { ...input, request: { ...request, url: `${url}/${scopes}` } }
}

function addIdAndFirstChild(input) {
  return pipe(addId, addFirstChild)(input)
}

function addIdAndAllChildren(input) {
  return pipe(addId, addAllChildren)(input)
}

// TODO: rewrite this with usage of qs package
function addQueryParams(params: string | string[], queryNames: string | string[] = params) {
  return (input) => {
    const { request, query } = input
    const { url } = request
    const joint = url.indexOf('?') > -1 ? '&' : '?'

    params = ([] as string[]).concat(params)
    queryNames = ([] as string[]).concat(queryNames)

    const queryParams = params.map((name, i) => `${queryNames[i]}=${query[name]}`)

    return { ...input, request: { ...request, url: `${url}${joint}${queryParams.join('&')}` } }
  }
}

function setName(name: string) {
  return (input) => ({ ...input, query: { ...input.query, name } })
}

const IBS_CONFIG: IIBSC_CONFIG = {
  cards: [setBasicUrl, addIdAndAllChildren],
  loans: [setBasicUrl, addIdAndFirstChild],
  loyalty: `${PREFIX}loyalty/v1/bonusPoints`,
  systemParameters: `${PREFIX}system-parameters/value`,
  statements: {
    method: 'PUT',
    url: [setBasicUrl, addId, addQueryParams(['startDate', 'endDate', 'inEquivalent'])],
  },
  statementMovements: [setName('statements'), setBasicUrl, addId, addQueryParams(['startPointer', 'count'])],
  getDebt: {
    method: 'POST',
    url: `${PREFIX}billpayment/v1/getDebt`,
  },
  getDebts: {
    method: 'POST',
    url: `${PREFIX}billpayment/v1/getDebts`,
  },
  getCommission: {
    method: 'POST',
    url: `${PREFIX}billpayment/v1/getCommission`,
  },
}

const mapParams = ({ url, params }) => {
  const paramString = qs.stringify(filterList((item) => !isNil(item), params), {
    encode: true,
    addQueryPrefix: true,
  })
  return `${url}${paramString}`
}

const parseConfig = (query) => {
  const { name } = query

  let currentConfig: any = IBS_CONFIG[name]

  let request: any = {
    method: 'GET',
    url: name,
  }

  if (name === 'deleteNotifications') {
    return ({
      method: 'DELETE',
      url: query.url,
      body: query.data,
    })
  }

  if (name === 'removeMessages') {
    return ({
      method: 'DELETE',
      url: query.url,
    })
  }

  if (currentConfig === undefined) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { authentication, authorization, locale, name, url, ...restQuery } = query
    return { ...request, url: mapParams({ url, params: restQuery }) }
  }

  if (!Array.isArray(currentConfig) && is(Object, currentConfig)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { authentication, authorization, children, id, locale, name, ...restQuery } = query
    if (currentConfig['method'] === 'POST' || currentConfig['method'] === 'PUT') {
      request.body = { ...restQuery }
    }

    request = { ...request, ...currentConfig }
  } else {
    request.url = currentConfig
  }

  let { url } = request

  if (Array.isArray(url)) {
    url = pipe(...url)
  }

  if (is(Function, url)) {
    request = url({ request, query }).request
  }

  if (is(String, url)) {
    request = { ...request, url }
  }

  return addQueryParams('authorization', 'contextId')({ request, query }).request
}

export const ibsInterceptor = {
  name: 'ibsApi',
  enter: (operation) => {
    const { query, command, keepalive = false } = operation

    if (query) {
      const { authentication, name } = query

      const headers = {
        Authorization: `Bearer ${authentication}`,
        Accept: name === 'messageDetailAttachment' ? 'application/octet-stream' : 'application/json',
      }

      const request = parseConfig(query)
      return {
        ...operation,
        query: request.query,
        request: { ...request, headers },
      }
    }

    if (command) {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        name, authentication, method = 'POST', url, path, authorization, locale, headers = {}, data, ...commandParams
      } = command

      const requestHeaders = {
        Authorization: `Bearer ${authentication}`,
        ...headers,
      }

      const requestUrl = url || `contexts/${authorization}/${path}`

      const request = {
        method: operation.method || method,
        url: `${PREFIX}${requestUrl}`,
        body: data || commandParams,
        headers: requestHeaders,
        keepalive,
      }

      return {
        ...operation,
        request,
      }
    }
  },
  leave: async({ response, ...operation }) => {
    const { location } = response.headers

    if (location) {
      return {
        ...operation,
        data: { location },
      }
    }

    if (response.body) {
      const data = response.body
      return {
        ...operation,
        data,
      }
    }

    return {
      ...operation,
      data: {},
    }
  },
}
