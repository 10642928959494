var accountRequisitesQuery = function accountRequisitesQuery(id) {
  var query = {
    Accounts: ['cbsID', 'friendlyName', 'accountOpenDate', {
      currency: ['currencyID']
    }, {
      ibanAccount: ['iban']
    }, {
      status: [{
        'statusId': ['text']
      }, 'hostStatusCode']
    }],
    id: id
  };
  return query;
};
var depositRequisitesQuery = function depositRequisitesQuery() {
  var query = {
    Deposits: ['cbsId', 'friendlyName', 'initialAmount', 'interestRate', {
      interestReleaseType: ['text']
    }, {
      depositAccount: ['cbsID']
    }]
  };
  return query;
};
var cardInsurancesRequisitesQuery = function cardInsurancesRequisitesQuery(iban) {
  var query = {
    CardInsurances: ['validTo', 'isProlongable', 'refundLimit', {
      'ibanAccount': ['iban']
    }, {
      status: ['text']
    }, {
      product: ['text']
    }, {
      refundCurrency: ['currencyID']
    }],
    where: {
      'ibanAccount.entityUniqueId': iban,
      'status.entityUniqueId': 'Active'
    }
  };
  return query;
};

export { accountRequisitesQuery, cardInsurancesRequisitesQuery, depositRequisitesQuery };
