import { get } from 'core-supply';

var _navigator;
// HACK: Temporary workaround to fix transition issues on mobile caused by wrong path.
var apiPrefix = ((_navigator = navigator) === null || _navigator === void 0 ? void 0 : _navigator.product) === 'ReactNative' ? '' : 'ibs/';
var fetchLocalizations = function fetchLocalizations(_ref) {
  var clientSystem = _ref.clientSystem;
  return get("".concat(apiPrefix, "localization/").concat(clientSystem));
};

export { fetchLocalizations };
