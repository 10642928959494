import { fieldsFragment, fieldFragment } from 'core-recipes';
import { composeFragments, validationRule, selector } from 'core-ingredients';
import { requiredRule } from '../../utils/validators.js';

var conditionFields = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'agree',
  rules: [validationRule(requiredRule)]
})), selector('disabledDueToAgree', ['agree'], function (agree) {
  return agree.errors && agree.errors.length;
}));

export { conditionFields };
