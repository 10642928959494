var SHORTCUT_KEYS = {
  LOCATION: 'location',
  TEMPLATES: 'myTemplates',
  WAITING_ROOM: 'paymentsWaitingForAuth',
  CC_PAYMENTS: 'ccPayments',
  CALCULATOR: 'calculator',
  MOBILE_TOP_UP: 'mobileTopUp',
  BILL_PAYMENTS: 'billPayments'
};
var isShortCutItemDisabled = function isShortCutItemDisabled(key, _ref) {
  var canViewCallCentre = _ref.canViewCallCentre,
      canViewWaitingRoom = _ref.canViewWaitingRoom,
      canViewBillPayments = _ref.canViewBillPayments,
      canViewPaymentTemplate = _ref.canViewPaymentTemplate,
      canCreateMobileTopUp = _ref.canCreateMobileTopUp;
  return key === 'myTemplates' && !canViewPaymentTemplate || key === 'billPayments' && !canViewBillPayments || key === 'mobileTopUp' && !canCreateMobileTopUp || key === SHORTCUT_KEYS.WAITING_ROOM && !canViewWaitingRoom || key === SHORTCUT_KEYS.CC_PAYMENTS && !canViewCallCentre;
};

export { SHORTCUT_KEYS, isShortCutItemDisabled };
