import { eventChannel, END } from 'redux-saga';

function exportCountdown(secs, limit) {
  return eventChannel(function (emitter) {
    var iv = setInterval(function () {
      secs -= limit;

      if (secs > 0) {
        emitter({
          secs: secs,
          emitter: emitter
        });
      } else {
        emitter({
          secs: 0,
          emitter: emitter
        }); // this causes the channel to close

        emitter(END);
      }
    }, limit); // The subscriber must return an unsubscribe function

    return function () {
      clearInterval(iv);
    };
  });
}

export { exportCountdown };
