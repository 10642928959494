import { takeLeading, takeLatest } from 'redux-saga/effects';
import { composeFragments, action, state, stateFlag, selector, addFlow, resolveAction } from 'core-ingredients';
import { fetchUserSettingsFlow, saveUserSettingsFlow, fetchClientUserSettingsFlow, saveClientUserSettingsFlow } from './user-settings.flows.js';

var userSettingsFragment = composeFragments(action('fetchUserSettings', function (realm) {
  return {
    realm: realm
  };
}), action('saveUserSettings', function (realm, settings) {
  return {
    realm: realm,
    settings: settings
  };
}), action('fetchClientUserSettings', function (realm) {
  return {
    realm: realm
  };
}), action('saveClientUserSettings', function (realm, settings) {
  return {
    realm: realm,
    settings: settings
  };
}), action('userSettingsSuccess', function (settings) {
  return {
    settings: settings
  };
}), action('userSettingsLoadFailed', function (realm) {
  return {
    realm: realm
  };
}), action('clientUserSettingsSuccess', function (settings) {
  return {
    settings: settings
  };
}), action('clientUserSettingsLoadFailed', function (realm) {
  return {
    realm: realm
  };
}), state(['userSettings', 'userSettingsSuccess'], function (_, _ref) {
  var settings = _ref.settings;
  return settings;
}, {}), state(['clientUserSettings', 'clientUserSettingsSuccess'], function (_, _ref2) {
  var settings = _ref2.settings;
  return settings;
}, {}), stateFlag(['userSettingsLoading'], ['fetchUserSettings'], ['userSettingsSuccess', 'userSettingsLoadFailed'], false), stateFlag(['clientUserSettingsLoading'], ['fetchClientUserSettings'], ['clientUserSettingsSuccess', 'clientUserSettingsLoadFailed'], false), stateFlag(['userSettingsLoaded'], ['userSettingsSuccess'], [], false), stateFlag(['clientUserSettingsLoaded'], ['clientUserSettingsSuccess'], [], false), selector('mainAccount', ['clientUserSettings'], function (userSettings) {
  var _userSettings$mainAcc, _userSettings$mainAcc2;

  if (userSettings !== null && userSettings !== void 0 && (_userSettings$mainAcc = userSettings.mainAccount) !== null && _userSettings$mainAcc !== void 0 && _userSettings$mainAcc.data) {
    return userSettings.mainAccount.data;
  }

  if (userSettings !== null && userSettings !== void 0 && (_userSettings$mainAcc2 = userSettings.mainAccount) !== null && _userSettings$mainAcc2 !== void 0 && _userSettings$mainAcc2.iban) {
    return userSettings.mainAccount;
  }

  return null;
}), addFlow('fetchUserSettings', [resolveAction('fetchUserSettings'), takeLeading, fetchUserSettingsFlow]), addFlow('saveUserSettings', [resolveAction('saveUserSettings'), takeLatest, saveUserSettingsFlow]), addFlow('fetchClientUserSettings', [resolveAction('fetchClientUserSettings'), takeLatest, fetchClientUserSettingsFlow]), addFlow('saveClientUserSettings', [resolveAction('saveClientUserSettings'), takeLatest, saveClientUserSettingsFlow]));

export { userSettingsFragment };
