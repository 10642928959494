var accountsListQuery = {
  Accounts: ['cbsID', 'friendlyName', 'accountName', 'ibanAccount', 'typeName', 'govRestrictionSeqType', 'govRestrictionIncType', 'accountOpenDate', {
    balance: [{
      'currency': ['currencyID']
    }, 'overdraftAmount', 'availableBalance', 'nonAuthorizedOverdraftAmount']
  }, {
    accountSubType: ['text', 'externalId', 'id']
  }, {
    accountType: ['accTypeId', 'text', 'status', {
      'defaultAccountIcon': ['icon']
    }]
  }, {
    currency: ['currencyID']
  }, {
    status: ['statusId', 'text', 'activeStatusId', 'hostStatusCode']
  }, {
    customProductIcon: ['icon']
  }, {
    multicurrencyAccount: ['iban']
  }],
  limit: 1000,
  where: {
    'status.entityUniqueId': {
      inlist: ['Open', 'CreditOnly', 'BudgetOnly', 'Frozen']
    },
    'accountType.entityUniqueId': {
      inlist: ['100', '200', '32']
    }
  }
};
var cardsListQuery = {
  Cards: ['cardNumber', 'isMainCard', 'validTo', {
    productType: ['text', 'linkedCardPriority']
  }, {
    cardDesign: ['picSmall']
  }, {
    ibanAccount: ['iban']
  }]
};

export { accountsListQuery, cardsListQuery };
