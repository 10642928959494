import React, { PureComponent } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { Flex, LoaderWrapper } from 'vtb-ui'

declare global {
  interface Window {
    requestIdleCallback?: (callback: () => void, options?: {timeout: number}) => void
  }
}

const CALL_TIMEOUT = 1000
export class InfiniteScroll extends PureComponent<{onBecomeVisible: () => void; loading?: boolean}> {
  onVisibilityChange = (isVisible) => {
    if (!isVisible) {
      return
    }
    const { onBecomeVisible } = this.props

    if (!onBecomeVisible) {
      return
    }

    if (window.requestIdleCallback) {
      window.requestIdleCallback(onBecomeVisible, { timeout: CALL_TIMEOUT })
    } else {
      onBecomeVisible()
    }
  }

  render() {
    const { loading = true } = this.props

    return (
      <Flex id='list-button-more' class='morePagination' justifyContent='center'>
        <VisibilitySensor onChange={this.onVisibilityChange} partialVisibility>
          <LoaderWrapper inner loading={loading} />
        </VisibilitySensor>
      </Flex>
    )
  }
}
