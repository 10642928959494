import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { Flex } from '../generic/flex.js';

var _templateObject;
var Toast = styled(Flex).attrs(function (_ref) {
  var theme = _ref.theme,
      color = _ref.color;
  return {
    width: ['100%', 'auto'],
    px: 20,
    height: theme.space.elementHeight * 1.5,
    background: color || theme.colors.warning,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    shadow: 3
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-flex;\n"])));

export { Toast };
