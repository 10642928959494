import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { pipe, filter, map, sortBy, prop } from 'ramda';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var gibLoginMethods = ['106', '102'];
var gibCertMethods = ['120', '302'];
var assignImg = function assignImg(method) {
  switch (method.code) {
    case '102':
      {
        return {
          img: '/securityMethods/LN_PWD_OTP.png',
          imgActive: '/securityMethods/LN_PWD_OTP_active.png',
          isLoginMethod: true,
          isEasyMethod: false,
          isBiometry: false,
          title: 'lnPwdOtp'
        };
      }

    case '106':
      {
        return {
          img: '/securityMethods/LN_PWD_SMS.png',
          imgActive: '/securityMethods/LN_PWD_SMS_active.png',
          isLoginMethod: true,
          isEasyMethod: false,
          isBiometry: false,
          title: 'lnPwdSms'
        };
      }

    case '107':
      {
        return {
          img: '/securityMethods/LN_PINDEVREG.png',
          imgActive: '/securityMethods/LN_PINDEVREG_active.png',
          isLoginMethod: true,
          isEasyMethod: true,
          isBiometry: false,
          title: 'lnPin'
        };
      }

    case '108':
      {
        return {
          img: '/securityMethods/LN_FINGERPRINTDEVREG.png',
          imgActive: '/securityMethods/LN_FINGERPRINTDEVREG_active.png',
          isLoginMethod: true,
          isEasyMethod: true,
          isBiometry: true,
          title: 'lnFingerprint'
        };
      }

    case '120':
      {
        return {
          img: '/securityMethods/SMS.png',
          imgActive: '/securityMethods/SMS_active.png',
          isLoginMethod: false,
          isEasyMethod: false,
          isBiometry: false,
          title: 'sms'
        };
      }

    case '302':
      {
        return {
          img: '/securityMethods/OTP.png',
          imgActive: '/securityMethods/OTP_active.png',
          isLoginMethod: false,
          isEasyMethod: false,
          isBiometry: false,
          title: 'otp'
        };
      }

    case '304':
      {
        return {
          img: '/securityMethods/LN_PINDEVREG.png',
          imgActive: '/securityMethods/LN_PINDEVREG_active.png',
          isLoginMethod: false,
          isEasyMethod: true,
          isBiometry: false,
          title: 'pin'
        };
      }

    case '305':
      {
        return {
          img: '/securityMethods/LN_FINGERPRINTDEVREG.png',
          imgActive: '/securityMethods/LN_FINGERPRINTDEVREG_active.png',
          isLoginMethod: false,
          isEasyMethod: true,
          isBiometry: true,
          title: 'fingerprint'
        };
      }

    default:
      {
        return {};
      }
  }
};

var mapMethod = function mapMethod(method) {
  var additionalAttributes = method.additionalAttributes;
  return _objectSpread({
    code: method.code,
    shortCode: additionalAttributes.SHORTCODE,
    texts: additionalAttributes.TEXTID,
    strength: additionalAttributes.STRENGTH
  }, assignImg(method));
};

var prepareLoginMethods = pipe(filter(function (_ref) {
  var active = _ref.active,
      code = _ref.code;
  return active && gibLoginMethods.includes(code);
}), map(mapMethod), sortBy(prop('strength')));
var prepareCertMethods = pipe(filter(function (_ref2) {
  var active = _ref2.active,
      code = _ref2.code;
  return active && gibCertMethods.includes(code);
}), map(mapMethod), sortBy(prop('strength')));
var selectLoginPayload = function selectLoginPayload(method, channelAccess) {
  return map(function (_ref3) {
    var timestamp = _ref3.timestamp;
    return {
      authenticationMethodIds: [method],
      timestamp: timestamp
    };
  }, channelAccess);
};
var selectCertificationPayload = function selectCertificationPayload(method, channelAccess) {
  return map(function (_ref4) {
    var timestamp = _ref4.timestamp;
    return {
      certificationMethodIds: [method],
      timestamp: timestamp
    };
  }, channelAccess);
};

export { assignImg, prepareCertMethods, prepareLoginMethods, selectCertificationPayload, selectLoginPayload };
