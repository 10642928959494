var loanDetailQuery = function loanDetailQuery(id, currentLang) {
  return {
    Loans: ['cbsLoanId', 'loanType', 'customProductIcon', 'friendlyName', 'currency', 'interestRate', 'startDate', 'endDate', 'loanAmount', 'account', {
      customProductIcon: ['icon']
    }, {
      client: ['name', 'externalClientID']
    }, {
      currency: ['currencyID']
    }, {
      productType: ['prodTypeID', 'prodTypeBisCode', 'prodTypeCategory', {
        text: [currentLang]
      }, {
        productIcon: ['icon']
      }, {
        detailParamsGroup: ['detailParamsGroupID']
      }, {
        repaymentType: ['typeID']
      }]
    }, {
      account: ['cbsID', 'ibanAccount', 'friendlyName', {
        accountType: ['accTypeId', 'text']
      }, {
        accountSubType: ['id']
      }, {
        balance: ['availableBalance']
      }]
    }],
    id: id
  };
};
var collateralLoanQuery = function collateralLoanQuery(cbsLoanId) {
  return {
    loans: ['collateral'],
    id: cbsLoanId
  };
};
var insuranceLoanQuery = function insuranceLoanQuery(cbsLoanId) {
  return {
    loans: ['insurance'],
    id: cbsLoanId
  };
};
var repaymentScheduleQuery = function repaymentScheduleQuery(cbsLoanId, order) {
  return {
    RepaymentScheduleItems: ['principalAmount', 'interestAmount', 'insurance', 'date', 'balance'],
    order: order,
    where: {
      'loan.entityUniqueId': cbsLoanId
    },
    limit: 1000
  };
};
var accountsQuery = function accountsQuery(cbsIDs) {
  return {
    Accounts: ['cbsID', 'ibanAccount', 'friendlyName'],
    where: {
      'entityUniqueId': {
        inlist: cbsIDs
      }
    }
  };
};

export { accountsQuery, collateralLoanQuery, insuranceLoanQuery, loanDetailQuery, repaymentScheduleQuery };
