import { composeFragments, validationRule, maxLength, autocorrectingValidationRule } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withDependentFieldFlow, withDependentField, withDependentFieldValidation } from 'core-recipes';
import { DIRECT_DEBIT_PAY_TYPE, nextWorkingDay, getDdDateBoundaries } from 'vtb-utils';
import { validationConfig as validationConfig$1 } from '../../../bill-payment/form/bill-payment-fields-validations.js';
import { minMaxAmountDependentField } from '../../../common/validations/min-max-amount.js';
import { validationConfig } from './dd.form.fields.validations.js';
import { requiredRule, maxLengthAmount, minDate, dateRangeValidation, fakeValidation } from '../../../../utils/validators.js';

var directDebitFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'friendlyName',
  rules: [validationRule(requiredRule), validationRule(maxLength(30))]
}), fieldFragment({
  name: 'ddIcon'
}), fieldFragment({
  name: 'categoryId',
  rules: [validationRule(requiredRule)]
}, withDependentFieldFlow({
  source: 'categoryId',
  updateHandler: validationConfig.categoryId.dependentFlow
})), fieldFragment({
  name: 'provider',
  rules: [validationRule(requiredRule)]
}, withDependentFieldFlow({
  source: 'provider',
  updateHandler: validationConfig.provider.dependentFlow
})), fieldFragment({
  name: 'serviceCode',
  rules: [validationRule(requiredRule)]
}, withDependentFieldFlow({
  source: 'serviceCode',
  updateHandler: validationConfig.serviceCode.dependentFlow
})), fieldFragment({
  name: 'customerCode',
  rules: [validationRule(maxLength(140)), validationRule(requiredRule)]
}, withDependentField({
  source: 'serviceCode',
  computation: validationConfig$1.customerCode.serviceComputation
})), fieldFragment({
  name: 'payType',
  initialValue: DIRECT_DEBIT_PAY_TYPE.BILL_AMOUNT,
  rules: [validationRule(requiredRule)]
}, withDependentFieldFlow({
  source: 'payType',
  updateHandler: validationConfig.amount.dependentFlow
})), fieldFragment({
  name: 'amount',
  rules: [validationRule(requiredRule), autocorrectingValidationRule(maxLengthAmount(5))]
}, withDependentField({
  source: 'serviceCode',
  computation: minMaxAmountDependentField
})), fieldFragment({
  name: 'debitAccount',
  rules: [validationRule(requiredRule)]
}), fieldFragment({
  name: 'payDay'
}, withDependentFieldValidation({
  fieldName: 'payType',
  code: 'required',
  validation: validationConfig.payDay.dependentFieldValidation,
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      payType: selectors.payType
    };
  }
})), fieldFragment({
  name: 'startDate',
  initialValue: nextWorkingDay().toDate(),
  rules: [validationRule(requiredRule), validationRule(minDate(getDdDateBoundaries().startMinDate, 'error.pay.directDebit.minDate'))]
}, withDependentFieldValidation({
  fieldName: 'endDate',
  code: 'startDateEndDate',
  validation: function validation(params) {
    var _params$notify;

    return params && !(params !== null && params !== void 0 && (_params$notify = params.notify) !== null && _params$notify !== void 0 && _params$notify.value) ? dateRangeValidation(params) : fakeValidation;
  },
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      startDate: selectors.startDate,
      endDate: selectors.endDate,
      notify: selectors.notify
    };
  }
})), fieldFragment({
  name: 'endDate'
}, withDependentFieldValidation({
  fieldName: 'notify',
  code: 'required',
  validation: function validation(params) {
    var _params$notify2;

    return params && !(params !== null && params !== void 0 && (_params$notify2 = params.notify) !== null && _params$notify2 !== void 0 && _params$notify2.value) ? requiredRule : fakeValidation;
  },
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      notify: selectors.notify
    };
  }
}), withDependentFieldValidation({
  fieldName: 'startDate',
  code: 'startDateEndDate',
  validation: function validation(params) {
    var _params$notify3;

    return !(params !== null && params !== void 0 && (_params$notify3 = params.notify) !== null && _params$notify3 !== void 0 && _params$notify3.value) ? dateRangeValidation(params) : fakeValidation;
  },
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      startDate: selectors.startDate,
      endDate: selectors.endDate,
      notify: selectors.notify
    };
  }
})), fieldFragment({
  name: 'notify',
  initialValue: true
}, withDependentFieldFlow({
  source: 'notify',
  updateHandler: validationConfig.notify.dependentFlow
}))));

export { directDebitFieldsFragment };
