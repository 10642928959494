import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { editGroupsFieldsFragment } from './bill-payment.fields.js';
import { bpEditGroupsFormFragment } from './bill-payment-groups-form.fragment.js';

var billPaymentGroupsFormLogic = logic2(composeFragments(pathFragment('transfers', 'billEditGroups'), editGroupsFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, bpEditGroupsFormFragment));
var billPaymentEditGroupsFormReducer = billPaymentGroupsFormLogic.reducer;
var billPaymentEditGroupsFormSaga = sagaifyLogic2(billPaymentGroupsFormLogic);

export { billPaymentEditGroupsFormReducer, billPaymentEditGroupsFormSaga, billPaymentGroupsFormLogic };
