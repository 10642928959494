import _defineProperty from '@babel/runtime/helpers/defineProperty';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var transferFragment = function transferFragment(initialState) {
  return {
    actions: {
      postTransfer: function postTransfer() {
        for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
          params[_key] = arguments[_key];
        }

        return _objectSpread({}, params);
      },
      confirm: function confirm() {
        return {};
      },
      setInstruction: function setInstruction(instruction) {
        return {
          instruction: instruction
        };
      },
      tmpFailed: function tmpFailed(error) {
        return {
          error: error
        };
      },
      setValidationErrors: function setValidationErrors(errors) {
        return {
          errors: errors
        };
      },
      tmpSuccess: function tmpSuccess() {
        return {};
      },
      updateTransfer: function updateTransfer(key, value) {
        return {
          key: key,
          value: value
        };
      },
      transferUpdated: function transferUpdated(transfer) {
        return {
          transfer: transfer
        };
      }
    },
    state: {
      transfer: {
        'actions.postTransfer': function actionsPostTransfer(_, _ref) {
          var transfer = _ref.transfer;
          return transfer;
        },
        'actions.transferUpdated': function actionsTransferUpdated(_, _ref2) {
          var transfer = _ref2.transfer;
          return transfer;
        },
        'actions.reset': function actionsReset() {
          return initialState.transfer;
        }
      },
      instruction: {
        'actions.setInstruction': function actionsSetInstruction(_, _ref3) {
          var instruction = _ref3.instruction;
          return instruction;
        },
        'actions.tmpFailed': function actionsTmpFailed() {
          return null;
        },
        'actions.reset': function actionsReset() {
          return null;
        }
      },
      isFormSubmited: {
        'actions.reset': function actionsReset() {
          return false;
        },
        'actions.submited': function actionsSubmited() {
          return true;
        }
      },
      serverErrors: {
        'actions.setValidationErrors': function actionsSetValidationErrors(_, _ref4) {
          var errors = _ref4.errors;
          return errors;
        },
        'actions.reset': function actionsReset() {
          return [];
        }
      }
    },
    selectors: {
      transfer: [['transfer']],
      instruction: [['instruction']],
      isFormSubmited: [['isFormSubmited']],
      serverErrors: [['serverErrors']]
    },
    flows: {},
    initialState: _objectSpread({}, initialState)
  };
};

export { transferFragment };
