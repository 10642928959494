import { takeLatest } from 'redux-saga/effects';
import { composeFragments, state, stateFlag, selector, flow, resolveAction } from 'core-ingredients';
import { listFragment } from 'core-recipes';
import { composeImages, soLoadingImagesFlow } from './so-loading-images.flow.js';

var soLoadImagesFragment = composeFragments(listFragment({
  listName: 'templates',
  entityName: 'paymentTemplates',
  listQuery: {
    PaymentTemplates: ['hasImage', 'beneficiaryAccount'],
    limit: 1000
  }
}), state(['pictureReferences', 'pictureReferencesDataLoaded'], function (_, _ref) {
  var pictureReferences = _ref.pictureReferences;
  return pictureReferences;
}, null), state(['pictureReferences', 'reset'], function () {
  return null;
}), stateFlag('imagesLoading', ['startImageLoading'], ['stopImageLoading', 'reset'], false), selector('images', ['templates', 'standingOrders', 'standingOrderTypes', 'pictureReferences'], function (templates, standingOrders, standingOrderTypes, pictureReferences) {
  var images = [];

  if (standingOrders && standingOrders.length > 0) {
    images = standingOrders.map(function (standingOrder) {
      return composeImages(standingOrder, templates, standingOrderTypes, pictureReferences);
    }).filter(Boolean);
  }

  return images;
}), flow('loadingImages', [resolveAction('startImageLoading'), takeLatest, soLoadingImagesFlow]));

export { soLoadImagesFragment };
