import { takeLatest } from 'redux-saga/effects';
import { composeFragments, pathFragment, action, flow, resolveAction, state, resolveLogic } from 'core-ingredients';
import { fetchFragment } from 'core-recipes';
import { usersBeforeQueryHandler, usersAfterQueryHandler, setSignersFlow } from './signing-rules-add-user.flow.js';

var signingRulesAddUserLogicSchema = composeFragments(pathFragment('signingRules', 'addUser'), fetchFragment({
  valueKey: 'users',
  valueQuery: {
    users: []
  },
  beforeQueryHandler: usersBeforeQueryHandler,
  afterQueryHandler: usersAfterQueryHandler,
  supplier: 'ibs'
}), action('setSigners'), flow('setSignersFlow', [resolveAction('setSigners'), takeLatest, setSignersFlow]), action('setUsersToAdd', function (usersToAdd) {
  return {
    usersToAdd: usersToAdd
  };
}), state(['usersToAdd', 'setUsersToAdd'], function (_, _ref) {
  var usersToAdd = _ref.usersToAdd;
  return usersToAdd;
}));
var signingRulesAddUserLogicAPI = resolveLogic(signingRulesAddUserLogicSchema);
var signingRulesAddUserLogic = signingRulesAddUserLogicAPI.logic;
var signingRulesAddUserReducer = signingRulesAddUserLogicAPI.reducer;
var signingRulesAddUserSaga = signingRulesAddUserLogicAPI.saga;

export { signingRulesAddUserLogic, signingRulesAddUserLogicAPI, signingRulesAddUserReducer, signingRulesAddUserSaga };
