import { style } from '../../node_modules/styled-system/dist/index.esm.js';

var borderTopLeftRadius = style({
  prop: 'borderTopLeftRadius'
});
var borderTopRightRadius = style({
  prop: 'borderTopRightRadius'
});
var borderBottomLeftRadius = style({
  prop: 'borderBottomLeftRadius'
});
var borderBottomRightRadius = style({
  prop: 'borderBottomRightRadius'
});

export { borderBottomLeftRadius, borderBottomRightRadius, borderTopLeftRadius, borderTopRightRadius };
