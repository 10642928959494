import { composeFragments, validationRule, maxLength, validation } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withDependentFieldValidation } from 'core-recipes';
import { initialDatePickerNextYear } from 'vtb-utils';
import { requiredRule } from '../../utils/validators.js';

var signingRulesStepHomeFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'ruleDescription',
  initialValue: '',
  rules: [validationRule(requiredRule), validationRule(maxLength(50))]
}, withDependentFieldValidation({
  fieldName: '',
  validation: function validation$1(params) {
    return validation('ruleDescriptionEquality', function (value) {
      var _params$values, _params$fields, _params$rules;

      return (params === null || params === void 0 ? void 0 : (_params$values = params.values) === null || _params$values === void 0 ? void 0 : _params$values.ruleDescription) === '' && (params === null || params === void 0 ? void 0 : params.fields) && !(params !== null && params !== void 0 && (_params$fields = params.fields) !== null && _params$fields !== void 0 && _params$fields.ruleDescription.touched) || !(params !== null && params !== void 0 && (_params$rules = params.rules) !== null && _params$rules !== void 0 && _params$rules.some(function (_ref) {
        var ruleDescription = _ref.ruleDescription;
        return (ruleDescription === null || ruleDescription === void 0 ? void 0 : ruleDescription.toLowerCase()) === (value === null || value === void 0 ? void 0 : value.toLowerCase());
      }));
    }, null, 'signingRules.error.unique');
  },
  code: '',
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      rules: selectors.existingRules,
      values: selectors.values
    };
  }
})), fieldFragment({
  name: 'period',
  initialValue: initialDatePickerNextYear
})));

export { signingRulesStepHomeFieldsFragment };
