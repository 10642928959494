import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { logic2, composeFragments, pathFragment, action, state, sagaifyLogic2 } from 'core-ingredients';
import { withFieldsSelectors, withInitializeForm, withResetForm } from 'core-recipes';
import { movementsFilterFragment } from './movements-filter.fragment.js';
import { loadAccountsFragment } from './load-accounts.fragment.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var movemenetsFilterLogic = logic2(composeFragments(pathFragment('movements', 'filter'), loadAccountsFragment(), movementsFilterFragment(), withFieldsSelectors, withInitializeForm({}), withResetForm({}), action('preserveQuery', function (query) {
  return {
    query: query
  };
}), state(['preservedQuery', 'preserveQuery'], function (_, _ref) {
  var query = _ref.query;
  return query;
}), action('updateDateInput', function (datePickerValue) {
  return {
    datePickerValue: datePickerValue
  };
}), state(['dates', 'updateDateInput'], function (dates, _ref2) {
  var datePickerValue = _ref2.datePickerValue;
  return _objectSpread(_objectSpread({}, dates), {}, {
    value: datePickerValue
  });
})));
var movemenetsFilterReducer = movemenetsFilterLogic.reducer;
var movemenetsFilterSaga = sagaifyLogic2(movemenetsFilterLogic);

export { movemenetsFilterLogic, movemenetsFilterReducer, movemenetsFilterSaga };
