import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { INSTRUCTION_TYPES, ROUTES, INSTRUCTION_STATUSES, BILL_TYPES } from 'vtb-utils';
import { flatten, propEq } from 'ramda';
import { getDetails } from '../transactions/current-orders/current-orders.helpers.js';
import { CANCEL_CREATE_SIGN_RULE, CANCEL_EDIT_SIGN_RULE, CANCEL_DISABLE_SIGN_RULE, CANCEL_ENABLE_SIGN_RULE, CANCEL_DELETE_SIGN_RULE, CANCEL_REQ_CUR_EX, DELETE_AUTHORIZE_MESSAGE, SET_CHANGE_USER, SET_CREATE_USER, SET_INVITE_USER, SET_ENABLE_USER, CANCEL_DELETE_USER, DIRECT_DEBITS, STANDING_ORDERS, MOBILE_TOP_UP_CERT, MOBILE_TOP_UP_NON_CERT, BILL_PAYMENT, CURRENCY_EXCHANGE_CERT, CURRENCY_EXCHANGE, BETWEEN_OWN_ACCOUNT_CERT, BETWEEN_OWN_ACCOUNT, TREASURY_TRANSFER, TO_SOMEONE_ELSE, BATCH_PAYMENT, DELETE, CREATE_CUR_EX, CANCEL_CUR_EX, CREATE_CUR_EX_CERT, CANCEL_CUR_EX_CERT, CREATE_REQ_CUR_EX } from '../permissions/rights.js';

var _instructionTypeSubCa, _nonFinInstructionTyp, _userInviteSubCategor, _individualRateSubCat;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var eventTypes = {
  SIGNING_PROCEDURES: 'SIGNING_PROCEDURES'
};
var subTypesMenuSections = {
  INDIVIDUAL_RATE: {
    ALL: 'All',
    NONE: 'None'
  },
  FINANCIAL: {
    ALL: 'All',
    NONE: 'None',
    TRANSFERS: 'Transfers',
    CURRENCY_EXCHANGE: 'CurrencyExchange',
    OTHER: 'Other'
  },
  NON_FINANCIAL: {
    ALL: 'All',
    SIGNING_RULES: 'SigningRules',
    USER_MANAGEMENT: 'UsersManagement',
    NONE: 'None',
    MESSAGES: 'Messages',
    STANDING_ORDERS: 'StandingOrders',
    DIRECT_DEBITS: 'DirectDebitCert'
  },
  USER_INVITATION: {
    ALL: 'All',
    NONE: 'None'
  }
};
var instructionTypeSubCategories = (_instructionTypeSubCa = {}, _defineProperty(_instructionTypeSubCa, subTypesMenuSections.FINANCIAL.ALL, [INSTRUCTION_TYPES.batchPayment, INSTRUCTION_TYPES.billPaymentCert, INSTRUCTION_TYPES.billPayment, INSTRUCTION_TYPES.mobileTopUpCert, INSTRUCTION_TYPES.mobileTopUp, INSTRUCTION_TYPES.toSomeoneElseSWIFT, // paymentInterBankFcy
INSTRUCTION_TYPES.toSomeoneElseDomestic, // paymentInterBankLcy
INSTRUCTION_TYPES.toSomeoneElseIntraBank, // paymentIntraBank
INSTRUCTION_TYPES.treasuryTransfer, // paymentTreasury
INSTRUCTION_TYPES.paymentTreasuryCert, INSTRUCTION_TYPES.currencyExchange, // paymentCurrencyExchange,
INSTRUCTION_TYPES.currencyExchangeCert, // paymentCurrencyExchangeCert,
INSTRUCTION_TYPES.requestCurrencyExchange]), _defineProperty(_instructionTypeSubCa, subTypesMenuSections.FINANCIAL.NONE, []), _defineProperty(_instructionTypeSubCa, subTypesMenuSections.FINANCIAL.TRANSFERS, [INSTRUCTION_TYPES.batchPayment, INSTRUCTION_TYPES.billPaymentCert, INSTRUCTION_TYPES.billPayment, INSTRUCTION_TYPES.mobileTopUpCert, INSTRUCTION_TYPES.mobileTopUp, INSTRUCTION_TYPES.toSomeoneElseSWIFT, // paymentInterBankFcy
INSTRUCTION_TYPES.toSomeoneElseDomestic, // paymentInterBankLcy
INSTRUCTION_TYPES.toSomeoneElseIntraBank, // paymentIntraBank
INSTRUCTION_TYPES.treasuryTransfer, // paymentTreasury
INSTRUCTION_TYPES.paymentTreasuryCert]), _defineProperty(_instructionTypeSubCa, subTypesMenuSections.FINANCIAL.CURRENCY_EXCHANGE, [INSTRUCTION_TYPES.currencyExchange, // paymentCurrencyExchange,
INSTRUCTION_TYPES.currencyExchangeCert, // paymentCurrencyExchangeCert,
INSTRUCTION_TYPES.requestCurrencyExchange]), _defineProperty(_instructionTypeSubCa, subTypesMenuSections.FINANCIAL.OTHER, []), _instructionTypeSubCa);
var nonFinInstructionTypeSubCategories = (_nonFinInstructionTyp = {}, _defineProperty(_nonFinInstructionTyp, subTypesMenuSections.NON_FINANCIAL.ALL, [INSTRUCTION_TYPES.corpSetCreateUser, INSTRUCTION_TYPES.corpSetChangeUser, INSTRUCTION_TYPES.corpSetEnableUser, INSTRUCTION_TYPES.corpSetDeleteUser, INSTRUCTION_TYPES.corpSetCreateSignRule, INSTRUCTION_TYPES.CorpSetDeleteSignRule, INSTRUCTION_TYPES.corpSetEditSignRule, INSTRUCTION_TYPES.CorpSetEnableSignRule, INSTRUCTION_TYPES.CorpSetDisableSignRule, INSTRUCTION_TYPES.CertifiedMessageToBank, INSTRUCTION_TYPES.standingOrder, INSTRUCTION_TYPES.directDebitCert, INSTRUCTION_TYPES.directDebit]), _defineProperty(_nonFinInstructionTyp, subTypesMenuSections.NON_FINANCIAL.SIGNING_RULES, [INSTRUCTION_TYPES.corpSetCreateSignRule, INSTRUCTION_TYPES.CorpSetDeleteSignRule, INSTRUCTION_TYPES.corpSetEditSignRule, INSTRUCTION_TYPES.CorpSetEnableSignRule, INSTRUCTION_TYPES.CorpSetDisableSignRule]), _defineProperty(_nonFinInstructionTyp, subTypesMenuSections.NON_FINANCIAL.USER_MANAGEMENT, [INSTRUCTION_TYPES.corpSetCreateUser, INSTRUCTION_TYPES.corpSetChangeUser, INSTRUCTION_TYPES.corpSetEnableUser, INSTRUCTION_TYPES.corpSetDeleteUser]), _defineProperty(_nonFinInstructionTyp, subTypesMenuSections.NON_FINANCIAL.NONE, []), _defineProperty(_nonFinInstructionTyp, subTypesMenuSections.NON_FINANCIAL.MESSAGES, [INSTRUCTION_TYPES.CertifiedMessageToBank]), _defineProperty(_nonFinInstructionTyp, subTypesMenuSections.NON_FINANCIAL.STANDING_ORDERS, [INSTRUCTION_TYPES.standingOrder]), _defineProperty(_nonFinInstructionTyp, subTypesMenuSections.NON_FINANCIAL.DIRECT_DEBITS, [INSTRUCTION_TYPES.directDebitCert]), _nonFinInstructionTyp);
var userInviteSubCategories = (_userInviteSubCategor = {}, _defineProperty(_userInviteSubCategor, subTypesMenuSections.USER_INVITATION.ALL, [INSTRUCTION_TYPES.corpSetInviteUser]), _defineProperty(_userInviteSubCategor, subTypesMenuSections.USER_INVITATION.NONE, []), _userInviteSubCategor);
var individualRateSubCategories = (_individualRateSubCat = {}, _defineProperty(_individualRateSubCat, subTypesMenuSections.INDIVIDUAL_RATE.ALL, [INSTRUCTION_TYPES.requestCurrencyExchange]), _defineProperty(_individualRateSubCat, subTypesMenuSections.INDIVIDUAL_RATE.NONE, []), _individualRateSubCat);
var selectHandler = function selectHandler(selectedItems, item) {
  // TODO do this a global
  var index = selectedItems.indexOf(item);
  var withoutItem = selectedItems.filter(function (compareItem) {
    return compareItem !== item;
  });
  return index === -1 ? [item].concat(_toConsumableArray(selectedItems)) : _toConsumableArray(withoutItem);
};
var FINANCIAL_TYPES = flatten(Object.values(instructionTypeSubCategories));
var CAR_BILL_TYPES = [BILL_TYPES.PARKINGPASS, BILL_TYPES.POLICEFINES, BILL_TYPES.PARKINGFINES];

var getInstructionDetails = function getInstructionDetails(instruction, service, services, language) {
  var _service$billType;

  var billType = service === null || service === void 0 ? void 0 : (_service$billType = service.billType) === null || _service$billType === void 0 ? void 0 : _service$billType.billTypeKey;

  if (billType && CAR_BILL_TYPES.includes(billType) && instruction.instructionItemCount > 1) {
    return 'pay.waitingForAuthorization.billPaymentsBasket.multiplePaymentsFromBasket';
  } else if (instruction.instructionTypeCode === INSTRUCTION_TYPES.directDebitCert && service) {
    var _service$serviceNameL;

    return "".concat(instruction.customerIdentificationNumber, " ").concat(service === null || service === void 0 ? void 0 : (_service$serviceNameL = service.serviceNameLocalizations) === null || _service$serviceNameL === void 0 ? void 0 : _service$serviceNameL[language]);
  } else {
    return getDetails(instruction, services, language);
  }
};

var finInstructionDataModel = function finInstructionDataModel(_ref) {
  var instruction = _ref.instruction,
      _ref$accounts = _ref.accounts,
      accounts = _ref$accounts === void 0 ? [] : _ref$accounts,
      _ref$services = _ref.services,
      services = _ref$services === void 0 ? [] : _ref$services,
      _ref$language = _ref.language,
      language = _ref$language === void 0 ? 'en' : _ref$language;

  var instr = _objectSpread(_objectSpread({}, instruction), instruction.item);

  var service = services.find(propEq('serviceCode', instr.serviceCode));
  return _objectSpread(_objectSpread({}, instr), {}, {
    id: instruction.id,
    to: instr.beneficiaryName,
    currency: instr.currencyId,
    name: instr.beneficiaryName,
    type: instr.instructionTypeCode,
    amount: Number(instr.amount),
    status: instr.statusCode,
    instructionStatusCode: instruction.statusCode,
    batchType: instr.type,
    debitAccount: accounts.find(propEq('entityUniqueId', String(instr.debitAccountId))) || accounts.find(propEq('entityUniqueId', String(instr.creditAccountId))),
    service: service,
    details: getInstructionDetails(instr, service, services, language),
    isSingle: Boolean(instr.instructionItemCount === 1),
    isFinancial: FINANCIAL_TYPES.includes(instr.instructionTypeCode),
    lastUpdateDate: instruction === null || instruction === void 0 ? void 0 : instruction.lastUpdateDate
  });
};
var TPMFilterHandler = function TPMFilterHandler(currentRoute) {
  if (currentRoute === ROUTES.SIGNING_PROCEDURES.PENDING) {
    return {
      statusCode: {
        inlist: [INSTRUCTION_STATUSES.SIGNED, INSTRUCTION_STATUSES.WAITING_BALANCE, INSTRUCTION_STATUSES.VERIFIED, INSTRUCTION_STATUSES.PASSING, INSTRUCTION_STATUSES.PASSED]
      }
    };
  } else if (currentRoute === ROUTES.SIGNING_PROCEDURES.ARCHIVE) {
    return {
      statusCode: {
        inlist: [INSTRUCTION_STATUSES.DONE, INSTRUCTION_STATUSES.CANCELLED, INSTRUCTION_STATUSES.ERROR]
      }
    };
  } else if (currentRoute === ROUTES.MAIN.OVERVIEW) {
    return {
      statusCode: {
        inlist: [INSTRUCTION_STATUSES.CANCELLED, INSTRUCTION_STATUSES.ERROR]
      }
    };
  } else if (currentRoute === ROUTES.MAIN.PAYMENTS) {
    return {
      statusCode: INSTRUCTION_STATUSES.FOR_SIGN,
      mySign: 1
    };
  } else {
    return {
      statusCode: INSTRUCTION_STATUSES.FOR_SIGN,
      mySign: currentRoute === ROUTES.SIGNING_PROCEDURES.WAITING_FOR_ME ? 1 : {
        ne: 1
      }
    };
  }
};
var canCancelSPInstruction = function canCancelSPInstruction(hasRights, instructionType) {
  var hasInstructionTypeRight = true;

  switch (instructionType) {
    case INSTRUCTION_TYPES.batchPayment:
      hasInstructionTypeRight = hasRights(BATCH_PAYMENT(DELETE));
      break;

    case INSTRUCTION_TYPES.toSomeoneElseIntraBank:
    case INSTRUCTION_TYPES.toSomeoneElseDomestic:
    case INSTRUCTION_TYPES.toSomeoneElseSWIFT:
      hasInstructionTypeRight = hasRights(TO_SOMEONE_ELSE(DELETE));
      break;

    case INSTRUCTION_TYPES.treasuryTransfer:
      hasInstructionTypeRight = hasRights(TREASURY_TRANSFER(DELETE));
      break;

    case INSTRUCTION_TYPES.paymentOwnAccount:
      hasInstructionTypeRight = hasRights(BETWEEN_OWN_ACCOUNT(DELETE));
      break;

    case INSTRUCTION_TYPES.paymentOwnAccountCert:
      hasInstructionTypeRight = hasRights(BETWEEN_OWN_ACCOUNT_CERT(DELETE));
      break;

    case INSTRUCTION_TYPES.currencyExchange:
      hasInstructionTypeRight = hasRights(CURRENCY_EXCHANGE(DELETE));
      break;

    case INSTRUCTION_TYPES.currencyExchangeCert:
      hasInstructionTypeRight = hasRights(CURRENCY_EXCHANGE_CERT(DELETE));
      break;

    case INSTRUCTION_TYPES.billPayment:
    case INSTRUCTION_TYPES.billPaymentCert:
      hasInstructionTypeRight = hasRights(BILL_PAYMENT(DELETE));
      break;

    case INSTRUCTION_TYPES.mobileTopUp:
      hasInstructionTypeRight = hasRights(MOBILE_TOP_UP_NON_CERT(DELETE));
      break;

    case INSTRUCTION_TYPES.mobileTopUpCert:
      hasInstructionTypeRight = hasRights(MOBILE_TOP_UP_CERT(DELETE));
      break;

    case INSTRUCTION_TYPES.standingOrder:
      hasInstructionTypeRight = hasRights(STANDING_ORDERS(DELETE));
      break;

    case INSTRUCTION_TYPES.directDebitCert:
      hasInstructionTypeRight = hasRights(DIRECT_DEBITS(DELETE));
      break;

    case INSTRUCTION_TYPES.corpSetDeleteUser:
      hasInstructionTypeRight = hasRights(CANCEL_DELETE_USER);
      break;

    case INSTRUCTION_TYPES.corpSetEnableUser:
      hasInstructionTypeRight = hasRights(SET_ENABLE_USER(DELETE));
      break;

    case INSTRUCTION_TYPES.corpSetInviteUser:
      hasInstructionTypeRight = hasRights(SET_INVITE_USER(DELETE));
      break;

    case INSTRUCTION_TYPES.corpSetCreateUser:
      hasInstructionTypeRight = hasRights(SET_CREATE_USER(DELETE));
      break;

    case INSTRUCTION_TYPES.corpSetChangeUser:
      hasInstructionTypeRight = hasRights(SET_CHANGE_USER(DELETE));
      break;

    case INSTRUCTION_TYPES.CertifiedMessageToBank:
      hasInstructionTypeRight = hasRights(DELETE_AUTHORIZE_MESSAGE);
      break;

    case INSTRUCTION_TYPES.requestCurrencyExchange:
      hasInstructionTypeRight = hasRights(CANCEL_REQ_CUR_EX);
      break;

    case INSTRUCTION_TYPES.CorpSetDeleteSignRule:
      hasInstructionTypeRight = hasRights(CANCEL_DELETE_SIGN_RULE);
      break;

    case INSTRUCTION_TYPES.CorpSetEnableSignRule:
      hasInstructionTypeRight = hasRights(CANCEL_ENABLE_SIGN_RULE);
      break;

    case INSTRUCTION_TYPES.CorpSetDisableSignRule:
      hasInstructionTypeRight = hasRights(CANCEL_DISABLE_SIGN_RULE);
      break;

    case INSTRUCTION_TYPES.corpSetEditSignRule:
      hasInstructionTypeRight = hasRights(CANCEL_EDIT_SIGN_RULE);
      break;

    case INSTRUCTION_TYPES.corpSetCreateSignRule:
      hasInstructionTypeRight = hasRights(CANCEL_CREATE_SIGN_RULE);
      break;
  }

  return hasInstructionTypeRight;
};
var instructionType2Rights = {
  paymentCurrencyExchange: {
    create: CREATE_CUR_EX,
    "delete": CANCEL_CUR_EX
  },
  paymentCurrencyExchangeCert: {
    create: CREATE_CUR_EX_CERT,
    "delete": CANCEL_CUR_EX_CERT
  },
  requestCurrencyExchange: {
    create: CREATE_REQ_CUR_EX,
    "delete": CANCEL_REQ_CUR_EX
  }
};
var getCancelAndRecreateInstructionRights = function getCancelAndRecreateInstructionRights(instructionType, hasRights) {
  var rights = instructionType2Rights[instructionType];

  if (!hasRights || !rights) {
    return {
      canCreate: false,
      canCancel: false
    };
  }

  return {
    canCreate: hasRights(rights.create),
    canCancel: hasRights(rights["delete"])
  };
};
var getArchivedInstrChips = function getArchivedInstrChips(lastSeenArchivedInstructions) {
  return {
    unsuccessfulAuthorizations: lastSeenArchivedInstructions.length,
    unsuccessfulAuthorizationsArray: lastSeenArchivedInstructions,
    display: 'ALL_IN_ONE',
    eventType: eventTypes.SIGNING_PROCEDURES,
    text1: 'pay.waitingForAuthorization.unsuccessfulAuthorizations'
  };
};
var signRulesLimitRows = [{
  label: 'signingRules.transactionLimit',
  key: 'transactionLimit',
  periodId: null
}, {
  label: 'signingRules.dailyLimit',
  key: 'dailyLimit',
  periodId: 101
}, {
  label: 'signingRules.monthlyLimit',
  key: 'monthlyLimit',
  periodId: 301
}];

export { TPMFilterHandler, canCancelSPInstruction, eventTypes, finInstructionDataModel, getArchivedInstrChips, getCancelAndRecreateInstructionRights, individualRateSubCategories, instructionTypeSubCategories, nonFinInstructionTypeSubCategories, selectHandler, signRulesLimitRows, userInviteSubCategories };
