import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { Flex } from '../../components/generic/flex.js';
import { theme } from '../../theme/index.js';
import { media } from '../../media.js';

var _templateObject, _templateObject2;

var getActivePosition = function getActivePosition(active, mobile) {
  var height = mobile ? theme.space.elementHeight * 1.5 : theme.space.elementHeight * 2;
  var logoHeight = 92;

  if (active === 1) {
    return logoHeight;
  } else if (active === 2) {
    return height + logoHeight;
  } else if (active === 3) {
    return height * 2 + logoHeight;
  } else if (active === 4) {
    return height * 3 + logoHeight;
  } else return null;
};

var ActiveComponent = styled(Flex).attrs(function (_ref) {
  var theme = _ref.theme;
  return {
    linearGradient: [theme.colors.menuActiveFrom, theme.colors.menuActiveTo, -50],
    skew: 5,
    borderTopRightRadius: ['10px'],
    borderBottomRightRadius: ['10px'],
    id: 'menu-active',
    'data-cy': 'menuActive',
    height: ["".concat(theme.space.elementHeight * 1.5, "px"), "".concat(theme.space.elementHeight * 2, "px")]
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  top: ", "px;\n  left: -12px;\n  width: 120%;\n  transition: 0.4s all ease;\n  box-shadow: -8px 16px 23px 0 rgba(0, 98, 149, 0.25), -1px 3px 7px 0 rgba(0, 98, 149, 0.1);\n  &:focus {\n    outline: none;\n  }\n  ", "\n"])), function (_ref2) {
  var active = _ref2.active;
  return getActivePosition(active, false);
}, media.phone(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    top: ", "px;\n  "])), function (_ref3) {
  var active = _ref3.active;
  return getActivePosition(active, true);
}));

export { ActiveComponent };
