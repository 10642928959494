import _slicedToArray from '@babel/runtime/helpers/slicedToArray';
import BigNumber from 'bignumber.js';
import { is } from 'ramda';
import { DECIMAL_SEPARATOR, PHONE_NUMBER_DOMESTIC_PREFIX } from './constants/index.js';
import { parsePhoneNumberFromString } from './node_modules/libphonenumber-js/min/exports/parsePhoneNumberFromString.js';

var EVENT_TYPES = {
  BILL: 'BILL',
  SODD: 'SODD',
  // expiring regular payments
  WAITING: 'WAITING',
  OVERDUE: 'OVERDUE',
  REJECTED: 'REJECTED',
  FINE: 'FINE',
  CARDEXPIRED: 'CARDEXPIRED',
  CALL: 'CALL',
  ARREST: 'ARREST',
  DEPOSIT: 'DEPOSIT',
  COMISSION: 'COMISSION',
  LOANPAYABLE: 'LOANPAYABLE'
};
var numberFormat = function numberFormat(amount) {
  var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var bignumber = new BigNumber(String(Math.abs(amount)));
  BigNumber.config({
    FORMAT: {
      decimalSeparator: DECIMAL_SEPARATOR,
      groupSeparator: ',',
      groupSize: 3
    },
    ROUNDING_MODE: 4
  });
  var value = bignumber.toFormat(decimals, 4);
  var ar = value.split(DECIMAL_SEPARATOR);
  return {
    number: ar[0],
    decimalPart: "".concat(DECIMAL_SEPARATOR).concat(ar[1])
  };
};
var notificationParser = function notificationParser(value, locale) {
  try {
    var parsedValue = JSON.parse(value);
    var dynamicValue = parsedValue.dynamicValue,
        eventType = parsedValue.eventType,
        text1 = parsedValue.text1,
        text2 = parsedValue.text2,
        text3 = parsedValue.text3,
        dateDiff = parsedValue.dateDiff;
    var dynamicValueText = dynamicValue ? dynamicValue + ' ' : '';

    switch (eventType) {
      case EVENT_TYPES.COMISSION:
      case EVENT_TYPES.ARREST:
      case EVENT_TYPES.CALL:
      case EVENT_TYPES.REJECTED:
      case EVENT_TYPES.WAITING:
      case EVENT_TYPES.BILL:
      case EVENT_TYPES.FINE:
      case EVENT_TYPES.OVERDUE:
        {
          return "".concat(dynamicValueText).concat(text1[locale]);
        }

      case EVENT_TYPES.SODD:
      case EVENT_TYPES.CARDEXPIRED:
      case EVENT_TYPES.LOANPAYABLE:
        {
          var text = dateDiff && dateDiff === 0 ? text3[locale] : "".concat(text1 === null || text1 === void 0 ? void 0 : text1[locale], " ").concat(dateDiff, " ").concat(text2 === null || text2 === void 0 ? void 0 : text2[locale]);
          return "".concat(dynamicValueText).concat(text);
        }

      case EVENT_TYPES.DEPOSIT:
        {
          return dateDiff && dateDiff === 0 ? text3[locale] : "".concat(text1 === null || text1 === void 0 ? void 0 : text1[locale], " ").concat(dateDiff, " ").concat(text2 === null || text2 === void 0 ? void 0 : text2[locale]);
        }

      default:
        {
          return '';
        }
    }
  } catch (error) {
    return value;
  }
};
var subjectParser = function subjectParser(text) {
  if (!is(String, text)) {
    return text;
  }

  var prefix = text === null || text === void 0 ? void 0 : text.substring(0, 4);

  if (prefix === '[-] ' || prefix === '[V] ') {
    return text === null || text === void 0 ? void 0 : text.substring(4);
  }

  return text;
};
var subjectResponseHandler = function subjectResponseHandler(text) {
  var prefix = text.substring(0, 4);

  if (prefix !== 'Re ') {
    return "RE: ".concat(text);
  }

  return text;
};
var verificationHandler = function verificationHandler(text) {
  return text === null || text === void 0 ? void 0 : text.includes('[V] ');
};
var bytesToMiBConverter = function bytesToMiBConverter(value) {
  return value / Math.pow(1024, 2);
};
var normalizePhoneNumber = function normalizePhoneNumber(phoneNumber) {
  return (phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.length) === 12 && phoneNumber.startsWith(PHONE_NUMBER_DOMESTIC_PREFIX.slice(1)) ? phoneNumber.slice(3) : phoneNumber;
};
var maskPhoneNumber = function maskPhoneNumber(phoneNumber) {
  var withSingleLeadingDigit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var mask = arguments.length > 2 ? arguments[2] : undefined;
  var threeLeadingDigitsConfig = {
    defaultMask: '$1 *** $3',
    regexp: /^\+?([0-9]{3})(.*)([0-9]{3})$/i
  };
  var singleLeadingDigitConfig = {
    regexp: /^\+?([0-9]{1})(.*)([0-9]{3})$/i,
    defaultMask: '+$1** *** *** $3'
  };
  var config = withSingleLeadingDigit ? singleLeadingDigitConfig : threeLeadingDigitsConfig;
  return phoneNumber ? phoneNumber.replace(config.regexp, mask || config.defaultMask) : '';
};

var splitGeorgianPrefix = function splitGeorgianPrefix(phone) {
  var _ref = (phone === null || phone === void 0 ? void 0 : phone.match(/^(?:00|\+)?995(.*)$/)) || [],
      _ref2 = _slicedToArray(_ref, 2),
      match = _ref2[0],
      mainNumber = _ref2[1];

  return {
    hasGePrefix: Boolean(match),
    mainNumber: mainNumber
  };
};

var parsePhoneNumber = function parsePhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return {
      hasGePrefix: true,
      mainNumber: null
    };
  }

  var _splitGeorgianPrefix = splitGeorgianPrefix(phoneNumber),
      hasGePrefix = _splitGeorgianPrefix.hasGePrefix,
      mainNumber = _splitGeorgianPrefix.mainNumber;

  var parsed = parsePhoneNumberFromString(hasGePrefix ? mainNumber : phoneNumber, 'GE');
  return {
    hasGePrefix: !parsed || hasGePrefix,
    mainNumber: (parsed === null || parsed === void 0 ? void 0 : parsed.formatNational()) || null
  };
};

export { bytesToMiBConverter, maskPhoneNumber, normalizePhoneNumber, notificationParser, numberFormat, parsePhoneNumber, subjectParser, subjectResponseHandler, verificationHandler };
