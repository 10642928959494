import { takeLatest } from 'redux-saga/effects';
import { composeFragments, state, flow, resolveAction, resolveLogic, pathFragment } from 'core-ingredients';
import { withVTBCertificationFragment } from '../../../../common/withVTBCertificationFragment.js';
import { verifyAvailableBalanceFragment } from '../../../../common/helpers.js';
import { instructionIdsFragment } from '../../../../common/instruction-logic.js';
import { soFragment } from '../common/so-formbasic-fragment.js';
import { useTemplateFragment } from '../../../../../generic/statistics/use-template.fragment.js';
import { conditionFields } from '../../../../common/condition-fields.fragment.js';
import { conditionFragment } from '../common/condition.fragment.js';
import { createSoTreasuryFlow } from './so-treasury.flow.js';

var fragment = composeFragments(withVTBCertificationFragment, verifyAvailableBalanceFragment, instructionIdsFragment, useTemplateFragment, conditionFields, state(['certifiable', 'certificationSubmited'], function (_, _ref) {
  var certifiable = _ref.certifiable;
  return certifiable;
}, false), flow('createSo', [resolveAction('initSoForm'), takeLatest, createSoTreasuryFlow]));
var soTreasuryLogicAPI = resolveLogic(composeFragments(pathFragment('so', 'treasury'), fragment, conditionFragment, soFragment));
var soTreasuryLogic = soTreasuryLogicAPI.logic;
var soTreasuryReducer = soTreasuryLogicAPI.reducer;
var soTreasurySaga = soTreasuryLogicAPI.saga;

export { soTreasuryLogic, soTreasuryReducer, soTreasurySaga };
