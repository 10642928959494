import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { theme } from '../../../../theme/index.js';

var _templateObject, _templateObject2, _templateObject3;
var TableView = styled.table(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  margin: 0;\n  padding: 0;\n"])));
TableView.defaultProps = {
  theme: theme
};
var DivView = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  margin: 0;\n  padding: 0;\n"])));
DivView.defaultProps = {
  theme: theme
};
var ListView = styled.ul(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n"])));
ListView.defaultProps = {
  theme: theme
};

export default ListView;
export { DivView, TableView };
