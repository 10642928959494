import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { bpGroupFormFragment } from './bill-payment-group-form.fragment.js';
import { billPaymentGroupFieldsFragment } from './bill-payment-group.fields.js';

var billPaymentGroupFormLogic = logic2(composeFragments(pathFragment('transfers', 'billGroupForm'), billPaymentGroupFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, bpGroupFormFragment));
var billPaymentGroupFormReducer = billPaymentGroupFormLogic.reducer;
var billPaymentGroupFormSaga = sagaifyLogic2(billPaymentGroupFormLogic);

export { billPaymentGroupFormLogic, billPaymentGroupFormReducer, billPaymentGroupFormSaga };
