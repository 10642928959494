import { takeLatest } from 'redux-saga/effects';
import { loadBlocationsFlow, loadWithoutFilterFlow } from './card-blocks.flows.js';
import { loadDataForFilterFlow, pickSelectedOptionFlow } from './card-blocks-filter.flows.js';
import { countTotalsFlow } from './card-blocks-totals.flows.js';

var cardBlocksFragment = function cardBlocksFragment() {
  return {
    actions: {
      loaded: function loaded(list) {
        return {
          list: list
        };
      },
      loadedTotals: function loadedTotals(totals) {
        return {
          totals: totals
        };
      },
      loadDataForFilter: function loadDataForFilter(urlParams) {
        return {
          urlParams: urlParams
        };
      },
      loadedSelectedOption: function loadedSelectedOption(option) {
        return {
          option: option
        };
      },
      loadedDataForFilter: function loadedDataForFilter(items, urlParams) {
        return {
          items: items,
          urlParams: urlParams
        };
      },
      updateFilter: function updateFilter(item) {
        var ids = Array.isArray(item.value) ? item.value : [item.value];
        return {
          ids: ids,
          scope: item.type,
          item: item
        };
      },
      filterLoadingStarted: function filterLoadingStarted() {},
      filterLoadingFinished: function filterLoadingFinished() {},
      loadWithoutFilter: function loadWithoutFilter(params) {
        return {
          cardIds: Array.isArray(params === null || params === void 0 ? void 0 : params.cardIds) ? params.cardIds.map(Number) : params === null || params === void 0 ? void 0 : params.cardIds,
          currencyID: params === null || params === void 0 ? void 0 : params.currencyID
        };
      }
    },
    state: {
      pending: {
        'actions.loaded': function actionsLoaded(_, _ref) {
          var list = _ref.list;
          return list;
        },
        'actions.loadWithoutFilter': function actionsLoadWithoutFilter() {
          return [];
        }
      },
      totals: {
        'actions.loadedTotals': function actionsLoadedTotals(_, _ref2) {
          var totals = _ref2.totals;
          return totals;
        },
        'actions.updateFilter': function actionsUpdateFilter() {
          return null;
        },
        'actions.loadWithoutFilter': function actionsLoadWithoutFilter() {
          return null;
        }
      },
      filterData: {
        'actions.loadedDataForFilter': function actionsLoadedDataForFilter(_, _ref3) {
          var items = _ref3.items;
          return items;
        }
      },
      selectedOption: {
        'actions.loadedSelectedOption': function actionsLoadedSelectedOption(_, _ref4) {
          var option = _ref4.option;
          return option;
        }
      },
      filterLoading: {
        'actions.filterLoadingStarted': function actionsFilterLoadingStarted() {
          return true;
        },
        'actions.filterLoadingFinished': function actionsFilterLoadingFinished() {
          return false;
        }
      },
      lastFilterItem: {
        'actions.updateFilter': function actionsUpdateFilter(_, _ref5) {
          var item = _ref5.item;
          return item;
        }
      },
      totalsCurrency: {
        'actions.loadWithoutFilter': function actionsLoadWithoutFilter(_, _ref6) {
          var currencyID = _ref6.currencyID;
          return currencyID;
        }
      }
    },
    selectors: {
      pending: [['pending']],
      filterData: [['filterData']],
      totals: [['totals']],
      selectedOption: [['selectedOption']],
      lastFilterItem: [['lastFilterItem']]
    },
    flows: {
      loadDataForFilter: ['actions.loadDataForFilter', takeLatest, loadDataForFilterFlow],
      updateFilter: ['actions.updateFilter', takeLatest, loadBlocationsFlow],
      firstLoad: ['actions.loadedDataForFilter', takeLatest, pickSelectedOptionFlow],
      countTotals: ['actions.loaded', takeLatest, countTotalsFlow],
      loadWithoutFilter: ['actions.loadWithoutFilter', takeLatest, loadWithoutFilterFlow]
    },
    initialState: {
      pending: [],
      filterData: [],
      totals: null,
      selectedOption: null,
      filterLoading: true
    }
  };
};

export { cardBlocksFragment };
