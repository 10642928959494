import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { getAllowedCardsFilter } from '../../cards/list/cards.query.js';
import { LOAN_CLOSED_ID } from '../loans.constants.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var getLoanRestrictions = function getLoanRestrictions() {
  return {
    'loanStatus.entityUniqueId': {
      ne: LOAN_CLOSED_ID
    }
  };
};
var loansListQuery = function loansListQuery(currentLang) {
  return {
    Loans: ['loanStatus', 'cbsLoanId', 'loanType', 'customProductIcon', 'friendlyName', 'currency', 'interestRate', 'startDate', 'endDate', 'loanAmount', 'account', {
      productType: ['prodTypeID', 'prodTypeBisCode', {
        prodTypeCategory: ['prodTypeCategoryID', 'text', 'prodTypeGroupID']
      }, {
        text: [currentLang]
      }, {
        productIcon: ['icon']
      }, {
        detailParamsGroup: ['detailParamsGroupID']
      }, {
        repaymentType: ['typeID']
      }]
    }, {
      customProductIcon: ['icon']
    }, {
      currency: ['currencyID']
    }, {
      account: ['cbsID', 'ibanAccount', 'friendlyName', 'accountName', {
        accountType: ['accTypeId', {
          defaultAccountIcon: ['icon']
        }]
      }, {
        accountSubType: ['id']
      }, {
        balance: ['availableBalance', {
          currency: ['currencyID']
        }]
      }]
    }],
    limit: 50,
    order: {
      startDate: 'desc'
    },
    where: getLoanRestrictions()
  };
};
var debtLoanQuery = function debtLoanQuery(cbsLoanId) {
  return {
    loans: ['debt'],
    id: cbsLoanId
  };
};
var accountCardsQuery = function accountCardsQuery(cardIbans, maxExpirationDays) {
  return {
    Cards: ['cbsId', 'friendlyName', 'isMainCard', 'validTo', {
      productType: ['text', 'linkedCardPriority', {
        productIcon: ['icon']
      }]
    }, {
      cardDesign: ['picMedium', 'picSmall']
    }, {
      ibanAccount: ['iban']
    }],
    where: _objectSpread(_objectSpread({}, getAllowedCardsFilter(maxExpirationDays)), {}, {
      'ibanAccount.entityUniqueId': {
        inlist: cardIbans
      }
    }),
    limit: 1000
  };
};

export { accountCardsQuery, debtLoanQuery, getLoanRestrictions, loansListQuery };
