import _extends from '@babel/runtime/helpers/extends';
import { composeFragments, state, selector } from 'core-ingredients';
import { listFragment } from 'core-recipes';
import { areThereAnyChanges } from './validate-cars.js';
import { getCarsQuery } from './cars-query.js';

var entityName = 'CarTemplates';
var listName = 'cars';
var mapSimpleCarEntity = function mapSimpleCarEntity(_ref) {
  var carTemplateId = _ref.carTemplateId,
      hasImage = _ref.hasImage,
      sortOrder = _ref.sortOrder,
      carNumber = _ref.carNumber,
      carTemplateName = _ref.carTemplateName,
      carOwnerIdNumber = _ref.carOwnerIdNumber,
      carTechnicalPassportId = _ref.carTechnicalPassportId,
      ownerIdNumber = _ref.ownerIdNumber;
  return {
    carTemplateName: carTemplateName,
    carTemplateId: carTemplateId,
    hasImage: hasImage,
    carOwnerIdNumber: carOwnerIdNumber,
    sortOrder: sortOrder,
    carNumber: carNumber,
    carTechnicalPassportId: carTechnicalPassportId,
    ownerIdNumber: ownerIdNumber,
    newCarTemplateIcon: undefined
  };
};

var carsForEditReducer = function carsForEditReducer(_, _ref2) {
  var response = _extends({}, _ref2);

  return response[listName][entityName].map(mapSimpleCarEntity);
};

var carsFragment = composeFragments(listFragment({
  entityName: entityName,
  listName: listName,
  listQuery: getCarsQuery()
}), state(['carsToEdit', 'setCarsToEdit'], function (_, _ref3) {
  var items = _ref3.items;
  return items;
}, []), state(['carsToEdit', 'carsDataLoaded'], carsForEditReducer), selector('loading', ['carsLoading'], function (carsLoading) {
  return carsLoading;
}), selector('isListChanged', ['carsToEdit', 'cars'], function (carsToEdit, cars) {
  return cars.some(areThereAnyChanges(carsToEdit, 'sortOrder'));
}));

export { carsFragment, mapSimpleCarEntity };
