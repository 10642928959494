import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { listFragment, configResetListFragment } from 'core-recipes';
import { composeFragments, state, stateFlag, action, selector, flow, resolveAction } from 'core-ingredients';
import { takeLatest } from 'redux-saga/effects';
import { identity } from 'ramda';
import { USER_SETTINGS_REALMS } from 'vtb-utils';
import { accountsSettingsQuery } from '../queries/accounts-settings.query.js';
import { accountsSettingsFlow, sortAccountGroupList } from '../flows/accounts-settings-flow.js';
import { saveAccountsSortingFlow, saveSavingsSortingFlow } from '../flows/save-sorting.flow.js';
import { addHiddenAccountFlow } from '../flows/add-hidden-account.flow.js';
import { toggleHiddenLoansSavingsFlow } from '../flows/toggle-hidden-loans-and-savings.flow.js';
import { setMainAccountFlow } from '../flows/set-main-account.flow.js';
import { accountPriorityCurrencyChangeFlow } from '../flows/account-priority-currency-change-flow.js';
import { changeProductIconFlow } from '../flows/change-product-icon-flow.js';
import { userLogic } from '../../../../generic/user/user.logic.js';
import { systemParamSelectorFactory } from '../../../../app/systemParamSelectorFactory.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var accountsSettingsFragment = function accountsSettingsFragment() {
  return composeFragments(listFragment({
    listName: 'accountGroups',
    entityName: 'accountGroups',
    // <= Accounts
    listQuery: _objectSpread({}, accountsSettingsQuery()),
    afterQueryHandler: accountsSettingsFlow,
    loadedAction: identity,
    loadedHandler: function loadedHandler(_, _ref) {
      var Accounts = _ref.Accounts;
      return Accounts;
    },
    flows: {
      addHiddenAccount: ['actions.addHiddenAccount', takeLatest, addHiddenAccountFlow],
      addHiddenLoan: ['actions.addHiddenLoan', takeLatest, toggleHiddenLoansSavingsFlow],
      addHiddenSaving: ['actions.addHiddenSaving', takeLatest, toggleHiddenLoansSavingsFlow],
      setMainAccount: ['actions.setMainAccount', takeLatest, setMainAccountFlow],
      changeProrityCurrency: ['actions.changeProrityCurrency', takeLatest, accountPriorityCurrencyChangeFlow],
      changeProductIcon: ['actions.changeProductIcon', takeLatest, changeProductIconFlow]
    }
  }, configResetListFragment({})), state(['savedSort', 'saveSorting'], function (_, _ref2) {
    var accountGroups = _ref2.accountGroups;
    return accountGroups;
  }), stateFlag(['cardsLoading'], ['accountGroupsDataLoaded'], ['cardsLoaded']), state(['hiddenAccount', 'loadHiddenAccounts'], function (_, _ref3) {
    var hiddenAccount = _ref3.hiddenAccount;
    return hiddenAccount;
  }), state(['accountGroups', 'cardsLoaded'], function (_, _ref4) {
    var accountGroups = _ref4.accountGroups;
    return accountGroups;
  }), state(['sorting', 'loadSorting'], function (_, _ref5) {
    var sorted = _ref5.sorted;
    return sorted;
  }), state(['currencyData', 'openCurrencyModal'], function (_, _ref6) {
    var currencyData = _ref6.currencyData;
    return currencyData;
  }), action('saveAccountsSorting', identity), action('saveSavingsSorting', identity), action('saveSorting', function (data) {
    return {
      data: data
    };
  }), action('openCurrencyModal', function (currencyData) {
    return {
      currencyData: currencyData
    };
  }), action('savingsSorting', function (data) {
    return {
      data: data
    };
  }), action('addHiddenAccount', function (iban) {
    return {
      iban: iban
    };
  }), action('addHiddenLoan', function (cbsLoanId) {
    return {
      id: cbsLoanId,
      key: 'loanVisibility'
    };
  }), action('addHiddenSaving', function (cbsSavingId) {
    return {
      id: cbsSavingId,
      key: 'savingVisibility'
    };
  }), action('setMainAccount', function (accInfo) {
    return {
      accInfo: accInfo
    };
  }), action('changeProrityCurrency', function (accountNumber, priorityCurrency) {
    return {
      accountNumber: accountNumber,
      priorityCurrency: priorityCurrency
    };
  }), action('changeProductIcon', function (id, customProductIcon, entityType) {
    return {
      id: id,
      customProductIcon: customProductIcon,
      entityType: entityType
    };
  }), stateFlag('currencyModalState', ['openCurrencyModal'], ['closeCurrencyModal', 'priorityCurrecyChangeSuccess'], false), stateFlag(['priorCurrencyErr'], ['priorityCurrecyChangeError'], ['reset']), stateFlag(['changeCurrencyRunning'], ['changeProrityCurrency'], ['openCurrencyModal', 'priorityCurrecyChangeSuccess', 'priorityCurrecyChangeError', 'reset']), stateFlag(['cardsLoadedResult'], ['cardsLoadedSuccess'], ['priorityCurrecyChangeError']), stateFlag(['changeIconResult'], ['productIconSuccess'], ['productIconError', 'productIconInit']), selector('loadingAllEntities', ['accountGroupsLoading', 'exchangeRatesLoading', 'loansSettingsLoading', 'productIconsLoading', 'savingsLoading'], function (accountGroupsLoading, exchangeRatesLoading, loansSettingsLoading, productIconsLoading, savingsLoading) {
    return accountGroupsLoading || exchangeRatesLoading || loansSettingsLoading || productIconsLoading || savingsLoading;
  }), selector('loadingEntitiesFailed', ['accountGroupsLoadFailed', 'exchangeRatesLoadFailed', 'loansSettingsLoadFailed', 'productIconsLoadFailed', 'savingsLoadFailed'], function (accountGroupsLoadFailed, exchangeRatesLoadFailed, loansSettingsLoadFailed, productIconsLoadFailed, savingsLoadFailed) {
    return accountGroupsLoadFailed || !exchangeRatesLoadFailed || loansSettingsLoadFailed || productIconsLoadFailed || savingsLoadFailed;
  }), selector('groupedAccounts', ['accountGroups', 'accountGroupsLoadFailed', userLogic.selectors.clientUserSettings], function (accountGroups, accountGroupsLoadFailed, clientUserSettings) {
    if (accountGroupsLoadFailed || !Array.isArray(accountGroups)) {
      return [];
    }

    return (accountGroups || []).map(function (group) {
      var _clientUserSettings$U;

      return _objectSpread(_objectSpread({}, group), {}, {
        accounts: sortAccountGroupList(group.accounts || [], clientUserSettings === null || clientUserSettings === void 0 ? void 0 : (_clientUserSettings$U = clientUserSettings[USER_SETTINGS_REALMS.ACCOUNT_SORTING]) === null || _clientUserSettings$U === void 0 ? void 0 : _clientUserSettings$U[group.id])
      });
    });
  }), selector('updateTime', [systemParamSelectorFactory('MCH_PRIORITY_CURRENCY_UPDATETIME')], function (updateTime) {
    return updateTime;
  }), flow('saveAccountsSortingFlow', [resolveAction('saveAccountsSorting'), takeLatest, saveAccountsSortingFlow]), flow('saveSavingsSortingFlow', [resolveAction('saveSavingsSorting'), takeLatest, saveSavingsSortingFlow]));
};

export { accountsSettingsFragment };
