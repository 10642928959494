import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { searchProviderSelectBoxOptions } from '../../bill-payment/wizard/selectors.js';
import { carsSearchFieldsFragment } from './bill-payment-search-fields.js';
import { carsWizardFragment } from './cars-wizard.fragment.js';

var carsWizardLogic = logic2(composeFragments(pathFragment('cars', 'wizard'), carsSearchFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, carsWizardFragment, searchProviderSelectBoxOptions));
var CarsWizardLogicReducer = carsWizardLogic.reducer;
var CarsWizardLogicSaga = sagaifyLogic2(carsWizardLogic);

export { CarsWizardLogicReducer, CarsWizardLogicSaga, carsWizardLogic };
