import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeLatest } from 'redux-saga/effects';
import { identity } from 'ramda';
import { composeFragments, pathFragment, action, state, stateFlag, selector, flow, resolveAction, resolveLogic } from 'core-ingredients';
import { listFragment } from 'core-recipes';
import { usersCreateFieldsFragment } from './users-create.fields.js';
import { loadRightProfilesFlow, initializeFlow, processInstructionFlow, loadUserChannelsFlow, loadRulesCountsForRolesFlow, loadRightProfileDefinitions } from './users-create.flow.js';
import { defaultUserChannels, USER_DETAIL_TYPE } from './users.helpers.js';
import { rolesManagementFragment } from './roles-management.fragment.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var createUserLogicSchema = composeFragments(pathFragment('corporateUsers', 'createUser'), usersCreateFieldsFragment, rolesManagementFragment, listFragment({
  listName: 'accountTypes',
  entityName: 'AccountTypes',
  listQuery: {
    AccountTypes: [],
    limit: 1000
  }
}), action('updateCertificationStatus'), action('loadUserChannels'), action('loadRulesCountForRoles'), action('initializeForm', identity), action('initialize', identity), action('setRightProfile', identity), action('setChannel', identity), action('success'), action('error'), action('certificationSubmited'), action('processInstruction'), action('setAllChannels', identity), action('setInstructionTypeAndUser', identity), action('setPersonalIdUniqueness', identity), action('setDigiPass', identity), action('setOtpDevice', identity), action('setProfileAccounts', identity), state(['instructionTypeAndUser', 'setInstructionTypeAndUser'], function (_, _ref) {
  var instructionType = _ref.instructionType,
      user = _ref.user;
  return {
    instructionType: instructionType,
    user: user
  };
}, null), state(['isPersonalIdUnique', 'setPersonalIdUniqueness'], function (_, _ref2) {
  var isUnique = _ref2.isUnique;
  return isUnique;
}, null), state(['personalIdMessage', 'setPersonalIdUniqueness'], function (_, _ref3) {
  var message = _ref3.message;
  return message;
}, ''), state(['rightProfiles', 'rightProfilesLoaded'], function (_, _ref4) {
  var rightProfiles = _ref4.rightProfiles;
  return rightProfiles;
}, null), state(['selectedRightProfile', 'setRightProfile'], function (_, _ref5) {
  var rightProfile = _ref5.rightProfile;
  return rightProfile;
}, null), state(['userChannels', 'setAllChannels'], function (_, _ref6) {
  var channels = _ref6.channels;
  return channels;
}, defaultUserChannels), state(['userChannels', 'setChannel'], function (prevChannels, _ref7) {
  var channel = _ref7.channel;
  return _objectSpread(_objectSpread({}, prevChannels), channel);
}, defaultUserChannels), state(['userChannels', 'loadUserChannels'], function () {
  return null;
}, defaultUserChannels), state(['otpDevice', 'setOtpDevice'], function (_, _ref8) {
  var otpDevice = _ref8.otpDevice;
  return otpDevice;
}, null), state(['otpDevice', 'setDigiPass'], function (otpDevice, _ref9) {
  var serialNumber = _ref9.serialNumber;
  return _objectSpread(_objectSpread({}, otpDevice), {}, {
    newSerialNumber: serialNumber
  });
}, null), state(['profilesAccounts', 'setProfileAccounts'], function (oldAccounts, _ref10) {
  var rightProfileId = _ref10.rightProfileId,
      allowedAccounts = _ref10.allowedAccounts;
  return _objectSpread(_objectSpread({}, oldAccounts), {}, _defineProperty({}, rightProfileId, allowedAccounts));
}, {}), stateFlag('isCertifyShown', ['certificationSubmited'], ['initialize'], false), stateFlag('isProcessing', ['processInstruction'], ['initialize'], false), stateFlag('hasError', ['error'], ['initialize'], false), stateFlag('isSuccess', ['success'], ['initialize'], false), stateFlag('rightProfilesLoading', ['loadRightProfiles'], ['rightProfilesLoaded'], false), selector('digiPass', ['otpDevice', 'instructionTypeAndUser'], function (otpDevice, instructionTypeAndUser) {
  if (!instructionTypeAndUser || !otpDevice || otpDevice.newSerialNumber === null) {
    return null;
  }

  var instructionType = instructionTypeAndUser.instructionType,
      user = instructionTypeAndUser.user;
  var serialNumber = otpDevice.serialNumber,
      otpDeviceTypeId = otpDevice.otpDeviceTypeId,
      id = otpDevice.id,
      newSerialNumber = otpDevice.newSerialNumber;
  var isBankUser = instructionType === USER_DETAIL_TYPE.INVITE || !(user !== null && user !== void 0 && user.isLocal);
  var isNotHardwareDigiPass = Number(otpDeviceTypeId) !== 2;
  var isReadOnly = newSerialNumber ? false : isNotHardwareDigiPass || isBankUser && id;
  return {
    value: serialNumber || newSerialNumber,
    isReadOnly: isReadOnly
  };
}), flow('loadRightProfilesFlow', [resolveAction('loadRightProfiles'), takeLatest, loadRightProfilesFlow]), flow('initializeFlow', [resolveAction('initialize'), takeLatest, initializeFlow]), flow('processInstructionFlow', [resolveAction('processInstruction'), takeLatest, processInstructionFlow]), flow('loadUserChannelsFlow', [resolveAction('loadUserChannels'), takeLatest, loadUserChannelsFlow]), flow('loadRulesCountForRolesFlow', [resolveAction('loadRulesCountForRoles'), takeLatest, loadRulesCountsForRolesFlow]), flow('loadRightProfileDefinitions', [resolveAction('setRightProfile'), takeLatest, loadRightProfileDefinitions]));
var createUserLogicAPI = resolveLogic(createUserLogicSchema);
var createUserLogic = createUserLogicAPI.logic;
var createUserReducer = createUserLogicAPI.reducer;
var createUserSaga = createUserLogicAPI.saga;

export { createUserLogic, createUserReducer, createUserSaga };
