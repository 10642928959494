import { composeFragments, pathFragment, resolveLogic } from 'core-ingredients';
import { digipassOtpFragment } from '../digipass-otp.fragment.js';
import { digipassPinFragment } from '../digipass-pin.fragment.js';
import { activationProcessingFragment } from './activation.fragment.js';
import { nativeActivationProcessingFragment } from './native-activation.fragment.js';

var activationSchema = composeFragments(pathFragment('digipass', 'activation'), activationProcessingFragment());
var nativeActivationSchema = composeFragments(pathFragment('digipass', 'activation'), nativeActivationProcessingFragment(), digipassOtpFragment(), digipassPinFragment());
var isNative = navigator && navigator.product === 'ReactNative';
var digipassActivationSchema = isNative ? nativeActivationSchema : activationSchema;

var _resolveLogic = resolveLogic(digipassActivationSchema),
    logic = _resolveLogic.logic,
    reducer = _resolveLogic.reducer,
    saga = _resolveLogic.saga;

export { logic as digipassActivationLogic, reducer as digipassActivationReducer, saga as digipassActivationSaga };
