import { takeLatest } from 'redux-saga/effects';
import { logic2, composeFragments, pathFragment, action, stateFlag, state, selector, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { enrichDataWithService, isCardOrCurrentGELAccount, ACCOUNT_VISIBILITY } from 'vtb-utils';
import { wizardFragment } from '../../../../generic/wizard.fragment.js';
import { TRANSFERS_STEPS } from '../../../constants.js';
import { transferFragment } from '../../../common/transfer/transfer.fragment.js';
import { providerFragment } from '../../../common/provider/provider.fragment.js';
import { templateIconFragment } from '../../../templates/templates-icon-fragment.js';
import { billPaymentFragment } from '../../../bill-payment/wizard/bill-payment.fragment.js';
import { bpCheckIdFlow } from '../../../bill-payment/form/flows/bill-payment-check-id-flow.js';
import { userHasNoAccountsSelector } from '../../../common/selectors.js';
import { bpRecalculateCommissionFee } from '../../../bill-payment/form/flows/bp-recalculate-fee.flow.js';
import { instructionIdsFragment } from '../../../common/instruction-logic.js';
import { moveInstructionToEditFragment } from '../../../../tpm/moveInstructionToEditFragment.js';
import { conditionFields } from '../../../common/condition-fields.fragment.js';
import { conditionFragment } from '../../standing-orders/transfers/common/condition.fragment.js';
import { decidePaymentWithoutBalanceCheckFragment } from '../../../common/decidePaymentWithoutBalanceCheckFragment.js';
import { sharedAccountsFragment } from '../../../common/shared-accounts/shared-accounts.fragment.js';
import { mapSharedAccountsFlow } from '../../../common/shared-accounts/map-shared-accounts.js';
import { paymentWithGetDebtIsSuccessfulFragment } from '../../../common/paymentWithGetDebtIsSuccessfulFragment.js';
import { directDebitFieldsFragment } from './dd.form.fields.js';
import { processDirectDebitFlow, GMBPrepareCertificationFlow } from './dd.form.flows.js';
import { debtAndCommissionFragment } from '../../../common/debt-and-commision/debt-and-commission-fragment.js';

var directDebitFormLogic = logic2(composeFragments(pathFragment('dd', 'form'), decidePaymentWithoutBalanceCheckFragment, directDebitFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, sharedAccountsFragment({
  listName: 'debitAccounts',
  afterQueryHandler: mapSharedAccountsFlow,
  filterCondition: isCardOrCurrentGELAccount,
  visibilityKey: ACCOUNT_VISIBILITY.BILL_PAYMENT_INDEBIT,
  instructionTypes: ['T:DirectDebitCert']
}), userHasNoAccountsSelector, templateIconFragment, billPaymentFragment, // loads and process categories
providerFragment, debtAndCommissionFragment, instructionIdsFragment, moveInstructionToEditFragment, paymentWithGetDebtIsSuccessfulFragment, wizardFragment(TRANSFERS_STEPS), transferFragment({}), conditionFields, conditionFragment, action('submit'), action('initialized'), action('initializeForm'), action('recalculateFee'), action('verifyInstruction'), stateFlag('isSubmittedFirstPart', ['checkId'], ['failed', 'reset', 'resetForm', 'resetCheckId'], false), stateFlag('isResetCustomerCodeForbidden', ['forbidResetCustomerCode'], ['allowResetCustomerCode'], false), stateFlag('isIdChecked', ['checked'], ['reset', 'resetForm', 'resetCheckId'], false), stateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm'], false), stateFlag('loading', ['initializeForm'], ['initialized'], false), stateFlag('submitting', ['calling', 'verifyingAvailableBalance'], ['tmpSuccess', 'tmpFailed', 'failed', 'checked', 'debtLoadError', 'resetCheckId', 'certificationSubmited', 'updateCertificationStatus', 'moveInstructionToEdit', 'reset', 'resetForm'], false), stateFlag('certifying', ['GMBPrepareCertification'], ['failed', 'reset', 'resetForm', 'moveInstructionToEdit', 'stop'], false), state(['certifiable', 'certificationSubmited'], function (_, _ref) {
  var certifiable = _ref.certifiable;
  return certifiable;
}, false), state(['shouldCertify', 'shouldCertifyInstruction'], function (_, _ref2) {
  var shouldCertify = _ref2.shouldCertify;
  return shouldCertify;
}, false), state(['instructionType', 'setInstructionType'], function (_, _ref3) {
  var value = _ref3.value;
  return value;
}, 'paymentInterBankLcy'), selector('providerServices', ['services', 'provider'], function (services, provider) {
  var data = [];
  var currentProvider = provider && provider.value && provider.value.value;

  if (Array.isArray(services) && services.length > 0 && currentProvider) {
    services.filter(function (service) {
      return service.provider.providerCode === currentProvider.providerCode;
    }).forEach(enrichDataWithService(data, null, undefined));
  }

  return data;
}), flow('checkId', [resolveAction('checkId'), takeLatest, bpCheckIdFlow]), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, processDirectDebitFlow]), flow('GMBPrepareCertification', [resolveAction('GMBPrepareCertification'), takeLatest, GMBPrepareCertificationFlow]), flow('recalculateFee', [resolveAction('recalculateFee'), takeLatest, bpRecalculateCommissionFee])));
var directDebitFormReducer = directDebitFormLogic.reducer;
var directDebitFormSaga = sagaifyLogic2(directDebitFormLogic);

export { directDebitFormLogic, directDebitFormReducer, directDebitFormSaga };
