var accountsSettingsQuery = function accountsSettingsQuery() {
  var q = {
    Accounts: ['cbsID', 'friendlyName', 'accountName', 'ibanAccount', 'typeName', 'govRestrictionSeqType', 'govRestrictionIncType', {
      balance: [{
        'currency': ['currencyID']
      }, 'overdraftAmount', 'availableBalance', 'nonAuthorizedOverdraftAmount']
    }, {
      accountSubType: ['text', 'externalId', 'id']
    }, {
      accountType: ['accTypeId', 'status', 'text', {
        'defaultAccountIcon': ['icon']
      }]
    }, {
      currency: ['currencyID']
    }, {
      status: ['statusId', 'text', 'activeStatusId', 'hostStatusCode']
    }, {
      customProductIcon: ['icon']
    }, {
      multicurrencyAccount: ['iban']
    }],
    where: {
      'status.entityUniqueId': {
        inlist: ['Open', 'CreditOnly', 'BudgetOnly', 'Frozen']
      },
      'accountType.entityUniqueId': {
        inlist: ['100', '200', '32']
      }
    },
    limit: 1000
  };
  return q;
};

export { accountsSettingsQuery };
