import { withDefaultProps, Tooltip as Tooltip$1 } from 'core-ui';
import { TooltipContentView } from './view/TooltipContentView.js';
import TooltipView from './view/TooltipView.js';
import TooltipWrapperView from './view/TooltipWrapperView.js';

var Tooltip = withDefaultProps({
  RenderComponent: TooltipView,
  components: {
    tooltipWrapper: TooltipWrapperView,
    tooltipContent: TooltipContentView
  }
}, Tooltip$1);

export { Tooltip };
