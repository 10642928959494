import { find, propEq } from 'ramda';
import { numberFormat } from 'vtb-utils';

var WRONG_ACCOUNT_STATUSES = ['Frozen', 'CreditOnly', 'BudgetOnly'];

var hasRestriction = function hasRestriction(restriction) {
  return Boolean(restriction && restriction !== null);
};

var getAccountWithEvaluateRestrictions = function getAccountWithEvaluateRestrictions(softCollection, totalDebt) {
  return function (_ref) {
    var ibanAccount = _ref.ibanAccount,
        entityUniqueId = _ref.entityUniqueId,
        govRestrictionSeqType = _ref.govRestrictionSeqType,
        govRestrictionIncType = _ref.govRestrictionIncType,
        status = _ref.status,
        balance = _ref.balance;
    var parsedTotalDebt = Number(totalDebt) || 0;
    var hasTotalDebt = parsedTotalDebt > 0;
    var totalDebtFormated = null;

    if (hasTotalDebt) {
      var formatedDebt = numberFormat(parsedTotalDebt, 2);
      totalDebtFormated = "".concat(formatedDebt.number).concat(formatedDebt.decimalPart);
    }

    var account = {
      ibanAccount: ibanAccount,
      id: Number(entityUniqueId),
      govRestrictionSeqType: govRestrictionSeqType,
      govRestrictionIncType: govRestrictionIncType,
      status: status && status.hostStatusCode,
      statusTranslation: status === null || status === void 0 ? void 0 : status.text,
      nonAuthorizedOverdraftAmount: balance.nonAuthorizedOverdraftAmount,
      softCollection: softCollection,
      retailComission: false,
      hasGovRestrictionSeq: false,
      hasRestrictionIncType: false,
      hasNonAuthorisedOverdraft: false,
      hasWrongStatus: false,
      hasRetailComission: false,
      hasRestriction: false,
      totalDebt: totalDebtFormated,
      hasTotalDebt: hasTotalDebt
    };
    account.hasGovRestrictionSeq = hasRestriction(account.govRestrictionSeqType);
    account.hasRestrictionIncType = hasRestriction(account.govRestrictionIncType);
    account.hasNonAuthorisedOverdraft = account.nonAuthorizedOverdraftAmount > 0;
    account.hasWrongStatus = WRONG_ACCOUNT_STATUSES.indexOf(account.status) > -1;
    account.hasRetailComission = account.retailComission;
    account.hasRestriction = account.hasGovRestrictionSeq === true || account.hasRestrictionIncType === true || account.hasNonAuthorisedOverdraft === true || account.hasWrongStatus === true || account.hasRetailComission === true || account.softCollection === true || account.hasTotalDebt === true;
    return account;
  };
};
var getAdditionalDataAndRestrictions = function getAdditionalDataAndRestrictions(account, additionalData) {
  var _ref2 = additionalData || {},
      accountDataList = _ref2.accountDataList,
      hasSoftCollection = _ref2.hasSoftCollection,
      totalDebt = _ref2.totalDebt;

  var additData = find(propEq(['accountId'], Number(account.entityUniqueId)))(accountDataList || []);
  var accountWithRestrictions = getAccountWithEvaluateRestrictions(hasSoftCollection, totalDebt)(account);
  return {
    visibility: additData || {},
    accountWithRestrictions: accountWithRestrictions
  };
};

export { WRONG_ACCOUNT_STATUSES, getAccountWithEvaluateRestrictions, getAdditionalDataAndRestrictions };
