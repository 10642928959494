import { takeEvery } from 'redux-saga/effects';
import { composeFragments, action, state, flow, resolveAction } from 'core-ingredients';
import { identity } from 'ramda';
import { loadVideochatUrl, redirectToUrl } from './videochat.flow.js';

var videochatFragment = function videochatFragment() {
  return composeFragments(action('setUrl', function (url) {
    return {
      url: url
    };
  }), action('loadUrl', identity), action('redirectToUrl', identity), state(['videochatUrl', 'setUrl'], function (_, _ref) {
    var url = _ref.url;
    return url;
  }, null), flow('loadUrl', [resolveAction('loadUrl'), takeEvery, loadVideochatUrl]), flow('redirectToUrl', [resolveAction('redirectToUrl'), takeEvery, redirectToUrl]));
};

export { videochatFragment };
