export const gaasInterceptor = {
  name: 'gaas',
  enter: (operation) => {
    const { query, command } = operation
    const request = query || command
    const { authentication, path, url } = request

    const headers = {
      authorization: `Bearer ${authentication}`,
    }
    const requestUrl = url || path

    if (query) {
      const { data, ...requestParams } = query

      return {
        ...operation,
        request: {
          method: query ? 'GET' : 'POST',
          headers,
          url: requestUrl,
          body: data,
          ...requestParams,
        },
      }
    }

    if (command) {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        name, method = 'POST', authentication, url, locale, ...commandParams
      } = command

      const request = {
        method: operation.method || method,
        url: `${requestUrl}`,
        body: commandParams,
        headers,
      }

      return {
        ...operation,
        request,
      }
    }
  },
  leave: (operation) => {
    const { response } = operation
    const data = (response && response.body) || {}
    return {
      ...operation,
      data,
    }
  },
}
