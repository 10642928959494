import { pipe, filter, map, join } from 'ramda';
import { createPopper } from '../../../node_modules/@popperjs/core/lib/popper.js';

var defaultModifiers = [{
  name: 'arrow',
  options: {
    element: '.popper-arrow'
  }
}, {
  name: 'flip',
  options: {
    allowedAutoPlacements: ['left', 'right', 'bottom', 'top']
  }
}, // Because of the performance issues on a large number of poppers
// See https://popper.js.org/docs/v2/tutorial/#performance
{
  name: 'eventListeners',
  enabled: false
}];
var placementKeys = {
  left: 'start',
  right: 'end',
  start: 'left-start',
  end: 'right-start'
};

var mapPlacement = function mapPlacement(key) {
  return placementKeys[key] || key;
};

var getPlacement = function getPlacement(placement) {
  return pipe(filter(function (val) {
    return !!val;
  }), Object.keys, map(mapPlacement), function (keys) {
    return keys.length === 1 ? map(mapPlacement, keys) : keys;
  }, join('-'), function (result) {
    return result || 'auto';
  })(placement);
};

var createCustomPopper = function createCustomPopper(content, popper, customPlacement) {
  return createPopper(content, popper, {
    strategy: 'absolute',
    placement: getPlacement(customPlacement),
    modifiers: defaultModifiers
  });
};

export { createCustomPopper };
