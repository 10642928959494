import { getAllowedCardsFilter } from '../../cards/list/cards.query.js';

var accountsListQuery = function accountsListQuery(iban) {
  var q = {
    Accounts: ['cbsID', 'friendlyName', 'accountName', 'ibanAccount', 'typeName', 'govRestrictionSeqType', 'govRestrictionIncType', 'accountOpenDate', {
      balance: [{
        'currency': ['currencyID']
      }, 'overdraftAmount', 'availableBalance', 'nonAuthorizedOverdraftAmount', 'balance']
    }, {
      accountSubType: ['text', 'externalId', 'id']
    }, {
      accountType: ['accTypeId', 'status', 'text', {
        'defaultAccountIcon': ['icon']
      }]
    }, {
      currency: ['currencyID']
    }, {
      status: ['statusId', 'text', 'activeStatusId', 'hostStatusCode']
    }, {
      customProductIcon: ['icon']
    }, {
      multicurrencyAccount: ['iban']
    }],
    where: {
      'status.entityUniqueId': {
        inlist: ['Open', 'CreditOnly', 'BudgetOnly', 'Frozen']
      },
      'accountType.entityUniqueId': {
        inlist: ['100', '200']
      }
    },
    limit: 1000
  };

  if (iban) {
    q.where['ibanAccount'] = iban;
  }

  return q;
};
var accountCardsQuery = function accountCardsQuery(cardIbans, maxExpirationDays) {
  var query = {
    Cards: ['cbsId', 'cardNumber', 'friendlyName', 'cardHolder', 'isMainCard', 'cardState', 'validTo', {
      productType: ['text', 'linkedCardPriority', {
        productIcon: ['icon']
      }]
    }, {
      cardDesign: ['picSmall', 'picLarge']
    }, {
      cardStatus: ['cardStatusId', 'cardStatusEnum', 'text']
    }, {
      ibanAccount: ['iban']
    }],
    where: getAllowedCardsFilter(maxExpirationDays),
    limit: 1000
  };

  if (Array.isArray(cardIbans) && cardIbans.length > 0) {
    query.where['ibanAccount.entityUniqueId'] = {
      inlist: cardIbans
    };
  }

  return query;
};

export { accountCardsQuery, accountsListQuery };
