import { performCommand, command } from 'core-supply';

var issueTokenCommand = function issueTokenCommand(session) {
  return command({
    issueToken: {
      session: session
    }
  });
};

var issueTokenSaga = function issueTokenSaga(session, handlers) {
  return performCommand(issueTokenCommand(session), handlers, {
    supplier: 'security'
  });
};

export { issueTokenSaga };
