import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { takeLatest } from 'redux-saga/effects';
import { logic2, composeFragments, pathFragment, action, state, stateFlag, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { checkRightsFragment } from '../../permissions/index.js';
import { loadMessageFlow } from '../actions/actions.js';
import { withVTBCertificationFragment } from '../../payment-centre/common/withVTBCertificationFragment.js';
import { sendMessageFlow } from './create-message.flow.js';
import { createMesssageFieldFragment } from './fields.fragment.js';
import { CREATE_AUTHORIZE_MESSAGE } from '../../permissions/rights.js';

var createMessageLogic = logic2(composeFragments(pathFragment('messageCenter', 'create'), createMesssageFieldFragment(), withResetForm({}), withInitializeForm({}), withFieldsSelectors, withVTBCertificationFragment, checkRightsFragment({
  rights: CREATE_AUTHORIZE_MESSAGE
}), action('addAttachment', function (_ref) {
  var file = _ref.file;
  return {
    file: file
  };
}), action('removeAttachment', function (_ref2) {
  var id = _ref2.id;
  return {
    id: id
  };
}), action('resetAttachments', function () {
  return {};
}), action('sendMessage', function (isCertificate) {
  return {
    isCertificate: isCertificate
  };
}), action('setShowCertification', function (showCertification) {
  return {
    showCertification: showCertification
  };
}), action('loadMessage', function (id, messageType) {
  return {
    id: id,
    messageType: messageType
  };
}), action('setReplyToId', function (id) {
  return {
    id: id
  };
}), state(['isCertificate', 'sendMessage'], function (_, _ref3) {
  var isCertificate = _ref3.isCertificate;
  return isCertificate;
}), state(['showCertification', 'setShowCertification'], function (_, _ref4) {
  var showCertification = _ref4.showCertification;
  return showCertification;
}), state(['message', 'messageLoaded'], function (_, _ref5) {
  var message = _ref5.message;
  return message;
}, {}), state(['attachments', 'addAttachment'], function (state, _ref6) {
  var file = _ref6.file;
  return [].concat(_toConsumableArray(state), [file]);
}, []), state(['attachments', 'resetAttachments'], function () {
  return [];
}), state(['replyToId', 'setReplyToId'], function (_, _ref7) {
  var id = _ref7.id;
  return id;
}, null), state(['replyToId', 'triggerPop'], function () {
  return null;
}), state(['updatedMessageContent', 'updateMessageContent'], function (_, _ref8) {
  var content = _ref8.content;
  return content;
}), state(['platform', 'setPlatform'], function (_, _ref9) {
  var os = _ref9.os;
  return os;
}, null), state(['attachments', 'removeAttachment'], function (state, _ref10) {
  var id = _ref10.id;
  var newState = [].concat(_toConsumableArray(state.slice(0, id)), _toConsumableArray(state.slice(id + 1)));
  return newState;
}, []), action('updateCertificationStatus'), stateFlag('attachmentUploading', ['attachmentLoad'], ['attachmentLoaded'], false), stateFlag('loading', ['loadMessage', 'loadAttachment', 'startLoading', 'showDeleteModal', 'load'], ['initializeForm', 'loaded', 'error', 'setShowCertification'], false), stateFlag('popModal', ['triggerPop'], ['stopPop'], false), stateFlag('isSubmited', ['sendMessage'], ['loaded', 'reset', 'error', 'initializeForm'], false), stateFlag('isError', ['error'], ['loaded', 'reset', 'initializeForm', 'resetError'], false), stateFlag('sendMessageFailed', ['openMessageSendFailedAlert'], ['closeMessageSendFailedAlert'], false), flow('sendMessageFlow', [resolveAction('sendMessage'), takeLatest, sendMessageFlow]), flow('loadMessageFlow', [resolveAction('loadMessage'), takeLatest, loadMessageFlow])));
var createMessageReducer = createMessageLogic.reducer;
var createMessageSaga = sagaifyLogic2(createMessageLogic);

export { createMessageLogic, createMessageReducer, createMessageSaga };
