import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { isEmpty, uniq, pathOr, path, is, pipe, reject, pathEq, map, sum } from 'ramda';
import { composeIcon, composeEntityName, isDebitCardAccount, isCreditCardAccount, isCurrentAccount, MAIN_CURRENCY, ACCOUNT_GROUP_ID } from 'vtb-utils';
import { createBalance } from '../../balance/create-balance.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var processAccount = function processAccount(account) {
  var isDebit = isDebitCardAccount(path(['accountType', 'accTypeId'], account), path(['accountSubType', 'id'], account));
  var isCredit = isCreditCardAccount(path(['accountType', 'accTypeId'], account), path(['accountSubType', 'id'], account));
  var isCurrent = isCurrentAccount(path(['accountType', 'accTypeId'], account));
  return _objectSpread(_objectSpread({}, account), {}, {
    icon: composeIcon(account),
    name: composeEntityName(account),
    id: account.cbsID,
    isCardAccount: isDebit || isCredit,
    isDebit: isDebit,
    isCredit: isCredit,
    isCurrent: isCurrent
  });
};

var accountGroupsPrescription = function accountGroupsPrescription() {
  return [{
    id: ACCOUNT_GROUP_ID.DEBIT,
    title: 'accounts.list.debitCardAccount.label',
    accounts: [],
    dataCy: 'debitCardAccTxt'
  }, {
    id: ACCOUNT_GROUP_ID.CREDIT,
    title: 'accounts.list.creditCardAccount.label',
    accounts: [],
    dataCy: 'creditCardAccTxt'
  }, {
    id: ACCOUNT_GROUP_ID.CURRENT,
    title: 'accounts.list.currentAccount.label',
    accounts: [],
    dataCy: 'currentAccTxt'
  }];
};
/**
 * Returns array of unique IBANs from card accounts
 * @param ibans Array<any>
 */


var getAccountIbans = function getAccountIbans(accounts) {
  return !isEmpty(accounts) ? uniq(accounts.map(function (_ref) {
    var ibanAccount = _ref.ibanAccount;
    return ibanAccount;
  })) : [];
};
var mapExchangedAmounts = function mapExchangedAmounts(mainCurrency, amountsToExchange, fetchedXrs) {
  return amountsToExchange.map(function (ate) {
    var amount = _objectSpread(_objectSpread({}, ate), {}, {
      originalCurrency: ate.currency,
      currency: mainCurrency
    });

    var rate = fetchedXrs.find(function (r) {
      return r.toCcy === amount.originalCurrency;
    });
    amount.exchangedAmount = amount.amount * (rate ? rate.exchangeRateReverse : 1);
    amount.rate = rate && rate.exchangeRateReverse;
    return amount;
  });
};
/**
 * Sum of all balances on all accounts (Total Balance in GEL)
 *
 * rate for recalculation is used from exchange rates - official rate rate
 *
 * * cards: fetch balances via AvailableBalance endpoint (specify in request you need it in GEL)
 * * accounts: GDS_Accounts.Balance.availableBalance recalculated to GEL
 */

var getTotalInGel = function getTotalInGel(accountGroups) {
  return accountGroups.reduce(function (sum, group) {
    return sum + group.accounts.reduce(function (accountsSum, account) {
      return accountsSum + pathOr(0, ['availableBalanceSumInGel', 'amount'], account);
    }, 0);
  }, 0);
};
var getPlaceholderForTotalSum = function getPlaceholderForTotalSum(accountGroups) {
  var isAnotherProblem = false;
  var areBalancesNotLoaded = accountGroups.some(function (group) {
    return group.accounts.some(function (_ref2) {
      var availableBalanceSumInGel = _ref2.availableBalanceSumInGel;

      if (availableBalanceSumInGel && !is(Number, availableBalanceSumInGel.amount)) {
        isAnotherProblem = true;
      }

      return availableBalanceSumInGel === null;
    });
  });
  return areBalancesNotLoaded ? null : isAnotherProblem && undefined;
};
/**
 * NON card MULTIcurrency accounts.
 *
 * Sums GEL balances and exchanges balances
 */

var setMulticurrencyAccountBalanceInMain = function setMulticurrencyAccountBalanceInMain(account, exchangedAmounts) {
  var exchangedAmountsForAccount = exchangedAmounts.filter(function (item) {
    return item.accountId === account.ibanAccount;
  });
  var wereRatesLoaded = exchangedAmountsForAccount.every(function (item) {
    return Boolean(item.rate);
  });

  if (!wereRatesLoaded) {
    account.availableBalanceSum = null;
  } else {
    account.availableBalanceSum = createBalance(sum([].concat(_toConsumableArray(exchangedAmountsForAccount.map(function (item) {
      return item.exchangedAmount;
    })), _toConsumableArray(account.multicurencyBalances.filter(function (bal) {
      return bal.currency === MAIN_CURRENCY;
    }).map(function (item) {
      return item.amount;
    })))), MAIN_CURRENCY);
  }
};

var hasBalance = function hasBalance(account) {
  var balance = path(['balance', 'availableBalance'], account);
  return Boolean(balance) || balance === 0 || balance === '0';
};

var setCardAccountBalance = function setCardAccountBalance(account, cardAccountsBalances, exchangedAmounts) {
  var balance = cardAccountsBalances ? cardAccountsBalances[account.ibanAccount] : null;

  if (!balance) {
    // empty availableBalanceSum will invoke required permanent loader on account balance UI elements
    account.availableBalanceSum = null;
    return;
  }

  if (balance.source && balance.source === 'GDS') {
    // there were any suitable card for this account to be asked to ALTA online service
    // -> availableBalanceSum is filled with data from GDS
    // TODO - for multicurrency account this should be calculated as sum of all currencies - in some single currency
    //        As we don't know priority currency (because fot this account ALTA wasn't asked) assuming it should be GEL.
    if (account.isMulticurrency && account.multicurencyBalances && exchangedAmounts) {
      setMulticurrencyAccountBalanceInMain(account, exchangedAmounts);
      return;
    }

    if (!hasBalance(account)) {
      account.availableBalanceSum = null;
      return;
    }

    balance = {
      amount: account.balance.availableBalance,
      currency: path(['balance', 'currency', 'currencyID'], account)
    };
  }

  account.availableBalanceSum = _objectSpread(_objectSpread({}, account.availableBalanceSum), createBalance(balance.amount, balance.currency));
}; // NON card MONO currency accounts

var setOtherAccountBalance = function setOtherAccountBalance(account) {
  if (!hasBalance(account)) {
    account.availableBalanceSum = null;
  } else {
    account.availableBalanceSum = createBalance(account.balance.availableBalance, path(['balance', 'currency', 'currencyID'], account));
  }
}; // TODO this filter should be used but web have to show account list probably before availableBalances are loaded
/**
 * Summary - displayed very right in account list row
 *
 * Is alse used for sum of all balances on all accounts (Total Balance in GEL)
 *
 * * cards: fetch balances via AvailableBalance endpoint (specify in request you need it in GEL)
 * * accounts: GDS_Accounts.Balance.availableBalance recalculated to GEL
 */

var setAvailableBalanceSumInGel = function setAvailableBalanceSumInGel(account, exchangedAmounts, cardAccountsBalances) {
  var sumCurrency = path(['availableBalanceSum', 'currency'], account);

  if (sumCurrency && sumCurrency !== MAIN_CURRENCY) {
    if (account.isCardAccount) {
      var cardBalance = cardAccountsBalances && cardAccountsBalances[account.ibanAccount];

      if (!cardBalance) {
        // balance not loaded -> loader will be shown
        account.availableBalanceSumInGel = null;
        return;
      }

      if (!cardBalance.source || cardBalance.source !== 'GDS') {
        var balanceInGel = cardBalance.balanceInGel;

        if (balanceInGel === undefined) {
          // balance loaded, but not GEL part -> loader will be shown instead of TOTALS
          account.availableBalanceSumInGel = null;
        } else {
          account.availableBalanceSumInGel = createBalance(balanceInGel, MAIN_CURRENCY);
        }

        return;
      } // else -> should be calculated from GDS data, continuing

    }

    var amounts = exchangedAmounts.filter(function (item) {
      return item.accountId === account.ibanAccount;
    }).map(function (item) {
      return item.exchangedAmount;
    });

    if (amounts.length === 0) {
      account.availableBalanceSumInGel = null;
      return;
    }

    account.availableBalanceSumInGel = createBalance(sum(amounts), MAIN_CURRENCY);
  } else if (account.availableBalanceSum) {
    account.availableBalanceSumInGel = createBalance(account.availableBalanceSum.amount, MAIN_CURRENCY);
  } else {
    account.availableBalanceSumInGel = null;
  }
};

var addBalancesForAccount = function addBalancesForAccount(account, exchangedAmounts, cardAccountsBalances) {
  if (account.isCardAccount) {
    setCardAccountBalance(account, cardAccountsBalances, exchangedAmounts);
  } else if (account.isMulticurrency) {
    setMulticurrencyAccountBalanceInMain(account, exchangedAmounts);
  } else {
    setOtherAccountBalance(account);
  } // set account balance summary in GEL:


  setAvailableBalanceSumInGel(account, exchangedAmounts, cardAccountsBalances);
};

var getAccountsWithBalances = function getAccountsWithBalances(accountGroups, exchangedAmounts, cardAccountsBalances) {
  return accountGroups.map(function (group) {
    var accounts = group.accounts.map(function (account) {
      var accountWithBalances = _objectSpread({}, account);

      addBalancesForAccount(accountWithBalances, exchangedAmounts, cardAccountsBalances);
      return accountWithBalances;
    });
    return _objectSpread(_objectSpread({}, group), {}, {
      accounts: accounts
    });
  });
};
/**
 * for non card account type:
 *
 * * multicurrency accounts:account available balance in GEL from GDS
 * * monocurrency accounts - available balance, currency - taken from GDS_Accounts.Balance
 *
 * for card account type:
 *
 * * Available balance+currency from online service
 * (already stored in session, data fetched during login process via get
 * /v1/cards/{cardId}/AvailableBalance in priority currency).
 */

var getCurrencySummaryTable = function getCurrencySummaryTable(groups) {
  var allBalances = {};
  groups.forEach(function (group) {
    return group.accounts.forEach(function (account) {
      account.multicurencyBalances && account.multicurencyBalances.forEach(function (balance) {
        var curr = balance.currency;

        if (!allBalances[curr]) {
          allBalances[curr] = _objectSpread({}, balance);
        } else {
          allBalances[curr].amount = allBalances[curr].amount + balance.amount;
        }
      });

      if (!account.multicurencyBalances && account.availableBalanceSum) {
        var curr = account.availableBalanceSum.currency;

        if (!allBalances[curr]) {
          allBalances[curr] = _objectSpread({}, account.availableBalanceSum);
        } else {
          allBalances[curr].amount = allBalances[curr].amount + account.availableBalanceSum.amount;
        }
      }
    });
  });
  return allBalances;
};
/**
 * For multicurrency current accounts get all non GEL balances and add them to returned collection
 */

var getAmountsToExchangeRateCalculation = function getAmountsToExchangeRateCalculation(allAccountsForDisplay, mcAccounts) {
  var amountsToExchange = [];
  allAccountsForDisplay.forEach(function (acc) {
    if (acc.isMulticurrency) {
      // both card and current account
      if (Array.isArray(mcAccounts[acc.ibanAccount])) {
        mcAccounts[acc.ibanAccount].forEach(function (balance) {
          if (balance.currency !== MAIN_CURRENCY) {
            amountsToExchange.push(_objectSpread(_objectSpread({}, balance), {}, {
              accountId: acc.ibanAccount
            }));
          }
        });
      }
    } else {
      // adding also card accounts in case that their balances weren't loaded from ALTA
      if (path(['balance', 'currency', 'currencyID'], acc) && acc.balance.currency.currencyID !== MAIN_CURRENCY) {
        amountsToExchange.push(_objectSpread(_objectSpread({}, createBalance(acc.balance.availableBalance, acc.balance.currency.currencyID)), {}, {
          accountId: acc.ibanAccount,
          shouldBeLoadedFromService: acc.isCardAccount
        }));
      }
    }
  });
  return amountsToExchange;
};

function getSortedAccountGroups(accounts, mcAccounts, overdrafts) {
  var sortedAccountGroups = accountGroupsPrescription();
  var alreadyParsedIbans = [];
  var allAccountsForDisplay = accounts.map(function (acc) {
    // set all static balances from all Account objects with given ibanAccount
    acc.multicurencyBalances = acc.isMulticurrency ? mcAccounts[acc.ibanAccount] || [] : null;
    var notParsedIban = !alreadyParsedIbans.includes(acc.ibanAccount);

    if (notParsedIban) {
      if (overdrafts[acc.ibanAccount]) {
        acc.overdrafts = overdrafts[acc.ibanAccount];
      }

      alreadyParsedIbans.push(acc.ibanAccount);
    }

    if (acc.isDebit || acc.isCredit) {
      acc.availableBalanceSum = null;

      if (notParsedIban) {
        if (acc.isDebit) {
          sortedAccountGroups[0].accounts.push(acc);
        }

        if (acc.isCredit) {
          sortedAccountGroups[1].accounts.push(acc);
        }
      }
    } else {
      acc.availableBalanceSum = acc.isMulticurrency || !hasBalance(acc) ? null : createBalance(acc.balance.availableBalance, path(['balance', 'currency', 'currencyID'], acc));

      if (notParsedIban && acc.isCurrent) {
        sortedAccountGroups[2].accounts.push(acc);
      }
    }

    return acc;
  });
  return {
    allAccountsForDisplay: allAccountsForDisplay,
    sortedAccountGroups: sortedAccountGroups
  };
}

var getMulticurrencyBalance = function getMulticurrencyBalance(account) {
  if (!account.isMulticurrency || !path(['balance', 'currency', 'currencyID'], account)) {
    return null;
  }

  return _objectSpread(_objectSpread({
    id: account.cbsID
  }, createBalance(account.balance.availableBalance, account.balance.currency.currencyID)), {}, {
    balance: account.balance.balance
  });
};

var getAccountAndMcBalance = function getAccountAndMcBalance(account, ibans) {
  var acc = _objectSpread(_objectSpread({}, processAccount(account)), {}, {
    isMulticurrency: ibans.filter(function (acc) {
      return acc === account.ibanAccount;
    }).length > 1
  });

  var mcBalance = getMulticurrencyBalance(acc);
  return _objectSpread(_objectSpread({}, acc), {}, {
    mcBalance: mcBalance
  });
};
/**
 * Returns all enriched accounts and array of balances of multicurrency accounts
 * Balances of card multicurrency accoutns have to be overridden in next steps
 */


var composeAccountsRows = function composeAccountsRows(accounts) {
  var ibans = accounts.map(function (_ref3) {
    var ibanAccount = _ref3.ibanAccount;
    return ibanAccount;
  });
  var mcAccounts = {};
  var overdrafts = {};
  var accountRows = accounts.map(function (account) {
    var acc = getAccountAndMcBalance(account, ibans);

    if (acc.mcBalance) {
      var _ref4 = account.multicurrencyAccount || {},
          _iban = _ref4.iban;

      if (!mcAccounts[_iban]) {
        mcAccounts[_iban] = [];
      }

      mcAccounts[_iban].push(acc.mcBalance);
    }

    if (Number(pathOr(0, ['balance', 'overdraftAmount'], account)) > 0) {
      var _iban2 = account.ibanAccount;

      if (!overdrafts[_iban2]) {
        overdrafts[_iban2] = [];
      }

      overdrafts[_iban2].push(_objectSpread({
        accountId: account.cbsID
      }, createBalance(account.balance.overdraftAmount, path(['balance', 'currency', 'currencyID'], account))));
    }

    return acc;
  });
  return {
    mcAccounts: mcAccounts,
    accountRows: accountRows,
    overdrafts: overdrafts
  };
};
var processAccounts = function processAccounts(accounts) {
  var _composeAccountsRows = composeAccountsRows(accounts),
      mcAccounts = _composeAccountsRows.mcAccounts,
      accountRows = _composeAccountsRows.accountRows,
      overdrafts = _composeAccountsRows.overdrafts;

  var _getSortedAccountGrou = getSortedAccountGroups(accountRows, mcAccounts, overdrafts),
      allAccountsForDisplay = _getSortedAccountGrou.allAccountsForDisplay,
      sortedAccountGroups = _getSortedAccountGrou.sortedAccountGroups;

  var amountsToExchange = getAmountsToExchangeRateCalculation(allAccountsForDisplay, mcAccounts);
  return {
    amountsToExchange: amountsToExchange,
    accountGroups: sortedAccountGroups,
    cardIbans: getAccountIbans([].concat(_toConsumableArray(sortedAccountGroups[0].accounts), _toConsumableArray(sortedAccountGroups[1].accounts)))
  };
};
var getBalancesForExchangeRates = function getBalancesForExchangeRates(accounts) {
  return pipe(reject(pathEq(['balance', 'currency', 'currencyID'], MAIN_CURRENCY)), map(function (account) {
    return {
      id: account.entityUniqueId,
      accountId: account.ibanAccount,
      currency: path(['balance', 'currency', 'currencyID'], account),
      amount: path(['balance', 'availableBalance'], account)
    };
  }))(accounts);
};

export { composeAccountsRows, getAccountIbans, getAccountsWithBalances, getBalancesForExchangeRates, getCurrencySummaryTable, getPlaceholderForTotalSum, getTotalInGel, mapExchangedAmounts, processAccount, processAccounts, setCardAccountBalance, setMulticurrencyAccountBalanceInMain, setOtherAccountBalance };
