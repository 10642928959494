import { takeLatest } from 'redux-saga/effects';
import { loadClientAdditionalDataFlow } from './client-additional-data.flow.js';

var INITIAL_ADDITIONAL_DATA = null;
var INITIAL_CLIENT_DATA = null;
var clientAdditionalDataFragment = function clientAdditionalDataFragment() {
  return {
    actions: {
      loadAdditionalData: function loadAdditionalData() {
        return {};
      },
      success: function success(result) {
        return {
          result: result
        };
      },
      clientLoaded: function clientLoaded(result) {
        return {
          result: result
        };
      },
      userLoaded: function userLoaded(result) {
        return {
          result: result
        };
      },
      failure: function failure(error) {
        return {
          error: error
        };
      },
      clientLoadedFailure: function clientLoadedFailure(error) {
        return {
          error: error
        };
      },
      userLoadedFailure: function userLoadedFailure(error) {
        return {
          error: error
        };
      }
    },
    state: {
      additionalData: {
        'actions.success': function actionsSuccess(_, _ref) {
          var result = _ref.result;
          return result;
        },
        'actions.failure': function actionsFailure() {
          return INITIAL_ADDITIONAL_DATA;
        }
      },
      client: {
        'actions.clientLoaded': function actionsClientLoaded(_, _ref2) {
          var result = _ref2.result;
          return result;
        },
        'actions.clientLoadedFailure': function actionsClientLoadedFailure() {
          return INITIAL_ADDITIONAL_DATA;
        }
      },
      currentUser: {
        'actions.userLoaded': function actionsUserLoaded(_, _ref3) {
          var result = _ref3.result;
          return result;
        },
        'actions.userLoadedFailure': function actionsUserLoadedFailure() {
          return INITIAL_ADDITIONAL_DATA;
        }
      },
      isClientLoading: {
        'actions.loadAdditionalData': function actionsLoadAdditionalData() {
          return true;
        },
        'actions.clientLoaded': function actionsClientLoaded() {
          return false;
        }
      }
    },
    selectors: {
      additionalData: [['additionalData']],
      currentClient: [['client']],
      isClientLoading: [['isClientLoading']]
    },
    flows: {
      loadData: ['actions.loadAdditionalData', takeLatest, loadClientAdditionalDataFlow]
    },
    initialState: {
      additionalData: INITIAL_ADDITIONAL_DATA,
      client: INITIAL_CLIENT_DATA,
      isClientLoading: true
    }
  };
};

export { clientAdditionalDataFragment };
