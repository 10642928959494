// import { signingRulesReducers, signingRulesSagas } from 'vtb-logic'

import { withLazy } from '../../../common/with-lazy'

export const DeleteSigningRule = withLazy(() => import('./delete-signing-rule.module'))

// export const reducers = {
//   signingRules: signingRulesReducers,
// }
//
// export const sagas = {
//   signingRulesSagas,
// }
