import { takeLatest } from 'redux-saga/effects';
import { resolveLogic, composeFragments, pathFragment, action, stateFlag, state, flow, resolveAction } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { INSTRUCTION_TYPES, isCardOrCurrent } from 'vtb-utils';
import { TRANSFERS_STEPS } from '../constants.js';
import { wizardFragment } from '../../generic/wizard.fragment.js';
import { instructionIdsFragment } from '../common/instruction-logic.js';
import { withVTBCertificationFragment } from '../common/withVTBCertificationFragment.js';
import { decideInstructionTypeFragment } from '../common/decideInstructionTypeFragment.js';
import { moveInstructionToEditFragment } from '../../tpm/moveInstructionToEditFragment.js';
import { decidePaymentWithoutBalanceCheckFragment } from '../common/decidePaymentWithoutBalanceCheckFragment.js';
import { userHasNoAccountsSelector } from '../common/selectors.js';
import { sharedAccountsFragment } from '../common/shared-accounts/shared-accounts.fragment.js';
import { currencyExchangeFieldsFragment } from './currency-exchange.fields.js';
import { processCurrencyExchangeFlow, verifyInstructionFlow, recalculateAmountAndVerifyFlow, mapAccountsFlow } from './currency-exchange.flows.js';
export { getRateByType } from './currency-exchange.flows.js';
import { verifyAvailableBalanceFragment } from '../common/helpers.js';
import { CURRENCY_EXCHANGE, CURRENCY_EXCHANGE_CERT, CREATE } from '../../permissions/rights.js';

var currencyExchangeLogicAPI = resolveLogic(composeFragments(pathFragment('transfers', 'currencyExchange'), decidePaymentWithoutBalanceCheckFragment, currencyExchangeFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, sharedAccountsFragment({
  listName: 'accounts',
  filterCondition: isCardOrCurrent,
  afterQueryHandler: mapAccountsFlow,
  visibilityKey: null,
  instructionTypes: ['T:paymentCurrencyExchangeCert', 'T:paymentCurrencyExchange', 'T:requestCurrencyExchange'],
  atLeastOneInstructionType: true
}), userHasNoAccountsSelector, wizardFragment(TRANSFERS_STEPS), action('submit'), action('initializeForm'), action('verifyInstruction'), action('recalculateAmountAndVerify'), stateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm'], false), stateFlag('isExchangeRateChanged', ['setDeprecatedConvertMoney'], ['failed', 'reset', 'resetForm', 'confirmNewExchnageRate'], false), stateFlag('submitting', ['calling'], ['tmpSuccess', 'tmpFailed', 'failed', 'submited', 'certificationSubmited', 'resetForm']), stateFlag('verifyingInstruction', ['verifyInstruction'], ['tmpSuccess', 'tmpFailed']), stateFlag('isTransferHeaderOpen', ['openTransferHeader'], ['closeTransferHeader', 'reset', 'resetForm']), stateFlag('convertingMoney', ['convertMoneyStarted'], ['convertMoneyLoaded']), stateFlag('updatingAmounts', ['updateSourceAmount', 'updateTargetAmount'], ['completeSourceAmount', 'completeTargetAmount', 'amountUpdateCompleted']), state(['debitAccounts', 'debitAccountsLoaded'], function (_, _ref) {
  var data = _ref.data;
  return data;
}), state(['creditAccounts', 'creditAccountsLoaded'], function (_, _ref2) {
  var data = _ref2.data;
  return data;
}), state(['creditAccounts', 'reset'], function () {
  return null;
}), state(['previousDebitAccount', 'setPreviousDebitAccount'], function (_, _ref3) {
  var value = _ref3.value;
  return value;
}), state(['previousDebitAccount', 'resetForm'], function () {
  return null;
}), state(['previousDebitAccount', 'submit'], function () {
  return null;
}), state(['previousCreditAccount', 'setPreviousCreditAccount'], function (_, _ref4) {
  var value = _ref4.value;
  return value;
}), state(['previousCreditAccount', 'resetForm'], function () {
  return null;
}), state(['previousCreditAccount', 'submit'], function () {
  return null;
}), state(['exchangeRateDisplays', 'exchangeRateDisplaysLoaded'], function (_, _ref5) {
  var ExchangeRateDisplays = _ref5.ExchangeRateDisplays;
  return ExchangeRateDisplays && ExchangeRateDisplays[0];
}), state(['exchangeRateDisplays', 'resetForm'], function () {
  return null;
}), state(['depricatedConvertMoney', 'setDeprecatedConvertMoney'], function (_, _ref6) {
  var data = _ref6.data;
  return data;
}), state(['depricatedConvertMoney', 'resetForm'], function () {
  return null;
}), state(['convertMoney', 'convertMoneyLoaded'], function (_, data) {
  return data;
}), state(['convertMoney', 'resetForm'], function () {
  return null;
}), state(['convertMoney', 'resetConvertMoney'], function () {
  return null;
}), withVTBCertificationFragment, verifyAvailableBalanceFragment, instructionIdsFragment, moveInstructionToEditFragment, state(['shouldCertify', 'shouldCertifyInstruction'], function (_, _ref7) {
  var shouldCertify = _ref7.shouldCertify;
  return shouldCertify;
}, false), state(['certifiable', 'certificationSubmited'], function (_, _ref8) {
  var certifiable = _ref8.certifiable;
  return certifiable;
}, false), state(['instructionType', 'setInstructionType'], function (_, _ref9) {
  var value = _ref9.value;
  return value;
}, INSTRUCTION_TYPES.currencyExchange), state(['operationType', 'setOperationType'], function (_, _ref10) {
  var operationType = _ref10.operationType;
  return operationType;
}), state(['operationType', 'resetForm'], function () {
  return null;
}), state(['mayHaveIndividualRate', 'mayHaveIndividualRateLoaded'], function (_, _ref11) {
  var mayHaveIndividualRateLoaded = _ref11.mayHaveIndividualRateLoaded;
  return mayHaveIndividualRateLoaded;
}), state(['mayHaveIndividualRate', 'resetForm'], function () {
  return null;
}), state(['mayHaveIndividualRate', 'resetConvertMoney'], function () {
  return null;
}), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, processCurrencyExchangeFlow]), flow('verifyInstruction', [resolveAction('verifyInstruction'), takeLatest, verifyInstructionFlow]), flow('recalculateAmountAndVerify', [resolveAction('recalculateAmountAndVerify'), takeLatest, recalculateAmountAndVerifyFlow]), decideInstructionTypeFragment({
  instruction: INSTRUCTION_TYPES.currencyExchange,
  instructionCert: INSTRUCTION_TYPES.currencyExchangeCert,
  right: CURRENCY_EXCHANGE(CREATE),
  rightCert: CURRENCY_EXCHANGE_CERT(CREATE)
})));
var currencyExchangeLogic = currencyExchangeLogicAPI.logic;
var currencyExchangeReducer = currencyExchangeLogic.reducer;
var currencyExchangeSaga = currencyExchangeLogicAPI.saga;

export { currencyExchangeLogic, currencyExchangeLogicAPI, currencyExchangeReducer, currencyExchangeSaga };
