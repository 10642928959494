import { composeFragments, validationRule, maxLength, autocorrectingValidationRule, validation } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withDependentFieldFlow, withDependentFieldValidation, withDynamicFieldValidation } from 'core-recipes';
import { regexCharset, bytesToMiBConverter } from 'vtb-utils';
import { allowedLatinNumbersAndChars } from '../../utils/validation-patterns.js';
import { validationConfig } from './someone-else.fields.validations.js';
import { requiredRule, charset, minAmount, fakeValidation } from '../../utils/validators.js';

var transferToSomeoneElseFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'beneficiaryName',
  rules: [validationRule(requiredRule), validationRule(maxLength(70)), autocorrectingValidationRule(charset(regexCharset))]
}), fieldFragment({
  name: 'beneficiaryAccount',
  rules: [autocorrectingValidationRule(charset(allowedLatinNumbersAndChars, 'validation.error.charset', /[^a-zA-Z0-9]/g)), validationRule(requiredRule)]
}, withDependentFieldFlow({
  source: 'beneficiaryAccount',
  sourceActionName: 'complete',
  updateHandler: validationConfig.beneficiaryAccount.dependentFlow
}), withDependentFieldValidation({
  fieldName: 'debitAccount',
  code: 'error.accountCurrencyValidation',
  validation: validationConfig.beneficiaryAccount.dependentFieldValidation,
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      debitAccount: selectors.debitAccount,
      additionalAccountInfo: selectors.additionalAccountInfo
    };
  }
})), fieldFragment({
  name: 'beneficiaryBankCode'
}, withDependentFieldFlow({
  source: 'beneficiaryBankCode',
  sourceActionName: 'complete',
  updateHandler: validationConfig.beneficiaryBankCode.dependentFlow
}), withDependentFieldValidation({
  fieldName: 'beneficiaryAccount',
  code: 'beneficiaryBankCode',
  validation: validationConfig.beneficiaryBankCode.dependentFieldValidation,
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      beneficiaryAccount: selectors.beneficiaryAccount
    };
  }
})), fieldFragment({
  name: 'beneficiaryBankName',
  rules: [validationRule(maxLength(300))]
}, withDependentFieldValidation({
  fieldName: 'beneficiaryAccount',
  code: 'required',
  validation: validationConfig.requiredForOutsideBanks,
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      beneficiaryAccount: selectors.beneficiaryAccount
    };
  }
})), fieldFragment({
  name: 'beneficiaryCountry'
}, withDependentFieldFlow({
  source: 'beneficiaryCountry',
  sourceActionName: 'complete',
  updateHandler: validationConfig.setInstructionType
}), withDependentFieldValidation({
  fieldName: 'beneficiaryAccount',
  code: 'required',
  validation: validationConfig.requiredForOutsideBanks,
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      beneficiaryAccount: selectors.beneficiaryAccount
    };
  }
})), fieldFragment({
  name: 'debitAccount',
  initialValue: null,
  rules: [validationRule(requiredRule)]
}, withDependentFieldFlow({
  source: 'debitAccount',
  sourceActionName: 'complete',
  updateHandler: validationConfig.setInstructionType
}), withDependentFieldValidation({
  fieldName: 'beneficiaryAccount',
  code: 'error.accountCurrencyValidation',
  validation: validationConfig.debitAccount.dependentFieldValidation,
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      beneficiaryAccount: selectors.beneficiaryAccount,
      additionalAccountInfo: selectors.additionalAccountInfo
    };
  }
})), fieldFragment({
  name: 'amount',
  rules: [validationRule(requiredRule), validationRule(minAmount(0.01))]
}), fieldFragment({
  name: 'ccy',
  initialValue: 'GEL'
}), fieldFragment({
  name: 'commissionType',
  initialValue: 'SHA'
}), fieldFragment({
  name: 'purpose',
  rules: [validationRule(maxLength(140)), autocorrectingValidationRule(charset(/\S/gi)), autocorrectingValidationRule(charset(regexCharset)), validationRule(requiredRule)]
}), fieldFragment({
  name: 'extrInfo',
  rules: [validationRule(maxLength(250)), autocorrectingValidationRule(charset(/\S/gi)), autocorrectingValidationRule(charset(regexCharset))]
}), fieldFragment({
  name: 'attachment'
}, withDynamicFieldValidation({
  query: validationConfig.attachment.dynamicFieldValidationQuery,
  isCallable: true,
  code: 'maxFileSizeExceeded',
  triggerActionName: 'updateAttachment',
  validation: function validation$1(_ref) {
    var maxFileSize = _ref.maxFileSize;
    var maxSizeInMB = bytesToMiBConverter(maxFileSize);
    return validation('maxFileSizeExceeded', function (value) {
      var _value$file;

      return ((value === null || value === void 0 ? void 0 : (_value$file = value.file) === null || _value$file === void 0 ? void 0 : _value$file.size) || 0) <= maxFileSize;
    }, null, {
      text: 'common.attachment.maxSize',
      params: {
        maxSize: maxSizeInMB
      }
    });
  }
}), withDynamicFieldValidation({
  validationKey: 'maxLengthAttachment',
  query: validationConfig.attachment.dynamicFieldValidationQuery,
  isCallable: true,
  code: 'maxFileNameLengthExceeded',
  triggerActionName: 'updateAttachment',
  validation: function validation$1(_ref2) {
    var maxFileNameLength = _ref2.maxFileNameLength;
    return validation('maxFileSizeExceeded', function (value) {
      var _value$file2, _value$file2$name, _value$fileName;

      var handledVal = (value === null || value === void 0 ? void 0 : (_value$file2 = value.file) === null || _value$file2 === void 0 ? void 0 : (_value$file2$name = _value$file2.name) === null || _value$file2$name === void 0 ? void 0 : _value$file2$name.length) || (value === null || value === void 0 ? void 0 : (_value$fileName = value.fileName) === null || _value$fileName === void 0 ? void 0 : _value$fileName.length) || 0;
      return handledVal <= maxFileNameLength;
    }, null, {
      text: 'messageCenter.attachment.nameLength',
      params: {
        maxSize: maxFileNameLength
      }
    });
  }
})), fieldFragment({
  name: 'beneficiaryAddress',
  rules: [validationRule(maxLength(140)), autocorrectingValidationRule(charset(regexCharset))]
}, withDynamicFieldValidation({
  query: validationConfig.beneficiaryAddress.dynamicFieldValidation,
  isCallable: true,
  code: 'required',
  triggerActionName: 'validateBeneficiaryAddress',
  validation: function validation(validationResult) {
    return validationResult.isForeignTransfer ? requiredRule : fakeValidation;
  }
})), fieldFragment({
  name: 'intermediaryBankCode'
}, withDependentFieldFlow({
  source: 'intermediaryBankCode',
  sourceActionName: 'complete',
  updateHandler: validationConfig.intermediaryBankCode.dependentFlow
})), fieldFragment({
  name: 'intermediaryBankName',
  rules: [autocorrectingValidationRule(charset(regexCharset))]
}, withDependentFieldValidation({
  fieldName: 'intermediaryBankCode',
  code: 'required',
  validation: validationConfig.intermediaryBankName.dependentValidation,
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      intermediaryBankCode: selectors.intermediaryBankCode
    };
  }
}))));

export { transferToSomeoneElseFieldsFragment };
