import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { withStateHandlers } from 'recompose';

var withToggle = function withToggle(stateName, stateAction, initialValue) {
  return withStateHandlers(function () {
    return _defineProperty({}, stateName, initialValue);
  }, _defineProperty({}, stateAction, function (_ref2) {
    var s = _ref2[stateName];
    return function () {
      return _defineProperty({}, stateName, !s);
    };
  }));
};

export { withToggle };
