import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withFieldsSelectors } from 'core-recipes';
import { providerFragment } from '../../common/provider/provider.fragment.js';
import { wizardFragment } from '../../../generic/wizard.fragment.js';
import { TRANSFERS_STEPS } from '../../constants.js';
import { billPaymentFragment } from './bill-payment.fragment.js';
import { bpSearchFieldsFragment } from './bill-payment-search-fields.js';
import { searchProviderSelectBoxOptions } from './selectors.js';
import { bpTemplatesFragment } from '../../common/bp-templates/bp-templates.fragment.js';

var billPaymentLogic = logic2(composeFragments(pathFragment('transfers', 'billList'), wizardFragment(TRANSFERS_STEPS), providerFragment, billPaymentFragment, bpTemplatesFragment, bpSearchFieldsFragment, withFieldsSelectors, searchProviderSelectBoxOptions));
var billPaymentReducer = billPaymentLogic.reducer;
var billPaymentSaga = sagaifyLogic2(billPaymentLogic);

export { billPaymentLogic, billPaymentReducer, billPaymentSaga };
