import { fieldsFragment, fieldFragment } from 'core-recipes';
import { initialDatePickerLastWeek } from 'vtb-utils';

var movementsFilterFragment = function movementsFilterFragment() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return fieldsFragment(options)(fieldFragment({
    name: 'beneficiaryName',
    initialValue: ''
  }), fieldFragment({
    name: 'dates',
    initialValue: initialDatePickerLastWeek,
    rules: [// validationRule(requiredRule),
    ]
  }), fieldFragment({
    name: 'minAmount',
    initialValue: '',
    rules: [// validationRule(requiredRule),
    ]
  }), fieldFragment({
    name: 'maxAmount',
    initialValue: '',
    rules: [// validationRule(requiredRule),
    ]
  }), fieldFragment({
    name: 'types',
    initialValue: '',
    rules: [// validationRule(requiredRule),
    ]
  }), fieldFragment({
    name: 'currencies',
    initialValue: '',
    rules: [// validationRule(requiredRule)
    ]
  }), fieldFragment({
    name: 'filterAccounts',
    initialValue: '',
    rules: [// validationRule(requiredRule)
    ]
  }), fieldFragment({
    name: 'bank',
    initialValue: '',
    rules: [// validationRule(requiredRule)
    ]
  }), fieldFragment({
    name: 'statuses',
    initialValue: '',
    rules: [// validationRule(requiredRule)
    ]
  }));
};

export { movementsFilterFragment };
