import { colors } from './colors.js';

var primarySmall = {
  "default": {
    color: colors.linkPrimary,
    borderColor: colors.clear,
    bgColor: colors.clear,
    gradStart: colors.buttonGradStart,
    gradEnd: colors.linkPrimary,
    disabledColor: colors.disabled,
    disabledBorderColor: colors.clear,
    disabledBgColor: colors.clear
  },
  hover: {
    color: colors.linkPrimary,
    borderColor: colors.clear,
    bgColor: 'rgba(204, 238, 255, 0.5)'
  },
  focus: {
    color: colors.linkPrimary,
    borderColor: colors.linkPrimary,
    bgColor: 'rgba(204, 238, 255, 0.5)'
  },
  active: {
    color: colors.clear,
    borderColor: colors.linkPrimary,
    bgColor: 'rgba(204, 238, 255, 0.5)'
  }
};
var primary = {
  "default": {
    color: colors.linkPrimary,
    borderColor: colors.linkPrimary,
    bgColor: colors.clear,
    gradStart: colors.buttonGradStart,
    gradEnd: colors.linkPrimary,
    disabledColor: colors.disabled,
    disabledBorderColor: colors.disabled,
    disabledBgColor: colors.clear
  },
  hover: {
    color: colors.linkPrimary,
    borderColor: colors.linkPrimary,
    bgColor: 'rgba(204, 238, 255, 0.5)'
  },
  focus: {
    color: colors.linkPrimary,
    borderColor: colors.buttonFocusBorder,
    bgColor: 'rgba(204, 238, 255, 0.5)'
  },
  active: {
    color: colors.clear,
    borderColor: colors.linkPrimary,
    bgColor: 'rgba(204, 238, 255, 0.5)'
  }
};
var primaryBig = {
  "default": {
    color: colors.linkPrimary,
    borderColor: colors.linkPrimary,
    bgColor: colors.clear,
    gradStart: colors.buttonGradStart,
    gradEnd: colors.linkPrimary,
    disabledColor: colors.clear,
    disabledBorderColor: colors.disabled,
    disabledBgColor: colors.disabled
  },
  hover: {
    color: colors.linkPrimary,
    borderColor: colors.linkPrimary,
    bgColor: 'rgba(204, 238, 255, 0.5)'
  },
  focus: {
    color: colors.linkPrimary,
    borderColor: colors.buttonFocusBorder,
    bgColor: 'rgba(204, 238, 255, 0.5)'
  },
  active: {
    color: colors.clear,
    borderColor: colors.linkPrimary,
    bgColor: 'rgba(204, 238, 255, 0.5)'
  }
};
var secondary = {
  "default": {
    color: colors.highlightGreen,
    borderColor: colors.highlightGreen,
    bgColor: colors.clear,
    gradStart: colors.primaryGreen,
    gradEnd: colors.secondaryGreen,
    disabledColor: colors.disabled,
    disabledBorderColor: colors.disabled,
    disabledBgColor: colors.clear
  },
  hover: {
    color: colors.highlightGreen,
    borderColor: colors.highlightGreen,
    bgColor: 'rgba(178, 227, 181, 0.4)'
  },
  focus: {
    color: colors.primaryGreen,
    borderColor: colors.highlightGreen,
    bgColor: 'rgba(178, 227, 181, 0.4)'
  },
  active: {
    color: colors.clear,
    borderColor: colors.primaryGreen,
    bgColor: 'rgba(178, 227, 181, 0.4)'
  }
};
var secondaryBig = {
  "default": {
    color: colors.highlightGreen,
    borderColor: colors.highlightGreen,
    bgColor: colors.clear,
    gradStart: colors.primaryGreen,
    gradEnd: colors.secondaryGreen,
    disabledColor: colors.clear,
    disabledBorderColor: colors.disabled,
    disabledBgColor: colors.disabled
  },
  hover: {
    color: colors.highlightGreen,
    borderColor: colors.highlightGreen,
    bgColor: 'rgba(178, 227, 181, 0.4)'
  },
  focus: {
    color: colors.primaryGreen,
    borderColor: colors.highlightGreen,
    bgColor: 'rgba(178, 227, 181, 0.4)'
  },
  active: {
    color: colors.clear,
    borderColor: colors.primaryGreen,
    bgColor: 'rgba(178, 227, 181, 0.4)'
  }
};
var basic = {
  "default": {
    color: colors.clear,
    borderColor: colors.highlightGreen,
    bgColor: 'linear-gradient(102deg, #39e75b, #2db747 100%);',
    gradStart: colors.primaryGreen,
    gradEnd: colors.secondaryGreen,
    disabledColor: colors.clear,
    disabledBorderColor: colors.disabled,
    disabledBgColor: 'linear-gradient(102deg, #acb6c3 3%, #8792a0 100%)'
  },
  hover: {
    color: colors.clear,
    borderColor: colors.highlightGreen,
    bgColor: 'linear-gradient(102deg, #39e75b, #2db747 100%);'
  },
  focus: {
    color: colors.primaryGreen,
    borderColor: colors.highlightGreen,
    bgColor: 'rgba(178, 227, 181, 0.4)'
  },
  active: {
    color: colors.clear,
    borderColor: colors.primaryGreen,
    bgColor: 'rgba(178, 227, 181, 0.4)'
  }
};
var neutral = {
  "default": {
    color: colors.tooltipText,
    borderColor: colors.disabled,
    bgColor: colors.clear,
    gradStart: colors.disabled,
    gradEnd: colors.buttonGradEnd,
    disabledColor: colors.disabled,
    disabledBorderColor: colors.disabled,
    disabledBgColor: colors.clear
  },
  hover: {
    color: colors.tooltipText,
    borderColor: 'rgba(172, 182, 195, 0.6)',
    bgColor: 'rgba(212, 212, 212, 0.4)'
  },
  focus: {
    color: colors.tooltipText,
    borderColor: colors.disabled,
    bgColor: 'rgba(212, 212, 212, 0.4)'
  },
  active: {
    color: colors.clear,
    borderColor: colors.disabled,
    bgColor: 'rgba(212, 212, 212, 0.4)'
  }
};
var label = {
  "default": {
    color: colors.buttonLabelColor,
    borderColor: colors.buttonLabelColor,
    bgColor: colors.clear,
    gradStart: 'rgba(241, 237, 254, 0.5)',
    gradEnd: 'rgba(241, 237, 254, 0.5)'
  },
  hover: {
    color: colors.buttonLabelColor,
    borderColor: colors.buttonLabelColor,
    bgColor: colors.buttonLabelBackground
  },
  focus: {
    color: colors.buttonLabelColor,
    borderColor: colors.buttonLabelColor,
    bgColor: 'rgba(241, 237, 254, 0.5)'
  },
  active: {
    color: colors.buttonLabelColor,
    borderColor: colors.buttonLabelColor,
    bgColor: colors.buttonLabelBackground
  }
};
var buttons = {
  primarySmall: primarySmall,
  primary: primary,
  primaryBig: primaryBig,
  secondary: secondary,
  secondaryBig: secondaryBig,
  neutral: neutral,
  basic: basic,
  label: label
};

export { buttons, primarySmall };
