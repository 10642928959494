import { composeFragments, action, stateFlag, extendInitialState } from 'core-ingredients';
import { userHasNoAccountsSelector } from '../common/selectors.js';

var initialState = {
  debitAccounts: [],
  allDebitAccounts: [],
  accountsLoadFailed: false,
  accountsLoaded: false
};
var transferMTUComboFragment = function transferMTUComboFragment() {
  return composeFragments({
    actions: {
      loadAccounts: function loadAccounts() {
        return {};
      },
      debitAccountSelected: function debitAccountSelected(account) {
        return {
          account: account,
          source: 'debit'
        };
      },
      setDebitOptions: function setDebitOptions(accounts) {
        return {
          accounts: accounts
        };
      },
      setAllDebit: function setAllDebit(accounts) {
        return {
          accounts: accounts
        };
      }
    },
    state: {
      debitAccounts: {
        'actions.setDebitOptions': function actionsSetDebitOptions(_, _ref) {
          var accounts = _ref.accounts;
          return accounts;
        }
      },
      allDebitAccounts: {
        'actions.setAllDebit': function actionsSetAllDebit(_, _ref2) {
          var accounts = _ref2.accounts;
          return accounts;
        }
      }
    }
  }, action('loadError'), stateFlag('debitAccountsLoadFailed', ['loadError'], ['setAllDebit']), stateFlag('debitAccountsLoaded', ['setAllDebit'], ['loadError']), userHasNoAccountsSelector, extendInitialState(null, initialState));
};

export { transferMTUComboFragment };
