import React, { PureComponent } from 'react'
import { Logger } from 'vtb-utils'
import { Flex, ErrorPage } from 'vtb-ui'

export class ErrorBoundary extends PureComponent {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, { componentStack }: { componentStack: string }) {
    Logger.error(`Uncaught component exception: ${error} ${componentStack}`)
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return (
        <Flex width={[290, 1080]}>
          <ErrorPage />
        </Flex>
      )
    }

    return this.props.children
  }
}
