var exchangeRatesQuery = function exchangeRatesQuery() {
  return {
    ExchangeRates: ['buyRate', 'sellRate', 'geminiId', {
      ccyTo: ['currencyID', 'flag']
    }, {
      exchangeRateType: ['type', {
        text: ['ru', 'ka', 'en']
      }]
    }],
    limit: 3
  };
};

export { exchangeRatesQuery };
