import React, { lazy } from 'react'
import { useStore } from 'react-redux'
import { AppStore, ReducerMap, SagaMap } from 'vtb-app'

type LazyModule<P> = { default: React.ComponentType<P>; reducers?: ReducerMap; sagas?: SagaMap }

const initializedLazyComponents: React.ComponentType[] = []
const loadLazyLogicOnce = (
  store: AppStore,
  Component: React.ComponentType,
  reducers?: ReducerMap,
  sagas?: SagaMap,
): void => {
  if (initializedLazyComponents.includes(Component)) {
    return
  }

  initializedLazyComponents.push(Component)

  if (reducers) {
    store.injectReducers(reducers)
  }

  if (sagas) {
    store.runSagas(sagas)
  }
}

const withLazyLogic = <P extends {}>({
  default: Component,
  reducers,
  sagas,
}: LazyModule<P>): React.ComponentType<P> => {
  return (props: P) => {
    const store = useStore() as AppStore

    loadLazyLogicOnce(store, Component, reducers, sagas)

    return <Component {...props} />
  }
}

export const withLazy = <P extends {}>(importModule: () => Promise<LazyModule<P>>): React.ComponentType<P> =>
  lazy(() =>
    importModule().then((module) => ({
      default: withLazyLogic<P>(module),
    })),
  ) as React.ComponentType<P>
