import { createSelector } from 'reselect';
import { path } from 'ramda';
import { buildHasRightSelector, matchRight, checkRightsFragment as checkRightsFragment$1 } from 'core-recipes';
import { contextSelector } from '../generic/context.logic.js';

var allAccountsRightsSelector = createSelector([function (state) {
  return path(['context', 'rights'], state);
}, contextSelector], function (rightsContext, context) {
  var _rightsContext$contex;

  return rightsContext && rightsContext[context] ? (_rightsContext$contex = rightsContext[context]) === null || _rightsContext$contex === void 0 ? void 0 : _rightsContext$contex.userRights : [];
});
var allRightsSelector = createSelector([function (state) {
  return path(['context', 'rightsBasic'], state);
}, contextSelector], function (rightsContext, context) {
  return rightsContext && rightsContext[context] ? rightsContext[context] : [];
});
var hasRightSelector = buildHasRightSelector(allRightsSelector);
var hasRightsSelector = createSelector(allRightsSelector, function (allRights) {
  return function (right) {
    return matchRight(allRights)(right);
  };
});
/**
 * @deprecated Use properly memoized hasRightsSelector
 */

var hasSpecificRightSelector = function hasSpecificRightSelector(params) {
  return function (right) {
    return hasRightSelector(right)(params);
  };
};
var checkRightsFragment = function checkRightsFragment(_ref) {
  var rights = _ref.rights;
  return checkRightsFragment$1({
    rights: rights,
    rightsSelector: allRightsSelector
  });
};
var clientTypeSelector = function clientTypeSelector(state) {
  return state.client && state.client.client && state.client.client.type;
};

export { allAccountsRightsSelector, allRightsSelector, checkRightsFragment, clientTypeSelector, hasRightSelector, hasRightsSelector, hasSpecificRightSelector };
