import { fieldsFragment, fieldFragment, withDependentFieldValidation, withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { composeFragments, validation, validationRule, required, autocorrectingValidationRule, maxLengthValidation, minLengthValidation } from 'core-ingredients';
import { passCodeValidation } from 'vtb-utils';
import { validationPasscodeFactory } from '../validation-utils.js';
import { PASSCODE_MAX_LENGTH, PASSCODE_MIN_LENGTH } from '../../auth/password-validation.js';

var passCodeRepeatingValidation = validation('phoneNumberMaskError', function (passcode) {
  var _passCodeValidation = passCodeValidation(passcode),
      isValid = _passCodeValidation.isValid,
      sequence = _passCodeValidation.sequence,
      repeating = _passCodeValidation.repeating;

  return !(passcode && !isValid && sequence <= repeating);
}, function (value) {
  return value;
}, 'security.setPasscode.noSameNumbers.text');
var passCodeSequenceValidation = validation('phoneNumberMaskError', function (passcode) {
  var _passCodeValidation2 = passCodeValidation(passcode),
      isValid = _passCodeValidation2.isValid,
      sequence = _passCodeValidation2.sequence,
      repeating = _passCodeValidation2.repeating;

  return !(passcode && !isValid && sequence > repeating);
}, function (value) {
  return value;
}, 'security.setPasscode.noSequence.text');
var fieldsValidation = [validationRule(required), autocorrectingValidationRule(maxLengthValidation('maxLength', 'security.error.passcodeMaxLength')(PASSCODE_MAX_LENGTH)), validationRule(passCodeRepeatingValidation), validationRule(passCodeSequenceValidation), validationRule(minLengthValidation('minLength', 'security.error.usernameMinLength')(PASSCODE_MIN_LENGTH))];
var changePasscodeFields = (function () {
  return composeFragments(fieldsFragment({})(fieldFragment({
    name: 'newPasscode',
    initialValue: '',
    rules: fieldsValidation
  }, withDependentFieldValidation({
    fieldName: 'repeatPasscode',
    validation: validationPasscodeFactory,
    code: 'passcodeEquality',
    selectorsFunc: function selectorsFunc(selectors) {
      return {
        newPasscode: selectors.newPasscode,
        repeatPasscode: selectors.repeatPasscode
      };
    }
  })), fieldFragment({
    name: 'repeatPasscode',
    initialValue: '',
    rules: fieldsValidation
  })), withResetForm({}), withInitializeForm({}), withFieldsSelectors);
});

export default changePasscodeFields;
