import { composeFragments, selector } from 'core-ingredients';
import { CLIENT_TYPES } from 'vtb-utils';
import { clientTypeSelector } from '../../../../../permissions/index.js';
import { systemParamSelectorFactory } from '../../../../../app/systemParamSelectorFactory.js';
import { localizationLocaleSelector } from '../../../../../utils/language-selector.js';

var getTermLinks = function getTermLinks(termsLinks, localization) {
  switch (localization) {
    case 'en':
      return termsLinks[0];

    case 'ka':
      return termsLinks[1];

    case 'ru':
      return termsLinks[2];

    default:
      return null;
  }
};

var conditionFragment = composeFragments(selector('termsLink', [systemParamSelectorFactory('CORP_URL_EN_TERMS_CONDITIONS'), systemParamSelectorFactory('CORP_URL_GE_TERMS_CONDITIONS'), systemParamSelectorFactory('CORP_URL_RU_TERMS_CONDITIONS'), systemParamSelectorFactory('RETAIL_URL_EN_TERMS_CONDITIONS'), systemParamSelectorFactory('RETAIL_URL_GE_TERMS_CONDITIONS'), systemParamSelectorFactory('RETAIL_URL_RU_TERMS_CONDITIONS'), clientTypeSelector, localizationLocaleSelector], function (CORP_URL_EN_TERMS_CONDITIONS, CORP_URL_GE_TERMS_CONDITIONS, CORP_URL_RU_TERMS_CONDITIONS, RETAIL_URL_EN_TERMS_CONDITIONS, RETAIL_URL_GE_TERMS_CONDITIONS, RETAIL_URL_RU_TERMS_CONDITIONS, clientType, localization) {
  return clientType === CLIENT_TYPES.ORGANIZATION ? getTermLinks([CORP_URL_EN_TERMS_CONDITIONS, CORP_URL_GE_TERMS_CONDITIONS, CORP_URL_RU_TERMS_CONDITIONS], localization) : getTermLinks([RETAIL_URL_EN_TERMS_CONDITIONS, RETAIL_URL_GE_TERMS_CONDITIONS, RETAIL_URL_RU_TERMS_CONDITIONS], localization);
}));

export { conditionFragment };
