import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { path } from 'ramda';
import { numberFormat } from '../format.js';
import { CURRENCY_EXCHAGE_RATES_TYPES, OPERATION_TYPE, TPM_ERRORS_CODE, CURRENCY_EXCHAGE_RATES } from '../constants/payments-constants.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getExchangeRateString = function getExchangeRateString(_ref) {
  var multiplier = _ref.multiplier,
      value = _ref.value,
      leftCcy = _ref.leftCcy,
      rightCcy = _ref.rightCcy;
  return "".concat(multiplier, " ").concat(leftCcy, " = ").concat(value, " ").concat(rightCcy);
};

var formatNumber = function formatNumber(number) {
  var formatedNumber = numberFormat(Number(number), 2);
  return "".concat(formatedNumber.number).concat(formatedNumber.decimalPart);
};

var calculateExchangeRates = function calculateExchangeRates(_ref2) {
  var amount = _ref2.amount,
      convertMoney = _ref2.convertMoney,
      operationType = _ref2.operationType,
      exchangeRateDisplays = _ref2.exchangeRateDisplays,
      getText = _ref2.getText;
  var leftCcy = path(['ccyLeft', 'currencyID'], exchangeRateDisplays);
  var rightCcy = path(['ccyRight', 'currencyID'], exchangeRateDisplays);

  var _ref3 = convertMoney || {},
      bestRateType = _ref3.bestRateType,
      rateSetUsed = _ref3.rateSetUsed,
      maxInputAmount = _ref3.maxInputAmount,
      availableLimitCcy = _ref3.availableLimitCcy,
      saveGainAmount = _ref3.saveGainAmount,
      convertedAmountCcy = _ref3.convertedAmountCcy,
      standardRate = _ref3.standardRate,
      channelRate = _ref3.channelRate,
      customerRate = _ref3.customerRate;

  var standartExchangeRate = getExchangeRateString(_objectSpread({
    leftCcy: leftCcy,
    rightCcy: rightCcy
  }, standardRate));
  var transactionsRateType = bestRateType === CURRENCY_EXCHAGE_RATES.CHR ? channelRate : bestRateType === CURRENCY_EXCHAGE_RATES.CUS ? customerRate : standardRate;
  var transactionExchangeRate = getExchangeRateString(_objectSpread({
    leftCcy: leftCcy,
    rightCcy: rightCcy
  }, transactionsRateType));
  var exceedAvailableLimit = Number(amount) > Number(maxInputAmount);
  var isSpecialRate = !exceedAvailableLimit && ['CUS', 'CHR'].includes(bestRateType);
  var availableLimit = "".concat(formatNumber(maxInputAmount), " ").concat(availableLimitCcy);
  var limit;

  if (Number(maxInputAmount) > 0 && ![CURRENCY_EXCHAGE_RATES_TYPES.STANDART, CURRENCY_EXCHAGE_RATES_TYPES.OFFICIAL].includes(rateSetUsed)) {
    limit = operationType === OPERATION_TYPE.SELL ? getText('payConv.limitUsedExpiresAfterBuy.label', {
      limit: availableLimit
    }) : getText('payConv.limitUsedExpiresAfterSell.label', {
      limit: availableLimit
    });
  }

  var saveGainText = Number(saveGainAmount) > 0 ? "".concat(getText('payConv.yougain.label'), " ") : "".concat(getText('payConv.yousave.label'), " ");
  var saveGain = Number(saveGainAmount) > 0 ? "".concat(formatNumber(saveGainAmount), " ").concat(convertedAmountCcy) : "".concat(formatNumber(Number(saveGainAmount) * -1), " ").concat(convertedAmountCcy);
  return {
    standartExchangeRate: standartExchangeRate,
    transactionExchangeRate: transactionExchangeRate,
    isSpecialRate: isSpecialRate,
    exceedAvailableLimit: exceedAvailableLimit,
    limit: limit,
    saveGainText: saveGainText,
    saveGain: saveGain
  };
};
var hasRelatedAccountsForCurrencies = function hasRelatedAccountsForCurrencies(_ref4) {
  var fields = _ref4.fields,
      userAccounts = _ref4.userAccounts;
  var currencyFrom = path([0, 'value', 'id'], fields);
  var currencyTo = path([1, 'value', 'id'], fields);
  var allCurrencies = [];
  userAccounts && userAccounts.forEach(function (group) {
    return group.accounts.forEach(function (account) {
      allCurrencies.push(account.currency.currencyID);
      var multiCurrency = path(['multicurencyBalances'], account);
      Array.isArray(multiCurrency) && multiCurrency.forEach(function (balance) {
        return balance && allCurrencies.push(balance.currency);
      });
    });
  });
  return allCurrencies.includes(currencyFrom) && allCurrencies.includes(currencyTo);
};
var reducedAccountsByIban = function reducedAccountsByIban(_ref5) {
  var accounts = _ref5.accounts,
      iban = _ref5.iban,
      isForeignPayment = _ref5.isForeignPayment;
  return accounts && accounts.reduce(function (acc, account) {
    return isForeignPayment && account.currency === 'GEL' || [account.iban, account.id].includes(iban) ? acc : [].concat(_toConsumableArray(acc), [account]);
  }, []);
};
var isBillPaymentTemplateUsedOrCreated = function isBillPaymentTemplateUsedOrCreated(bpTemplates, values) {
  var _ref6 = values || {},
      serviceCode = _ref6.serviceCode,
      customerCode = _ref6.customerCode;

  var existingTemplates = [];

  if (bpTemplates && bpTemplates.length > 0) {
    existingTemplates = bpTemplates.filter(function (_ref7) {
      var _serviceCode$value, _service$provider, _serviceCode$value2, _serviceCode$value2$p;

      var service = _ref7.service,
          subscriberId = _ref7.subscriberId;
      return (// crippled serviceCode because of repeat payment pre-fill:
        (service === null || service === void 0 ? void 0 : service.serviceCode) === (serviceCode === null || serviceCode === void 0 ? void 0 : serviceCode.value) && subscriberId === customerCode || (service === null || service === void 0 ? void 0 : service.serviceCode) === (serviceCode === null || serviceCode === void 0 ? void 0 : (_serviceCode$value = serviceCode.value) === null || _serviceCode$value === void 0 ? void 0 : _serviceCode$value.serviceCode) && subscriberId === customerCode && (service === null || service === void 0 ? void 0 : (_service$provider = service.provider) === null || _service$provider === void 0 ? void 0 : _service$provider.entityUniqueId) === (serviceCode === null || serviceCode === void 0 ? void 0 : (_serviceCode$value2 = serviceCode.value) === null || _serviceCode$value2 === void 0 ? void 0 : (_serviceCode$value2$p = _serviceCode$value2.provider) === null || _serviceCode$value2$p === void 0 ? void 0 : _serviceCode$value2$p.providerCode)
      );
    });
  }

  return existingTemplates.length > 0;
};
function shouldItCertify(service) {
  if (!service) {
    return true;
  }

  var shouldCertify = service && service.certify;

  if (shouldCertify === false) {
    return false;
  }

  return true;
}
var makeDisabledAccountSelectable = function makeDisabledAccountSelectable(options) {
  return options.map(function (account) {
    return account !== null && account !== void 0 && account.isDisabled ? _objectSpread(_objectSpread({}, account), {}, {
      isDisabled: false,
      isDisabledUsable: true
    }) : account;
  });
};
var POPULAR_EVENT_TYPES = {
  payment: 'Payment',
  billTemplate: 'BPTemplate',
  paymentTemplate: 'PaymentTemplate'
};
var certificationResponseResolver = function certificationResponseResolver(errorCode) {
  // can be enhanced for more certification error codes
  switch (errorCode) {
    case TPM_ERRORS_CODE.NO_SIGNATURE_AVAILABLE:
      return {
        message: 'payment.noCertificationMethodAvailable.corporate',
        hasNoSigningRule: true
      };

    case TPM_ERRORS_CODE.NO_CERTIFICATION_METHOD_AVAILABLE:
      return {
        message: 'payment.noCertificationMethodAvailable.errorMessage',
        hasNoSigningRule: false
      };

    default:
      return {
        message: 'pay.nofunds.label',
        hasNoSigningRule: false
      };
  }
};
var makePaymentInstructionResponseResolver = function makePaymentInstructionResponseResolver(errorCode) {
  switch (errorCode) {
    case TPM_ERRORS_CODE.ACCOUNT_NOT_FOUND:
      return {
        message: 'pay.accountRightsError.label'
      };

    default:
      return {
        message: 'pay.nofunds.label'
      };
  }
};
var normalizeBicCode = function normalizeBicCode(value) {
  return value && value.length === 8 ? "".concat(value, "XXX") : value;
};

export { POPULAR_EVENT_TYPES, calculateExchangeRates, certificationResponseResolver, hasRelatedAccountsForCurrencies, isBillPaymentTemplateUsedOrCreated, makeDisabledAccountSelectable, makePaymentInstructionResponseResolver, normalizeBicCode, reducedAccountsByIban, shouldItCertify };
