import { format } from 'date-fns';

var mapBillPaymentInstructionItem = function mapBillPaymentInstructionItem(values) {
  var debitAccount = values.debitAccount,
      serviceCode = values.serviceCode,
      customerCode = values.customerCode,
      amount = values.amount,
      feeAmount = values.feeAmount,
      clientPaymentId = values.clientPaymentId,
      additionalInfo = values.additionalInfo,
      channelId = values.channelId,
      carTemplate = values.carTemplate,
      _values$paymentTime = values.paymentTime,
      paymentTime = _values$paymentTime === void 0 ? new Date() : _values$paymentTime;
  var payLoad = {
    debitAccountIBAN: debitAccount.iban,
    debitAccountId: debitAccount.id,
    serviceCode: serviceCode,
    customerCode: customerCode,
    ccy: debitAccount.currency,
    amount: amount,
    feeAmount: feeAmount,
    date: format(paymentTime, 'YYYY-MM-DD'),
    paymentTime: paymentTime,
    clientPaymentId: clientPaymentId,
    channel: channelId,
    additionalInfo: additionalInfo,
    carTemplate: carTemplate
  };
  return payLoad;
};

export { mapBillPaymentInstructionItem };
