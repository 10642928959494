import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { Flex } from '../../../components/generic/flex.js';

var _templateObject;
var BasicLayout = styled(Flex).attrs(function (_ref) {
  var theme = _ref.theme,
      _ref$justifyContent = _ref.justifyContent,
      justifyContent = _ref$justifyContent === void 0 ? 'center' : _ref$justifyContent;
  return {
    flexDirection: 'column',
    justifyContent: justifyContent,
    alignItems: 'center',
    width: [theme.space.responsiveWidth, '100%'],
    maxWidth: ['none', theme.space.pageContentWidth],
    height: '100%',
    border: 0,
    id: 'transfer-basic-layout'
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));

export { BasicLayout };
