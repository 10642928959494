import { resolveLogic, composeFragments, pathFragment, stateFlag } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { standingOrderFriendlyNameFieldsFragment } from './standingOrderFriendlyNameFieldsFragment.js';

var soFriendlyNameFormLogicAPI = resolveLogic(composeFragments(pathFragment('so', 'friendlyName'), standingOrderFriendlyNameFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, stateFlag('isSubmited', ['submit'], ['resetForm'], false)));
var soFriendlyNameFormLogic = soFriendlyNameFormLogicAPI.logic;
var soFriendlyNameFormReducer = soFriendlyNameFormLogicAPI.reducer;
var soFriendlyNameFormSaga = soFriendlyNameFormLogicAPI.saga;

export { soFriendlyNameFormLogic, soFriendlyNameFormReducer, soFriendlyNameFormSaga };
