import { push } from 'connected-react-router'
import { get } from 'core-provider'
import { all, put } from 'redux-saga/effects'
import {
  accountsSagas,
  allAuthSagas,
  appSaga,
  cardsSagas,
  certificationSaga,
  userSaga,
  clientSaga,
  connectionSaga,
  contextSaga,
  corporateUsersSagas,
  digipassSaga,
  loansSagas,
  loyaltySaga,
  movementExportSaga,
  movementsSagas,
  otpCertificationSaga,
  productsSagas,
  runSagaAndCatchErrors,
  savingsSagas,
  sharedSaga,
  signingProceduresSagas,
  signingRulesSagas,
  smsCertificationSaga,
  statementsSagas,
  transferSagas,
  vtbOtpCertificationSaga,
  vtbSmsCertificationSaga,
  // lazy
  carsSagas,
  cardBlocksSagas,
  currencyExchangeRequestSaga,
  currentOrdersBeneficiarySearchSaga,
  currentOrdersSaga,
  ddSagas,
  exchangeRatesListSaga,
  exchangeRatesSaga,
  layoutSagas,
  messageCenterSagas,
  movementsBeneficiarySearchSaga,
  notificationSettingsSaga,
  popularPaymentsSaga,
  profileSettingsSagas,
  securitySagas,
  soPaymentCentreSaga,
  soSagas,
  templatesSagas,
  videochatSaga,
  whatsNewSaga,
  openBankingLogicSaga,
  openBankingDetailSaga,
  offersSaga,
} from 'vtb-logic'
import { ROUTES } from 'vtb-utils'

// TODO: rewrite this inot Async component with dynamically loaded sagas
export default function* root() {
  yield all([
    connectionSaga(get('info')),
    ...[
      appSaga,
      contextSaga,
      sharedSaga,
      certificationSaga,
      smsCertificationSaga,
      vtbSmsCertificationSaga,
      otpCertificationSaga,
      vtbOtpCertificationSaga,
      accountsSagas,
      cardsSagas,
      productsSagas,
      allAuthSagas,
      savingsSagas,
      statementsSagas,
      movementsSagas,
      loansSagas,
      userSaga,
      clientSaga,
      transferSagas,
      loyaltySaga,
      movementExportSaga,
      corporateUsersSagas,
      signingRulesSagas,
      signingProceduresSagas,
      digipassSaga,
      // lazy
      cardBlocksSagas,
      layoutSagas,
      profileSettingsSagas,
      exchangeRatesSaga,
      exchangeRatesListSaga,
      videochatSaga,
      templatesSagas,
      popularPaymentsSaga,
      securitySagas,
      soSagas,
      ddSagas,
      soPaymentCentreSaga,
      currentOrdersSaga,
      carsSagas,
      messageCenterSagas,
      movementsBeneficiarySearchSaga,
      currentOrdersBeneficiarySearchSaga,
      whatsNewSaga,
      currencyExchangeRequestSaga,
      notificationSettingsSaga,
      openBankingLogicSaga,
      openBankingDetailSaga,
      offersSaga,
    ].map(
      runSagaAndCatchErrors(process.env.NODE_ENV, function* (error) {
        yield put(push(ROUTES.MAIN.GENERAL_ERROR, { error }))
      }),
    ),
  ])
}
