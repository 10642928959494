import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled, { keyframes } from 'styled-components';
import { withAnimation } from '../decorators/withAnimation.js';
import { Toast } from './toast.js';

var _templateObject, _templateObject2;
var toastAnimation = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  from {\n    opacity: 0;\n    transform: translateY(-50px);\n  }\n\n  to {\n    opacity: 1;\n    transform: translateY(0px);\n  }\n"])));
var Offline = styled(withAnimation(Toast, toastAnimation, 0.3))(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  text-align: center;\n"])));

export { Offline };
