import { is, replace, isEmpty } from 'ramda';
import { LANGUAGES } from './constants/languages.js';
import { truncateText } from './string/string.js';
import { isMultiLanguageStringObject } from './validation/validation.js';

var getLanguageText = function getLanguageText(key, data, current) {
  var t = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var truncateLength = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  var translated;

  if (Array.isArray(key)) {
    return key.map(function (keyPart) {
      var result = getLanguageText(keyPart, data, current, t);
      return is(String, result) ? result : '';
    }).join(' ');
  }

  if (is(String, key)) {
    translated = data && data[current] && data[current][key];

    if (translated && t) {
      Object.keys(t).forEach(function (e) {
        try {
          translated = replace("{{".concat(e, "}}"), t[e], translated); // eslint-disable-next-line no-empty
        } catch (e) {}
      });
    }

    translated = translated || key;
    return truncateLength === null ? translated : truncateText(translated, truncateLength);
  }

  if (isMultiLanguageStringObject(key)) {
    translated = key[current] || key[LANGUAGES.EN] || '';
    return truncateLength === null ? translated : truncateText(translated, truncateLength);
  }

  if (is(Object, key) && isEmpty(key)) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn('Warning: Text is not valid locale object!', key);
    }

    return '';
  }

  return key;
};

export { getLanguageText };
