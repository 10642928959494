import { validationRule, maxLength, autocorrectingValidationRule } from 'core-ingredients';
import { fieldsFragment, fieldFragment } from 'core-recipes';
import { requiredRule, charset } from '../../../utils/validators.js';
import { groupNameOnlyAllowedChars } from '../../../utils/validation-patterns.js';

var billPaymentGroupFieldsFragment = fieldsFragment({})(fieldFragment({
  name: 'groupCategory',
  rules: [validationRule(requiredRule)]
}), fieldFragment({
  name: 'groupName',
  rules: [validationRule(maxLength(30)), autocorrectingValidationRule(charset(groupNameOnlyAllowedChars)), autocorrectingValidationRule(maxLength(30)), validationRule(requiredRule)]
}));

export { billPaymentGroupFieldsFragment };
