import { memoizeWith, identity } from 'ramda';
import { defaultMemoize } from 'reselect';

var memoize = function memoize(fn) {
  var resolver = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : identity;
  return memoizeWith(resolver, fn);
};
var memoizeOne = defaultMemoize;

export { memoize, memoizeOne };
