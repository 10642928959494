import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { curry, path, map, move } from 'ramda';
import { isDebitCardAccount, isCreditCardAccount, isCurrentAccount, isCardAccount } from 'vtb-utils';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getHostUserId = function getHostUserId(str) {
  return str.split(':')[0];
};

var NON_ACCOUNT_TYPES = {
  SMS: 'SMS',
  PUSH: 'PUSH',
  MAIL: 'MAIL'
};
var enrichUsers = curry(function (notifSettings, user) {
  var foundNotificationSetting = notifSettings.find(function (notif) {
    return getHostUserId(notif.id) === user.hostUserId;
  });

  if (foundNotificationSetting) {
    return _objectSpread(_objectSpread({}, user), foundNotificationSetting);
  }

  return _objectSpread(_objectSpread({}, user), {}, {
    isDefaultSetting: true
  });
});

var isNonsenseAccount = function isNonsenseAccount(accountType) {
  return accountType > 200;
};

var accountGroupsModel = function accountGroupsModel() {
  return [{
    id: 1,
    title: 'accounts.list.debitCardAccount.label',
    accounts: []
  }, {
    id: 2,
    title: 'accounts.list.creditCardAccount.label',
    accounts: []
  }, {
    id: 3,
    title: 'accounts.list.currentAccount.label',
    accounts: []
  }];
};

var processAccount = function processAccount(account) {
  var isDebit = isDebitCardAccount(String(account.accountTypeId), String(account.accountSubTypeId));
  var isCredit = isCreditCardAccount(String(account.accountTypeId), String(account.accountSubTypeId));
  var isCurrent = isCurrentAccount(String(account.accountTypeId));
  var isCard = isCardAccount(String(account.accountTypeId));
  var isNonsense = isNonsenseAccount(path(['accountTypeId'], account));
  return _objectSpread(_objectSpread({}, account), {}, {
    isCardAccount: isCard,
    isDebit: isDebit,
    isCredit: isCredit,
    isCurrent: isCurrent,
    isNonsense: isNonsense
  });
};
var processAccounts = function processAccounts(accounts) {
  var groupedAccounts = accountGroupsModel();
  accounts.forEach(function (acc) {
    if (acc.isDebit) {
      groupedAccounts[0].accounts.push(acc);
    }

    if (acc.isCredit) {
      groupedAccounts[1].accounts.push(acc);
    }

    if (acc.isCurrent) {
      groupedAccounts[2].accounts.push(acc);
    }
  });
  return groupedAccounts;
};
var enrichOfViewableAccs = function enrichOfViewableAccs(clientAccounts, users) {
  var enrichedAccounts = map(processAccount, clientAccounts);
  var processed = users.map(function (user) {
    // TODO
    // const viewableAccounts: number[] = data && data.rightTypes['200070001']
    // const filteredAccounts = clientAccounts.filter(({ accountId }) => viewableAccounts.includes(accountId))
    var processedAccounts = processAccounts(enrichedAccounts);
    return _objectSpread(_objectSpread({}, user), {}, {
      accounts: processedAccounts
    });
  });
  return processed;
};
var unpackAccounts = function unpackAccounts(accounts) {
  var unpackedAccounts = accounts.reduce(function (total, group) {
    return total.concat(group.accounts || []);
  }, []);
  return unpackedAccounts;
};
var formatAccounts = curry(function (accounts) {
  return accounts.map(function (_ref) {
    var accountId = _ref.accountId;
    return {
      accountId: String(accountId),
      allowNotifications: false
    };
  });
});
var userListReorder = function userListReorder(myId, userList) {
  if (!myId) {
    return userList;
  }

  var newUserList = _toConsumableArray(userList);

  var alphabeticalOrder = newUserList.sort(function (a, b) {
    if (a.lastName < b.lastName) {
      return -1;
    }

    if (a.lastName > b.lastName) {
      return 1;
    }

    return 0;
  });
  var myIndex = alphabeticalOrder.findIndex(function (_ref2) {
    var hostUserId = _ref2.hostUserId;
    return hostUserId === myId;
  });
  return move(myIndex, 0, alphabeticalOrder);
};

export { NON_ACCOUNT_TYPES, enrichOfViewableAccs, enrichUsers, formatAccounts, processAccount, processAccounts, unpackAccounts, userListReorder };
