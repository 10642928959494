var EXPORT_ENDPOINTS = {
  STATUS: 'status',
  RESULT: 'result',
  LIST: 'list'
}; // time in ms

var EXPORT_CHECK_INTERVAL = 1500;
var ASYNC_EXPORT_CHECK_INTERVAL = 10000;
var EXPORT_FORMATS = {
  STATEMENTS: ['PDF', 'XLSX', 'XML'],
  MOVEMENTS: ['CSV', 'XLSX'],
  MOVEMENT: ['PDF'],
  LOAN_PAYMENT_HISTORY: ['PDF', 'XLSX', 'XML', 'CSV'],
  ACCOUNT_REQUSITES: ['PDF'],
  LOAN_SCHEDULE: ['PDF', 'XLSX']
};

export { ASYNC_EXPORT_CHECK_INTERVAL, EXPORT_CHECK_INTERVAL, EXPORT_ENDPOINTS, EXPORT_FORMATS };
