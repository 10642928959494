import { takeLatest } from 'redux-saga/effects';
import { composeFragments, action, state, stateFlag, selector, flow, resolveAction } from 'core-ingredients';
import { bpCheckIdFlow } from '../../form/flows/bill-payment-check-id-flow.js';
import { providerServicesSelector } from '../../../common/provider/selectors.js';
import { commonTemplateFragment } from '../common-template-forms.fragment.js';
import { initDataFlow, bpTemplateMainFormProcessFlow } from './bill-payment-template-main-form.flow.js';
import { initTemplateFormFlow } from './bill-payment-init-template-main-form.flow.js';
import { deleteBillPaymentTemplateFlow } from './bill-payment-delete-template.flow.js';
import { reloadTemplateListFlow } from './bill-payment-reload-list.flow.js';

var editTemplateFragment = composeFragments(action('initData'), action('deleteTemplate'), action('openDeleteConfirm'), action('closeDeleteConfirm'), action('closeDeleteResultModal'), action('deleteDone'), action('reloadList'), action('initTemplateForEdit', function (template) {
  return {
    template: template
  };
}), action('setInitialFormValues', function (formValues) {
  return {
    formValues: formValues
  };
}), action('initEditTemplateForm'), state(['templateToEdit', 'initTemplateForEdit'], function (_, _ref) {
  var template = _ref.template;
  return template;
}, null), state(['templateToEdit', 'resetTemplateToEdit'], function () {
  return null;
}), state(['templateToEdit', 'reset'], function () {
  return null;
}), state(['templateToEdit', 'resetForm'], function () {
  return null;
}), state(['initialFormValues', 'setInitialFormValues'], function (_, _ref2) {
  var formValues = _ref2.formValues;
  return formValues;
}, null), state(['initialFormValues', 'reset'], function () {
  return null;
}), state(['initialFormValues', 'resetForm'], function () {
  return null;
}), state(['savedTemplate', 'setJustSavedTemplate'], function (_, _ref3) {
  var template = _ref3.template;
  return template;
}, null), state(['savedTemplate', 'resetJustSavedTemplate'], function () {
  return null;
}), state(['savedTemplate', 'reset'], function () {
  return null;
}), state(['savedTemplate', 'resetForm'], function () {
  return null;
}), state(['deletedTemplate', 'setJustDeletedTemplate'], function (_, _ref4) {
  var template = _ref4.template;
  return template;
}, null), state(['deletedTemplate', 'resetDeletedTemplate'], function () {
  return null;
}), state(['deletedTemplate', 'reset'], function () {
  return null;
}), state(['deletedTemplate', 'resetForm'], function () {
  return null;
}), state(['currentCategoryId', 'resetForm'], function () {
  return null;
}), state(['serverErrors', 'setServerErrors'], function (_, _ref5) {
  var errors = _ref5.errors;
  return errors;
}, []), state(['serverErrors', 'resetForm'], function () {
  return [];
}), state(['serverErrors', 'reset'], function () {
  return [];
}), stateFlag('missingService', ['setMissingService'], ['reset', 'initializeForm', 'resetForm'], false), stateFlag('deleteConfirmOpened', ['openDeleteConfirm'], ['reset', 'deleteFailed', 'deleteDone', 'closeDeleteConfirm'], false), stateFlag('deleteTemplateSuccess', ['deleteDone'], ['deleteFailed', 'initializeForm', 'closeDeleteResultModal', 'reset', 'resetForm'], false), stateFlag('deleteTemplateError', ['deleteFailed'], ['tmpFailed', 'initializeForm', 'closeDeleteResultModal', 'reset', 'resetForm'], false), stateFlag('deletingTemplate', ['deleteTemplate'], ['deleteDone', 'deleteFailed', 'reset', 'resetForm'], false), selector('customerCodeChanged', ['fields', 'initialFormValues', 'debtLoadFailed'], function (fields, initialFormValues, debtLoadFailed) {
  if (!initialFormValues) {
    return false;
  } // if debt loading failed, let user may check ID again, even he reverted subscriberID to initial value


  return fields.customerCode.value !== initialFormValues.customerCode || debtLoadFailed;
}), selector('formChanged', ['fields', 'initialFormValues'], function (fields, initialFormValues) {
  if (!initialFormValues) {
    return false;
  }

  var templateName = initialFormValues.templateName,
      customerCode = initialFormValues.customerCode,
      templateIcon = initialFormValues.templateIcon,
      mode = initialFormValues.mode,
      groupId = initialFormValues.groupId;
  return fields.templateName.value !== templateName || fields.customerCode.value !== customerCode || (fields.groupId.value && fields.groupId.value.value.groupId || null) !== (groupId && groupId.value.groupId || null) || // users template was changed
  fields.templateIcon.value && fields.templateIcon.value.base64 !== templateIcon.base64 || ((templateIcon === null || templateIcon === void 0 ? void 0 : templateIcon.base64) || (templateIcon === null || templateIcon === void 0 ? void 0 : templateIcon.uri)) && !fields.templateIcon.value || fields.mode.value !== mode;
}), flow('initData', [resolveAction('initData'), takeLatest, initDataFlow]), flow('deleteTemplate', [resolveAction('deleteTemplate'), takeLatest, deleteBillPaymentTemplateFlow]), flow('initEditTemplateForm', [resolveAction('initEditTemplateForm'), takeLatest, initTemplateFormFlow]), flow('reloadList', [resolveAction('reloadList'), takeLatest, reloadTemplateListFlow]));
var mainFormFragment = composeFragments(commonTemplateFragment, editTemplateFragment, action('checkId'), action('checked'), state(['paymentsFee', 'feeLoaded'], function (_, _ref6) {
  var value = _ref6.value;
  return value;
}), state(['paymentsFee', 'reset'], function () {
  return null;
}), stateFlag('isIdChecked', ['checked'], ['reset', 'resetForm'], false), stateFlag('isResetCustomerCodeForbidden', ['forbidResetCustomerCode'], ['allowResetCustomerCode'], false), selector('isLoading', ['providersLoading', 'servicesLoading', 'submitting'], function (providersLoading, servicesLoading, submitting) {
  return providersLoading && servicesLoading || submitting;
}), selector('loading', ['providersLoading', 'servicesLoading', 'categoriesLoading', 'submitting'], function (providersLoading, servicesLoading, categoriesLoading, submitting) {
  return providersLoading && servicesLoading && categoriesLoading || submitting;
}), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, bpTemplateMainFormProcessFlow]), flow('checkId', [resolveAction('checkId'), takeLatest, bpCheckIdFlow]), providerServicesSelector);

export { mainFormFragment };
