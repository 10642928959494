import { path } from 'ramda'
import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router'
import {
  VIEW_CORP_SETTINGS_MENU,
  VIEW_ENABLE_OR_DELETE_USER,
  VIEW_MESSAGE_CENTER,
  VIEW_OR_CREATE_USER,
  VIEW_PAYMENTS,
  VIEW_SECURITY_SETTINGS,
  VIEW_WAITING_ROOM,
} from 'vtb-logic'
import { ROUTES } from 'vtb-utils'

import { ErrorScreen, PageNotFound } from '../../../common'
import { BranchesAtms } from '../../branches-atms'
import { AuthorizedRoute } from '../../common/AuthorizedRoute'
import { CorporateSettings } from '../../corporate-settings'
import { CreateSigningRule } from '../../corporate-settings/signing-rules/create-signing-rule'
import { DeleteSigningRule } from '../../corporate-settings/signing-rules/delete-signing-rule'
import { EnableSigningRule } from '../../corporate-settings/signing-rules/enable-signing-rule'
import { AuthorizeUserAction } from '../../corporate-settings/users/authorize-user-action'
import { UserDetail } from '../../corporate-settings/users/user-detail'
import { Dashboard } from '../../dashboard'
import { MessageCenter } from '../../message-center'
import { MyMoney } from '../../my-money'
import { PaymentCentre } from '../../payment-centre'
import { ProfileSettings } from '../../profile-setting'
import { SecuritySettings } from '../../security-settings'
import {
  SigningInstructionDetail,
  SigningProceduresConfirmMultiple,
  SigningProceduresList,
} from '../../signing-procedures'
import { Transactions } from '../../transactions'

export const ContentRouting = (): ReactElement => (
  <Switch>
    <AuthorizedRoute exact path={ROUTES.MAIN.OVERVIEW} component={Dashboard} />
    <Route path={ROUTES.MAIN.MY_MONEY} component={MyMoney} />
    <AuthorizedRoute right={VIEW_PAYMENTS} path={ROUTES.MAIN.PAYMENTS} component={PaymentCentre} />
    <Route path={ROUTES.MAIN.GENERAL_ERROR} component={ErrorScreen} />
    <AuthorizedRoute right={VIEW_MESSAGE_CENTER} path={ROUTES.MAIN.MESSAGE_CENTER} component={MessageCenter} />
    <AuthorizedRoute path={ROUTES.MAIN.TRANSACTIONS} component={Transactions} />
    <Route path={ROUTES.MAIN.PROFILE_SETTING} component={ProfileSettings} />
    <AuthorizedRoute right={VIEW_SECURITY_SETTINGS} path={ROUTES.MAIN.SECURITY_SETTING} component={SecuritySettings} />
    <AuthorizedRoute
      right={VIEW_ENABLE_OR_DELETE_USER}
      path={ROUTES.CORPORATE_SETTING.AUTHORIZE_USER_ACTION}
      component={AuthorizeUserAction}
    />
    <AuthorizedRoute right={VIEW_OR_CREATE_USER} path={ROUTES.CORPORATE_SETTING.CREATE_USER} component={UserDetail} />
    <AuthorizedRoute
      right={VIEW_CORP_SETTINGS_MENU}
      path={ROUTES.MAIN.CORPORATE_SETTING}
      component={CorporateSettings}
    />
    <Route path={ROUTES.MAIN.BRANCHES_ATMS} component={BranchesAtms} />
    <Route path={ROUTES.MAIN.CREATE_SIGNING_RULE} component={CreateSigningRule} />
    <Route path={ROUTES.MAIN.DELETE_SIGNING_RULE} component={DeleteSigningRule} />
    <Route path={ROUTES.MAIN.ENABLE_SIGNING_RULE} component={EnableSigningRule} />
    <AuthorizedRoute
      exact
      right={VIEW_WAITING_ROOM}
      path={`${ROUTES.SIGNING_PROCEDURES.DETAIL}/:id`}
      component={SigningInstructionDetail}
    />
    <AuthorizedRoute
      right={VIEW_WAITING_ROOM}
      path={ROUTES.SIGNING_PROCEDURES.CONFIRM_MULTIPLE}
      component={SigningProceduresConfirmMultiple}
    />
    <AuthorizedRoute
      right={VIEW_WAITING_ROOM}
      path={ROUTES.MAIN.SIGNING_PROCEDURES}
      component={SigningProceduresList}
    />
    <Route component={PageNotFound} />
  </Switch>
)
