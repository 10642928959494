import React, { PureComponent } from 'react'
import { TableData, Table, onSortEndHandler } from 'vtb-ui'

const sorter = ({ data, sorting: { columnTitle, direction } }) => {
  // SIMULATE sorting
  const selector = (datum) => datum[columnTitle]
  const newData = [...data.sort((a, b) => {
    const propA = selector(a)
    const propB = selector(b)
    const valueA = typeof propA === 'string' ? propA.toUpperCase() : propA
    const valueB = typeof propB === 'string' ? propB.toUpperCase() : propB
    if (valueA < valueB) {
      return direction === 'asc' ? -1 : 1
    }
    if (valueA > valueB) {
      return direction === 'asc' ? 1 : -1
    }
    return 0
  })]
  return { data: newData }
}

export interface ITableContainerState {
  tableData: any
  loading?
  scrollToTopEnabled?
}

export class TableContainer extends PureComponent<any, ITableContainerState> {
  constructor(props) {
    super(props)
    const { data, columns, settingsComponent } = props.tableDataSource
    let { options } = props.tableDataSource
    options = {
      ...options,
      onSortClick: this.onSortClick,
      onRowClick: options.onRowClick || this.onRowClick,
      onSelectRow: this.onSelectRow,
      onSelectAllRows: this.onSelectAllRows,
      showSettings: false,
      showFullScreen: false,
      onSortEnd: ({ oldIndex, newIndex }) => {
        this.setState((prevState) => {
          const sortedData = onSortEndHandler({ items: prevState.tableData.data, oldIndex, newIndex })
          if (this.props.saveSortingHandler) {
            this.props.saveSortingHandler(sortedData)
          }
          return {
            tableData: { ...prevState.tableData, data: sortedData },
          }
        })
      },
    }
    this.state = {
      tableData: { ...new TableData(data, columns, options, null, null), settingsComponent },
    }
  }

  onSortClick = (headerIndex) => {
    this.setState((prevState) => {
      const prevTableData = prevState.tableData
      const columnTitle = prevTableData.columns[headerIndex].title
      const direction = prevTableData.sorting ? (prevTableData.sorting.direction === 'asc' ? 'desc' : 'asc') : 'asc'
      prevTableData.sorting = { columnTitle, direction }

      const { data } = sorter(prevState.tableData)
      return {
        ...prevState,
        tableData: {
          ...prevState.tableData,
          data,
        },
      }
    })
  }

  onSelectRow() {

  }

  onSelectAllRows() {

  }

  // TODO: predelat tuhle prasarnu TableContainer a prepisovany onRowClick
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRowClick(row, rowIndex, event) {

  }

  render() {
    const { tableData: { options: { tableId } } } = this.state
    const { dataCy, components, tableDataSource: tableData } = this.props
    return (
      <Table
        id={tableId}
        dataCy={dataCy}
        tableData={tableData}
        components={{ ...Table.defaultProps.components, ...components }}
      />
    )
  }
}
