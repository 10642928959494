var levels = {
  0: [0, 0, 0, 0],
  1: [0, 2, 5, -2],
  2: [0, 3, 10, -3],
  3: [0, 4, 18, -5],
  4: [0, 10, 25, -8],
  5: [0, 13, 35, -10],
  6: [0, 10, 45, -10],
  7: [0, 0, 50, -20]
};
var boxShadow = function boxShadow(_ref) {
  var theme = _ref.theme,
      shadow = _ref.shadow,
      shadowColor = _ref.shadowColor,
      background = _ref.background;

  if (!shadow) {
    return;
  }

  var l = levels[shadow] || [0, 0, shadow, 0];
  var color = theme.colors[shadowColor] || shadowColor || theme.colors[background] || background || theme.colors.neutralPrimary;
  return "box-shadow: ".concat(color, " ").concat(l[0], "px ").concat(l[1], "px ").concat(l[2], "px ").concat(l[3], "px;");
};

export { boxShadow };
