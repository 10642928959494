import { format } from 'date-fns';
import { CURRENCY_EXCHAGE_RATES } from 'vtb-utils';

var mapInstruction = function mapInstruction(values) {
  var debitAccount = values.debitAccount;
  return {
    accountNumber: debitAccount.id
  };
};
var mapCurrencyExchangeInstructionItem = function mapCurrencyExchangeInstructionItem(values) {
  var debitAccount = values.debitAccount,
      creditAccount = values.creditAccount,
      sourceAmount = values.sourceAmount,
      targetAmount = values.targetAmount,
      convertMoney = values.convertMoney,
      operationType = values.operationType,
      customerId = values.customerId,
      channelId = values.channelId;

  var _ref = convertMoney || {},
      bestRateType = _ref.bestRateType,
      standardRate = _ref.standardRate,
      channelRate = _ref.channelRate,
      customerRate = _ref.customerRate;

  var usedRate = bestRateType === CURRENCY_EXCHAGE_RATES.CHR ? channelRate : bestRateType === CURRENCY_EXCHAGE_RATES.CUS ? customerRate : standardRate;
  var payLoad = {
    customerId: customerId,
    debitAccountId: debitAccount.id,
    creditAccountId: creditAccount.id,
    fromCcy: debitAccount.currency,
    toCcy: creditAccount.currency,
    fromAmount: sourceAmount,
    toAmount: targetAmount,
    date: format(new Date(), 'YYYY-MM-DD'),
    purpose: 'Conversion',
    transactionReference: '-',
    // on trunas request
    channelId: channelId,
    'rate__multiplier': usedRate.multiplier,
    'rate__value': usedRate.value,
    'rate__isReversed': usedRate.isReversed,
    operationType: operationType,
    rateType: bestRateType,
    requestedRate: false // sourceTemplate: null,

  };
  return payLoad;
};

export { mapCurrencyExchangeInstructionItem, mapInstruction };
