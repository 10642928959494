import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { pipe, filter, equals, map, find, propEq } from 'ramda';
import { isPropNotEmpty } from 'vtb-utils';
import { LOAN_COLLATERAL_TYPES } from '../loans.constants.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var isMortgage = function isMortgage(collateral) {
  return collateral.type && collateral.type.groupName === LOAN_COLLATERAL_TYPES.MORTGAGE;
};

var isTransport = function isTransport(collateral) {
  return collateral.type && collateral.type.groupName === LOAN_COLLATERAL_TYPES.TRANSPORT;
};

var isDeposit = function isDeposit(collateral) {
  return collateral.type && collateral.type.groupName === LOAN_COLLATERAL_TYPES.DEPOSIT;
};

var isBail = function isBail(collateral) {
  return collateral.type && collateral.type.groupName === LOAN_COLLATERAL_TYPES.BAIL;
};

var isOther = function isOther(collateral) {
  return collateral.type && collateral.type.groupName === LOAN_COLLATERAL_TYPES.OTHER;
};

var sortByTypeName = function sortByTypeName(collateral) {
  return {
    mortgage: filter(isMortgage, collateral),
    transport: filter(isTransport, collateral),
    deposit: filter(isDeposit, collateral),
    bail: filter(isBail, collateral),
    other: filter(isOther, collateral)
  };
};

var getDepositAccountName = function getDepositAccountName(_ref) {
  var loanAccount = _ref.loanAccount,
      accounts = _ref.accounts,
      collaterals = _ref.collaterals;
  return map(function (collateral) {
    if (!isDeposit(collateral)) {
      return collateral;
    }

    var depositAccount = find(propEq('cbsID', collateral.depositAccountId))(accounts);
    var accountName;
    var fieldLabel = 'loans.detail.collateral.deposit.iban.label';

    if (depositAccount) {
      accountName = depositAccount.ibanAccount;

      if (isPropNotEmpty(depositAccount.friendlyName)) {
        accountName = "".concat(depositAccount.friendlyName, " - ").concat(accountName);
        fieldLabel = 'loans.detail.collateral.deposit.nickname.label';
      }
    } else {
      accountName = loanAccount.ibanAccount;
    }

    return _objectSpread(_objectSpread({}, collateral), {}, {
      accountName: accountName,
      fieldLabel: fieldLabel
    });
  })(collaterals);
};
var checkCollateralAccounts = function checkCollateralAccounts(_ref4) {
  var accountId = _ref4.accountId,
      collaterals = _ref4.collaterals;
  return pipe(filter(function (_ref5) {
    var depositAccountId = _ref5.depositAccountId;
    return depositAccountId && !equals(depositAccountId, accountId);
  }), map(function (_ref6) {
    var depositAccountId = _ref6.depositAccountId;
    return String(depositAccountId);
  }))(collaterals);
};
var processCollateral = function processCollateral(_ref7) {
  var loanAccount = _ref7.loanAccount,
      accounts = _ref7.accounts,
      collaterals = _ref7.collaterals;
  var withDeposits = getDepositAccountName({
    loanAccount: loanAccount,
    accounts: accounts,
    collaterals: collaterals
  });
  return sortByTypeName(withDeposits);
};

export { checkCollateralAccounts, processCollateral };
