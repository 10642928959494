import _defineProperty from '@babel/runtime/helpers/defineProperty';
import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { takeLatest } from 'redux-saga/effects';
import { zipObj, repeat } from 'ramda';
import { LOAN_CATEGORIES } from '../loans.constants.js';
import { loadLoansFlow } from './loans-list.flow.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var loanCategories = Object.values(LOAN_CATEGORIES);
var initialStateLoans = [];
var loansByCategory = zipObj(loanCategories, repeat([], loanCategories.length));
var loansListFragment = function loansListFragment() {
  return {
    actions: {
      resetLoans: function resetLoans() {
        return {};
      },
      fetchRequirements: function fetchRequirements() {
        return {};
      },
      loadLoans: function loadLoans() {
        return {};
      },
      loansDataLoaded: function loansDataLoaded(loans) {
        return {
          loans: loans
        };
      },
      loansByCategoryLoaded: function loansByCategoryLoaded(loansByCategory) {
        return {
          loansByCategory: loansByCategory
        };
      },
      changeLocalLoanName: function changeLocalLoanName(itemId, friendlyName) {
        return {
          itemId: itemId,
          friendlyName: friendlyName
        };
      }
    },
    state: {
      loans: {
        'actions.loansDataLoaded': function actionsLoansDataLoaded(_, _ref) {
          var loans = _ref.loans;
          return loans;
        },
        'actions.loadError': function actionsLoadError() {
          return null;
        },
        'actions.resetLoans': function actionsResetLoans() {
          return [];
        },
        'actions.changeLocalLoanName': function actionsChangeLocalLoanName(prevState, _ref2) {
          var itemId = _ref2.itemId,
              friendlyName = _ref2.friendlyName;
          var index = prevState.findIndex(function (loan) {
            return loan.cbsLoanId === itemId;
          });

          if (index !== -1) {
            var newState = _toConsumableArray(prevState);

            newState[index] = _objectSpread(_objectSpread({}, newState[index]), {}, {
              friendlyName: friendlyName
            });
            return _toConsumableArray(newState);
          } else {
            return _toConsumableArray(prevState);
          }
        }
      },
      loansByCategory: {
        'actions.loansByCategoryLoaded': function actionsLoansByCategoryLoaded(_, _ref3) {
          var loansByCategory = _ref3.loansByCategory;
          return loansByCategory;
        }
      }
    },
    selectors: {
      loans: [['loans']],
      loansByCategory: [['loansByCategory']]
    },
    flows: {
      loadLoansFlow: ['actions.loadLoans', takeLatest, loadLoansFlow]
    },
    initialState: {
      loans: initialStateLoans,
      loansByCategory: loansByCategory,
      loansLoaded: false,
      loansLoadFailed: false
    }
  };
};

export { loansListFragment };
