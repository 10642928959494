import { composeFragments, validationRule } from 'core-ingredients';
import { fieldsFragment, fieldFragment } from 'core-recipes';
import { requiredRule } from '../../../utils/validators.js';

var basketFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'debitAccount',
  rules: [validationRule(requiredRule)]
})));

export { basketFieldsFragment };
