import { createSelector } from 'reselect';

var clientSystemSelector = function clientSystemSelector(state) {
  var _state$app;

  return state === null || state === void 0 ? void 0 : (_state$app = state.app) === null || _state$app === void 0 ? void 0 : _state$app.clientSystem;
};
var userAgentSelector = createSelector(function (state) {
  var _state$app2;

  return state === null || state === void 0 ? void 0 : (_state$app2 = state.app) === null || _state$app2 === void 0 ? void 0 : _state$app2.userAgentInfo;
}, function (userAgentInfo) {
  if (userAgentInfo) {
    var appName = userAgentInfo.appName,
        appVersion = userAgentInfo.appVersion,
        appBuild = userAgentInfo.appBuild,
        systemName = userAgentInfo.systemName,
        systemVersion = userAgentInfo.systemVersion,
        deviceType = userAgentInfo.deviceType;
    return "".concat(appName, "/").concat(appVersion, " (Build/").concat(appBuild, "; ").concat(systemName, " ").concat(systemVersion, ") ").concat(deviceType);
  }

  return null;
});

export { clientSystemSelector, userAgentSelector };
