import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { whiteColor, tooltipBackground } from '../../../../theme/colors.js';

var _templateObject;

var TooltipWrapperView = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  visibility: ", ";\n  opacity: ", ";\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  bottom: ", ";\n  left: ", ";\n  width: auto;\n  padding: 8px;\n  color: ", ";\n  background-color: ", ";\n  transform: translateY(\n    ", "\n  );\n  z-index: 98989;\n  ::after {\n    content: '';\n    display: block;\n    position: absolute;\n    width: 16px;\n    height: 16px;\n    background: ", ";\n    top: ", ";\n    right: ", ";\n    bottom: ", ";\n    left: ", ";\n    transform: rotate(45deg);\n  }\n  transition: transform 250ms, visibility 0s, opacity 250ms linear;\n"])), function (_ref) {
  var open = _ref.open;
  return open ? 'visible' : 'hidden';
}, function (_ref2) {
  var open = _ref2.open;
  return open ? '1' : '0';
}, function (_ref3) {
  var top = _ref3.top;
  return top ? '-12px' : '';
}, function (_ref4) {
  var right = _ref4.right;
  return right ? '0' : '';
}, function (_ref5) {
  var bottom = _ref5.bottom;
  return bottom ? '-12px' : '';
}, function (_ref6) {
  var left = _ref6.left,
      right = _ref6.right;
  return left ? '0' : right ? '' : '50%';
}, whiteColor, tooltipBackground, function (_ref7) {
  var open = _ref7.open,
      top = _ref7.top,
      bottom = _ref7.bottom;
  return open ? top ? '-100%' : bottom ? '100%' : '' : top ? '-80%' : bottom ? '80%' : '';
}, tooltipBackground, function (_ref8) {
  var bottom = _ref8.bottom;
  return bottom ? '-8px' : '';
}, function (_ref9) {
  var right = _ref9.right;
  return right ? '16px' : '';
}, function (_ref10) {
  var top = _ref10.top;
  return top ? '-8px' : '';
}, function (_ref11) {
  var left = _ref11.left;
  return left ? '16px' : '';
});

export default TooltipWrapperView;
