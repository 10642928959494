import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeLatest } from 'redux-saga/effects';
import { loadAccountsDetailFlow } from './accounts-detail.flow.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var accountsDetailFragment = function accountsDetailFragment() {
  return {
    actions: {
      load: function load(iban) {
        return {
          iban: iban
        };
      },
      loaded: function loaded(detail) {
        return {
          detail: detail
        };
      },
      modalToggle: function modalToggle() {
        return {};
      },
      overdraftToggle: function overdraftToggle(accountId) {
        return {
          accountId: accountId
        };
      },
      changeLocalAccountName: function changeLocalAccountName(friendlyName) {
        return {
          friendlyName: friendlyName
        };
      }
    },
    state: {
      detail: {
        'actions.load': function actionsLoad() {
          return null;
        },
        'actions.loaded': function actionsLoaded(_, _ref) {
          var detail = _ref.detail;
          return detail;
        },
        'actions.changeLocalAccountName': function actionsChangeLocalAccountName(prevState, _ref2) {
          var friendlyName = _ref2.friendlyName;
          return _objectSpread(_objectSpread({}, prevState), {}, {
            friendlyName: friendlyName
          });
        }
      },
      failed: {
        'actions.init': function actionsInit() {
          return false;
        },
        'actions.load': function actionsLoad() {
          return false;
        },
        'actions.loadError': function actionsLoadError() {
          return true;
        }
      },
      loading: {
        'actions.load': function actionsLoad() {
          return true;
        },
        'actions.loaded': function actionsLoaded() {
          return false;
        },
        'actions.loadError': function actionsLoadError() {
          return false;
        }
      },
      modalDisplay: {
        'actions.modalToggle': function actionsModalToggle(modalDisplay) {
          return !modalDisplay;
        }
      },
      iban: {
        'actions.load': function actionsLoad(_, _ref3) {
          var iban = _ref3.iban;
          return iban;
        }
      },
      overdraftDisplay: {
        'actions.load': function actionsLoad() {
          return false;
        },
        'actions.overdraftToggle': function actionsOverdraftToggle(overdraftDisplay) {
          return !overdraftDisplay;
        }
      },
      overdraftAccountId: {
        'actions.overdraftToggle': function actionsOverdraftToggle(_, _ref4) {
          var accountId = _ref4.accountId;
          return accountId;
        }
      },
      restrictions: {
        'actions.load': function actionsLoad() {
          return null;
        },
        'actions.restrictionsDataLoaded': function actionsRestrictionsDataLoaded(_, _ref5) {
          var restrictions = _ref5.restrictions;
          return restrictions;
        }
      }
    },
    selectors: {
      failed: [['failed']],
      detail: [['detail']],
      iban: [['iban']],
      overdraftAccountId: [['overdraftAccountId']],
      restrictions: [['restrictions']]
    },
    flows: {
      loadDetail: ['actions.load', takeLatest, loadAccountsDetailFlow]
    },
    initialState: {
      detail: null,
      failed: false,
      loading: true,
      modalDisplay: false,
      iban: null,
      overdraftDisplay: false,
      overdraftAccountId: null,
      restrictions: null
    }
  };
};

export { accountsDetailFragment };
