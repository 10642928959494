import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { getLoanRestrictions } from '../../loans/list/loans-list.queries.js';
import { LOAN_PRODUCT_TYPES } from '../../loans/loans.constants.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var accountOverdraftQuery = function accountOverdraftQuery(iban) {
  return {
    Loans: ['loanAmount', 'cbsLoanId', {
      account: ['cbsID']
    }, {
      currency: ['currencyID']
    }, {
      loanStatus: ['hostStatusCode']
    }],
    where: _objectSpread({
      'productType.detailParamsGroup.entityUniqueId': {
        inlist: [LOAN_PRODUCT_TYPES.CREDIT_CARD, LOAN_PRODUCT_TYPES.OVERDRAFT, LOAN_PRODUCT_TYPES.BUSINESS_OVERDRAFT]
      },
      'account.ibanAccount': iban
    }, getLoanRestrictions())
  };
};

export { accountOverdraftQuery };
