var INSTRUCTION_TYPES = {
  cardFriendlyNameUpdate: 'CardFriendlyNameUpdate',
  accountFriendlyNameUpdate: 'AccountFriendlyNameUpdate',
  loanFriendlyNameUpdate: 'LoanFriendlyNameUpdate',
  depositFriendlyNameUpdate: 'DepositFriendlyNameUpdate',
  changeCertificationSecurityLevel: 'ChangeCertificationSecLevel',
  changeAuthenticationSecurityLevel: 'ChangeAuthenticationSecLevel',
  standingOrderFriendlyNameUpdate: 'StandingOrderFriendlyNameUpdate',
  cardBlock: 'cardBlock',
  cardUnblock: 'cardUnblock',
  paymentOwnAccount: 'paymentOwnAccount',
  paymentOwnAccountCert: 'paymentOwnAccountCert',
  mobileTopUp: 'mobileTopUp',
  mobileTopUpCert: 'mobileTopUpCert',
  billPayment: 'billPayment',
  billPaymentCert: 'billPaymentCert',
  billPaymentTemplateGroup: 'BillPaymentTemplateGroup',
  billPaymentTemplate: 'BillPaymentTemplate',
  toSomeoneElseDomestic: 'paymentInterBankLcy',
  toSomeoneElseSWIFT: 'paymentInterBankFcy',
  toSomeoneElseIntraBank: 'paymentIntraBank',
  standingOrderDelete: 'StandingOrderDelete',
  standingOrder: 'StandingOrder',
  carTemplate: 'CarTemplate',
  treasuryTransfer: 'paymentTreasury',
  currencyExchange: 'paymentCurrencyExchange',
  currencyExchangeCert: 'paymentCurrencyExchangeCert',
  requestCurrencyExchange: 'requestCurrencyExchange',
  accountPriorityCurrencyChange: 'AccountPriorityCurrencyChange',
  changePassword: 'changePassword',
  removeDevice: 'RemoveDeviceRegistration',
  blockDevice: 'BlockDeviceRegistration',
  unblockDevice: 'UnblockDeviceRegistration',
  clientUpdateChangeAvatar: 'ClientUpdateChangeAvatar',
  clientUpdate: 'ClientUpdate',
  paymentTemplate: 'PaymentTemplate',
  paymentTemplateGroup: 'PaymentTemplateGroup',
  customProductIconAccount: 'CustomProductIconAccount',
  messageToBank: 'MessageToBank',
  CertifiedMessageToBank: 'CertifiedMessageToBank',
  customProductIconLoan: 'CustomProductIconLoan',
  customProductIconDeposit: 'CustomProductIconDeposit',
  changeIncomingMessageStatusRequest: 'ChangeIncomingMessageStatusRequest',
  createDeviceRegistration: 'CreateDeviceRegistration',
  changeLoginName: 'ChangeLoginName',
  batchUnblockDeviceRegistration: 'BatchUnblockDeviceRegistration',
  changePinDeviceRegistration: 'ChangePinDeviceRegistration',
  billPaymentCertParkingFines: 'billPaymentCert:PARKINGFINES',
  billPaymentParkingFines: 'billPayment:PARKINGFINES',
  billPaymentCertParkingPass: 'billPaymentCert:PARKINGPASS',
  billPaymentParkingPass: 'billPayment:PARKINGPASS',
  batchConcept: 'BatchConcept',
  BatchConceptItem: 'BatchConceptItem',
  batchConceptItemSaveDelete: 'BatchConceptItemSaveDelete',
  batchPayment: 'BatchPayment',
  directDebit: 'DirectDebit',
  directDebitCert: 'DirectDebitCert',
  directDebitDelete: 'DirectDebitDelete',
  ddFriendlyNameUpdate: 'DDFriendlyNameUpdate',
  ddIconUpdate: 'DDIconUpdate',
  paymentCCDecision: 'paymentCCDecision',
  corpSetDeleteUser: 'CorpSetDeleteUser',
  corpSetDisableUser: 'CorpSetDisableUser',
  corpSetEnableUser: 'CorpSetEnableUser',
  corpSetCreateUser: 'CorpSetCreateUser',
  corpSetInviteUser: 'CorpSetInviteUser',
  corpSetInvitationDecision: 'CorpSetInvitationDecision',
  corpSetChangeUser: 'CorpSetChangeUser',
  corpSetCreateRole: 'CorpSetCreateRole',
  corpSetDeleteRole: 'CorpSetDeleteRole',
  notificationSettingsRetail: 'NotificationSettingRetail',
  notificationSettingsCorporate: 'NotificationSettingCorporate',
  CorpSetEnableSignRule: 'CorpSetEnableSignRule',
  CorpSetDisableSignRule: 'CorpSetDisableSignRule',
  CorpSetDeleteSignRule: 'CorpSetDeleteSignRule',
  corpSetCreateSignRule: 'CorpSetCreateSignRule',
  corpSetEditSignRule: 'CorpSetEditSignRule',
  paymentTreasuryCert: 'paymentTreasuryCert',
  addCardIntoAppleWallet: 'AddCardIntoAppleWallet',
  requestBonusPointsCashOut: 'requestBonusPointsCashOut',
  createBankOffered: 'CreateBankOffered',
  extendConsent: 'ExtendConsent',
  deleteConsent: 'DeleteConsent'
};

export { INSTRUCTION_TYPES };
