import { composeFragments, action, state, stateFlag } from 'core-ingredients';
import { withVTBCertificationFragment } from '../../payment-centre/common/withVTBCertificationFragment.js';
import { instructionIdsFragment } from '../../payment-centre/common/instruction-logic.js';
import { verifyAvailableBalanceFragment } from '../../payment-centre/common/helpers.js';

var basicRateErrors = [];
var basicBalanceErrors = [];
var signingProceduresSignFragment = composeFragments(withVTBCertificationFragment, verifyAvailableBalanceFragment, instructionIdsFragment, action('submit'), action('confirm'), action('calling'), action('failed'), action('GMBPrepareCertification'), state(['instructionType', 'setInstructionType'], function (_, _ref) {
  var value = _ref.value;
  return value;
}, null), state(['certifiable', 'certificationSubmited'], function (_, _ref2) {
  var certifiable = _ref2.certifiable;
  return certifiable;
}, false), state(['balanceErrors', 'setBalanceErrors'], function (_, _ref3) {
  var errors = _ref3.errors;
  return errors;
}, basicBalanceErrors), state(['rateErrors', 'setRateErrors'], function (_, _ref4) {
  var errors = _ref4.errors;
  return errors;
}, basicRateErrors), state(['balanceErrors', 'reset'], function () {
  return basicBalanceErrors;
}), state(['rateErrors', 'reset'], function () {
  return basicRateErrors;
}), state(['balanceErrors', 'confirm'], function () {
  return basicBalanceErrors;
}), state(['rateErrors', 'confirm'], function () {
  return basicRateErrors;
}), state(['totalFee', 'setTotalFee'], function (_, _ref5) {
  var fee = _ref5.fee;
  return fee;
}, 0), state(['totalFee', 'reset'], function () {
  return 0;
}), state(['balanceErrorsMessage', 'setBalanceErrorsMessage'], function (_, _ref6) {
  var message = _ref6.message;
  return message;
}, null), state(['balanceErrorsMessage', 'reset'], function () {
  return null;
}), stateFlag('certifying', ['GMBPrepareCertification', 'certifyWithBalanceValidation', 'verifyingAvailableBalance'], ['failed', 'reset', 'resetForm', 'stop'], false));

export { signingProceduresSignFragment };
