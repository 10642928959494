import { takeLatest } from 'redux-saga/effects';
import { composeFragments, pathFragment, action, flow, resolveAction, state, stateFlag, resolveLogic } from 'core-ingredients';
import { deleteSigningRuleFlow } from './signing-rules-delete.flow.js';

var signingRulesDeleteLogicSchema = composeFragments(pathFragment('signingRules', 'delete'), action('deleteSigningRule'), flow('deleteSigningRuleFlow', [resolveAction('deleteSigningRule'), takeLatest, deleteSigningRuleFlow]), action('updateCertificationStatus'), action('setCertificationState', function (certificationState) {
  return {
    certificationState: certificationState
  };
}), state(['certificationState', 'setCertificationState'], function (_, _ref) {
  var certificationState = _ref.certificationState;
  return certificationState;
}), stateFlag('isProcessing', ['deleteSigningRule'], ['setCertificationState'], false));
var signingRulesDeleteLogicAPI = resolveLogic(signingRulesDeleteLogicSchema);
var signingRulesDeleteLogic = signingRulesDeleteLogicAPI.logic;
var signingRulesDeleteReducer = signingRulesDeleteLogicAPI.reducer;
var signingRulesDeleteSaga = signingRulesDeleteLogicAPI.saga;

export { signingRulesDeleteLogic, signingRulesDeleteLogicAPI, signingRulesDeleteReducer, signingRulesDeleteSaga };
