import { find, propEq } from 'ramda';
import { memoize } from 'vtb-utils';

var sysParamSelectorFactory = function sysParamSelectorFactory(param) {
  return function (state) {
    var systemParams = state && state.app && state.app.systemParams;

    if (!systemParams) {
      // eslint-disable-next-line no-console
      console.warn('SystemParams are null!', systemParams);
      return null;
    }

    var item = find(propEq('code', param))(systemParams);

    if (item) {
      return item.value;
    }

    return null;
  };
};
/**
 * Returns redux selector for given system parameter
 *
 * @example
 * const ADMIN_CONTRACT_GENERATION_ALLOW_EDITABLE = yield select(
 *  systemParamSelectorFactory('ADMIN_CONTRACT_GENERATION_ALLOW_EDITABLE'))
 */


var systemParamSelectorFactory = memoize(sysParamSelectorFactory);

export { systemParamSelectorFactory };
