import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { carsListFragment } from './cars-list.fragment.js';
import { editCarIconFieldsFragment } from './edit-car-icon.fields.js';

var carsListLogic = logic2(composeFragments(pathFragment('cars', 'list'), editCarIconFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, carsListFragment));
var CarsListLogicReducer = carsListLogic.reducer;
var CarsListLogicSaga = sagaifyLogic2(carsListLogic);

export { CarsListLogicReducer, CarsListLogicSaga, carsListLogic };
