import { takeLatest } from 'redux-saga/effects';
import { SORT } from 'vtb-utils';
import { LOAN_DETAIL_TABS, loadLoansDetailFlow, loadHistoryFlow } from './loans-detail.flows.js';
export { LOAN_DETAIL_TABS } from './loans-detail.flows.js';

var repaymentScheduleDefaultSort = {
  columnTitle: 'date',
  direction: SORT.ASC
};
var loansDetailFragment = function loansDetailFragment() {
  return {
    actions: {
      loadDetail: function loadDetail(id, displayedTab) {
        return {
          id: id,
          displayedTab: displayedTab
        };
      },
      loadHistory: function loadHistory(id) {
        return {
          id: id
        };
      },
      detailLoaded: function detailLoaded(detail, displayedTab) {
        return {
          detail: detail,
          displayedTab: displayedTab
        };
      },
      historyLoaded: function historyLoaded(history) {
        return {
          history: history
        };
      }
    },
    state: {
      detail: {
        'actions.loadDetail': function actionsLoadDetail() {
          return null;
        },
        'actions.detailLoaded': function actionsDetailLoaded(_, _ref) {
          var detail = _ref.detail;
          return detail;
        }
      },
      history: {
        'actions.loadHistory': function actionsLoadHistory() {
          return null;
        },
        'actions.historyLoaded': function actionsHistoryLoaded(_, _ref2) {
          var history = _ref2.history;
          return history;
        }
      },
      historyLoadFailed: {
        'actions.loadHistory': function actionsLoadHistory() {
          return false;
        },
        'actions.historyLoaded': function actionsHistoryLoaded() {
          return false;
        },
        'actions.historyLoadError': function actionsHistoryLoadError() {
          return true;
        }
      },
      repaymentScheduleLoadFailed: {
        'actions.loadDetail': function actionsLoadDetail() {
          return false;
        },
        'actions.repaymentScheduleLoadError': function actionsRepaymentScheduleLoadError() {
          return true;
        }
      },
      loadingHistory: {
        'actions.loadHistory': function actionsLoadHistory() {
          return true;
        },
        'actions.historyLoaded': function actionsHistoryLoaded() {
          return false;
        },
        'actions.historyLoadError': function actionsHistoryLoadError() {
          return false;
        }
      },
      repaymentScheduleSorting: {
        'actions.updateRepaymentScheduleSorting:': function actionsUpdateRepaymentScheduleSorting(_, sorting) {
          return sorting;
        }
      },
      displayedTab: {
        'actions.setDisplayedTab': function actionsSetDisplayedTab(_, _ref3) {
          var displayedTab = _ref3.displayedTab;
          return displayedTab;
        },
        'actions.showPaymentHistoryTab': function actionsShowPaymentHistoryTab() {
          return LOAN_DETAIL_TABS.HISTORY;
        },
        'actions.detailLoaded': function actionsDetailLoaded(_, _ref4) {
          var displayedTab = _ref4.displayedTab;
          return displayedTab || LOAN_DETAIL_TABS.DETAIL;
        }
      }
    },
    selectors: {
      detail: [['detail']]
    },
    flows: {
      loadDetail: ['actions.loadDetail', takeLatest, loadLoansDetailFlow],
      loadHistory: ['actions.loadHistory', takeLatest, loadHistoryFlow]
    },
    initialState: {
      detail: null,
      displayedTab: LOAN_DETAIL_TABS.DETAIL,
      repaymentScheduleSorting: repaymentScheduleDefaultSort
    }
  };
};

export { loansDetailFragment };
