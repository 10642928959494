import { takeLatest } from 'redux-saga/effects';
import { resolveLogic, composeFragments, pathFragment, action, state, stateFlag, flow, resolveAction } from 'core-ingredients';
import { listFragment, configResetListFragment } from 'core-recipes';
import { OPEN_BANKING_ACCOUNT_TRANSACTIONS_RESPONSE_SIZE } from '../common.js';
import { openBankingDetailFlow } from './open-banking-detail.flow.js';

var initialItems = [];
var openBankingBanksQuery = {
  OpenBankingTransactionCaches: ['transactionId', 'externalClientId', 'aspsp', 'iban', 'amount', 'currency', 'status', 'creditorName', 'creditorAccountIban', 'debtorName', 'debtorAccountIban', 'bookingValueDate'],
  limit: OPEN_BANKING_ACCOUNT_TRANSACTIONS_RESPONSE_SIZE
};
var openBankingDetailLogicAPI = resolveLogic(composeFragments(pathFragment('openBankingDetail'), listFragment({
  listName: 'transactionCaches',
  entityName: 'OpenBankingTransactionCaches',
  listQuery: openBankingBanksQuery
}, configResetListFragment({})), action('init'), action('loadMore'), state(['items', 'init'], function () {
  return initialItems;
}, initialItems), state(['afterCursor', 'itemsDataLoaded'], function (_, _ref) {
  var afterCursor = _ref.afterCursor;
  return Number(afterCursor) || null;
}), state(['allItems', 'itemsDataLoaded'], function (_, _ref2) {
  var allItems = _ref2.allItems;
  return allItems;
}, []), state(['items', 'itemsDataLoaded'], function (_, _ref3) {
  var items = _ref3.items;
  return items;
}), state(['afterCursor', 'init'], function () {
  return null;
}, null), state(['gdsAfterCursor', 'transactionCachesDataLoaded'], function (_, _ref4) {
  var _transactionCaches$cu;

  var transactionCaches = _ref4.transactionCaches;
  return transactionCaches === null || transactionCaches === void 0 ? void 0 : (_transactionCaches$cu = transactionCaches.cursors) === null || _transactionCaches$cu === void 0 ? void 0 : _transactionCaches$cu.after;
}, null), state(['gdsAfterCursor', 'init'], function () {
  return null;
}, null), state(['allItems', 'init'], function () {
  return [];
}), state(['currentPage', 'init'], function () {
  return 0;
}, 0), state(['currentPage', 'setPage'], function (_, _ref5) {
  var page = _ref5.page;
  return page;
}), state(['errorType', 'init'], function () {
  return null;
}, null), state(['errorType', 'loadMore'], function () {
  return null;
}), state(['errorType', 'setErrorType'], function (_, _ref6) {
  var errorType = _ref6.errorType;
  return errorType;
}), stateFlag('itemsLoading', ['startLoading'], ['init', 'itemsDataLoaded'], false), flow('initFlow', [resolveAction('init'), takeLatest, openBankingDetailFlow]), flow('loadMore', [resolveAction('loadMore'), takeLatest, openBankingDetailFlow])));
var openBankingDetailLogic = openBankingDetailLogicAPI.logic;
var openBankingDetailReducer = openBankingDetailLogicAPI.reducer;
var openBankingDetailSaga = openBankingDetailLogicAPI.saga;

export { openBankingDetailLogic, openBankingDetailReducer, openBankingDetailSaga };
