import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { parse, differenceInCalendarDays, getTime, subDays } from 'date-fns';
import { path } from 'ramda';
import { isInExpirationPeriod } from 'vtb-utils';

var CARD_STATES = {
  Printed: ['0', 'Printed'],
  DeliveredToBranch: ['21', 'DeliveredToBranch'],
  DeliveredToCustomer: ['30', 'DeliveredToCustomer'],
  InDeliveryBranch: ['26', 'InDeliveryBranch'],
  TransferredToCourier: ['29', 'TransferredToCourier']
};
var isExpired = function isExpired(card) {
  return ['90', 'Expired'].includes(path(['cardStatus', 'cardStatusId'], card));
};
var isInBeforeExpirationPeriod = function isInBeforeExpirationPeriod(card, MCH_CARD_BEFOREEXPIRE_WARNING) {
  if (['90', 'Expired'].includes(path(['cardStatus', 'cardStatusId'], card))) {
    return {
      isInBeforeExpirationPeriod: false,
      daysToExpiration: 0
    };
  }

  var parsedDate = parse(card.validTo);
  var today = new Date();
  var remainingDays = differenceInCalendarDays(parsedDate, today);
  return {
    isInExpirationPeriod: isInExpirationPeriod(card.validTo, MCH_CARD_BEFOREEXPIRE_WARNING),
    daysToExpiration: Math.max(0, remainingDays)
  };
};
var isInBeforeDueDayPeriod = function isInBeforeDueDayPeriod(nextInstallmentDate, days) {
  var parsedDate = parse(nextInstallmentDate);
  var startOfNotificationPeriod = getTime(subDays(parsedDate, days));
  var dueDay = getTime(parsedDate);
  var today = getTime(new Date());
  return startOfNotificationPeriod <= today && today < dueDay;
};
var isCardReady = function isCardReady(card) {
  return [].concat(_toConsumableArray(CARD_STATES.InDeliveryBranch), _toConsumableArray(CARD_STATES.DeliveredToBranch)).includes(path(['cardState', 'cardStateId'], card));
};
var isCardOnItsWay = function isCardOnItsWay(card) {
  return CARD_STATES.TransferredToCourier.includes(path(['cardState', 'cardStateId'], card));
};
var isCardNew = function isCardNew(card) {
  return ['New', '0'].includes(path(['cardStatus', 'entityUniqueId'], card));
};
var getCardStateText = function getCardStateText(card) {
  var text = null;
  var cardStateId = path(['cardState', 'cardStateId'], card);

  if (CARD_STATES.Printed.includes(cardStateId)) {
    text = 'cards.cardDetail.footer.printed.text';
  } else if (CARD_STATES.DeliveredToBranch.includes(cardStateId)) {
    text = 'cards.cardDetail.footer.deliveredToBranch.text';
  } else if (CARD_STATES.DeliveredToCustomer.includes(cardStateId)) {
    text = 'cards.cardDetail.footer.deliveredToCustomer.text';
  } else if (CARD_STATES.InDeliveryBranch.includes(cardStateId)) {
    text = 'cards.cardDetail.footer.inDeliveryBranch.text';
  } else if (CARD_STATES.TransferredToCourier.includes(cardStateId)) {
    text = 'cards.cardDetail.footer.transferredToCourier.text';
  }

  return text;
};

export { CARD_STATES, getCardStateText, isCardNew, isCardOnItsWay, isCardReady, isExpired, isInBeforeDueDayPeriod, isInBeforeExpirationPeriod };
