import { composeFragments } from 'core-ingredients';
import { fieldsFragment, fieldFragment } from 'core-recipes';
import { initialDatePickerLastMonth } from 'vtb-utils';

var currentOrdersFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'beneficiaryName',
  initialValue: ''
}), fieldFragment({
  name: 'dates',
  initialValue: initialDatePickerLastMonth
}), fieldFragment({
  name: 'minAmount',
  initialValue: ''
}), fieldFragment({
  name: 'maxAmount',
  initialValue: ''
}), fieldFragment({
  name: 'types',
  initialValue: ''
}), fieldFragment({
  name: 'currencies',
  initialValue: ''
}), fieldFragment({
  name: 'filterAccounts',
  initialValue: ''
}), fieldFragment({
  name: 'statuses',
  initialValue: ''
}), fieldFragment({
  name: 'fullSearch'
})));

export { currentOrdersFieldsFragment };
