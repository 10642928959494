import { composeFragments, validationRule, maxLength } from 'core-ingredients';
import { fieldsFragment, fieldFragment } from 'core-recipes';
import { basicCharsetWithGeorgianAndNoRussianChars } from '../../../../../utils/validation-patterns.js';
import { charset, requiredRule } from '../../../../../utils/validators.js';

var standingOrderFriendlyNameFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'friendlyName',
  rules: [validationRule(charset(/\S/)), validationRule(charset(basicCharsetWithGeorgianAndNoRussianChars)), validationRule(requiredRule), validationRule(maxLength(30))]
})));

export { standingOrderFriendlyNameFieldsFragment };
