import _defineProperty from '@babel/runtime/helpers/defineProperty';
import md5 from '../node_modules/crypto-js/md5.js';
import { includes, flatten } from 'ramda';
import { mapFullOptions } from '../collections.js';
import { BILL_TYPES } from '../constants/payments-constants.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var isProviderGlobal = function isProviderGlobal(provider) {
  return (provider && provider.isLocalGlobal) === 'GLOBAL';
};
var isMobileProvider = function isMobileProvider(provider) {
  if (!Array.isArray(provider === null || provider === void 0 ? void 0 : provider.billType) || provider.billType.length !== 1) {
    return false;
  } // provider has billType: array of types like MOBILE or UTILITY
  // if it has only MOBILE, its evaluated as mobile provider
  // if it has mobile and at least one other type too, its evaluated as non-mobile provider


  return includes(BILL_TYPES.MOBILE, provider.billType);
};
var isMobileTemplate = function isMobileTemplate(bpTemplate) {
  var _bpTemplate$templateT, _bpTemplate$templateT2, _bpTemplate$templateT3;

  return Boolean(bpTemplate === null || bpTemplate === void 0 ? void 0 : (_bpTemplate$templateT = bpTemplate.templateType) === null || _bpTemplate$templateT === void 0 ? void 0 : (_bpTemplate$templateT2 = _bpTemplate$templateT.billTypeKey) === null || _bpTemplate$templateT2 === void 0 ? void 0 : (_bpTemplate$templateT3 = _bpTemplate$templateT2.includes) === null || _bpTemplate$templateT3 === void 0 ? void 0 : _bpTemplate$templateT3.call(_bpTemplate$templateT2, BILL_TYPES.MOBILE));
};
var getBillTypeKey = function getBillTypeKey(service) {
  var _service$billType;

  return service === null || service === void 0 ? void 0 : (_service$billType = service.billType) === null || _service$billType === void 0 ? void 0 : _service$billType.billTypeKey;
};
var getServiceCode = function getServiceCode(service) {
  return service.serviceCode;
};
var getCategoryId = function getCategoryId(service) {
  var _service$category;

  return (_service$category = service.category) === null || _service$category === void 0 ? void 0 : _service$category.categoryId;
};
var addToCollection = function addToCollection(collection, service, valueCallback) {
  var providerId = service.provider.entityUniqueId;

  if (!collection[providerId]) {
    collection[providerId] = [];
  }

  var value = valueCallback(service);

  if (!includes(value, collection[providerId])) {
    collection[providerId].push(value);
  }
};

var constructBPProviderService = function constructBPProviderService(service, canOverride, hasPermisible, mainAmount, mainName, permissibleAmount) {
  return _objectSpread(_objectSpread({}, service), {}, {
    // TODO would be nice to rename this property in future sweet times of peace
    uuid: md5("".concat(service === null || service === void 0 ? void 0 : service.serviceCode).concat(mainAmount).concat(permissibleAmount).concat(JSON.stringify(mainName))).toString(),
    canOverride: canOverride,
    hasPermisible: hasPermisible,
    mainAmount: mainAmount,
    mainName: mainName,
    permissibleAmount: permissibleAmount
  });
};

var enrichDataWithService = function enrichDataWithService(data, debtAmount, canOverride) {
  var features = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  return function (serviceOriginal) {
    var service = _objectSpread(_objectSpread({}, serviceOriginal), {}, {
      features: features
    });

    if (Array.isArray(service.permissibleAmountList) && service.permissibleAmountList.length > 0) {
      service.permissibleAmountList.forEach(function (item) {
        var amount = item.amount || (debtAmount > 0 ? debtAmount : null);
        data.push(constructBPProviderService(service, canOverride, true, amount, item.name, item.amount));
      });
    } else {
      var amount = debtAmount > 0 ? debtAmount : service.minAmount;
      data.push(constructBPProviderService(service, true, false, amount, service.serviceNameLocalizations, // former service.serviceName
      null));
    }
  };
};
var getPermissibleAmounts = function getPermissibleAmounts() {
  var permissibleAmounts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var amounts = flatten(permissibleAmounts.map(function (pa) {
    return pa === null || pa === void 0 ? void 0 : pa.permissibleAmountList;
  }));
  return mapFullOptions(amounts, {
    labelKey: 'name'
  }).map(function (option) {
    var _option$value;

    return _objectSpread(_objectSpread({}, option), {}, {
      value: _objectSpread(_objectSpread({}, option === null || option === void 0 ? void 0 : option.value), {}, {
        permissibleAmount: option === null || option === void 0 ? void 0 : (_option$value = option.value) === null || _option$value === void 0 ? void 0 : _option$value.amount,
        hasPermisible: true
      })
    });
  });
};
var sanitizeServiceName = function sanitizeServiceName(option) {
  var opt = _objectSpread({}, option);

  var mainName = option.value.serviceNameLocalizations || option.value.serviceName;

  if (!opt.label) {
    opt.label = mainName;
    opt.value.mainName = mainName;
  }

  return opt;
};
var getBillPaymentTemplateIconUri = function getBillPaymentTemplateIconUri(service, provider) {
  var iconUri = '';

  if (service) {
    var _service$provider;

    iconUri = service.serviceIconUri !== 'PROVIDER' && service.serviceIconUri || (service === null || service === void 0 ? void 0 : (_service$provider = service.provider) === null || _service$provider === void 0 ? void 0 : _service$provider.providerIconUri);
  }

  if (provider) {
    iconUri = provider === null || provider === void 0 ? void 0 : provider.providerIconUri;
  }

  return iconUri;
};

export { addToCollection, enrichDataWithService, getBillPaymentTemplateIconUri, getBillTypeKey, getCategoryId, getPermissibleAmounts, getServiceCode, isMobileProvider, isMobileTemplate, isProviderGlobal, sanitizeServiceName };
