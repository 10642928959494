import { fieldsFragment, fieldFragment } from 'core-recipes';
import { autocorrectingValidationRule } from 'core-ingredients';
import { bpSearchAllowedChars } from '../../../utils/validation-patterns.js';
import { charset } from '../../../utils/validators.js';

var carsSearchFieldsFragment = fieldsFragment({})(fieldFragment({
  name: 'search',
  initialValue: '',
  rules: [autocorrectingValidationRule(charset(bpSearchAllowedChars))]
}));

export { carsSearchFieldsFragment };
