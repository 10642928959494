import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeLatest } from 'redux-saga/effects';
import { loadAccountNotificationsFlow } from './flows/account-notifications.flow.js';
import { loadCardNotificationsFlow } from './flows/card-notifications.flow.js';
import { loadLoanNotificationsFlow } from './flows/loan-notifications.flow.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var initialState = {
  accounts: 0,
  cards: 0,
  loans: 0,
  cardsObjects: []
};
var productNotificationsFragment = function productNotificationsFragment() {
  return {
    actions: {
      loadProductNotifications: function loadProductNotifications(realm) {
        return {
          realm: realm
        };
      },
      accountsLoaded: function accountsLoaded(value) {
        return {
          value: value
        };
      },
      cardsLoaded: function cardsLoaded(value) {
        return {
          value: value
        };
      },
      loansLoaded: function loansLoaded(value) {
        return {
          value: value
        };
      }
    },
    state: {
      accounts: {
        'actions.accountsLoaded': function actionsAccountsLoaded(_, _ref) {
          var value = _ref.value;
          return value;
        }
      },
      cards: {
        'actions.cardsLoaded': function actionsCardsLoaded(_, _ref2) {
          var value = _ref2.value;
          return Array.isArray(value) ? value.length : 0;
        }
      },
      cardsObjects: {
        'actions.cardsLoaded': function actionsCardsLoaded(_, _ref3) {
          var value = _ref3.value;
          return value;
        }
      },
      loans: {
        'actions.loansLoaded': function actionsLoansLoaded(_, _ref4) {
          var value = _ref4.value;
          return value;
        }
      }
    },
    selectors: {
      accounts: [['accounts']],
      cards: [['cards']],
      cardsObjects: [['cardsObjects']],
      loans: [['loans']],
      notifications: [function () {
        return [['accounts'], ['cards'], ['loans']];
      }, function (accounts, cards, loans) {
        return {
          accounts: accounts,
          cards: cards,
          loans: loans
        };
      }]
    },
    flows: {
      load: ['actions.loadProductNotifications', takeLatest, loadAccountNotificationsFlow],
      card: ['actions.loadProductNotifications', takeLatest, loadCardNotificationsFlow],
      loan: ['actions.loadProductNotifications', takeLatest, loadLoanNotificationsFlow]
    },
    initialState: _objectSpread({}, initialState)
  };
};

export { productNotificationsFragment };
