import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { wizardFragment } from '../../../generic/wizard.fragment.js';
import { TRANSFERS_STEPS } from '../../constants.js';
import { checkRightsFragment } from '../../../permissions/index.js';
import { withVTBCertificationFragment } from '../../common/withVTBCertificationFragment.js';
import { decidePaymentWithoutBalanceCheckFragment } from '../../common/decidePaymentWithoutBalanceCheckFragment.js';
import { paymentWithGetDebtIsSuccessfulFragment } from '../../common/paymentWithGetDebtIsSuccessfulFragment.js';
import { basketFragment } from './basket.fragment.js';
import { basketFieldsFragment } from './basket.fields.js';
import { BILL_PAYMENT } from '../../../permissions/rights.js';

var basketLogic = logic2(composeFragments(pathFragment('transfers', 'basket'), wizardFragment(TRANSFERS_STEPS), decidePaymentWithoutBalanceCheckFragment, basketFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, withVTBCertificationFragment, paymentWithGetDebtIsSuccessfulFragment, basketFragment, checkRightsFragment({
  rights: BILL_PAYMENT()
})));
var billPaymentBasketReducer = basketLogic.reducer;
var billPaymentBasketSaga = sagaifyLogic2(basketLogic);

export { basketLogic, billPaymentBasketReducer, billPaymentBasketSaga };
