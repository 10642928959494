import { logic2, composeFragments, pathFragment, state, stateFlag, sagaifyLogic2 } from 'core-ingredients';
import { catalogFragment, fetchFragment } from 'core-recipes';
import { loadingFragment } from '../../loading.fragment.js';
import { RATE_TABLE_CBR } from '../../../app/constants.js';
import { pendingTransactionsTotalsFragment } from '../../pending-transactions-totals.fragment.js';
import { accountsListFragment } from './accounts-list.fragment.js';
import { exportFragment } from '../../../generic/export/export.fragment.js';
import { exportRequisitesConfigFactory } from '../../../generic/export/export-config-factories.js';

var accountListLogic = logic2(composeFragments(pendingTransactionsTotalsFragment, pathFragment('accounts', 'list'), loadingFragment(), accountsListFragment(), catalogFragment({
  name: 'currencies',
  querySchema: {
    Currencies: ['flag', 'currencyID'],
    limit: 1000
  }
}), state(['allLoadedAccounts', 'storeAllLoadedAccounts'], function (_, _ref) {
  var accounts = _ref.accounts;
  return accounts;
}, []), state(['groupsWithCards', 'setGroupsWithCards'], function (_, _ref2) {
  var groupsWithCards = _ref2.groupsWithCards;
  return groupsWithCards;
}, []), stateFlag(['accountsLoading'], ['loadAccounts'], ['calculateTotals', 'loadError'], false), stateFlag(['accountsGroupsLoading'], ['loadAccounts'], ['groupsLoaded', 'loadError'], false), stateFlag(['accountsBasicsLoading'], ['loadAccounts'], ['loaded', 'loadError'], false), stateFlag(['accountsLoaded'], ['loaded'], ['loadError'], false), stateFlag(['accountsLoadFailed'], ['loadError'], ['loaded'], false), exportFragment({
  configFactory: exportRequisitesConfigFactory
}), fetchFragment({
  valueKey: 'exchangeRates',
  valueQuery: {
    exchangeRates: []
  },
  beforeQueryHandler: function beforeQueryHandler() {
    return {
      url: "xr/v1/rateTable/".concat(RATE_TABLE_CBR, "/latest")
    };
  },
  supplier: 'ibs'
})));
var accountListReducer = accountListLogic.reducer;
var accountListSaga = sagaifyLogic2(accountListLogic);

export { accountListLogic, accountListReducer, accountListSaga };
