import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { userHasNoAccountsSelector } from '../common/selectors.js';
import { transferHomepageFragment } from './transfer-homepage.fragment.js';
import { unsignedPaymentsCountFragment } from './unsigned-payments-count.fragment.js';

var transferHomepageLogic = logic2(composeFragments(pathFragment('transfers', 'home'), transferHomepageFragment, unsignedPaymentsCountFragment, userHasNoAccountsSelector));
var transferHomepageReducer = transferHomepageLogic.reducer;
var transferHomepageSaga = sagaifyLogic2(transferHomepageLogic);

export { transferHomepageLogic, transferHomepageReducer, transferHomepageSaga };
