import { format } from 'date-fns';
import { Logger } from 'vtb-utils';

var mapInstruction = function mapInstruction(values) {
  var debitAccount = values.debitAccount;
  return {
    accountNumber: debitAccount.id // '12925324' TODO: only for debug purpouses, remove

  };
};
var mapTreasuryTransferInstructionItem = function mapTreasuryTransferInstructionItem(values) {
  var payerName = values.payerName,
      payerTaxId = values.payerTaxId,
      debitAccount = values.debitAccount,
      treasuryCode = values.treasuryCode,
      amount = values.amount,
      purpose = values.purpose,
      customerId = values.customerId,
      channelId = values.channelId;
  Logger.info("TT instruction mapping - payerName: ".concat(payerName, ", customerId: ").concat(customerId));
  var payLoad = {
    customerId: customerId,
    debitAccountId: debitAccount.id,
    payerName: payerName,
    payerTaxId: payerTaxId,
    treasuryCode: treasuryCode,
    ccy: debitAccount.currency,
    amount: amount,
    date: format(new Date(), 'YYYY-MM-DD'),
    purpose: purpose,
    transactionReference: '-',
    // on trunas request
    channelId: channelId,
    isFastPayment: false // sourceTemplate: null,

  };
  return payLoad;
};

export { mapInstruction, mapTreasuryTransferInstructionItem };
