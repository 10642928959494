import { composeFragments, validationRule, minLength, maxLength } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withResetForm, withFieldsSelectors } from 'core-recipes';
import { requiredRule, charset } from '../../utils/validators.js';

var createRoleFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'roleName',
  rules: [validationRule(requiredRule), validationRule(charset(/^[a-zA-Z0-9 \-_=,]*$/, 'um.roleCreate.validationHint')), validationRule(minLength(3)), validationRule(maxLength(30))]
})), withResetForm({}), withFieldsSelectors);

export { createRoleFieldsFragment };
