import { takeLatest } from 'redux-saga/effects';
import { composeFragments, pathFragment, action, flow, resolveAction, stateFlag, state, resolveLogic } from 'core-ingredients';
import { createSigningRuleFlow } from './signing-rules.step-finish.flow.js';

var signingRulesStepFinishLogicSchema = composeFragments(pathFragment('signingRules', 'stepFinish'), action('createSigningRule'), flow('createSigningRuleFlow', [resolveAction('createSigningRule'), takeLatest, createSigningRuleFlow]), stateFlag('isError', ['error'], ['loaded', 'reset', 'initializeForm'], false), stateFlag('isProcessing', ['createSigningRule'], ['error', 'setCertificationState'], false), action('setCertificationState', function (certificationState) {
  return {
    certificationState: certificationState
  };
}), state(['certificationState', 'setCertificationState'], function (_, _ref) {
  var certificationState = _ref.certificationState;
  return certificationState;
}), action('updateCertificationStatus'));
var signingRulesStepFinishLogicAPI = resolveLogic(signingRulesStepFinishLogicSchema);
var signingRulesStepFinishLogic = signingRulesStepFinishLogicAPI.logic;
var signingRulesStepFinishReducer = signingRulesStepFinishLogicAPI.reducer;
var signingRulesStepFinishSaga = signingRulesStepFinishLogicAPI.saga;

export { signingRulesStepFinishLogic, signingRulesStepFinishLogicAPI, signingRulesStepFinishReducer, signingRulesStepFinishSaga };
