import { composeFragments, pathFragment, resolveLogic } from 'core-ingredients';
import { addDigiPassFieldsFragment } from './add-digipass.fields.js';

var addDigiPassFormLogicSchema = composeFragments(pathFragment('corporateUsers', 'addDigiPassForm'), addDigiPassFieldsFragment);
var addDigiPassFormLogicAPI = resolveLogic(addDigiPassFormLogicSchema);
var addDigiPassFormLogic = addDigiPassFormLogicAPI.logic;
var addDigiPassFormReducer = addDigiPassFormLogicAPI.reducer;
var addDigiPassFormSaga = addDigiPassFormLogicAPI.saga;

export { addDigiPassFormLogic, addDigiPassFormReducer, addDigiPassFormSaga };
