import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { templateIconFragment } from '../../../templates/templates-icon-fragment.js';
import { transferBillPaymentTemplateFieldsFragment } from './bill-payment.fields.js';
import { bpSaveAsTemplateFragment } from './bill-payment-save-as-template.fragment.js';
import { bpTemplatesFragment } from '../../../common/bp-templates/bp-templates.fragment.js';

var billPaymentSaveAsTemplateLogic = logic2(composeFragments(pathFragment('transfers', 'billTemplateSmallForm'), bpTemplatesFragment, transferBillPaymentTemplateFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, bpSaveAsTemplateFragment, // edit provider/template icon
templateIconFragment));
var billPaymentTemplateFormReducer = billPaymentSaveAsTemplateLogic.reducer;
var billPaymentTemplateFormSaga = sagaifyLogic2(billPaymentSaveAsTemplateLogic);

export { billPaymentSaveAsTemplateLogic, billPaymentTemplateFormReducer, billPaymentTemplateFormSaga };
