import { russianCharset, georgianCharset, georgianUnicodeCharset } from 'vtb-utils';
import { getIsValidRegExpFromCharset } from './validators.js';

/* eslint-disable max-len */
var groupNameOnlyAllowedCharset = " a-zA-Z0-9\u10D0\u10D1\u10D2\u10D3\u10D4\u10D5\u10D6\u10D7\u10D8\u10D9\u10DA\u10DB\u10DC\u10DD\u10DE\u10DF\u10E0\u10E1\u10E2\u10E3\u10E4\u10E5\u10E6\u10E7\u10E8\u10E9\u10EA\u10EB\u10EC\u10ED\u10EE\u10EF\u10F0\u0410\u0430\u0411\u0431\u0412\u0432\u0413\u0433\u0414\u0434\u0415\u0435\u0401\u0451\u0416\u0436\u0417\u0437\u0418\u0438\u0419\u0439\u041A\u043A\u041B\u043B\u041C\u043C\u041D\u043D\u041E\u043E\u041F\u043F\u0420\u0440\u0421\u0441\u0422\u0442\u0423\u0443\u0424\u0444\u0425\u0445\u0426\u0446\u0427\u0447\u0428\u0448\u0429\u0449\u042A\u044A\u042B\u044B\u042C\u044C\u042D\u044D\u042E\u044E\u042F\u044F";
var groupNameOnlyAllowedChars = getIsValidRegExpFromCharset(groupNameOnlyAllowedCharset);
var carNameOnlyAllowedChars = /^[ a-zA-Z0-9\u10d0\u10d1\u10d2\u10d3\u10d4\u10d5\u10d6\u10d7\u10d8\u10d9\u10da\u10db\u10dc\u10dd\u10de\u10df\u10e0\u10e1\u10e2\u10e3\u10e4\u10e5\u10e6\u10e7\u10e8\u10e9\u10ea\u10eb\u10ec\u10ed\u10ee\u10ef\u10f0АаБбВвГгДдЕеЁёЖжЗзИиЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЪъЫыЬьЭэЮюЯя]+$/;
var allowedLatinNumbersAndChars = /^[a-zA-Z0-9]+$/;
var allowedNumbersChars = /^[0-9]+$/;
var templateNameChars = /^[ a-zA-Z0-9'+\u10d0\u10d1\u10d2\u10d3\u10d4\u10d5\u10d6\u10d7\u10d8\u10d9\u10da\u10db\u10dc\u10dd\u10de\u10df\u10e0\u10e1\u10e2\u10e3\u10e4\u10e5\u10e6\u10e7\u10e8\u10e9\u10ea\u10eb\u10ec\u10ed\u10ee\u10ef\u10f0АаБбВвГгДдЕеЁёЖжЗзИиЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЪъЫыЬьЭэЮюЯя]+$/;
var emailAutoCorrectCharset = '-a-zA-Z0-9@_&\'"*+./=?^{}~;';
var emailNoAutoCorrectCharset = /^[-a-zA-Z0-9@_&'"*+./=?^{}~;]+$/;
var emailStructure = /^[^.@][^@]{0,63}@[^@]+\.[^.@]+$/;
var personalEmailStructure = /^[^@]+@[^@]+\.[^.@]{2,}$/;
var basicCharset = " a-zA-Z0-9/\\-?:().,'+".concat(georgianUnicodeCharset);
var bpSearchAllowedChars = new RegExp("^[a-zA-Z0-9".concat(georgianUnicodeCharset, "]+$"));
var smartSearchAllowedCharset = "".concat(basicCharset).concat(georgianCharset).concat(russianCharset);
var basicCharsetWithGeorgianAndRussianChars = getIsValidRegExpFromCharset("".concat(basicCharset).concat(russianCharset));
var basicCharsetWithGeorgianAndNoRussianChars = getIsValidRegExpFromCharset("".concat(basicCharset).concat(georgianCharset));
var basicCharsetChars = getIsValidRegExpFromCharset(basicCharset);
var addressChars = basicCharsetChars;
var usernameAllowedChars = /^[a-zA-Z0-9@\u002D_.]+$/;

export { addressChars, allowedLatinNumbersAndChars, allowedNumbersChars, basicCharsetChars, basicCharsetWithGeorgianAndNoRussianChars, basicCharsetWithGeorgianAndRussianChars, bpSearchAllowedChars, carNameOnlyAllowedChars, emailAutoCorrectCharset, emailNoAutoCorrectCharset, emailStructure, groupNameOnlyAllowedChars, groupNameOnlyAllowedCharset, personalEmailStructure, smartSearchAllowedCharset, templateNameChars, usernameAllowedChars };
