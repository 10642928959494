// import appStore from '../../assets/images/app-store.png'
// import googlePlay from '../../assets/images/google-play.png'
// import loginBg from '../../assets/images/login-bg.jpg'
// TODO: FIX add real assets
var image = {
  appStore: '',
  googlePlay: '',
  loginBg: ''
};

export default image;
