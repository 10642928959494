import { takeLatest } from 'redux-saga/effects';
import { loadDataForFilterFlow } from '../../statements/flows/statements-filter.flows.js';

var loadAccountsFragment = function loadAccountsFragment() {
  return {
    actions: {
      loadAccounts: function loadAccounts() {
        return {};
      },
      loadedDataForFilter: function loadedDataForFilter(accounts) {
        return {
          accounts: accounts
        };
      },
      accountsLoadingDone: function accountsLoadingDone() {
        return {};
      }
    },
    state: {
      allAccounts: {
        'actions.loadedDataForFilter': function actionsLoadedDataForFilter(_, _ref) {
          var accounts = _ref.accounts;
          return accounts;
        }
      },
      accountsLoaded: {
        'actions.accountsLoadingDone': function actionsAccountsLoadingDone() {
          return true;
        },
        'actions.loadAccounts': function actionsLoadAccounts() {
          return false;
        }
      }
    },
    selectors: {
      allAccounts: [['allAccounts']],
      accountsLoaded: [['accountsLoaded']]
    },
    flows: {
      loadAccounts: ['actions.loadAccounts', takeLatest, function (props) {
        return loadDataForFilterFlow(props, true, true);
      }]
    },
    initialState: {
      accountsLoaded: false,
      allAccounts: []
    }
  };
};

export { loadAccountsFragment };
