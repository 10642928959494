import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { composeFragments, action, state, stateFlag, selector, validationRule, required, validation } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withDataFieldValidation, withFieldsSelectors } from 'core-recipes';
import { systemParamSelectorFactory } from '../../app/systemParamSelectorFactory.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var otpCodeValidation = validation('otpCode', function (value) {
  return !value || /\d+/g.test(value);
});

var otpCodeLengthValidation = function otpCodeLengthValidation(length) {
  return validation('otpCodeLength', function (value) {
    return !value || value.length === length;
  });
};

var tokenType = function tokenType(token) {
  return token && token.otpDeviceTypeId === '4' ? 'SW' : 'HW';
};
var certificationOtpFragment = function certificationOtpFragment() {
  return composeFragments(action('start', function (params) {
    return _objectSpread({}, params);
  }), action('tokenLoaded', function (token) {
    return {
      token: token
    };
  }), action('submit', function (otpCode) {
    return {
      otpCode: otpCode
    };
  }), action('finalized', function () {
    return {};
  }), action('failed', function (error) {
    return {
      error: error
    };
  }), action('reset', function () {
    return {};
  }), action('unlockToken'), action('cancelUnlock'), state(['token', 'tokenLoaded'], function (_, _ref) {
    var token = _ref.token;
    return token;
  }), state(['error', 'start'], function () {
    return null;
  }), state(['error', 'restart'], function () {
    return null;
  }), state(['error', 'failed'], function (_, _ref2) {
    var error = _ref2.error;
    return error;
  }), stateFlag('loaded', ['tokenLoaded'], ['initialize'], false), stateFlag('loading', ['initialize'], ['tokenLoaded', 'failed'], false), stateFlag('submitting', ['submit'], ['finalized', 'failed'], false), stateFlag('unlocking', ['unlockToken'], ['start', 'cancelUnlock'], false), selector('needsActivation', ['token', 'loaded'], function (token, loaded) {
    return !token && loaded;
  }), selector('tokenMode', ['token'], function (token) {
    return tokenType(token);
  }), selector('otpCodeLength', [systemParamSelectorFactory('OTP_METHOD_CODE_LENGTH'), 'tokenMode'], // OTP code length should be 8, but testing HW tokens have different settings which should read from system param.
  function (codeLength, tokenMode) {
    return tokenMode === 'HW' && codeLength ? parseInt(codeLength) : 8;
  }), fieldsFragment({})(fieldFragment({
    name: 'otpCode',
    rules: [validationRule(required), validationRule(otpCodeValidation)]
  }, withDataFieldValidation({
    parameters: function parameters(selectors) {
      return {
        otpCodeLength: selectors.otpCodeLength
      };
    },
    validation: function validation(_ref3) {
      var otpCodeLength = _ref3.otpCodeLength;
      return otpCodeLengthValidation(otpCodeLength);
    }
  }))), withFieldsSelectors);
};

export { certificationOtpFragment };
