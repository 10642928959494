import { MenuWrapper, Flex, ActiveComponent } from 'vtb-ui'
import * as React from 'react'

const styles = {
  wrapper: { position: 'relative' },
  hamburger: {
    px: [20, 10],
    flexDirection: ['column', 'column', 'row'],
  },
}

const getActivePosition = (location: string): number | null => {
  if (location === '') {
    return 1
  } else if (location === 'my-money') {
    return 2
  } else if (location === 'payments') {
    return 3
  } else if (location === 'transactions') {
    return 4
  }
  return null
}

export const MenuView = ({ items, ...props }) => {
  const url = props.location.pathname.split('/')
  const location = url[1]
  const activeItemPosition = getActivePosition(location)
  return (
    <Flex style={styles.wrapper}>
      <MenuWrapper {...props}>
        {activeItemPosition && <ActiveComponent active={activeItemPosition} />}
        <div id='main-menu-items' data-cy='mainMenuItems'>
          {items}
        </div>
        <Flex
          mt={50}
          px={styles.hamburger.px}
          flexDirection={styles.hamburger.flexDirection}
          justifyContent='space-between'
          id='main-menu-actions'
          data-cy='mainMenuActions'
        >
          <props.RenderActionsComponent onHamburgerClick={props.onHamburgerClick} />
        </Flex>
      </MenuWrapper>
    </Flex>
  )
}
