var billPaymentGroupsQuery = {
  BPTemplateGroups: ['groupName', 'groupId', 'sortOrder', {
    groupCategory: ['isActiveGemini', 'name', 'groupCategoryId', 'groupCategoryIconUri', 'sortOrder']
  }],
  where: {},
  order: {
    sortOrder: 'asc'
  },
  limit: 1000
};

export { billPaymentGroupsQuery };
