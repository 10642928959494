import { filter } from 'ramda';
import { sortCards } from 'vtb-utils';

var processAccountCards = function processAccountCards(account, accountCards) {
  var cards = filter(function (card) {
    return card.ibanAccount.iban === account.ibanAccount;
  }, accountCards);
  return {
    cards: sortCards(cards),
    numOfCards: cards.length
  };
};

export { processAccountCards };
