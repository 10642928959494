import { logic2, composeFragments, pathFragment, action, stateFlag, state, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { takeLatest } from 'redux-saga/effects';
import { changeUsernameFieldsFragment } from './change-username-fields-fragment.js';
import { processUsernameFlow, initiateChangeUsername } from './change-username-flow.js';

var changeUsernameLogic = logic2(composeFragments(pathFragment('security', 'changeUsername'), changeUsernameFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, action('submit'), action('calling'), action('initializeForm'), action('setLoginName', function (loginName) {
  return {
    loginName: loginName
  };
}), action('closeModal'), action('notAvailableName'), action('notCorrectPassword'), stateFlag('isSubmited', ['submit'], ['failed', 'resetForm'], false), stateFlag('submitting', ['calling'], ['failed', 'notCorrectPassword', 'notAvailableName', 'resetForm']), stateFlag('modalIsOpen', ['success'], ['closeModal']), stateFlag('notCorrectPasswordState', ['notCorrectPassword'], ['updatePassword', 'resetForm'], false), stateFlag('notAvailibleNameState', ['notAvailableName'], ['updateUsername', 'resetForm'], false), stateFlag('changeUsernameFailed', ['failed'], ['closeModal']), stateFlag('isModalGMB', ['openModalGMB'], ['closeModalGMB']), state(['loginName', 'setLoginName'], function (_, _ref) {
  var loginName = _ref.loginName;
  return loginName;
}, null), state(['passwordTypeInput', 'passwordTypeToggle'], function (state) {
  return state === 'password' ? 'text' : 'password';
}, 'password'), state(['passwordTypeInput', 'hidePassword'], function () {
  return 'password';
}, 'password'), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, processUsernameFlow]), flow('initiateChangeUsername', [resolveAction('initializeForm'), takeLatest, initiateChangeUsername])));
var changeUsernameReducer = changeUsernameLogic.reducer;
var changeUsernameSaga = sagaifyLogic2(changeUsernameLogic);

export { changeUsernameLogic, changeUsernameReducer, changeUsernameSaga };
