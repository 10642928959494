import { withDefaultProps, TableBody, TableHead, TableRow, TableCell, TableHeadCell, Table as Table$1 } from 'core-ui';
import TableView from './views/TableView.js';
import TableBodyView from './views/TableBodyView.js';
import TableHeadView from './views/TableHeadView.js';
import TableCellView from './views/TableCellView.js';
import TableCellInnerView from './views/TableCellInnerView.js';
import TableHeadCellView from './views/TableHeadCellView.js';
import TableHeadCellInnerView from './views/TableHeadCellInnerView.js';
import TableBodyEmptyView from './views/TableBodyEmptyView.js';
import TableRowView from './views/TableRowView.js';
import TableBodyEmpty from './views/TableBodyEmpty.js';
import { TableSelectCell, TableSelectHeaderCell } from './views/TableSelectView.js';

var Table = withDefaultProps({
  RenderComponent: TableView,
  RenderSelectCellComponent: TableSelectCell,
  RenderSelectHeaderCellComponent: TableSelectHeaderCell,
  components: {
    TableBodyComponent: TableBody,
    TableHeadComponent: TableHead,
    TableRowComponent: TableRow,
    TableCellComponent: TableCell,
    TableHeadCellComponent: TableHeadCell,
    TableBodyEmptyComponent: TableBodyEmpty,
    tableBody: {
      RenderComponent: TableBodyView
    },
    tableHead: {
      RenderComponent: TableHeadView
    },
    tableCell: {
      RenderComponent: TableCellView,
      RenderInnerComponent: TableCellInnerView
    },
    tableHeadCell: {
      RenderComponent: TableHeadCellView,
      RenderInnerComponent: TableHeadCellInnerView
    },
    tableRow: {
      RenderComponent: TableRowView
    },
    tableEmpty: {
      RenderComponent: TableBodyEmptyView
    }
  }
}, Table$1);

export default Table;
