import { composeFragments, pathFragment, resolveLogic } from 'core-ingredients';
import { unlockProcessingFragment } from './unlock.fragment.js';

var digipassUnlockSchema = composeFragments(pathFragment('digipass', 'unlock'), unlockProcessingFragment());

var _resolveLogic = resolveLogic(digipassUnlockSchema),
    logic = _resolveLogic.logic,
    reducer = _resolveLogic.reducer,
    saga = _resolveLogic.saga;

export { logic as digipassUnlockLogic, reducer as digipassUnlockReducer, saga as digipassUnlockSaga, digipassUnlockSchema };
