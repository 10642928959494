import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { DEBT_ERRORS } from './debts.js';
import { BILL_TYPES } from '../constants/payments-constants.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var isFineService = function isFineService(service) {
  return service && service.billType && [BILL_TYPES.PARKINGFINES, BILL_TYPES.POLICEFINES].includes(service.billType.billTypeKey) || false;
};
var isParkingService = function isParkingService(service) {
  return service && service.billType && service.billType.billTypeKey === BILL_TYPES.PARKINGPASS || false;
};
var isCarService = function isCarService(service) {
  return isParkingService(service) || isFineService(service);
};
var getCarFineTitles = function getCarFineTitles(titles, fine) {
  var titleKey = "".concat(fine.template.carNumber, ":").concat(fine.template.carTemplateId);

  if (!titles[titleKey]) {
    titles[titleKey] = {
      text: "".concat(fine.template.carNumber, " - ").concat(fine.template.carTemplateName),
      displayed: false
    };
  } else {
    titles[titleKey].displayed = true;
  }

  return _objectSpread({}, titles[titleKey]);
};
var hasPaymentAlreadyExistsError = function hasPaymentAlreadyExistsError(errors, serviceCode, customerData) {
  if (!errors || !Array.isArray(errors) || errors.length === 0) {
    return false;
  }

  return errors.some(function (error) {
    return error.errorCode === DEBT_ERRORS.PAYMENT_ALREADY_EXISTS && error.customerData === customerData && error.serviceCode === serviceCode;
  });
};
var CUSTOMER_CODE_SEPARATOR = '/';
/**
 * Method composes car identifier for ALTA using Service params or only carNumber/ownerIdNumber if car is present
 * If there is no possibility to compose result, then returns null and them getDebt request should not be called
 * @param selectedCarId It's carId, non empty string when user comes from "Add parkingPass" button on cars dashboard
 * @param values Form values
 * @param carNumber Param that every car has
 * @param serviceCode Id of service selected in payment form of wizard
 * @param servicePackages Service params
 */

var composeCustomerDataForGetDebt = function composeCustomerDataForGetDebt(selectedCarId, values, carNumber, serviceCode, servicePackages) {
  if (selectedCarId) {
    if (serviceCode && carNumber && carNumber.errors.length === 0) {
      return "".concat(values.carNumber).concat(CUSTOMER_CODE_SEPARATOR).concat(values.ownerIdNumber);
    }

    return null;
  }

  if (!values['possibleValue0']) {
    return null;
  }

  var secondPartOfCustomerData;
  var customerData;

  if (servicePackages.length > 1) {
    secondPartOfCustomerData = values['possibleValue1'] || values['possibleValue2'] || values['possibleValue3'] || values['possibleValue4']; // if there are more than one fields then at least one of them is mandatory

    if (!secondPartOfCustomerData) {
      return null;
    }

    customerData = "".concat(values['possibleValue0']).concat(CUSTOMER_CODE_SEPARATOR).concat(secondPartOfCustomerData);
  } else {
    customerData = "".concat(values['possibleValue0']);
  }

  if (!customerData) {
    return null;
  }

  return customerData;
};

export { CUSTOMER_CODE_SEPARATOR, composeCustomerDataForGetDebt, getCarFineTitles, hasPaymentAlreadyExistsError, isCarService, isFineService, isParkingService };
