import { logic2, composeFragments, pathFragment } from 'core-ingredients';
import { checkRightsFragment } from '../../../permissions/index.js';
import { loadingFragment } from '../../loading.fragment.js';
import { loansDetailFragment } from './loans-detail.fragment.js';
import { exportFragment } from '../../../generic/export/export.fragment.js';
import { VIEW_LOAN } from '../../../permissions/rights.js';
import { exportLoanScheduleConfigFactory } from '../../../generic/export/export-config-factories.js';

var loansDetailLogic = logic2(composeFragments(pathFragment('loans', 'detail'), loadingFragment(), loansDetailFragment(), checkRightsFragment({
  rights: function rights(_ref) {
    var id = _ref.id;
    return VIEW_LOAN(id);
  }
}), exportFragment({
  configFactory: exportLoanScheduleConfigFactory
})));

export { loansDetailLogic };
