import { takeLatest } from 'redux-saga/effects';
import { logic2, composeFragments, pathFragment, action, state, stateFlag, selector, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { INSTRUCTION_TYPES, isCardOrCurrentGELAccount, ACCOUNT_VISIBILITY } from 'vtb-utils';
import { wizardFragment } from '../../generic/wizard.fragment.js';
import { useTemplateFragment } from '../../generic/statistics/use-template.fragment.js';
import { transferFragment } from '../common/transfer/transfer.fragment.js';
import { TRANSFERS_STEPS } from '../constants.js';
import { instructionIdsFragment } from '../common/instruction-logic.js';
import { withVTBCertificationFragment } from '../common/withVTBCertificationFragment.js';
import { verifyAvailableBalanceFragment } from '../common/helpers.js';
import { moveInstructionToEditFragment } from '../../tpm/moveInstructionToEditFragment.js';
import { userHasNoAccountsSelector } from '../common/selectors.js';
import { sharedAccountsFragment } from '../common/shared-accounts/shared-accounts.fragment.js';
import { mapSharedAccountsFlow } from '../common/shared-accounts/map-shared-accounts.js';
import { treasuryTransferFieldsFragment } from './treasury-transfer.fields.js';
import { processTreasuryTransferFlow, recalculateFee } from './treasury-transfer.flows.js';

var treasuryTransferLogic = logic2(composeFragments(pathFragment('transfers', 'treasury'), treasuryTransferFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, useTemplateFragment, sharedAccountsFragment({
  listName: 'accounts',
  afterQueryHandler: mapSharedAccountsFlow,
  filterCondition: isCardOrCurrentGELAccount,
  visibilityKey: ACCOUNT_VISIBILITY.TREASURY_INDEBIT,
  instructionTypes: ['T:paymentTreasury']
}), userHasNoAccountsSelector, wizardFragment(TRANSFERS_STEPS), withVTBCertificationFragment, verifyAvailableBalanceFragment, instructionIdsFragment, moveInstructionToEditFragment, transferFragment({}), action('submit'), action('initializeForm'), action('recalculateFee'), action('calling'), state(['instructionType', 'setInstructionType'], function (_, _ref) {
  var value = _ref.value;
  return value;
}, INSTRUCTION_TYPES.treasuryTransfer), stateFlag('isSubmited', ['submit'], ['failed', 'resetForm'], false), stateFlag('submitting', ['calling'], ['tmpSuccess', 'tmpFailed', 'reset', 'failed', 'certificationSubmited', 'stopCalling']), state(['paymentsFee', 'feeLoaded'], function (_, _ref2) {
  var value = _ref2.value;
  return value;
}), state(['paymentsFee', 'resetForm'], function () {
  return null;
}), stateFlag('paymentsFeeFailed', ['feeLoadFailed'], ['feeLoaded', 'initializeForm', 'resetForm']), state(['certifiable', 'certificationSubmited'], function (_, _ref3) {
  var certifiable = _ref3.certifiable;
  return certifiable;
}, false), state(['isTemplatesModalOpen', 'toggleTemplateModal'], function (_, _ref4) {
  var isOpen = _ref4.isOpen;
  return isOpen;
}, false), stateFlag('isTemplatesModalOpen', [], ['initializeForm', 'reset', 'resetForm']), stateFlag('isTransferHeaderOpen', ['openTransferHeader'], ['closeTransferHeader', 'reset', 'resetForm']), stateFlag('isFromDashboard', ['setFromDashboard'], ['reset', 'resetForm'], false), stateFlag('accountsMapping', ['initializeForm'], ['accountsMapped'], false), stateFlag('isTemplateInvalid', ['setTemplateInvalid'], ['toggleTemplateModal', 'reset', 'resetForm'], false), selector('loading', ['accountsLoading'], function (accountsLoading) {
  return accountsLoading;
}), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, processTreasuryTransferFlow]), flow('recalculateFee', [resolveAction('recalculateFee'), takeLatest, recalculateFee])));
var treasuryTransferReducer = treasuryTransferLogic.reducer;
var treasuryTransferSaga = sagaifyLogic2(treasuryTransferLogic);

export { treasuryTransferLogic, treasuryTransferReducer, treasuryTransferSaga };
