import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import { compose, pipe, assoc } from 'ramda';
import { mapProps } from 'recompose';
import styled from 'styled-components';
import { withTouch } from '../decorators/withTouch.js';
import { Text } from './text.js';

var _templateObject;
var StyledLink = styled(Text).attrs(function (_ref) {
  var disabled = _ref.disabled,
      theme = _ref.theme,
      color = _ref.color,
      fontSize = _ref.fontSize;
  return {
    fontSize: fontSize || 'inherit',
    color: disabled ? theme.colors.disabled : color || theme.colors.linkPrimary
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
var Link = compose(withTouch, mapProps(function (props) {
  var disabled = props.disabled,
      onClick = props.onClick;
  return pipe(assoc('onClick', disabled ? undefined : onClick))(props);
}))(StyledLink);

export { Link };
