import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeLatest } from 'redux-saga/effects';
import { logic2, composeFragments, pathFragment, action, stateFlag, state, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { loadMessagesFlow, selectAllFlow, selectFlow, removeFlow } from './sent-messages.flow.js';
import { sentMesssageFieldFragment } from './fields.fragment.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var sentMessagesLogic = logic2(composeFragments(pathFragment('messageCenter', 'sent'), sentMesssageFieldFragment(), withResetForm({}), withInitializeForm({}), withFieldsSelectors, action('select', function (id) {
  return {
    id: id
  };
}), action('changeLocalState', function (id) {
  return {
    id: id
  };
}), action('selectResolve', function (data) {
  return {
    data: data
  };
}), action('remove', function (data) {
  return {
    data: data
  };
}), action('submitRemove', function () {
  return {};
}), action('loadMessages', function (withSearch) {
  return {
    withSearch: withSearch
  };
}), stateFlag('loading', ['loadMessages', 'startLoading', 'showDeleteModal'], ['loaded'], true), stateFlag('messagesLoading', ['loadMessages'], ['messagesLoaded', 'failed'], true), stateFlag('editMode', ['editModeOn'], ['editModeOff', 'loadMessages'], false), stateFlag('selectedAll', ['selectAll'], ['deselectAll', 'deselect', 'editModeOff'], false), stateFlag('deleteModal', ['showDeleteModal'], ['hideDeleteModal']), stateFlag('searchSelected', ['openSearch'], ['closeSearch']), state(['messages', 'messagesLoaded'], function (_, _ref) {
  var messages = _ref.messages;
  return messages;
}, []), state(['messages', 'changeLocalState'], function (state, _ref2) {
  var id = _ref2.id;
  var messages = state.map(function (item) {
    if (item.id === id) {
      item = _objectSpread(_objectSpread({}, item), {}, {
        receiveStatus: 'READ'
      });
    }

    return item;
  });
  return _toConsumableArray(messages);
}), state(['selected', 'selectResolve'], function (_, _ref3) {
  var data = _ref3.data;
  return data;
}, []), state(['selected', 'editModeOff'], function () {
  return [];
}), state(['selected', 'deselectAll'], function () {
  return [];
}), flow('loadMessagesFlow', [resolveAction('loadMessages'), takeLatest, loadMessagesFlow]), flow('selectAllFlow', [resolveAction('selectAll'), takeLatest, selectAllFlow]), flow('selectFlow', [resolveAction('select'), takeLatest, selectFlow]), flow('removeFlow', [resolveAction('remove'), takeLatest, removeFlow])));
var sentMessagesReducer = sentMessagesLogic.reducer;
var sentMessagesSaga = sagaifyLogic2(sentMessagesLogic);

export { sentMessagesLogic, sentMessagesReducer, sentMessagesSaga };
