import _slicedToArray from '@babel/runtime/helpers/slicedToArray';

var backgroundLinearGradient = function backgroundLinearGradient(_ref) {
  var theme = _ref.theme,
      linearGradient = _ref.linearGradient;

  if (!linearGradient) {
    return;
  }

  var _linearGradient = _slicedToArray(linearGradient, 3),
      a = _linearGradient[0],
      b = _linearGradient[1],
      angle = _linearGradient[2];

  return "background: linear-gradient(".concat(angle || 220, "deg, ").concat(theme.colors[a] || a, ", ").concat(theme.colors[b] || b, " 80%);");
};

export { backgroundLinearGradient };
