
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'
import { contextSelector } from 'vtb-logic'

const isAuthenticatedSelector = createSelector([contextSelector], (context) => !!context)

const renderRoute = (isAuthenticated, Component) => (props) =>
  (isAuthenticated ? <Component {...props} /> : <Redirect to='/logout' />)

const AuthenticatedRoute = ({ isAuthenticated, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={renderRoute(isAuthenticated, Component)}
  />
)

export default connect(createStructuredSelector({ isAuthenticated: isAuthenticatedSelector }))(AuthenticatedRoute)
