import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { tokenSelector } from 'core-auth-client'
import { AuthContext } from 'vtb-ui'

const AuthContainer = ({ children, token }) => (
  <AuthContext.Provider value={token}>
    {children}
  </AuthContext.Provider>
)

const mapState = createStructuredSelector({
  token: tokenSelector('MCH'),
})

export const AuthProvider = connect(mapState)(AuthContainer)
