import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { selector } from 'core-ingredients';
import { find, propEq, sortBy, prop, includes } from 'ramda';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var currentCategorySelector = selector('currentCategory', ['categories', 'currentCategoryId'], function (categories, currentCategoryId) {
  // entityUniqueId of category is string but id is number
  return find(propEq('categoryId', Number(currentCategoryId)))(categories || []);
});
var searchProviderSelectBoxOptions = selector('options', ['categories', 'providersOfServices'], function (categories, providersOfServices) {
  return categories && sortBy(prop('sortOrder'), categories).map(function (_ref) {
    var text = _ref.text,
        id = _ref.id;
    var categoryProvidersOfServices = providersOfServices.filter(function (provider) {
      return includes(id, provider.categories);
    });
    return {
      label: text,
      id: id,
      options: sortBy(prop('sortOrder'), categoryProvidersOfServices).map(function (provider) {
        return _objectSpread(_objectSpread({}, provider), {}, {
          label: provider.text,
          categoryId: id,
          img: provider.Icon
        });
      })
    };
  });
});

export { currentCategorySelector, searchProviderSelectBoxOptions };
