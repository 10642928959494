import { composeFragments, pathFragment, action, state, addStateFlag, stateFlag, selector, flow, resolveAction, logic2, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors, listFragment } from 'core-recipes';
import { takeLatest } from 'redux-saga/effects';
import { instructionIdsFragment } from '../../payment-centre/common/instruction-logic.js';
import { wizardFragment } from '../../generic/wizard.fragment.js';
import { TRANSFERS_STEPS } from '../../payment-centre/constants.js';
import { personalInfoFragment } from './personal-info.fragment.js';
import { personalInfoFields } from './input-fields.fragment.js';
import { profileProcessFlow } from './process.flow.js';
import { loadUserFlow } from './personal-info.flow.js';

var personalInfoLogicScheme = composeFragments(pathFragment('profile', 'personalInfo'), personalInfoFields(), withResetForm({}), withInitializeForm({}), withFieldsSelectors, wizardFragment(TRANSFERS_STEPS), personalInfoFragment, listFragment({
  listName: 'countries',
  entityName: 'Countries',
  listQuery: {
    Countries: [],
    where: {
      isDeleted: 'false',
      status: 'true'
    },
    limit: 1000
  }
}), listFragment({
  listName: 'cities',
  entityName: 'Cities',
  listQuery: {
    Cities: [],
    where: {
      isDeleted: 'false',
      status: 'true'
    },
    limit: 1000
  }
}), action('submit'), action('initializeForm'), action('initialize'), action('processData'), action('initialized'), action('loadUser'), action('updateCertificationStatus'), state(['certifiable', 'certificationSubmited'], function (_, _ref) {
  var certifiable = _ref.certifiable;
  return certifiable;
}, false), state(['profilePhoneNumber', 'storeProfilePhoneNumber'], function (_, _ref2) {
  var phoneNumber = _ref2.phoneNumber;
  return phoneNumber;
}, null), state(['initFormValues', 'storeInitFormValues'], function (_, _ref3) {
  var initFormValues = _ref3.initFormValues;
  return initFormValues;
}), addStateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm'], false), stateFlag('loading', ['initialize'], ['initializeForm', 'reset'], false), stateFlag('submitting', ['calling'], ['tmpSuccess', 'tmpFailed', 'failed', 'submited', 'reset']), state(['editing', 'reset'], function () {
  return false;
}), state(['editing', 'tmpFailed'], function () {
  return false;
}), selector('citiesRelevant', ['values', 'cities'], function (values, cities) {
  if (values && values.country && values.country.value) {
    return cities.filter(function (city) {
      return city.country.countryID === values.country.value;
    });
  }

  return cities;
}), instructionIdsFragment, flow('initialize', [resolveAction('initialize'), takeLatest, profileProcessFlow]), flow('loadUser', [resolveAction('loadUser'), takeLatest, loadUserFlow]));
var personalInfoLogic = logic2(personalInfoLogicScheme);
var personalInfoReducer = personalInfoLogic.reducer;
var personalInfoSaga = sagaifyLogic2(personalInfoLogic);

export { personalInfoLogic, personalInfoReducer, personalInfoSaga };
