var INSTRUCTION_STATUSES = {
  ACCOK: 'ACCOK',
  ACCOK2: 'ACCOK2',
  APPROVED: 'APPROVED',
  ACCEPTED: 'ACCEPTED',
  // for USER_INVITATION flow
  REJECTED: 'REJECTED',
  // for USER_INVITATION flow
  CANCELED: 'CANCELED',
  DELETED: 'DELETED',
  DONE: 'DONE',
  DONE_E: 'DONE_E',
  DRAFT: 'DRAFT',
  EDIT: 'EDIT',
  ERROR: 'ERROR',
  ERROR_1: 'ERROR_1',
  ERROR_2: 'ERROR_2',
  ERROR_3: 'ERROR_3',
  FOR_APPROVE: 'FOR_APPROVE',
  FOR_SIGN: 'FOR_SIGN',
  FOR_SIGN_URL: 'forSign',
  FOR_REVIEW: 'FOR_REVIEW',
  IMPORT: 'IMPORT',
  INSTRUCTION_CHECK: 'INSTRUCTION_CHECK',
  LOCK_PROC: 'LOCK_PROC',
  NEW: 'NEW',
  PARTIALLY_DONE: 'PARTIALLY_DONE',
  PASSED: 'PASSED',
  PASSED_E: 'PASSED_E',
  PASSING: 'PASSING',
  PROCESSING: 'PROCESSING',
  READY: 'READY',
  REQESTED_CANCEL: 'REQESTED_CANCEL',
  SAVED: 'SAVED',
  SIGNED: 'SIGNED',
  VERIFIED: 'VERIFIED',
  WAITAUTH: 'WAITAUTH',
  WAITDONE: 'WAITDONE',
  WAITEFFECT: 'WAITEFFECT',
  WAITFUNDS: 'WAITFUNDS',
  WAITMATURE: 'WAITMATURE',
  CANCELLED: 'CANCELLED',
  WAITING_BALANCE: 'WAITING_BALANCE',
  WAITING_DECISION: 'WAITING_DECISION'
};
var PENDING_INSTRUCTION_STATUSES = [INSTRUCTION_STATUSES.VERIFIED, INSTRUCTION_STATUSES.PASSING, INSTRUCTION_STATUSES.PASSED, INSTRUCTION_STATUSES.SIGNED];

export { INSTRUCTION_STATUSES, PENDING_INSTRUCTION_STATUSES };
