import { takeLatest } from 'redux-saga/effects';
import { composeFragments, action, state, stateFlag, flow, resolveAction } from 'core-ingredients';
import { listFragment } from 'core-recipes';
import { billPaymentGroupsQuery, billPaymentGroupCategoriesQuery, billPaymentTypesQuery } from './bill-payment.queries.js';
import { validateTemplateGroupsFlow } from './validate-template-groups.flow.js';

var commonTemplateFragment = composeFragments(listFragment({
  entityName: 'BPTemplateGroups',
  listName: 'groups',
  listQuery: billPaymentGroupsQuery
}), listFragment({
  entityName: 'BPGroupCategories',
  listName: 'groupCategories',
  listQuery: billPaymentGroupCategoriesQuery
}), listFragment({
  entityName: 'BPBillTypes',
  listName: 'types',
  listQuery: billPaymentTypesQuery
}), action('submit', function (mobile, operation) {
  return {
    mobile: mobile,
    operation: operation
  };
}), action('calling'), action('saveTemplate'), action('validateTemplateGroups'), action('done'), state(['isOpen', 'toggleModal'], function (_, _ref) {
  var isOpen = _ref.isOpen;
  return isOpen;
}, false), stateFlag('isSubmited', ['submit', 'saveOnlyTemplate'], ['failed', 'reset', 'resetForm'], false), stateFlag('submitting', ['calling'], ['tmpSuccess', 'tmpFailed', 'failed', 'checked', 'backToFirstStep', 'debtLoadError', 'initializeForm', 'done'], false), stateFlag('saveTemplateFailed', ['tmpFailed'], ['tmpSuccess', 'initializeForm', 'checked', 'backToFirstStep', 'reset', 'resetForm'], false), stateFlag('saveTemplateSuccess', ['done', 'setTemplateSaved'], ['tmpFailed', 'initializeForm', 'checked', 'backToFirstStep', 'reset', 'resetForm'], false), flow('validationFlow', [resolveAction('initializeForm'), takeLatest, validateTemplateGroupsFlow]));

export { commonTemplateFragment };
