import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { display, gridAutoColumns, gridAutoRows, gridAutoFlow, gridColumn, gridGap, gridRow, gridRowGap, gridTemplateAreas, gridTemplateColumns, gridTemplateRows } from '../../node_modules/styled-system/dist/index.esm.js';
import { Flex } from './flex.js';

var _templateObject;
var Grid = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: -ms-grid;\n  -ms-grid-columns: ", ";\n  -ms-grid-rows: ", ";\n  display: grid;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), function (props) {
  return props.gridTemplateColumns ? props.gridTemplateColumns : 'auto';
}, function (props) {
  return props.gridTemplateRows ? props.gridTemplateRows : 'auto';
}, display, gridAutoColumns, gridAutoRows, gridAutoFlow, gridColumn, gridGap, gridRow, gridRowGap, gridTemplateAreas, gridTemplateColumns, gridTemplateRows);

export { Grid };
