var APP_TYPES = {
  GMB: 'GMB',
  LOGIN: 'LOGIN',
  GIB: 'GIB'
};
var FROM_CCY = 'GEL';
var TO_CCY = 'USD,EUR,RUR';
var TO_CCY_LIST = 'USD,EUR,RUR,AZN,CHF,GBP';
var RATE_TABLE = 'CHR';
var RATE_TABLE_CBR = 'CBR';
var RATE_TABLE_CBR_CHR = ['CBR', 'CHR'];
var RATE_TABLE_CBR_CHR_BR = ['CBR', 'CHR', 'BR'];
var rateGEL = {
  id: 'GEL',
  label: 'GEL',
  value: 'GEL',
  flag: '/flags/GEL.png'
};
var rateUSD = {
  id: 'USD',
  label: 'USD',
  value: 'USD',
  flag: '/flags/USD.png'
};

export { APP_TYPES, FROM_CCY, RATE_TABLE, RATE_TABLE_CBR, RATE_TABLE_CBR_CHR, RATE_TABLE_CBR_CHR_BR, TO_CCY, TO_CCY_LIST, rateGEL, rateUSD };
