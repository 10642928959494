import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { BATCH_CONCEPT_TYPE, MAIN_CURRENCY, INSTRUCTION_TYPES, BATCH_PAYMENT_TYPE } from 'vtb-utils';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var batchConceptItemPayloadMapping = function batchConceptItemPayloadMapping(_ref) {
  var conceptType = _ref.conceptType,
      paymentType = _ref.paymentType,
      amount = _ref.amount,
      beneficiaryAccount = _ref.beneficiaryAccount,
      beneficiaryName = _ref.beneficiaryName,
      purpose = _ref.purpose,
      extrInfo = _ref.extrInfo,
      payerName = _ref.payerName,
      payerTaxId = _ref.payerTaxId,
      treasuryCode = _ref.treasuryCode,
      treasuryCodeC = _ref.treasuryCodeC;
  var commonPayload = {
    amount: amount,
    ccy: MAIN_CURRENCY,
    beneficiaryName: beneficiaryName,
    purpose: purpose
  };

  if (conceptType === BATCH_CONCEPT_TYPE.SOMEONE_ELSE) {
    return _objectSpread(_objectSpread({}, commonPayload), {}, {
      beneficiaryAccount: beneficiaryAccount,
      extrInfo: extrInfo,
      paymentType: paymentType === INSTRUCTION_TYPES.toSomeoneElseIntraBank ? BATCH_PAYMENT_TYPE.INTRABANKPAYMENTS : BATCH_PAYMENT_TYPE.INTERBANKLCCYPAYMENTS
    });
  }

  return _objectSpread(_objectSpread({}, commonPayload), {}, {
    treasuryCode: treasuryCode,
    extrInfo: treasuryCodeC,
    payerName: payerName,
    payerTaxId: payerTaxId,
    paymentType: BATCH_PAYMENT_TYPE.TREASURYPAYMENTS,
    isFastPayment: false
  });
};
var batchConceptItemProcessMapping = function batchConceptItemProcessMapping(_ref2) {
  var batchId = _ref2.batchId,
      customerId = _ref2.customerId,
      channelId = _ref2.channelId,
      batchConcept = _ref2.batchConcept,
      paymentDate = _ref2.paymentDate,
      paymentType = _ref2.paymentType,
      debitAccount = _ref2.debitAccount,
      amount = _ref2.amount,
      beneficiaryAccount = _ref2.beneficiaryAccount,
      beneficiaryName = _ref2.beneficiaryName,
      purpose = _ref2.purpose,
      extrInfo = _ref2.extrInfo,
      payerName = _ref2.payerName,
      payerTaxId = _ref2.payerTaxId,
      treasuryCode = _ref2.treasuryCode;
  var commonPayload = {
    batchId: batchId,
    debitAccountId: debitAccount && debitAccount.id,
    amount: amount,
    ccy: MAIN_CURRENCY,
    customerId: Number(customerId),
    date: paymentDate,
    type: paymentType,
    channelId: channelId,
    transactionReference: '-',
    purpose: purpose,
    conceptName: batchConcept === null || batchConcept === void 0 ? void 0 : batchConcept.conceptName,
    conceptDescription: batchConcept === null || batchConcept === void 0 ? void 0 : batchConcept.conceptDescription
  };

  if (batchConcept && batchConcept.conceptType === BATCH_CONCEPT_TYPE.SOMEONE_ELSE) {
    return _objectSpread(_objectSpread({}, commonPayload), {}, {
      beneficiaryAccount: beneficiaryAccount,
      beneficiaryName: beneficiaryName,
      extrInfo: extrInfo
    });
  }

  return _objectSpread(_objectSpread({}, commonPayload), {}, {
    payerName: payerName,
    payerTaxId: payerTaxId,
    treasuryCode: treasuryCode,
    isFastPayment: false
  });
};
var batchConceptSingleItemProcessMapping = function batchConceptSingleItemProcessMapping(_ref3) {
  var customerId = _ref3.customerId,
      channelId = _ref3.channelId,
      paymentDate = _ref3.paymentDate,
      paymentType = _ref3.paymentType,
      debitAccount = _ref3.debitAccount,
      amount = _ref3.amount,
      beneficiaryAccount = _ref3.beneficiaryAccount,
      beneficiaryName = _ref3.beneficiaryName,
      purpose = _ref3.purpose,
      extrInfo = _ref3.extrInfo,
      payerName = _ref3.payerName,
      payerTaxId = _ref3.payerTaxId,
      treasuryCode = _ref3.treasuryCode;
  var commonPayload = {
    debitAccountId: debitAccount && debitAccount.id,
    amount: amount,
    ccy: MAIN_CURRENCY,
    customerId: Number(customerId),
    date: paymentDate,
    channelId: Number(channelId),
    transactionReference: '-',
    purpose: purpose
  };

  if (paymentType === BATCH_PAYMENT_TYPE.INTRABANKPAYMENTS) {
    return _objectSpread(_objectSpread({}, commonPayload), {}, {
      beneficiaryAccount: beneficiaryAccount,
      beneficiaryName: beneficiaryName,
      extrInfo: extrInfo
    });
  }

  if (paymentType === BATCH_PAYMENT_TYPE.INTERBANKLCCYPAYMENTS) {
    return _objectSpread(_objectSpread({}, commonPayload), {}, {
      beneficiaryAccount: beneficiaryAccount,
      beneficiaryName: beneficiaryName,
      extrInfo: extrInfo,
      isFastPayment: false
    });
  }

  return _objectSpread(_objectSpread({}, commonPayload), {}, {
    payerName: payerName,
    payerTaxId: payerTaxId,
    treasuryCode: treasuryCode,
    isFastPayment: false
  });
};

export { batchConceptItemPayloadMapping, batchConceptItemProcessMapping, batchConceptSingleItemProcessMapping };
