import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { equals, pipe, map, filter, flatten, find, propEq, curry, omit, compose } from 'ramda';
import { format } from 'date-fns';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
// constants according to catalog
var EVENT_TYPES = {
  BILL: 'BILL',
  SODD: 'SODD',
  // expiring regular payments
  WAITING: 'WAITING',
  OVERDUE: 'OVERDUE',
  REJECTED: 'REJECTED',
  FINE: 'FINE',
  CARDEXPIRED: 'CARDEXPIRED',
  CALL: 'CALL',
  ARREST: 'ARREST',
  DEPOSIT: 'DEPOSIT',
  COMISSION: 'COMISSION',
  LOANPAYABLE: 'LOANPAYABLE'
};
var mapItemsToCatalogValues = function mapItemsToCatalogValues(actualData) {
  return function (catalogItem) {
    var accountsArrested = actualData.accountsArrested,
        expiringDeposits = actualData.expiringDeposits,
        pendingTransactions = actualData.pendingTransactions,
        accountsAtCall = actualData.accountsAtCall,
        rejectedTransactions = actualData.rejectedTransactions,
        expiringCards = actualData.expiringCards;
    var chipDisplayOneOrAll = catalogItem.chipDisplayOneOrAll,
        eventType = catalogItem.eventType,
        icon = catalogItem.icon,
        text1 = catalogItem.text1,
        text2 = catalogItem.text2,
        text3 = catalogItem.text3,
        text4 = catalogItem.text4;

    switch (catalogItem.eventType) {
      case EVENT_TYPES.ARREST:
        {
          if (accountsArrested === 0) {
            return null;
          }

          return {
            accountsArrested: accountsArrested,
            eventType: eventType,
            text1: text1,
            text4: text4,
            img: icon,
            display: chipDisplayOneOrAll
          };
        }

      case EVENT_TYPES.DEPOSIT:
        {
          return {
            expiringDeposits: expiringDeposits,
            eventType: eventType,
            text1: text1,
            text2: text2,
            text3: text3,
            text4: text4,
            img: icon,
            display: chipDisplayOneOrAll
          };
        }

      case EVENT_TYPES.CALL:
        {
          if (accountsAtCall === 0) {
            return null;
          }

          return {
            accountsAtCall: accountsAtCall,
            eventType: eventType,
            text1: text1,
            text4: text4,
            img: icon,
            display: chipDisplayOneOrAll
          };
        }

      case EVENT_TYPES.REJECTED:
        {
          return {
            rejectedTransactions: rejectedTransactions,
            eventType: eventType,
            text1: text1,
            text4: text4,
            img: icon,
            display: chipDisplayOneOrAll
          };
        }

      case EVENT_TYPES.CARDEXPIRED:
        {
          return {
            expiringCards: expiringCards,
            eventType: eventType,
            text1: text1,
            text2: text2,
            text3: text3,
            text4: text4,
            img: icon,
            display: chipDisplayOneOrAll
          };
        }

      case EVENT_TYPES.WAITING:
        {
          if (pendingTransactions === 0) {
            return null;
          }

          return {
            pendingTransactions: pendingTransactions,
            eventType: eventType,
            text1: text1,
            text4: text4,
            img: icon,
            display: chipDisplayOneOrAll
          };
        }

      default:
        {
          return null;
        }
    }
  };
};
var formatCurrentDate = format(new Date(), 'YYYY-MM-DD');
var dateDiffInDays = curry(function (currDate, specDate) {
  var currentDate = new Date(currDate);
  var specifiedDate = new Date(specDate);
  var diffTime = Math.abs(specifiedDate - currentDate);
  var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (specifiedDate < currentDate) {
    return -diffDays;
  }

  return diffDays;
});
var unpack = function unpack(item) {
  var _item$expiringCards = item.expiringCards,
      expiringCards = _item$expiringCards === void 0 ? [] : _item$expiringCards,
      _item$expiringDeposit = item.expiringDeposits,
      expiringDeposits = _item$expiringDeposit === void 0 ? [] : _item$expiringDeposit,
      _item$rejectedTransac = item.rejectedTransactions,
      rejectedTransactions = _item$rejectedTransac === void 0 ? [] : _item$rejectedTransac,
      img = item.img,
      display = item.display,
      text1 = item.text1,
      text2 = item.text2,
      text3 = item.text3,
      text4 = item.text4,
      eventType = item.eventType;

  if (expiringCards && expiringCards.length > 0) {
    return expiringCards.map(function (card) {
      return _objectSpread(_objectSpread({}, card), {}, {
        img: img,
        display: display,
        eventType: eventType,
        dateDiff: dateDiffInDays(formatCurrentDate)(card.expirationDate),
        text1: text1,
        text2: text2,
        text3: text3,
        text4: text4
      });
    });
  }

  if (expiringDeposits && expiringDeposits.length > 0) {
    return expiringDeposits.map(function (deposit) {
      return _objectSpread(_objectSpread({}, deposit), {}, {
        img: img,
        display: display,
        eventType: eventType,
        dateDiff: dateDiffInDays(formatCurrentDate)(deposit.endDate),
        text1: text1,
        text2: text2,
        text3: text3,
        text4: text4
      });
    });
  }

  if (rejectedTransactions && rejectedTransactions.length > 0) {
    return rejectedTransactions.map(function (transaction) {
      if (!(transaction !== null && transaction !== void 0 && transaction.amount)) {
        return;
      }

      return _objectSpread(_objectSpread({}, transaction), {}, {
        display: display,
        img: img,
        eventType: eventType,
        text1: text1,
        text4: text4
      });
    }).filter(Boolean);
  }
}; // no ids -> comparing object equality -> some props are not needed

var omitProps = function omitProps(item) {
  return omit(['img', 'key', 'type', 'text1', 'text2', 'text3', 'text4', 'label', 'amount', 'display', 'endDate', 'dateDiff', 'currency', 'interacted', 'friendlyName', 'expirationDate', 'nextInstalmentAmount'], item);
};

var organizeChipsData = function organizeChipsData(cachedData, item) {
  var trimmedItem = omitProps(item);

  if (!cachedData) {
    return [_objectSpread({}, trimmedItem)];
  }

  if ((item === null || item === void 0 ? void 0 : item.display) === 'ALL_IN_ONE') {
    return [].concat(_toConsumableArray(cachedData.filter(function (cachedItem) {
      return (cachedItem === null || cachedItem === void 0 ? void 0 : cachedItem.eventType) !== (item === null || item === void 0 ? void 0 : item.eventType);
    })), [_objectSpread({}, trimmedItem)]);
  }

  return [].concat(_toConsumableArray(cachedData), [_objectSpread({}, trimmedItem)]);
};
var compare = function compare(old, actual) {
  var compared = actual.filter(function (item) {
    var strippedActualItem = omitProps(item);
    return !old.some(function (i) {
      var strippedOldItem = omitProps(i);
      return equals(strippedActualItem, strippedOldItem);
    });
  });
  return compared;
};

var determineVisibility = function determineVisibility(dueDaysSysParam) {
  return function (n) {
    return n <= Number(dueDaysSysParam) && !(n < 0);
  };
};

var determinePath = function determinePath(_ref) {
  var _data$creditCardDebt;

  var data = _ref.data;

  if (data !== null && data !== void 0 && (_data$creditCardDebt = data.creditCardDebt) !== null && _data$creditCardDebt !== void 0 && _data$creditCardDebt.nextRepaymentDate) {
    var _data$creditCardDebt2;

    return data === null || data === void 0 ? void 0 : (_data$creditCardDebt2 = data.creditCardDebt) === null || _data$creditCardDebt2 === void 0 ? void 0 : _data$creditCardDebt2.nextRepaymentDate;
  }

  return data === null || data === void 0 ? void 0 : data.nextInstallmentDate;
};

var filterPayableLoans = function filterPayableLoans(data, loans, dueDaysSysParam) {
  var debts = filter(compose(determineVisibility(dueDaysSysParam), dateDiffInDays(formatCurrentDate), determinePath), data) // eslint-disable-next-line @typescript-eslint/no-use-before-define
  .map(formatDebt); // TODO: refactor this shit 🤮

  var linkedDebts = loans.filter(function (_ref2) {
    var cbsLoanId = _ref2.cbsLoanId;
    return debts.some(function (_ref3) {
      var loanId = _ref3.loanId;
      return cbsLoanId === loanId;
    });
  }).map(function (_ref4) {
    var friendlyName = _ref4.friendlyName,
        cbsLoanId = _ref4.cbsLoanId,
        currency = _ref4.currency;
    return debts.map(function (debt) {
      if (debt.loanId === cbsLoanId) {
        return _objectSpread(_objectSpread({}, debt), {}, {
          friendlyName: friendlyName,
          loanCurrency: currency === null || currency === void 0 ? void 0 : currency.currencyID
        });
      }
    });
  });
  return flatten(linkedDebts).filter(function (item) {
    return item;
  });
};

function formatDebt(_ref5) {
  var data = _ref5.data;
  var nextInstallmentDate = data.nextInstallmentDate,
      loanId = data.loanId,
      nextInstallmentAmount = data.nextInstallmentAmount,
      creditCardDebt = data.creditCardDebt;
  return {
    nextInstallmentAmount: nextInstallmentAmount,
    nextInstallmentDate: nextInstallmentDate,
    loanId: loanId,
    creditCardDebt: creditCardDebt,
    dateDiff: dateDiffInDays(formatCurrentDate)(nextInstallmentDate)
  };
}

var firstKey = function firstKey(obj) {
  var _Object$keys;

  return obj && ((_Object$keys = Object.keys(obj)) === null || _Object$keys === void 0 ? void 0 : _Object$keys[0]);
};

var formatDebtsFactory = function formatDebtsFactory(isFine) {
  return function (debt) {
    // TODO can use getDebtParam from packages/logic/src/payment-centre/cars/common/utils.ts
    var singleDebt = !isFine ? debt[firstKey(debt)].data.debtInfo && debt[firstKey(debt)].data.debtInfo[0].debtData : debt[firstKey(debt)].data.data.debtInfo[0].debtData;
    var custDebt = singleDebt && singleDebt.find(function (data) {
      return data.key === 'CUST_DEBT';
    });
    return {
      debtId: firstKey(debt),
      amount: custDebt && custDebt.value || 0
    };
  };
};

var formatDebts = function formatDebts(debts) {
  var isFine = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return map(formatDebtsFactory(isFine), debts).filter(function (item) {
    return Math.round(parseFloat(item.amount)) > 0;
  });
};
var formatFines = function formatFines(fines) {
  return pipe(map(function (debt) {
    return {
      debtId: debt.debtKey,
      amount: debt.custDebt
    };
  }), filter(function (debt) {
    return debt.amount > 0;
  }))(fines);
};
var compareBPDebts = function compareBPDebts(cachedCount, actual) {
  if (cachedCount) {
    var count = cachedCount !== (actual === null || actual === void 0 ? void 0 : actual.length) ? actual === null || actual === void 0 ? void 0 : actual.length : 0;
    return count;
  } else {
    return actual === null || actual === void 0 ? void 0 : actual.length;
  }
};

var descriptionModel = function descriptionModel(dynamicValue, eventType, text1, text2, text3, dateDiff, id) {
  return {
    dynamicValue: dynamicValue,
    eventType: eventType,
    text1: text1,
    text2: text2,
    text3: text3,
    dateDiff: dateDiff,
    id: id
  };
}; // notifications part


var formatItemToNotification = function formatItemToNotification(item) {
  var text1 = item.text1,
      text2 = item.text2,
      text3 = item.text3,
      eventType = item.eventType;
  var itemFirstKey = item === null || item === void 0 ? void 0 : item[firstKey(item)];

  switch (item.eventType) {
    case EVENT_TYPES.COMISSION:
      {
        if (!text1) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel("".concat(itemFirstKey), eventType, text1))
        };
      }

    case EVENT_TYPES.ARREST:
      {
        var accountsArrested = item.accountsArrested;

        if (!text1) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel("".concat(accountsArrested), eventType, text1))
        };
      }

    case EVENT_TYPES.CALL:
      {
        var accountsAtCall = item.accountsAtCall;

        if (!text1) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel("".concat(accountsAtCall), eventType, text1))
        };
      }

    case EVENT_TYPES.REJECTED:
      {
        var amount = item.amount,
            currency = item.currency,
            instructionId = item.instructionId;

        if (!text1) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel("".concat(amount, " ").concat(currency), eventType, text1, null, null, null, Number(instructionId)))
        };
      }

    case EVENT_TYPES.CARDEXPIRED:
      {
        var cardId = item.cardId,
            dateDiff = item.dateDiff;

        if (!text1 || !text2 || !text3) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel("".concat(cardId), eventType, text1, text2, text3, dateDiff))
        };
      }

    case EVENT_TYPES.WAITING:
      {
        if (!text1) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel("".concat(itemFirstKey), eventType, text1))
        };
      }

    case EVENT_TYPES.SODD:
      {
        var expiringRegularPayments = item.expiringRegularPayments,
            _dateDiff = item.dateDiff;

        if (!text1 || !text2 || !text3) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel("".concat(expiringRegularPayments), eventType, text1, text2, text3, _dateDiff))
        };
      }

    case EVENT_TYPES.BILL:
      {
        var readyBillPayments = item.readyBillPayments;

        if (!text1) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel("".concat(readyBillPayments), eventType, text1))
        };
      }

    case EVENT_TYPES.FINE:
      {
        var finePayments = item.finePayments;

        if (!text1) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel("".concat(finePayments), eventType, text1))
        };
      }

    case EVENT_TYPES.OVERDUE:
      {
        var loansOverdue = item.loansOverdue;

        if (!text1) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel("".concat(loansOverdue), eventType, text1))
        };
      }

    case EVENT_TYPES.LOANPAYABLE:
      {
        var nextInstallmentAmount = item.nextInstallmentAmount,
            friendlyName = item.friendlyName,
            _dateDiff2 = item.dateDiff;

        if (!text1 || !text2 || !text3) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel("".concat(nextInstallmentAmount, " ").concat(friendlyName || ''), eventType, text1, text2, text3, _dateDiff2))
        };
      }

    case EVENT_TYPES.DEPOSIT:
      {
        var _dateDiff3 = item.dateDiff;

        if (!text1 || !text2 || !text3) {
          return;
        }

        return {
          subject: item.eventType,
          description: JSON.stringify(descriptionModel('', eventType, text1, text2, text3, _dateDiff3))
        };
      }

    default:
      {
        return {};
      }
  }
};
var formatGdsNotifications = function formatGdsNotifications(_ref6) {
  var subject = _ref6.subject,
      description = _ref6.description;
  return {
    subject: subject,
    description: description
  };
}; // compare notifications after login part

var compareNotification = function compareNotification(notifications) {
  return function (obj) {
    if (!obj) {
      return null;
    } // leave this event type comparison to next function in pipeline


    if ((obj === null || obj === void 0 ? void 0 : obj.subject) === EVENT_TYPES.BILL) {
      return obj;
    }

    var foundNotification = notifications.map(formatGdsNotifications).find(function (notif) {
      return equals(obj, notif);
    });

    if (foundNotification) {
      return null;
    }

    return obj;
  };
}; // mark notification as read (update flow) part

var linkItemWithNotification = function linkItemWithNotification(item, notifications) {
  var _formatItemToNotifica = formatItemToNotification(item),
      description = _formatItemToNotifica.description;

  return find(propEq('description', description))(notifications);
}; // sodd find the nearest expiring payment

var nearestExpiringSodd = function nearestExpiringSodd(arr) {
  var endDates = arr.map(function (i) {
    return i === null || i === void 0 ? void 0 : i.endDate;
  }).filter(Boolean);
  var processed = endDates.map(function (endDate) {
    return dateDiffInDays(formatCurrentDate)(endDate);
  });
  return Math.min.apply(Math, _toConsumableArray(processed));
};

export { EVENT_TYPES, compare, compareBPDebts, compareNotification, dateDiffInDays, filterPayableLoans, firstKey, formatDebts, formatFines, formatGdsNotifications, formatItemToNotification, linkItemWithNotification, mapItemsToCatalogValues, nearestExpiringSodd, organizeChipsData, unpack };
