import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeLatest } from 'redux-saga/effects';
import { composeFragments, selector, flow, resolveAction, stateFlag, extendInitialState } from 'core-ingredients';
import { accountBalancesLogic } from '../../accounts/balances/accounts-balances.logic.js';
import { getCardsOwners, getBusinessOwner } from './cards.helpers.js';
import { unblockCardFlow } from './flows/unblock-card.flow.js';
import { localizationLocaleSelector } from '../../../utils/language-selector.js';
import { clientLogic } from '../../../generic/client/client.logic.js';
import { loadCardsFlow } from './flows/load-cards.flow.js';
import { loadClientsForBusinessCardsFlow } from './flows/load-clients-for-business-cards.flow.js';
import { saveSortingFlow } from './flows/save-sorting.flow.js';
import { blockCardFlow } from './flows/block-card.flow.js';
import { updateCardStausFlow, addAppleCardFlow } from './flows/update-card-status.flow.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var setIndex = function setIndex(map, key, value) {
  var setAlways = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var oldValue = map[key];

  var newState = _objectSpread({}, map);

  if (setAlways || oldValue !== undefined) {
    newState[key] = value;
  }

  return newState;
};

var cardsListFragment = composeFragments({
  actions: {
    loadCards: function loadCards() {
      return {};
    },
    loaded: function loaded(accounts) {
      return {
        accounts: accounts
      };
    },
    loadClientsForBusinessCards: function loadClientsForBusinessCards(cards) {
      return {
        cards: cards
      };
    },
    loadClientsForBusinessCardsSuccess: function loadClientsForBusinessCardsSuccess(clients) {
      return {
        clients: clients
      };
    },
    loadClientsForBusinessCardsError: function loadClientsForBusinessCardsError() {
      return {};
    },
    saveSorting: function saveSorting(cards, accountId) {
      return {
        cards: cards,
        accountId: accountId
      };
    },
    openModal: function openModal(card) {
      return {
        card: card
      };
    },
    closeModal: function closeModal() {
      return {};
    },
    blockCard: function blockCard(cbsId, accountId, reasonId) {
      return {
        cbsId: cbsId,
        accountId: accountId,
        reasonId: reasonId
      };
    },
    unblockCard: function unblockCard(cbsId, ibanAccount) {
      return {
        cbsId: cbsId,
        ibanAccount: ibanAccount
      };
    },
    changeStatusInit: function changeStatusInit(cbsId) {
      return {
        cbsId: cbsId
      };
    },
    changeStatusSuccess: function changeStatusSuccess(cbsId, newStatus, reasonId) {
      return {
        cbsId: cbsId,
        newStatus: "".concat(newStatus, "_SUCCESS"),
        reasonId: reasonId
      };
    },
    changeStatusError: function changeStatusError(cbsId, newStatus) {
      return {
        cbsId: cbsId,
        newStatus: "".concat(newStatus, "_FAILED")
      };
    },
    certificationSubmited: function certificationSubmited(certifiable) {
      return {
        certifiable: certifiable
      };
    },
    onCardNameChange: function onCardNameChange(accountId, cardCbsId, name) {
      return {
        accountId: accountId,
        cardCbsId: cardCbsId,
        name: name
      };
    },
    addCardToAppleWallet: function addCardToAppleWallet(payload) {
      return {
        payload: payload
      };
    }
  },
  state: {
    accounts: {
      'actions.loadCards': function actionsLoadCards() {
        return [];
      },
      'actions.loaded': function actionsLoaded(_, _ref) {
        var accounts = _ref.accounts;
        return accounts;
      },
      'actions.onCardNameChange': function actionsOnCardNameChange(accounts, _ref2) {
        var accountId = _ref2.accountId,
            cardCbsId = _ref2.cardCbsId,
            name = _ref2.name;
        return accounts.map(function (account) {
          if (account.id !== accountId || !Array.isArray(account.cards)) {
            return _objectSpread({}, account);
          }

          var cards = account.cards.map(function (card) {
            if (card.cbsId !== cardCbsId) {
              return _objectSpread({}, card);
            }

            return _objectSpread(_objectSpread({}, card), {}, {
              friendlyName: name
            });
          });
          return _objectSpread(_objectSpread({}, account), {}, {
            cards: cards
          });
        });
      }
    },
    failed: {
      'actions.init': function actionsInit() {
        return false;
      },
      'actions.loadCards': function actionsLoadCards() {
        return false;
      },
      'actions.loadError': function actionsLoadError() {
        return true;
      }
    },
    changeStatusResult: {
      'actions.changeStatusInit': function actionsChangeStatusInit(old, _ref3) {
        var cbsId = _ref3.cbsId;
        return setIndex(old, cbsId, null, true);
      },
      'actions.changeStatusSuccess': function actionsChangeStatusSuccess(old, _ref4) {
        var cbsId = _ref4.cbsId,
            newStatus = _ref4.newStatus;
        return setIndex(old, cbsId, newStatus);
      },
      'actions.changeStatusError': function actionsChangeStatusError(old, _ref5) {
        var cbsId = _ref5.cbsId,
            newStatus = _ref5.newStatus;
        return setIndex(old, cbsId, newStatus);
      },
      'actions.closeModal': function actionsCloseModal() {
        return {};
      }
    },
    isChangeStatusInProgress: {
      'actions.changeStatusInit': function actionsChangeStatusInit() {
        return true;
      },
      'actions.changeStatusSuccess': function actionsChangeStatusSuccess() {
        return false;
      },
      'actions.changeStatusError': function actionsChangeStatusError() {
        return false;
      },
      'actions.closeModal': function actionsCloseModal() {
        return false;
      }
    },
    isCertificationShown: {
      'actions.changeStatusInit': function actionsChangeStatusInit() {
        return false;
      },
      'actions.changeStatusSuccess': function actionsChangeStatusSuccess() {
        return false;
      },
      'actions.changeStatusError': function actionsChangeStatusError() {
        return false;
      },
      'actions.closeModal': function actionsCloseModal() {
        return false;
      },
      'actions.certificationSubmited': function actionsCertificationSubmited() {
        return true;
      }
    },
    businessClients: {
      'actions.loadClientsForBusinessCardsSuccess': function actionsLoadClientsForBusinessCardsSuccess(_, _ref6) {
        var clients = _ref6.clients;
        return clients;
      },
      'actions.loadClientsForBusinessCardsError': function actionsLoadClientsForBusinessCardsError() {
        return [];
      }
    },
    selectedCardCbsId: {
      'actions.openModal': function actionsOpenModal(_, _ref7) {
        var card = _ref7.card;
        return card.cbsId;
      },
      'actions.closeModal': function actionsCloseModal() {
        return null;
      }
    },
    isModalOpen: {
      'actions.openModal': function actionsOpenModal() {
        return true;
      },
      'actions.closeModal': function actionsCloseModal() {
        return false;
      }
    }
  }
}, selector('selectedCard', [['accounts'], ['selectedCardCbsId']], function (accounts, selectedCardCbsId) {
  var card = null;
  accounts.some(function (_ref8) {
    var cards = _ref8.cards;
    card = cards.find(function (_ref9) {
      var cbsId = _ref9.cbsId;
      return selectedCardCbsId === cbsId;
    });
    return card !== undefined;
  });
  return card || null;
}), selector('decoratedAccounts', [['accounts'], ['businessClients'], localizationLocaleSelector, accountBalancesLogic.selectors.balances, clientLogic.selectors.client], function (accounts, businessClients, currentLang, balances, client) {
  if (Array.isArray(accounts) && accounts.length > 0 && balances && currentLang) {
    return accounts.map(function (account) {
      return _objectSpread(_objectSpread({}, account), {}, {
        cards: getCardsOwners(account.cards, currentLang, businessClients, client),
        businessOwner: getBusinessOwner(account, currentLang, businessClients, client)
      });
    });
  }

  return accounts;
}), flow('loadCards', [resolveAction('loadCards'), takeLatest, loadCardsFlow]), flow('loadClientsForBusinessCards', [resolveAction('loadClientsForBusinessCards'), takeLatest, loadClientsForBusinessCardsFlow]), flow('saveSorting', [resolveAction('saveSorting'), takeLatest, saveSortingFlow]), flow('blockCard', [resolveAction('blockCard'), takeLatest, blockCardFlow]), flow('unblockCard', [resolveAction('unblockCard'), takeLatest, unblockCardFlow]), flow('updateCardStatus', [resolveAction('changeStatusSuccess'), takeLatest, updateCardStausFlow]), stateFlag('certifyAppleCardModal', ['openCertifyModal'], ['closeCertifyModal', 'certificationSucceeded', 'resetCertifState'], false), stateFlag('appleCardCertificationSuccess', ['certificationSucceeded'], ['certificationFailed', 'resetCertifState'], false), stateFlag('appleCardCertification', ['startAppleCardCertification'], ['endAppleCardCertification'], false), stateFlag('prinumError', ['setPrinumError'], ['unsetPrinumError'], false), flow('addCardToAppleWallet', [resolveAction('addCardToAppleWallet'), takeLatest, addAppleCardFlow]), extendInitialState(null, {
  accounts: [],
  businessClients: [],
  failed: false,
  isChangeStatusInProgress: false,
  changeStatusResult: {},
  cardsLoadFailed: false,
  cardsLoaded: false,
  selectedCard: null,
  isModalOpen: false
}));

export { cardsListFragment };
