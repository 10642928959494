import * as React from 'react'
import {
  IcOverview,
  IcMyMoney,
  IcPaymentCentre,
  IcPaymentIcon,
} from 'vtb-ui'
import { ROUTES } from 'vtb-utils'

const enabledColor = 'white'
// const disabledColor = 'darkgrey'

export const menuConfig = [
  {
    label: 'common.menu.overview',
    icon: <IcOverview width={35} height={27} color={enabledColor} />,
    textColor: enabledColor,
    // TODO phase 0 preparation
    link: ROUTES.MAIN.OVERVIEW,
    'data-cy': 'mainMenuOverview',
  },
  {
    label: 'common.menu.myMoney',
    icon: <IcMyMoney width={32} height={31} stroke={enabledColor} />,
    textColor: enabledColor,
    link: ROUTES.MY_MONEY.ACCOUNTS,
    base: ROUTES.MY_MONEY,
    'data-cy': 'mainMenuMyMoney',
  },
  {
    label: 'common.menu.paymentCentre',
    icon: <IcPaymentCentre width={43} height={30} color={enabledColor} />,
    textColor: enabledColor,
    // TODO phase 0 preparation
    link: ROUTES.PAYMENT_CENTER.CENTRE,
    base: ROUTES.PAYMENT_CENTER,
    'data-cy': 'mainMenuPaymentCentre',
  },
  {
    label: 'common.menu.transactions',
    icon: <IcPaymentIcon width={50} height={30} color={enabledColor} />,
    textColor: enabledColor,
    link: ROUTES.TRANSACTIONS.MOVEMENTS,
    base: ROUTES.TRANSACTIONS,
    'data-cy': 'mainMenuTransactions',
  },
  // {
  // TODO phase 2 preparation
  // label: 'common.menu.financialPlanner',
  // icon: <IcFinancialPlanner width={29} height={29} color={disabledColor}/>,
  // textColor: disabledColor,
  // link: '/placeholder2',
  // },
  // {
  // TODO phase 2 preparation
  // label: 'common.menu.products',
  // icon: <IcProducts width={32} height={31} color={disabledColor}/>,
  // textColor: disabledColor,
  // link: '/placeholder3',
  // },
]
