import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import { withDefaultProps, ListItem as ListItem$1 } from 'core-ui';
import styled from 'styled-components';
import ListItemView from './views/list-item-view.js';

var _templateObject;
var Label = styled.label(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  margin: 0;\n  padding: 0;\n"])));
var ListItem = withDefaultProps({
  RenderComponent: ListItemView,
  components: {
    Label: Label
  }
}, ListItem$1);

export { ListItem };
