import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { differenceInCalendarDays, parse, differenceInMonths, startOfToday } from 'date-fns';
import { composeEntityName, composeIcon } from 'vtb-utils';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var computeRemainingTime = function computeRemainingTime(startDate, endDate) {
  var remainingDays = differenceInCalendarDays(parse(endDate), parse(new Date()));
  var depositLengthInDays = differenceInCalendarDays(parse(endDate), parse(startDate));
  var remaining;
  var remainingUnit;
  var outOf;
  var outOfUnit;

  if (depositLengthInDays < 31) {
    remaining = remainingDays;
    remainingUnit = 'days';
    outOf = depositLengthInDays;
    outOfUnit = 'days';
  } else {
    var depositLengthInMonths = differenceInMonths(parse(endDate), parse(startDate));
    outOf = depositLengthInMonths;
    outOfUnit = 'months';

    if (remainingDays < 31) {
      remaining = remainingDays;
      remainingUnit = 'days'; // if endDate is smaller than current date, remaining time equals 0

      if (remainingDays < 0) {
        remaining = 0;
      }
    } else {
      var remainingMonths = differenceInMonths(parse(endDate), startOfToday());
      remaining = remainingMonths;
      remainingUnit = 'months';
    }
  }

  return {
    remaining: remaining,
    remainingUnit: remainingUnit,
    outOf: outOf,
    outOfUnit: outOfUnit
  };
};

var processDeposit = function processDeposit(detail) {
  return _objectSpread(_objectSpread({}, detail), {}, {
    id: detail.cbsId,
    currency: detail.currency.currencyID,
    name: composeEntityName(detail),
    releaseType: detail.interestReleaseType && detail.interestReleaseType.text,
    icon: composeIcon(detail),
    iban: detail.depositAccount.ibanAccount
  }, computeRemainingTime(detail.startDate, detail.endDate));
};

export { processDeposit };
