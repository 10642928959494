var passCodeValidation = function passCodeValidation(passcode) {
  var arr = Array.from(passcode.toString()).map(Number);
  var sequence = 0;
  var repeating = 0;
  arr.forEach(function (value, i) {
    if (value + 1 === arr[i + 1]) {
      sequence += 1;
    }

    if (value === arr[i + 1]) {
      repeating += 1;
    }
  });

  if (sequence >= 4 || repeating >= 4) {
    return {
      isValid: false,
      sequence: sequence,
      repeating: repeating
    };
  }

  return {
    isValid: true,
    sequence: sequence,
    repeating: repeating
  };
};

export { passCodeValidation };
