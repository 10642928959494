import { takeLatest } from 'redux-saga/effects';
import { composeFragments, action, state, stateFlag, selector, flow, resolveAction } from 'core-ingredients';
import { isCardOrCurrentGELAccount, ACCOUNT_VISIBILITY, INSTRUCTION_TYPES } from 'vtb-utils';
import { userHasNoAccountsSelector } from '../../common/selectors.js';
import { providerServicesSelector } from '../../common/provider/selectors.js';
import { decideInstructionTypeFragment } from '../../common/decideInstructionTypeFragment.js';
import { sharedAccountsFragment } from '../../common/shared-accounts/shared-accounts.fragment.js';
import { mapSharedAccountsFlow } from '../../common/shared-accounts/map-shared-accounts.js';
import { TRANSFERS_STEPS } from '../../constants.js';
import { bpProcessFlow, GMBPrepareCertificationFlow } from './flows/process-bill-payment.flow.js';
import { bpCheckIdFlow } from './flows/bill-payment-check-id-flow.js';
import { bpRecalculateCommissionFee } from './flows/bp-recalculate-fee.flow.js';
import { bpResetCheckFlow } from './flows/bill-payment-reset-check-flow.js';
import { BILL_NON_CERT, BILL_CERT, CREATE } from '../../../permissions/rights.js';
import { userLogic } from '../../../generic/user/user.logic.js';

var bpFormFragment = composeFragments(sharedAccountsFragment({
  listName: 'debitAccounts',
  afterQueryHandler: mapSharedAccountsFlow,
  filterCondition: isCardOrCurrentGELAccount,
  visibilityKey: ACCOUNT_VISIBILITY.BILL_PAYMENT_INDEBIT,
  instructionTypes: ['T:billPayment', 'T:billPaymentCert']
}), action('submit'), action('checkId'), action('checked'), action('calling'), action('saveTemplate'), action('initializeForm'), action('recalculateFee'), action('resetCheck'), action('GMBPrepareCertification'), state(['paymentsFee', 'feeLoaded'], function (_, _ref) {
  var value = _ref.value;
  return value;
}, null), state(['paymentsFee', 'reset'], function () {
  return null;
}), state(['instructionType', 'setInstructionType'], function (_, _ref2) {
  var value = _ref2.value;
  return value;
}, null), state(['instructionType', 'reset'], function () {
  return null;
}), stateFlag('isResetCustomerCodeForbidden', ['forbidResetCustomerCode'], ['allowResetCustomerCode'], false), stateFlag('forceFilter', ['setForceFilter'], ['initializeForm', 'resetCheckId', 'backToFirstStep', 'reset', 'resetForm'], false), stateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm', 'resetCheck'], false), stateFlag('isSubmittedFirstPart', ['checkId'], ['failed', 'reset', 'resetForm'], false), stateFlag('certifying', ['GMBPrepareCertification', 'certifyWithBalanceValidation', 'verifyingAvailableBalance'], ['failed', 'reset', 'resetForm', 'stop', 'finalizeHasFailed'], false), stateFlag('submitting', ['calling'], ['tmpSuccess', 'tmpFailed', 'failed', 'checked', 'backToFirstStep', 'debtLoadError', 'resetCheckId', 'reset', 'resetForm'], false), stateFlag('isIdChecked', ['checked'], ['reset', 'resetForm', 'resetCheckId'], false), stateFlag('isFromDashboard', ['setFromDashboard'], ['reset', 'resetForm'], false), state(['certifiable', 'certificationSubmited'], function (_, _ref3) {
  var certifiable = _ref3.certifiable;
  return certifiable;
}, false), state(['shouldCertify', 'shouldCertifyInstruction'], function (_, _ref4) {
  var shouldCertify = _ref4.shouldCertify;
  return shouldCertify;
}, false), state(['savedTemplate', 'submit'], function (_, _ref5) {
  var savedTemplate = _ref5.savedTemplate;
  return savedTemplate;
}, null), state(['tmpError', 'tmpFailed'], function (_, _ref6) {
  var error = _ref6.error;
  return error;
}, null), state(['tmpError', 'reset'], function () {
  return null;
}), state(['tmpError', 'setStep'], function (previous, _ref7) {
  var step = _ref7.step;
  return step === TRANSFERS_STEPS.FORM ? null : previous;
}), state(['tmpError', 'backToFirstStep'], function () {
  return null;
}), selector('loading', ['providersLoading', 'servicesLoading', 'categoriesLoading', 'submitting'], function (providersLoading, servicesLoading, categoriesLoading, submitting) {
  return providersLoading || servicesLoading || categoriesLoading || submitting;
}), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, bpProcessFlow]), flow('checkId', [resolveAction('checkId'), takeLatest, bpCheckIdFlow]), flow('recalculateFee', [resolveAction('recalculateFee'), takeLatest, bpRecalculateCommissionFee]), flow('resetCheck', [resolveAction('resetCheck'), takeLatest, bpResetCheckFlow]), flow('GMBPrepareCertification', [resolveAction('GMBPrepareCertification'), takeLatest, GMBPrepareCertificationFlow]), decideInstructionTypeFragment({
  instruction: INSTRUCTION_TYPES.billPayment,
  instructionCert: INSTRUCTION_TYPES.billPaymentCert,
  right: BILL_NON_CERT(CREATE),
  rightCert: BILL_CERT(CREATE),
  isBillPayment: true
}), providerServicesSelector, selector('mainAccount', [userLogic.selectors.mainAccount], function (mainAccount) {
  return mainAccount;
}), selector('hasBalanceProblem', ['submitting', 'values', 'totalCommission'], function (submitting, values, totalCommission) {
  if (values.debitAccount) {
    return submitting && values.debitAccount.balance < Number(values.amount) + (Number(totalCommission) || 0);
  }

  return false;
}), userHasNoAccountsSelector);

export { bpFormFragment };
