import React, { ReactElement, Suspense } from 'react'
import { LoaderWrapper } from 'vtb-ui'

import { ErrorBoundary } from '../../../common'

import { ContentRouting } from './routing'

export const Content = (): ReactElement => (
  <ErrorBoundary>
    <Suspense fallback={<LoaderWrapper loading />}>
      <ContentRouting />
    </Suspense>
  </ErrorBoundary>
)
