var LOAN_CATEGORIES = {
  CONSUMER: 'CAT40004',
  MORTGAGE_LOAN: 'CAT40006',
  CREDIT_CARD: 'CAT40009'
};
var LOAN_PRODUCT_TYPES = {
  OVERDRAFT: 'Overdraft',
  BUSINESS_OVERDRAFT: 'BusinessOverdraft',
  CREDIT_CARD: 'CreditCard',
  CREDIT_LINE: 'CreditLine',
  LOAN: 'Loan',
  INDEXED_LOAN: 'IndexedLoan',
  SUBSIDED_LOAN: 'SubsidedLoan',
  GUARANTEE: 'Guarantee'
};
var LOAN_COLLATERAL_TYPES = {
  MORTGAGE: 'Mortgage',
  TRANSPORT: 'Transport',
  DEPOSIT: 'Deposit',
  BAIL: 'Bail',
  OTHER: 'Other'
};
var LOAN_CLOSED_ID = 'Closed';
var LOAN_PAYMENT_TYPES = {
  NEXT_REPAYMENT: 'NextRepayment',
  FULL_REPAYMENT: 'FullRepayment'
};

export { LOAN_CATEGORIES, LOAN_CLOSED_ID, LOAN_COLLATERAL_TYPES, LOAN_PAYMENT_TYPES, LOAN_PRODUCT_TYPES };
