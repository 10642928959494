import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { uniqWith, eqBy } from 'ramda';

var errorsMerger = function errorsMerger(errors) {
  return {
    errors: uniqWith(eqBy(function (_ref) {
      var code = _ref.code;
      return code;
    }))(_toConsumableArray(errors || []))
  };
};

export { errorsMerger };
