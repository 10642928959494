var VTB_ERRORS = {
  NOT_FOUND: 'vtb.error.core.100',
  NOT_FOUND_MCH: 'mch.vtb.error.core.100001009',
  // something what needed not found
  NOT_FOUND_USER_GAAS: 'mch.vtb.error.core.100003001',
  // User was not found in GAAS
  NOT_FOUND_USER: 'mch.vtb.error.core.100001010',
  // User not found
  UNAUTHORIZED: 'vtb.error.core.101'
};
var PASSWORD_ERRORS_TO_FILTER_OUT = ['error.minLength', 'error.upperCaseValidation', 'error.lowerCaseValidation', 'error.digitValidation', 'error.specialCharValidation', 'error.charsetValidation'];

export { PASSWORD_ERRORS_TO_FILTER_OUT, VTB_ERRORS };
