import { prepareCertifiedData } from './helpers.js';

var prepareSomeoneElseCertifiedData = prepareCertifiedData(['beneficiaryAccount', 'beneficiaryBankCode', 'amount', 'ccy']);
var prepareTreasuryCertifiedData = prepareCertifiedData(['payerTaxId', 'treasuryCode', 'amount', 'ccy']);
var prepareCarCertifiedData = prepareCertifiedData(['serviceCode', 'customerCode', 'amount', 'ccy']);
var prepareBillCertifiedData = prepareCertifiedData(['serviceCode', 'customerCode', 'amount', 'ccy']);
var prepareMobileTopUpCertifiedData = prepareCertifiedData(['serviceCode', 'customerCode', 'amount', 'ccy']);
var prepareDirectDebitCertifiedData = prepareCertifiedData(['serviceCode', 'customerIdentificationNumber', 'amount', 'maxAmount', 'ccy']);
var prepareSoSomeoneElseCertifiedData = prepareCertifiedData(['order__beneficiaryAccount', 'order__beneficiaryBankCode', 'order__amount', 'order__ccy']);
var prepareSoTreasuryCertifiedData = prepareCertifiedData(['order__payerTaxId', 'order__treasuryCode', 'order__amount', 'order__ccy']);

export { prepareBillCertifiedData, prepareCarCertifiedData, prepareDirectDebitCertifiedData, prepareMobileTopUpCertifiedData, prepareSoSomeoneElseCertifiedData, prepareSoTreasuryCertifiedData, prepareSomeoneElseCertifiedData, prepareTreasuryCertifiedData };
