import { composeFragments, pathFragment, resolveLogic } from 'core-ingredients';
import { accountsFragment } from './accounts.fragment.js';
import { accountsRightsFragment } from './accounts-rights.fragment.js';
import { getDebtsFragment } from './get-debts.fragment.js';

var sharedLogicSchema = composeFragments(pathFragment('shared'), accountsFragment, accountsRightsFragment, getDebtsFragment);
var logicAPI = resolveLogic(sharedLogicSchema);
var sharedLogic = logicAPI.logic;
var sharedReducer = logicAPI.reducer;
var sharedSaga = logicAPI.saga;

export { sharedLogic, sharedReducer, sharedSaga };
