import { pathOr, path } from 'ramda';
import { DateTime } from 'vtb-utils';
import { format, isToday, isAfter, isEqual, differenceInCalendarDays } from 'date-fns';

var getRepaymentScheduleItemByDate = function getRepaymentScheduleItemByDate(repaymentSchedule, date) {
  var nearestRepaymentScheduleItem = date && repaymentSchedule.find(function (item) {
    return isEqual(item.date, date);
  });
  return nearestRepaymentScheduleItem || {
    principalAmount: 0,
    interestAmount: 0,
    insurance: 0
  };
};

var dateInNotificationPeriodOrLater = function dateInNotificationPeriodOrLater(_ref) {
  var nextInstallmentDate = _ref.nextInstallmentDate,
      today = _ref.today,
      MCH_LOAN_DUE_DAYS_NOTIFICATION = _ref.MCH_LOAN_DUE_DAYS_NOTIFICATION;
  var differenceDays = differenceInCalendarDays(nextInstallmentDate, today);
  return differenceDays <= MCH_LOAN_DUE_DAYS_NOTIFICATION;
}; // TODO: implement repaymentSchedule or remove
// eslint-disable-next-line @typescript-eslint/no-unused-vars


var calculateDuePayment = function calculateDuePayment(_ref2) {
  var debtLoan = _ref2.debtLoan,
      MCH_LOAN_DUE_DAYS_NOTIFICATION = _ref2.MCH_LOAN_DUE_DAYS_NOTIFICATION,
      _ref2$repaymentSchedu = _ref2.repaymentSchedule,
      repaymentSchedule = _ref2$repaymentSchedu === void 0 ? [] : _ref2$repaymentSchedu,
      _ref2$isFullRepayment = _ref2.isFullRepayment,
      isFullRepayment = _ref2$isFullRepayment === void 0 ? false : _ref2$isFullRepayment;
  var today = new Date();
  var duePayment = {
    total: 0,
    date: format(today, 'YYYY-MM-DD'),
    showInList: false
  };

  if (!debtLoan) {
    return null;
  }

  var nextInstallmentDate = debtLoan.nextInstallmentDate;
  var hasOverDue = Number(debtLoan.overdueAmount) > 0;
  var isInNotificationPeriodOrLater = dateInNotificationPeriodOrLater({
    nextInstallmentDate: nextInstallmentDate,
    today: today,
    MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
  });

  if (hasOverDue) {
    duePayment.overDue = {
      principal: Math.abs(debtLoan.overduePrincipal),
      interest: Math.abs(debtLoan.overdueInterest),
      principalInterest: Math.abs(debtLoan.overduePrincipalInterest),
      insurance: Math.abs(debtLoan.insuranceDebt),
      penalty: Math.abs(debtLoan.penalty),
      amount: Math.abs(debtLoan.overdueAmount),
      date: duePayment.date
    };
    duePayment.total = duePayment.overDue.amount;
  }

  var hasOnlyNotification = !hasOverDue && isInNotificationPeriodOrLater; // @see https://support.bsc-ideas.com/confl/display/VTBREAL/UC+View+loans
  // part WEB Screen description of List of Loans - point 3, 13

  if (hasOverDue && isToday(nextInstallmentDate) || hasOnlyNotification) {
    var nearestRepaymentScheduleItem = getRepaymentScheduleItemByDate(repaymentSchedule, nextInstallmentDate);
    var subsidedInterestAmount = debtLoan.subsidedInterestAmount ? Number(debtLoan.subsidedInterestAmount) : 0;
    duePayment.due = {
      principal: Math.abs(nearestRepaymentScheduleItem.principalAmount),
      interest: Math.abs(nearestRepaymentScheduleItem.interestAmount),
      insurance: Math.abs(nearestRepaymentScheduleItem.insurance),
      amount: isFullRepayment ? Number(pathOr(0, ['creditCardDebt', 'minRepaymentAmount'], debtLoan)) : Number(debtLoan.nextInstallmentAmount) - subsidedInterestAmount,
      date: isFullRepayment ? pathOr(0, ['creditCardDebt', 'nextRepaymentDate'], debtLoan) : debtLoan.nextInstallmentDate
    };

    if (hasOnlyNotification) {
      duePayment.date = duePayment.due.date;
    }

    duePayment.total += duePayment.due.amount;
  }

  if ((hasOverDue && isToday(nextInstallmentDate) || hasOverDue || !hasOverDue && isInNotificationPeriodOrLater) && duePayment.total > 0) {
    duePayment.showInList = true;
  }
  /* TODO: get rid of "mobile" completely... */


  duePayment.mobile = {
    data: {
      amount: duePayment.total,
      date: duePayment.date
    },
    repaidProgress: {
      outstandingPrincipalAmount: debtLoan.outstandingPrincipalAmount,
      withdrawnLoanAmount: debtLoan.withdrawnLoanAmount
    },
    unusedBalance: debtLoan.unusedBalance,
    totalDebt: debtLoan.totalDebt
  };
  return duePayment;
};
var calculateNextPayment = function calculateNextPayment(_ref3) {
  var debtLoan = _ref3.debtLoan,
      MCH_LOAN_DUE_DAYS_NOTIFICATION = _ref3.MCH_LOAN_DUE_DAYS_NOTIFICATION,
      _ref3$repaymentSchedu = _ref3.repaymentSchedule,
      repaymentSchedule = _ref3$repaymentSchedu === void 0 ? [] : _ref3$repaymentSchedu,
      _ref3$isFullRepayment = _ref3.isFullRepayment,
      isFullRepayment = _ref3$isFullRepayment === void 0 ? false : _ref3$isFullRepayment;

  if (!debtLoan) {
    return null;
  }

  var today = new Date();
  var nextInstallmentDate = debtLoan.nextInstallmentDate;
  var hasOverDue = debtLoan.overdueAmount > 0;
  var isInNotificationPeriodOrLater = dateInNotificationPeriodOrLater({
    nextInstallmentDate: nextInstallmentDate,
    today: today,
    MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
  });
  var nearestRepaymentScheduleItem = getRepaymentScheduleItemByDate(repaymentSchedule, nextInstallmentDate);

  if (hasOverDue && isToday(nextInstallmentDate) || isInNotificationPeriodOrLater) {
    nearestRepaymentScheduleItem = getRepaymentScheduleItemByDate(repaymentSchedule, debtLoan.installmentDate);
  }

  var nextPayment = {
    principal: Math.abs(nearestRepaymentScheduleItem.principalAmount),
    interest: Math.abs(nearestRepaymentScheduleItem.interestAmount),
    insurance: Math.abs(nearestRepaymentScheduleItem.insurance),
    amount: 0,
    date: format(today, 'YYYY-MM-DD')
  };

  if (isFullRepayment) {
    nextPayment.amount = debtLoan.totalDebt;

    if (isAfter(debtLoan.creditCardDebt.interestFreePeriodExpireDate, today)) {
      nextPayment.date = debtLoan.creditCardDebt.interestFreePeriodExpireDate;
    }

    return nextPayment;
  }

  var subsidedInterestAmount = debtLoan.subsidedInterestAmount ? Number(debtLoan.subsidedInterestAmount) : 0;

  if (hasOverDue && isToday(nextInstallmentDate) || !hasOverDue && isInNotificationPeriodOrLater) {
    nextPayment.amount = debtLoan.installmentAmount ? debtLoan.installmentAmount - subsidedInterestAmount : 0;
    nextPayment.date = debtLoan.installmentDate;
  } else {
    nextPayment.amount = debtLoan.nextInstallmentAmount ? debtLoan.nextInstallmentAmount - subsidedInterestAmount : 0;
    nextPayment.date = debtLoan.nextInstallmentDate;
  }

  return nextPayment;
};
var calculateFullRepayment = function calculateFullRepayment(_ref4) {
  var debtLoan = _ref4.debtLoan,
      isFullRepayment = _ref4.isFullRepayment;

  if (!debtLoan) {
    return null;
  }

  var CurrentDate = new DateTime(new Date());
  var date = CurrentDate.toISOString();

  if (isFullRepayment && path(['creditCardDebt', 'interestFreePeriodExpireDate'], debtLoan) && DateTime.isBeforeOrEqual(CurrentDate, debtLoan.creditCardDebt.interestFreePeriodExpireDate)) {
    date = debtLoan.creditCardDebt.interestFreePeriodExpireDate;
  }

  return {
    value: debtLoan.totalDebt,
    date: date
  };
};
var prepareRemainingPrincipal = function prepareRemainingPrincipal(_ref5) {
  var debtLoan = _ref5.debtLoan;

  if (!debtLoan) {
    return null;
  }

  return {
    value: debtLoan.outstandingPrincipalAmount,
    outOf: debtLoan.withdrawnLoanAmount
  };
};
var processDebtLoan = function processDebtLoan(_ref6) {
  var debtLoan = _ref6.debtLoan,
      MCH_LOAN_DUE_DAYS_NOTIFICATION = _ref6.MCH_LOAN_DUE_DAYS_NOTIFICATION;
  return {
    fullRepayment: calculateFullRepayment({
      debtLoan: debtLoan,
      isFullRepayment: false
    }),
    duePayment: calculateDuePayment({
      debtLoan: debtLoan,
      isFullRepayment: false,
      MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
    }),
    nextPayment: calculateNextPayment({
      debtLoan: debtLoan,
      MCH_LOAN_DUE_DAYS_NOTIFICATION: MCH_LOAN_DUE_DAYS_NOTIFICATION
    }),
    remainingPrincipal: prepareRemainingPrincipal({
      debtLoan: debtLoan
    })
  };
};
var calculateLoanRepaid = function calculateLoanRepaid(_ref7) {
  var debtLoan = _ref7.debtLoan;

  if (!debtLoan) {
    return null;
  }

  var withdrawnLoanAmount = Number(debtLoan.withdrawnLoanAmount) || 0;
  var outstandingPrincipalAmount = Number(debtLoan.outstandingPrincipalAmount) || 0;

  if (withdrawnLoanAmount > 0 && withdrawnLoanAmount > outstandingPrincipalAmount) {
    return Math.round(Number("".concat((withdrawnLoanAmount - outstandingPrincipalAmount) / withdrawnLoanAmount, "e2")));
  }

  return 0;
};

export { calculateDuePayment, calculateFullRepayment, calculateLoanRepaid, calculateNextPayment, prepareRemainingPrincipal, processDebtLoan };
