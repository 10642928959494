import { validation } from 'core-ingredients';
import { isEmpty, isNil } from 'ramda';

var PASSWORD_MIN_LENGTH = 8;
var PASSWORD_MAX_LENGTH = 100;
var PASSCODE_MIN_LENGTH = 5;
var PASSCODE_MAX_LENGTH = 5;
var validationPasswordFactory = function validationPasswordFactory(params) {
  var _ref = params || {},
      otherPassword = _ref.otherPassword;

  var otherValue = otherPassword && otherPassword.value ? otherPassword.value : null;
  return validation('passwordEquality', function (value) {
    return isNil(value) || isNil(otherValue) ? true : value === otherValue;
  }, null, 'error.repeatPassword');
};
var validationUsernamePasswordFactory = function validationUsernamePasswordFactory(params) {
  var _ref2 = params || {},
      userName = _ref2.userName,
      password = _ref2.password;

  var un = userName && userName.value ? userName.value : null;
  var pw = password && password.value ? password.value : null;
  return validation('userNamePasswordEquality', function () {
    return un === null || pw === null || un !== pw;
  }, null, 'enrollment.step5.passwordInput.cannotBeSameAsLogin');
};
var lowerCaseValidation = validation('lowerCaseValidation', function (value) {
  return value && value !== value.toUpperCase();
}, null, 'enrollment.step5.passwordInput.lowerCaseValidation');
var upperCaseValidation = validation('upperCaseValidation', function (value) {
  return value && value !== value.toLowerCase();
}, null, 'enrollment.step5.passwordInput.upperCaseValidation');
var digitValidation = validation('digitValidation', function (value) {
  return value && /[0-9]/.test(value);
}, null, 'enrollment.step5.passwordInput.digitValidation');
var requiredRule = validation('required', function (value) {
  return value && !isEmpty(value);
}, null, 'enrollment.step4.error.Empty');
var charsetValidation = function charsetValidation() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'enrollment.step5.usernameInput.errorPolicy.errorMessage';
  return validation('charsetValidation', function () {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    if (isEmpty(value) && message === 'enrollment.step5.usernameInput.errorPolicy.errorMessage') {
      return true;
    } else {
      return value && /^[a-zA-Z0-9@_\-.#!$]+$/.test(value);
    }
  }, null, message);
};
var specialCharValidation = validation('specialCharValidation', function (value) {
  return value && /[!@#$\-._]/.test(value);
}, null, 'enrollment.step5.passwordInput.tooltipSpecial');

export { PASSCODE_MAX_LENGTH, PASSCODE_MIN_LENGTH, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, charsetValidation, digitValidation, lowerCaseValidation, requiredRule, specialCharValidation, upperCaseValidation, validationPasswordFactory, validationUsernamePasswordFactory };
