import { logic2CombineReducers } from 'core-ingredients';
import { map, is } from 'ramda';

var combineNestedReducer = function combineNestedReducer(reducer) {
  if (is(Function, reducer)) {
    return reducer;
  }

  var isNestedDeeper = Object.values(reducer).some(function (nestedReducer) {
    return !is(Function, nestedReducer);
  });

  if (isNestedDeeper) {
    var flattened = map(combineNestedReducer, reducer);
    return logic2CombineReducers(flattened);
  }

  return logic2CombineReducers(reducer);
};

var combineReducers = function combineReducers(reducers) {
  var flatReducers = map(combineNestedReducer, reducers);
  var reducer = logic2CombineReducers(flatReducers);
  return reducer;
};

export { combineReducers };
