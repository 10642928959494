import * as React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider, theme } from 'vtb-ui'

import { Router } from '../screens'

import { AuthProvider } from './auth-context'

const Container = ({ store }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </ThemeProvider>
  </Provider>
)

export default Container
