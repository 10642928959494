import { takeLatest } from 'redux-saga/effects';
import { loadAccountBalancesFlow } from './accounts-balances.flow.js';

var accountsBalancesFragment = function accountsBalancesFragment() {
  return {
    actions: {
      balancesLoaded: function balancesLoaded(result) {
        return {
          result: result
        };
      }
    },
    state: {
      balances: {
        'actions.balancesLoaded': function actionsBalancesLoaded(_, _ref) {
          var result = _ref.result;
          return result;
        },
        'actions.failed': function actionsFailed() {}
      },
      loading: {
        'actions.load': function actionsLoad() {
          return true;
        },
        'actions.balancesLoaded': function actionsBalancesLoaded() {
          return false;
        },
        'actions.failed': function actionsFailed() {
          return false;
        }
      }
    },
    selectors: {
      balances: [['balances']]
    },
    flows: {
      loadBalances: ['actions.load', takeLatest, loadAccountBalancesFlow]
    },
    initialState: {
      balances: {},
      loading: false
    }
  };
};

export { accountsBalancesFragment };
