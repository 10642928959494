import { validationRule, maxLength } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withDependentFieldFlow, withDependentField } from 'core-recipes';
import { minMaxAmountDependentField } from '../../common/validations/min-max-amount.js';
import { validationConfig } from './bill-payment-fields-validations.js';
import { requiredRule } from '../../../utils/validators.js';

var transferBillPaymentFieldsFragment = fieldsFragment({})(fieldFragment({
  name: 'serviceCode',
  rules: [validationRule(requiredRule)]
}, withDependentFieldFlow({
  source: 'serviceCode',
  updateHandler: validationConfig.serviceCode.dependentFlow
})), fieldFragment({
  name: 'customerCode',
  rules: [validationRule(maxLength(140)), validationRule(requiredRule)]
}, withDependentField({
  source: 'serviceCode',
  computation: validationConfig.customerCode.serviceComputation
})), fieldFragment({
  name: 'debitAccount',
  rules: [validationRule(requiredRule)]
}), fieldFragment({
  name: 'amount',
  rules: [validationRule(requiredRule) // TODO
  // validationRule(maxLength(5)),
  ]
}, // should not validate amount against amount
withDependentField({
  source: 'serviceCode',
  computation: minMaxAmountDependentField
})));

export { transferBillPaymentFieldsFragment };
