import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeLatest } from 'redux-saga/effects';
import { resolveLogic, composeFragments, pathFragment, action, state, stateFlag, selector, flow, resolveAction } from 'core-ingredients';
import { listFragment, configResetListFragment } from 'core-recipes';
import { OPEN_BANKING_STEPS, MAIN_CURRENCY } from 'vtb-utils';
import { addConsentFlow } from './add-and-extend-consent/add-new-bank.flow.js';
import { extendConsentFlow } from './add-and-extend-consent/extend-consent.flow.js';
import { loadConsentAccountsFlow, reloadConsentAccountsFlow } from './list/load-accounts.flow.js';
import { deleteConsentFlow } from './list/delete-consent.flow.js';
import { sortBalances, sortBanks } from './common.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var openBankingBanksQuery = {
  Banks: ['id', 'icon', 'iban', {
    country: ['countryID', 'name', 'text']
  }, 'text', 'status', 'openBankingAllowed', 'openBankingSorting'],
  where: {
    openBankingAllowed: 'true'
  },
  order: {
    openBankingSorting: 'asc'
  },
  limit: 30
};
var openBankingLogicAPI = resolveLogic(composeFragments(pathFragment('openBanking'), listFragment({
  listName: 'banks',
  entityName: 'banks',
  listQuery: openBankingBanksQuery
}, configResetListFragment({})), action('init'), action('loadConsentAccounts'), action('reloadConsentAccounts'), action('deleteBank'), action('confirmTerms'), action('confirmTermsAndExtendConsent'), action('addingBankTmpFailed'), action('expectedResultFailed'), action('addingBankTmpSuccess'), action('addingBankCancel'), action('loadingTmpExtFailed'), state(['step', 'setStep'], function (_, _ref) {
  var step = _ref.step;
  return step;
}, null), state(['selectedBank', 'selectBank'], function (_, _ref2) {
  var bank = _ref2.bank;
  return bank;
}, null), state(['agree', 'selectAgree'], function (_, _ref3) {
  var agree = _ref3.agree;
  return agree;
}, false), state(['step', 'initAddNewBankWizard'], function () {
  return OPEN_BANKING_STEPS.SELECT_BANK;
}), state(['selectedBank', 'initAddNewBankWizard'], function () {
  return null;
}), state(['agree', 'initAddNewBankWizard'], function () {
  return false;
}), state(['step', 'resetAddNewBankWizard'], function () {
  return null;
}), state(['selectedBank', 'resetAddNewBankWizard'], function () {
  return null;
}), state(['agree', 'resetAddNewBankWizard'], function () {
  return false;
}), state(['bankToDelete', 'openDeleteModal'], function (_, _ref4) {
  var bank = _ref4.bank;
  return bank;
}, null), state(['bankToDelete', 'closeDeleteModal'], function () {
  return null;
}), state(['termsTexts', 'setParameters'], function (_, _ref5) {
  var texts = _ref5.texts;
  return texts;
}, null), state(['duration', 'setParameters'], function (_, _ref6) {
  var duration = _ref6.duration;
  return duration;
}, null), state(['urls', 'setParameters'], function (_, _ref7) {
  var urls = _ref7.urls;
  return urls;
}, null), state(['externalBankUrl', 'setExternalBankUrl'], function (_, _ref8) {
  var url = _ref8.url;
  return url;
}, null), state(['errorStatus', 'addingBankTmpFailed'], function (_, _ref9) {
  var code = _ref9.code;
  return code;
}, null), state(['errorStatus', 'init'], function () {
  return null;
}), state(['errorStatus', 'resetAddNewBankWizard'], function () {
  return null;
}), state(['externalBankUrl', 'init'], function () {
  return null;
}), state(['totalBalance', 'setTotalBalance'], function (_, _ref10) {
  var totalBalance = _ref10.totalBalance;
  return totalBalance;
}, null), state(['totalBalance', 'init'], function () {
  return null;
}), state(['consents', 'stopLoadingConsents'], function (_, _ref11) {
  var consents = _ref11.consents;
  return consents;
}, []), state(['consentAccounts', 'consentAccountsDataLoaded'], function () {
  var _consentAccounts$acco;

  var prevAccounts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  var _ref12 = arguments.length > 1 ? arguments[1] : undefined,
      consentAccounts = _ref12.consentAccounts,
      bankCode = _ref12.bankCode,
      isLoading = _ref12.isLoading;

  var accounts = (consentAccounts === null || consentAccounts === void 0 ? void 0 : (_consentAccounts$acco = consentAccounts.accounts) === null || _consentAccounts$acco === void 0 ? void 0 : _consentAccounts$acco.map(function (account) {
    var _sortBalances, _sortBalances$;

    // TODO take the newest balance when there will be referenceDate in object and type interimAvailable
    var balance = (_sortBalances = sortBalances((account === null || account === void 0 ? void 0 : account.balances) || [])) === null || _sortBalances === void 0 ? void 0 : (_sortBalances$ = _sortBalances[0]) === null || _sortBalances$ === void 0 ? void 0 : _sortBalances$.balanceAmount;
    return _objectSpread(_objectSpread({}, account), {}, {
      balanceOriginalAmount: (balance === null || balance === void 0 ? void 0 : balance.amount) || null,
      balanceCurrency: (balance === null || balance === void 0 ? void 0 : balance.currency) || MAIN_CURRENCY
    });
  })) || [];
  return [].concat(_toConsumableArray(prevAccounts === null || prevAccounts === void 0 ? void 0 : prevAccounts.filter(function (bank) {
    return bank.bankCode !== bankCode;
  })), [{
    bankCode: bankCode,
    accounts: accounts,
    validUntil: consentAccounts.validUntil,
    isExpired: consentAccounts.isExpired,
    notFound: consentAccounts.notFound,
    isLoading: isLoading
  }]);
}, []), state(['consentAccounts', 'loadConsentAccounts'], function () {
  return [];
}), stateFlag('consentsLoaded', ['stopLoadingConsents'], ['loadConsentAccounts'], false), stateFlag('consentsLoading', ['loadConsentAccounts'], ['init', 'stopLoadingConsents'], false), stateFlag('calculatingTotalBalance', ['loadConsentAccounts', 'reloadConsentAccounts'], ['init', 'setTotalBalance'], false), stateFlag('deleteModalOpened', ['openDeleteModal'], ['init', 'closeDeleteModal'], false), stateFlag('deleteBankSuccess', ['deleteBankSucceeded'], ['init', 'closeDeleteModal', 'openDeleteModal'], false), stateFlag('deleteBankError', ['deleteBankFailed'], ['init', 'closeDeleteModal', 'openDeleteModal'], false), stateFlag('deleting', ['startDeleting'], ['init', 'deleteBankSucceeded', 'deleteBankFailed', 'openDeleteModal'], false), stateFlag('addingInProgress', ['startAddingProcess'], ['init', 'stopAddingProcess'], false), stateFlag('addingBankError', ['addingBankTmpFailed'], ['init', 'stopAddingProcess', 'resetAddNewBankWizard', 'initAddNewBankWizard'], false), stateFlag('extendInProgress', ['startExtendConsent'], ['init', 'stopExtendConsent'], false), selector('banksWithAccounts', ['consentAccounts', 'banks', 'consentsLoaded'], function (consentAccounts, banks, consentsLoaded) {
  if (consentAccounts.length === 0 && consentsLoaded) {
    return [];
  }

  return sortBanks(consentAccounts.map(function (_ref13, index) {
    var bankCode = _ref13.bankCode,
        accounts = _ref13.accounts,
        isExpired = _ref13.isExpired,
        validUntil = _ref13.validUntil,
        isLoading = _ref13.isLoading,
        notFound = _ref13.notFound;
    var bank = banks.find(function (bank) {
      return bank.id === bankCode;
    });
    return {
      bankCode: bankCode,
      accounts: accounts,
      validUntil: validUntil,
      isExpired: isExpired,
      notFound: notFound,
      isLoading: isLoading,
      bank: bank,
      // when consent is invalid then sort is to the end of list
      // and when there is no openBankingSorting then sort it to the end but before invalid consents
      sortOrder: isExpired || notFound ? 1000000 + ((bank === null || bank === void 0 ? void 0 : bank.openBankingSorting) || index) : (bank === null || bank === void 0 ? void 0 : bank.openBankingSorting) || index + 500
    };
  }));
}), selector('totalAccountsCount', ['banksWithAccounts'], function (banksWithAccounts) {
  return banksWithAccounts.reduce(function (prev, current) {
    var _current$accounts;

    return prev + ((current === null || current === void 0 ? void 0 : (_current$accounts = current.accounts) === null || _current$accounts === void 0 ? void 0 : _current$accounts.length) || 0);
  }, 0);
}), flow('initFlow', [resolveAction('init'), takeLatest, addConsentFlow]), flow('extendConsentFlow', [resolveAction('init'), takeLatest, extendConsentFlow]), flow('deleteBank', [resolveAction('init'), takeLatest, deleteConsentFlow]), flow('loadConsentAccountsFlow', [resolveAction('loadConsentAccounts'), takeLatest, loadConsentAccountsFlow]), flow('reloadConsentAccountsFlow', [resolveAction('reloadConsentAccounts'), takeLatest, reloadConsentAccountsFlow])));
var openBankingLogic = openBankingLogicAPI.logic;
var openBankingLogicReducer = openBankingLogicAPI.reducer;
var openBankingLogicSaga = openBankingLogicAPI.saga;

export { openBankingLogic, openBankingLogicReducer, openBankingLogicSaga };
