import _defineProperty from '@babel/runtime/helpers/defineProperty';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var LOG_LEVEL = {
  DEBUG: 'Debug',
  INFO: 'Info',
  WARN: 'Warn',
  ERROR: 'Error'
};

var shouldLog = function shouldLog(messageLevel, level) {
  switch (messageLevel) {
    case LOG_LEVEL.DEBUG:
      return level === LOG_LEVEL.DEBUG;

    case LOG_LEVEL.INFO:
      return level === LOG_LEVEL.DEBUG || level === LOG_LEVEL.INFO;

    case LOG_LEVEL.WARN:
      return level === LOG_LEVEL.DEBUG || level === LOG_LEVEL.INFO || level === LOG_LEVEL.WARN;

    case LOG_LEVEL.ERROR:
      return true;

    default:
      return false;
  }
};

var loggingProviderFactory = function loggingProviderFactory() {
  var provider = {
    bootstrapped: false
  };

  var bootstrap = function bootstrap(handler) {
    if (!handler) {
      // eslint-disable-next-line no-console
      console.error('Logging provider needs handler implementation to be bootstrapped.', handler);
      return;
    }

    provider.handler = handler;
    provider.bootstrapped = true;
  };

  var log = function log(params) {
    if (!provider.bootstrapped) {
      // eslint-disable-next-line no-console
      console.warn('Logging provider is not ready.');
      return;
    }

    provider.handler(params);
  };

  return {
    bootstrap: bootstrap,
    log: log
  };
};

var LoggingProvider = loggingProviderFactory();

var logLabelInfo = function logLabelInfo(appLabel, label) {
  return "".concat(appLabel).concat(label ? " - ".concat(label) : '');
};

var userContextInfo = function userContextInfo(userId, contextId) {
  return "".concat(userId ? "user=".concat(userId) : '').concat(contextId ? ",context=".concat(contextId) : '');
};

var devLogHandler = function devLogHandler(appLabel) {
  return function (_ref) {
    var label = _ref.label,
        level = _ref.level,
        message = _ref.message,
        meta = _ref.meta;
    var userId = meta.userId,
        contextId = meta.contextId;
    var entry = "".concat(logLabelInfo(appLabel, label), ": [").concat(userContextInfo(userId, contextId), "] ").concat(message); // eslint-disable-next-line no-console

    var consoleWriter = console[level.toLowerCase()] || console.log;
    consoleWriter(entry);
  };
};
var remoteLogHandler = function remoteLogHandler(appLabel, write) {
  return function (_ref2) {
    var label = _ref2.label,
        level = _ref2.level,
        message = _ref2.message,
        timestamp = _ref2.timestamp,
        meta = _ref2.meta;
    var traceId = meta.traceId,
        userId = meta.userId,
        contextId = meta.contextId;
    var entry = "[".concat(traceId, "] ").concat(logLabelInfo(appLabel, label), ": [").concat(userContextInfo(userId, contextId), "] ").concat(message);
    write({
      message: entry,
      severity: level.toUpperCase(),
      dateTime: timestamp
    });
  };
};

var loggerFactory = function loggerFactory() {
  var defaultLevel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : LOG_LEVEL.INFO;
  var meta = {};
  var logLevel = defaultLevel;

  var log = function log(level, message) {
    if (!shouldLog(level, logLevel)) {
      return;
    }

    var timestamp = new Date();
    LoggingProvider.log({
      level: level,
      message: message,
      timestamp: timestamp,
      meta: meta
    });
  };

  var setLevel = function setLevel(level) {
    logLevel = level;
  };

  var setMeta = function setMeta(key, value) {
    meta[key] = value;
  };

  var logAtLevel = function logAtLevel(level) {
    return function (message) {
      return log(level, message);
    };
  };

  var loggerInstance = function loggerInstance(label) {
    var instanceLevel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : logLevel;
    var initialInstanceMeta = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var instanceMeta = _objectSpread({}, initialInstanceMeta);

    var instanceLogLevel = instanceLevel;

    var instanceLog = function instanceLog(level, message) {
      if (!shouldLog(level, instanceLogLevel)) {
        return;
      }

      var timestamp = new Date();

      var effectiveMeta = _objectSpread(_objectSpread({}, meta), instanceMeta);

      LoggingProvider.log({
        level: level,
        label: label,
        message: message,
        timestamp: timestamp,
        meta: effectiveMeta
      });
    };

    var instanceSetLevel = function instanceSetLevel(level) {
      instanceLogLevel = level;
    };

    var instanceSetMeta = function instanceSetMeta(key, value) {
      instanceMeta[key] = value;
    };

    var instanceLogAtLevel = function instanceLogAtLevel(level) {
      return function (message) {
        return instanceLog(level, message);
      };
    };

    return {
      log: instanceLog,
      meta: instanceSetMeta,
      level: instanceSetLevel,
      debug: instanceLogAtLevel(LOG_LEVEL.DEBUG),
      info: instanceLogAtLevel(LOG_LEVEL.INFO),
      warn: instanceLogAtLevel(LOG_LEVEL.WARN),
      error: instanceLogAtLevel(LOG_LEVEL.ERROR)
    };
  };

  loggerInstance.log = log;
  loggerInstance.meta = setMeta;
  loggerInstance.level = setLevel;
  loggerInstance.debug = logAtLevel(LOG_LEVEL.DEBUG);
  loggerInstance.info = logAtLevel(LOG_LEVEL.INFO);
  loggerInstance.warn = logAtLevel(LOG_LEVEL.WARN);
  loggerInstance.error = logAtLevel(LOG_LEVEL.ERROR);
  return loggerInstance;
};

var Logger = loggerFactory();

export { LOG_LEVEL, Logger, LoggingProvider, devLogHandler, remoteLogHandler };
