import _defineProperty from '@babel/runtime/helpers/defineProperty';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var minMaxAmountDependentField = function minMaxAmountDependentField(originalField, sourceParams) {
  var _sourceParams$value;

  var field = _objectSpread({}, originalField);

  var service = sourceParams === null || sourceParams === void 0 ? void 0 : (_sourceParams$value = sourceParams.value) === null || _sourceParams$value === void 0 ? void 0 : _sourceParams$value.value;

  var _ref = service || {},
      minAmount = _ref.minAmount,
      maxAmount = _ref.maxAmount; // should not be less than min Amount (field in service catalog)
  // should not be more than max Amount (field in service catalog)


  field.errors = field.errors || [];

  if (Number(field.value) < Number(minAmount)) {
    field.errors = field.errors.filter(function (_ref2) {
      var code = _ref2.code;
      return !['error.minAmount'].includes(code);
    });
    field.errors.push({
      code: 'error.minAmount',
      message: 'validation.error.minAmount'
    });
  } else if (Number(field.value) > Number(maxAmount)) {
    field.errors = field.errors.filter(function (_ref3) {
      var code = _ref3.code;
      return !['error.maxAmount'].includes(code);
    });
    field.errors.push({
      code: 'error.maxAmount',
      message: 'validation.error.maxAmount'
    });
  }

  return field;
};

export { minMaxAmountDependentField };
