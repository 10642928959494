var getCarsQuery = function getCarsQuery(id) {
  var q = {
    CarTemplates: ['carTemplateId', 'carTemplateName', 'hasImage', 'carNumber', 'carTechnicalPassportId', 'carOwnerIdNumber', 'sortOrder', 'client', 'originatorUser', 'lastUpdateUser', 'bornDate', 'lastUpdateDate'],
    where: {},
    order: {
      sortOrder: 'asc',
      bornDate: 'asc'
    },
    limit: 1000
  };

  if (id) {
    q.where['carTemplateId'] = id;
    q.limit = 1;
  }

  return q;
};

export { getCarsQuery };
