import { withDefaultProps, Tooltip } from 'core-ui';
import TooltipContent from './view/TooltipContentViewVTB.js';
import TooltipView from './view/TooltipView.js';
import { TooltipWrapperView } from './view/TooltipWrapperViewVTB.js';

var TooltipVTB = withDefaultProps({
  RenderComponent: TooltipView,
  components: {
    tooltipWrapper: TooltipWrapperView,
    tooltipContent: TooltipContent
  }
}, Tooltip);

export { TooltipVTB };
