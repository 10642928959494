import _defineProperty from '@babel/runtime/helpers/defineProperty';
import _objectWithoutProperties from '@babel/runtime/helpers/objectWithoutProperties';
import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { find, propEq, equals, props, pipe, filter, is, map, flatten } from 'ramda';
import { validationRule, maxLength, autocorrectingValidationRule } from 'core-ingredients';
import { groupNameOnlyAllowedChars } from '../../../utils/validation-patterns.js';
import { getCarValidationErrors } from '../../cars/common/validate-cars.js';
import { charset, requiredRule } from '../../../utils/validators.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var templateGroupNameValidationRules = [validationRule(maxLength(30)), autocorrectingValidationRule(charset(groupNameOnlyAllowedChars)), autocorrectingValidationRule(maxLength(30)), validationRule(requiredRule)];
var validateGroups = function validateGroups(groupsToEdit, rules) {
  var uniqueName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var validatedFields = groupsToEdit.map(function (item) {
    var field = {
      id: item.groupId,
      value: item.groupName,
      errors: getCarValidationErrors({
        value: item.groupName
      }, rules)
    };

    if (uniqueName) {
      var _groupsToEdit$filter;

      var isUnique = ((_groupsToEdit$filter = groupsToEdit.filter(function (_ref) {
        var groupName = _ref.groupName;
        return groupName === item.groupName;
      })) === null || _groupsToEdit$filter === void 0 ? void 0 : _groupsToEdit$filter.length) === 1;
      field.errors = !isUnique ? [].concat(_toConsumableArray(field.errors || []), [{
        code: 'error.uniqueName'
      }]) : field.errors;
    }

    return field;
  });
  var errors = pipe(filter(function (field) {
    return field.errors.length;
  }), flatten)(validatedFields);
  return {
    valid: errors.length === 0,
    errors: errors
  };
};
var areThereAnyChanges = function areThereAnyChanges(groupsToEdit, key) {
  return function (_ref2) {
    var groupId = _ref2.groupId,
        groupName = _ref2.groupName,
        rest = _objectWithoutProperties(_ref2, ["groupId", "groupName"]);

    var potentiallyEditedGroup = find(propEq('groupId', groupId))(groupsToEdit);
    return !equals(props(['groupName', key], potentiallyEditedGroup), [groupName, rest[key]]);
  };
};
var parseServerErrors = pipe(filter(function (item) {
  return !is(Object, item.data);
}), map(function (item) {
  if (item.error && item.error.info && Array.isArray(item.error.info.errors) && item.error.info.errors.length > 0) {
    return {
      id: item.groupId,
      value: item.value,
      errors: item.error.info.errors.map(function (error) {
        return _objectSpread(_objectSpread({}, error), {}, {
          message: null
        });
      })
    };
  }
}), filter(function (item) {
  return item;
}));

export { areThereAnyChanges, parseServerErrors, templateGroupNameValidationRules, validateGroups };
