var listQuery = function listQuery() {
  return {
    Deposits: ['cbsId', 'friendlyName', 'initialAmount', 'interestRate', 'startDate', 'endDate', 'totalPlannedGrossInterestAmount', 'paidGrossInterestAmount', 'interestCapitalization', 'depositAmount', {
      interestReleaseType: ['text']
    }, {
      customProductIcon: ['icon']
    }, {
      currency: ['currencyID']
    }, {
      depositTermType: ['text']
    }, {
      depositAccount: ['ibanAccount', {
        accountType: ['accTypeId']
      }]
    }, {
      productType: ['text', {
        productIcon: ['icon']
      }]
    }],
    where: {
      'depositStatus.entityUniqueId': 'Open',
      'depositType.entityUniqueId': 'Term'
    },
    limit: 100
  };
};

export { listQuery };
