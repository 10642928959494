import { fieldsFragment, fieldFragment } from 'core-recipes';
import { validationRule, autocorrectingValidationRule, maxLength } from 'core-ingredients';
import { basicCharsetWithGeorgianAndNoRussianChars, basicCharsetWithGeorgianAndRussianChars } from '../../utils/validation-patterns.js';
import { requiredRule, charset, minAmount } from '../../utils/validators.js';

var BOAAmountFieldFragment = fieldFragment({
  name: 'amount',
  initialValue: '',
  rules: [validationRule(requiredRule), validationRule(minAmount(0.01)), autocorrectingValidationRule(charset(/[0-9]*/))]
});
var transferBOAFieldsFragment = function transferBOAFieldsFragment() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var amountFieldFragment = arguments.length > 1 ? arguments[1] : undefined;
  var isSO = arguments.length > 2 ? arguments[2] : undefined;
  return fieldsFragment(options)(fieldFragment({
    name: 'debitAccount',
    initialValue: '',
    rules: [validationRule(requiredRule)]
  }), fieldFragment({
    name: 'creditAccount',
    initialValue: '',
    rules: [validationRule(requiredRule)]
  }), fieldFragment({
    name: 'paymentsFee',
    initialValue: ''
  }), amountFieldFragment, fieldFragment({
    name: 'purpose',
    initialValue: 'payOA.defpurpose',
    rules: [autocorrectingValidationRule(charset(/\S/gi)), isSO ? validationRule(maxLength(140)) : autocorrectingValidationRule(maxLength(140)), isSO ? autocorrectingValidationRule(charset(basicCharsetWithGeorgianAndNoRussianChars)) : autocorrectingValidationRule(charset(basicCharsetWithGeorgianAndRussianChars)), validationRule(requiredRule)]
  }));
};

export { BOAAmountFieldFragment, transferBOAFieldsFragment };
