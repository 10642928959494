var getCategoriesQuery = function getCategoriesQuery(id) {
  var q = {
    BPCategories: ['categoryIconUri', 'text', 'sortOrder', 'isActiveGemini', 'categoryId'],
    where: {
      isActiveGemini: 'true',
      isDeleted: 'false'
    },
    order: {
      sortOrder: 'asc'
    },
    limit: 1000
  };

  if (id) {
    q['id'] = id;
  }

  return q;
};

export { getCategoriesQuery };
