import { selector } from 'core-ingredients';
import { enrichDataWithService } from 'vtb-utils';

var providerServicesSelector = selector('providerServices', ['services', 'currentProvider'], function (services, currentProvider) {
  var data = [];

  if (Array.isArray(services) && services.length > 0 && currentProvider) {
    services.filter(function (service) {
      return service.provider.providerCode === currentProvider.providerCode;
    }).forEach(enrichDataWithService(data, null, undefined));
  }

  return data;
});

export { providerServicesSelector };
