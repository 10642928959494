import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { backgroundPosition, borderRadius, borderTop, border, bottom, color, display, gridArea, gridColumn, gridRow, height, left, maxHeight, maxWidth, minHeight, minWidth, opacity, position, right, space, borderBottom, borderRight, boxShadow, top, width, zIndex } from '../../node_modules/styled-system/dist/index.esm.js';
import { withAllowedHtmlAttrs } from '../../with-allowed-html-attrs.js';
import { background } from '../extensions/background.js';
import { backgroundLinearGradient } from '../extensions/background-linear-gradient.js';
import { borderBottomLeftRadius, borderBottomRightRadius, borderTopLeftRadius, borderTopRightRadius } from '../extensions/border-radius.js';
import { boxShadow as boxShadow$1 } from '../extensions/box-shadow.js';
import { cursor } from '../extensions/cursor.js';
import { textOverflow } from '../extensions/text-overflow.js';
import { transformSkew } from '../extensions/transform-skew-x.js';
import { whiteSpace } from '../extensions/white-space.js';

var _templateObject;
var Box = styled(withAllowedHtmlAttrs('div'))(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  position: relative;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])),
/* styled-components */
'', backgroundPosition, borderRadius, borderTop, border, bottom, color, display, gridArea, gridColumn, gridRow, height, left, maxHeight, maxWidth, minHeight, minWidth, opacity, position, right, space, borderBottom, borderRight, boxShadow, top, width, zIndex,
/* styled-components extensions */
'', background, backgroundLinearGradient, borderBottomLeftRadius, borderBottomRightRadius, borderTopLeftRadius, borderTopRightRadius, cursor, boxShadow$1, transformSkew, textOverflow, whiteSpace);

export { Box };
