import React, { memo } from 'react'
import { connectLogic } from 'core-ingredients'
import { logoutLogic } from 'vtb-logic'
import { AutoLogoutModal } from 'vtb-ui'

const AutologoutPromptComponent = (props: any) => {
  const { expirationPrompted, extendSession, logout, ignoreExpirationPrompt, expirationTime } = props

  return (
    <AutoLogoutModal
      isOpen={expirationPrompted}
      closeModal={extendSession}
      expirationTime={expirationTime}
      extendSession={extendSession}
      logout={logout}
      ignoreExpiration={ignoreExpirationPrompt}
    />
  )
}

export const AutologoutPrompt = connectLogic(logoutLogic)(memo(AutologoutPromptComponent))
