import { takeLatest } from 'redux-saga/effects';
import { loadOverdraftFlow } from './card-limit-overdraft.flow.js';

var accountCardLimitOverdraftFragment = function accountCardLimitOverdraftFragment() {
  return {
    actions: {
      loadOverdraft: function loadOverdraft(iban) {
        return {
          iban: iban
        };
      },
      overdraftLoaded: function overdraftLoaded(data) {
        return {
          data: data
        };
      }
    },
    state: {
      overdraft: {
        'actions.overdraftLoaded': function actionsOverdraftLoaded(_, _ref) {
          var data = _ref.data;
          return data;
        }
      }
    },
    selectors: {
      overdraft: [['overdraft']]
    },
    flows: {
      loadOvd: ['actions.loadOverdraft', takeLatest, loadOverdraftFlow]
    },
    initialState: {
      overdraft: {}
    }
  };
};

export { accountCardLimitOverdraftFragment };
