import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';

var _templateObject;
var withDrawer = function withDrawer(Component) {
  return styled(Component).attrs(function (_ref) {
    var drawerOn = _ref.drawerOn;
    return {
      left: [drawerOn ? 0 : '-100%', 0]
    };
  })(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transition: all 0.2s ease;\n"])));
};

export { withDrawer };
