import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { pick, pickBy } from 'ramda';
import { INSTRUCTION_TYPES, getStandingOrderType, StandingOrderType, MAIN_CURRENCY } from 'vtb-utils';
import { prepareDirectDebitCertifiedData, prepareSoTreasuryCertifiedData, prepareSoSomeoneElseCertifiedData, prepareTreasuryCertifiedData, prepareCarCertifiedData, prepareBillCertifiedData, prepareMobileTopUpCertifiedData, prepareSomeoneElseCertifiedData } from '../../payment-centre/common/prepare-certified-data-helpers.js';
import { prepareBatchCertifiedData } from '../../payment-centre/common/helpers.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var pickJsonData = function pickJsonData(item) {
  var _item$instructionItem, _item$instructionItem2, _item$instructionItem3;

  return item === null || item === void 0 ? void 0 : (_item$instructionItem = item.instructionItems) === null || _item$instructionItem === void 0 ? void 0 : (_item$instructionItem2 = _item$instructionItem.data) === null || _item$instructionItem2 === void 0 ? void 0 : (_item$instructionItem3 = _item$instructionItem2[0]) === null || _item$instructionItem3 === void 0 ? void 0 : _item$instructionItem3.jsonData;
};

var pickNotNull = function pickNotNull(allowedKeys) {
  return pickBy(function (value, key) {
    return allowedKeys.includes(key) && value !== null && value !== undefined;
  });
};

var normalizeBatchItem = function normalizeBatchItem(item) {
  var mandatoryValues = pick(['batchId', 'debitAccountId', 'amount', 'ccy', 'customerId', 'date', 'type', 'channelId', 'transactionReference', 'purpose', 'conceptName', 'conceptDescription'], item);
  var optionalValues = pickNotNull(['beneficiaryAccount', 'beneficiaryName', 'extrInfo', 'payerName', 'payerTaxId', 'treasuryCode', 'isFastPayment'])(item);
  return _objectSpread(_objectSpread({}, mandatoryValues), optionalValues);
};

var itemsByIdComparator = function itemsByIdComparator(itemA, itemB) {
  if (!(itemA !== null && itemA !== void 0 && itemA.id) || !(itemB !== null && itemB !== void 0 && itemB.id)) {
    return 0;
  }

  var idA = itemA.id.substring(itemA.id.lastIndexOf('.') + 1);
  var idB = itemB.id.substring(itemB.id.lastIndexOf('.') + 1);

  if (idA === idB) {
    return 0;
  }

  return Number(idA) < Number(idB) ? -1 : 1;
};

var composeBatchData = function composeBatchData(items) {
  // sort items by item ID and extract only data used for hash during batch creation
  // (otherwise hashes will differ)
  var payload = items.sort(itemsByIdComparator).map(function (item) {
    return normalizeBatchItem(_objectSpread({}, item.jsonData));
  }); // counting 100x multiplied values (and then divide by 100) to avoid float-storing errors
  // 1.12 + 10 // 11.120000000000001
  // (112 + 1000) / 100 // 11.12

  var totalAmount = payload.reduce(function (sum, item) {
    return sum + Math.round(item.amount * 100);
  }, 0) / 100;

  var subtotals = _defineProperty({}, MAIN_CURRENCY, {
    amount: totalAmount,
    count: payload.length
  });

  return prepareBatchCertifiedData(payload, subtotals);
};

var prepareCertifiedPSD2Data = function prepareCertifiedPSD2Data(item) {
  switch (item.instructionTypeCode) {
    case INSTRUCTION_TYPES.toSomeoneElseSWIFT:
    case INSTRUCTION_TYPES.toSomeoneElseIntraBank:
    case INSTRUCTION_TYPES.toSomeoneElseDomestic:
      return prepareSomeoneElseCertifiedData(pickJsonData(item));

    case INSTRUCTION_TYPES.mobileTopUpCert:
      {
        return prepareMobileTopUpCertifiedData(pickJsonData(item));
      }

    case INSTRUCTION_TYPES.billPaymentCert:
      {
        var _item$instructionItem4, _item$instructionItem5;

        if ((item === null || item === void 0 ? void 0 : (_item$instructionItem4 = item.instructionItems) === null || _item$instructionItem4 === void 0 ? void 0 : (_item$instructionItem5 = _item$instructionItem4.data) === null || _item$instructionItem5 === void 0 ? void 0 : _item$instructionItem5.length) > 1) {
          var _item$instructionItem6;

          return composeBatchData(item === null || item === void 0 ? void 0 : (_item$instructionItem6 = item.instructionItems) === null || _item$instructionItem6 === void 0 ? void 0 : _item$instructionItem6.data);
        }

        var jsonData = pickJsonData(item);

        if (jsonData.carTemplate) {
          return prepareCarCertifiedData(item);
        }

        return prepareBillCertifiedData(pickJsonData(item));
      }

    case INSTRUCTION_TYPES.treasuryTransfer:
      {
        return prepareTreasuryCertifiedData(pickJsonData(item));
      }

    case INSTRUCTION_TYPES.standingOrder:
      {
        var _jsonData = pickJsonData(item);

        var standingOrderType = getStandingOrderType({
          instruction: _jsonData
        });

        switch (standingOrderType) {
          case StandingOrderType.ToSomeOneElse:
            return prepareSoSomeoneElseCertifiedData(pickJsonData(item));

          case StandingOrderType.Treasury:
            return prepareSoTreasuryCertifiedData(pickJsonData(item));

          default:
            return null;
        }
      }

    case INSTRUCTION_TYPES.directDebitCert:
      {
        return prepareDirectDebitCertifiedData(pickJsonData(item));
      }

    case INSTRUCTION_TYPES.batchPayment:
      {
        var _item$instructionItem7, _item$instructionItem8;

        if ((item === null || item === void 0 ? void 0 : (_item$instructionItem7 = item.instructionItems) === null || _item$instructionItem7 === void 0 ? void 0 : (_item$instructionItem8 = _item$instructionItem7.data) === null || _item$instructionItem8 === void 0 ? void 0 : _item$instructionItem8.length) > 1) {
          var _item$instructionItem9;

          return composeBatchData(item === null || item === void 0 ? void 0 : (_item$instructionItem9 = item.instructionItems) === null || _item$instructionItem9 === void 0 ? void 0 : _item$instructionItem9.data);
        }

        break;
      }

    default:
      return null;
  }
};

export { prepareCertifiedPSD2Data };
