import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { path } from 'ramda';

var mapOptions = function mapOptions(collection, _ref) {
  var labelKey = _ref.labelKey,
      valueKey = _ref.valueKey;
  return collection ? collection.map(function (item) {
    return {
      label: item[labelKey || 'text'],
      value: item[valueKey || 'id']
    };
  }) : [];
};
var mapFullOptions = function mapFullOptions(collection, _ref2) {
  var _ref2$labelKey = _ref2.labelKey,
      labelKey = _ref2$labelKey === void 0 ? '' : _ref2$labelKey;
  return collection ? collection.map(function (item) {
    if (!item) {
      return null;
    }

    return {
      label: item[labelKey || 'text'],
      value: item
    };
  }).filter(function (item) {
    return item;
  }) : [];
};
var getDistinctCollection = function getDistinctCollection(collection, keyPath) {
  return _toConsumableArray(new Set(collection.map(function (item) {
    return path(keyPath, item);
  }).filter(function (item) {
    return item !== null;
  })));
};

export { getDistinctCollection, mapFullOptions, mapOptions };
