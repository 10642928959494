import { composeFragments, pathFragment, action, stateFlag, state, flow, resolveAction, resolveLogic } from 'core-ingredients';
import { takeLatest } from 'redux-saga/effects';
import { offersGenerateFlow, extendSessionFlow } from './offers-flows.js';

var offersLogicAPI = composeFragments(pathFragment('offers'), action('loadOffersAppURL'), action('extendSession'), action('requestGenerateFailed'), action('requestExtendFailed'), action('setURL', function (data) {
  return {
    data: data
  };
}), action('setSessionInfo', function (data) {
  return {
    data: data
  };
}), stateFlag('errorOcurred', ['requestGenerateFailed', 'requestExtendFailed'], ['reset'], false), state(['importedAppUrl', 'setURL'], function (_, _ref) {
  var data = _ref.data;
  return data;
}), state(['sessionInfo', 'setSessionInfo'], function (_, _ref2) {
  var data = _ref2.data;
  return data;
}), flow('loadOffersAppURL', [resolveAction('loadOffersAppURL'), takeLatest, offersGenerateFlow]), flow('extendSessionFlow', [resolveAction('extendSession'), takeLatest, extendSessionFlow]));
var logicAPI = resolveLogic(offersLogicAPI);
var offersReducer = logicAPI.reducer;
var offersSaga = logicAPI.saga;
var offersLogic = logicAPI.logic;

export { offersLogic, offersLogicAPI, offersReducer, offersSaga };
