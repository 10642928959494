import React, { memo } from 'react'
import { connectLogic } from 'core-ingredients'
import { BasicBigButton, I18n, StyledModal, theme, Flex } from 'vtb-ui'
import styled from 'styled-components'
import { sharedLogic } from 'vtb-logic'

const Modal = styled(StyledModal)`
.Modal {
  top: auto;
}`

type NoAccountsModalProps = {
  noAccountsModalIsOpen: boolean
  closeNoAccountsModal: () => void
}

const NoAccountsModalComponent = ({ closeNoAccountsModal, noAccountsModalIsOpen }: NoAccountsModalProps) => (
  <Modal
    width='600px'
    responsiveWidth={theme.space.responsiveWidth}
    closeModal={closeNoAccountsModal}
    isOpen={noAccountsModalIsOpen}
    overlayClose
    escapeClose
    header='payment.info.title'
  >
    <Flex flex='1' flexDirection='column' justifyContent='space-around'>
      <Flex maxWidth={500} alignSelf='center'>
        <I18n fontSize={20} fontWeight={300} color={theme.colors.subtitleText}>
          payment.noAccount.text
        </I18n>
      </Flex>
      <Flex alignSelf='center'>
        <BasicBigButton onClick={closeNoAccountsModal}>
          <I18n>common.button.ok</I18n>
        </BasicBigButton>
      </Flex>
    </Flex>
  </Modal>
)

export const NoAccountsModal = connectLogic(sharedLogic)(memo(NoAccountsModalComponent))
