import { isIntraBankInGE } from 'vtb-utils';

var getFirstCreditAccount = function getFirstCreditAccount(so) {
  return Array.isArray(so.creditAccounts) ? so.creditAccounts[0] || null : null;
};
var getFirstDebitAccount = function getFirstDebitAccount(so) {
  return Array.isArray(so.debitAccounts) ? so.debitAccounts[0] || null : null;
};
/**
 * @see https://support.bsc-ideas.com/confl/display/VTBREAL/UC+View+Standing+Order+details
 */

var getSoType = function getSoType(so, types) {
  var firstCreditAccount = getFirstCreditAccount(so);

  if (isIntraBankInGE(so.debitAccount.ibanAccount) && isIntraBankInGE(firstCreditAccount.beneficiaryAccountNumber)) {
    return types.find(function (type) {
      return type.id === '5';
    });
  }
};

export { getFirstCreditAccount, getFirstDebitAccount, getSoType };
