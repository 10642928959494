import { INSTRUCTION_TYPES } from 'vtb-utils';

var isValidInstructionType = function isValidInstructionType(instructionType) {
  return Object.keys(INSTRUCTION_TYPES).map(function (k) {
    return INSTRUCTION_TYPES[k];
  }).indexOf(instructionType) > -1;
};
var isTreasury = function isTreasury(paymentType) {
  return Boolean(paymentType === INSTRUCTION_TYPES.treasuryTransfer);
};
var isCurrencyExchange = function isCurrencyExchange(paymentType) {
  return Boolean(paymentType === INSTRUCTION_TYPES.currencyExchange || paymentType === INSTRUCTION_TYPES.currencyExchangeCert);
};
var isToSomeoneElse = function isToSomeoneElse(paymentType) {
  return Boolean(paymentType === INSTRUCTION_TYPES.toSomeoneElseDomestic || paymentType === INSTRUCTION_TYPES.toSomeoneElseSWIFT);
};
var isIntraBank = function isIntraBank(paymentType) {
  return Boolean(paymentType === INSTRUCTION_TYPES.toSomeoneElseIntraBank);
};

export { isCurrencyExchange, isIntraBank, isToSomeoneElse, isTreasury, isValidInstructionType };
