import { format, parse } from 'date-fns';

/**
 * Returns true/false whether timestamp is expired or not
 * @param timestamp
 * @returns {boolean}
 */

var expired = function expired(timestamp) {
  var currentTime = new Date().getTime();
  return timestamp < currentTime;
};
/**
 * Returns difference between current time and timestamp
 * @param timestamp
 * @returns {string}
 */

var timeDifference = function timeDifference(timestamp) {
  var currentTime = new Date().getTime();
  return ((timestamp - currentTime) / 1000).toFixed(2);
};
var formatDateToFilter = function formatDateToFilter(date) {
  return format(parse(date), 'YYYY-MM-DD');
};

export { expired, formatDateToFilter, timeDifference };
