import { validation } from 'core-ingredients';

var validationPasswordFactory = function validationPasswordFactory(params) {
  var _ref = params || {},
      password = _ref.password,
      repeatPassword = _ref.repeatPassword;

  var af = password && password.value ? password.value : null;
  var at = repeatPassword && repeatPassword.value ? repeatPassword.value : null;
  return validation('passwordEquality', function () {
    return af === null || at === null ? true : af === at;
  }, null, 'security.error.repeatPassword');
};
var validationPasscodeFactory = function validationPasscodeFactory(params) {
  var _ref2 = params || {},
      newPasscode = _ref2.newPasscode,
      repeatPasscode = _ref2.repeatPasscode;

  var af = newPasscode && newPasscode.value ? newPasscode.value : null;
  var at = repeatPasscode && repeatPasscode.value ? repeatPasscode.value : null;
  return validation('passcodeEquality', function () {
    return af === null || at === null ? true : af === at;
  }, null, 'security.error.repeatPasscode');
};
var lowerCaseValidation = validation('lowerCaseValidation', function (value) {
  return value && value !== value.toUpperCase();
}, null, 'security.error.passwordInput.lowerCaseValidation');
var upperCaseValidation = validation('upperCaseValidation', function (value) {
  return value && value !== value.toLowerCase();
}, null, 'security.error.passwordInput.upperCaseValidation');
var digitValidation = validation('digitValidation', function (value) {
  return value && /[0-9]/.test(value);
}, null, 'security.error.passwordInput.digitValidation');

export { digitValidation, lowerCaseValidation, upperCaseValidation, validationPasscodeFactory, validationPasswordFactory };
