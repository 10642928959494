import { composeFragments } from 'core-ingredients';

var personalInfoFragment = composeFragments({
  actions: {
    editToggle: function editToggle() {
      return {};
    },
    editProfileToggle: function editProfileToggle() {
      return {};
    },
    uploadImage: function uploadImage(uploadedImage) {
      return {
        uploadedImage: uploadedImage
      };
    },
    loadAvatar: function loadAvatar(userId) {
      return {
        userId: userId
      };
    },
    setClientData: function setClientData(data) {
      return {
        data: data
      };
    },
    loadLanguage: function loadLanguage(lang) {
      return {
        lang: lang
      };
    },
    onCountrySelected: function onCountrySelected(countrySelected) {
      return {
        countrySelected: countrySelected
      };
    },
    onCitySelected: function onCitySelected(citySelected) {
      return {
        citySelected: citySelected
      };
    },
    handleSaveButton: function handleSaveButton(submitted) {
      return {
        submitted: submitted
      };
    },
    saveUsersData: function saveUsersData() {
      return {};
    },
    toggleSuccessModal: function toggleSuccessModal() {
      return {};
    }
  },
  state: {
    editing: {
      'actions.editToggle': function actionsEditToggle(editing) {
        return !editing;
      }
    },
    editingProfile: {
      'actions.editProfileToggle': function actionsEditProfileToggle(editingPhone) {
        return !editingPhone;
      }
    },
    uploadedImage: {
      'actions.uploadImage': function actionsUploadImage(_, _ref) {
        var uploadedImage = _ref.uploadedImage;
        return uploadedImage;
      }
    },
    clientData: {
      'actions.setClientData': function actionsSetClientData(_, _ref2) {
        var data = _ref2.data;
        return data;
      }
    },
    actualLang: {
      'actions.loadLanguage': function actionsLoadLanguage(_, _ref3) {
        var lang = _ref3.lang;
        return lang;
      }
    },
    countrySelected: {
      'actions.onCountrySelected': function actionsOnCountrySelected(_, _ref4) {
        var countrySelected = _ref4.countrySelected;
        return countrySelected;
      }
    },
    citySelected: {
      'actions.onCitySelected': function actionsOnCitySelected(_, _ref5) {
        var citySelected = _ref5.citySelected;
        return citySelected;
      }
    },
    submitted: {
      'actions.handleSaveButton': function actionsHandleSaveButton() {
        return true;
      }
    },
    successModalDisplayed: {
      'actions.toggleSuccessModal': function actionsToggleSuccessModal(successModalDisplayed) {
        return !successModalDisplayed;
      }
    }
  },
  selectors: {
    editing: [['editing']],
    uploadedImage: [['uploadedImage']],
    citySelected: [['citySelected']],
    countrySelected: [['countrySelected']],
    clientData: [['clientData']],
    countries: [['countries']],
    cities: [['cities']]
  },
  initialState: {
    editing: false,
    editingProfile: false,
    uploadedImage: null,
    clientData: null,
    actualLang: null,
    countrySelected: null,
    citySelected: null,
    submitted: false,
    successModalDisplayed: false
  }
});

export { personalInfoFragment };
