import { includes, pipe, split, reverse, join } from 'ramda';
import DateTime from '../validation/dates.js';

var DEBT_ERRORS = {
  PAYMENT_ALREADY_EXISTS: 'ERR_PAYMENT_ALREADY_EXISTS',
  CODE_NOT_FOUND: 'ERR_CUSTOMER_CODE_NOT_FOUND'
};
var hasDebtPaymentAlreadyExists = function hasDebtPaymentAlreadyExists() {
  var errors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return Array.isArray(errors) && errors.some(function (error) {
    return error.errorCode === DEBT_ERRORS.PAYMENT_ALREADY_EXISTS;
  });
};
var getTransactionReference = function getTransactionReference(customerData, serviceCode) {
  var date = new Date();
  return "".concat(customerData, "/").concat(serviceCode, "/").concat(date.toISOString());
};
var getDebtKey = function getDebtKey(data) {
  return "".concat(data.customerData, "/").concat(data.serviceCode);
};
var parseDateFromVtbFormat = function parseDateFromVtbFormat(date) {
  if (new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/).test(DateTime.toISOString(date))) {
    return DateTime.toISOString(date);
  }

  if (date && includes('-', date)) {
    return pipe(split('-'), reverse, join('-'))(date);
  }

  return pipe(split('.'), reverse, join('-'))(date);
};

export { DEBT_ERRORS, getDebtKey, getTransactionReference, hasDebtPaymentAlreadyExists, parseDateFromVtbFormat };
