import { withDefaultProps, ListItem } from 'core-ui';
import { Flex } from '../../../../components/generic/flex.js';
import { ItemListBasicView } from './views/item-basic-view.js';

var ItemListBasic = withDefaultProps({
  RenderComponent: ItemListBasicView,
  components: {
    Label: Flex
  }
}, ListItem);

export { ItemListBasic };
