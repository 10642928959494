import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import { css } from 'styled-components';

var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var sizes = {
  /* giant: 1280,
  desktop: 1024,
  tablet: 768,
  phone: 376 */

  /* giant: 1920,
  desktop: 1366,
  tablet: 1024,
  phone: 768 */

  /* giant: 1920,
  tablet: 1024, */
  desktop: 1366,
  phone: 768
};
var media = Object.keys(sizes).reduce(function (accumulator, label) {
  var emSize = sizes[label] / 16; // TODO remove @ts-ignore and solve problem with ts lint

  accumulator[label] = function () {
    return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    @media (max-width: ", "em) {\n      // @ts-ignore\n      ", "\n    }\n  "])), emSize, css.apply(void 0, arguments));
  };

  return accumulator;
}, {});
var mediaJS = Object.keys(sizes).reduce(function (accumulator, label) {
  var emSize = sizes[label] / 16;

  accumulator[label] = function () {
    return window.matchMedia("(max-width: ".concat(emSize, "em)"));
  };

  return accumulator;
}, {});
var content = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding-left: 12%;\n  padding-right: 12%;\n  ", "\n  ", "\n"])), media.desktop(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding-left: 5%;\n    padding-right: 5%;\n  "]))), media.phone(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    padding-left: 10px;\n    padding-right: 10px;\n  "]))));
var isMobileView = function isMobileView() {
  var isMobile = mediaJS.phone();
  return isMobile.matches;
};

export { content, isMobileView, media, mediaJS, sizes };
