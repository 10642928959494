import _defineProperty from '@babel/runtime/helpers/defineProperty';
import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import { pipe, sortBy, prop, reverse, uniq, equals } from 'ramda';
import { takeLatest } from 'redux-saga/effects';
import { composeFragments, pathFragment, action, state, stateFlag, selector, flow, resolveAction, resolveLogic } from 'core-ingredients';
import { listFragment, configResetListFragment } from 'core-recipes';
import { BILL_TYPES, INSTRUCTION_TYPES, POPULAR_EVENT_TYPES } from 'vtb-utils';
import { bpTemplatesQuerySchema, serviceQuerySchema, providerCodes, statisticsLoadedFlow } from './statisticsLoadedFlow.js';
import { fetchStatisticsFlow } from './fetch-statistics.js';
import { deconstructReference } from './fetch-payments.js';
import { loadDefaultProvidersFlow } from './loadDefaultProviders.flow.js';
import { sharedLogic } from '../../generic/shared/shared.logic.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var forbiddenBillTypes = [BILL_TYPES.PARKINGPASS, BILL_TYPES.POLICEFINES, BILL_TYPES.PARKINGFINES];
var sortByPopularity = pipe(sortBy(prop('popularity')), reverse);

var getSortedPopularItems = function getSortedPopularItems() {
  var defaultProviders = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var paymentItems = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return defaultProviders ? _toConsumableArray(defaultProviders) : sortByPopularity(paymentItems).slice(0, 10);
};

var templatesQuerySchema = {
  PaymentTemplates: ['templateId', 'templateName', 'beneficiaryName', 'beneficiaryAccount', 'hasImage', 'treasuryCode', 'amount', 'ccy', 'paymentType', 'purpose', 'extrInfo', 'payerName', 'payerTaxId', 'beneficiaryPhoneNumber', 'beneficiaryEmail', 'beneficiaryBankCode', 'bornDate']
};
var defaultBillTypes = {};
var billPaymentTypes = [INSTRUCTION_TYPES.mobileTopUp, INSTRUCTION_TYPES.mobileTopUpCert, INSTRUCTION_TYPES.billPayment, INSTRUCTION_TYPES.billPaymentCert];
var popularPaymentsLogicSchema = composeFragments(pathFragment('popularPayments'), listFragment({
  listName: 'templates',
  entityName: 'PaymentTemplates',
  listQuery: templatesQuerySchema
}, configResetListFragment({})), listFragment({
  listName: 'bpTemplates',
  entityName: 'BPTemplates',
  listQuery: bpTemplatesQuerySchema
}, configResetListFragment({})), listFragment({
  listName: 'providers',
  entityName: 'BPProviders',
  listQuery: {
    BPProviders: ['nameOriginal', 'providerCode', 'providerIconUri', 'isLocalGlobal', 'text'],
    where: {
      isActiveGemini: 'true',
      isActive: 'true',
      isDeleted: 'false'
    },
    limit: 1000
  }
}), listFragment({
  listName: 'services',
  entityName: 'BPServices',
  listQuery: serviceQuerySchema
}), action('init'), action('loadDefaultProviders'), state(['popularPayments', 'setSetPopularPayments'], function (_, _ref) {
  var data = _ref.data;
  return data;
}, null), state(['statistics', 'popularStatsLoaded'], function (_, _ref2) {
  var data = _ref2.data;
  return data;
}, null), state(['profiles', 'profilesLoaded'], function (_, _ref3) {
  var data = _ref3.data;
  return data;
}, []), state(['payments', 'paymentsLoaded'], function (_, _ref4) {
  var data = _ref4.data;
  return data;
}, []), stateFlag('popularPaymentsLoading', ['loadStatistics'], ['setSetPopularPayments'], false), stateFlag('popularPaymentsLoaded', ['setSetPopularPayments'], ['loadPopularPayments'], false), selector('billTypes', ['services'], function (services) {
  if (!services || services.length === 0) {
    return defaultBillTypes;
  }

  var billTypes = services.reduce(function (previous, current) {
    var _current$billType;

    var newList = _objectSpread({}, previous);

    if (current !== null && current !== void 0 && (_current$billType = current.billType) !== null && _current$billType !== void 0 && _current$billType.billTypeKey) {
      newList[current.provider.providerCode] = uniq([].concat(_toConsumableArray(newList[current.provider.providerCode] || []), [current.billType.billTypeKey]));
    }

    return _objectSpread({}, newList);
  }, {});
  return billTypes;
}), selector('isStaticList', [sharedLogic.selectors.accounts], function (accounts) {
  return Boolean(accounts && accounts.length < 1);
}), selector('paymentItems', ['popularPayments', 'services', 'popularPaymentsLoaded', 'profiles', 'payments'], function (popularPayments, services, popularPaymentsLoaded, profiles, payments) {
  if (!popularPaymentsLoaded || !popularPayments) {
    return [];
  }

  var _ref5 = popularPayments || {},
      filteredPayments = _ref5.filteredPayments;

  var regularPayments = [];

  if (payments.length > 0) {
    regularPayments = filteredPayments.map(function (payment) {
      if (!payment.lastTransactionReference) {
        return;
      } // TTSE payment


      var foundPayment = payments.find(function (fetchedPayment) {
        return payment.entityReferenceId === fetchedPayment.beneficiaryAccount;
      });

      if (foundPayment && !billPaymentTypes.includes(payment.entitySubType)) {
        var foundProfile = profiles.find(function (profile) {
          return (profile === null || profile === void 0 ? void 0 : profile.accountIban) === foundPayment.beneficiaryAccount;
        });
        var beneficiaryAccount = foundPayment.beneficiaryAccount,
            beneficiaryName = foundPayment.beneficiaryName,
            beneficiaryBankCode = foundPayment.beneficiaryBankCode,
            beneficiaryBankName = foundPayment.beneficiaryBankName,
            beneficiaryCountry = foundPayment.beneficiaryCountry,
            debitAccountId = foundPayment.debitAccountId,
            amount = foundPayment.amount,
            purpose = foundPayment.purpose,
            beneficiaryAddress = foundPayment.beneficiaryAddress,
            intermediaryBankCode = foundPayment.intermediaryBankCode,
            intermediaryBankName = foundPayment.intermediaryBankName,
            extrInfo = foundPayment.extrInfo,
            commissionType = foundPayment.commissionType;
        return {
          beneficiaryAccount: beneficiaryAccount,
          beneficiaryName: beneficiaryName,
          beneficiaryBankCode: beneficiaryBankCode,
          beneficiaryBankName: beneficiaryBankName,
          beneficiaryCountry: beneficiaryCountry,
          beneficiaryAddress: beneficiaryAddress,
          intermediaryBankCode: intermediaryBankCode,
          intermediaryBankName: intermediaryBankName,
          extrInfo: extrInfo,
          commissionType: commissionType,
          isPaymentType: true,
          isSoPayment: true,
          debitAccount: Number(debitAccountId),
          amount: amount,
          purpose: purpose,
          pictureReference: foundProfile === null || foundProfile === void 0 ? void 0 : foundProfile.pic,
          popularity: payment.popularity,
          forceFilter: true
        };
      } // treasury payments


      var foundTreasuryPayment = payments.find(function (fetchedPayment) {
        return fetchedPayment.treasuryCode === payment.entityReferenceId;
      });

      if (foundTreasuryPayment) {
        var entityType = foundTreasuryPayment.entityType,
            _extrInfo = foundTreasuryPayment.extrInfo,
            _purpose = foundTreasuryPayment.purpose;
        return _objectSpread(_objectSpread({}, foundTreasuryPayment), {}, {
          purpose: entityType === POPULAR_EVENT_TYPES.paymentTemplate ? _extrInfo : _purpose,
          isPaymentType: true,
          isTreasuryPayment: true,
          popularity: payment.popularity
        });
      } // bill payments (including mobileTopUps)


      var foundBillPayment = payments.find(function (fetchedPayment) {
        if (!payment.entityReferenceId.includes(':')) {
          return fetchedPayment.serviceCode === deconstructReference(payment.entityReferenceId)[0];
        }

        var popularBP = [deconstructReference(payment.entityReferenceId)[0], deconstructReference(payment.entityReferenceId)[1]];
        return equals([fetchedPayment.serviceCode, fetchedPayment.customerCode], popularBP);
      });

      if (foundBillPayment && billPaymentTypes.includes(payment.entitySubType)) {
        var _service$billType;

        var service = services.find(function (service) {
          return deconstructReference(payment.entityReferenceId)[0] === service.serviceCode;
        });

        if (forbiddenBillTypes.includes(service === null || service === void 0 ? void 0 : (_service$billType = service.billType) === null || _service$billType === void 0 ? void 0 : _service$billType.billTypeKey)) {
          return;
        }

        var customerCode = foundBillPayment.customerCode,
            _debitAccountId = foundBillPayment.debitAccountId,
            _amount = foundBillPayment.amount;
        return _objectSpread(_objectSpread({}, service || []), {}, {
          isPaymentType: true,
          subscriberId: customerCode,
          customerCode: customerCode,
          isBillPayment: true,
          debitAccount: Number(_debitAccountId),
          amount: _amount,
          popularity: payment.popularity,
          serviceCode: service !== null && service !== void 0 && service.serviceCode ? {
            value: {
              serviceCode: service === null || service === void 0 ? void 0 : service.serviceCode
            }
          } : null
        });
      }
    });
  }

  return _toConsumableArray(regularPayments);
}), selector('defaultProviders', ['paymentItems', 'providers'], function (paymentItems, providers) {
  var _defaultPPProviders;

  var defaultPPProviders = null;

  if ((paymentItems === null || paymentItems === void 0 ? void 0 : paymentItems.length) < 1) {
    defaultPPProviders = (providers || []).filter(function (provider) {
      return providerCodes.includes(provider.providerCode);
    });
  }

  return ((_defaultPPProviders = defaultPPProviders) === null || _defaultPPProviders === void 0 ? void 0 : _defaultPPProviders.map(function (item) {
    return _objectSpread(_objectSpread({}, item), {}, {
      isDefaultProvider: true
    });
  })) || null;
}), selector('sliderItems', ['defaultProviders', 'paymentItems'], function (defaultProviders, paymentItems) {
  return getSortedPopularItems(defaultProviders, paymentItems);
}), flow('initFlow', [resolveAction('init'), takeLatest, fetchStatisticsFlow]), flow('loadPopularPayments', [resolveAction('popularStatsLoaded'), takeLatest, statisticsLoadedFlow]), flow('loadDefaultProviders', [resolveAction('loadDefaultProviders'), takeLatest, loadDefaultProvidersFlow]));
var logicAPI = resolveLogic(popularPaymentsLogicSchema);
var popularPaymentsLogic = logicAPI.logic;
var popularPaymentsReducer = logicAPI.reducer;
var popularPaymentsSaga = logicAPI.saga;

export { logicAPI, popularPaymentsLogic, popularPaymentsReducer, popularPaymentsSaga };
