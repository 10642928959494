import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { servicesForCarsFragment } from '../common/services-for-cars.fragment.js';
import { templateIconFragment } from '../../templates/templates-icon-fragment.js';
import { addCarFormFragment } from './add-car-form.fragment.js';
import { addCarsFieldsFragment } from './add-cars.fields.js';

var addCarLogic = logic2(composeFragments(pathFragment('cars', 'addCar'), servicesForCarsFragment, addCarsFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, addCarFormFragment, templateIconFragment));
var AddCarReducer = addCarLogic.reducer;
var AddCarSaga = sagaifyLogic2(addCarLogic);

export { AddCarReducer, AddCarSaga, addCarLogic };
