import { composeFragments, pathFragment, action, state, resolveLogic } from 'core-ingredients';
import { exportFragment } from '../../generic/export/export.fragment.js';
import { exportMovementConfigFactory } from '../../generic/export/export-config-factories.js';

var movementExportLogicSchema = composeFragments(pathFragment('movementExport'), exportFragment({
  configFactory: exportMovementConfigFactory
}), action('setExportId', function (exportId) {
  return {
    exportId: exportId
  };
}), state(['exportId', 'setExportId'], function (_, _ref) {
  var exportId = _ref.exportId;
  return exportId;
}), action('setExportTemplate', function (exportTemplate) {
  return {
    exportTemplate: exportTemplate
  };
}), state(['exportTemplate', 'setExportTemplate'], function (_, _ref2) {
  var exportTemplate = _ref2.exportTemplate;
  return exportTemplate;
}));
var movementExportLogicAPI = resolveLogic(movementExportLogicSchema);
var movementExportLogic = movementExportLogicAPI.logic;
var movementExportReducer = movementExportLogicAPI.reducer;
var movementExportSaga = movementExportLogicAPI.saga;

export { movementExportLogic, movementExportReducer, movementExportSaga };
