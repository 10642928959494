var getServicesQuery = function getServicesQuery(provider) {
  var withoutFilter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var filter = {
    isActiveGemini: 'true',
    isActive: 'true',
    isDeleted: 'false',
    'provider.isActive': 'true',
    'provider.isActiveGemini': 'true'
  };
  var q = {
    BPServices: ['certify', 'minAmount', 'maxAmount', 'hasDirectDebit', 'serviceCode', 'serviceIconUri', 'serviceName', 'isActive', 'sortOrder', 'isActiveGemini', 'serviceNameLocalizations', 'clientAbonentCodeMask', 'hint', 'permissibleAmountList', 'serviceDebtType', 'features', 'serviceType', 'flowTypes', 'hidden', 'serviceParameters', {
      billType: ['billTypeKey']
    }, {
      provider: ['providerCode', 'text', 'isActive', 'providerIconUri', 'sortOrder', 'isLocalGlobal']
    }, {
      category: ['text', 'categoryId', 'categoryIconUri', 'isActiveGemini', 'sortOrder']
    }],
    where: withoutFilter ? null : filter,
    order: {
      sortOrder: 'asc'
    },
    limit: 1000
  };

  if (provider) {
    q.where['provider.providerCode'] = provider.providerCode;
    q.limit = 1;
  }

  return q;
};
var getServiceParametersQuery = function getServiceParametersQuery() {
  var q = {
    BPServiceParams: ['paramKey', 'hint', 'label'],
    where: {
      isDeleted: 'false'
    },
    limit: 1000
  };
  return q;
};

export { getServiceParametersQuery, getServicesQuery };
