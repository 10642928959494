import {
  createGraphEntry,
  localeInterceptor,
  httpInterceptor,
  oneapiInterceptor,
  authenticationInterceptor,
  authorizationInterceptor,
  oneapiTpmInterceptor,
} from 'core-supply'
import { tokenSelector } from 'core-auth-client'
import { contextSelector, gpsApiInterceptor, traceIdInterceptor } from 'vtb-logic'

import { genApiInterceptor } from './gen-api-interceptor'
import { ibsInterceptor } from './ibs-api-interceptor'
import { gaasInterceptor } from './gaas-interceptors'
import { errorInterceptor } from './error-interceptor'

const gdsInterceptors = [
  authenticationInterceptor('MCH', tokenSelector),
  authorizationInterceptor((state) => contextSelector(state)),
  oneapiInterceptor,
  errorInterceptor,
  traceIdInterceptor(),
  httpInterceptor({ fetch, base: '/vtb-gds/public/business/' }),
]

const genInterceptors = [
  authenticationInterceptor('MCH', tokenSelector),
  genApiInterceptor,
  errorInterceptor,
  traceIdInterceptor(),
  httpInterceptor({ fetch, base: '/vtb-mch/entitlements/business/' }),
]

const tpmInterceptors = [
  authenticationInterceptor('MCH', tokenSelector),
  authorizationInterceptor((state) => contextSelector(state)),
  localeInterceptor(() => 'en'),
  oneapiTpmInterceptor,
  errorInterceptor,
  traceIdInterceptor(),
  httpInterceptor({ fetch, base: '/vtb-tpm/business/' }),
]

const gaasInterceptors = [
  authenticationInterceptor('MCH', tokenSelector),
  gaasInterceptor,
  errorInterceptor,
  httpInterceptor({ fetch, base: '/vtb-gaas/' }),
]

const ibsInterceptors = [
  authenticationInterceptor('MCH', tokenSelector),
  authorizationInterceptor((state) => contextSelector(state)),
  localeInterceptor(() => 'en'),
  ibsInterceptor,
  errorInterceptor,
  traceIdInterceptor(),
  httpInterceptor({ fetch, base: '/vtb-mch/ibs/' }),
]

const gpsInterceptors = [
  authenticationInterceptor('MCH', tokenSelector),
  authorizationInterceptor((state) => contextSelector(state)),
  localeInterceptor(() => 'en'),
  gpsApiInterceptor,
  errorInterceptor,
  traceIdInterceptor(),
  httpInterceptor({ fetch, base: '/vtb-gps/' }),
]

export default createGraphEntry({
  gds: gdsInterceptors,
  gen: genInterceptors,
  tpm: tpmInterceptors,
  gaas: gaasInterceptors,
  ibs: ibsInterceptors,
  gps: gpsInterceptors,
})
