import _defineProperty from '@babel/runtime/helpers/defineProperty';

var _METHOD_REGISTRATIONS;

var AUTHENTICATION_METHODS = {
  BIOMETRY: 'LN_FINGERPRINTDEVREG',
  PASSCODE: 'LN_PINDEVREG',
  SMS: 'VTB_LN_PWD_SMS',
  OTP: 'VTB_LN_PWD_OTP'
};
var EASY_AUTHENTICATION_METHODS = [AUTHENTICATION_METHODS.BIOMETRY, AUTHENTICATION_METHODS.PASSCODE];
var REGULAR_AUTHENTICATION_METHODS = [AUTHENTICATION_METHODS.SMS, AUTHENTICATION_METHODS.OTP];
var CERTIFICATION_METHODS = {
  BIOMETRY: 'FINGERPRINTDEVREG',
  PASSCODE: 'PINDEVREG',
  SMS: 'VTB_SMS',
  OTP: 'VTB_OTP'
};
var EASY_CERTIFICATION_METHODS = [CERTIFICATION_METHODS.BIOMETRY, CERTIFICATION_METHODS.PASSCODE];
var REGULAR_CERTIFICATION_METHODS = [CERTIFICATION_METHODS.SMS, CERTIFICATION_METHODS.OTP];
var BIOMETRIC_METHODS = [AUTHENTICATION_METHODS.BIOMETRY, CERTIFICATION_METHODS.BIOMETRY];
var METHOD_REGISTRATIONS = (_METHOD_REGISTRATIONS = {}, _defineProperty(_METHOD_REGISTRATIONS, AUTHENTICATION_METHODS.BIOMETRY, 'FINGERPRINTSEC'), _defineProperty(_METHOD_REGISTRATIONS, AUTHENTICATION_METHODS.PASSCODE, 'PINSEC'), _defineProperty(_METHOD_REGISTRATIONS, CERTIFICATION_METHODS.BIOMETRY, 'FINGERPRINTSEC'), _defineProperty(_METHOD_REGISTRATIONS, CERTIFICATION_METHODS.PASSCODE, 'PINSEC'), _METHOD_REGISTRATIONS);
var CHANNELS = {
  GIB: 7,
  GMB: 21
};

export { AUTHENTICATION_METHODS, BIOMETRIC_METHODS, CERTIFICATION_METHODS, CHANNELS, EASY_AUTHENTICATION_METHODS, EASY_CERTIFICATION_METHODS, METHOD_REGISTRATIONS, REGULAR_AUTHENTICATION_METHODS, REGULAR_CERTIFICATION_METHODS };
