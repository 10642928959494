import { takeLatest } from 'redux-saga/effects';
import { composeFragments, action, state, stateFlag, selector, flow, resolveAction } from 'core-ingredients';
import { listFragment } from 'core-recipes';
import { billPaymentGroupsQuery, billPaymentGroupCategoriesQuery } from '../template/bill-payment.queries.js';
import { bpGroupProcessFlow } from './bill-payment-group-process.flow.js';

var bpGroupFormFragment = composeFragments(listFragment({
  entityName: 'BPTemplateGroups',
  listName: 'groups',
  listQuery: billPaymentGroupsQuery
}), listFragment({
  entityName: 'BPGroupCategories',
  listName: 'groupCategories',
  listQuery: billPaymentGroupCategoriesQuery
}), action('submit'), action('calling'), action('initializeForm'), action('setCreatedGroup', function (group) {
  return {
    group: group
  };
}), state(['createdGroup', 'setCreatedGroup'], function (_, _ref) {
  var group = _ref.group;
  return group;
}, null), state(['createdGroup', 'reset'], function () {
  return null;
}), state(['createdGroup', 'failed'], function () {
  return null;
}), state(['createdGroup', 'initializeForm'], function () {
  return null;
}), stateFlag('isSuccessResult', ['tmpSuccess'], ['tmpFailed', 'failed', 'reset', 'resetForm'], false), stateFlag('isErrorResult', ['tmpFailed', 'failed'], ['tmpSuccess', 'reset', 'resetForm'], false), stateFlag('isSubmited', ['submit'], ['failed', 'reset', 'resetForm'], false), stateFlag('submitting', ['calling'], ['tmpSuccess', 'tmpFailed', 'failed', 'backToFirstStep'], false), selector('loading', ['groupCategoriesLoading', 'submitting'], function (groupCategoriesLoading, submitting) {
  return groupCategoriesLoading || submitting;
}), flow('initializeForm', [resolveAction('initializeForm'), takeLatest, bpGroupProcessFlow]));

export { bpGroupFormFragment };
