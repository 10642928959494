import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';

var ruChars = 'АаБбВвГгДдЕеЁёЖжЗзИиЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЪъЫыЬьЭэЮюЯя'; // note that you can see squares instead of uppercase chars, but thats ok, its only viewing problem,
// proper ascii code is stored here correctly

var georgianChars = 'ᲐაᲑბᲒგᲓდᲔეᲕვᲖზᲱჱᲗთᲘიᲙკᲚლᲛმᲜნᲲჲᲝოᲞპᲟჟᲠრᲡსᲢტᲳჳᲣუᲷჷᲤფᲥქᲦღᲧყᲸჸᲨშᲩჩᲪცᲫძᲬწᲭჭᲮხᲴჴᲯჯᲰჰᲵჵᲶჶᲹჹᲺჺ';

var starts = function starts(value, charset) {
  return charset.includes((value || '').substr(0, 1));
}; // digits are also considered as part of georgian charset


var startsKa = function startsKa(value) {
  return /^[0-9]/.test(value) || starts(value, georgianChars);
};

var startsRu = function startsRu(value) {
  return starts(value, ruChars);
};
/**
 * Sort entities by first letter of given property according to collection of languages
 * Utility recognizes language of letter
 * @param data Array of entities
 * @param key Name of property of Entity
 * @param languages Collection of languages that drives sorting
 * @param sortBy Sorting function within language data
 * */


var sortAllLanguages = function sortAllLanguages(data, key) {
  var languages = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ['ka', 'en', 'ru'];
  var sortBy = arguments.length > 3 ? arguments[3] : undefined;

  if (!Array.isArray(data)) {
    return data;
  }

  var entities = {};
  languages.forEach(function (lang) {
    entities[lang] = [];
  });
  data.forEach(function (template) {
    if (startsKa(template[key])) {
      entities.ka.push(template);
    } else if (startsRu(template[key])) {
      entities.ru.push(template);
    } else {
      entities.en.push(template);
    }
  });
  return languages.reduce(function (res, lang) {
    var sortedLang = entities[lang];

    if (sortBy) {
      sortedLang = sortBy(entities[lang]);
    }

    return [].concat(_toConsumableArray(res), _toConsumableArray(sortedLang));
  }, []);
};

export { sortAllLanguages };
