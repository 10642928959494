import { jose } from './jose'
import { app } from './app'

const prepareSagaOptions = (entries, onError) => {
  const context = {
    supply: entries,
    storage: {
      getItem: (key) => {
        const value = window.localStorage.getItem(key)
        return value
      },
      setItem: (key, value) => {
        window.localStorage.setItem(key, value)
      },
      removeItem: (key) => {
        window.localStorage.removeItem(key)
      },
    },
    location: window.location,
    jose,
    app,
  }

  return {
    context,
    onError,
  }
}

export default prepareSagaOptions
