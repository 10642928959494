import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeEvery } from 'redux-saga/effects';
import { composeFragments, action, state, flow, resolveAction, extendInitialState, stateFlag } from 'core-ingredients';
import { exportFlow } from './export.flow.js';
import { initExportWaitingRoomFlow, deleteJobFlow, downloadJobFlow } from './export-waiting-room.flow.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var corporateExportFragment = function corporateExportFragment(_ref) {
  var configFactory = _ref.configFactory;
  return composeFragments(action('loadExports'), action('deleteJob'), action('deleteFailed'), action('downloadJob'), state(['exportJobs', 'exportJobsDataLoaded'], function (_, _ref2) {
    var jobs = _ref2.jobs;
    return jobs;
  }, []), state(['isAsynchronous', 'setAsAsynchronous'], function () {
    return true;
  }, false), state(['notifyNumber', 'setNotifyNumber'], function (_, _ref3) {
    var number = _ref3.number;
    return number;
  }, 0), state(['notifyNumber', 'increaseNotifyNumber'], function (notifyNumber) {
    return notifyNumber + 1;
  }, 0), state(['jobsInProcess', 'setJobState'], function (prevState, _ref4) {
    var jobId = _ref4.jobId,
        state = _ref4.state;
    return _objectSpread(_objectSpread({}, prevState), {}, _defineProperty({}, jobId, state));
  }, {}), stateFlag('wrIsOpen', ['openWaitingRoom'], ['closeWaitingRoom', 'reset'], false), stateFlag('exportJobsLoading', ['loadExports'], ['exportJobsDataLoaded', 'reset'], false), flow('initExportWaitingRoomFlow', [resolveAction('loadExports'), takeEvery, initExportWaitingRoomFlow]), flow('deleteJobFlow', [resolveAction('deleteJob'), takeEvery, deleteJobFlow]), flow('downloadJobFlow', [resolveAction('downloadJob'), takeEvery, downloadJobFlow(configFactory)]));
};

var exportFragment = function exportFragment(_ref5) {
  var configFactory = _ref5.configFactory;
  return composeFragments(action('dropDownToggle', function (dropDownState) {
    return {
      dropDownState: dropDownState
    };
  }), action('exportFile', function (exportFileFormat) {
    return {
      exportFileFormat: exportFileFormat
    };
  }), action('exportList', function (saveAs, detail) {
    return {
      saveAs: saveAs,
      detail: detail
    };
  }), action('loaderToggle'), action('exportProgress'), action('showError'), action('errorLoader'), state(['dropDownDisplay', 'dropDownToggle'], function (dropDownDisplay) {
    return !dropDownDisplay;
  }), state(['exportFileFormat', 'exportFile'], function (_, _ref6) {
    var exportFileFormat = _ref6.exportFileFormat;
    return exportFileFormat;
  }), state(['loaderDisplay', 'loaderToggle'], function (loaderDisplay) {
    return !loaderDisplay;
  }), state(['exportStarted', 'exportProgress'], function (exportStarted) {
    return !exportStarted;
  }), state(['errorShown', 'showError'], function (errorShown) {
    return !errorShown;
  }), flow('exportListFlow', [resolveAction('exportList'), takeEvery, exportFlow(configFactory)]), extendInitialState(null, {
    exportFileFormat: null,
    loaderDisplay: false,
    exportStarted: null,
    dropDownDisplay: false,
    errorShown: false
  }), corporateExportFragment({
    configFactory: configFactory
  }));
};

export { exportFragment };
