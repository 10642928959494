import { DIRECT_DEBIT_PAY_TYPE, DIRECT_DEBIT_MAX_DATE } from 'vtb-utils';
import { format } from 'date-fns';

var mapDirectDebitInstructionItem = function mapDirectDebitInstructionItem(props) {
  var _serviceCode$value;

  var debitAccount = props.debitAccount,
      friendlyName = props.friendlyName,
      serviceCode = props.serviceCode,
      customerCode = props.customerCode,
      payType = props.payType,
      amount = props.amount,
      startDate = props.startDate,
      endDate = props.endDate,
      payDay = props.payDay,
      notify = props.notify,
      ddIcon = props.ddIcon,
      customerId = props.customerId,
      channelId = props.channelId;
  var payLoad = {
    channelId: channelId,
    clientId: customerId,
    // we have to send externalClientId
    friendlyName: friendlyName,
    customerIdentificationNumber: customerCode,
    serviceCode: (serviceCode === null || serviceCode === void 0 ? void 0 : (_serviceCode$value = serviceCode.value) === null || _serviceCode$value === void 0 ? void 0 : _serviceCode$value.serviceCode) || (serviceCode === null || serviceCode === void 0 ? void 0 : serviceCode.value),
    payType: payType,
    maxAmount: payType === DIRECT_DEBIT_PAY_TYPE.BILL_AMOUNT ? amount : undefined,
    amount: payType === DIRECT_DEBIT_PAY_TYPE.FIX_AMOUNT ? amount : undefined,
    accountId: debitAccount === null || debitAccount === void 0 ? void 0 : debitAccount.id,
    ccy: debitAccount === null || debitAccount === void 0 ? void 0 : debitAccount.currency,
    startDate: format(startDate, 'YYYY-MM-DD'),
    endDate: notify ? DIRECT_DEBIT_MAX_DATE : endDate && format(endDate, 'YYYY-MM-DD'),
    payDay: payDay === null || payDay === void 0 ? void 0 : payDay.value,
    ddIcon: ddIcon === null || ddIcon === void 0 ? void 0 : ddIcon.base64
  };
  return payLoad;
};

export { mapDirectDebitInstructionItem };
