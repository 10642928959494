export const genApiInterceptor = {
  name: 'genApi',
  enter: (operation) => {
    const { query } = operation
    const { name, authentication } = query

    const headers = {
      authorization: `Bearer ${authentication}`,
    }

    const request = {
      method: 'GET',
      url: name,
      headers,
    }

    return {
      ...operation,
      request,
    }
  },
  leave: async({ response, ...operation }) => {
    const { location } = response.headers

    if (location) {
      return {
        ...operation,
        data: { location },
      }
    }

    if (response.body) {
      const data = response.body

      return {
        ...operation,
        data,
      }
    }

    return {
      ...operation,
      data: {},
    }
  },
}
