import { Jose } from 'jose-jwe-jws'

export const jose = {
  encryptJWE: async(message, headers, publicKey) => {
    const keyEncryptionAlgorithm = 'RSA-OAEP'
    const contentEncryptionAlgorithm = 'A256GCM'

    const cryptographer = new Jose.WebCryptographer()
    cryptographer.setContentEncryptionAlgorithm(contentEncryptionAlgorithm)

    const rsaKey = Jose.Utils.importRsaPublicKey(publicKey, keyEncryptionAlgorithm)
    const encrypter = new Jose.JoseJWE.Encrypter(cryptographer, rsaKey)

    Object.entries(headers).forEach(([key, value]) => {
      encrypter.addHeader(key, value)
    })

    const jwe = await encrypter.encrypt(message)
    return jwe
  },
}
