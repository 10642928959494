import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { map } from 'ramda';
import { setCardAccountBalance } from '../../accounts/list/accounts-list.helpers.js';
import { processAccountCards } from './card.helpers.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var processAccountInfo = function processAccountInfo(account) {
  return {
    iban: account.ibanAccount,
    friendlyName: account.friendlyName || account.accountName
  };
};

var processAvailableBalance = function processAvailableBalance(account, availableBalances) {
  var modifiedAccount = _objectSpread({}, account);

  setCardAccountBalance(modifiedAccount, availableBalances);
  return {
    availableBalance: modifiedAccount.availableBalanceSum
  };
};

var processAccount = function processAccount(creditCardAccounts, accountCards, availableBalances) {
  return map(function (account) {
    return _objectSpread(_objectSpread(_objectSpread({}, processAccountInfo(account)), processAccountCards(account, accountCards)), processAvailableBalance(account, availableBalances));
  })(creditCardAccounts);
};

export { processAccount };
