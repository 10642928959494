import _taggedTemplateLiteral from '@babel/runtime/helpers/taggedTemplateLiteral';
import styled from 'styled-components';
import { style, display, alignItems, alignSelf, flexBasis, flexDirection, flexWrap, flex, justifyContent, justifyItems, justifySelf, order, overflow, textAlign } from '../../node_modules/styled-system/dist/index.esm.js';
import { Box } from './box.js';

var _templateObject;
var direction = style({
  cssProperty: 'direction',
  prop: 'direction'
});
var Flex = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), display, alignItems, alignSelf, flexBasis, flexDirection, flexWrap, flex, justifyContent, justifyItems, justifySelf, order, overflow, textAlign, direction);

export { Flex };
