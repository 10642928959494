import { logic2, composeFragments, pathFragment, action, stateFlag, state, flow, resolveAction, sagaifyLogic2 } from 'core-ingredients';
import { takeLatest } from 'redux-saga/effects';
import { processSetPasscode } from './set-new-passcode-flow.js';

var setNewPasscodeLogic = logic2(composeFragments(pathFragment('security', 'setNewPasscode'), action('submit'), action('calling'), action('closeModal'), action('notCorrectCode'), stateFlag('isSubmited', ['submit'], ['failed'], false), stateFlag('submitting', ['calling'], ['failed', 'notCorrectCode']), stateFlag('modalIsOpen', ['success'], ['closeModal']), stateFlag('isModalGMB', ['openModalGMB'], ['closeModalGMB']), state(['passcode', 'setPasscode'], function (_, _ref) {
  var passcode = _ref.passcode;
  return passcode;
}, null), flow('processSetPasscode', [resolveAction('setPasscode'), takeLatest, processSetPasscode])));
var setNewPasscodeReducer = setNewPasscodeLogic.reducer;
var setNewPasscodeSaga = sagaifyLogic2(setNewPasscodeLogic);

export { setNewPasscodeLogic, setNewPasscodeReducer, setNewPasscodeSaga };
