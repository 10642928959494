import { logic2, composeFragments, pathFragment, sagaifyLogic2 } from 'core-ingredients';
import { withResetForm, withInitializeForm, withFieldsSelectors } from 'core-recipes';
import { editGroupsFieldsFragment } from './groups-list.fields.js';
import { groupsListFragment } from './groups-list.fragment.js';

var templateGroupsListLogic = logic2(composeFragments(pathFragment('templates', 'groups'), editGroupsFieldsFragment, withResetForm({}), withInitializeForm({}), withFieldsSelectors, groupsListFragment));
var templateGroupsListReducer = templateGroupsListLogic.reducer;
var templateGroupsListSaga = sagaifyLogic2(templateGroupsListLogic);

export { templateGroupsListLogic, templateGroupsListReducer, templateGroupsListSaga };
