import { fieldsFragment, fieldFragment } from 'core-recipes';

var notificationsFieldFragment = function notificationsFieldFragment() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return fieldsFragment(options)(fieldFragment({
    name: 'notificationsSearch',
    initialValue: ''
  }));
};

export { notificationsFieldFragment };
