import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { takeLatest } from 'redux-saga/effects';
import { composeFragments, stateFlag } from 'core-ingredients';
import { loadAccountsFlow } from './accounts-list.flow.js';
import { loadBalancesFlow } from './accounts-list-load-balances.flow.js';
import { calculateTotalsFlow } from './accounts-list-calculate-totals.flow.js';
import { processRestrictionsFlow } from './accounts-list-process-restrictions.flow.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
var accountsListFragment = function accountsListFragment() {
  return composeFragments({
    actions: {
      loadAccounts: function loadAccounts() {
        var refresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var withPendingCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        return {
          refresh: refresh,
          withPendingCount: withPendingCount
        };
      },
      groupsLoaded: function groupsLoaded(accounts) {
        return {
          accounts: accounts
        };
      },
      loaded: function loaded(accounts) {
        return {
          accounts: accounts
        };
      },
      loadBalances: function loadBalances(amountsToExchange) {
        return {
          amountsToExchange: amountsToExchange
        };
      },
      allBalancesLoaded: function allBalancesLoaded(result) {
        return {
          result: result
        };
      },
      totalBalanceLoaded: function totalBalanceLoaded(result) {
        return {
          result: result
        };
      },
      calculateTotals: function calculateTotals(exchangedAmounts) {
        return {
          exchangedAmounts: exchangedAmounts
        };
      },
      processRestrictions: function processRestrictions() {
        return {};
      },
      restrictionsLoaded: function restrictionsLoaded(restrictions) {
        return {
          restrictions: restrictions
        };
      },
      changeLocalAccountListName: function changeLocalAccountListName(friendlyName, account) {
        return {
          friendlyName: friendlyName,
          account: account
        };
      }
    },
    state: {
      accounts: {
        'actions.groupsLoaded': function actionsGroupsLoaded(_, _ref) {
          var accounts = _ref.accounts;
          return accounts;
        },
        'actions.loaded': function actionsLoaded(_, _ref2) {
          var accounts = _ref2.accounts;
          return accounts;
        },
        'actions.changeLocalAccountListName': function actionsChangeLocalAccountListName(prevState, _ref3) {
          var friendlyName = _ref3.friendlyName,
              selectedAccount = _ref3.account;
          return prevState.map(function (accountGroup) {
            return _objectSpread(_objectSpread({}, accountGroup), {}, {
              accounts: Array.isArray(accountGroup.accounts) && accountGroup.accounts.map(function (account) {
                return _objectSpread(_objectSpread({}, account), {}, {
                  friendlyName: account.cbsID === selectedAccount.cbsID ? friendlyName : account.friendlyName
                });
              })
            });
          });
        }
      },
      allBalances: {
        'actions.allBalancesLoaded': function actionsAllBalancesLoaded(_, _ref4) {
          var result = _ref4.result;
          return result;
        }
      },
      totalBalance: {
        'actions.totalBalanceLoaded': function actionsTotalBalanceLoaded(_, _ref5) {
          var result = _ref5.result;
          return result;
        }
      },
      restrictions: {
        'actions.restrictionsLoaded': function actionsRestrictionsLoaded(_, _ref6) {
          var restrictions = _ref6.restrictions;
          return restrictions;
        }
      }
    },
    selectors: {
      accounts: [['accounts']],
      allBalances: [['allBalances']],
      totalBalance: [['totalBalance']],
      restrictions: [['restrictions']]
    },
    flows: {
      loadAccounts: ['actions.loadAccounts', takeLatest, loadAccountsFlow],
      loadBalances: ['actions.loadBalances', takeLatest, loadBalancesFlow],
      calculateTotals: ['actions.calculateTotals', takeLatest, calculateTotalsFlow],
      processRestrictions: ['actions.processRestrictions', takeLatest, processRestrictionsFlow]
    },
    initialState: {
      accounts: [],
      allBalances: {},
      totalBalance: null,
      restrictions: null,
      accountsLoaded: false,
      accountsLoadFailed: false
    }
  }, stateFlag('totalsLoading', ['calculateTotals'], ['totalBalanceLoaded']));
};

export { accountsListFragment };
