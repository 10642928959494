import React, { PureComponent } from 'react'
import {
  friendlyNameLogic, isValidInstructionType, hasSpecificRightSelector, ACCOUNT_FRIENDLY_NAME_UPDATE,
} from 'vtb-logic'
import { EditFriendlyNameForm } from 'vtb-ui'
import { connectLogic } from 'core-ingredients'

interface IProductNameEditablePublicProps {
  name: string
  alternativeName: string
  itemId: any
  type: string
  maxWidth?: number
  hasRights: (rights) => boolean
}

interface IProductNameEditableInnerProps extends IProductNameEditablePublicProps {
  submit: any
  names: any
  onChange(friendlyName: string)
  init(friendlyName: string)
  cardAccountId: number
  accountIban: string
  dataCy?: string
}

class ProductNameEditableInner extends PureComponent<IProductNameEditableInnerProps, any> {
  submitForm = (cbsID: string, friendlyName: string) => {
    const { type, itemId, submit, onChange, accountIban } = this.props
    const instructionType = `${type}FriendlyNameUpdate`
    if (isValidInstructionType(instructionType)) {
      submit(type, itemId, friendlyName, instructionType, onChange, accountIban)
    }
  }

  render() {
    const { name, itemId, names, type, alternativeName, maxWidth = [190, '100%'], hasRights, ...props } = this.props
    // TODO get validity from form logic
    const valid = true
    let value = name
    if (names[type] && names[type][itemId]) {
      value = names[type][itemId].value
    }

    return (
      <EditFriendlyNameForm
        dataCy={props.dataCy}
        name={value}
        alternativeName={alternativeName}
        itemId={itemId}
        onSubmit={this.submitForm}
        loading={!valid /* this.props.initializing */}
        valid={valid /* this.props.valid */}
        id={`friendlyName-${itemId}`}
        placeholder='products.friendlyName.edit.placeholder'
        maxWidth={maxWidth}
        isEditable={hasRights(ACCOUNT_FRIENDLY_NAME_UPDATE)}
        {...props}
      />
    )
  }
}

const withLogic = connectLogic(friendlyNameLogic, {
  selectors: {
    hasRights: hasSpecificRightSelector,
  },
})

export const ProductNameEditable = withLogic(ProductNameEditableInner)
