import { composeFragments, validationRule, maxLength, autocorrectingValidationRule, validation } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withDependentFieldFlow, withDependentFieldValidation, withDynamicFieldValidation } from 'core-recipes';
import { basicCharsetChars } from '../../utils/validation-patterns.js';
import { validationConfig, TREASURY_PAYER_NAME_LENGTH } from './treasury-transfer.fields.validations.js';
import { requiredRule, charset, minAmount } from '../../utils/validators.js';
import { localizationSelector } from '../../utils/language-selector.js';

/* eslint-disable no-useless-escape */
var treasuryTransferFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'treasuryMode',
  rules: [validationRule(requiredRule)]
}, withDependentFieldFlow({
  source: 'treasuryMode',
  updateHandler: validationConfig.treasuryMode.dependentFlow
})), fieldFragment({
  name: 'payerName',
  rules: [validationRule(maxLength(TREASURY_PAYER_NAME_LENGTH)), autocorrectingValidationRule(charset(/\S/gi)), autocorrectingValidationRule(charset(basicCharsetChars))]
}, withDependentFieldValidation({
  fieldName: 'treasuryMode',
  code: 'required',
  validation: validationConfig.requiredForSomeoneElse,
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      values: selectors.values
    };
  }
})), fieldFragment({
  name: 'payerTaxId'
}, withDependentFieldFlow({
  source: 'payerTaxId',
  sourceActionName: 'complete',
  updateHandler: validationConfig.payerTaxId.dependentFlow
}), withDependentFieldValidation({
  fieldName: '',
  code: 'taxCodeLength',
  validation: validationConfig.payerTaxId.dependentFieldValidation,
  selectorsFunc: function selectorsFunc(selectors) {
    return {
      localizations: localizationSelector,
      values: selectors.values
    };
  }
})), fieldFragment({
  name: 'treasuryCode',
  rules: [validationRule(requiredRule)]
}, withDynamicFieldValidation({
  query: validationConfig.treasuryCode.dynamicFieldValidation,
  isCallable: true,
  code: 'invalidTreasuryCode',
  triggerActionName: 'completeTreasuryCode',
  validation: function validation$1(validationResult) {
    return validation('invalidTreasuryCode', function () {
      return validationResult.isValid;
    }, null, 'pay.validation.invalidTreasuryCode');
  }
})), fieldFragment({
  name: 'debitAccount',
  initialValue: null,
  rules: [validationRule(requiredRule)]
}), fieldFragment({
  name: 'amount',
  rules: [validationRule(requiredRule), validationRule(minAmount(0.01))]
}), fieldFragment({
  name: 'purpose',
  rules: [validationRule(maxLength(250)), autocorrectingValidationRule(charset(/\S/gi)), autocorrectingValidationRule(charset(basicCharsetChars)), validationRule(requiredRule)]
}), fieldFragment({
  name: 'beneficiaryName'
}), fieldFragment({
  name: 'beneficiaryBank'
}), fieldFragment({
  name: 'beneficiaryDetails'
})));

export { treasuryTransferFieldsFragment };
