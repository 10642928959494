import { composeFragments, validationRule, required, minLength, maxLength, validation } from 'core-ingredients';
import { fieldsFragment, fieldFragment, withDynamicFieldValidation, withResetForm, withFieldsSelectors, withInitializeForm } from 'core-recipes';
import { emailStructure } from '../../utils/validation-patterns.js';
import { checkIfPersonalIdIsNotAddedYet, checkUserNameUniqueness } from './users-create.flow.js';
import { charset } from '../../utils/validators.js';

var noDoubleDot = /^(?!.*?\.\.).*?$/; // single @, but not at start or at the end
// mandatory dot after @, but at least one character between them
// 64 character max before @
// no dot at start or at the end

var usersCreateFieldsFragment = composeFragments(fieldsFragment({})(fieldFragment({
  name: 'personalId',
  rules: [validationRule(required), validationRule(minLength(5)), validationRule(maxLength(30))]
}, withDynamicFieldValidation({
  query: checkIfPersonalIdIsNotAddedYet,
  isCallable: true,
  code: 'personalIdNotUnique',
  triggerActionName: 'completePersonalId',
  validation: function validation$1(_ref) {
    var isValid = _ref.isValid,
        message = _ref.message;
    return validation('personalIdNotUnique', function () {
      return isValid;
    }, null, message);
  }
})), fieldFragment({
  name: 'firstName',
  rules: [validationRule(required), validationRule(minLength(1)), validationRule(maxLength(50))]
}), fieldFragment({
  name: 'lastName',
  rules: [validationRule(required), validationRule(minLength(2)), validationRule(maxLength(50))]
}), fieldFragment({
  name: 'username',
  rules: [validationRule(required), validationRule(minLength(1)), validationRule(maxLength(30))]
}, withDynamicFieldValidation({
  query: checkUserNameUniqueness,
  isCallable: true,
  code: 'usernameNotUnique',
  triggerActionName: 'completeUsername',
  validation: function validation$1(validationResult) {
    return validation('usernameNotUnique', function () {
      return validationResult.isValid;
    }, null, 'um.userInfo.usernameNotUnique.errorMessage');
  }
})), fieldFragment({
  name: 'roleName',
  rules: []
}), fieldFragment({
  name: 'mobilePhoneNumber',
  rules: [validationRule(required), validationRule(minLength(9)), validationRule(maxLength(9)), validationRule(charset(/[0-9]{9}/))]
}), fieldFragment({
  name: 'email',
  rules: [validationRule(required), validationRule(minLength(5)), validationRule(maxLength(200)), validationRule(charset(/^[@.a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]+$/)), validationRule(charset(emailStructure, 'um.userInfo.invalidEmail.errorMessage')), validationRule(charset(noDoubleDot, 'um.userInfo.invalidEmail.errorMessage'))]
})), withResetForm({}), withFieldsSelectors, withInitializeForm({}));

export { usersCreateFieldsFragment };
